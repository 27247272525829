import LessonTypeURLFactory from "../../../Helpers/LessonTypeURLFactory";
import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function adminGetCourses(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.admin_get_courses, {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getAllCourses(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_courses, {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCourseInfo(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_info, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addProgramToCart(programPaymentId, paymentAmount) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_to_cart, {
          program_payment_id: programPaymentId,
          email: localStorage.getItem("email"),
          purchase_type: "program",
          program_amount: paymentAmount,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addToCart(courseId, couponValue, giftPayload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(
          env.add_to_cart,
          giftPayload
            ? { ...giftPayload, coupon: couponValue }
            : {
                course_id: courseId,
                email: localStorage.getItem("email"),
                purchase_type: "course",
                coupon: couponValue,
              }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function finalPrice(giftPayload, courseId, couponValue) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(
          env.final_price,
          giftPayload
            ? { ...giftPayload, coupon: couponValue }
            : {
                course_id: courseId,
                email: localStorage.getItem("email"),
                purchase_type: "course",
                coupon: couponValue,
              }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function removeFromCart(courseId, cartId) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.remove_from_cart, {
          course_id: courseId,
          cart_id: cartId,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function payCourseWithBankTransfer(formData) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.cart_pay, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function freeCourseRegister(cartId, paymentType) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.cart_pay, {
          cart_id: cartId,
          payment_type: paymentType,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function payCourse(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.cart_pay, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function listCourseCategories() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.list_course_categories)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function listCourseDepartments(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.list_course_departments, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addCourseCategory(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_course_category, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addCourseDepartment(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_course_department, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function syncDepartmentUsers(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.sync_department_users, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getDepartmentUsers(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_department_users, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteDepartment(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_department, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err, res) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteCategory(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_category, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getDepartments(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_department, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCategories(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_category, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editDepartments(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_department, { id: id }), payload)
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err, "err 2");
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editCategory(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .put(URLBuilder(env.edit_category, { id: id }), payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addCourse(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_course, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function cloneCourse(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.clone_course, { id: id }), payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCoursesInfo() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_courses_info)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSpecialties(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_specialties, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getSpecialtiesNew(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_specialties_new, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTrainerCourses() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_trainer_courses)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addChapter(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_chapter, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editChapter(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_chapter, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteChapter(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_chapter, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCourseChapters(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_chapters, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getChapterSubChapters(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_chapter_subchapters, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSubChapterLessons(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_subchapter_lesson, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function approveActivityResponse(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.approve_activity_response, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function disapproveActivityResponse(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.disapprove_activity_response, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCourseObjectives(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_objectives, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addSubChapterLesson(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_subchapter_lesson, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteLesson(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_Lesson, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function runCourse(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.run_course, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCourseTree(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_tree, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getIntroVideo() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_intro_video)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editLessonContent(id, content) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_lesson_content, { id: id }), {
          content: content,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editLesson(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.edit_lesson, { id: id }), {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getLessonActivity(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_activity, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function answerLessonActivity(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.answer_lesson_activity, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getLessonActivityAnswers(id, filter) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_activity_answers, { id: id }), {
          params: {
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editCourse(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_course, { id: id }), payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editCourseVisibility(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_course_visibility, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCourseDetails(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_details, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getProgramDetails(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_program_details, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function joinFreeCourse(courseId, type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.join_free_course, { id: courseId, type: type }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCoursePaymentDetails(id, type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_payment_details, { id: id, type: type }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getCourseGiftPaymentDetails(id, type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.get_course_gift_payment_details, {
            id: id,
            type: type,
          })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function payCourseOrProgram(type, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.pay_course, { type: type }), payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function reorderLessonPlans(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.reorder_lesson_plans, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportCertificate(id, language) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.export_certificate, { id: id }), {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": language,
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "certificate.pdf");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function payProgramPayments(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.pay_program_payments, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function payProgramPaymentsGift(payload, gift_id, payment_id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(
          URLBuilder(env.pay_program_payments_gift, {
            giftId: gift_id,
            paymentId: payment_id,
          }),
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

// applyCoupon
export function applyCoupon(courseId, couponCode) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.apply_coupon, { id: courseId, coupon: couponCode }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getLessonContent(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_content, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getLessonActivities(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_activities, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addContentToLesson(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_lesson_content, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function updateContentActivity(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(
          URLBuilder(env.update_content_activity, { content: id }),
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addActivityToLesson(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_activity_content, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function addActivityToChapter(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_activity_to_chapter, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function editActivityToLesson(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_activity_content, { id: id }), payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function deleteLessonActivity(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_activity, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function organizeLessonContent(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.organize_lesson_content, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getLessonPlans(id, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_plans, { id: id }), { params: params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteFileFromLesson(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.delete_file_from_lesson, { content: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function deleteActivityFromLesson(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.delete_activit_from_lesson, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function uploadFileToLesson(type, payload, lesson) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(
          URLBuilder(LessonTypeURLFactory(type), { lesson: lesson }),
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addVideoContentToLesson(type, payload, lesson) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(
          URLBuilder(LessonTypeURLFactory(type), { lesson: lesson }),
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addTextContentToLesson(payload, lesson) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.add_text_to_lesson, { lesson: lesson }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function downloadFileFromLesson(name, extension, id, type) {
  // console.log(extension);
  // console.log(name);
  // console.log(id);
  console.log(type);

  connector
    .get(URLBuilder(env.download_file_from_lesson, { id: id }), {
      responseType: "blob",
      params: {
        type: type,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); //or any other extension
      if (extension != "")
        link.setAttribute("download", name + "." + extension); //or any other extension

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
}

export function downloadFolderFromLesson(name, id) {
  connector
    .get(URLBuilder(env.download_folder_from_lesson, { id: id }), {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
}

export function getEnrolledUsersForRestrictions(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_enrolled_users_for_restrictions, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getPreRequestsLessons(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_pre_requests_lessons, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addRestrictionLesson(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.add_restriction_lesson, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getRestrictions(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_restrictions, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportLessonContent(type, name, extension, id, loading) {
  loading.setFileLoaded(true);
  let url = "";
  if (type == "chapters") {
    url = env.export_File_To_chapter;
  } else if (type == "subChapters") {
    url = env.export_File_To_chapter;
  } else if (type == "lesson") {
    url = env.export_File_To_lesson;
  } else if (type == "lessons") {
    url = env.export_File_To_chapter;
  } else if (type == "course") {
    url = env.export_File_To_course;
  }
  connector
    .get(URLBuilder(url, { id: id }), {
      responseType: "blob",
    })
    .then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + "." + extension); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      loading.setFileLoaded(false);
    });
}

export function importLessonContent(type, payload) {
  console.log(type);
  let url = "";
  if (type == "chapters") {
    url = env.import_File_To_chapter;
  } else if (type == "subChapters") {
    url = env.import_File_To_chapter;
  } else if (type == "lesson") {
    url = env.import_File_To_lesson;
  } else if (type == "lessons") {
    url = env.import_File_To_lesson;
  } else if (type == "course") {
    url = env.import_File_To_course;
  }
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(url, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addCourseRate(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_rate_course, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCourseRate(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_rate, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function coursesPartnerList({ perPage, page, filter, id }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.courses_partnar_list, { id: id }), {
          params: {
            page,
            perPage,
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}
export function programsPartnerList({ perPage, page, filter, id }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.programs_partnar_list, { id: id }), {
          params: {
            page,
            perPage,
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}
