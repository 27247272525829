import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  addActivityToChapter,
  addActivityToLesson,
  addContentToLesson,
  deleteActivityFromLesson,
  deleteFileFromLesson,
  deleteLessonActivity,
  editActivityToLesson,
  getLessonActivities,
  getLessonPlans,
  organizeLessonContent,
  updateContentActivity,
} from "../Services/api/courses/courseProvider";
import { deleteSurvey } from "../Services/api/survey/SurveyProvider";
import i18n from "../i18n/i18n";
import { Lang } from "../utils";

export const LessonContentContext = React.createContext();

export function LessonContentProvider(props) {
  const { t } = useTranslation();

  const [lessonID, setLessonID] = useState(0);
  const [targetModalContent, setTargetModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] =
    useState(false);
  const [content, setContent] = useState([]);
  const [plans, setPlans] = useState([]);
  const [activitiesTab, setActivitiesTab] = useState([]);
  const [lessonInfo, setLessonInfo] = useState({});
  const [dataToBeOrganized, setDataToBeOrganized] = useState([]);
  const [originalContent, setOriginalContent] = useState([]);
  const [flatContent, setFlatContent] = useState([]);
  const [contentMap, setContentMap] = useState({});
  const [directories, setDirectories] = useState([]);
  const [pickedFileIdToBeDragged, setPickedFileIdToBeDragged] = useState(null);
  const [pickedDirectoryIdToBeDragged, setPickedDirectoryIdToBeDragged] =
    useState(null);
  const [targetEntityOnDropSuccess, setTargetEntityOnDropSuccess] =
    useState(null);
  const [disableOrganizingActionButtons, setDisableOrganizingActionButtons] =
    useState(true);
  const [selectedPanel, setSelectedPanel] = useState(0);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    if (targetEntityOnDropSuccess) {
      const parsedId = parseInt(targetEntityOnDropSuccess.substring(2));
      if (pickedFileIdToBeDragged) {
        changeDataAlignment(
          parsedId,
          pickedFileIdToBeDragged,
          targetEntityOnDropSuccess[0]
        );
      } else if (pickedDirectoryIdToBeDragged) {
        changeDataAlignment(
          parsedId,
          pickedDirectoryIdToBeDragged,
          targetEntityOnDropSuccess[0]
        );
      }

      setPickedFileIdToBeDragged(null);
      setPickedDirectoryIdToBeDragged(null);
      setTargetEntityOnDropSuccess(null);
    }
  }, [targetEntityOnDropSuccess]);

  useEffect(() => {
    let flatContentList = [];
    let contentMapObject = {};
    if (dataToBeOrganized && dataToBeOrganized.length > 0) {
      dataToBeOrganized.forEach((item, index) => {
        const toBePushedItem = {
          ...item,
        };

        toBePushedItem.flatOrder = flatContentList.length;
        toBePushedItem.order = index;
        toBePushedItem.isChild = false;
        flatContentList.push(toBePushedItem);
        contentMapObject[toBePushedItem.id] = toBePushedItem;

        if (
          toBePushedItem.type === "directory" &&
          item.files &&
          item.files.length > 0
        ) {
          item.files.forEach((child, position) => {
            const toBePushedChild = {
              ...child,
            };

            toBePushedChild.flatOrder = flatContentList.length;
            toBePushedChild.order = position;
            toBePushedChild.isChild = true;
            toBePushedItem.parentID = toBePushedItem.id;

            flatContentList.push(toBePushedChild);
            contentMapObject[toBePushedChild.id] = toBePushedChild;
          });
        }
      });

      setContentMap(contentMapObject);
      setFlatContent(flatContentList);
    }
  }, [dataToBeOrganized]);

  const reconstructContentFromFlatList = (flatList) => {
    const newContent = [];
    for (let cursor = 0; cursor < flatList.length; cursor++) {
      let capturedItem = {};
      if (flatList[cursor].type !== "directory") {
        capturedItem = {
          id: flatList[cursor].id,
          title: flatList[cursor].title,
          type: flatList[cursor].type,
          extension: flatList[cursor].extension,
        };
      } else {
        capturedItem = {
          id: flatList[cursor].id,
          name: flatList[cursor].name,
          type: "directory",
          files: [],
        };

        cursor++;
        while (flatList[cursor] && flatList[cursor].isChild) {
          capturedItem.files.push({
            id: flatList[cursor].id,
            title: flatList[cursor].title,
            type: flatList[cursor].type,
            extension: flatList[cursor].extension,
          });

          cursor++;
        }

        cursor--;
      }

      newContent.push(capturedItem);
    }

    setDataToBeOrganized(newContent);
    setDisableOrganizingActionButtons(false);
  };

  const changeDataAlignment = (pickedId, targetId, direction) => {
    let newFlatList = [];
    const contentMapTemp = contentMap;
    let hasSwapped = false;
    if (contentMap[targetId] && contentMap[pickedId]) {
      if (
        contentMap[targetId].type === "directory" &&
        contentMap[pickedId].type !== "directory"
      ) {
        return false;
      }

      for (let cursor = 0; cursor < flatContent.length; cursor++) {
        if (
          contentMap[targetId].type === "directory" &&
          contentMap[pickedId].type === "directory" &&
          flatContent[cursor].id === targetId
        ) {
          cursor++;
          while (flatContent[cursor] && flatContent[cursor].isChild) {
            cursor++;
          }
        } else if (flatContent[cursor].id === targetId) {
          cursor++;
        }

        if (flatContent[cursor]) {
          if (hasSwapped || flatContent[cursor].id !== pickedId) {
            contentMapTemp[flatContent[cursor].id].flatOrder =
              newFlatList.length;
            newFlatList.push(flatContent[cursor]);
          } else {
            hasSwapped = true;
            if (
              contentMap[targetId].type === "directory" &&
              contentMap[pickedId].type === "directory"
            ) {
              cursor++;
              while (flatContent[cursor] && flatContent[cursor].isChild) {
                cursor++;
              }
              cursor--;

              let capturedNewFlatListCursor = newFlatList.length;
              let targetCursor = contentMapTemp[targetId].flatOrder;
              const targetList = [];
              targetList.push(flatContent[targetCursor]);
              targetCursor++;
              while (
                flatContent[targetCursor] &&
                flatContent[targetCursor].isChild
              ) {
                targetList.push(flatContent[targetCursor]);
                targetCursor++;
              }

              let pickCursor = contentMapTemp[pickedId].flatOrder;
              const pickList = [];
              pickList.push(flatContent[pickCursor]);
              pickCursor++;
              while (
                flatContent[pickCursor] &&
                flatContent[pickCursor].isChild
              ) {
                pickList.push(flatContent[pickCursor]);
                pickCursor++;
              }

              if (direction === "u") {
                newFlatList = newFlatList.concat(targetList.concat(pickList));
              } else {
                newFlatList = newFlatList.concat(pickList.concat(targetList));
              }

              while (newFlatList[capturedNewFlatListCursor]) {
                contentMapTemp[
                  newFlatList[capturedNewFlatListCursor].id
                ].flatOrder = capturedNewFlatListCursor;
                capturedNewFlatListCursor++;
              }
            } else {
              if (
                contentMapTemp[targetId].type !== "directory" &&
                contentMapTemp[pickedId].type === "directory"
              ) {
                if (direction === "u") {
                  contentMapTemp[targetId].isChild = false;
                } else {
                  contentMapTemp[targetId].isChild = true;
                }
              } else if (
                contentMapTemp[targetId].type !== "directory" &&
                contentMapTemp[pickedId].type !== "directory"
              ) {
                contentMapTemp[targetId].isChild =
                  contentMapTemp[pickedId].isChild;
              }

              if (direction === "u") {
                contentMapTemp[targetId].flatOrder = newFlatList.length;
                newFlatList.push(contentMapTemp[targetId]);
                contentMapTemp[flatContent[cursor].id].flatOrder =
                  newFlatList.length;
                newFlatList.push(flatContent[cursor]);
              } else {
                contentMapTemp[flatContent[cursor].id].flatOrder =
                  newFlatList.length;
                newFlatList.push(flatContent[cursor]);
                contentMapTemp[targetId].flatOrder = newFlatList.length;
                newFlatList.push(contentMapTemp[targetId]);
              }
            }
          }
        }
      }
      reconstructContentFromFlatList(newFlatList);
    }
  };

  const prepareData = (data) => {
    const currentDirectories = [];
    let manipulatedData = [];
    data.forEach((item) => {
      if (item.type !== "folder") {
        manipulatedData.push({
          id: item.id,
          title: item.title + (item.extension ? "." + item.extension : ""),
          type: item.type,
          extension: item.extension,
        });
      } else {
        currentDirectories.push({
          id: item.id,
          name: item.title,
        });

        manipulatedData.push({
          id: item.id,
          name: item.title,
          type: "directory",
          files: item.childs.map((mapItem) => {
            return {
              id: mapItem.id,
              name:
                mapItem.title +
                (mapItem.extension ? "." + mapItem.extension : ""),
              type: mapItem.type,
              extension: mapItem.extension,
            };
          }),
        });
      }
    });

    setDataToBeOrganized(manipulatedData);
    setOriginalContent(manipulatedData);
    setDirectories(currentDirectories);
  };

  const resetContent = () => {
    setDisableOrganizingActionButtons(true);
    setDataToBeOrganized(selectedPanel === 0 ? content : activitiesTab);
  };

  const deleteContent = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      deleteFileFromLesson(id)
        .then(async (result) => {
          if (result.status) {
            resolve();
          }
        })
        .catch((err) => {
          reject();
        });
    });

    return myPromise;
  };
  const deleteActivityNew = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      deleteActivityFromLesson(id)
        .then(async (result) => {
          if (result.status) {
            resolve();
          }
        })
        .catch((err) => {
          reject();
        });
    });

    return myPromise;
  };

  const deleteSurveyById = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      deleteSurvey(id)
        .then(async (result) => {
          if (result.status) {
            resolve();
          }
        })
        .catch((err) => {
          reject();
        });
    });

    return myPromise;
  };

  const addContent = (payload) => {
    const myPromise = new Promise((resolve, reject) => {
      addContentToLesson(payload)
        .then(async (result) => {
          if (result.status) {
            resolve();
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {typeof err === "string" ? (
                <span
                  style={{ fontSize: 12, fontWeight: "500", display: "block" }}
                >
                  {err}
                </span>
              ) : err.response.data ? (
                <>
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    {err.response.data.msg}
                  </span>
                  <ul>
                    {Object.keys(err.response.data.errors).map((key) => {
                      return (
                        <li>
                          <span style={{ fontSize: 11 }}>
                            {err.response.data.errors[key]}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                t("Failure_in_service")
              )}
            </span>
          );

          reject(err);
        });
    });

    return myPromise;
  };
  const editContentActivity = (payload, id) => {
    const myPromise = new Promise((resolve, reject) => {
      setDataToBeOrganized([]);
      updateContentActivity(payload, id)
        .then(async (result) => {
          if (result.status) {
            resolve();
          }
        })
        .catch((err) => {
          toast.error(
            <span
              style={{
                fontSize: 13,
                fontWeight: "bold",
                direction: i18n.language == Lang.AR ? "rtl" : "ltr",
              }}
            >
              {typeof err === "string" ? (
                <span
                  style={{ fontSize: 12, fontWeight: "500", display: "block" }}
                >
                  {err}
                </span>
              ) : err.response.data ? (
                <>
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    {err.response.data.msg}
                  </span>
                  <ul>
                    {Object.keys(err.response.data.errors).map((key) => {
                      return (
                        <>
                          {err.response.data.errors[key].map((msg) => (
                            <li>
                              <span style={{ fontSize: 11 }}>{msg}</span>
                            </li>
                          ))}
                        </>
                      );
                    })}
                  </ul>
                </>
              ) : (
                t("Failure_in_service")
              )}
            </span>
          );

          reject(err);
        });
    });

    return myPromise;
  };

  const addActivity = (payload) => {
    const myPromise = new Promise((resolve, reject) => {
      addActivityToLesson(payload)
        .then(async (result) => {
          if (result.status) {
            resolve();
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {typeof err === "string" ? (
                <span
                  style={{ fontSize: 12, fontWeight: "500", display: "block" }}
                >
                  {err}
                </span>
              ) : err.response.data ? (
                <>
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    {err.response.data.msg}
                  </span>
                  <ul>
                    {Object.keys(err.response.data.errors).map((key) => {
                      return (
                        <li>
                          <span style={{ fontSize: 11 }}>
                            {err.response.data.errors[key]}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                t("Failure_in_service")
              )}
            </span>
          );
          reject();
        });
    });

    return myPromise;
  };
  const addActivityChapter = (payload) => {
    const myPromise = new Promise((resolve, reject) => {
      addActivityToChapter(payload)
        .then(async (result) => {
          if (result.status) {
            resolve();
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {typeof err === "string" ? (
                <span
                  style={{ fontSize: 12, fontWeight: "500", display: "block" }}
                >
                  {err}
                </span>
              ) : err.response.data ? (
                <>
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    {err.response.data.msg}
                  </span>
                  <ul>
                    {Object.keys(err.response.data.errors).map((key) => {
                      return (
                        <li>
                          <span style={{ fontSize: 11 }}>
                            {err.response.data.errors[key]}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                t("Failure_in_service")
              )}
            </span>
          );
          reject();
        });
    });

    return myPromise;
  };

  const editActivity = (payload, id, lessonId) => {
    const myPromise = new Promise((resolve, reject) => {
      editActivityToLesson(payload, id)
        .then(async (result) => {
          if (result.status) {
            fetchLessonActivities(lessonId);
            resolve();
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {typeof err === "string" ? (
                <span
                  style={{ fontSize: 12, fontWeight: "500", display: "block" }}
                >
                  {err}
                </span>
              ) : err.response.data ? (
                <>
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    {err.response.data.msg}
                  </span>
                  <ul>
                    {Object.keys(err.response.data.errors).map((key) => {
                      return (
                        <li>
                          <span style={{ fontSize: 11 }}>
                            {err.response.data.errors[key]}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                t("Failure_in_service")
              )}
            </span>
          );
          reject();
        });
    });

    return myPromise;
  };
  const deleteActivity = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      deleteLessonActivity(id)
        .then(async (result) => {
          if (result.status) {
            toast.success(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("trainer.course.lesson.successful_deleting_activity")}
              </span>
            );
            resolve();
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("trainer.course.lesson.failed_deleting_activity")}
            </span>
          );

          reject();
        });
    });

    return myPromise;
  };

  const constructReOrganizedDataPayload = () => {
    const payload = [];
    dataToBeOrganized.forEach((rootItem, rootIndex) => {
      if (rootItem.type !== "directory") {
        payload.push({
          id: rootItem.id,
          order: rootIndex + 1,
        });
      } else {
        const dir = {
          id: rootItem.id,
          order: rootIndex + 1,
          childs: [],
        };

        rootItem.files.forEach((child, childIndex) => {
          dir.childs.push({
            id: child.id,
            order: childIndex + 1,
          });
        });

        payload.push(dir);
      }
    });

    return {
      data: {
        [selectedPanel === 0 ? "content" : "activity"]: payload,
      },
    };
  };

  const reOrganizeContent = () => {
    const myPromise = new Promise((resolve, reject) => {
      setIsPerformingBackgroundOperation(true);
      organizeLessonContent(lessonID, constructReOrganizedDataPayload())
        .then(async (result) => {
          if (result.status) {
            setDisableOrganizingActionButtons(true);
            toast.success(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("trainer.course.lesson.successful_content_organization")}
              </span>
            );
            setIsPerformingBackgroundOperation(false);
            resolve();
          }
        })
        .catch((err) => {
          setIsPerformingBackgroundOperation(false);
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("trainer.course.lesson.failed_organizing_content")}
            </span>
          );

          reject();
        });
    });

    return myPromise;
  };

  const fetchLessonActivities = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      getLessonActivities(id).then(
        (res) => {
          if (res.status === 200) {
            setActivities(res.data.data.data);
            resolve();
          } else {
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("trainer.course.lesson.failed_fetching_activities")}
              </span>
            );
            reject();
          }
        },
        (err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("trainer.course.lesson.failed_fetching_activities")}
            </span>
          );
          reject();
        }
      );
    });

    return myPromise;
  };

  const fetchLessonContent = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      setLessonID(id);
      getLessonPlans(id, { perPage: 100 })
        .then(async (result) => {
          if (result.status === 200) {
            setPlans(result.data.plans);
            // setContent(result.data.data.contents);
            // setActivitiesTab(result.data.data.activities);
            setLessonInfo(result.data.lesson);
            // setDataToBeOrganized(
            //   selectedPanel === 0
            //     ? result.data.data.contents
            //     : result.data.data.activities
            // );
            setInitialLoading(false);
            resolve();
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("failed_fetching")}
            </span>
          );

          reject();
        });
    });

    return myPromise;
  };

  return (
    <LessonContentContext.Provider
      value={{
        plans,
        setPlans,
        content,
        setContent,
        activitiesTab,
        lessonInfo,
        setActivitiesTab,
        dataToBeOrganized,
        setDataToBeOrganized,
        isModalOpen,
        setIsModalOpen,
        targetModalContent,
        setTargetModalContent,
        initialLoading,
        setInitialLoading,
        isPerformingBackgroundOperation,
        setIsPerformingBackgroundOperation,
        directories,
        setDirectories,
        pickedFileIdToBeDragged,
        setPickedFileIdToBeDragged,
        pickedDirectoryIdToBeDragged,
        setPickedDirectoryIdToBeDragged,
        targetEntityOnDropSuccess,
        setTargetEntityOnDropSuccess,
        disableOrganizingActionButtons,
        setDisableOrganizingActionButtons,
        selectedPanel,
        setSelectedPanel,
        addActivityChapter,
        activities,
        setActivities,
        fetchLessonContent,
        addContent,
        deleteContent,
        changeDataAlignment,
        resetContent,
        reOrganizeContent,
        addActivity,
        fetchLessonActivities,
        deleteActivity,
        deleteSurveyById,
        editActivity,
        editContentActivity,
        deleteActivityNew,
      }}
    >
      {props.children}
    </LessonContentContext.Provider>
  );
}
