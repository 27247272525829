import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

function UploadFileModal(props) {
    require("./UploadFileModal.css");
    const [file, setFile] = useState(null);

    const inputFile = useRef(null);

    const {t} = useTranslation();

    const preparePayload = () => {
        if (file) {
            let formData = new FormData();
            formData.append('file', file);
            props.onFileUpload(formData);
            props.importModalRef.current.dismissModal();
        } else {
            toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    {t("trainer.quiz.modal.import.validation.no_file_selected")}
                </span>
            )
        }
    }

    return (
        <>
            <div
                className={"tajah-basic-modal-md"}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className={"tajah-basic-modal-header"}>
                    <h4>{t("trainer.quiz.modal.import.label")}</h4>
                    <div>
                        <i
                            className="fas fa-times tajah-modal-dismiss"
                            onClick={() => {
                                props.importModalRef.current.dismissModal();
                            }}
                        ></i>
                    </div>
                </div>

                <div className={"tajah-basic-modal-body"} style={{
                    display: "flex",
                    justifyContent:"center",
                    alignItems:"center",
                    flexDirection: "row"
                }}>
                    {
                        file?
                            <>
                                <i
                                    className={"fa fa-trash"}
                                    style={{
                                        cursor: "pointer",
                                        color:"red",
                                        marginLeft: document.body.dir == 'rtl' ? 10 : 0,
                                        marginRight: document.body.dir == 'ltr' ? 10 : 0,
                                    }}
                                    onClick={() => {
                                        setFile(null);
                                    }}
                                ></i>
                                <span>{file.name}</span>
                            </> :
                            <>
                                <div className={"tajah-form-row"}>
                                    <div className={"tajah-form-row-element-v2"}>
                                        <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                            <button
                                                type={"button"}
                                                onClick={() => {
                                                    inputFile.current.click();
                                                }}
                                                className="admin_add_button"
                                            >
                                                {t("trainer.quiz.modal.import.add_file")}
                                            </button>
                                            <input
                                                id="file"
                                                name="file"
                                                type="file"
                                                ref={inputFile}
                                                style={{ display: "none" }}
                                                onChange={(event) => {
                                                    let file = event.target.files[0];
                                                    let reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    reader.onload = () => {
                                                        setFile(event.target.files[0]);
                                                    };
                                                    reader.onerror = function (error) {
                                                        console.log("Error: ", error);
                                                    };
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                </div>
                <div className={"tajah-basic-modal-footer"}>
                    <button
                        style={{
                            marginLeft: document.body.dir == "rtl" ? 10 : 0,
                            marginRight: document.body.dir == "ltr" ? 10 : 0,
                        }}
                        className={"btn btn-success"}
                        onClick={() => {
                            preparePayload();
                        }}
                    >
                        {t("trainer.quiz.modal.import.accept")}
                    </button>
                    <button
                        type={"button"}
                        onClick={() => {
                            props.importModalRef.current.dismissModal();
                        }}
                        className={"btn"}
                    >
                        {t("modal.common.cancel")}
                    </button>
                </div>
            </div>
        </>
    )
}

export default UploadFileModal;