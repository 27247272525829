import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useHistory} from "react-router";
import i18n from "./../../i18n/i18n";
import {Lang} from "./../../utils";
import {Rating} from "@mui/material";
import {toast} from "react-toastify";
import {Formik} from "formik";
import {addCourseRate} from "../../Services/api/courses/courseProvider";

function AddRateCourseModal(props) {
  require("./addRateCourseModal.css");
  const { t } = useTranslation();
  const history = useHistory()
  const [isLoading,setIsLoading] = useState(true)
  const [hasSubmitted, setHasSubmitted] = useState(false);

    return (
        <>
            <div
                className={"tajah-basic-modal-md"}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className={"tajah-basic-modal-header"}>
                    <h4>{props.title}</h4>
                    <div>
                        <i
                            className="fas fa-times tajah-modal-dismiss"
                            onClick={() => {
                                props.addRateCourseModalRef.current.dismissModal();
                                props.accept();
                            }}
                        ></i>
                    </div>
                </div>

                <div className={"tajah-basic-modal-body"}>
                <div className="table-responsive" style={{
                    width: '90%'
                  }}>
                 <Formik
                    initialValues={{
                    rateable_id: props.courseId,
                    stars: "",
                    description: "",
                    course_id:null
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        setIsLoading(true);
                        let dataToBeUploaded = {
                          ...values,
                        };


                        let response = await addCourseRate(dataToBeUploaded);
                        if (response.status === 201 && response.data.status) {
                            props.addRateCourseModalRef.current.dismissModal();
                            toast.success(
                        <span style={{fontSize: 13, fontWeight: "bold"}}>
                          {t('rate.label.success')}
                        </span>
                            );
                          setIsLoading(false);
                        } else {
                          toast.error(
                            <span style={{fontSize: 13, fontWeight: "bold"}}>
                           {response.data.msg ? response.data.msg : "Failure in service"}
                           </span>
                        );
                          setSubmitting(false);
                        }
                      } catch (err) {
                        setSubmitting(false);
                        toast.error(
                         <span style={{fontSize: 13, fontWeight: "bold"}}>
                        {err.response.data.msg ? err.response.data.msg : "Failure in service"}
                        </span>
                           )
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.stars) {
                        errors.stars = t("crud.errors.required");
                      }

                      if (!values.description) {
                        errors.description = t("crud.errors.required");
                      }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="admin_add_form">
                                <div className="row">
                              <div className="col-md-6">
                          <label htmlFor="name" className="admin_add_label">
                              {t("rate.label.rate")}:
                            </label>
                            <Rating
                             className={i18n.language == Lang.AR ? "rateStyle" : ''}
                             name={"stars"}
                              value={values.stars}
                             onChange={(event, newValue) => {
                               console.log(newValue)
                             setFieldValue('stars',newValue);
                             }}
                             style={{marginRight:'5%',marginLeft:'5%'}}
                           />
                            <p className={"form-input-error-space"}>
                              {errors.stars ? errors.stars : null}
                            </p>
                            </div>

                              </div>
                        <div className="row">
                          <div className="col-md-12">
                            <label htmlFor="name" className="admin_add_label">
                              {t("rate.label.description")}:
                            </label>
                                  <textarea
              id="description"
              placeholder={t("survey.trainee.description")}
              name={"description"}
              value={values.description}
              className="description"
              onChange={(e)=>{
                  console.log(e.target.value)
                  setFieldValue('description',e.target.value)
                  }}/>
                            <p className={"form-input-error-space"}>
                              {errors.description ? errors.description : null}
                            </p>
                          </div>

                          </div>


                          <div className="col-md-3">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="admin_add_button"
                            >
                              {t("rate.label.add")}
                            </button>
                          </div>
                      </form>
                    )}
                  </Formik>
                  </div>
                </div>
            </div>
        </>
    );
}

export default AddRateCourseModal;
