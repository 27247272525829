import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import classes from "./Calender.module.css";
import {
  getEventTypes,
  getEventPerMonth,
  getEventPerDay,
} from "../../../../Services/api/event/eventProvider";
import Calendar from "react-calendar";
import "./Calender.css";
import EventCard from "./EventCard";
import LoadingSpinner from "../../../Shared/Components/LoadingSpinner/LoadingSpinner";

export default function Calender() {
  const currentDate = moment().format("YYYY-MM-DD");
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(currentDate);
  const [eventTypes, setEventTypes] = useState([]);
  const [badgesLoading, setBadgesLoading] = useState(true);
  const [LoadingEvents, setLoadingEvents] = useState(true);
  const [eventsOfDay, setEventsOfDay] = useState([]);
  const [showEvents, setShowEvents] = useState(true);
  const [events, setEvents] = useState([]);
  const [endOfMonth, setEndOfMonth] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [startOfMonth, setStartOfMonth] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const getEventsOfMonth = (start, end) =>
    getEventPerMonth(start, end)
      .then((res) => {
        if (res.status === 200) {
          setEvents(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  const getEventsOfDay = (date) => {
    setLoadingEvents(true);
    getEventPerDay(date)
      .then((res) => {
        if (res.status === 200) {
          setEventsOfDay(res.data.data);
          setLoadingEvents(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingEvents(false);
      });
  };
  useEffect(() => {
    getEventsOfDay(moment().format("YYYY-MM-DD"));
  }, []);
  useEffect(() => {
    getEventTypes()
      .then((res) => {
        if (res.status === 200) {
          setEventTypes(res.data.data);
          // console.log(res.data);
          setBadgesLoading(false);
        }
      })
      .catch((err) => console.log(err));
    getEventsOfMonth(startOfMonth, endOfMonth);
  }, [startOfMonth, endOfMonth]);

  const handleChange = (value) => {
    setValue(value);
    setShowEvents(true);
    getEventsOfDay(moment(value).format("YYYY-MM-DD"));
    console.log(value);
  };

  const handleNextPrev = (value) => {
    console.log(value);
    setStartOfMonth(moment(value.activeStartDate).format("YYYY-MM-DD"));
    setEndOfMonth(
      moment(value.activeStartDate).endOf("month").format("YYYY-MM-DD")
    );
  };

  return (
    <>
      <div className={classes.mainCont}>
        <div
          className={classes.calenderCont}
          style={showEvents ? { borderRadius: "0 0 10px 10px" } : {}}
        >
          <Calendar
            calendarType="islamic"
            value={value}
            // onViewChange={(next) => console.log(next)}
            // onClickMonth={(value) => console.log(value)}
            onActiveStartDateChange={(value) => handleNextPrev(value)}
            onChange={(newValue) => handleChange(newValue)}
            tileClassName={({ date, view }) => {
              if (
                events.find(
                  (x) =>
                    moment(x.date).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY") &&
                    x.type.value === "academic-calendar"
                )
              ) {
                return `academic-calendar`;
              } else if (
                events.find(
                  (x) =>
                    moment(x.date).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY") &&
                    x.type.value === "forum"
                )
              ) {
                return `forum`;
              } else if (
                events.find(
                  (x) =>
                    moment(x.date).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY") &&
                    x.type.value === "conference"
                )
              ) {
                return `conference`;
              } else if (
                events.find(
                  (x) =>
                    moment(x.date).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY") &&
                    x.type.value === "seminar"
                )
              ) {
                return `seminar`;
              } else if (
                events.find(
                  (x) =>
                    moment(x.date).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY") &&
                    x.type.value === "other"
                )
              ) {
                return `other`;
              } else if (
                events.find(
                  (x) =>
                    moment(x.date).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY") &&
                    x.type.value === "lecture"
                )
              ) {
                return `lecture`;
              } else if (
                events.find(
                  (x) =>
                    moment(x.date).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY") &&
                    x.type.value === "meeting"
                )
              ) {
                return `meeting`;
              } else if (
                events.find(
                  (x) =>
                    moment(x.date).format("DD-MM-YYYY") ===
                      moment(date).format("DD-MM-YYYY") &&
                    x.type.value === "celebration"
                )
              ) {
                return `celebration`;
              }
            }}
            // tileDisabled={({ date }) => date.getDay() === 0}
          />

          {showEvents && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: 342,
              }}
            >
              {LoadingEvents ? (
                <div className={classes["loading__container"]}>
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {eventsOfDay.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 270,
                        // padding: "0 20px",
                        paddingInlineEnd: 20,
                      }}
                    >
                      <p>{t("no_event_msg")}</p>
                    </div>
                  ) : (
                    <div className={classes.popUp}>
                      {eventsOfDay.map((event) => (
                        <EventCard event={event} />
                      ))}
                    </div>
                  )}
                </>
              )}
              {/* <div className={classes.closeBtn}>
                <button
                  onClick={() => setShowEvents(false)}
                  className={classes.btn}
                >
                  {t("close")}
                </button>
              </div> */}
            </div>
          )}
        </div>
        <div className={classes.badgesCont}>
          {!badgesLoading &&
            eventTypes?.map((type) => (
              <div
                className={classes.badge}
                style={{ backgroundColor: type.color }}
              >
                {type.text}
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
