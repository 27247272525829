import React from "react";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import classes from "./CourseGroups.module.css";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import Input from "../../../Shared/Components/Input/Input";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Search from "../../../../assets/icons/SearchHome.svg";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BorderBottom } from "@mui/icons-material";
import TraineeCard from "./TraineeCard/TraineeCard";
import BtnIcon from "../../../../assets/icons/Chevron-down.svg";
import MoveBtn from "../../../../assets/icons/send-svgrepo-com.svg";

export default function CourseGroups() {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <div className="container-fluid" style={{ marginTop: "3rem" }}>
      <MainBox className={classes.MainCont}>
        <MainBoxHead
          title={t("addnewGroup")}
          style={{ borderBottom: "none" }}
        />
        <div className={classes.groupName}>
          <Input placeholder={t("group_name")} label={t("group_name")} />
        </div>
        <div style={{ display: "flex" }}>
          <div className={classes.rightDiv}>
            <div className={classes.selectedCont}>
              <p>{t("selectedTrainee")}</p>
              <div className={classes.selected_btn}>
                <div className={classes.selctedCardsCont}>
                  <TraineeCard
                    selected={true}
                    name={"ايمن ابراهيم مصطفى"}
                    level={"المرحلة الاولى"}
                    img={
                      "https://qaseem-staging-be.tajahdev.dev/storage/user-manager/user-1707305872.png"
                    }
                  />
                  <TraineeCard
                    selected={true}
                    name={"ايمن ابراهيم مصطفى"}
                    level={"المرحلة الاولى"}
                    img={
                      "https://qaseem-staging-be.tajahdev.dev/storage/user-manager/user-1707305872.png"
                    }
                  />
                </div>
                <div className={classes.reversbtn}>
                  <div className={classes.movebtnCont}>
                    <img src={MoveBtn} alt="" />
                  </div>
                </div>
              </div>
              <div>
                <CustomButton
                  value={t("CreateGroup")}
                  colors="#03a2b2"
                  type="button"
                  icon={BtnIcon}
                />
              </div>
            </div>
          </div>
          <div className={classes.leftDiv}>
            <div className={classes.allTraineeTitle}>
              <p>{t("All_trainees")}</p>
            </div>
            <div className={classes.searchInput}>
              <img src={Search} alt="" />
              <input placeholder={t("search_by_name")} />
            </div>

            <div>
              <TraineeCard
                // selected={true}
                name={"ايمن ابراهيم مصطفى"}
                level={"المرحلة الاولى"}
                img={
                  "https://qaseem-staging-be.tajahdev.dev/storage/user-manager/user-1707305872.png"
                }
              />
              <TraineeCard
                // selected={true}
                name={"ايمن ابراهيم مصطفى"}
                level={"المرحلة الاولى"}
                img={
                  "https://qaseem-staging-be.tajahdev.dev/storage/user-manager/user-1707305872.png"
                }
              />
            </div>
          </div>
        </div>
      </MainBox>
    </div>
  );
}
