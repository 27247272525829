import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DatePickerWithIcon from "./../../../Components/Input/DatePickerWithIcon";
import Rating from '@mui/material/Rating';
import {SurveyTrainerContext} from './../../../Services/api/survey/SurveyTrainerContext'


import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Slider from '@material-ui/core/Slider';
import {createTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {Lang} from "./../../../utils";
import { arrayMove } from "@dnd-kit/sortable";


const muiTheme = createTheme({
  overrides:{
    MuiSlider: {
      thumb:{
      color: "#006d77",
      },
      track: {
        color: '#006d77'
      },
      rail: {
        color: 'black'
      }
    }
}
});
export default function SurveyTrainer(props){
    require('./survey.css')
    const surveyTrainerContext = useContext(SurveyTrainerContext)
    const { t, i18n } = useTranslation();
    const [dateOfSurvey, setDateOfSurvey] = useState("");
    const [rateValue, setRateValue] = useState("");
    const [isVerified, setVerified] = useState(false);
    const [modeTrainer, setModeTrainer] = useState(false);
    const [radio, setRadio] = useState("");
    const [check, setCheck] = useState("");
    const [checked, setChecked] = useState("");
    const [dropdown, setDropdown] = useState("");
    const [file, setFile] = useState("");
    const [text, setText] = useState("");
    const [email, setEmail] = useState("");
    const [questionFileId, setQuestionFileId] = useState("");
    const [emoji, setEmoji] = useState("");
    const [like, setLike] = useState("");
    const [borderSelectedLikeId, setBorderSelectedLikeId] = useState("");
    const [borderSelectedEmojiId, setBorderSelectedEmojiId] = useState("");
    const [singleText, setSingleText] = useState("");


    const [image, setImage] = useState("");
    const [calenderOfSurvey, setCalenderOfSurvey] = useState("");
    const [borderSelectedGraphicalId, setBorderSelectedGraphicalId] = useState("");
    const [dragAndDropQuestionId, setDragAndDropQuestionId] = useState("");
    const [textSlider, setTextSlider] = useState("");
    const [slideText,setSlideText] = useState([
    "Good",
    "Medium",
    "Very good",
    "excellent"
   ])
   const [numericSlider, setNumericSlider] = useState("");
   const [rankOrder, setRankOrder] = useState("");
   const [rankOrderArr,setRankOrderArr] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'])
   const [ordering,setOrdering] = useState("")
   const [questionIdOrdering,setQuestionIdOrdering] = useState("")
   const [orderingArr,setOrderingArr] = useState([])
   const [constantSumArr,setConstantSumArr] = useState(['Item 1', 'Item 2', 'Item 3', 'Item 4'])
   const [constantSum, setConstantSum] = useState("");
   const [dragndropArr,setDragNDropArr] = useState(['Item 1', 'Item 2', 'Item 3'])
   const [answers, setAnswers] = useState([{name:'test1'},{name:'test2'},{name:'test3'},{name:'test4'}]);
   const [dragAndDrop, setDragAndDrop] = useState("");

   const [answeredQuestions,setAnsweredQuestions] = useState([])
   console.log(props.questions)
   useEffect(()=>{
     let newArr = []
     console.log(props.questions)

     props.questions.map((item,index)=>{
       if(item.options.length != 0 && (item.type == "constant_sum" || item.type == "rank_order" || item.type =="contact_information")){
         var answers = item.options.map((item,index)=>({value:"",option_id:item.id}))
         var options = options = item.options.map((item,index)=>({value:item.value,id:item.id,label:item.value}))
        newArr.push({
         id:item.id,
         type:item.type,
        answers:answers,
        options:options,
        name:item.name
       })
       }else if(item.options.length != 0 && item.type == "ordering"){
        var answers = item.options.map((item,index)=>({value:"",option_id:item.id}))
         var options = item.options.map((item,index)=>({value:item.value,id:item.id,label:item.value}))
        newArr.push({
          id:item.id,
          type:item.type,
         answers:answers,
         options:options,
          name:item.name
        })
       }else if(item.options.length != 0 && item.type == "select_many"){
        var answers = item.options.map((item,index)=>({value:""}))
         var options = item.options.map((item,index)=>({value:item.value,id:item.id}))
        newArr.push({
          id:item.id,
          type:item.type,
         answers:answers,
         options:options,
          name:item.name
        })
       }else{
         newArr.push({
           id:item.id,
           type:item.type,
           answers:[{value:""}],
           options:item.options.map((item,index)=>({value:item.value,id:item.id})),
           name:item.name
         })
       }

     })
     setAnsweredQuestions(newArr)
     console.log(newArr)
   },[])
    const formatDate = (d) => {
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    };

    const formatTime = (t) => {
      let hours = "" + t.getHours(),
        minutes = "" + t.getMinutes(),
        seconds = "" + t.getSeconds();

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return [hours, minutes, seconds].join(":");
    };


    const dateSurveyChange = (input,id) => {
      let answers = []
      answeredQuestions.map((question,index)=>{
        if(question.id == id){
         question.answers.map((answer,i)=>{
            answer.value = formatDate(input) + " " + formatTime(input)
          })
        }
        answers.push(question)

     })
     setAnsweredQuestions(answers)
     surveyTrainerContext.setAnswersTrainer(answers)
     console.log(answeredQuestions)
    };

    const calenderSurveyChange = (input,id) => {
      let answers = []
      answeredQuestions.map((question,index)=>{
        if(question.id == id){
         question.answers.map((answer,i)=>{
            answer.value = formatDate(input) + " " + formatTime(input)
          })
        }
        answers.push(question)

     })
     setAnsweredQuestions(answers)
     surveyTrainerContext.setAnswersTrainer(answers)

     console.log(answeredQuestions)
    };

    const recaptchaLoaded = () => {};

    const verifyCallback = (response) => {
      if (response) {
        setVerified(!isVerified);
      }
    };

    const handleRadioMode = (e,id,answerId) => {
      let answers = []
      answeredQuestions.map((question,index)=>{
        if(question.id == id){
          question.answers.map((answer,index)=>{
            answer.value = answerId
          })
        }
        answers.push(question)

     })
     setAnsweredQuestions(answers)
     surveyTrainerContext.setAnswersTrainer(answers)

     console.log(answeredQuestions)
      setModeTrainer(e.target.value)
    }


    const handleCheckMode=(e,id,answerId)=>{
      console.log(answerId)
      var updatedList = [...checked];
      if (e.target.checked) {
        updatedList = [...checked, e.target.value];
      } else {
        updatedList.splice(checked.indexOf(e.target.value), 1);
      }
      setChecked(updatedList);

      let checkedArr = []
      for (let i = 0; i < updatedList.length; i++) {
        let obj={value : parseInt(updatedList[i])}
        checkedArr.push(obj)
      }
      let answers = []

      answeredQuestions.map((question,index)=>{
         if(question.id == id){
          question.answers = checkedArr
         }

         answers.push(question)

      })

      setAnsweredQuestions(answers)
      surveyTrainerContext.setAnswersTrainer(answers)

      console.log(answeredQuestions)
    }

    const handleChangeDropdown=(e,id)=>{
      let answers = []
      answeredQuestions.map((question,index)=>{
        if(question.id == id){
          question.answers.map((answer,index)=>{
            answer.value = parseInt(e.target.value)
          })
        }
        answers.push(question)

     })
     setAnsweredQuestions(answers)
     surveyTrainerContext.setAnswersTrainer(answers)

    }


    const handleChangeEmail=(e,id)=>{
    let answers = []
    answeredQuestions.map((question,index)=>{
      if(question.id == id){
        question.answers.map((answer,index)=>{
          answer.value = e.target.value
        })
      }
      answers.push(question)

   })
   setAnsweredQuestions(answers)
   surveyTrainerContext.setAnswersTrainer(answers)

    }

    const handleChangeDescription=(e,id)=>{
      let answers = []
      answeredQuestions.map((question,index)=>{
        if(question.id == id){
          question.answers.map((answer,index)=>{
            answer.value = e.target.value
          })
        }
        answers.push(question)

     })
     setAnsweredQuestions(answers)
     surveyTrainerContext.setAnswersTrainer(answers)

    }

    const handleChangeSingleText=(e,id)=>{
      let answers = []
      answeredQuestions.map((question,index)=>{
        if(question.id == id){
          question.answers.map((answer,index)=>{
            answer.value = e.target.value
          })
        }
        answers.push(question)

     })
     setAnsweredQuestions(answers)
     surveyTrainerContext.setAnswersTrainer(answers)

    }


    const handleChangeFile=(event,id)=>{
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let answers = []
        answeredQuestions.map((question,index)=>{
          if(question.id == id){
            question.answers.map((answer,index)=>{
              answer.value = file
            })
          }
          answers.push(question)

       })
       setAnsweredQuestions(answers)
       surveyTrainerContext.setAnswersTrainer(answers)

        setQuestionFileId(id)

      };
      reader.onerror = function (error) {
      };
    }

    const handleEmojis=(emoji,emojiId,id)=>{
      let answers = []
      answeredQuestions.map((question,index)=>{
        if(question.id == id){
          question.answers.map((answer,index)=>{
            answer.value = emoji
          })
        }
        answers.push(question)

     })
     setBorderSelectedEmojiId(emojiId)
     setAnsweredQuestions(answers)
     surveyTrainerContext.setAnswersTrainer(answers)

    }


    const handleGraphical=(image,imageId,id)=>{
      let answers = []
      answeredQuestions.map((question,index)=>{
        if(question.id == id){
         question.answers.map((answer,i)=>{
            answer.value = imageId
          })
        }
        answers.push(question)

     })
      setBorderSelectedGraphicalId(imageId)
     setAnsweredQuestions(answers)
     surveyTrainerContext.setAnswersTrainer(answers)

     console.log(answeredQuestions)

    }

    const handleLikes=(like,likeId,id)=>{
      let answers = []
      answeredQuestions.map((question,index)=>{
        if(question.id == id){
         question.answers.map((answer,i)=>{
            answer.value = like
          })
        }
        answers.push(question)

     })
     setBorderSelectedLikeId(likeId)

     setAnsweredQuestions(answers)
     surveyTrainerContext.setAnswersTrainer(answers)

    }

   const handleTextSlider =(e,value,options,questionId)=>{
    console.log(e.target.ariaValueNow)
    console.log(value)
    let answers = []
    answeredQuestions.map((question,index)=>{
      if(question.id == questionId){
        question.answers.map((answerText,index)=>{
        options.map((answer,i)=>{
        if(value == i + 1 ){
          answerText.value = answer.id
          }
        })
        })
      }
      answers.push(question)

   })
   setAnsweredQuestions(answers)
   surveyTrainerContext.setAnswersTrainer(answers)
  }


   const handleNumericSlider =(e,questionId)=>{
   let answers = []
    console.log(e.target.ariaValueNow)
    answeredQuestions.map((question,index)=>{
      if(question.id == questionId){
       question.answers.map((answer)=>{
           answer.value = e.target.ariaValueNow
        })
      }
      answers.push(question)

   })
   setAnsweredQuestions(answers)
        surveyTrainerContext.setAnswersTrainer(answers)
  }
  const handleConstantSumSlider =(e,option,options,questionId)=>{
    let answers = []

    answeredQuestions.map((question,index)=>{
       if(question.id == questionId){
        question.answers.map((answer)=>{
          if(answer.option_id == option){
            answer.value = e.target.ariaValueNow
          }

         })
       }
       answers.push(question)

    })

    setAnsweredQuestions(answers)
    console.log(answers)
    surveyTrainerContext.setAnswersTrainer(answers)
  }

  const handleChangeRankOrder =(options,itemId,e,questionId)=>{
    console.log(e.target.value)
    let answers = []
    answeredQuestions.map((question,index)=>{
      if(question.id == questionId){
       question.answers.map((answer)=>{
         if(answer.option_id == itemId){
           answer.value = e.target.value

         }

        })
      }
      answers.push(question)

   })

   setAnsweredQuestions(answers)
   console.log(answers)

   surveyTrainerContext.setAnswersTrainer(answers)
  }

 const onSortEnd = ({oldIndex, newIndex}) => {
  const newOrdering = arrayMove(orderingArr, oldIndex, newIndex);
  console.log(newOrdering)

  let answers = []
  answeredQuestions.map((question,index)=>{
    if(question.id == questionIdOrdering){
      question.answers = []
     question.answers = orderingArr
     console.log(orderingArr)
      question.answers.map((answer,index)=>{
       if(answer.id){
        //  answer.option_id = answer.id
        //  delete answer.id
        //  delete answer.label
         answer.value = index + 1
       }

      })
    }
    answers.push(question)

 })

 setAnsweredQuestions(answers)
 surveyTrainerContext.setAnswersTrainer(answers)

  };


  const SortableItem = SortableElement(({value}) => <li style={{padding:10,color:'#006d77',cursor:'pointer'}}>{value.label}</li>);

const SortableList = SortableContainer(({items,questionId}) => {
  console.log(items)
  setOrderingArr(items)
  setQuestionIdOrdering(questionId)
return (
  <ul style={{listStyleType:'none'}}>
    {items.map((item, index) => (
      <SortableItem key={`item-${item.id}`} index={index} value={item}  />
    ))}
  </ul>
);
});

function allowDrag(ev,questionId) {
ev.preventDefault();
}

function drop(ev,optionId,questionId) {
ev.preventDefault();
var texts = ev.dataTransfer.getData("text");
console.log(texts)
var data = document.getElementById(texts)
 ev.target.appendChild(data)
let answers = []
answeredQuestions.map((question,index)=>{
  if(question.id == dragAndDropQuestionId){
   question.answers.map((answer)=>{
       answer.value = texts
    })
  }
  answers.push(question)

})
setAnsweredQuestions(answers)
surveyTrainerContext.setAnswersTrainer(answers)

console.log(answeredQuestions)
}

function allowDrog(ev,questionId) {
setDragAndDropQuestionId(questionId)
console.log('test')
if(dragAndDrop == ""){
  ev.dataTransfer.setData("text", ev.target.id);
}else{
  alert('error')
}
}


const handleChangeRate=(e,value,id)=>{
let answers = []
answeredQuestions.map((question,index)=>{
  if(question.id == id){
   question.answers.map((answer)=>{
       answer.value = value
    })
  }
  answers.push(question)

})
setAnsweredQuestions(answers)
surveyTrainerContext.setAnswersTrainer(answers)

console.log(answeredQuestions)
}




    const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + ", " + item;
      })
    : "";

      var isChecked = (item) =>
      checked.includes(item) ? "checked-item" : "not-checked-item";

   return(
       <>
       <div>
       {answeredQuestions.map((question,index)=>(
        <div key={index} className="survey-card-container-trainer">
           <p className="survey-card-title-trainer">{question.name} ؟</p>
               {question.type == 'select_one' ? (
                        <>
                        {question.options.map((item,index)=>(
                             <div className="input_radio_container_2">
                             <input
                               className="form-check-input shape_radio_2"
                               type="radio"
                               name="inlineRadioOptions1"
                               id={item.id}
                               value={item.id}
                               checked={modeTrainer == item.id}
                               onChange={(e)=>handleRadioMode(e,question.id,item.id)}
                             />
                             <label
                               className="form-check-label-profile_2"
                               for={item.id}
                             >
                             <span></span>
                             </label>
                             <span className="label-radio-2">{item.value}</span>
                             </div>
                        ))}
                        </>
               ) :
                question.type == 'select_many' ? (
                    <>
                    {question.options.map((item,index)=>(
                             <div
                             className="input_Check_container"
                             >

                             <input
                               type="checkbox"
                               value={item.id}
                               onChange={(e)=>handleCheckMode(e,question.id,item.id)}
                             />
                             <span className={isChecked(item.id)}>{item.value}</span>
                             </div>

                    ))}
                    </>
                ) :
                question.type == 'star_rating' ? (
                    <Rating
                    name={`${question.value}`}
                    className={i18n.language == Lang.AR ? "rateStyle" : ''}
                    value={rateValue[`${question.value}`]}
                    onChange={(e,newValue)=>handleChangeRate(e,newValue,question.id)}
                  />
                ):
                question.type == 'single_choice' ?     <select
                id="dropdown"
                name="dropdown"
                value={question.value}
                onChange={(e)=>handleChangeDropdown(e,question.id)}
                placeholder={t("survey.trainee.dropdown")}
                className="dropdown-survey"
              >
                <option value='0'>{t("survey.trainee.select")}</option>
                {question.options.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  );
                })}
              </select> :
                question.type == 'text' ?
              <textarea
              id="description"
              className="description"
              key={index}
              placeholder={t("survey.trainee.description")}
              name={`${question.name}`}
              value={text[`${question.name}`]}
              onChange={(e)=>handleChangeDescription(e,question.id)}
            />: question.type == 'string' ?
            <input
            className="email-survey"
            id="singleText"
            type="text"
            name={`${question.name}`}
            value={singleText[`${question.name}`]}
            onChange={(e)=>handleChangeSingleText(e,question.id)}
            placeholder={t("survey.trainee.smallDescription")}
            />
               :
                question.type == 'date' ? (
                    <div className="date-container">
                    <DatePickerWithIcon
                          name={`${question.value}`}
                          initialValue={dateOfSurvey[`${question.value}`]}
                          setContent={(input)=>dateSurveyChange(input,question.id)}
                          icon={<i style={{fontSize:25,color:'#ccc'}} className="fal fa-calendar-alt"></i>}
                          type="survey"
                    />
                    </div>
                ) :
                question.type == 'thumbs_rating' ? (
                    <div className="row">
                        {question.options.map((item,index)=>(
                        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-4 like-container" style={{border:item.id == borderSelectedLikeId ? '1px solid #006d77' : '1px solid transparent'}} onClick={()=>handleLikes(item.value,item.id,question.id)}>
                        <span className="text-like" style={{fontSize:30}}>{item.value}</span>
                        </div>
                        ))}
                    </div>
                ) :
                question.type == 'smiley_rating' ? (
                    <div className="row">
                    {question.options.map((item,index)=>(
                    <div className="col-lg-2 col-md-2 col-sm-3 col-xs-4 shape-container" style={{border:item.id == borderSelectedEmojiId ? '1px solid #006d77' : '1px solid transparent'}} onClick={()=>handleEmojis(item.value,item.id,question.id)}>
                    <span className="text-shape" style={{fontSize:30}}>{item.value}</span>
                    </div>
                    ))}
                    </div>
                ) :
                question.type == 'email' ?
                <input
                className="email-survey"
                id="email"
                type="email"
                name={`${question.name}`}
                value={email[`${question.name}`]}
                onChange={(e)=>handleChangeEmail(e,question.id)}
                placeholder={t("survey.trainee.email")}
              /> :
                question.type == 'single_file' ? (
                    <div>
                            <input
                                  type="file"
                                  name={`${question.value}`}
                                  id="file"
                                  className="inputfile"
                                  accept=".png,.jpg,.jpeg,.doc,.docx,application/pdf"
                                  value={file[`${question.value}`]}
                                  onChange={(event) => handleChangeFile(event,question.id)}
                                />
                                <label for="file" className="labelFile">
                                    {t("chooseFile")}
                                  {"     "}
                                </label>
                    </div>

                ) :
                question.type == 'text_slider' ? (
                  <div style={{width:'100%'}}>
                  <div style={{display:'flex',justifyContent:'space-between',width:'70%'}}>{i18n.language == Lang.AR ?  question.options.reverse().map((item,index)=>(<span>{item.value}</span>)) : question.options.map((item,index)=>(<span>{item.value}</span>))}</div>
                  <ThemeProvider theme={muiTheme}>
      <Slider style={{width:"70%"}} min={1} max={question.options.length}
      onChange={(e,value)=>handleTextSlider(e,value,question.options,question.id)}
       defaultValue={1} />
    </ThemeProvider>
                  </div>
                   ): question.type == 'constant_sum' ? (
                  <div style={{width:'100%'}}>
                  {question.options.map((item,index)=>(
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:'2%'}}>
                    <span>{item.label}</span>
                    <ThemeProvider theme={muiTheme}>
      <Slider style={{width:"70%"}} onChange={(e,value)=>handleConstantSumSlider(e,item.id,question.options,question.id)}
       value={constantSum[`${item.value}`]} name={`${item.value}`} min={1} max={10} defaultValue={1} />
    </ThemeProvider>
                    </div>
                  ))}
                  </div>
                ) : question.type == 'numeric_slider' ? (
                  <div style={{width:'100%'}}>
                  <ThemeProvider theme={muiTheme}>
      <Slider style={{width:"70%"}}  onChange={(e)=>handleNumericSlider(e,question.id)}
 min={1} max={10} defaultValue={1}
  />
    </ThemeProvider>
                  </div>
                   ):question.type == 'calender' ? (
                    <div className="date-container">
                    <DatePickerWithIcon
                          name={`${question.value}`}
                          initialValue={calenderOfSurvey[`${question.value}`]}
                          setContent={(input)=>calenderSurveyChange(input,question.id)}
                          icon={<i style={{fontSize:25,color:'#ccc'}} className="fal fa-calendar-alt"></i>}
                          type="survey"
                    />
                    </div>


                 ) : question.type == 'drag_and_drop' ? (
                   <>
                        <div className={"row"}>
                            <div style={{display:'flex',width:100,height:50,border:'2px dashed black',backgroundColor:'white',alignItems:'center',justifyContent:'center'}} className={"tajah-drag-div"} id={"drops"} onDrop={(e) => drop(e)}
                                 onDragOver={(e) => allowDrag(e)} draggable="true" onDragStart={(e) => allowDrog(e)}></div>
            </div>
            <div style={{width:300,height:100,border:'0px solid black',marginTop:'3%'}} className={"row"}>
                {question.options.map((item, index) => {
                    return (
                        <>
                            <div className={"tajah-drag-div"} id={item.id} draggable="true"
                                 onDragStart={(e) => allowDrog(e,question.id)} onDrop={(e)=>drop(e,item.id,question.id)} onDragOver={(e) => allowDrag(e,question.id)}>
                             <span style={{padding:10,borderWidth:1,cursor:'pointer'}}>{item.value}</span>
                            </div>

                        </>
                    );
                })}
            </div>
            </>
               ) : question.type == "rank_order" ? (
<div>
          <ul style={{listStyleType:"none"}}>
      {question.options.map((item, index) => (
          <li>
            <input
            className="email-survey"
            style={{width:50,borderWidth:1,borderColor:'#006d77'}}
            id="rankorder"
            type="number"
            name={`${item}`}
            value={rankOrder[`${item}`]}
            onChange={(e)=>handleChangeRankOrder(question.options,item.id,e,question.id)}
          />
          <span style={{marginRight:'5%'}}>{item.label}</span>
          </li>
      ))}
    </ul>

          </div>
               ):question.type == "ordering" ? (
                  <SortableList items={question.options} questionId={question.id} onSortEnd={onSortEnd} />
               ):question.type == 'graphical_rating' ? (
                  <div className="row">
                  {question.options.map((item,index)=>(
                  <div className="col-lg-4 col-md-4 col-sm-3 col-xs-4 shape-container" style={{border:item.id == borderSelectedGraphicalId ? '1px solid #006d77' : '1px solid transparent',marginLeft:'5%',marginTop:'5%'}} onClick={()=>handleGraphical(item.value,item.id,question.id)}>
                  <img src={item.value} width={'100%'} height={100}/>
                  </div>
                  ))}
                  </div>
                 ) : question.type == 'contact_information' ? (
                  <div className="row">
                  </div>
                 ):
                question.type == 'section' ? <SurveyTrainer quations={question.children} title={question.title}/> :
                question.type == 'captcha' ? (
                  <p></p>
                ) : null}

       </div>
       ))}
       </div>
       </>
   )
}
