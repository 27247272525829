import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { offerConstant } from "../../Constants/offer/offer.constant";
import env from "../../../Services/api/Environment";
import { toast } from "react-toastify";

export function getOfferList(filter) {
  return (dispatch) => {
    dispatch({
      type: offerConstant.START_LOADING,
    });
    connector
      .get(env.get_offers, {
        params: {
          ...filter,
        },
      })
      .then((res) => {
        dispatch({
          type: offerConstant.GET_OFFER,
          payload: res,
        });
        dispatch({
          type: offerConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
        dispatch({
          type: offerConstant.STOP_LOADING,
        });
      });
  };
}

export function getOfferById(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.get_offers_by_id, { id: id }))
      .then((res) => {
        dispatch({
          type: offerConstant.GET_OFFER_BY_ID,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
      });
  };
}

export const postOfferStatus = (id) => {
  console.log(id);
  return async (dispatch) => {
    await connector
      .post(URLBuilder(env.offer_status, { id: id }))
      .then((res) => {
        dispatch({
          type: offerConstant.POST_OFFER_STATUS,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export function deleteOffer(id) {
  return (dispatch) => {
    connector
      .delete(URLBuilder(env.delete_offers, { id: id }))
      .then((res) => {
        dispatch({
          type: offerConstant.DELETE_OFFER,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
      });
  };
}

export function resetOffer() {
  return (dispatch) => {
    dispatch({
      type: offerConstant.RESET,
    });
  };
}
