import { toast } from "react-toastify";
import env from "../../Services/api/Environment";
import connector from "../../Services/api/Handler";
import { default as URLBuilder, default as urlBuilder } from "../../Services/api/UrlBuilder";
import { searchConstants } from "../Constants/search.constants";

export const getSearchData = ({
  tab,
}) => {
  return (dispatch) => {
    dispatch({ type: searchConstants.GET_SEARCH_DATA_REQUEST });
    connector
      .get(tab ? URLBuilder(env.search_data_tab, { tab }) : env.search_data)
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          if(tab) {
            dispatch({
              type: searchConstants.GET_SEARCH_DATA_SUCCESS,
              payload: {
                search: response.data.tables,
              },
            });
          } else {
            dispatch({
              type: searchConstants.GET_SEARCH_Tabs_SUCCESS,
              payload: {
                searchTabs: response.data.tables.tables,
              },
            });
          }
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: searchConstants.GET_SEARCH_DATA_FAILURE,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: searchConstants.GET_SEARCH_DATA_FAILURE,
          payload: err,
        });
      });
  };
};

// SEND SEARCH
export const sendSearch = ({ page = 1, perPage = 10, data, resetSearchHandler }) => {
  return async (dispatch) => {
    dispatch({ type: searchConstants.SEND_SEARCH_REQUEST });
    await connector
      .get(env.search, {
        params: {
          page,
          perPage,
          ...data,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.status) {
          dispatch({
            type: searchConstants.SEND_SEARCH_SUCCESS,
            payload: {
              columns: response?.data?.data?.columns,
              list: response?.data?.data?.data,
              meta: response?.data?.data?.meta,
            },
          });
        } else {
          dispatch({
            type: searchConstants.SEND_SEARCH_FAILURE,
            payload: response.data.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: searchConstants.SEND_SEARCH_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        resetSearchHandler();
      });
  };
};

// GET SAVED SEARCHES
export const getSavedSearches = ({ page = 1, perPage = 10 }) => {
  return (dispatch) => {
    dispatch({ type: searchConstants.GET_SAVED_SEARCHES_REQUEST });
    connector
      .get(env.saved_searches, {
        params: {
          page,
          perPage,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: searchConstants.GET_SAVED_SEARCHES_SUCCESS,
            payload: {
              savedSearches: response.data.data.history.data,
              meta: response.data.data.history.meta,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: searchConstants.GET_SAVED_SEARCHES_FAILURE,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: searchConstants.GET_SAVED_SEARCHES_FAILURE,
          payload: err,
        });
      });
  };
};

// GET SAVED SEARCH DETAILS
export const getSavedSearchDetails = ({ id, page = 1, perPage = 10, history }) => {
  return (dispatch) => {
    dispatch({ type: searchConstants.GET_SAVED_SEARCH_DETAILS_REQUEST });
    connector
      .get(URLBuilder(env.saved_search_details, { id: id }), {
        params: {
          page,
          perPage,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: searchConstants.GET_SAVED_SEARCH_DETAILS_SUCCESS,
            payload: {
              list: response.data.data.data,
              columns: response.data.data.columns,
              meta: response.data.data.meta,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: searchConstants.GET_SAVED_SEARCH_DETAILS_FAILURE,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          return history.push("/admin/page-not-found");
        }
        dispatch({
          type: searchConstants.GET_SAVED_SEARCH_DETAILS_FAILURE,
          payload: err,
        });
      });
  };
};

// DELETE SAVED SEARCH
export const deleteSavedSearch = ({ id, callback }) => {
  return (dispatch) => {
    dispatch({ type: searchConstants.DELETE_SAVED_SEARCH_REQUEST });
    connector
      .delete(URLBuilder(env.update_saved_search, { id: id }))
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: searchConstants.DELETE_SAVED_SEARCH_SUCCESS,
            payload: id,
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: searchConstants.DELETE_SAVED_SEARCH_FAILURE,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: searchConstants.DELETE_SAVED_SEARCH_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        callback();
      });
  };
};

// UPDATE SAVED SEARCH
export const updateSavedSearch = ({ id, callback, name, report, emails }) => {
  return (dispatch) => {
    dispatch({ type: searchConstants.UPDATE_SAVED_SEARCH_REQUEST });
    connector
      .post(URLBuilder(env.update_saved_search, { id: id }), {
        name: name,
        report: report,
        emails: emails,
      })
      .then((response) => {
        console.log("response", response);
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: searchConstants.UPDATE_SAVED_SEARCH_SUCCESS,
            payload: {
              id: id,
              name: name,
              report: report,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: searchConstants.UPDATE_SAVED_SEARCH_FAILURE,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <>
            <span
              style={{
                fontSize: 13,
                fontWeight: "bold",
                display: "block",
                paddingBottom: "10px",
              }}
            >
              {err.response.data.msg}
            </span>
            <ul>
              {Object.keys(err?.response?.data?.errors).map((key) => {
                return (
                  <li>
                    <span style={{ fontSize: 11 }}>{err?.response?.data?.errors[key]}</span>
                  </li>
                );
              })}
            </ul>
          </>
        );
        dispatch({
          type: searchConstants.UPDATE_SAVED_SEARCH_FAILURE,
          payload: err,
        });
      })
      .finally(() => {
        callback();
      });
  };
};
export const resetSearch = () => {
  return (dispatch) => {
    dispatch({ type: searchConstants.RESET_SEARCH });
  };
};

// GET FREE SEARCH
export const getFreeSearch = ({ page = 1, perPage = 10, searchText }) => {
  return (dispatch) => {
    dispatch({ type: searchConstants.GET_FREE_SEARCH_REQUEST });
    connector
      .get(env.free_search, {
        params: {
          page,
          perPage,
          search_text: searchText,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: searchConstants.GET_FREE_SEARCH_SUCCESS,
            payload: {
              list: response.data.data.data,
              meta: response.data.data.meta,
              statistics: response.data.data.dashboard,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: searchConstants.GET_FREE_SEARCH_FAILURE,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: searchConstants.GET_FREE_SEARCH_FAILURE,
          payload: err,
        });
      });
  };
};

// RESET FREE SEARCH
export const resetFreeSearch = () => {
  return (dispatch) => {
    dispatch({ type: searchConstants.RESET_FREE_SEARCH });
  };
};
