export const eventsListConstants = {
  // GET ALL  EVENTS LIST
  GET_EVENTS_LIST_REQUEST: "GET_EVENTS_LIST_REQUEST",
  GET_EVENTS_LIST_SUCCESS: "GET_EVENTS_LIST_SUCCESS",
  GET_EVENTS_LIST_REJECTED: "GETEVENTS_LIST_REJECTED",

  // CANCEL EVENT
  CANCEL_EVENT_REQUEST: "CANCEL_EVENT_REQUEST",
  CANCEL_EVENT_SUCCESS: "CANCEL_EVENT_SUCCESS",
  CANCEL_EVENT_REJECTED: "CANCEL_EVENT_REJECTED",
};
