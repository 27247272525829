import React, { useState } from "react";
import { DndContext, useDraggable } from "@dnd-kit/core";

import { Draggable, DraggableOverlay, Droppable } from "./components";
import { ReactComponent as PhotoIcon } from "../../../../assets/icons/photo.svg";
import { useTranslation } from "react-i18next";

export function MultipleDroppables({ options, items, answers, setAnswers }) {
  const { t } = useTranslation();
  const [dragging, setDragging] = useState(null);

  return (
    <DndContext
      onDragStart={({ active }) => setDragging(active.id)}
      onDragEnd={({ over, active }) => {
        if (over) {
          let newAnswers = [...answers];
          const findAns = newAnswers.findIndex(
            (ans) => ans.answer == active.id
          );
          const findOp = newAnswers.findIndex(
            (ans) => ans.option_id == over.id
          );
          if (findOp < 0) {
            if (findAns >= 0) {
              newAnswers.splice(findAns, 1);
            }
            newAnswers.push({ option_id: over.id, answer: active.id });
          } else {
            if (findAns >= 0) {
              newAnswers[findAns].answer = newAnswers[findOp].answer;
            }
            newAnswers[findOp].answer = active.id;
          }
          setAnswers(newAnswers);
          setDragging(null);
        }
      }}
      onDragCancel={() => setDragging(null)}
    >
      <div className="tw-pe-4 tw-space-y-4">
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
          {options.map((option) => (
            <div className="tw-flex tw-flex-col tw-space-y-4 tw-w-full tw-aspect-square">
              <div className="tw-w-full tw-border tw-border-dashed tw-border-gray-300 tw-aspect-[4/3] tw-justify-between">
                <Droppable key={option.id} id={option.id} dragging={!!dragging}>
                  {answers.find((ans) => ans.option_id == option.id) ? (
                    <DraggableItem
                      link={
                        answers.find((ans) => ans.option_id == option.id).answer
                      }
                    />
                  ) : (
                    <div className="tw-flex tw-flex-col tw-items-center tw-space-y-4 tw-p-4">
                      <PhotoIcon className="tw-h-8" />
                      <div className="tw-text-gray-400">{t("add_image")}</div>
                    </div>
                  )}
                </Droppable>
              </div>
              <div
                className={`tw-w-full tw-text-gray-400 tw-text-center tw-border tw-rounded tw-p-2.5 ${
                  answers.find((ans) => ans.option_id == option.id)
                    ?.flagColor ?? "tw-border-gray-200"
                }`}
              >
                {option.value}
              </div>
            </div>
          ))}
        </div>

        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
          {items
            .filter((item) => !answers.find((ans) => ans.answer == item))
            .map((item, index) => (
              <DraggableItem key={index} link={item} />
            ))}
        </div>
      </div>
      <DraggableOverlay link={dragging} />
    </DndContext>
  );
}

function DraggableItem({ handle, link }) {
  const { isDragging, setNodeRef, listeners } = useDraggable({
    id: `${link}`,
  });

  return (
    <Draggable
      dragging={isDragging}
      ref={setNodeRef}
      handle={handle}
      link={link}
      listeners={listeners}
      style={{
        opacity: isDragging ? 0 : undefined,
      }}
    />
  );
}
