import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowIcon from "../../../../../assets/icons/arrow.png";
import HomeIcon from "../../../../../assets/icons/home.svg";
import i18n from "../../../../../i18n/i18n";
import { Lang } from "../../../../../utils";
import QuestionTypes from "../../../../Admin/Assessments/QuestionTypes";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import H5pQuestionView from "../QuestionsTypes/H5pQuestionView";
import HTMLQuestionView from "../QuestionsTypes/HTMLQuestionView";
import HotSpotQuestionView from "../QuestionsTypes/HotSpotQuestionView";
import ScormQuestionView from "../QuestionsTypes/ScormQuestionView";
import XApiQuestionView from "../QuestionsTypes/XApiQuestionView";
import classes from "./ListExams.module.css";
import {
  answerLessonActivity,
  getLessonActivity,
} from "../../../../../Services/api/courses/courseProvider";
import { saveWatchingProgress } from "../../../../../Redux/Actions/courses/coursesList.action";
import { useDispatch } from "react-redux";

function LessonActivityView({ role }) {
  require("./exams.css");
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    id: courseId,
    chapterId,
    supChapterId,
    lessonId,
    activityId,
  } = useParams();
  const fromRef = useRef();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [activity, setActivity] = useState({});
  const [isCourseClosed, setIsCourseClosed] = useState(true);

  useEffect(() => {
    getLessonActivity(activityId).then((res) => {
      if (res.status && res.status === 200 && res.data.status) {
        setActivity(res.data.activity);
        setIsCourseClosed(
          res.data.activity.course_status === "Closed" ||
            res.data.activity.course_status === "مغلقة"
        );
        !role && saveWatchingProgressAPI(res.data.activity);
      }
    });
  }, []);
  const saveWatchingProgressAPI = async (activity) => {
    try {
      if (activity?.is_watched) {
        return;
      }
      const payload = {
        course_id: courseId,
        lesson_id: lessonId,
        // sub_chapter_id: supChapterId,
        // chapter_id: chapterId,
        watchable_id: activity.id,
        watchable_type: "activity",
      };
      console.log(payload);
      dispatch(saveWatchingProgress(payload));
    } catch (err) {
      console.log("ERROR");
      console.log(err);
    }
  };

  // const breadcrumbList = [
  //   {
  //     id: "home",
  //     page: <img src={HomeIcon} alt="" />,
  //     pagePath: "/",
  //   },
  //   {
  //     id: t("Courseoutline"),
  //     page: t("Courseoutline"),
  //     pagePath: `/training-courses/${courseId}/content/${chapterId}/${supChapterId}/lessons`,
  //   },
  //   {
  //     id: 1,
  //     page: activity.title,
  //     active: true,
  //   },
  // ];

  const QuestionTypes = [
    {
      type: "hotspot",
      viewComponent: HotSpotQuestionView,
    },
    {
      type: "h5p",
      viewComponent: H5pQuestionView,
    },
    {
      type: "html",
      viewComponent: HTMLQuestionView,
    },
    {
      type: "scorm",
      viewComponent: ScormQuestionView,
    },
    {
      type: "xapi",
      viewComponent: XApiQuestionView,
    },
  ];
  const ViewComponent = QuestionTypes.find(
    (questionType) => activity.type === questionType.type
  )?.viewComponent;

  return (
    <div className="container-fluid">
      {/* <div className="row">
        <div className="col-12 sm:tw-px-2 tw-py-8">
          <Breadcrumb list={breadcrumbList} />
        </div>
      </div> */}

      {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
      {activity && activity.id ? (
        <>
          <Formik
            innerRef={(f) => (fromRef.current = f)}
            initialValues={{
              answer: [],
            }}
            onSubmit={async (values, { setErrors }) => {
              let data = {
                activity_id: activity.id,
                answer: values.answer,
              };
              try {
                const res = await answerLessonActivity(activity.id, data);

                if (res.status && res.status === 201 && res.data.status) {
                  history.goBack();
                  toast.success(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                      {t("already_answerd")}
                    </span>
                  );
                } else {
                  throw res;
                }
              } catch (error) {
                toast.error(
                  <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    لم نتمكن من تسجيل اجاباتك
                  </span>
                );
              }
            }}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};

              if (Object.keys(errors).length) {
                console.log({ values, errors });
              }
              return errors;
            }}
            validateOnChange={hasSubmitted}
          >
            {({
              setFieldValue,
              setValues,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handlevalidate,
              isSubmitting,
              resetForm,
              submitForm,
              validateForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8 tw-mt-8">
                  <div
                    className={classes["back"]}
                    onClick={() => history.goBack()}
                  >
                    <div className={classes["back-icon"]}>
                      <img src={ArrowIcon} alt="back" />
                    </div>
                    <div className={classes["back-text"]}>{t("back")}</div>
                  </div>
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2">
                      <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2 tw-text-lg sm:tw-text-2xl tw-font-bold tw-text-teal-600">
                        <div>{`${activity.title}`}</div>
                        {/* <div>{`(${activity.type})`}</div> */}
                      </div>
                    </div>
                  </div>
                  <div className="tw-overflow-x-auto inner-scrollbar tw-pb-4">
                    <div className="tw-space-y-4">
                      <div
                        className="tw-max-w-full"
                        dangerouslySetInnerHTML={{ __html: activity.content }}
                      />
                      <ViewComponent
                        key={activity.id}
                        question={activity}
                        values={values}
                        setFieldValue={setFieldValue}
                        isActivity={true}
                      />
                    </div>
                  </div>

                  <div className="tw-flex tw-items-center tw-justify-between">
                    <div className="tw-flex tw-items-center tw-space-s-6">
                      <button
                        disabled={
                          isSubmitting ||
                          activity.my_answered_count >=
                            activity.answer_attempts ||
                          isCourseClosed ||
                          role
                        }
                        type="submit"
                        className="tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-py-2 tw-px-16 tw-rounded tw-text-white"
                      >
                        {activity.my_answered_count >= activity.answer_attempts
                          ? t("already_answerd")
                          : t("submit_answer")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      )}
    </div>
  );
}

export default LessonActivityView;
