import React from "react";
import classes from "./contactUsUpper.module.css";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import Bg from "../../../assets/image/bgcontactus.svg";
import SideImge from "../../../assets/image/Group 4.svg";
import Phone from "../../../assets/icons/phoneContact.svg";
import LocationIcon from "../../../assets/icons/locationContact.svg";
import WhatsAppIcon from "../../../assets/icons/whatsappContact.svg";
import { useTranslation } from "react-i18next";
export default function ContactUsUpper() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <MainBox
        style={{
          backgroundImage: `url(${Bg})`,
        }}
      >
        <div className={classes.main_contact}>
          <div className={classes.imgDiv}>
            <img src={SideImge} alt="" />
          </div>
          <div className={classes.contentDiv}>
            <div className={classes.title}>
              <p>{t("Getintouch")}</p>
            </div>
            <p className={classes.desc}>
              some long text need to be added some long text need to be added
              some long text need to be added
            </p>
            <div className={classes.contactInfo}>
              <div className={classes.icon_text}>
                <img src={Phone} alt="" />
                <p>{t("Contact_number")} +966 (16) 3011111</p>
              </div>
              <div className={classes.icon_text}>
                <img src={WhatsAppIcon} alt="" />
                <p>{t("WhatsApp")} +966 (16) 3011111 </p>
              </div>
              <div
                className={classes.icon_text}
                style={{ alignItems: "start" }}
              >
                <img src={LocationIcon} alt="" />
                <p>{t("location")}</p>
              </div>
            </div>
          </div>
        </div>
      </MainBox>
    </>
  );
}
