import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchPeymentRequestsList } from "../../../../Redux/Actions/peymentRequest/peymentRequest.action";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { exportPeymentRequests } from "../../../../Services/api/PeymentRequests";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import PeymentsRequestFilter from "./PeymentRequestFilter";
import PeymentRequestTable from "./PeymentsRequestTable";
import { filterActions } from "./PeymentsRequestTableActions";
import classes from "./peymentRequest.module.css";

const PeymentsRequest = ({ role, isProgram, programCourses, inProgram, notInProgram, status }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);

  const { peymentRequestsMeta, peymentRequests } = useSelector((state) => state.peymentRequestsReducer);

  const [showFilter, setShowFilter] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const [filterData, setFilterData] = useState({
    is_program: "",
    course_id: "",
    program_id: "",
    course_run_serial_number: "",
    status: "",
    start_date: "",
    end_date: "",

    course_code: "",
    program_code: "",
  });

  const userId = localStorage.getItem("userId");

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "private_profile",
      page: t("sidebar.label.private_profile"),
      pagePath: "/private-profile",
    },
    {
      id: `peyment_request`,
      page: t("private_profile.financial_request"),
      pagePath: "#",
      active: true,
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      is_program: "",
      course_id: "",
      program_id: "",
      run_serial_number: "",
      status: "",

      course_code: "",
      program_code: "",
      start_date: "",
      end_date: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = { role: role === "manager" ? "manager" : "trainer" };
    filterData.status && (filter.status = filterData.status);
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);
    filterData.is_program && (filter.is_program = filterData.is_program - 1);
    filterData.run_serial_number &&
      filterData.is_program == 1 &&
      (filter.run_serial_number = filterData.run_serial_number);
    (filterData.course_id || filterData.program_id) &&
      (filterData.is_program == 2
        ? (filter.program_id = filterData.program_id)
        : (filter.course_id = filterData.course_id));
    (filterData.course_code || filterData.program_code) &&
      (filterData.is_program == 1
        ? (filter.course_code = filterData.course_code)
        : (filter.program_code = filterData.program_code));

    return filter;
  };
  /////////////////////////////////////
  const getPeymentRequestsHandler = () => {
    const filter = getFilterData();
    console.log(filter);
    dispatch(
      fetchPeymentRequestsList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
  };
  ///////////////////////////

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      owner: userId,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { not_in_program: 1 } : {}),
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      })
      .finally(() => {
        setIsCoursesLoading(false);
        setIsProgramsLoading(false);
      });
  };

  useEffect(() => {
    getAllCoursesProgramsHandler({
      is_program: false,
    });
    getAllCoursesProgramsHandler({
      is_program: true,
    });
  }, []);

  useEffect(() => {
    getPeymentRequestsHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      await exportPeymentRequests(filter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  return (
    <div className={classes["courses-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["courses-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("private_profile.financial_request")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    addAction: () => {
                      history.push(
                        `${
                          role === "manager" ? "/manager" : role === "trainer" ? "/trainer" : "private-profile"
                        }/payments-requests/add`
                      );
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <PeymentsRequestFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getPeymentRequestsHandler}
                    resetFilterHandler={resetFilterHandler}
                    courses={courses}
                    programs={programs}
                    role={role}
                  />
                )}

                <PeymentRequestTable userId={userId} />
                <Pagination
                  count={peymentRequestsMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeymentsRequest;
