import { boothConstants } from "../../Constants/Booth/booth.constants";

const initialState = {
  loading: false,
  createBoothLoading: false,
  updateBoothDraftLoading: false,
  exhibition: {},
  positions: [],
  prices: {},
  createBoothResponse: {},
  updateBoothDraftResponse: {},
  addBoothToCartResponse: {},
  payAddBoothResponse: {},
  error: null,
  booth: {},
  boothLoading: false,
  boothError: null,

  boothVisitors: [],
  boothVisitorsLoading: false,
  boothVisitorsError: null,

  approveBoothLoading: false,
  approveBooth: false,
  approveBoothError: false,
  approveBoothMsg: "",

  rejectBoothLoading: false,
  rejectBooth: false,
  rejectBoothError: null,
  rejectBoothMsg: "",

  approveDraftBoothLoading: false,
  approveDraftBooth: false,
  approveDraftBoothError: false,
  approveDraftBoothMsg: "",

  rejectDraftBoothLoading: false,
  rejectDraftBooth: false,
  rejectDraftBoothError: false,
  rejectDraftBoothMsg: "",
};

export default function boothReducer(state = initialState, action) {
  switch (action.type) {
    // GET EXHIBITION
    case boothConstants.GET_EXHIBITION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case boothConstants.GET_EXHIBITION_SUCCESS:
      return {
        ...state,
        loading: false,
        exhibition: action.payload.exhibition,
        positions: action.payload.positions,
        prices: action.payload.prices,
      };

    case boothConstants.GET_EXHIBITION_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // CREATE BOOTH
    case boothConstants.CREATE_BOOTH_REQUEST:
      return {
        ...state,
        createBoothLoading: true,
      };

    case boothConstants.CREATE_BOOTH_SUCCESS:
      return {
        ...state,
        createBoothLoading: false,
        createBoothResponse: action.payload,
      };

    case boothConstants.CREATE_BOOTH_REJECTED:
      return {
        ...state,
        createBoothLoading: false,
        error: action.payload,
      };

    // UPDATE BOOTH
    case boothConstants.UPDATE_BOOTH_DRAFT_REQUEST:
      return {
        ...state,
        updateBoothDraftLoading: true,
      };

    case boothConstants.UPDATE_BOOTH_DRAFT_SUCCESS:
      return {
        ...state,
        updateBoothDraftLoading: false,
        updateBoothDraftResponse: action.payload,
      };

    case boothConstants.UPDATE_BOOTH_DRAFT_REJECTED:
      return {
        ...state,
        updateBoothDraftLoading: false,
        error: action.payload,
      };

    // ADD BOOTH TO CART
    case boothConstants.ADD_BOOTH_TO_CART_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case boothConstants.ADD_BOOTH_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        addBoothToCartResponse: action.payload,
      };

    case boothConstants.ADD_BOOTH_TO_CART_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // PAY ADD BOOTH WITH BANK TRANSFER
    case boothConstants.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case boothConstants.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        payAddBoothResponse: action.payload,
      };

    case boothConstants.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // GET BOOTH
    case boothConstants.GET_BOOTH_REQUEST:
      return {
        ...state,
        boothLoading: true,
      };

    case boothConstants.GET_BOOTH_SUCCESS:
      return {
        ...state,
        boothLoading: false,
        booth: action.payload,
      };

    case boothConstants.GET_BOOTH_REJECTED:
      return {
        ...state,
        boothLoading: false,
        boothError: action.payload,
      };

    // GET BOOTH VISITORS
    case boothConstants.GET_BOOTH_VISITORS_REQUEST:
      return {
        ...state,
        boothVisitorsLoading: true,
      };

    case boothConstants.GET_BOOTH_VISITORS_SUCCESS:
      return {
        ...state,
        boothVisitorsLoading: false,
        boothVisitors: action.payload,
      };

    case boothConstants.GET_BOOTH_VISITORS_REJECTED:
      return {
        ...state,
        boothVisitorsLoading: false,
        boothVisitorsError: action.payload,
      };

    // approve booth
    case boothConstants.APPROVE_BOOTH_REQUEST:
      return {
        ...state,
        approveBoothLoading: true,
        approveBoothMsg: "",
        approveBooth: false,
        approveBoothError: false,
      };

    case boothConstants.APPROVE_BOOTH_SUCCESS:
      return {
        ...state,
        approveBoothLoading: false,
        approveBooth: true,
        approveBoothMsg: action.payload,
      };

    case boothConstants.APPROVE_BOOTH_REJECTED:
      return {
        ...state,
        approveBoothLoading: false,
        approveBoothError: true,
        approveBoothMsg: action.payload,
      };

    // reject booth
    case boothConstants.REJECT_BOOTH_REQUEST:
      return {
        ...state,
        rejectBoothLoading: true,
        rejectBoothMsg: "",
      };

    case boothConstants.REJECT_BOOTH_SUCCESS:
      return {
        ...state,
        rejectBoothLoading: false,
        rejectBooth: true,
        rejectBoothMsg: action.payload,
      };

    case boothConstants.REJECT_BOOTH_REJECTED:
      return {
        ...state,
        rejectBoothLoading: false,
        rejectBoothError: action.payload,
        rejectBoothMsg: action.payload,
      };

    // approve draft booth
    case boothConstants.APPROVE_DRAFT_BOOTH_REQUEST:
      return {
        ...state,
        approveDraftBoothLoading: true,
        approveDraftBooth: false,
        approveDraftBoothError: false,
        approveDraftBoothMsg: "",
      };

    case boothConstants.APPROVE_DRAFT_BOOTH_SUCCESS:
      return {
        ...state,
        approveDraftBoothLoading: false,
        approveDraftBooth: true,
        approveDraftBoothMsg: action.payload,
        approveDraftBoothError: false,
      };

    case boothConstants.APPROVE_DRAFT_BOOTH_REJECTED:
      return {
        ...state,
        approveDraftBoothLoading: false,
        approveDraftBoothError: true,
        approveDraftBoothMsg: action.payload,
      };

    // reject draft booth
    case boothConstants.REJECT_DRAFT_BOOTH_REQUEST:
      return {
        ...state,
        rejectDraftBoothLoading: true,
        rejectDraftBooth: false,
        rejectDraftBoothError: false,
        rejectDraftBoothMsg: "",
      };

    case boothConstants.REJECT_DRAFT_BOOTH_SUCCESS:
      return {
        ...state,
        rejectDraftBoothLoading: false,
        rejectDraftBooth: true,
        rejectDraftBoothError: false,
        rejectDraftBoothMsg: action.payload,
      };

    case boothConstants.REJECT_DRAFT_BOOTH_REJECTED:
      return {
        ...state,
        rejectDraftBoothLoading: false,
        rejectDraftBoothError: true,
        rejectDraftBoothMsg: action.payload,
      };

    case boothConstants.RESET_BOOTH:
      return {
        ...state,
        loading: false,
        createBoothResponse: {},
        updateBoothDraftResponse: {},
        updateBoothResponse: {},
      };

    case boothConstants.RESET_PAYMENT_BOOTH:
      return {
        ...state,
        payAddBoothResponse: {},
      };

    case boothConstants.RESET_ADD_TO_CART:
      return {
        ...state,
        addBoothToCartResponse: {},
      };

    default:
      return state;
  }
}
