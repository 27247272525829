import React, {createContext, useState} from "react";

const ClassificationContext = createContext();

function ClassificationProvider(props) {
  const [classifications, setClassifications] = useState([]);
  const [allClassifications, setAllClassifications] = useState(0);
  const [allClassificationsTrainer, setAllClassificationsTrainer] = useState(0);
  const [allClassificationsCourses, setAllClassificationsCourses] = useState(0);

  return (
    <ClassificationContext.Provider
      value={{
        classifications,
        setClassifications,
        allClassifications,
        setAllClassifications,
        allClassificationsTrainer,
        setAllClassificationsTrainer,
        allClassificationsCourses,
        setAllClassificationsCourses,
      }}
    >
      {props.children}
    </ClassificationContext.Provider>
  );
}

export { ClassificationContext, ClassificationProvider };
