import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getFreeSearch,
  resetFreeSearch,
} from "../../../../Redux/Actions/search.action";
import { exportFreeSearch } from "../../../../Services/api/search";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SearchContainer from "../../../Shared/Components/SearchContainer/SearchContainer";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "../search.module.css";
import { filterActions } from "./SearchFreeActions";
import SearchFreeTable from "./SearchFreeTable";
import SearchStatistics from "./SearchStatistics";

const SearchFree = ({ role }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { freeSearchMeta } = useSelector((state) => state.searchReducer);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "dashboard",
      page: t("admin.label.dashboard"),
      pagePath:
        localStorage.getItem("type") == "group-admin"
          ? `/group-admin`
          : localStorage.getItem("type") == "manager"
          ? `/manager`
          : "/admin",
    },
    {
      id: "search",
      page: t("search.search_reports"),
      pagePath:
        localStorage.getItem("type") == "group-admin"
          ? `/group-admin/search/free`
          : localStorage.getItem("type") == "manager"
          ? `/manager/search/free`
          : "/admin/search/free",
    },
    {
      id: "search_history",
      page: t("search.free_search"),
      active: true,
    },
  ];

  const resetSearchHandler = () => {
    setSearchText("");
    dispatch(resetFreeSearch());
  };

  useEffect(() => {
    return () => {
      resetSearchHandler();
    };
  }, []);

  const getFreeSearchHandler = (page = 1) => {
    dispatch(
      getFreeSearch({
        page: page,
        perPage: 10,
        searchText,
      })
    );
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportFreeSearch(
        {
          search_text: searchText,
        },
        type
      );
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["search"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["search__wrapper"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <SearchStatistics />
              </MainBox>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead title={t("search.label")} />
                <SearchContainer
                  label={t("search.label")}
                  buttonText={t("search.search")}
                  onChange={(event) => {
                    const { value } = event.target;
                    setSearchText(value);
                  }}
                  onClick={() => {
                    getFreeSearchHandler();
                  }}
                  value={searchText}
                  resetSearch={resetSearchHandler}
                />
                <MainBoxHead
                  title={`${t("search.total_search_results")}: ${
                    freeSearchMeta?.total || 0
                  }`}
                  actions={filterActions({
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                <SearchFreeTable
                  getFreeSearchHandler={getFreeSearchHandler}
                  role={role}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFree;
