import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getStatisticsSurvey} from "../../../../Services/api/survey/SurveyProvider";
import CardStatistics from "../parts/CardStatistics";
import User from './../../../../assets/image/user.png'
import BarChart from './../parts/BarChart'
import {useTranslation} from 'react-i18next'

export default function Statistics(){
  require('./statistics.css')
  const {id} = useParams()
  const {t} = useTranslation()
    const [usersSent,setUsersSent] = useState("")
    const [usersNotSent,setUsersNotSent] = useState("")
    const [listCards,setListCards] = useState([])
    const [dates,setDates] = useState([])
    const [statisticsSent,setStatisticsSent] = useState([])
    const [counts,setCounts] = useState()


    useEffect(() => {
        getStatisticsSurvey(id)
          .then((res) => {
            if (res.status && res.status == 200 && res.data.status) {
              let totalUsersSentArr = []
              let totalUsersNotSentArr = []
              let dates = []
              let countSent = 0
              let countNotSent = 0
              for (let i = 0; i < res.data.statistics.length; i++) {
                if(res.data.statistics[i].response_id != null){
                  totalUsersSentArr.push(res.data.statistics[i])
                   countSent = totalUsersSentArr.length
                  setStatisticsSent(totalUsersSentArr)
                  setUsersSent(countSent)


                }

                if(res.data.statistics[i].response_time == null){
                  totalUsersNotSentArr.push(res.data.statistics[i])
                   countNotSent = totalUsersNotSentArr.length
                  setUsersNotSent(countNotSent)
                }


                if(res.data.statistics[i].response_time != null){
                  dates.push(res.data.statistics[i].response_time)
                  // setDates(dates)


        let counts = []
          let newDates = []
        for (let i = 0; i < dates.length; i++) {
            newDates.push(dates[i].split(' ')[0])
        }
          let datesAll = newDates.filter( (ele, ind) => ind === newDates.findIndex( elem => elem === ele))
          setDates(datesAll)

          totalUsersSentArr.map((item,index)=> {
            datesAll.map((date,i)=>{
                if(item.response_time  && item.response_time.split(" ")[0] == date){
                  if (counts[i] === undefined) {
                    counts[i] = 1;
                } else {
                    counts[i] += 1;
                }
                }
            })
          })
          let count = res.data.statistics.length + 50
          counts.push(count)
          setCounts(counts)
        }

                const total = countSent + countNotSent

                const list = [
                  {backgroundColor:'red',number:countNotSent,image:User,label:t('survey.statistics.usersNotAnswered')},
                  {backgroundColor:'orange',number:countSent,image:User,label:t('survey.statistics.usersAnswered')},
                  {backgroundColor:'yellow',number:total,image:User,label:t('survey.statistics.usersTotal')}

                ]

                setListCards(list)
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
    return(
       <div className="container-fluid">
           <h3 className="title-statistics">
             {t('survey.statistics.statistics')}
           </h3>
         <div className="content-statistics">
           <div className="row" >
              {listCards.map((card,index)=>(
               <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 card-statistics">
                 <CardStatistics key={index} color={card.backgroundColor} label={card.label} number={card.number}  image={card.image} />
                </div>
              ))}
           </div>

           <div>
             <BarChart counts={counts} statistics={statisticsSent} dates={dates} usersNotSent={usersNotSent} usersSent={usersSent}/>
           </div>
           </div>
       </div>
    )
}
