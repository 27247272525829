import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import { AdminUserProvider } from "../../../Services/api/toutorials/profileInfoContext";
import HomeIcon from "../../../assets/icons/home.svg";
import UserIcon from "../../../assets/icons/user.svg";
import CourseHistory from "../../../assets/icons/privateProfile/01.svg";
import CurrentCourse from "../../../assets/icons/privateProfile/02.svg";
import ProgramsHistory from "../../../assets/icons/privateProfile/03.svg";
import CurrentPrograms from "../../../assets/icons/privateProfile/04.svg";
import Certificates from "../../../assets/icons/privateProfile/05.svg";
import RefundRequests from "../../../assets/icons/privateProfile/06.svg";
import AboutMe from "../../../assets/icons/privateProfile/AboutMe.svg";
import Finance from "../../../assets/icons/privateProfile/Finance.svg";
import Invoices from "../../../assets/icons/privateProfile/09.svg";
import Wallet from "../../../assets/icons/privateProfile/10.svg";
import LearningAnalysisIcon from "../../../assets/icons/privateProfile/11.svg";

import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import Select from "../../Shared/Components/Select/Select";
import UsersProfileItemBox from "./UsersProfileItemBox";
import classes from "./usersProfile.module.css";

function PrivateProfile() {
  require("./usersProfile.css");
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const roles = authContext.roles;
  const [activeRole, setActiveRole] = useState(roles[0]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "privateProfile",
      page: t("sidebar.label.private_profile"),
      active: true,
    },
  ];

  const rolesOptions = roles.map((role) => {
    return { id: role, value: role, label: t(`selection.role.${role}`) };
  });

  const itemLists = [
    {
      id: "coursesHistory",
      title: t("private_profile.courses_history"),
      description: t("private_profile.courses_history"),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : activeRole === "content_developer"
              ? "content_developer"
              : "private-profile"
          }/courses-history`
        ),
      icon: CourseHistory,
      allowRoles: [
        "trainer",
        "manager",
        "trainee",
        "partner",
        "content_developer",
      ],
    },
    {
      id: "currentCourses",
      title: t("private_profile.current_courses"),
      description: t("private_profile.current_courses"),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : activeRole === "content_developer"
              ? "content_developer"
              : "private-profile"
          }/courses`
        ),
      icon: CurrentCourse,
      allowRoles: [
        "trainer",
        "manager",
        "trainee",
        "partner",
        "content_developer",
      ],
    },
    {
      id: "programsHistory",
      title: t(
        `private_profile.${
          activeRole === "trainer" || activeRole === "content_developer"
            ? "courses_programs"
            : "programs"
        }_history`
      ),
      description: t(
        `private_profile.${
          activeRole === "trainer" || activeRole === "content_developer"
            ? "courses_programs"
            : "programs"
        }_history`
      ),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : activeRole === "content_developer"
              ? "content_developer"
              : "private-profile"
          }/programs-history`
        ),
      icon: ProgramsHistory,
      allowRoles: [
        "trainer",
        "manager",
        "trainee",
        "partner",
        "content_developer",
      ],
    },
    {
      id: "currentPrograms",
      title: t(
        `private_profile.current_${
          activeRole === "trainer" || activeRole === "content_developer"
            ? "courses_programs"
            : "programs"
        }`
      ),
      description: t(
        `private_profile.current_${
          activeRole === "trainer" || activeRole === "content_developer"
            ? "courses_programs"
            : "programs"
        }`
      ),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : activeRole === "content_developer"
              ? "content_developer"
              : "private-profile"
          }/programs`
        ),
      icon: CurrentPrograms,
      allowRoles: [
        "trainer",
        "manager",
        "trainee",
        "partner",
        "content_developer",
      ],
    },
    {
      id: "certificates",
      title: t("private_profile.certificates"),
      description: t("private_profile.certificates"),
      onClick: () => history.push("/private-profile/certificates"),
      icon: Certificates,
      allowRoles: ["trainee"],
    },
    {
      id: "refundRequests",
      title: t("private_profile.refund_requests"),
      description: t("private_profile.refund_requests"),
      onClick: () => history.push("/private-profile/refund-requests"),
      icon: RefundRequests,
      allowRoles: ["trainee"],
    },
    {
      id: "aboutMe",
      title: t("private_profile.about_me"),
      description: t("private_profile.about_me"),
      onClick: () => history.push("/private-profile/about-me"),
      icon: AboutMe,
      allowRoles: ["manager", "trainer", "content_developer"],
    },
    {
      id: "financalRequset",
      title: t("private_profile.financial_request"),
      description: t("private_profile.financial_request"),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "content_developer"
              ? "content_developer"
              : "private-profile"
          }/payments-requests`
        ),
      icon: Finance,
      allowRoles: ["manager", "trainer", "content_developer"],
    },
    // {
    //   id: "sentGift",
    //   title: t("private_profile.sent_gift"),
    //   description: t("private_profile.sent_gift"),
    //   onClick: () => history.push("/private-profile/sent-gift"),
    //   icon: UserIcon,
    //   allowRoles: ["trainer", "manager", "trainee", "sponser", "partner"],
    // },
    // {
    //   id: "receivedGift",
    //   title: t("private_profile.received_gift"),
    //   description: t("private_profile.received_gift"),
    //   onClick: () => history.push("/private-profile/received-gift"),
    //   icon: UserIcon,
    //   allowRoles: ["trainer", "manager", "trainee", "sponser", "partner"],
    // },
    {
      id: "invoices",
      title: t("private_profile.invoices"),
      description: t("private_profile.invoices"),
      onClick: () => history.push("/private-profile/invoices"),
      icon: Invoices,
      allowRoles: ["trainee"],
    },
    {
      id: "wallet",
      title: t("private_profile.wallet"),
      description: t("private_profile.wallet"),
      onClick: () => history.push("/private-profile/wallet"),
      icon: Wallet,
      allowRoles: ["trainer", "manager", "trainee", "content_developer"],
    },
    {
      id: "learning-analysis",
      title: t("learning_analysis.title"),
      description: t("learning_analysis.title"),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : "private-profile"
          }/learning-analysis`
        ),
      icon: LearningAnalysisIcon,
      allowRoles: ["trainer", "manager", "trainee", "partner"],
    },
  ];

  return (
    <AdminUserProvider>
      <div className="container-fluid min-hight-78-200">
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {rolesOptions.length === 1 ? (
          <></>
        ) : (
          <div className="row">
            <div className="col-12 sm:tw-px-2">
              <MainBox>
                <div className={classes["select-role"]}>
                  <Select
                    label={t("selection.role.label")}
                    labelColor="#036c77"
                    options={rolesOptions}
                    onchange={(e) => setActiveRole(e.target.value)}
                    disabled={rolesOptions.length === 1}
                    value={activeRole}
                    name="role"
                  />
                </div>
              </MainBox>
            </div>
          </div>
        )}

        <div className="row">
          <div
            className={`col-12 sm:tw-px-2 ${
              rolesOptions.length === 1 ? "tw-py-6" : "tw-py-8"
            }`}
          >
            <MainBox style={{ minHeight: "500px" }}>
              <div className="row bg-container">
                <div className={classes["profile-container"]}>
                  {itemLists.map((item) =>
                    item.allowRoles.includes(activeRole) ? (
                      <UsersProfileItemBox
                        key={item.id}
                        title={item.title}
                        description={item.description}
                        onClick={item.onClick}
                        icon={item.icon}
                      />
                    ) : null
                  )}
                </div>
              </div>
            </MainBox>
          </div>
        </div>
      </div>
    </AdminUserProvider>
  );
}

export default PrivateProfile;
