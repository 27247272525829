import { Pagination } from "@mui/material";
import moment from "moment";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import classes from "../search.module.css";
const SearchFreeTable = ({ getFreeSearchHandler, role }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { freeSearchList, freeSearchMeta, freeSearchLoading } = useSelector(
    (state) => state.searchReducer
  );

  const displayPageLink = (rowData) => {
    switch (rowData.type) {
      case "المقرر":
      case "Course":
        return `/${role ? role : "admin"}/course/edit/${rowData.id}`;
      case "البرنامج":
      case "Program":
        return `/${role ? role : "admin"}/program/edit/${rowData.id}`;
      case "الإستبيان":
      case "Survey":
        return `/${role ? role : "admin"}/survey/edit/${rowData.id}`;
      case "المعرض":
      case "Exhibition":
        return `/${role ? role : "admin"}/edit-exhibition/${rowData.id}`;
      default:
        return `/${role ? role : "admin"}/search/free`;
    }
  };

  const columns = [
    {
      id: "serial",
      name: "#",
      key: "serial",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "name",
      name: t("search.save_search_name"),
      key: "name",
      cell: ({ rowData }) => <>{rowData?.name || "-"}</>,
    },
    {
      id: "source",
      name: t("search.search_source"),
      key: "source",
      cell: ({ rowData }) => <>{rowData?.type || "-"}</>,
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {rowData.created_at ? (
            <>
              {moment(rowData.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData.created_at)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "pageLink",
      name: t("search.review"),
      key: "pageLink",
      cell: ({ rowData }) => (
        <button
          className={classes["saved-search__button"]}
          onClick={() => {
            window.open(displayPageLink(rowData), "_blank");
          }}
        >
          {t("search.view")}
        </button>
      ),
    },
  ];

  return (
    <>
      <MainTable
        columns={columns}
        data={freeSearchList || []}
        isLoading={freeSearchLoading}
      />
      <Pagination
        count={freeSearchMeta?.totalPages || 0}
        showFirstButton
        showLastButton
        variant="outlined"
        shape="rounded"
        className="main-pagination"
        onChange={(e, page) => {
          getFreeSearchHandler(page);
        }}
      />
    </>
  );
};

export default SearchFreeTable;
