import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory, useLocation, useParams} from "react-router"

import Header from "../Header/Header"
import CourseVideo from "../../Profile/CourseVideo/CourseVideo"
import {Formik} from "formik"

import {CourseProfileProvider} from "../../../../../Services/api/courses/Trainer/CourseProfileContext"
import {toast} from "react-toastify";
import {EditObjective} from "../../../../../Services/api/EduGoals/EduGoalsProvider"
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";

const Edit = () => {
    require("../Content/content.css");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    const [editObjective, setEditObjective] = useState(null);
    const [nameField, setNameField] = useState("");
    const [descField, setDescField] = useState("");

    useEffect(() => {
        if(location.state){
            console.log(location.state.data.description)
            setEditObjective(location.state.data)
            setNameField(location.state.data.name)
            setDescField(location.state.data.description)
        }
    }, [])


    return (
        <CourseProfileProvider>
            <Header />
            <div className="container-fluid training-course-syllabus-container" style={{
                marginTop: 35
            }}>
                <div className="training-course-syllabus-section">
                    <div className="add-goals-Edu">
                    <h4>{t('edu.Add_edu_goals')} </h4>
                        <div>
                            {
                                editObjective?
                                <Formik
                                    initialValues={{
                                        name:  nameField,
                                        description: descField
                                    }}
                                    validateOnChange = {editObjective}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        try {
                                            let response = await EditObjective(editObjective.id,values)

                                            if(response.status === 200){
                                                toast.success(
                                                    <span style={{ fontSize: 13, fontWeight: "bold", display:'block', paddingBottom: '10px' }}>
                                                        {response.data.msg}
                                                    </span>
                                                );
                                                history.push(`/trainer/course/educational-goals/${id}`);
                                            }
                                        } catch (err) {
                                            toast.error(
                                                <span style={{ fontSize: 13, fontWeight: "bold", display:'block', paddingBottom: '10px' }}>{err.response.data.msg}</span>
                                            );
                                        }
                                    }}
                                    // validateOnChange={editObjective}
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.name) {
                                            errors.name = t("crud.errors.required");
                                        }
                                        if (!values.description) {
                                            errors.description = t("crud.errors.required");
                                        }
                                        return errors;
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        handleBlur,
                                        setFieldValue,
                                    }) => (
                                        <form onSubmit={handleSubmit} className="admin_add_form">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='row'>
                                                        <div className="col-md-12">
                                                            <label htmlFor="name" className="admin_add_label">
                                                            {t("edu.Goal")}
                                                            </label>
                                                            <input
                                                                id="name"
                                                                type="text"
                                                                name="name"
                                                                className="admin_add_input"
                                                                onChange={handleChange}
                                                                defaultValue = {nameField? nameField: ""}
                                                            />
                                                            <p className={"form-input-error-space"}>
                                                                {errors.name ? errors.name : null}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label htmlFor="name" className="admin_add_label">
                                                                {t("edu.description")}
                                                            </label>
                                                            <input
                                                                id="description"
                                                                type="text"
                                                                name="description"
                                                                className="admin_add_input"
                                                                onChange={handleChange}
                                                                defaultValue = {descField? descField: ""}
                                                            />
                                                            <p className={"form-input-error-space"}>
                                                                {errors.description ? errors.description : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                        className="btn_submit"
                                                    >
                                                        {t('edu.save')}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            :
                                <div style={{ height: "35vh",width: '100%'}}>
                                    <SkeletonCard/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <CourseVideo courseID={id} />
            </div>
        </CourseProfileProvider>
    )
}
export default Edit
