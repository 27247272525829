import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { paymentConstant } from "../../Constants/payment/payment.constant";
import env from "../../../Services/api/Environment";

export const getOrderDetails = (id, userId) => {
  return async (dispatch) => {
    dispatch({ type: paymentConstant.GET_ORDER_DETAILS_REQUEST });

    connector
      .get(URLBuilder(env.order_details, { id: id, userId: userId }))
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: paymentConstant.GET_ORDER_DETAILS_SUCCESS,
            payload: response.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: paymentConstant.GET_ORDER_DETAILS_REJECTED,
          payload: err,
        });
      });
  };
};

export const resetOrderDetails = () => {
  return async (dispatch) => {
    dispatch({ type: paymentConstant.RESET_ORDER_DETAILS });
  };
};

export const getVisitorOrderDetails = (id, userId) => {
  return async (dispatch) => {
    dispatch({ type: paymentConstant.GET_ORDER_DETAILS_REQUEST });

    connector
      .get(URLBuilder(env.visitor_order_details, { id: id, userId: userId }))
      .then((response) => {
        if (response.data.status) {
          dispatch({
            type: paymentConstant.GET_ORDER_DETAILS_SUCCESS,
            payload: response.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: paymentConstant.GET_ORDER_DETAILS_REJECTED,
          payload: err,
        });
      });
  };
};
