import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import {NavLink} from "react-router-dom";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import {getDepartmentUsers, syncDepartmentUsers} from "../../../../Services/api/courses/courseProvider";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import {Formik} from "formik";
import {getAllUsers} from "../../../../Services/api/Roles/RolesProvider";
import {toast} from "react-toastify";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

function AssignUsersToDepartment(props) {
    require("./AssignUsers.css");

    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [mappedSelectedUsers, setMappedSelectedUsers] = useState([]);
    const [mappedSelectedUserIDs, setMappedSelectedUserIDs] = useState([]);
    const [showPopper, setShowPopper] = useState(false);
    const [isSearchingOrDataFound, setIsSearchingOrDataFound] = useState(false);
    const [searchTimeOutHandler, setSearchTimeOutHandler] = useState(null);
    const [globalIsSubmitting, setGlobalIsSubmitting] = useState(false);
    const [canSubmitZeroElements, setCanSubmitZeroElements] = useState(false);

    const {t} = useTranslation();
    const history = useHistory();

    useEffect(() => {
        fetchDepartmentUsers();
    }, [])

    useEffect(() => {
        if (selectedUsers.length > 0) {
            setMappedSelectedUsers(selectedUsers.map(item => {
                return {
                    id: item.id,
                    title: item.name,
                };
            }));

            setMappedSelectedUserIDs(selectedUsers.map(item => {
                return item.id;
            }));

            setIsLoading(false);
        }
    }, [selectedUsers])

    const fetchUsers = (email) => {
        const myPromise = new Promise(async (resolve, reject) => {
            try{
                let response = await getAllUsers({
                    email: email
                });
                if (response.status == 200) {
                    console.log(response.data.users);
                    if(response.data.users.length > 0) {
                        setIsSearchingOrDataFound(true);
                        setFilteredUsers(response.data.users);
                    } else {
                        setIsSearchingOrDataFound(false);
                    }
                    resolve();
                }else {
                    toast.error(
                        <span style={{fontSize: 13, fontWeight: "bold"}}>
                            {t("failed_fetching")}
                        </span>
                    );
                    reject();
                }
            } catch(err) {
                console.log(err);
                toast.error(
                    <span style={{fontSize: 13, fontWeight: "bold"}}>
                        {t("failed_fetching")}
                    </span>
                );
                reject();
            }
        });

        return myPromise;
    }

    const fetchDepartmentUsers = () => {
        const myPromise = new Promise(async (resolve, reject) => {
            try{
                let response = await getDepartmentUsers(id);
                if ((response.status == 200 || response.status == 201 && response.data.status)) {
                    if(response.data.users.length > 0) {
                        if (response.data.users.length > 0) {
                            setCanSubmitZeroElements(true);
                        }
                        setSelectedUsers(response.data.users);
                    } else {
                        setIsLoading(false);
                    }
                    resolve();
                } else {
                    toast.error(
                        <span style={{fontSize: 13, fontWeight: "bold"}}>
                            {t("failed_fetching")}
                        </span>
                    );
                    reject();
                }
            } catch (err) {
                toast.error(
                    <span style={{fontSize: 13, fontWeight: "bold"}}>
                        {t("failed_fetching")}
                    </span>
                );
                reject();
            }
        });

        return myPromise;
    }

    return (
        <>
            <div className="container-fluid" style={{
                position: 'relative',
            }} onClick={(event) => {
                setShowPopper(false);
                event.stopPropagation();
            }}>
                <div className="row">
                    <div className="col-12">
                        <div className="admin_label">
                            <NavLink to="/admin">
                                {t("admin.label.admin_label")}
                            </NavLink>
                            <p style={{padding: "0 5px"}}>/</p>
                            <NavLink to="/admin/department">
                                {t("admin.label.list_department")}
                            </NavLink>
                            <p style={{padding: "0 5px"}}>/</p>
                            {t("admin.label.add_users_to_department")}
                        </div>
                        {isLoading ?
                            <div style={{
                                height: "60vh"
                            }}>
                                <SkeletonCard/>
                            </div>
                            :
                            <div className={"tajah-form-container"}>
                                {
                                    globalIsSubmitting?
                                        <SkeletonCardOverlay borderRadius={20} />: null
                                }
                                <Formik
                                    initialValues={{
                                        users: mappedSelectedUsers,
                                        userIDs: mappedSelectedUserIDs,
                                        anchorEl: null
                                    }}
                                    onSubmit={async (values, {setSubmitting}) => {
                                        setSubmitting(true);
                                        setGlobalIsSubmitting(true);
                                        try{
                                            const payload = {
                                                "department_id" : id,
                                                "users": values.userIDs
                                            }
                                            let response = await syncDepartmentUsers(payload);
                                            if (response.status == 200 && response.data.status) {
                                                history.push("/admin/department");
                                            } else {
                                                setSubmitting(false);
                                                setGlobalIsSubmitting(false);
                                            }
                                        }catch (err) {
                                            setSubmitting(false);
                                            setGlobalIsSubmitting(false);
                                        }
                                    }}
                                    validate={(values) => {

                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue,
                                          setErrors,
                                      }) => (
                                        <>
                                            <form onSubmit={handleSubmit} className="admin_add_form">
                                                <Collapse in={values.users.length > 0}>
                                                    <div className={"tajah-form-row"}>
                                                        <div className={"tajah-form-column"}>
                                                            <label className="admin_add_label">
                                                                {t("crud.list.users")}
                                                            </label>
                                                            <div className={"tajah-selection-holder"}>
                                                                {
                                                                    values.users.map(item => {
                                                                        return (
                                                                            <div className={"tajah-selection-holder-item"} key={item.id}>
                                                                                <div className={"tajah-selection-holder-item-text"}>{item.title}</div>
                                                                                <div className={"tajah-selection-holder-item-dismiss"} onClick={(event) => {
                                                                                    event.stopPropagation();
                                                                                    event.preventDefault();
                                                                                    console.log("HI DEL")
                                                                                    const userIDs = values.userIDs;
                                                                                    const users = values.users;
                                                                                    const indexOfToBeDeletedItem = userIDs.indexOf(item.id);
                                                                                    console.log(indexOfToBeDeletedItem);

                                                                                    userIDs.splice(indexOfToBeDeletedItem,1);
                                                                                    users.splice(indexOfToBeDeletedItem,1);

                                                                                    console.log(users)

                                                                                    setFieldValue('users',users);
                                                                                    setFieldValue('userIDs',userIDs);
                                                                                }}>
                                                                                    <i
                                                                                        className="fas fa-times"
                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Collapse>

                                                <div className={"tajah-form-row"}>
                                                    <div className={"tajah-form-column"}>
                                                        <label className="admin_add_label">
                                                            {t("crud.title.search_for_user")}
                                                        </label>
                                                        <input type={"text"}
                                                               id={"popper-anchor-element"}
                                                               className="admin_add_input"
                                                               placeholder={t('crud.placeholders.email_search')}
                                                               onClick={(event) => {
                                                                   event.stopPropagation();
                                                                   event.preventDefault();
                                                                   setShowPopper(true);
                                                                   setFieldValue('anchorEl',document.getElementById('popper-anchor-element'));
                                                               }}
                                                               onInput={async (event) => {
                                                                    setShowPopper(event.target.value.length > 2);
                                                                    setIsSearchingOrDataFound(true);
                                                                    setFilteredUsers([]);

                                                                    if (selectedUsers.length > 0 || showPopper) {
                                                                        setFieldValue('anchorEl',document.getElementById('popper-anchor-element'));
                                                                    }

                                                                    if (event.target.value.length <= 2) {
                                                                        setFieldValue('anchorEl',null);
                                                                    } else {
                                                                        if (searchTimeOutHandler != null){
                                                                            clearTimeout(searchTimeOutHandler);
                                                                        }

                                                                        setSearchTimeOutHandler(setTimeout(async () => {
                                                                            await fetchUsers(event.target.value);
                                                                        }, 1000));
                                                                    }
                                                               }} />
                                                        {
                                                            values.anchorEl != null && isSearchingOrDataFound && showPopper?
                                                                <Popper id={id} open={showPopper} anchorEl={values.anchorEl} transition>
                                                                    {({ TransitionProps }) => (
                                                                        <Fade {...TransitionProps} timeout={350}>
                                                                            <Box sx={{ border: 1, bgcolor: '#FFFFFF', width:values.anchorEl.offsetWidth, maxHeight: 280, overflowY: 'scroll'}}>
                                                                                {
                                                                                    filteredUsers.length > 0 ?
                                                                                        filteredUsers.map(item => {
                                                                                            return (
                                                                                                <div
                                                                                                    key={item.id}
                                                                                                    onClick={(event) => {
                                                                                                        event.stopPropagation();
                                                                                                        event.preventDefault();
                                                                                                        const currentUserIDs = values.userIDs;
                                                                                                        if (!currentUserIDs.includes(item.id)){
                                                                                                            const currentUsers = values.users;
                                                                                                            currentUserIDs.push(item.id);
                                                                                                            currentUsers.push({
                                                                                                                id: item.id,
                                                                                                                title: document.body.dir == 'rtl' ? item.name_ar : item.name_en
                                                                                                            })
                                                                                                            setFieldValue('users',currentUsers);
                                                                                                            setFieldValue('userIDs',currentUserIDs);
                                                                                                            setFieldValue('anchorEl',null);
                                                                                                        }
                                                                                                    }}
                                                                                                    className={"tajah-auto-complete-text-item"}>
                                                                                                    <span>{document.body.dir == 'rtl' ? item.name_ar : item.name_en}</span>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        :
                                                                                        <>
                                                                                            <div class={"tajah-auto-complete-loading"}>
                                                                                                <CircularProgress />
                                                                                            </div>
                                                                                        </>
                                                                                }
                                                                            </Box>
                                                                        </Fade>
                                                                    )}
                                                                </Popper> : null
                                                        }

                                                    </div>
                                                </div>
                                                <div className={"tajah-form-row"}>
                                                    <div className={"tajah-form-column"}>
                                                        <button
                                                            type="submit"
                                                            className="admin_add_button"
                                                            disabled={(canSubmitZeroElements ? false : values.userIDs.length == 0)  || isSubmitting}
                                                        >
                                                            {t("crud.placeholders.submit")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </Formik>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssignUsersToDepartment;
