import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteProgramPayment, updateProgramPayment } from "../../../../Redux/Actions/courses/coursesList.action";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import ProgramPaymentModal from "./ProgramPaymentModal.jsx";
import classes from "./programPaymentList.module.css";

const ProgramPaymentListTable = ({ isAdmin, programId, programInfo, paymentsAmount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const programPaymentModalRef = useRef();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showProgramPaymentModal, setShowProgramPaymentModal] = useState(false);
  const [selectedProgramPayment, setSelectedProgramPayment] = useState({});
  const { programPaymentsList, programPaymentsListLoading, deleteProgramPaymentLoading } = useSelector(
    (state) => state.coursesListReducer
  );

  const openProgramPaymentModalHandler = (id) => {
    if (
      programInfo?.status === "Draft" ||
      (programInfo?.status === "Published" && (programInfo?.number_of_users === 0 || programInfo?.is_started === false))
    ) {
      setSelectedProgramPayment(programPaymentsList.find((course) => course.id === id));
      setShowProgramPaymentModal(true);
      programPaymentModalRef.current.showModal();
    } else {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("courses_list.cannot_edit_payment")}</span>);
    }
  };

  const programPaymentsTableActionsList = [
    {
      id: "edit",
      icon: <img src={editIcon} alt="" />,
      action: (id) => {
        openProgramPaymentModalHandler(id);
      },
      tooltip: t("general.edit"),
    },
    {
      id: "delete",
      icon: <img src={DeleteIcon} alt="" />,
      action: (id) => {
        console.log("delete course");
        if (
          programInfo?.status === "Draft" ||
          (programInfo?.status === "Published" &&
            (programInfo?.number_of_users === 0 || programInfo?.is_started === false))
        ) {
          setSelectedProgramPayment({
            id,
            name: `${t("trainer.program.payment")} ${programPaymentsList.findIndex((course) => course.id === id) + 1}`,
          });
          setShowDeleteModal(true);
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("courses_list.cannot_delete_payment")}</span>
          );
        }
      },
      tooltip: t("general.delete"),
    },
  ];

  console.log("selectedProgramPayment", selectedProgramPayment);

  const deleteProgramPaymentHandler = (id) => {
    dispatch(
      deleteProgramPayment({
        id,
      })
    );
    setShowDeleteModal(false);
  };

  const updateProgramPaymentHandler = ({ id, data }) => {
    dispatch(
      updateProgramPayment({
        id,
        data,
      })
    );
    setShowProgramPaymentModal(false);
    programPaymentModalRef.current.dismissModal();
  };

  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
      sortable: false,
    },
    {
      id: "title",
      name: t("general.title"),
      key: "title",
      cell: ({ rowData, rowIndex }) => (
        <>
          {t("trainer.program.payment")} {rowIndex + 1}
        </>
      ),
      sortable: false,
    },
    {
      id: "amount",
      name: t("general.amount"),
      key: "amount",
      cell: ({ rowData }) => <>{`${rowData?.amount} ${t("general.rs")}` || "-"}</>,
      sortable: false,
    },
    {
      id: "courses",
      name: t(`courses`),
      key: "courses",
      cell: ({ rowData }) => (
        <ul className={classes["program-payment-list__table-trainers"]}>
          {!!rowData?.courses &&
            rowData?.courses?.map((trainer) => (
              <li className={classes["program-payment-list__table-trainers-item"]} key={trainer?.id}>
                {trainer?.name || "-"}
              </li>
            ))}
        </ul>
      ),
      sortable: false,
    },
    {
      id: "payment_date",
      name: t("general.payment_date"),
      key: "payment_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.payment_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.payment_date)}
        </>
      ),
      sortable: false,
    },
    {
      id: "end_date",
      name: t("general.payment_end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.end_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.end_date)}
        </>
      ),
      sortable: false,
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
      sortable: false,
    },
    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <div className={classes["courses-list__table__actions"]}>
          {programPaymentsTableActionsList.map((action) => {
            return (
              <button
                key={action.id}
                className={classes["courses-list__table__actions-btn"]}
                title={typeof action.tooltip === "function" ? action.tooltip(rowData.name) : action.tooltip}
                onClick={() => {
                  action.action(rowData.id);
                }}
                // disabled={false}
              >
                {action.icon}
              </button>
            );
          })}
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <MainTable columns={columns} data={programPaymentsList || []} isLoading={programPaymentsListLoading} />
      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon}
          title={t("trainer.program.delete_program_payment")}
          name={selectedProgramPayment.name}
          confirmMsg={t("trainer.program.delete_program_payment_msg")}
          confirmText={t("Exhibition.confirm")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            deleteProgramPaymentHandler(selectedProgramPayment.id);
          }}
          isLoading={deleteProgramPaymentLoading}
        />
      )}
      <BasicModal ref={programPaymentModalRef}>
        {showProgramPaymentModal && (
          <ProgramPaymentModal
            programPayment={selectedProgramPayment}
            programId={programId}
            closeModal={() => {
              setShowProgramPaymentModal(false);
              programPaymentModalRef.current.dismissModal();
            }}
            submitHandler={updateProgramPaymentHandler}
            paymentsAmount={paymentsAmount}
            programPrice={programInfo?.price}
            firstPayment={selectedProgramPayment.id === programPaymentsList[0].id}
            startRegisterDate={programInfo?.start_register_date}
          />
        )}
      </BasicModal>
    </>
  );
};

export default ProgramPaymentListTable;
