import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import {getTraineeCourseLessonAnalysis,} from "../../../../Services/api/Analysis/AnalysisProvider";
import {useParams} from "react-router";
import {NavLink} from "react-router-dom";
import {BasicModal} from "../../../Shared/Components/Modal/BasicModal";
import TraineeLessonAnalysis from "../../../../Modals/traineeLessonAnalysis/TraineeLessonAnalysis";
import {toast} from "react-toastify";

const TraineeCourseLesson = () => {
  require("../Analysis.css");
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [lesson, setLesson] = useState([]);
  const { userId, courseId } = useParams();
  const [lessonContent, setLessonContent] = useState([]);
  const modalRef = useRef();

  useEffect(() => {
    getTraineeCourseLessonAnalysis(userId, courseId).then((res) => {
      if (res.status && res.status == 200 && res.data.status) {
        setTitle(res.data.response.course_name);
        setLesson(res.data.response.lessons);
        setIsLoading(false);
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("failed_fetching")}
          </span>
        );
      }
    });
  }, []);

  const modalHandler = () => modalRef.current.dismissModal();

  const dataPopup = (content) => {
    modalRef.current.showModal();
    setLessonContent(content);
  };

  return (
    <>
      <BasicModal ref={modalRef}>
        <TraineeLessonAnalysis
          title={t("syllabus")}
          refuse={modalHandler}
          data={lessonContent}
          modalRef={modalRef}
        />
      </BasicModal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="admin_label">
              <NavLink to="/admin">{t("Home")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              <NavLink to="/admin/trainee-analysis">
                {t("sidebar.label.traineeAnalysis")}
              </NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {title}
            </div>
            <>
              <div className="counter_analysis">
                {isLoading ? (
                  <div
                    style={{
                      height: "25vh",
                      marginTop: "20px",
                    }}
                  >
                    <SkeletonCard />
                  </div>
                ) : (
                  <>
                    <table
                      className="table table-bordered"
                      style={{
                        margin: 0,
                        marginTop: "50px",
                        padding: 0,
                      }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{t("lessons")}</th>
                          <th scope="col">{t("syllabus")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lesson &&
                          lesson.map((l, index) => (
                            <tr key={l.id}>
                              <td>{index + 1}</td>
                              <td>{l.title}</td>
                              <td>
                                <i
                                  className="fal fa-eye"
                                  onClick={(e) => dataPopup(l.contents)}
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                    backgroundColor: "white",
                                    borderWidth: 0,
                                    fontSize: 20,
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default TraineeCourseLesson;
