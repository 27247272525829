import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import { programPaymentListConstant } from "../../Constants/ProgramPaymentList/programPaymentList.constant";

// GET SYSTEM USERS LIST
export const fetchProgramPaymentList = ({ page = 1, perPage = 10, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: programPaymentListConstant.GET_FINANCIAL_PROGRAM_PAYMENT_LIST_REQUEST,
    });

    await connector
      .get(env.get_program_payment_list, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          //   console.log("response", response);
          dispatch({
            type: programPaymentListConstant.GET_FINANCIAL_PROGRAM_PAYMENT_LIST_SUCCESS,
            payload: {
              programPaymentList: response.data.data,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: programPaymentListConstant.GET_FINANCIAL_PROGRAM_PAYMENT_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: programPaymentListConstant.GET_FINANCIAL_PROGRAM_PAYMENT_LIST_REJECTED,
          payload: err,
        });
      });
  };
};
