import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { toServerTime } from "../../../../Services/Timezone";
import { useDispatch } from "react-redux";
import HomeIcon from "../../../../assets/icons/home.svg";
import { formatDate, formatTime } from "../../../../utils/formatDate";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../Shared/Components/DataTable/DataTable";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import classes from "./advertisementsForm.module.css";

import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import SunEditor from "suneditor-react";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import InputFile from "../../../Shared/Components/InputFile/InputFile";

const AdvertisementsForm = ({
  type,
  submitHandler,
  AdDetails,
  types,
  audience_types,
  loading,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);
  const [disableAudiancetype, setDisableAudiancetype] = useState(false);

  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const downloadFile = (url) => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = URL.createObjectURL(blob).split("/").slice(-1)[0];
        link.click();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (
      AdDetails?.type?.value === "both" ||
      AdDetails?.type?.value === "in-home-page"
    ) {
      setDisableAudiancetype(true);
    }
  }, [AdDetails]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "events",
      page: t("Advertisements"),
      pagePath: "/admin/advertisements-list",
    },
    {
      id: "create_event",
      page: type !== "Edit" ? t("add_ad") : AdDetails?.title,
      active: true,
    },
  ];

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };
  useEffect(() => {
    currentDate();
    setIsLoading(false);
  }, []);

  return (
    <div className={classes["create_event"]}>
      <div className="container-fluid">
        <div className={classes["create_event__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {!isLoading && !loading && (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      ...(type === "Edit"
                        ? { _method: "put", resend_email: "" }
                        : {}),
                      title_ar: AdDetails?.title_ar || "",
                      title_en: AdDetails?.title_en || "",
                      description_ar: AdDetails?.description_ar || "",
                      description_en: AdDetails?.description_en || "",
                      location: AdDetails?.location || "",
                      type: AdDetails?.type?.value || "",
                      has_button: AdDetails?.has_button ? 1 : 0 || 0,
                      button_text_en: AdDetails?.button_text_en || "",
                      button_text_ar: AdDetails?.button_text_ar || "",
                      url: AdDetails?.url || "",
                      audience_type: AdDetails?.audience_type?.value || "",

                      file: AdDetails?.image || "",
                      from_date: AdDetails?.from_date
                        ? getLocaleTime(new Date(AdDetails?.from_date))
                        : setHours(
                            setMinutes(new Date(), minutesDatepicker),
                            hoursDatepicker
                          ),
                      to_date: AdDetails?.to_date
                        ? getLocaleTime(new Date(AdDetails?.to_date))
                        : setHours(
                            setMinutes(new Date(), minutesDatepicker),
                            hoursDatepicker
                          ),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        let formData = new FormData();
                        Object.keys(values).forEach((field) => {
                          if (field === "file") {
                            values[field].file &&
                              formData.append("image", values[field].file);
                          } else if (
                            field === "to_date" ||
                            field === "from_date"
                          ) {
                            formData.append(
                              `${field}`,

                              toServerTime(
                                formatDate(new Date(values[field])) +
                                  " " +
                                  formatTime(new Date(values[field]))
                              )
                            );
                          } else {
                            formData.append(field, values[field]);
                          }
                        });
                        if (values.has_button === 0) {
                          formData.delete("button_text_en");
                          formData.delete("button_text_ar");
                          formData.delete("url");
                        }
                        await submitHandler(formData);
                        setSubmitting(false);
                      } catch (err) {
                        console.log("err", err);
                        toast.error(
                          <div>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <span style={{ fontSize: 12 }}>
                                    {err.response.data.errors[key]}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.title_ar) {
                        errors.title_ar = t("crud.errors.required");
                      }
                      if (!values.title_en) {
                        errors.title_en = t("crud.errors.required");
                      }

                      if (!values.to_date) {
                        errors.to_date = t("crud.errors.required");
                      }
                      if (!values.from_date) {
                        errors.from_date = t("crud.errors.required");
                      }

                      if (!values.description_ar) {
                        errors.description_ar = t("crud.errors.required");
                      }

                      if (!values.description_en) {
                        errors.description_en = t("crud.errors.required");
                      }
                      if (!values.type) {
                        errors.type = t("crud.errors.required");
                      }
                      if (!values.audience_type) {
                        errors.audience_type = t("crud.errors.required");
                      }
                      if (values.has_button == 1) {
                        if (!values.button_text_ar) {
                          errors.button_text_ar = t("crud.errors.required");
                        }
                        if (!values.button_text_en) {
                          errors.button_text_en = t("crud.errors.required");
                        }
                        if (!values.url) {
                          errors.url = t("crud.errors.required");
                        }
                      }
                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["create_event__form"]}
                      >
                        <MainBoxHead
                          title={
                            type !== "Edit" ? t("add_ad") : AdDetails?.title
                          }
                        />
                        <div
                          className={`row ${classes["create_event__form__fields"]}`}
                        >
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("title_ar_input")}
                              type="text"
                              name="title_ar"
                              placeholder={t("title_ar_input")}
                              onChange={handleChange}
                              error={errors.title_ar}
                              value={values.title_ar}
                              required={true}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("title_en_input")}
                              type="text"
                              name="title_en"
                              placeholder={t("title_en_input")}
                              onChange={handleChange}
                              error={errors.title_en}
                              value={values.title_en}
                              required={true}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description_ar"
                              style={{ color: "#777777" }}
                            >
                              {t(`specialties_manage.specialty_description_ar`)}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <textarea
                              id="description_ar"
                              name="description_ar"
                              placeholder={t(
                                `specialties_manage.specialty_description_ar`
                              )}
                              value={values.description_ar}
                              defaultValue={values.description_ar}
                              style={{
                                border: "1px solid rgb(201, 200, 200)",
                                display: "block",
                                width: "100%",
                                borderRadius: "6px",
                                padding: "15px",
                              }}
                              maxLength={250}
                              onChange={handleChange}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.description_ar
                                ? errors.description_ar
                                : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description_en"
                              style={{ color: "#777777" }}
                            >
                              {t(`specialties_manage.specialty_description_en`)}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <textarea
                              id="description_en"
                              name="description_en"
                              placeholder={t(
                                `specialties_manage.specialty_description_en`
                              )}
                              value={values.description_en}
                              defaultValue={values.description_en}
                              style={{
                                border: "1px solid rgb(201, 200, 200)",
                                display: "block",
                                width: "100%",
                                borderRadius: "6px",
                                padding: "15px",
                              }}
                              maxLength={250}
                              onChange={handleChange}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.description_en
                                ? errors.description_en
                                : null}
                            </p>
                          </div>

                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("general.start_date_time")}
                              name="from_date"
                              error={errors.from_date}
                              value={values.from_date}
                              onChange={(date) => {
                                setFieldValue("from_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatepicker(0);
                                  setMinutesDatepicker(0);
                                } else {
                                  currentDate();
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatepicker),
                                hoursDatepicker
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              required={true}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.from_date ? errors.from_date : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("general.end_date_time")}
                              name="to_date"
                              error={errors.to_date}
                              value={values.to_date}
                              onChange={(date) => {
                                setFieldValue("to_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatepicker(0);
                                  setMinutesDatepicker(0);
                                } else {
                                  currentDate();
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatepicker),
                                hoursDatepicker
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              required={true}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.to_date ? errors.to_date : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <label htmlFor="type">
                              {t("type")}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              name="type"
                              id="type"
                              options={types}
                              value={types.find(
                                (option) => option.value == values.type
                              )}
                              getOptionLabel={(option) => option.text}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                if (
                                  item.value === "in-home-page" ||
                                  item.value === "both"
                                ) {
                                  setFieldValue("audience_type", "all");
                                  setDisableAudiancetype(true);
                                } else {
                                  setDisableAudiancetype(false);
                                }
                                setFieldValue("type", item.value);
                              }}
                              placeholder={t("type")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.type ? errors.type : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <label htmlFor="mechanism">
                              {t("audience_type")}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              name="audience_type"
                              id="audience_type"
                              options={audience_types}
                              value={audience_types.find(
                                (option) => option.value == values.audience_type
                              )}
                              getOptionLabel={(option) => option.text}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setFieldValue("audience_type", item.value);
                              }}
                              placeholder={t("audience_type")}
                              isDisabled={disableAudiancetype}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.audience_type
                                ? errors.audience_type
                                : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <InputFile
                              name="file"
                              type="document"
                              documentWithoutTitle={true}
                              accept={"image/*"}
                              label={t("Exhibition.upload_image")}
                              placeholder={t("Exhibition.upload_image")}
                              handleDownload={() => {
                                downloadFile(values.file);
                              }}
                              note={t("image_ad_note")}
                              setFieldValue={setFieldValue}
                              value={values.file}
                              error={errors.file}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <Checkbox
                              name="has_button"
                              label={t(`has_button`)}
                              onChange={(event) => {
                                setFieldValue(
                                  "has_button",
                                  event.target.checked ? 1 : 0
                                );
                              }}
                              checked={
                                values.has_button === true ||
                                values.has_button === 1
                              }
                              error={errors.has_button}
                            />
                          </div>
                          {values.has_button == 1 ? (
                            <>
                              <div
                                className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                              >
                                <Input
                                  required={true}
                                  label={t("title_ar_input_button")}
                                  type="text"
                                  name="button_text_ar"
                                  placeholder={t("title_ar_input_button")}
                                  onChange={handleChange}
                                  error={errors.button_text_ar}
                                  value={values.button_text_ar}
                                />
                              </div>
                              <div
                                className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                              >
                                <Input
                                  required={true}
                                  label={t("title_en_input_button")}
                                  type="text"
                                  name="button_text_en"
                                  placeholder={t("title_en_input_button")}
                                  onChange={handleChange}
                                  error={errors.button_text_en}
                                  value={values.button_text_en}
                                />
                              </div>
                              <div
                                className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                              >
                                <Input
                                  required={true}
                                  label={t("button_url")}
                                  type="text"
                                  name="url"
                                  placeholder={t("button_url")}
                                  onChange={handleChange}
                                  error={errors.url}
                                  value={values.url}
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div
                            className={`${classes["event__form__submit__container"]} col-12`}
                          >
                            {type === "Edit" && (
                              <CustomButton
                                type="button"
                                colors="#036c77"
                                value={t("Edit_email")}
                                action={() => {
                                  setFieldValue("resend_email", 1);
                                  handleSubmit();
                                }}
                                disable={
                                  isSubmitting || values.type === "in-home-page"
                                }
                                loading={isSubmitting}
                              />
                            )}
                            <CustomButton
                              type="submit"
                              colors="#036c77"
                              value={type === "Edit" ? t("Edit") : t("create")}
                              disable={isSubmitting}
                              loading={isSubmitting}
                            />
                          </div>
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvertisementsForm;
