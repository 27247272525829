import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Formik } from "formik";
import { NavLink, useLocation } from "react-router-dom";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";

import { UpdateWebxTeamsToken } from "../../../Services/api/Zoom/ZoomProvider";

export default function UpdateToken() {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [OldData, setOldData] = useState(null);

  useEffect(() => {
    console.log(location);
    setOldData(location.state);
  }, []);

  return (
    <div className="faq-add-pg">
      <div className="container-fluid profile_wrapper">
        <div className="admin_label">
          <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to="/admin/teams/list">{t("zoom.Webx_teams")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {t("zoom.Update_token")}
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className={isLoading ? null : "table_bank_tran faq_box_white"}>
          {isLoading ? (
            <div
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <>
              <div className="box_faq" style={{ width: "90%" }}>
                <div>
                  <Formik
                    initialValues={{
                      provider: location.state.provider,
                      token: location.state.token,
                      refresh_token: location.state.refresh_token,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        const response = await UpdateWebxTeamsToken(id, values);
                        if (response.status === 200 && response.data.status) {
                          toast.success(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {t("zoom.Updated_successfully")}
                            </span>,
                            {
                              onClose: () => history.push(`/admin/teams/list`),
                              autoClose: 1000,
                            }
                          );
                        }
                      } catch (err) {
                        setIsLoading(false);
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {err.response.data && err.response.data.msg
                              ? err.response.data.msg
                              : "Failure in service"}
                          </span>
                        );
                      }
                    }}
                    // validateOnChange={}
                    validate={(values) => {
                      const errors = {};
                      if (!values.provider) {
                        errors.provider = t("crud.errors.required");
                      }
                      if (!values.token) {
                        errors.token = t("crud.errors.required");
                      }
                      if (!values.refresh_token) {
                        errors.refresh_token = t("crud.errors.required");
                      }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="admin_add_form">
                        <div className="row">
                          <div className="col-md-4">
                            <label htmlFor="name" className="admin_add_label">
                              {t("zoom.type")}
                            </label>
                            <select
                              name="provider"
                              id="provider"
                              className="admin_add_input"
                              onChange={handleChange}
                              defaultValue={location.state.provider}
                            >
                              <option value=""> -- </option>
                              <option value="webx"> webx </option>
                              <option value="teams"> teams</option>
                            </select>
                            <p className={"form-input-error-space"}>
                              {errors.provider ? errors.provider : null}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="name" className="admin_add_label">
                              {t("zoom.add_token")}
                            </label>
                            <input
                              name="token"
                              className="admin_add_input"
                              defaultValue={OldData ? OldData.token : ""}
                              onChange={handleChange}
                            />

                            <p className={"form-input-error-space"}>
                              {errors.token ? errors.token : null}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="name" className="admin_add_label">
                              {t("zoom.add_refreshToken")}
                            </label>
                            <input
                              name="refresh_token"
                              className="admin_add_input"
                              defaultValue={
                                OldData ? OldData.refresh_token : ""
                              }
                              onChange={handleChange}
                            />

                            <p className={"form-input-error-space"}>
                              {errors.refresh_token
                                ? errors.refresh_token
                                : null}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="admin_add_button btn_zoom"
                            >
                              {t("zoom.Update")}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
