import PublishRequestsForm from "./PublishRequestsForm/PublishRequestsForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addRequest } from "../../../Services/api/PublishReq/PublishReqProvider";

export default function PublishRequestsAdd({ role }) {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  const submitHandler = async (formData) => {
    try {
      const response = await addRequest(formData, role);
      if (response.status === 201) {
        history.push("/");
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg}
          </span>,
          {
            autoClose: 1000,
          }
        );
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
      }
    } catch (err) {
      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response.data.msg}
          </span>
          <ul>
            {Object.keys(err.response.data.errors).map((key) => {
              return (
                <li>
                  <span style={{ fontSize: 11 }}>
                    {err.response.data.errors[key]}
                  </span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  return <PublishRequestsForm submitHandler={submitHandler} role={role} />;
}
