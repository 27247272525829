import PdfIcon from "../../../../assets/icons/pdf.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";

export const filterActions = ({ addAction, exportPdf, exportExcel, traineePayments, cloneCourse }) => {
  const actionsList = [
    ...(traineePayments
      ? [
          {
            id: "traineePayments",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #036c77",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label: i18n.language === "ar" ? "دفعات المتدربين" : "Trainee Payments",
            btnTextColor: "#036c77",
            action: traineePayments,
          },
        ]
      : []),
    {
      id: "add",
      icon: <img src={AddIcon} alt="" />,
      theme: "add",
      action: addAction,
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};
