import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import AnalysisCard from "./AnalysisCard";
import numberOfRegistered from "../../../../assets/image/analysis/number-of-registered.png";
import {getAnalysisStatisticsById} from "../../../../Services/api/Analysis/AnalysisProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import {NavLink, useParams} from "react-router-dom";
import {useHistory} from "react-router";


function Statistics() {
  require("./statistics.css");

  const { t } = useTranslation();
  const history = useHistory()
  const {id} = useParams()
  const [isLoading, setIsLoading] = useState(true);
  const [analysis,setAnalysis] = useState({})
  const [quizes,setQuizes] = useState([])
  const [questions,setQuestions] = useState([])

  useEffect(() => {
    setIsLoading(true);
    if (id !== null) {
        console.log("quizId", id)

        getAnalysisStatisticsById(id)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setIsLoading(false);
            setAnalysis(res.data.response)
            setQuizes(res.data.response.quiz_trainees)
            setQuestions(res.data.response.questions)
          }
        })
        .catch((err) => {
          console.log("ERROR");
          console.log(err);
        });
    }
  }, [id]);

  return (
    <div className="container-fluid" style={{paddingTop: '50px'}}>
      <div className="row">
        <div className="col-12">
          <div className="admin_label">
            <NavLink to="/">{t("Home")}</NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("analysis.quizAnalysis")}
          </div>
          <>
            <div className="counter_analysis">
              {isLoading ? (
                <div
                  style={{
                    height: "25vh",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : null}
              {isLoading ? (
                <div
                  style={{
                    height: "25vh",
                    marginTop: "20px",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <>
                  <>
                    <div>
                        <div className="counter_analysis_grid_new">
                        <AnalysisCard
                            img={numberOfRegistered}
                            label={t('analysis.total_trainess')}
                            counter={analysis.total_trainess}
                            smallImage={true}
                        />
                        <AnalysisCard
                            img={numberOfRegistered}
                            label={t('analysis.total_not_answered')}
                            counter={analysis.total_not_answered}
                            smallImage={true}
                        />
                            <AnalysisCard
                            img={numberOfRegistered}
                            label={t('analysis.total_answered')}
                            counter={analysis.total_answered}
                            smallImage={true}
                        />
                        <AnalysisCard
                            img={numberOfRegistered}
                            label={t('analysis.passed_trainess')}
                            counter={analysis.passed_trainess}
                            smallImage={true}
                        />
                            <AnalysisCard
                            img={numberOfRegistered}
                            label={t('analysis.failed_trainess')}
                            counter={analysis.failed_trainess}
                            smallImage={true}
                        />

                        </div>
                        <div className="counter_analysis_grid_table">


                            <div
                                className="table-responsive tabel-analysis_new"
                                style={{
                                width: "100%",
                                }}
                            >
                                <div
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                }}
                                >
                                {t("analysis.quiz_trainees")}
                                </div>

                                <table
                                className="table table-bordered"
                                style={{
                                    margin: 0,
                                    padding: 0,
                                }}
                                >
                                <thead>
                                    <tr>
                                        <th scope="col">{t("analysis.userName")}</th>
                                        <th scope="col">{t("analysis.status")}</th>
                                        {/* <th scope="col">{t("crud.list.actions")}</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    { quizes.map(
                                    (item, index) => (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>
                                                {
                                                    item.passed === 0 ? t('analysis.not_answer') :
                                                        item.passed === 1 ? t('analysis.passed_traines') :
                                                        t('analysis.failed_traines')
                                                }
                                            </td>
                                            {/* <td>
                                                <i
                                                    onClick={() => {
                                                        // history.push(`/trainer/course/${id}/${props.quizable}/${quizable_id}/exam/${exam_id}/grades/${item.trainee_id}`)
                                                    }}
                                                    title={"Edit"}
                                                    style={{
                                                        fontSize: 20,
                                                        color:'black',

                                                    }} className="fal fa-eye"></i>
                                            </td> */}
                                        </tr>
                                    )
                                    )}
                                </tbody>
                                </table>

                            </div>


                            {/* TEst */}
                            {
                                questions.length > 0?
                                    <div
                                        className="table-responsive tabel-analysis_new"
                                        style={{
                                        width: "100%",
                                        }}
                                    >
                                            <div
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                marginBottom: "10px",
                                            }}
                                            >
                                                {t("analysis.questions")}
                                            </div>

                                            <table
                                            className="table table-bordered"
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                            }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{t("analysis.question")}</th>
                                                        <th scope="col">{t("analysis.total_passed")}</th>
                                                        <th scope="col">{t("analysis.total_failed")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { questions.map(
                                                    (item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.title}</td>
                                                            <td>{item.total_passed}</td>
                                                            <td>{item.total_failed}</td>
                                                        </tr>
                                                    )
                                                    )}
                                                </tbody>
                                            </table>
                                    </div>
                                :
                                <div style={{display: 'flex', justifyContent:"center", alignItems : 'center', backgroundColor: "#efefef"}}>
                                    <p style={{ padding: "20px", textAlign:"center", }}>{t('analysis.not_found_question')} </p>
                                </div>
                            }
                            {/* test */}
                        </div>
                    </div>
                  </>

                </>
              )}
              {/* </>
              )} */}
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
