import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { usePusherContext } from "../../../../Contexts/PusherContext";
import {
  getPrivateChatSessions,
  getlivechatViewSession,
  livechatChangeMessage,
  postPrivateChatSendMessage,
} from "../../../../Redux/Actions/livechat/livechat.action";
import toLocalTimezone from "../../../../Services/Timezone";
import { getCourseDetails } from "../../../../Services/api/courses/courseProvider";
import HomeIcon from "../../../../assets/icons/Group 3.svg";
import ChatBubbleInputs from "../../../LiveChat/ChatBubbleInputs";
import LoadingSpinner from "../../../Shared/Components/LoadingSpinner/LoadingSpinner";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import Massages from "./Massages/Massages";
import ChatsList from "./chatsList/ChatsList";
import "./coursePrivateChat.css";
import classes from "./coursePrivateChat.module.css";

const CoursePrivateChat = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    private_chat_sessions,
    livechat_view_session,
    livechat_message_change,
    livechat_assign_session,
    first_message,
  } = useSelector((state) => state.livechatReducer);

  const { pusher } = usePusherContext();

  const { id } = useParams();
  const history = useHistory();
  const [sessions, setSessions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [msgLoading, setMsgLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [sessionId, setSessionId] = useState();
  const [replies, setReplies] = useState([]);
  const [courseTitle, setCourseTitle] = useState("");

  const userId = localStorage.getItem("userId");

  function sendMessage() {
    if (inputMessage.trim() !== "") {
      let formData = new FormData();

      formData.append("chat_session_id", sessionId);

      formData.append("message", inputMessage);
      dispatch(postPrivateChatSendMessage(formData));
      dispatch(livechatChangeMessage());

      setInputMessage("");
    }
  }

  const getCourseDetailsAPI = () => {
    getCourseDetails(id)
      .then((res) => {
        if (res.status === 200 && res.data.status) {
          setCourseTitle(res.data.course.name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCourseDetailsAPI();
  }, []);

  const handleSeenUpdate = (itemId) => {
    setSessions((prevObjects) =>
      prevObjects.map((obj) =>
        Number(obj.id) === Number(itemId) ? { ...obj, is_seen: true } : obj
      )
    );
  };

  const fetchMessages = (id) => {
    setMsgLoading(true);
    dispatch(getlivechatViewSession(id));
  };

  useEffect(() => {
    const channel = pusher.subscribe("learning-chat");
    channel.bind("App\\Events\\PrivateMessageSent", (data) => {
      if (Number(data.chat_id) === Number(sessionId)) {
        if (!messages.find((message) => message.id === data.message.id)) {
          setMessages((oldArray) => [...oldArray, data.message]);
        }
      } else {
        setSessions((prevObjects) =>
          prevObjects.map((obj) =>
            Number(obj.id) === Number(data.chat_id)
              ? { ...obj, is_seen: false }
              : obj
          )
        );
      }
    });
    return () => {
      channel?.unbind("App\\Events\\PrivateMessageSent");
      pusher?.unsubscribe("learning-chat");
    };
  }, [sessionId]);

  const handler = (event) => {
    if (event.key == "Enter") sendMessage();
  };

  useEffect(() => {
    if (livechat_message_change && sessionId) {
      dispatch(livechatChangeMessage());
    }
  }, [livechat_message_change, dispatch, sessionId]);

  useEffect(() => {
    dispatch(getPrivateChatSessions(id));
  }, [first_message]);

  useEffect(() => {
    if (private_chat_sessions.status === 200) {
      setSessions(private_chat_sessions.data?.chatsessions);
      setIsLoading(false);
    }
  }, [private_chat_sessions]);

  useEffect(() => {
    if (livechat_view_session.status === 200) {
      setMessages(livechat_view_session.data.response.messages);
      setReplies(livechat_view_session.data.response.replies);
      setMsgLoading(false);
    }
  }, [livechat_view_session]);

  useEffect(() => {
    if (livechat_assign_session.status === 200) {
      updateObjectById(sessionId, livechat_assign_session.data.chatsession);
    }
  }, [livechat_assign_session]);

  const updateObjectById = (id, newObject) => {
    setSessions((prevObjects) =>
      prevObjects.map((obj) => (obj.id === id ? newObject : obj))
    );
  };

  const handleSetSession = (id) => {
    setSessionId(id);
    setInputMessage("");
  };

  return (
    <div className="container-fluid">
      <div className={classes.header}>
        <img
          src={HomeIcon}
          alt=""
          onClick={() => history.push("/")}
          style={{ cursor: "pointer" }}
        />
        <div className={classes["header__title"]}>
          <h2>{t("chat.privateChat")}</h2>
          <p>
            {t("chat.courseTitle")}: <span>{courseTitle}</span>
          </p>
        </div>
      </div>
      <MainBox>
        <div className={classes["d-flex"]}>
          <div
            className={`col-md-12 col-lg-4 col-sm-12 col-xs-12 ${classes.rmPadding}`}
          >
            {isLoading ? (
              <div
                className={`${classes["loading__container"]} ${classes.containerUsersList}`}
              >
                <LoadingSpinner />
              </div>
            ) : (
              <ChatsList
                chats={sessions}
                changeSeen={handleSeenUpdate}
                fetchMessages={fetchMessages}
                handleSetSession={handleSetSession}
                courseId={id}
              />
            )}
          </div>
          <div
            className={`col-md-12 col-lg-8 col-sm-12 col-xs-12 ${classes.livechat} ${classes.rmPadding}`}
          >
            {msgLoading ? (
              <div
                className={classes["loading__container"]}
                style={{ height: "85%" }}
              >
                <LoadingSpinner />
              </div>
            ) : (
              <Massages messages={messages} height="500px" />
            )}
            <ChatBubbleInputs
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              handleOnEnter={(e) => handler(e)}
              messages={messages.reverse()}
              sendMessage={sendMessage}
              selectbox={true}
              options={replies}
            ></ChatBubbleInputs>
          </div>
        </div>
      </MainBox>
    </div>
  );
};

export default CoursePrivateChat;
