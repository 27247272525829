import { Formik } from "formik";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { paymentAction } from "../../../../../Services/api/toutorials/PaymentsProvaider";
import exit from "../../../../../assets/icons/remove-red.svg";
import ApproveIcon from "../../../../../assets/image/user-check.svg";
import RejectIcon from "../../../../../assets/image/user-x.svg";
import i18n from "../../../../../i18n/i18n";
import { Lang } from "../../../../../utils";
import { utcToLocal } from "../../../../../utils/utcToLocal";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainTable from "../../../../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import TableActionsColumn from "../../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "./walletDetailsList.module.css";

const WalletDetailsListTable = ({
  getWalletList,
  loading,
  walletList,
  isLoading,
}) => {
  const { t } = useTranslation();
  const approvePaymentModalRef = useRef(null);
  const rejectPaymentModalRef = useRef(null);
  const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false);
  const [showRejectPaymentModal, setShowRejectPaymentModal] = useState(false);
  const [paymentActionData, setPaymentActionData] = useState(null);

  const paymentsActionsList = [
    {
      id: "approve",
      icon: <img src={ApproveIcon} alt="" />,
      action: (id) => {
        openApprovePaymentModal(id);
      },
      tooltip: t("general.approve"),
    },
    {
      id: "reject",
      icon: <img src={RejectIcon} alt="" />,
      action: (id) => {
        openRejectPaymentModal(id);
      },
      tooltip: t("general.reject"),
    },
  ];

  const openApprovePaymentModal = (id) => {
    setShowApprovePaymentModal(true);
    setPaymentActionData({ id: id, status: "Approved" });
    approvePaymentModalRef.current.showModal();
  };

  const closeApprovePaymentModal = () => {
    approvePaymentModalRef.current.dismissModal();
    setShowApprovePaymentModal(false);
    setPaymentActionData(null);
  };

  const openRejectPaymentModal = (id) => {
    setShowRejectPaymentModal(true);
    setPaymentActionData({ id: id, status: "Rejected" });
    rejectPaymentModalRef.current.showModal();
  };

  const closeRejectPaymentModal = () => {
    rejectPaymentModalRef.current.dismissModal();
    setShowRejectPaymentModal(false);
    setPaymentActionData(null);
  };

  const approvePaymentHandler = () => {
    paymentAction({
      id: paymentActionData.id,
      status: paymentActionData.status,
    })
      .then((res) => {
        toast.success(res.data.msg);
        getWalletList();
      })
      .catch((err) => {
        console.log(err);
        toast.error(t("general.error"));
      })
      .finally(() => {
        closeApprovePaymentModal();
      });
  };

  const displayPaymentType = {
    course: t("trainer.course.label"),
    program_payment: t("trainer.program.label"),
    booth: t("booth.label"),
    exhibition_visit: t("Exhibition.label"),
  };

  const paymentDisplay = {
    Bank: t("general.bank"),
    Wallet: t("general.wallet"),
    tebX: t("general.tebX"),
  };
  const displayGift = (is_gift) => {
    if (is_gift) {
      return t("yes");
    }
    return t("no");
  };

  const displayStatus = {
    Waiting: <span className={classes["waiting"]}>{t("general.waiting")}</span>,
    Approved: (
      <span className={classes["approved"]}>{t("general.approved")}</span>
    ),
    Rejected: (
      <span className={classes["rejected"]}>{t("general.rejected")}</span>
    ),
    Canceled: (
      <span className={classes["canceled"]}>{t("general.canceled")}</span>
    ),
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "transaction_type",
      name: t("wallet.label.WDtransactiontype"),

      key: "transaction_type",
      cell: ({ rowData }) => <div>{rowData?.payable_target}</div>,
      sortable: false,
    },
    {
      id: "payment_type",
      name: t("payments.payable_type"),
      key: "payment_type",
      cell: ({ rowData }) => (
        <>{displayPaymentType[rowData?.payable_type] || "-"}</>
      ),
    },
    {
      id: "payable_name",
      name: t("payments.payable_name"),
      key: "payable_name",
      cell: ({ rowData }) => <>{rowData?.payable_name || "-"}</>,
    },
    {
      id: "payment_courses",
      name: t("payments.payment_courses"),
      key: "payment_courses",
      cell: ({ rowData }) =>
        rowData?.covered_courses?.length > 0 ? (
          <p>
            {rowData?.covered_courses?.map((course) => course.name).join(" | ")}
          </p>
        ) : (
          <p>-</p>
        ),
    },
    // {
    //   id: "amount",
    //   name: t("wallet.label.WDamount"),
    //   key: "amount",
    //   cell: ({ rowData }) => (
    //     <ul>
    //       {rowData.amount} {t("rs")}
    //     </ul>
    //   ),
    //   sortable: false,
    // },
    // {
    //   id: "nametransactiondirection",
    //   name: t("wallet.label.WDnametransactiondirection"),

    //   key: "nametransactiondirection",
    //   cell: ({ rowData }) => <div>{rowData?.morph_data?.name || "-"}</div>,
    //   sortable: false,
    // },
    // {
    //   id: "transactiondirection",
    //   name: t("wallet.label.WDtransactiondirection"),

    //   key: "transactiondirection",
    //   cell: ({ rowData }) => <div>{rowData?.morph_type || "-"}</div>,
    //   sortable: false,
    // },
    // {
    //   id: "gift",
    //   name: t("gift"),
    //   key: "gift",
    //   cell: ({ rowData }) => <>{displayGift(rowData?.is_gift) || "-"}</>,
    // },
    // {
    //   id: "origianl_price",
    //   name: t("payments.original_price"),
    //   key: "origianl_price",
    //   cell: ({ rowData }) => (
    //     <>{`${rowData?.origianl_price} ${t("rs")}` || "-"}</>
    //   ),
    // },
    {
      id: "payment_price",
      name: t("payments.payment_price"),
      key: "payment_price",
      cell: ({ rowData }) => (
        <>{`${Number(rowData?.paid_amount)} ${t("rs")}` || "-"}</>
      ),
    },
    // {
    //   id: "discount_price",
    //   name: t("payments.discount_price"),
    //   key: "discount_price",
    //   cell: ({ rowData }) => (
    //     <>{`${Number(rowData?.offer_amount)} ${t("rs")}` || "-"}</>
    //   ),
    // },
    // {
    //   id: "tax_price",
    //   name: t("payments.tax_price"),
    //   key: "tax_price",
    //   cell: ({ rowData }) => (
    //     <>{`${Number(rowData?.tax_amount)} ${t("rs")}` || "-"}</>
    //   ),
    // },
    // {
    //   id: "coupon_price",
    //   name: t("payments.coupon_price"),
    //   key: "coupon_price",
    //   cell: ({ rowData }) => (
    //     <>{`${Number(rowData?.coupon_amount)} ${t("rs")}` || "-"}</>
    //   ),
    // },
    // {
    //   id: "processed_by",
    //   name: t("general.processed_by"),
    //   key: "processed_by",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.processor ? (i18n.language === "ar" ? rowData.processor.name_ar : rowData.processor.name_en) : "-"}
    //     </>
    //   ),
    // },
    {
      id: "rejection_reason",
      name: t("financial.payments_request.rejected_reason"),
      key: "rejection_reason",
      // cell: ({ rowData }) => <>{rowData?.rejection_reason || "-"}</>,
      cell: ({ rowData }) => (
        <div
          dangerouslySetInnerHTML={{
            __html: rowData?.rejection_reason || "-",
          }}
        ></div>
      ),
    },
    {
      id: "created_at",
      name: t("wallet.label.WDtransactiondata"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {rowData?.created_at ? (
            <>
              {moment(rowData?.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.created_at)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
      sortable: false,
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => <>{displayStatus[rowData?.status] || "-"}</>,
    },
  ];
  return (
    <>
      <MainTable
        columns={columns}
        data={walletList || []}
        isLoading={loading || isLoading}
      />
      <BasicModal ref={approvePaymentModalRef}>
        {showApprovePaymentModal && (
          <SharedModal
            title={t("payments.approve")}
            cancelText={t("general.cancel")}
            confirmMsg={t("payments.approve_msg")}
            confirmText={t("general.approve")}
            onConfirm={approvePaymentHandler}
            onCancel={closeApprovePaymentModal}
          />
        )}
      </BasicModal>
      <BasicModal ref={rejectPaymentModalRef}>
        {showRejectPaymentModal && (
          <MainBox className="p-0 rounded">
            <div className={classes["reject-payment-modal"]}>
              <div className={classes["reject-payment-modal__header"]}>
                <h3>{t("general.reject")}</h3>
                <img src={exit} alt="" onClick={closeRejectPaymentModal} />
              </div>
              <div className={classes["reject-payment-modal__body"]}>
                <Formik
                  initialValues={{
                    rejection_reason: "",
                  }}
                  onSubmit={(values) => {
                    const data = {
                      id: paymentActionData.id,
                      status: paymentActionData.status,
                      rejectionReason: values.rejection_reason,
                    };
                    paymentAction(data)
                      .then((res) => {
                        toast.success(res.data.msg);
                        getWalletList();
                      })
                      .catch((err) => {
                        console.log(err);
                        toast.error(t("general.error"));
                      })
                      .finally(() => {
                        closeRejectPaymentModal();
                      });
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.rejection_reason) {
                      errors.rejection_reason = t("crud.errors.required");
                    }
                    return errors;
                  }}
                  validateOnChange={true}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    errors,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="rejection_reason">
                          {t("financial.payments_request.rejected_reason")}
                        </label>
                        <textarea
                          id="rejection_reason"
                          name="rejection_reason"
                          style={{
                            border: "1px solid rgb(201, 200, 200)",
                            display: "block",
                            width: "500px",
                            borderRadius: "6px",
                            padding: "15px",
                          }}
                          maxLength={3000}
                          value={values.rejection_reason}
                          onChange={handleChange}
                          placeholder={t(
                            "financial.payments_request.rejected_reason"
                          )}
                          defaultValue={values.rejection_reason}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.rejection_reason
                            ? errors.rejection_reason
                            : ""}
                        </p>
                      </div>
                      <div className={classes["reject-payment-modal__submit"]}>
                        <CustomButton
                          value={t("submit")}
                          type="submit"
                          action={handleSubmit}
                          colors="#036c77"
                          disabled={false}
                          classes={classes["reject-payment-modal__submit-btn"]}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </MainBox>
        )}
      </BasicModal>
    </>
  );
};

export default WalletDetailsListTable;
