import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SelectGroupModel.module.css";
import Select from "react-select";
import { Formik } from "formik";
import { toast } from "react-toastify";
import CustomButton from "../CustomButton/CustomButton";
import {
  getGroupsForUser,
  getMyPlatform,
} from "../../../../Services/api/PlatformManagment/PlatformManagmentProvider";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    overflowY: "auto",
    // height: "500px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
    "&::-webkit-scrollbar": {
      width: 10,
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#DADADA",
      borderRadius: 10,
    },
  },
  alertOctagon: {
    marginBottom: 38,
    width: 80,
    height: 80,
  },
  confirmPaymentTxt: {
    textAlign: "center",
    fontSize: 24,
    color: "#555555",
    fontWeight: 400,
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    justifyContent: "space-between",
  },
  yesBtn: {
    backgroundColor: "#24B3B9",
    color: "#ffffff",
    borderRadius: 6,
    borderColor: "#EBEBEB",
    width: 150,
    height: 50,
  },
  noBtn: {
    backgroundColor: "#CFCFCF",
    color: "#858585",
    borderRadius: 6,
    borderColor: "#EBEBEB",
    width: 150,
    height: 50,
  },
  btnBack: {
    webkitAppearance: "button",
    backgroundColor: "transparent",
    backgroundImage: "none",
  },
  customButton: {
    width: "180px",
    height: "50px",
    background: "#025299",
    borderRadius: "6px",
    opacity: 1,
    fontSize: "18px",
    fontFamily: "dinNextRegular, sans-serif",
    textAlign: "center",
  },
  uploadBtn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 26,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const SelectGroupModel = ({
  openModal,
  setOpenModal,
  modalTitle,
  buttonText,
  handleSubmit,
  userId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [inputValue, setinputValue] = useState("");
  const [inputBody, setinputBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const changeHandler = (e) => {
    setinputValue(e.target.value);
  };

  const submitHandler = () => {
    handleSubmit();
  };

  function CloseIcon({ className }) {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <g
          id="x-circle_2_"
          data-name="x-circle (2)"
          transform="translate(-1 -1)"
        >
          <circle
            id="Ellipse_23"
            data-name="Ellipse 23"
            cx="10"
            cy="10"
            r="10"
            transform="translate(2 2)"
            fill="none"
            stroke="#e62626"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_35"
            data-name="Line 35"
            x1="6"
            y2="6"
            transform="translate(9 9)"
            fill="none"
            stroke="#e62626"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_36"
            data-name="Line 36"
            x2="6"
            y2="6"
            transform="translate(9 9)"
            fill="none"
            stroke="#e62626"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    );
  }
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [myGroups, setMyGroups] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getGroupsForUser(userId)
      .then((res) => {
        if (res.status === 200) {
          // console.log(res);
          setMyGroups(res.data.groups);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={openModal}>
        <Box className={styles.modalContainer}>
          <div className={styles.modal_container}>
            <div className={styles.modal_header_row}>
              <Typography
                id="modal-modal-title"
                variant="h3"
                style={{
                  //   font: "dinNextRegular, sans-serif",
                  color: "#025299",
                  //   fontWeight: "bold",
                }}
              >
                {modalTitle}
              </Typography>
              <button type="button" onClick={() => setOpenModal(false)}>
                <CloseIcon className="tw-h-6 tw-w-6" />
              </button>
            </div>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div>
                <Formik
                  initialValues={{
                    groups: myGroups.filter(
                      (group) => group.is_selected === true
                    ),
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      let dataToBeUploaded = {
                        ...values,
                      };
                      dataToBeUploaded.groups = dataToBeUploaded.groups.map(
                        (group) => group.id
                      );
                      let response = await handleSubmit(
                        dataToBeUploaded.groups
                      );

                      setOpenModal(false);

                      setSubmitting(false);
                    } catch (err) {
                      console.log("err", err);
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {err?.data?.message
                            ? err?.data?.message
                            : "Failure in service"}
                        </span>
                      );
                    }
                  }}
                  validateOnChange={hasSubmitted}
                  validate={(values) => {
                    setHasSubmitted(true);
                    const errors = {};
                    if (values.groups.length == 0) {
                      errors.groups = t("crud.errors.required");
                    }
                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    handleBlur,
                    setFieldValue,
                    validateForm,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className={styles["select-field"]}>
                        <label htmlFor={"groups"}>{t("selectGroups")}</label>

                        <Select
                          isMulti
                          name={"groups"}
                          onChange={(value) => {
                            setFieldValue("groups", value);
                          }}
                          className={styles["select"]}
                          options={myGroups.map((group) => {
                            return {
                              id: group.id,
                              value: group.id,
                              label: `${group.name}`,
                            };
                          })}
                          getOptionValue={(group) => group.id}
                          placeholder={t("selectGroups")}
                          defaultValue={values.groups.map((group) =>
                            group.is_selected
                              ? {
                                  id: group.id,
                                  value: group.id,
                                  label: group.name,
                                }
                              : null
                          )}
                        />

                        <p
                          className={`form-input-error-space ${classes["select-field-error"]}`}
                        >
                          {errors.groups && errors.groups}
                        </p>
                      </div>
                      <div className={styles.done_btn_container}>
                        <CustomButton
                          type={"submit"}
                          value={buttonText}
                          classes={styles.customButton}
                          colors={"#025299"}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SelectGroupModel;
