import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";

import DeleteIcon from "../../../../assets/icons/delete.svg";

import editIcon from "../../../../assets/icons/pen.svg";

import { useContext, useState } from "react";
import { toast } from "react-toastify";
import {
  deletePeymentRequest,
  updatePeymentRequestStatus,
} from "../../../../Redux/Actions/peymentRequest/peymentRequest.action";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import classes from "./peymentRequest.module.css";
const PeymentRequestTable = ({ userId }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});
  //////
  const {
    peymentRequests,
    peymentRequestsLoading,
    deletePeymentRequestLoading,
  } = useSelector((state) => state.peymentRequestsReducer);
  ///////////

  const coursesTableActionsList = (mode) => {
    return [
      {
        id: "edit",
        icon: <img src={editIcon} alt="" />,
        action: (id) => {
          //   history.push(`/admin/${isProgram ? "program" : "course"}/edit/${id}`);
        },
        tooltip: (name) => {
          return `${t("general.edit")} ${name}`;
        },
      },
      {
        id: "delete",
        icon: <img src={DeleteIcon} alt="" />,
        action: (id, status, number_of_users, is_started) => {
          console.log("delete course");
          if (
            status === "Draft" ||
            (status === "Published" && !is_started && number_of_users === 0)
          ) {
            setSelectedCourse({
              id,
              name: peymentRequests.find((course) => course.id === id).name,
            });
            setShowDeleteModal(true);
          } else {
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("courses_list.cannot_delete_course")}
              </span>
            );
          }
        },
        tooltip: (name) => {
          return `${t("general.delete")} ${name}`;
        },
      },
    ];
  };
  /////////////////
  const updateCourseStatusHandler = (id) => {
    dispatch(
      updatePeymentRequestStatus({
        id,
      })
    );
  };
  const deleteCourseHandler = (id) => {
    dispatch(
      deletePeymentRequest({
        id,
      })
    );
    setShowDeleteModal(false);
  };
  ///////////////////

  const displayMode = (type) => {
    if (type === "Course") {
      return t("rate.label.course");
    } else {
      return t("general.program");
    }
  };

  const displayStatus = (status) => {
    if (status === "Accepted") {
      return t("general.accepted");
    } else if (status === "Waiting") {
      return t("general.waiting");
    } else if (status === "Rejected") {
      return t("general.rejected");
    }
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },

    {
      id: "name",
      name: t(`financial.payments_request.title`),
      key: "name",
      cell: ({ rowData }) => (
        <>
          {`${t("financial.payments_request.request")} ${rowData?.id}` || "-"}
        </>
      ),
      sortable: false,
      align: "start",
    },
    {
      id: "notes",
      name: t("financial.payments_request.notes"),
      key: "notes",
      cell: ({ rowData }) => (
        <>
          {
            <div style={{ width: 300 }}>
              <p className={classes["note_table"]}>{rowData?.note || "-"}</p>
            </div>
          }
        </>
      ),
    },

    {
      id: "type",
      name: t("zoom.type"),
      key: "type",
      cell: ({ rowData }) => (
        <>
          <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.type == "Course" ? "#8599B6" : "#12C689",
              backgroundColor:
                rowData?.type == "Course" ? "#C1CEE222" : "#C1E2C822",
            }}
          >
            {displayMode(rowData?.type) || "-"}
          </span>
        </>
      ),
    },
    {
      id: "program_title",
      name: t("financial.payments_request.program_title"),
      key: "program_title",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData?.type === "Course"
              ? rowData?.course?.program?.name || "-"
              : rowData?.course?.name}
          </span>
        </>
      ),
    },

    {
      id: "program_code",
      name: t("financial.payments_request.program_code"),
      key: "program_code",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData?.type === "Course"
              ? rowData?.course?.program?.code || "-"
              : rowData?.course?.code}
          </span>
        </>
      ),
    },
    {
      id: "course_title",
      name: t("financial.payments_request.course_title"),
      key: "course_title",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData.type === "Program" ? "-" : rowData?.course?.name || "-"}
          </span>{" "}
        </>
      ),
    },

    {
      id: "course_code",
      name: t("financial.payments_request.course_code"),
      key: "course_code",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData.type === "Program" ? "-" : rowData?.course?.code || "-"}
          </span>
        </>
      ),
    },

    {
      id: "course_run_serial_number",
      name: t("financial.payments_request.course_run_serial_number"),
      key: "course_run_serial_number",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData?.course?.run_serial_number}
          </span>
        </>
      ),
    },
    {
      id: "status",
      name: t("financial.payments_request.status"),
      key: "status",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color:
              rowData.status === "Accepted"
                ? "#46BB3E"
                : rowData.status === "Waiting"
                ? "#8599B6"
                : rowData.status === "Rejected"
                ? "#E22020"
                : "normal",
            backgroundColor:
              rowData.status === "Accepted"
                ? "#C1E2C822"
                : rowData.status === "Waiting"
                ? "#C1CEE222"
                : rowData.status === "Rejected"
                ? "#E8919122"
                : "normal",
          }}
        >
          {displayStatus(rowData?.status) || "-"}
        </span>
      ),
      sortable: false,
    },

    {
      id: "trainer_total_amount",
      name: t("financial.payments_request.trainer_total_amount"),
      key: "trainer_total_amount",
      cell: ({ rowData }) => (
        <>
          {rowData?.dues.length !== 0
            ? rowData?.dues.map((due) =>
                due.trainer_id == userId
                  ? due.value
                    ? `${due.value} ${t("rs")}`
                    : "-"
                  : ""
              )
            : "-"}
        </>
      ),
    },
    {
      id: "added_amount_when_accepted",
      name: t("financial.payments_request.added_amount_when_accepted"),
      key: "added_amount_when_accepted",
      cell: ({ rowData }) => (
        <>{rowData?.amount ? `${rowData?.amount} ${t("rs")}` : "-"}</>
      ),
    },
    {
      id: "trainer_received_amounts",
      name: t("financial.payments_request.trainer_received_amounts"),
      key: "trainer_received_amounts",
      cell: ({ rowData }) => (
        <>{rowData?.totalPaid ? `${rowData?.totalPaid} ${t("rs")}` : "-"}</>
      ),
    },

    {
      id: "rejected_reason",
      name: t("financial.payments_request.rejected_reason"),
      key: "rejected_reason",
      cell: ({ rowData }) => (
        <>
          <div style={{ width: 300 }}>
            <p className={classes["note_table"]}>
              {rowData?.reject_reason || "-"}
            </p>
          </div>
        </>
      ),
    },

    {
      id: "request_date",
      name: t("financial.payments_request.request_date"),
      key: "request_date",
      cell: ({ rowData }) => (
        <>
          {rowData?.created_at ? (
            <>
              {moment(rowData?.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.created_at)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "processed_at",
      name: t("financial.payments_request.action_date"),
      key: "processed_at",
      cell: ({ rowData }) => (
        <>
          {rowData?.processed_at ? (
            <>
              {moment(rowData?.processed_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.processed_at)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "processed_by",
      name: t("financial.payments_request.action_by"),
      key: "processed_by",
      cell: ({ rowData }) => (
        <>
          {(i18n.language === "ar"
            ? rowData?.processor?.name_ar
            : rowData?.processor?.name_en) || "-"}
        </>
      ),
    },
  ];

  return (
    <>
      <MainTable
        columns={columns}
        data={peymentRequests || []}
        isLoading={peymentRequestsLoading}
      />
      ;
      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon}
          title={t("Exhibition.Exhibition_delete_title")}
          name={selectedCourse.name}
          confirmMsg={t("Exhibition.Exhibition_delete_confirmMsg")}
          confirmText={t("Exhibition.confirm")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            // deleteCourseHandler(selectedCourse.id);
          }}
          isLoading={deletePeymentRequestLoading}
        />
      )}
    </>
  );
};

export default PeymentRequestTable;
