import env from "../Environment";
import { exportApi } from "../Essential";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function addSurvey(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_survey, payload, {})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateSurvey(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.update_survey, { id: id }), payload, {})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateTrackingStatus({ id, payload }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .put(URLBuilder(env.update_tracking_status, { id: id }), payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSurvies(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_survies, {
          params: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getExhibitionsSurvies() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_exhibitions_survey)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getExhibitionListSurvies() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_exhibition_list_survey)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getAllCourses() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_all_courses)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getLessonById(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_by_id, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportSurvies(type, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      exportApi(type, env.get_survies, params, "surveys").then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportParticipants(type, id, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      exportApi(type, URLBuilder(env.get_surveys_participants, { id: id }), params, "participants").then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSurvey(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_survey, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSurveysForLessonOrCourse(courseId, lessonId) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_surveys_lesson_and_course, {
          params: { course_id: courseId, lesson_id: lessonId },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getSurveysForExhibition(exhibitionId) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_surveys_lesson_and_course, {
          params: { exhibition_id: exhibitionId },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSurveysPublic(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_public_surveys, { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getStatisticsSurvey(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_surveys_statistics, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getParticipantsSurvey(id, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_surveys_participants, { id: id }), {
          params: params,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function responseSurvey(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.response_survey, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err.response);
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getResponseSurvey(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.response_survey, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err.response);
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getAllUsers(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_all_users, {
          params: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getAllUsersForSearch(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.livechat_users_filter, {
          params: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteChooseFromSurveyQuestion(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.remove_choose_question_survey, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSurveyResponse(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_survey_response, { params: { survey_id: id } })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteSurvey(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_survey, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function toggleSurveyAccessibility(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.disable_survey, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteSurveyQuestion(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.remove_question_survey, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateEndTimeSurvey(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.update_end_time_survey, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSurveysStatistics() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_surveys_statistics_dashboard)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSurveysStatisticsQuestions(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_surveys_statistics_questions, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportSurveysStatisticsQuestions(id, type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      exportApi(
        type,
        URLBuilder(env.get_surveys_statistics_questions, { id: id }),
        {},
        "surveys-statistics-questions"
      ).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addCloneFromSurvey(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.add_clone_from_survey, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
