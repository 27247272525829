import React from "react";
import classes from "./userCard.module.css";
import Title from "../Title/Title";
import StarsRating from "../StarsRating/StarsRating";
import studentsicon from "../../../../assets/icons/students.svg";
import courseicon from "../../../../assets/icons/courses.svg";
import Icon3 from "../../../../assets/icons/Icon3.svg";
import Icon4 from "../../../../assets/icons/Icon4.svg";
import { useTranslation } from "react-i18next";

const UserCard = ({
  img,
  name,
  rate,
  postionTitle,
  description,
  countCourses,
  countUsers,
  trainer,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={classes["user-card"]}>
      <div className={classes["card-content"]}>
        <div class={classes.content}>
          <img src={img} alt={name} className={classes.img} />
          <div className={classes["name"]}>
            <Title title={name} fontSize={24} titleColor={"#012327"} />
          </div>
          <div className={classes.job_title}>
            <p>
              {i18n.language == "ar"
                ? trainer?.job_title_ar
                : trainer?.job_title_en}
            </p>
          </div>
          <div className={classes["star-dep-cat"]}>
            <p>{`${t("department")} : ${
              trainer?.department?.name || t("unspecified")
            }`}</p>
            <p>&nbsp;| &nbsp;</p>
            <p>{`${t("department_list.specialty")} : ${
              trainer?.specialty?.name || t("unspecified")
            }`}</p>
          </div>
          <div className={classes["flip-star"]}>
            <StarsRating
              rating={rate || 0}
              cutStyle={classes["star-font-size"]}
            />
          </div>
          <div className={classes.num_courses}>
            <div className={classes.numContainer}>
              <img src={Icon3} alt="" />
              {trainer && trainer.countCourses}
            </div>
            <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp; &nbsp;</span>
            <div className={classes.numContainer}>
              <img src={Icon4} alt="" />
              {trainer && trainer.countUsers}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
