import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CloseIcon from "../../../../assets/icons/remove-red.svg";
import Input from "../Input/Input";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import classes from "./paymentDetailsModal.module.css";
import { downloadFile } from "../../../../utils/downloadFile";

const PaymentDetailsModal = ({
  closeModal,
  approveAction,
  rejectAction,
  approveLoading,
  type,
}) => {
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);
  const { orderDetails, orderDetailsLoading, orderDetailsError } = useSelector(
    (state) => state.paymentReducer
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const inputFields = [
    {
      label: t("financial.refund.account_holder_name"),
      type: "text",
      name: "user_name",
      value: orderDetails?.account_holder_name,
      readonly: true,
    },
    {
      label: t("financial.refund.phone_number"),
      type: "text",
      name: "phone",
      value: orderDetails?.phone_number,
      readonly: true,
    },
    {
      label: t("financial.refund.bank_name"),
      type: "text",
      name: "bank_name",
      value: orderDetails?.bank_name,
      readonly: true,
    },
    {
      label: t("financial.refund.IBAN_number"),
      type: "text",
      name: "iban",
      value: orderDetails?.iban_number,
      readonly: true,
    },
  ];

  return (
    <div className={classes["payment-details-modal"]}>
      <div
        className={classes["payment-details-modal__container"]}
        ref={modalRef}
      >
        <div
          className={classes["payment-details-modal__close"]}
          onClick={() => {
            closeModal();
          }}
        >
          <img src={CloseIcon} alt="" />
        </div>
        {orderDetailsLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className={classes["payment-details-modal__header"]}>
              <div className={classes["payment-details-modal-title"]}>
                {t("Exhibition.bank_transfers")}
              </div>
              <div className={classes["payment-details-modal-value"]}>
                <div className={classes["payment-details-modal-value-label"]}>
                  {t("Exhibition.amount_required")}
                </div>

                <div className={classes["payment-details-modal-value-box"]}>
                  {orderDetails?.amount}
                  <sub>{t("Exhibition.sar_currency")}</sub>
                </div>
              </div>
              {orderDetails?.cancel_reason && (
                <div className={classes["payment-details-modal-value"]}>
                  <div className={classes["payment-details-modal-value-label"]}>
                    {t("Exhibition.cancel_reason")}
                  </div>
                  <div
                    className={
                      classes["payment-details-modal-cancel-value-box"]
                    }
                  >
                    {orderDetails?.cancel_reason}
                  </div>
                </div>
              )}
            </div>
            <div className={classes["payment-details-modal__content"]}>
              {inputFields.map((input) => (
                <div
                  className={classes["payment-details-modal__field"]}
                  key={input.name}
                >
                  <Input
                    label={input.label}
                    type={input.type}
                    name={input.name}
                    value={input.value}
                    readonly
                  />
                </div>
              ))}
              <div className={classes["payment-details-modal__image"]}>
                <img src={orderDetails?.payment_proof_img_name} alt="" />
                <button
                  className={classes["payment-details-modal__image-download"]}
                  onClick={() =>
                    downloadFile(orderDetails?.payment_proof_img_name)
                  }
                >
                  <i className="fa fa-download"></i>
                </button>
              </div>
            </div>
          </>
        )}
        {type == !"sponser" ? (
          <div className={classes["payment-details-modal__actions"]}>
            <button
              className={`${classes["payment-details-modal__actions-btn"]} ${classes["primary"]}`}
              onClick={() => {
                approveAction(orderDetails?.id);
              }}
              disabled={orderDetailsLoading || approveLoading}
            >
              {approveLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress color="white" size={30} />
                </Box>
              ) : (
                t("financial.refund.approval")
              )}
            </button>
            <button
              className={`${classes["payment-details-modal__actions-btn"]} ${classes["reject"]}`}
              onClick={() => {
                rejectAction(orderDetails?.id);
              }}
              disabled={orderDetailsLoading}
            >
              {t("financial.refund.reject")}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentDetailsModal;
