import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TrainerAddExamContext } from "../../../../../../../Providers/TrainerAddExamProvider";
import Select from "react-select";
import QuestionTypeEnum from "../../../../../../../Enums/QuestionTypeEnum";
import TextQuestion from "../TextQuestion/TextQuestion";
import MultipleSelectQuestion from "../MultipleSelectQuestion/MultipleSelectQuestion";
import SingularSelectQuestion from "../SingularSelectQuestion/SingularSelectQuestion";
import { AddQuestionToQuestionBankContext } from "../../../../../../../Providers/AddQuestionToQuestionBankProvider";
import { useHistory, useParams } from "react-router";
import {
  addQuestionToQuestionBank,
  addQuiz,
  editQuestionToQuestionBank,
} from "../../../../../../../Services/api/exams/ExamsProvider";
import { toast } from "react-toastify";
import H5pQuesiton from "../H5pQuestion/H5pQuestion";
import OrderingQuestion from "../OrderingQuestion/Wrapper/Wrapper";
import HotSpotQuestion from "../HotSpotQuestion/HotSpotQuestion";
import DragAndDropQuestion from "../DragAndDropQuestion/DragAndDropQuestion";
import ScormQuestion from "../ScormQuestion/ScormQuestion";
import XAPIQuestion from "../XAPIQuestion/XAPIQuestion";
import EssayQuestion from "../EssayQuestion/EssayQuestion";
import HtmlQuestion from "../HtmlQuestion/HtmlQuestion";
import SkeletonCardOverlay from "../../../../Spinner/SkeletonCardOverlay";

function AddQuestion(props) {
  require("./Add.css");

  const { t } = useTranslation();
  const { id, quizable_id, exam_id, question_id } = useParams();
  const history = useHistory();

  const wrapperContext = useContext(
    props.renderedAs === "questionBank"
      ? AddQuestionToQuestionBankContext
      : TrainerAddExamContext
  );
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [showDefaultNavigationButton, setShowDefaultNavigationButton] =
    useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (wrapperContext.isEdit) {
      setSelectedQuestionType(wrapperContext.question.type.id);
    } else {
      setSelectedQuestionType(null);
    }
  }, [wrapperContext.isEdit]);

  useEffect(() => {
    if (
      selectedQuestionType == QuestionTypeEnum.text ||
      selectedQuestionType == QuestionTypeEnum.radio_button ||
      selectedQuestionType == QuestionTypeEnum.checkbox ||
      selectedQuestionType == QuestionTypeEnum.ordering ||
      selectedQuestionType == QuestionTypeEnum.hotspot
    ) {
      setShowDefaultNavigationButton(false);
    } else {
      setShowDefaultNavigationButton(true);
    }
  }, [selectedQuestionType]);

  const onQuestionSubmit = (payload) => {
    console.log(payload);
    const myPromise = new Promise(async (resolve, reject) => {
      payload.type_id = selectedQuestionType;
      let courseObjectives = [];
      payload.course_objectives.forEach((item) => {
        if (Number.isInteger(item)) {
          courseObjectives.push(item);
        } else {
          courseObjectives.push(item.id);
        }
      });
      payload.course_objectives = courseObjectives;
      if (props.renderedAs == "questionBank") {
        payload.parent_id = parseInt(id);
        try {
          if (wrapperContext.isEdit) {
            await editQuestionToQuestionBank(payload, question_id);
          } else {
            await addQuestionToQuestionBank(payload);
          }
          resolve();
          history.push("/trainer/course/" + id + "/question-bank");
        } catch (err) {
          if (
            err.response.data &&
            err.response.data.errors &&
            typeof err.response.data.errors === "object" &&
            !Array.isArray(err.response.data.errors) &&
            err.response.data.errors !== null
          ) {
            Object.keys(err.response.data.errors).forEach((errorItemKey) => {
              if (Array.isArray(err.response.data.errors[errorItemKey])) {
                toast.error(
                  <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    {err.response.data.errors[errorItemKey][0]}
                  </span>
                );
              }
            });
          }
          reject();
        }
      } else {
        const examPayload = wrapperContext.examPayload;
        if (!payload.media_id) {
          payload.media_id = "";
        }

        if (wrapperContext.editable) {
          wrapperContext.submitExamEdits(payload, exam_id).then(
            (result) => {
              wrapperContext.fetchExamData(exam_id).then(
                (quizData) => {
                  toast.success(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                      {t("trainer.quiz.edited_successfully")}
                    </span>
                  );
                  setSelectedQuestionType(null);
                  wrapperContext.setAddedQuestions(quizData.added_questions);
                  resolve();
                },
                (err) => {
                  reject();
                }
              );
            },
            (err) => {
              reject();
            }
          );
        } else {
          setIsUpdating(true);
          examPayload.new_questions = [];
          examPayload.new_questions.push(payload);
          wrapperContext.setExamPayload(examPayload);
          addQuiz(examPayload)
            .then((res) => {
              wrapperContext.fetchExamData(res.data.quiz.id).then(
                (quizData) => {
                  toast.success(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                      {t("trainer.quiz.added_successfully")}
                    </span>
                  );

                  history.push({
                    pathname:
                      "/trainer/course/" +
                      id +
                      "/" +
                      wrapperContext.quizable +
                      "/" +
                      quizable_id +
                      "/exam/edit/" +
                      res.data.quiz.id,
                    state: {
                      questionTypes: wrapperContext.questionTypes,
                      courseObjectives: wrapperContext.objectives,
                      trainees: wrapperContext.trainees,
                      examInstance: quizData,
                      activePanel: wrapperContext.activePanel,
                      selectedQuestionPanel:
                        wrapperContext.selectedQuestionPanel,
                    },
                  });
                  resolve();
                },
                (err) => {
                  history.push(
                    "/trainer/course/" +
                      id +
                      "/" +
                      wrapperContext.quizable +
                      "/" +
                      quizable_id +
                      "/assessments"
                  );
                }
              );

              setIsUpdating(false);
            })
            .catch((err) => {
              if (
                err.response &&
                err.response.data &&
                err.response.data.errors &&
                typeof err.response.data.errors === "object" &&
                !Array.isArray(err.response.data.errors) &&
                err.response.data.errors !== null
              ) {
                Object.keys(err.response.data.errors).forEach(
                  (errorItemKey) => {
                    if (Array.isArray(err.response.data.errors[errorItemKey])) {
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {err.response.data.errors[errorItemKey][0]}
                        </span>
                      );
                    }
                  }
                );
              } else {
                console.log(err);
              }
              reject();

              setIsUpdating(false);
            });
        }
      }
    });

    return myPromise;
  };

  const renderQuestionTypeSection = () => {
    if (selectedQuestionType == QuestionTypeEnum.text) {
      return (
        <>
          <TextQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.radio_button) {
      return (
        <>
          <SingularSelectQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.checkbox) {
      return (
        <>
          <MultipleSelectQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.ordering) {
      return (
        <>
          <OrderingQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.h5p) {
      return (
        <>
          <H5pQuesiton
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.hotspot) {
      return (
        <>
          <HotSpotQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.drag_and_drop) {
      return (
        <>
          <DragAndDropQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.scorm) {
      return (
        <>
          <ScormQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.xapi) {
      return (
        <>
          <XAPIQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.essay_question) {
      return (
        <>
          <EssayQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    } else if (selectedQuestionType == QuestionTypeEnum.html) {
      return (
        <>
          <HtmlQuestion
            onSubmit={onQuestionSubmit}
            renderedAs={props.renderedAs}
          />
        </>
      );
    }
  };

  return (
    <>
      {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
      <div className={"tajah-form-row"}>
        <div className={"tajah-form-row-element-v2"}>
          <div
            className={"tajah-form-row-element-holder"}
            style={{ width: "95%" }}
          >
            <label htmlFor="name_en" className="admin_add_label">
              {t("trainer.quiz.crud.question_type")}
            </label>
            <Select
              name="question_type"
              id="question_type"
              isClearable={true}
              options={wrapperContext.questionTypes}
              getOptionLabel={(option) => {
                if (document.body.dir == "rtl") {
                  return option.name_ar;
                } else {
                  return option.name_en;
                }
              }}
              value={wrapperContext.questionTypes.filter((item) => {
                return item.id == selectedQuestionType;
              })}
              getOptionValue={(option) => option.id}
              onChange={(item) => {
                if (item) {
                  setSelectedQuestionType(item.id);
                } else {
                  setSelectedQuestionType(null);
                }
              }}
              placeholder={t("trainer.quiz.crud.select.question_type")}
            />
            <p className={"form-input-error-space"}></p>
          </div>
        </div>
      </div>
      {props.renderedAs !== "questionBank" && showDefaultNavigationButton ? (
        <div className={"tajah-form-row"}>
          <div className={"tajah-form-row-element-v2"}>
            <div
              className={"tajah-form-row-element-holder"}
              style={{
                width: "95%",
              }}
            >
              {/*<button*/}
              {/*    type="button"*/}
              {/*    className="admin_add_button"*/}
              {/*    style={{*/}
              {/*        backgroundColor: '#CDCDCD',*/}
              {/*        color: '#000000'*/}
              {/*    }}*/}
              {/*    onClick={() => {*/}
              {/*        wrapperContext.setActivePanel(0);*/}
              {/*    }}*/}
              {/*>*/}
              {/*    {t("crud.placeholders.previous")}*/}
              {/*</button>*/}
            </div>
          </div>
        </div>
      ) : null}
      {renderQuestionTypeSection()}
    </>
  );
}

export default AddQuestion;
