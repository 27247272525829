import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { eventsListConstants } from "../../Constants/events/events.constants";
import i18n from "../../../i18n/i18n";

// GET EVENTSS LIST
export const fetchEventssList = ({ page = 1, perPage = 10, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: eventsListConstants.GET_EVENTS_LIST_REQUEST,
    });

    await connector
      .get(env.events, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        // console.log(response);
        if (response?.status === 200) {
          dispatch({
            type: eventsListConstants.GET_EVENTS_LIST_SUCCESS,
            payload: {
              events: response?.data,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: eventsListConstants.GET_EVENTS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: eventsListConstants.GET_EVENTS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};
export const cancelEvent = (id, send_email) => {
  return async (dispatch) => {
    dispatch({ type: eventsListConstants.CANCEL_EVENT_REQUEST });
    await connector
      .post(URLBuilder(env.cancel_event, { id: id }), {
        send_email: send_email,
        _method: "put",
      })
      .then((response) => {
        console.log("response", response);
        if (response.status && response.status === 200) {
          dispatch({
            type: eventsListConstants.CANCEL_EVENT_SUCCESS,
            payload: { id },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {i18n.language === "en"
                ? "Cancelled Successfully"
                : "تم الغاء الفعالية بنجاح"}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: eventsListConstants.CANCEL_EVENT_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: eventsListConstants.CANCEL_EVENT_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};
