import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {TrainerAddExamContext} from "../../../../../../Providers/TrainerAddExamProvider";
import ExamQuestionPanel from "../ExamQuestion/ExamQuestion";
import QuestionBankPanel from "../QuestionBank/QuestionBank";
import AddedQuestionPanel from "../AddedQuestion/AddedQuestion";

function QuestionWrapper(props) {
    require("./Wrapper.css");
    const {t} = useTranslation();
    const trainerAddExamContext = useContext(TrainerAddExamContext);

    const handleScreenChange = (event, newValue) => {
        trainerAddExamContext.setSelectedQuestionPanel(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Tabs value={trainerAddExamContext.selectedQuestionPanel} onChange={handleScreenChange} aria-label="basic tabs example" sx={{
                marginBottom: 2
            }}>
                <Tab label={trainerAddExamContext.isEdit ? t("trainer.quiz.edit_question") : t("trainer.quiz.add_question")} {...a11yProps(0)} />
                <Tab label={t("trainer.quiz.question_bank")} {...a11yProps(1)} />
                {
                    trainerAddExamContext.editable?
                        <Tab label={t("trainer.quiz.added_questions")} {...a11yProps(2)} /> : null
                }
            </Tabs>
            <hr />
            <div
                role="tabpanel"
                hidden={0 !== trainerAddExamContext.selectedQuestionPanel}
                id={`simple-tabpanel-0`}
                aria-labelledby={`simple-tab-0`}>
                <ExamQuestionPanel />
            </div>

            <div
                role="tabpanel"
                hidden={1 !== trainerAddExamContext.selectedQuestionPanel}
                id={`simple-tabpanel-1`}
                aria-labelledby={`simple-tab-1`}>
                <QuestionBankPanel />
            </div>
            {
                trainerAddExamContext.editable?
                    <div
                        role="tabpanel"
                        hidden={2 !== trainerAddExamContext.selectedQuestionPanel}
                        id={`simple-tabpanel-2`}
                        aria-labelledby={`simple-tab-2`}>
                        <AddedQuestionPanel />
                    </div> : null
            }
        </>
    )
}

export default QuestionWrapper;
