import React from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowIcon from "../../../../assets/icons/arrow.png";
import classes from "./trainingCourseContentLessons.module.css";
import { useTranslation } from "react-i18next";

export default function CourseContentLessonsSyllabus({
  subChapter,
  setSelectedLesson,
  selectedLesson,
}) {
  const { i18n } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  return (
    <div
      className={classes["syllabus"]}
      style={
        i18n.language == "ar"
          ? { borderLeft: "1px solid #c8c8c8" }
          : { borderRight: "1px solid #c8c8c8" }
      }
    >
      <div className={classes["syllabus-header"]}>
        <div className={classes["syllabus-header-details"]}>
          <div
            className={classes["syllabus-body-sub-chapter"]}
            onClick={() => history.push(`/training-courses/${id}/content`)}
          >
            <div className={classes["syllabus-body-sub-chapter-icon"]}>
              <img src={ArrowIcon} alt="" />
            </div>
            <p className={classes["back_title"]}>{subChapter.name}</p>
          </div>
        </div>
        <div
          className={classes["syllabus-header-image"]}
          style={
            i18n.language == "ar" ? { paddingLeft: 20 } : { paddingRight: 20 }
          }
        >
          <img src={subChapter.course_image} alt="course" />
        </div>
        <h2 className={classes["course-title-sidebar"]}>
          {subChapter.course_name}
        </h2>
        <p
          dangerouslySetInnerHTML={{
            __html: subChapter.course_brief_description,
          }}
        ></p>
      </div>
      <div className={classes["syllabus-body"]}>
        <div className={classes["syllabus-body-lessons"]}>
          {subChapter?.lessons?.map((item, index) => (
            <div
              className={classes["syllabus-body-lessons-item"]}
              key={item.id}
              onClick={() => setSelectedLesson(item.id)}
            >
              <div className={classes["syllabus-body-lessons-item-number"]}>
                {index + 1}
              </div>
              <div className={classes["syllabus-body-lessons-item-name"]}>
                {item.title}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
