import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { CoursePaymentContext } from "../../../../../Services/api/courses/coursePaymentContext";
import { addToCart, payCourse } from "../../../../../Services/api/courses/courseProvider";
import master from "../../../../../assets/image/mastercard.png";
import video from "../../../../../assets/image/video.png";

function TrainingCoursePaymentWithCard(props) {
  require("./TrainingCoursePaymentWithCard.css");
  const { t } = useTranslation();
  const coursePaymentContext = useContext(CoursePaymentContext);
  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory();
  const handlePayment = async (event) => {
    event.preventDefault();

    const cardNumberElement = elements.getElement(CardNumberElement);
    try {
      let stripeResponse = await stripe.createToken(cardNumberElement);
      let addToCartResponse = await addToCart(
        props.courseDetailsContext.courseDetails.id,
        props.coupon,
        props.giftDataPayload ? props.giftDataPayload : ""
      );
      if (addToCartResponse.status === 201 && addToCartResponse.data.status) {
        let payload = {
          cart_id: addToCartResponse.data.cart.id,
          payment_type: "stripe",
          token: stripeResponse.token.id,
        };

        if (props.giftDataPayload) {
          payload = {
            ...payload,
            ...props.giftDataPayload,
          };
        }

        let paymentResponse = await payCourse(payload);
        props.paymentModalRef.current.dismissModal();
        if (props.giftDataPayload || props.courseDetailsContext.courseDetails.subscribed) {
          history.push(
            `/${props.courseDetailsContext.courseDetails.is_program ? "training-programs" : "training-courses"}${
              props.courseDetailsContext.courseDetails.id
            }/details`
          );
        } else {
          history.push("/training-courses/" + props.courseDetailsContext.courseDetails.id + "/profile");
        }
      }
    } catch (error) {
      toast.error(<span style={{ fontSize: 14 }}>{t("paymentModal.message.error")}</span>);
    }
  };

  return (
    <>
      <div className="modal_head">
        <div className="modal_head_label">
          <div className="">{t("safepurchase")}</div>
          <button
            type="button"
            className="close"
            onClick={() => {
              coursePaymentContext.setModal(false);
            }}
          >
            &times;
          </button>
        </div>
        <div className="modal_head_label_bottom">{t("allscr")}</div>
        <div className="modal_pay_head_card_after">
          <div className="modal_flex">
            <img src={props.courseDetailsContext.courseDetails.img} alt="..." className="modal_course_img_after" />
            <div className="modal_course_text_after">{props.courseDetailsContext.courseDetails.name}</div>
          </div>

          <div className="overall_modal">
            <div className="">
              {/*{((props.couponPrice || props.price && props.price.offer !== 0) || (props.couponPrice || props.price && props.price.tax !== 0)) && (*/}
              {/*    <div className="modal_coupon_price" style={{color:"black"}}>*/}
              {/*    {t("price")} : {props.couponPrice ? props.couponPrice.item_real_price : props.price.item_real_price}{" "}*/}
              {/*    {t("Rial")}*/}
              {/*    </div>*/}
              {/*)}*/}
              {props.couponPrice && (
                <div className="modal_coupon_price">
                  {t("price")} :{" "}
                  {props.couponPrice
                    ? props.couponPrice.real_price *
                      (props.couponPrice.purchaseDiscount / 100) *
                      props.couponPrice.numberOfPurchase
                    : props.giftDataPayload
                    ? props.price.to_be_paid.toFixed(2)
                    : props.courseDetailsContext?.courseDetails?.price}{" "}
                  {t("Rial")}
                </div>
              )}
              {props.couponPrice && (
                <div className="modal_coupon_price" style={{ color: "green" }}>
                  {t("dis_price")} : {props.couponPrice.discount_price * props.couponPrice.numberOfPurchase} {t("Rial")}
                </div>
              )}
              {(props.couponPrice || (props.price && props.price.paid_from_wallet !== 0)) && (
                <div className="modal_coupon_price" style={{ color: "green" }}>
                  {t("paid_from_wallet")} :{" "}
                  {props.couponPrice
                    ? props.couponPrice.paid_from_wallet.toFixed(2)
                    : props.price.paid_from_wallet.toFixed(2)}{" "}
                  {t("Rial")}
                </div>
              )}
              {(props.couponPrice || (props.price && props.price.tax !== 0)) && (
                <div className="modal_coupon_price" style={{ color: "green" }}>
                  {t("tax")} : {props.couponPrice ? props.couponPrice.tax.toFixed(2) : props.price.tax.toFixed(2)}{" "}
                  {t("Rial")}
                </div>
              )}
              {(props.couponPrice || (props.price && props.price.offer !== 0)) && (
                <div className="modal_coupon_price" style={{ color: "green" }}>
                  {t("offer")} : {props.couponPrice ? props.couponPrice.offer.toFixed(2) : props.price.offer.toFixed(2)}{" "}
                  {t("Rial")}
                </div>
              )}
              {props.couponPrice && (
                <div className="modal_coupon_price" style={{ fontSize: "18px" }}>
                  {t("total_price")} : {props.couponPrice.to_be_paid.toFixed(2)} {t("Rial")}
                </div>
              )}
              {!props.couponPrice && (
                <div className="modal_coupon_price" style={{ fontSize: "18px" }}>
                  {t("total_price")} :{" "}
                  {props.couponPrice
                    ? props.couponPrice.real_price *
                      (props.couponPrice.purchaseDiscount / 100) *
                      props.couponPrice.numberOfPurchase
                    : props.giftDataPayload || props.price
                    ? props.price.to_be_paid.toFixed(2)
                    : props.courseDetailsContext?.courseDetails?.price}{" "}
                  {t("Rial")}
                </div>
              )}
              <div className="overall_text">{t("rialsaudi")}</div>
            </div>
            {props.courseDetailsContext.courseDetails.video && (
              <div className="modal_coupon_video">
                <img src={video} alt="..." />
                <div className="">{t("videode")}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-body">
        <div className="modal_body_label">{t("howtopay")}</div>
        <div className="">
          <div className="modal_card_after">
            <img src={master} alt="" className="img_card_pay_after" />
            <div className="">
              <div className="modal_top_text">{t("visa")}</div>
              <div className="modal_bottom_text">{t("master")}</div>
            </div>
          </div>
        </div>
        <form onSubmit={handlePayment}>
          <div className="modal_form_after">
            <label htmlFor="cardName" className="form_modal_label">
              {t("numcard")}
            </label>
            <CardNumberElement />
            <label htmlFor="cardName" className="form_modal_label">
              {t("datecard")}
            </label>
            <CardExpiryElement />
            <label htmlFor="cardName" className="form_modal_label">
              {t("vsscard")}
            </label>
            <CardCvcElement />
          </div>

          <div className="d-block payment_button">
            <input type="submit" className="modal_btn submit_btn" value={t("paynow")} />

            <button
              className="btn mx-2"
              onClick={() => {
                coursePaymentContext.setPaymentScreen(0);
              }}
            >
              {t("back")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default TrainingCoursePaymentWithCard;
