import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {Formik} from "formik";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import {toast} from "react-toastify";
import {
    EditTrainerDues,
    getCourses,
    getCourseTrainersList
} from "../../../../Services/api/Financial/PercentageTrainers/PerTrainersProvider";


function EditTrainer() {
  require("./PercentageTrainers.css");
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [coursesData, setCoursesData] = useState([]);
  const [listOfTrainer, setListOfTrainer] = useState({});
  const [TrainerArr, setTrainerArr] = useState([]);

  useEffect(() => {
    _getCourses();
  },[])
  const _getCourses = async () => {
    let res = await getCourses();

    if(res.status === 200){
        setIsLoading(false)
        setCoursesData(res.data.courses)
    }
  }

  const courseSelected = async (e) =>{
        let id = e.target.value;
        let resList = await getCourseTrainersList(id)
        let trainerArr = [];

        if(resList.status === 200){
            setListOfTrainer(resList.data.response)
            console.log(resList.data.response)
            let trainer_count = resList.data.response.trainer_count;

            for(let i = 0; i< trainer_count; i++){
                if(resList.data.response[i]){
                    trainerArr.push(resList.data.response[i]);
                }
                if(i == trainer_count - 1){
                    console.log("trainerArr", trainerArr)
                    setTrainerArr(trainerArr)
                }
            }
        }

  }


  return (
    <>
      <div
        className="container-fluid"
        id={"content-section"}
        style={{
          position: "relative",
          padding: 0,
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: 50,
        }}
      >
        <div className="admin_flex" id="content-header-bar">
          <div className="admin_label">
            <NavLink
              to={`${
                localStorage.getItem("type") == "accountant"
                  ? "/accountant"
                  : "/admin"
              }`}
            >
              {t("admin.label.admin_label")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("Percentage_trainers_courses")}
          </div>
        </div>
        {isLoading == false ? (
          <div
            style={{width: "100%"}}
          >
                <select style={{ height: "40px", padding: "0 10px", width: "50%", margin: "10px 4px "}} className='admin_add_input' onChange = {(e)=> courseSelected(e)}>
                    <option>{t("block.label.Choose_course")}</option>
                    {
                        coursesData.map((course, key)=>{
                            return <option value={course.id} key={key}> {course.name} </option>
                        })
                    }
                </select>
                <Formik
                    initialValues={{
                        lang: '',
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            let TrainerArrValue = [];
                            if (Object.keys(listOfTrainer).length > 0 ){
                                for(let i=0; i < listOfTrainer.trainer_count; i++){
                                    let objtrainer = {
                                        "id": listOfTrainer[i].id,
                                        "mechanism": values[`mechanism_${i}`],
                                        "value": values[`value_${i}`],
                                        "payment_received": values[`payment_received_${i}`]? values[`payment_received_${i}`] : null
                                    }
                                    console.log('objtrainer',objtrainer)
                                    if(values[`mechanism_${i}`]){
                                        TrainerArrValue.push(objtrainer)
                                    }

                                    if(i  == listOfTrainer.trainer_count - 1){
                                        console.log(i, "< 4", TrainerArrValue)

                                        let dataToBeUploaded = {
                                            course_id: listOfTrainer.course_id,
                                            trainers: TrainerArrValue
                                        };
                                        console.log("dataToBeUploaded",dataToBeUploaded)
                                        let response = await EditTrainerDues(dataToBeUploaded)
                                        if (response.status === 201 && response.data.status) {

                                            toast.success(
                                                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                                     { response.data.msg? response.data.msg : response.data.response? response.data.response : "success"}
                                                </span>
                                            );
                                        }
                                    }
                                }
                            }
                        }
                         catch (err) {
                            // setIsLoading(false);
                            toast.error(
                                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                    {err.response.data && err.response.data.msg ? err.response.data.msg : "Failure in service"}
                                </span>
                            );
                        }
                    }}
                    // validateOnChange={hasSubmitted}
                    validate={(values) => {
                        const errors = {};
                        for(let i=0; i < listOfTrainer.trainer_count; i++){
                            if (!values[`mechanism_${i}`]) {
                                errors[`mechanism_${i}`] = t("crud.errors.required");
                            }
                            if (!values[`payment_received_${i}`]) {
                                errors[`payment_received_${i}`] = t("crud.errors.required");
                            }
                            if (!values[`value_${i}`]) {
                                errors[`value_${i}`] = t("crud.errors.required");
                            }
                        }

                        return errors;
                    }}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        handleBlur,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit} className="admin_add_form">
                            <div className="row">
                            <table className="table table-bordered" style={{margin: "30px 0"}}>
                                    <thead>
                                    <tr>
                                        <th scope="col">{t("name")}</th>
                                        <th scope="col">{t('mechanism')}</th>
                                        <th scope="col">{t('payment_received')}</th>
                                        <th scope="col">{t('value')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        TrainerArr.length > 0 && TrainerArr.map((trainer, key) => {
                                            return (
                                                <tr key={`trainer_${key}`}>
                                                    <td>{trainer.name}</td>
                                                    <td>
                                                        <select
                                                            style={{ height: "40px", padding: "0 10px"}}
                                                            className='admin_add_input'
                                                            name={`mechanism_${key}`}
                                                            onChange={handleChange}>
                                                            <option value='' > {t('select')}  </option>
                                                            <option value={1} > {t('co_value')}  </option>
                                                            <option value={2} >{t('per_value')} </option>
                                                        </select>
                                                        <p className={"form-input-error-space"}>
                                                            {errors[`mechanism_${key}`] ? errors[`mechanism_${key}`] : null}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <input
                                                                type="number"
                                                                name={`payment_received_${key}`}
                                                                className="admin_add_input"
                                                                onChange={handleChange}
                                                            />
                                                        <p className={"form-input-error-space"}>
                                                            {errors[`payment_received_${key}`] ? errors[`payment_received_${key}`] : null}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <input
                                                                type="number"
                                                                name={`value_${key}`}
                                                                className="admin_add_input"
                                                                onChange={handleChange}
                                                            />
                                                        <p className={"form-input-error-space"}>
                                                            {errors[`value_${key}`] ? errors[`value_${key}`] : null}
                                                        </p>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        }
                                    </tbody>

                                </table>
                                {
                                    TrainerArr.length == 0?
                                    <p style={{width: "100%"}} className=' text-center w-full'>{t('Please_choose_course')}</p> : null
                                }
                                {
                                    TrainerArr.length > 0?
                                        <div className="col-md-12">
                                            <button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="admin_add_button btn_zoom"
                                            >
                                                {t('save')}
                                            </button>
                                        </div>
                                    :
                                        null
                                }
                            </div>
                        </form>
                    )}
                </Formik>

          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default EditTrainer;
