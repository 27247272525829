import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import classes from "./mainSection.module.css";
import { useHistory } from "react-router";
import Img from "../../../assets/image/SliderPic.png";

import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import MainSectionCarousel from "../../Shared/Components/MainSectionCarousel/MainSectionCarousel";
import { getAdHome } from "../../../Services/api/HomeCoursesProvider";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
const MainSection = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [adList, setAdList] = useState([]);
  const [adListLoading, setAdListLoading] = useState(false);

  useEffect(() => {
    // setTrainersLoading(true);
    const apiCall = getAdHome;

    apiCall()
      .then((res) => {
        if (res.status && res.status === 200) {
          // console.log(res);
          setAdList(res.data.data);
          setAdListLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setAdListLoading(true);
      });
  }, []);

  return (
    <>
      {adListLoading ? (
        <div className={classes.text_img}>
          {adList.length === 0 ? (
            <div className={classes["main_defualt"]}>
              <div className={classes["main__wrapper"]}>
                <div className={classes["main__content_defualt"]}>
                  <h2 className={classes["main__content-title"]}>
                    {t("home.search_for_training_courses")}
                  </h2>
                  <p
                    className={classes["main__content-desc"]}
                    // style={{ fontSize: "14px" }}
                  >
                    {t("home.home_description")}
                  </p>
                </div>
                <div className={classes["search_container"]}>
                  <CustomButton
                    value={t("enroll")}
                    colors={"rgb(3, 162, 178)"}
                    classes={classes.btnRegister}
                    action={() =>
                      localStorage.type
                        ? history.push("/training-courses")
                        : history.push("/register")
                    }
                  />
                </div>
              </div>
              <div className={classes.imgcont}>
                <img src={Img} alt="" height={600} width={"100%"} />
              </div>
            </div>
          ) : (
            <>
              {adList.length === 1 ? (
                <>
                  {adList.map((ad) => (
                    <>
                      <div className={classes["main"]}>
                        <div className={classes["main__wrapper"]}>
                          <div className={classes["main__content"]}>
                            <h2 className={classes["main__content-title"]}>
                              {ad?.title}
                            </h2>
                            <p
                              className={classes["main__content-desc"]}
                              style={
                                ad?.description?.length > 710
                                  ? { fontSize: "14px" }
                                  : {}
                              }
                            >
                              {ad?.description}
                            </p>
                          </div>
                          {ad?.has_button && (
                            <div className={classes["search_container"]}>
                              <CustomButton
                                value={ad?.button_text}
                                colors={"rgb(3, 162, 178)"}
                                classes={classes.btnRegister}
                                action={() => window.open(ad?.url, "_blank")}
                              />
                            </div>
                          )}
                        </div>
                        <div className={classes.imgcont}>
                          <img
                            src={ad?.image || Img}
                            alt=""
                            height={600}
                            width={"100%"}
                          />
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <MainSectionCarousel>
                    {adList.map((ad) => (
                      <>
                        <div className={classes["main"]}>
                          <div className={classes.imgcont}>
                            <img
                              src={ad?.image || Img}
                              alt=""
                              height={600}
                              width={"100%"}
                            />
                          </div>
                          <div className={classes["main__wrapper"]}>
                            <div className={classes["main__content"]}>
                              <h2 className={classes["main__content-title"]}>
                                {ad?.title}
                              </h2>
                              <p
                                className={classes["main__content-desc"]}
                                style={
                                  ad?.description?.length > 710
                                    ? { fontSize: "14px" }
                                    : {}
                                }
                              >
                                {ad?.description}
                              </p>
                            </div>
                            {ad?.has_button && (
                              <div className={classes["search_container"]}>
                                <CustomButton
                                  value={ad?.button_text}
                                  colors={"rgb(3, 162, 178)"}
                                  classes={classes.btnRegister}
                                  action={() => window.open(ad?.url, "_blank")}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </MainSectionCarousel>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div
            style={{
              height: "400px",
              width: "90%",
            }}
          >
            <SkeletonSpinnerOverlay
              backdrop={true}
              skeletonWidth="100"
              skeletonHight="100"
            />
          </div>
          {/* <div
            style={{
              width: "200px",
            }}
          >
            <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
            <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
          </div> */}
        </div>
      )}
    </>
  );
};

export default MainSection;
