import React from "react";
import XIcon from "../../../../../assets/icons/XGray.svg";
import classes from "./TraineeCard.module.css";
import Checkbox from "../../../../Shared/Components/Checkbox/Checkbox";

export default function TraineeCard({ img, name, level, selected }) {
  return (
    <div
      className={classes.cardCont}
      style={
        selected ? { backgroundColor: "#e7e6e6" } : { backgroundColor: "#fff" }
      }
    >
      <div className={classes.img_name}>
        <div className={classes.imgCont}>
          <img src={img} alt="" className={classes.Img} />
        </div>
        <div>
          <p>{name}</p>
          <span>{level}</span>
        </div>
      </div>
      {selected ? (
        <div>
          <img src={XIcon} alt="" />
        </div>
      ) : (
        <div>
          {/* <Checkbox name="selected" onChange={(event) => {}} />
           */}
          <input
            type="checkbox"
            name={"selected"}
            id={"selected"}
            onChange={(event) => {}}
          />
        </div>
      )}
    </div>
  );
}
