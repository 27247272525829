import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Lang } from "../../../../utils";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";

import classes from "./PercentageTrainersList.module.css";

const PercentageTrainersListTable = ({ sortAction, rolesEditHandle }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { percentageTrainersList, percentageTrainersListLoading } = useSelector(
    (state) => state.percentageTrainersListReducer
  );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "trainer_name",
      name: t("department_trainers.trainer_name"),
      key: "id",
      cell: ({ rowData }) => (
        <>
          {i18n.language === "ar"
            ? rowData?.trainer?.name_ar
            : rowData?.trainer?.name_en}
        </>
      ),
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "program_name",
      name: t("trainer.program.name"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-name"]}>
          {rowData?.is_program
            ? rowData?.course?.name
            : rowData?.course?.program_id
            ? rowData?.course?.program?.name
            : "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "program_code",
      name: t("trainer.program.code"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-name"]}>
          {rowData?.is_program
            ? rowData?.course?.code
            : rowData?.course?.program_id
            ? rowData?.course?.program?.code
            : "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "course_name",
      name: t("crud.list.course_name"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-name"]}>
          {!rowData?.is_program ? rowData?.course?.name : "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "course_code",
      name: t("report.label.course_code"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-name"]}>
          {!rowData?.is_program ? rowData?.course?.code : "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "course_serial_number",
      name: t("general.serial_number"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-name"]}>
          {rowData?.course?.run_serial_number || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "mechanism",
      name: t("machinsim"),
      key: "mechanism",
      cell: ({ rowData }) => (
        <>
          {rowData?.mechanism == 1
            ? t("amount_tax")
            : t("coupon.label.per_value")}
        </>
      ),
    },

    {
      id: "value",
      name: t("general.amount"),
      key: "value",
      cell: ({ rowData }) => (
        <>
          {rowData?.mechanism == 1
            ? rowData?.value + t("general.sar")
            : rowData?.value + "%"}
        </>
      ),
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: false,
    },
  ];

  return (
    <MainTable
      columns={columns}
      data={percentageTrainersList || []}
      isLoading={percentageTrainersListLoading}
      sortAction={sortAction}
    />
  );
};

export default PercentageTrainersListTable;
