import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import i18n from "../../../../i18n/i18n";
import en from "../../../../assets/image/united-kingdom.png";
import ar from "../../../../assets/image/saudi-arabia.png";
import GlobIcon from "../../../../assets/icons/globIcon.svg";

function DynamicHeaderWithLogin() {
  const { t } = useTranslation();
  const theme = useTheme();

  document.body.dir = i18n.dir();

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };

  let buttonLang = <div onClick={() => changeLanguage("ar")}>AR</div>;
  if (document.body.dir === "rtl") {
    buttonLang = (
      <div className="lang_style" onClick={() => changeLanguage("en")}>
        <div className="lang_btn_qassem">
          <img src={GlobIcon} alt="" style={{ width: 20, height: 20 }} />
          <div className="">English</div>
          <img src={en} alt="" />
        </div>
      </div>
    );
  } else {
    buttonLang = (
      <div className="lang_style" onClick={() => changeLanguage("ar")}>
        <div className="lang_btn_qassem">
          <img src={ar} alt="" />
          <div className="">العربية</div>
          <img src={GlobIcon} alt="" style={{ width: 20, height: 20 }} />{" "}
        </div>
      </div>
    );
  }
  const loginNav = (
    <>
      <NavLink className="header_btn_color header_nav_link" to="/register">
        <span className="header_btn">{t("JoinUs")}</span>
      </NavLink>

      <NavLink
        className="header_btn_style header_nav_link header_text"
        to="/login"
      >
        {t("Login")}
      </NavLink>
    </>
  );

  return (
    <div className="header_dinamic">
      {loginNav}
      {buttonLang}
    </div>
  );
}

export default DynamicHeaderWithLogin;
