import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCourseTrainees, postFirstMessage } from "../../../../../Redux/Actions/livechat/livechat.action";
import DialogModal from "../../../../Shared/Components/DialogModal/DialogModal";
import SelectWithSearch from "../../../../Shared/Components/SelectWithSearch/SelectWithSearch";
import Textarea from "../../../../Shared/Components/Textarea/Textarea";
import ExitIcon from "./../../../../../assets/icons/remove-red.svg";
import classes from "./newMsg.module.css";

const NewMsg = ({ handleClose, courseId }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { course_trainees, first_message } = useSelector((state) => state.livechatReducer);

  useEffect(() => {
    dispatch(getCourseTrainees(courseId));
  }, []);

  console.log("first_message", first_message);

  const traineesOptions = course_trainees.map((trainee) => {
    return {
      id: trainee.id,
      value: trainee.id,
      label: `${i18n.language === "ar" ? trainee.name_ar : trainee.name_en} (${trainee.email})`,
    };
  });

  return (
    <DialogModal
      className="tw-bg-white sm:tw-rounded-lg tw-shadow-xl tw-p-4 tw-space-y-4 tw-w-full sm:tw-w-2/3 lg:tw-w-1/2"
      onCancel={handleClose}
    >
      <div className="tw-flex tw-justify-between tw-items-center">
        <h1 className="tw-text-lg sm:tw-text-xl tw-text-black-100">{t("chat.compose_message")}</h1>
        <img src={ExitIcon} alt="exit" onClick={handleClose} width="20" height="20" />
      </div>
      <Formik
        initialValues={{
          trainee_id: "",
          message: "",
          course_id: courseId,
        }}
        onSubmit={async (values) => {
          dispatch(postFirstMessage(values));
          handleClose();
        }}
        validateOnChange={true}
        validate={(values) => {
          const errors = {};
          if (!values.trainee_id) {
            errors.trainee_id = t("chat.required");
          }
          if (!values.message) {
            errors.message = t("chat.required");
          }
          return errors;
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, errors }) => (
          <form onSubmit={handleSubmit} className="tw-space-y-4">
            <SelectWithSearch
              label={t("chat.send_to")}
              placeholder={t("chat.select_trainee")}
              name="trainee_id"
              options={traineesOptions}
              onchange={(value) => {
                setFieldValue("trainee_id", value.value);
              }}
              value={{
                id: values.trainee_id,
                value: values.trainee_id,
                label: traineesOptions.find((option) => option.id === values.trainee_id)?.label,
              }}
              error={errors.trainee_id}
              required
            />
            <Textarea
              placeholder={t("chat.type_message")}
              name="message"
              value={values.message}
              onChange={handleChange}
              error={errors.message}
            />
            <div className="tw-flex tw-justify-end">
              <button type="submit" className="tw-bg-teal-600 tw-text-white tw-py-2 tw-px-4 tw-rounded-md">
                {t("chat.send")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </DialogModal>
  );
};

export default NewMsg;
