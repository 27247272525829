import { ErrorMessage, Field, FieldArray } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteChooseFromSurveyQuestion } from "../../../Services/api/survey/SurveyProvider";
import { toast } from "react-toastify/dist";
import { useEffect } from "react";
import { Slider } from "@mui/material";
import DatePicker from "react-datepicker";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MultipleContainers } from "./MultipleContainers/MultipleContainers";
import { ReactComponent as PhotoIcon } from '../../../assets/icons/photo.svg';

function MemuIcon({}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g id="grid_1_" data-name="grid (1)" transform="translate(-2 -2)">
        <rect
          id="Rectangle_100"
          data-name="Rectangle 100"
          width="7"
          height="7"
          transform="translate(3 3)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <rect
          id="Rectangle_101"
          data-name="Rectangle 101"
          width="7"
          height="7"
          transform="translate(14 3)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <rect
          id="Rectangle_102"
          data-name="Rectangle 102"
          width="7"
          height="7"
          transform="translate(14 14)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <rect
          id="Rectangle_103"
          data-name="Rectangle 103"
          width="7"
          height="7"
          transform="translate(3 14)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function PlusIcon({}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g id="Group_105" data-name="Group 105" transform="translate(-347 -498)">
        <g id="plus-square" transform="translate(344.698 495.698)">
          <rect
            id="Rectangle_19"
            data-name="Rectangle 19"
            width="22"
            height="22"
            rx="2"
            transform="translate(3.302 3.302)"
            fill="none"
            stroke="#046c77"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            y2="10"
            transform="translate(14.302 9.302)"
            fill="none"
            stroke="#046c77"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            x2="10"
            transform="translate(9.302 14.302)"
            fill="none"
            stroke="#046c77"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </g>
    </svg>
  );
}

function CloseIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
    >
      <g id="x-circle_2_" data-name="x-circle (2)" transform="translate(-1 -1)">
        <circle
          id="Ellipse_23"
          data-name="Ellipse 23"
          cx="10"
          cy="10"
          r="10"
          transform="translate(2 2)"
          fill="none"
          stroke="#e62626"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_35"
          data-name="Line 35"
          x1="6"
          y2="6"
          transform="translate(9 9)"
          fill="none"
          stroke="#e62626"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_36"
          data-name="Line 36"
          x2="6"
          y2="6"
          transform="translate(9 9)"
          fill="none"
          stroke="#e62626"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function UploadIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 56.027 46.275"
    >
      <g
        id="Group_7"
        data-name="Group 7"
        transform="translate(-70.991 -19.322)"
      >
        <g id="upload-cloud" transform="translate(73 21.33)">
          <path
            id="Path_210"
            data-name="Path 210"
            d="M26.907,21.453,17.453,12,8,21.453"
            transform="translate(8.556 9.265)"
            fill="none"
            stroke="#24b3b9"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          />
          <line
            id="Line_41"
            data-name="Line 41"
            y2="21"
            transform="translate(26 21.267)"
            fill="none"
            stroke="#24b3b9"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          />
          <path
            id="Path_211"
            data-name="Path 211"
            d="M46.833,39.369a11.817,11.817,0,0,0-5.648-22.192H38.207A18.907,18.907,0,1,0,5.734,34.43"
            transform="translate(-0.995 -3.002)"
            fill="none"
            stroke="#24b3b9"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          />
          <path
            id="Path_212"
            data-name="Path 212"
            d="M26.907,21.453,17.453,12,8,21.453"
            transform="translate(8.556 9.265)"
            fill="none"
            stroke="#24b3b9"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="4"
          />
        </g>
      </g>
    </svg>
  );
}

function CalendarIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 22"
    >
      <g id="calendar_9_" data-name="calendar (9)" transform="translate(-2 -1)">
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="18"
          height="18"
          rx="2"
          transform="translate(3 4)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_1"
          data-name="Line 1"
          y2="4"
          transform="translate(16 2)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_2"
          data-name="Line 2"
          y2="4"
          transform="translate(8 2)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_3"
          data-name="Line 3"
          x2="18"
          transform="translate(3 10)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function ArrowDownIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 16.828 9.414"
    >
      <g
        id="arrow-down_6_"
        data-name="arrow-down (6)"
        transform="translate(1.414 1.414)"
      >
        <path
          id="Path_3"
          data-name="Path 3"
          d="M19,12l-7,7L5,12"
          transform="translate(-5 -12)"
          fill="none"
          stroke="#24b3b9"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function StarIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="46.604"
      height="44.418"
      viewBox="0 0 46.604 44.418"
    >
      <path
        id="star_17_"
        data-name="star (17)"
        d="M24.3,2l6.891,13.961L46.6,18.213,35.453,29.074l2.632,15.344L24.3,37.17,10.519,44.418l2.632-15.344L2,18.213l15.411-2.252Z"
        transform="translate(-1 -1)"
        fill="current"
        stroke="current"
        strokeLinecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
}

export function JustPreview({ children }) {
  const { t } = useTranslation();
  return (
    <div className="tw-py-4">
      <div className="tw-relative tw-p-4 tw-border-[1px] tw-border-teal-400 tw-rounded">
        <div className="tw-bg-white tw-px-2 tw-text-teal-600 tw-absolute tw-left-8 -tw-top-4">
          {t("just_preview")}
        </div>
        {children}
      </div>
    </div>
  );
}

function DeleteChoose({ id, type = "options", onRemove, disabled = false }) {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={async () => {
        try {
          if (id && type == "options") {
            var response = await deleteChooseFromSurveyQuestion(id);
            if (response.status == 200) {
              toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {t("survey.messages.deleteOption")}
                </span>
              );
            } else {
              throw response;
            }
          }

          onRemove();
        } catch (e) {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("failed_deleting")}
            </span>
          );
        }
      }}
    >
      <CloseIcon className="tw-w-5 tw-h-5" />
    </button>
  );
}

function RadioQuestion({
  prefix = "",
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!preview && !questionId) {
      setFieldValue(`${prefix}options`, [
        { value: "" },
        { value: "" },
        { value: "" },
      ]);
    }
  }, []);

  return (
    <>
      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
        {t("options")}
      </div>
      <FieldArray name={`${prefix}options`}>
        {({ insert, remove, push }) => (
          <>
            <div className="tw-space-y-4">
              {values.options?.length &&
                values.options.map((option, index) => (
                  <div
                    key={index}
                    className="tw-flex tw-items-center tw-space-s-4"
                  >
                    <input
                      type="radio"
                      disabled
                      // name={`${prefix}options.${index}.checked`}
                      className="!tw-w-auto"
                    />
                    <div className="tw-grow">
                      <Field
                        name={`${prefix}options.${index}.value`}
                        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        placeholder={t("add_title")}
                        type="text"
                      />
                      <ErrorMessage
                        name={`${prefix}options.${index}.value`}
                        component="div"
                        className="tw-text-xs tw-text-red-700 tw-h-4"
                      />
                    </div>
                    <DeleteChoose
                      id={option.id}
                      onRemove={() => remove(index)}
                      disabled={values.options.length <= 1}
                    />
                  </div>
                ))}
            </div>
            <button
              type="button"
              onClick={() => push({ value: "" })}
              className="tw-h-6 tw-w-6 tw-mt-3"
            >
              <PlusIcon />
            </button>
          </>
        )}
      </FieldArray>
    </>
  );
}

function CheckboxQuestion({
  prefix = "",
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!preview && !questionId) {
      setFieldValue(`${prefix}options`, [
        { value: "" },
        { value: "" },
        { value: "" },
      ]);
    }
  }, []);
  return (
    <>
      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
        {t("options")}
      </div>
      <FieldArray name={`${prefix}options`}>
        {({ insert, remove, push }) => (
          <div>
            <div className="tw-space-y-4">
              {values.options?.length &&
                values.options.map((option, index) => (
                  <div
                    key={index}
                    className="tw-flex tw-items-center tw-space-s-4"
                  >
                    <input
                      type="checkbox"
                      disabled
                      // name={`${prefix}options.${index}.checked`}
                      className="!tw-w-auto"
                    />
                    <div className="tw-grow">
                      <Field
                        name={`${prefix}options.${index}.value`}
                        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        placeholder={t("add_title")}
                        type="text"
                      />
                      <ErrorMessage
                        name={`${prefix}options.${index}.value`}
                        component="div"
                        className="tw-text-xs tw-text-red-700 tw-h-4"
                      />
                    </div>

                    <DeleteChoose
                      id={option.id}
                      onRemove={() => remove(index)}
                      disabled={values.options.length <= 1}
                    />
                  </div>
                ))}
            </div>
            <button
              type="button"
              onClick={() => push({ value: "" })}
              className="tw-h-6 tw-w-6 tw-mt-3"
            >
              <PlusIcon />
            </button>
          </div>
        )}
      </FieldArray>
    </>
  );
}

function OptionsQuestion({
  prefix = "",
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
  type,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!preview && !questionId) {
      setFieldValue(
        `${prefix}options`,
        type == "contact_information"
          ? [
              { value: t("first_name") },
              { value: t("last_name") },
              { value: t("email") },
              { value: t("phone") },
            ]
          : [{ value: "" }, { value: "" }, { value: "" }]
      );
    }
  }, []);
  return (
    <>
      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
        {t(type == "contact_information" ? "fields" : "options")}
      </div>
      <FieldArray name={`${prefix}options`}>
        {({ insert, remove, push }) => (
          <div>
            <div className="tw-space-y-4">
              {values.options?.length &&
                values.options.map((option, index) => (
                  <div
                    key={index}
                    className="tw-flex tw-items-center tw-space-s-4"
                  >
                    <div className="tw-grow">
                      <Field
                        name={`${prefix}options.${index}.value`}
                        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        placeholder={t("add_title")}
                        type="text"
                      />
                      <ErrorMessage
                        name={`${prefix}options.${index}.value`}
                        component="div"
                        className="tw-text-xs tw-text-red-700 tw-h-4"
                      />
                    </div>
                    <DeleteChoose
                      id={option.id}
                      onRemove={() => remove(index)}
                      disabled={values.options.length <= 1}
                    />
                  </div>
                ))}
            </div>
            <button
              type="button"
              onClick={() => push({ value: "" })}
              className="tw-h-6 tw-w-6 tw-mt-3"
            >
              <PlusIcon />
            </button>
          </div>
        )}
      </FieldArray>
    </>
  );
}

function StringQuestion({
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
  type,
}) {
  const { t } = useTranslation();
  return (
    <JustPreview>
      <input
        className="tw-text-gray-400 tw-border-b tw-border-gray-200 tw-py-2.5 tw-w-full"
        placeholder={t(`survey.demoData.${type}`)}
        readOnly
      />
    </JustPreview>
  );
}

function TextQuestion({
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
}) {
  const { t } = useTranslation();
  return (
    <>
      <JustPreview>
        <div className="tw-w-full">
          <div className="tw-text-gray-400">{t("survey.demoData.text")}</div>
          <div className="tw-h-6 tw-border-b tw-border-gray-200 tw-w-full" />
          <div className="tw-h-6 tw-border-b tw-border-gray-200 tw-w-full" />
          <div className="tw-h-6 tw-border-b tw-border-gray-200 tw-w-full" />
        </div>
      </JustPreview>
    </>
  );
}

function FileQuestion({
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
}) {
  const { t } = useTranslation();
  return (
    <JustPreview>
      <div className="tw-flex tw-items-center tw-justify-center tw-py-16 tw-border-[1px] tw-border-gray-100 tw-rounded">
        <UploadIcon className="tw-h-8" />
      </div>
    </JustPreview>
  );
}

function BasicMatrexQuestion({
  prefix = "",
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!preview && !questionId) {
      setValues({
        ...values,
        [`${prefix}options`]: [{ value: "" }, { value: "" }, { value: "" }],
        [`${prefix}items`]: [
          { name: "" },
          { name: "" },
          { name: "" },
          { name: "" },
        ],
      });
    }
  }, []);
  return (
    <div className="tw-overflow-x-auto inner-scrollbar tw-pb-4">
      <FieldArray name={`${prefix}items`}>
        {({ insert: insertItem, remove: removeItem, push: pushItem }) => (
          <table>
            <FieldArray name={`${prefix}options`}>
              {({
                insert: insertOption,
                remove: removeOption,
                push: pushOption,
              }) => (
                <tbody>
                  <tr>
                    <td className="tw-p-2"></td>
                    {values.options?.length &&
                      values.options.map((option, optionIndex) => (
                        <td
                          className={`tw-py-2 ${
                            optionIndex == 0
                              ? "tw-pe-2"
                              : optionIndex == values.options.length - 1
                              ? "tw-ps-2"
                              : "tw-px-2"
                          }`}
                          key={optionIndex}
                        >
                          <Field
                            name={`${prefix}options.${optionIndex}.value`}
                            className="tw-block tw-min-w-[6rem] tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                            placeholder={t("add_title")}
                            type="text"
                          />
                        </td>
                      ))}

                    <td className="tw-p-2">
                      <div className="tw-flex tw-items-center tw-justify-center">
                        <button
                          type="button"
                          onClick={() => pushOption({ value: "" })}
                          className="tw-h-6 tw-w-6"
                        >
                          <PlusIcon />
                        </button>
                      </div>
                    </td>
                  </tr>

                  {values.items?.length &&
                    values.items.map((item, itemIndex) => (
                      <tr key={itemIndex}>
                        <td
                          className={`tw-px-2 tw-w-28 ${
                            itemIndex == 0
                              ? "tw-pb-2"
                              : itemIndex == values.items.length - 1
                              ? "tw-pt-2"
                              : "tw-py-2"
                          }`}
                        >
                          <Field
                            name={`${prefix}items.${itemIndex}.name`}
                            className="tw-block tw-w-24 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                            placeholder={t("add_title")}
                            type="text"
                          />
                        </td>
                        {values.options?.length &&
                          values.options.map((option, optionIndex) => (
                            <td className={`tw-p-0`} key={optionIndex}>
                              <div
                                className={`tw-flex tw-items-center tw-justify-center tw-h-16 tw-w-full ${
                                  itemIndex == 0 ? "tw-border-t" : ""
                                } ${
                                  itemIndex == values.items.length - 1
                                    ? "tw-border-b"
                                    : ""
                                } ${optionIndex == 0 ? "tw-border-s" : ""} ${
                                  optionIndex == values.options.length - 1
                                    ? "tw-border-e"
                                    : ""
                                } ${
                                  itemIndex == 0 && optionIndex == 0
                                    ? "tw-rounded-ts"
                                    : ""
                                } ${
                                  itemIndex == values.items.length - 1 &&
                                  optionIndex == values.options.length - 1
                                    ? "tw-rounded-be"
                                    : ""
                                } ${
                                  optionIndex == 0 &&
                                  itemIndex == values.items.length - 1
                                    ? "tw-rounded-bs"
                                    : ""
                                } ${
                                  itemIndex == 0 &&
                                  optionIndex == values.options.length - 1
                                    ? "tw-rounded-te"
                                    : ""
                                } tw-border-gray-200`}
                              >
                                <div className="tw-h-3 tw-w-3 tw-border tw-border-gray-200 tw-rounded-full"></div>
                              </div>
                            </td>
                          ))}

                        <td className="tw-p-2">
                          <div className="tw-flex tw-items-center tw-justify-center">
                            <button
                              type="button"
                              disabled={values.items.length <= 1}
                              onClick={() => removeItem(itemIndex)}
                            >
                              <CloseIcon className="tw-w-5 tw-h-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}

                  <tr>
                    <td className="tw-p-2">
                      <button
                        type="button"
                        onClick={() => pushItem({ name: "" })}
                        className="tw-h-6 tw-w-6"
                      >
                        <PlusIcon />
                      </button>
                    </td>
                    {values.options?.length &&
                      values.options.map((option, optionIndex) => (
                        <td className="tw-p-2" key={optionIndex}>
                          <div className="tw-flex tw-items-center tw-justify-center">
                            <DeleteChoose
                              id={option.id}
                              onRemove={() => removeOption(optionIndex)}
                              disabled={values.options.length <= 1}
                            />
                          </div>
                        </td>
                      ))}

                    <td className="tw-p-2"></td>
                  </tr>
                </tbody>
              )}
            </FieldArray>
          </table>
        )}
      </FieldArray>
    </div>
  );
}

function SideBySideMatrexQuestion({
  prefix = "",
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!preview && !questionId) {
      setValues({
        ...values,
        [`${prefix}options`]: [{ value: "" }, { value: "" }],
        [`${prefix}items`]: [
          { name: "" },
          { name: "" },
          { name: "" },
          { name: "" },
        ],
        [`${prefix}item_options`]: [{ name: "" }, { name: "" }, { name: "" }],
      });
    }
  }, []);
  return (
    <div className="tw-overflow-x-auto inner-scrollbar tw-pb-4">
      <FieldArray name={`${prefix}options`}>
        {({ insert: insertOption, remove: removeOption, push: pushOption }) => (
          <>
            {values.options?.length &&
              values.options.map((option2, option2Index) => (
                <FieldArray key={option2Index} name={`${prefix}items`}>
                  {({
                    insert: insertItem,
                    remove: removeItem,
                    push: pushItem,
                  }) => (
                    <table>
                      <FieldArray name={`${prefix}item_options`}>
                        {({
                          insert: insertItemOption,
                          remove: removeItemOption,
                          push: pushItemOption,
                        }) => (
                          <tbody>
                            <tr>
                              <td className="tw-p-2">
                                <label
                                  htmlFor={`${prefix}options.${option2Index}.value`}
                                  className="tw-text-sm tw-whitespace-nowrap"
                                >
                                  {t("array_title")}
                                </label>
                              </td>

                              <td
                                className={`tw-py-2`}
                                colSpan={values.options?.length}
                              >
                                <Field
                                  id={`${prefix}options.${option2Index}.value`}
                                  name={`${prefix}options.${option2Index}.value`}
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  placeholder={t("add_array_title")}
                                  type="text"
                                />
                              </td>

                              <td className="tw-p-2">
                                <div className="tw-flex tw-items-center tw-justify-center">
                                  <DeleteChoose
                                    id={option2.id}
                                    type={"options"}
                                    onRemove={() => removeOption(option2Index)}
                                    disabled={values.options.length <= 1}
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="tw-p-2"></td>
                              {values.item_options?.length &&
                                values.item_options.map(
                                  (optionItem, optionItemIndex) => (
                                    <td
                                      className={`tw-py-2 ${
                                        optionItemIndex == 0
                                          ? "tw-pe-2"
                                          : optionItemIndex ==
                                            values.options.length - 1
                                          ? "tw-ps-2"
                                          : "tw-px-2"
                                      }`}
                                      key={optionItemIndex}
                                    >
                                      <Field
                                        name={`${prefix}item_options.${optionItemIndex}.name`}
                                        className="tw-block tw-min-w-[6rem] tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                        placeholder={t("add_title")}
                                        type="text"
                                      />
                                    </td>
                                  )
                                )}

                              <td className="tw-p-2">
                                <div className="tw-flex tw-items-center tw-justify-center">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      pushItemOption({ value: "" })
                                    }
                                    className="tw-h-6 tw-w-6"
                                  >
                                    <PlusIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>

                            {values.items?.length &&
                              values.items.map((item, itemIndex) => (
                                <tr key={itemIndex}>
                                  <td
                                    className={`tw-px-2 tw-w-28 ${
                                      itemIndex == 0
                                        ? "tw-pb-2"
                                        : itemIndex == values.items.length - 1
                                        ? "tw-pt-2"
                                        : "tw-py-2"
                                    }`}
                                  >
                                    <Field
                                      name={`${prefix}items.${itemIndex}.name`}
                                      className="tw-block tw-w-24 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                      placeholder={t("add_title")}
                                      type="text"
                                    />
                                  </td>
                                  {values.item_options?.length &&
                                    values.item_options.map(
                                      (itemOption, itemOptionIndex) => (
                                        <td
                                          className={`tw-p-0`}
                                          key={itemOptionIndex}
                                        >
                                          <div
                                            className={`tw-flex tw-items-center tw-justify-center tw-h-16 tw-w-full ${
                                              itemIndex == 0
                                                ? "tw-border-t"
                                                : ""
                                            } ${
                                              itemIndex ==
                                              values.items.length - 1
                                                ? "tw-border-b"
                                                : ""
                                            } ${
                                              itemOptionIndex == 0
                                                ? "tw-border-s"
                                                : ""
                                            } ${
                                              itemOptionIndex ==
                                              values.item_options.length - 1
                                                ? "tw-border-e"
                                                : ""
                                            } ${
                                              itemIndex == 0 &&
                                              itemOptionIndex == 0
                                                ? "tw-rounded-ts"
                                                : ""
                                            } ${
                                              itemIndex ==
                                                values.items.length - 1 &&
                                              itemOptionIndex ==
                                                values.item_options.length - 1
                                                ? "tw-rounded-be"
                                                : ""
                                            } ${
                                              itemOptionIndex == 0 &&
                                              itemIndex ==
                                                values.items.length - 1
                                                ? "tw-rounded-bs"
                                                : ""
                                            } ${
                                              itemIndex == 0 &&
                                              itemOptionIndex ==
                                                values.item_options.length - 1
                                                ? "tw-rounded-te"
                                                : ""
                                            } tw-border-gray-200`}
                                          >
                                            <div className="tw-h-3 tw-w-3 tw-border tw-border-gray-200 tw-rounded-full"></div>
                                          </div>
                                        </td>
                                      )
                                    )}

                                  <td className="tw-p-2">
                                    <div className="tw-flex tw-items-center tw-justify-center">
                                      <button
                                        type="button"
                                        disabled={values.items.length <= 1}
                                        onClick={() => removeItem(itemIndex)}
                                      >
                                        <CloseIcon className="tw-w-5 tw-h-5" />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}

                            <tr>
                              <td className="tw-p-2">
                                <button
                                  type="button"
                                  onClick={() => pushItem({ name: "" })}
                                  className="tw-h-6 tw-w-6"
                                >
                                  <PlusIcon />
                                </button>
                              </td>
                              {values.item_options?.length &&
                                values.item_options.map(
                                  (itemOption, itemOptionIndex) => (
                                    <td
                                      className="tw-p-2"
                                      key={itemOptionIndex}
                                    >
                                      <div className="tw-flex tw-items-center tw-justify-center">
                                        <DeleteChoose
                                          id={itemOption.id}
                                          onRemove={() =>
                                            removeItemOption(itemOptionIndex)
                                          }
                                          disabled={
                                            values.item_options.length <= 1
                                          }
                                        />
                                      </div>
                                    </td>
                                  )
                                )}

                              <td className="tw-p-2"></td>
                            </tr>
                          </tbody>
                        )}
                      </FieldArray>
                    </table>
                  )}
                </FieldArray>
              ))}

            <div className="tw-relative tw-py-4">
              <hr className="tw-border-dashed" />
              <button
                type="button"
                onClick={() => pushOption({ name: "" })}
                className="tw-flex tw-items-center tw-space-s-2 tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-1/2 -tw-translate-y-1/2 tw-bg-white tw-px-3 tw-text-teal-700"
              >
                <div className="tw-h-4 tw-w-4">
                  <PlusIcon />
                </div>
                <div>{t("add_array")}</div>
              </button>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
}

export const smileyFaces = (t) => [
  {
    name: t("very_dissatisfied"),
    color: "#dd1921",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="59.763"
        height="59.852"
        viewBox="0 0 59.763 59.852"
      >
        <g id="Group_172" data-name="Group 172" transform="translate(0 -0.158)">
          <path
            id="Path_233"
            data-name="Path 233"
            d="M734.649-32.1h19.687C749.157-39.025,740.632-39.085,734.649-32.1Z"
            transform="translate(-714.691 72.184)"
            fill="current"
          />
          <path
            id="Path_234"
            data-name="Path 234"
            d="M733.386-50.471c.311,2,1.225,3.428,3.42,3.5a3.267,3.267,0,0,0,3.719-3.585,3.209,3.209,0,0,0-3.337-3.476C734.724-54.128,733.707-52.656,733.386-50.471Z"
            transform="translate(-699.617 72.184)"
            fill="current"
          />
          <path
            id="Path_235"
            data-name="Path 235"
            d="M748.377-50.54c.269,2.005,1.16,3.45,3.347,3.566a3.274,3.274,0,0,0,3.8-3.51,3.218,3.218,0,0,0-3.266-3.547C749.8-54.173,748.735-52.734,748.377-50.54Z"
            transform="translate(-729.605 72.184)"
            fill="current"
          />
          <path
            id="Path_264"
            data-name="Path 264"
            d="M714.531-42.42a30.327,30.327,0,0,0,29.591,30.241c16.214.329,30.22-13.673,30.171-30.161a29.716,29.716,0,0,0-29.341-29.685C727.977-72.087,714.624-59.093,714.531-42.42Zm30.214,24.337a24.337,24.337,0,0,1-24.63-24.091,24.183,24.183,0,0,1,23.962-24.2,24.17,24.17,0,0,1,24.629,23.522A24.408,24.408,0,0,1,744.745-18.083Z"
            transform="translate(-714.531 72.184)"
            fill="current"
          />
        </g>
      </svg>
    ),
  },
  {
    name: t("not_satisfied"),
    color: "#e54614",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60.112"
        height="59.924"
        viewBox="0 0 60.112 59.924"
      >
        <g
          id="Group_173"
          data-name="Group 173"
          transform="translate(-190.076 -0.145)"
        >
          <path
            id="Path_230"
            data-name="Path 230"
            d="M565.933-36.05a16.926,16.926,0,0,0-11.224,5.364c-.6.66-2.247,1.436-.664,2.726.909.74,1.993,1.527,3.083.463,6.272-6.118,12.543-5.595,18.844-.1,1.156,1.009,2.134.5,3.088-.247,1.68-1.315-.049-2.045-.659-2.673A18.134,18.134,0,0,0,565.933-36.05Z"
            transform="translate(-346.012 72.184)"
            fill="current"
          />
          <path
            id="Path_231"
            data-name="Path 231"
            d="M557.571-56.718c-2.6.355-4.48,1.68-4.526,4.394-.045,2.636,1.38,4.336,4.279,4.394,2.86.058,4.423-1.506,4.544-4.154C561.993-54.832,560.142-56.2,557.571-56.718Z"
            transform="translate(-326.92 72.184)"
            fill="current"
          />
          <path
            id="Path_232"
            data-name="Path 232"
            d="M571.039-52.1c.417,2.484,1.616,4.191,4.511,4.176,2.865-.015,4.378-1.618,4.417-4.274a4.142,4.142,0,0,0-4.419-4.4C572.922-56.631,571.365-54.9,571.039-52.1Z"
            transform="translate(-364.176 72.184)"
            fill="current"
          />
          <path
            id="Path_219"
            data-name="Path 219"
            d="M566.277-72.039A29.837,29.837,0,0,0,536.4-42.322c-.028,16.419,13.933,30.366,30.236,30.206a30.546,30.546,0,0,0,29.876-30.067A29.854,29.854,0,0,0,566.277-72.039Zm.093,5.822a23.951,23.951,0,0,1,24.316,23.736,24.527,24.527,0,0,1-23.889,24.4,24.511,24.511,0,0,1-24.57-24.2A23.978,23.978,0,0,1,566.37-66.217Z"
            transform="translate(-346.324 72.184)"
            fill="current"
          />
        </g>
      </svg>
    ),
  },
  {
    name: t("neutral"),
    color: "#ffab14",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60.676"
        height="60.121"
        viewBox="0 0 60.676 60.121"
      >
        <g
          id="Group_176"
          data-name="Group 176"
          transform="translate(-380.049 0)"
        >
          <path
            id="Path_221"
            data-name="Path 221"
            d="M388-33.2c-3.309,0-6.619.014-9.929,0-1.6-.009-2.978.083-2.892,2.306.074,1.918,1.285,2.014,2.681,2.015l20.355.007c1.581,0,3.026-.073,2.92-2.305-.11-2.305-1.77-1.992-3.206-2.008C394.62-33.225,391.31-33.2,388-33.2Z"
            transform="translate(22.604 72.184)"
            fill="current"
          />
          <path
            id="Path_222"
            data-name="Path 222"
            d="M380.175-47.861a3.976,3.976,0,0,0,3.9-4.449,4.252,4.252,0,0,0-4.865-4.462,4.221,4.221,0,0,0-4.276,4.572C375-49.151,376.816-47.784,380.175-47.861Z"
            transform="translate(40.461 72.184)"
            fill="current"
          />
          <path
            id="Path_223"
            data-name="Path 223"
            d="M392.925-52.448c.307,2.894,1.62,4.51,4.451,4.58,3.015.073,4.765-1.4,4.8-4.413a4.233,4.233,0,0,0-4.36-4.5C394.925-56.927,393.323-55.1,392.925-52.448Z"
            transform="translate(3.215 72.184)"
            fill="current"
          />
          <path
            id="Path_216"
            data-name="Path 216"
            d="M388.048-72.125a30.051,30.051,0,0,0-29.877,30.234c.1,16.338,13.958,29.889,30.51,29.828,16.46-.061,30.25-13.808,30.165-30.183C418.758-59.185,403.911-73.207,388.048-72.125ZM364.807-42.179c-.009-12.828,10.757-23.771,23.386-23.768a24.067,24.067,0,0,1,23.762,23.919c-.088,12.827-10.989,23.91-23.4,23.8A24.143,24.143,0,0,1,364.807-42.179Z"
            transform="translate(21.879 72.184)"
            fill="current"
          />
        </g>
      </svg>
    ),
  },
  {
    name: t("satisfied"),
    color: "#83b449",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60.623"
        height="60.034"
        viewBox="0 0 60.623 60.034"
      >
        <g
          id="Group_175"
          data-name="Group 175"
          transform="translate(-570.356 -0.069)"
        >
          <path
            id="Path_224"
            data-name="Path 224"
            d="M211.074-26.507A16.9,16.9,0,0,0,222.1-31.449c.807-.843,2.4-1.809.733-3.2-.991-.823-2.123-1.573-3.484-.289-6.191,5.845-12.348,5.389-18.366-.284-1.208-1.139-2.129-.352-3.062.27-1.611,1.075-.449,1.979.321,2.768A18.668,18.668,0,0,0,211.074-26.507Z"
            transform="translate(389.98 72.184)"
            fill="current"
          />
          <path
            id="Path_225"
            data-name="Path 225"
            d="M201.682-56.89c-2.725.417-4.711,1.695-4.685,4.712.024,2.711,1.545,4.223,4.35,4.307,2.764.082,4.467-1.27,4.715-3.96C206.34-54.837,204.436-56.266,201.682-56.89Z"
            transform="translate(407.877 72.184)"
            fill="current"
          />
          <path
            id="Path_226"
            data-name="Path 226"
            d="M219.63-56.936c-2.957.655-4.856,2.071-4.61,5.078.221,2.712,1.928,4.055,4.679,3.983,2.783-.073,4.317-1.572,4.395-4.279C224.184-55.243,222.015-56.323,219.63-56.936Z"
            transform="translate(370.707 72.184)"
            fill="current"
          />
          <path
            id="Path_217"
            data-name="Path 217"
            d="M210.475-72.115a30.011,30.011,0,0,0-30.24,29.944,30.586,30.586,0,0,0,30.383,30.09,30.586,30.586,0,0,0,30.24-30.234A29.992,29.992,0,0,0,210.475-72.115Zm.285,6.049A23.387,23.387,0,0,1,234.372-42.3c-.053,13.372-11,24.393-24.044,24.211a24.149,24.149,0,0,1-23.609-24.127C186.7-55.788,197.1-66.111,210.76-66.066Z"
            transform="translate(390.121 72.184)"
            fill="current"
          />
        </g>
      </svg>
    ),
  },
  {
    name: t("very_satisfied"),
    color: "#60a426",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60.52"
        height="60.027"
        viewBox="0 0 60.52 60.027"
      >
        <g
          id="Group_174"
          data-name="Group 174"
          transform="translate(-760.676 -0.098)"
        >
          <path
            id="Path_227"
            data-name="Path 227"
            d="M19.975-35.787c7.749,8.856,17.772,8.812,25.357,0Z"
            transform="translate(758.221 72.184)"
            fill="current"
          />
          <path
            id="Path_228"
            data-name="Path 228"
            d="M23.82-47.875c2.759-.118,4.332-1.643,4.323-4.317a4.23,4.23,0,0,0-4.731-4.54,4.258,4.258,0,0,0-4.325,4.948C19.317-49.079,21.041-47.752,23.82-47.875Z"
            transform="translate(776.32 72.184)"
            fill="current"
          />
          <path
            id="Path_229"
            data-name="Path 229"
            d="M42.577-47.91c2.127-.18,3.65-1.747,3.55-4.422a4.269,4.269,0,0,0-4.881-4.391,4.2,4.2,0,0,0-4.192,4.588C37.159-49.153,38.965-47.765,42.577-47.91Z"
            transform="translate(740.346 72.184)"
            fill="current"
          />
          <path
            id="Path_218"
            data-name="Path 218"
            d="M32.559-12.059A30.69,30.69,0,0,0,62.852-42.215,29.963,29.963,0,0,0,32.625-72.086,29.98,29.98,0,0,0,2.332-42.28,30.679,30.679,0,0,0,32.559-12.059Zm-.214-54.025c13.627-.057,24.223,10.319,24.2,23.7-.021,13.273-10.9,24.3-23.958,24.3A24.267,24.267,0,0,1,8.636-42.373,23.6,23.6,0,0,1,32.345-66.084Z"
            transform="translate(758.344 72.184)"
            fill="current"
          />
        </g>
      </svg>
    ),
  },
];

function SmileyQuestion({
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
  type,
}) {
  const { t } = useTranslation();

  return (
    <JustPreview>
      <div className="tw-py-4 tw-flex tw-items-center tw-space-s-3 tw-w-full tw-overflow-x-auto inner-scrollbar">
        {smileyFaces(t).map((item, index) => (
          <div
            key={index}
            className={`tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-100 tw-rounded tw-space-y-2 tw-h-28 tw-aspect-square tw-fill-[${item.color}]`}
          >
            <div
              style={{
                fill: item.color,
              }}
            >
              {item.icon}
            </div>
            <div className="tw-text-sm">{item.name}</div>
          </div>
        ))}
      </div>
    </JustPreview>
  );
}

function StarQuestion({
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
  type,
}) {
  const { t } = useTranslation();

  const stars = [...Array(5).keys()];
  return (
    <JustPreview>
      <div className="tw-py-4 tw-flex tw-items-center tw-space-s-3 tw-w-full tw-overflow-x-auto inner-scrollbar">
        {stars.map((item, index) => (
          <StarIcon
            key={index}
            className="tw-h-10 tw-w-10 tw-fill-white tw-stroke-gray-500"
          />
        ))}
      </div>
    </JustPreview>
  );
}

function CalendarQuestion({
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
}) {
  const { t } = useTranslation();
  return (
    <JustPreview>
      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("date")}</div>
      <div className="tw-relative">
        <input
          className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
          placeholder={t(`survey.demoData.day`)}
          readOnly
        />
        <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
      </div>
    </JustPreview>
  );
}

function DateQuestion({
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
}) {
  const { t } = useTranslation();
  return (
    <JustPreview>
      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("time")}</div>
      <div className="tw-space-y-2 sm:tw-flex sm:tw-space-s-4 sm:tw-space-y-0">
        <div className="tw-relative">
          <input
            className="tw-block tw-w-full sm:tw-w-32 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
            placeholder={t(`the_hour`)}
            readOnly
          />
          <ArrowDownIcon className="tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
        </div>
        <div className="tw-relative">
          <input
            className="tw-block tw-w-full sm:tw-w-32 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
            placeholder={t(`the_minute`)}
            readOnly
          />
          <ArrowDownIcon className="tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
        </div>
        <div className="tw-relative">
          <input
            className="tw-block tw-w-full sm:tw-w-32 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
            placeholder={t(`am`)}
            readOnly
          />
          <ArrowDownIcon className="tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
        </div>
      </div>
    </JustPreview>
  );
}

function GraphicalQuestion({
  prefix = "",
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
  type,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!preview && !questionId) {
      setFieldValue(`${prefix}options`, [
        { value: "" },
        { value: "" },
        { value: "" },
      ]);
    }
  }, []);

  return (
    <>
      <FieldArray name={`${prefix}options`}>
        {({ insert, remove, push }) => (
          <div>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
              {values.options?.length &&
                values.options.map((option, index) => (
                  <div
                    key={index}
                    className="tw-relative tw-flex tw-space-y-2 tw-w-full tw-aspect-square"
                  >
                    <label className="tw-relative  tw-mb-20 tw-shrink-0 tw-flex tw-items-center tw-justify-center tw-rounded tw-w-full tw-bg-gray-100 tw-shadow-sm">
                      <input
                        type="file"
                        className="tw-hidden"
                        onChange={(e) =>
                          setFieldValue(
                            `${prefix}options.${index}.value`,
                            e.target.files[0]
                          )
                        }
                        accept=".png,.jpg,.jpeg,.gif"
                        placeholder={t("survey.title")}
                      />
                      {option.value ? (
                        <img
                          src={
                            option.value.name
                              ? URL.createObjectURL(option.value)
                              : option.value
                          }
                          className="tw-w-full tw-max-h-full tw-shrink-0 tw-rounded tw-object-contain"
                        />
                      ) : (
                        <div className="tw-flex tw-flex-col tw-items-center tw-space-y-4 tw-p-4">
                          <PhotoIcon className="tw-h-8" />
                          <div className="tw-text-gray-400">
                            {t("add_image")}
                          </div>
                        </div>
                      )}
                      <div className="tw-absolute tw-top-2 tw-left-2">
                        <DeleteChoose
                          id={option.id}
                          onRemove={() => remove(index)}
                          disabled={values.options.length <= 1}
                        />
                      </div>
                    </label>
                    <div className="tw-absolute tw-bottom-0 tw-w-full tw-h-16">
                      <input
                        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500 tw-text-center"
                        placeholder={index + 1}
                        disabled
                        readOnly
                      />
                      <ErrorMessage
                        name={`${prefix}options.${index}.value`}
                        component="div"
                        className="tw-text-xs tw-text-red-700 tw-h-4"
                      />
                    </div>
                  </div>
                ))}
            </div>
            <button
              type="button"
              onClick={() => push({ value: "" })}
              className="tw-h-6 tw-w-6 tw-mt-3"
            >
              <PlusIcon />
            </button>
          </div>
        )}
      </FieldArray>
    </>
  );
}

function SliderQuestion({
  prefix = "",
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
  type,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!preview && !questionId) {
      setFieldValue(`${prefix}options`, [
        { value: "" },
        { value: "" },
        { value: "" },
      ]);
    }
  }, []);
  return (
    <>
      <div className="tw-p-4">
        <Slider
          marks={values.options.map((o, i) => ({ value: i, label: o.value }))}
          min={0}
          max={values.options.length ? values.options.length - 1 : 0}
          aria-label="Custom marks"
          step={null}
          disabled={true}
          // valueLabelDisplay="auto"
          // getAriaValueText={valuetext}
        />
      </div>
      <div className="md:tw-w-1/2">
        <FieldArray name={`${prefix}options`}>
          {({ insert, remove, push }) => (
            <div>
              <div className="tw-space-y-4">
                {values.options?.length &&
                  values.options.map((option, index) => (
                    <div
                      key={index}
                      className="tw-flex tw-items-center tw-space-s-4"
                    >
                      <div className="tw-grow">
                        <Field
                          name={`${prefix}options.${index}.value`}
                          className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                          placeholder={t("add_title")}
                          type="text"
                        />
                        <ErrorMessage
                          name={`${prefix}options.${index}.value`}
                          component="div"
                          className="tw-text-xs tw-text-red-700 tw-h-4"
                        />
                      </div>
                      <DeleteChoose
                        id={option.id}
                        onRemove={() => remove(index)}
                        disabled={values.options.length <= 1}
                      />
                    </div>
                  ))}
              </div>
              <button
                type="button"
                onClick={() => push({ value: "" })}
                className="tw-h-6 tw-w-6 tw-mt-3"
              >
                <PlusIcon />
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </>
  );
}

function ConstantSumQuestion({
  prefix = "",
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
  questionId,
  type,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!preview && !questionId) {
      setValues({
        ...values,
        [`${prefix}options`]: [{ value: "" }, { value: "" }, { value: "" }],
        [`${prefix}items`]: [{ name: "" }, { name: "" }],
      });
    }
  }, []);
  return (
    <>
      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
        {t("slider_title")}
      </div>

      <FieldArray name={`${prefix}items`}>
        {({ insert: insertItem, remove: removeItem, push: pushItem }) => (
          <>
            {values.items?.length &&
              values.items.map((item, itemIndex) => (
                <div key={itemIndex}>
                  <div className="tw-flex tw-items-center tw-space-s-8 tw-py-4">
                    <div className="tw-w-1/3">
                      <Field
                        name={`${prefix}items.${itemIndex}.name`}
                        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        placeholder={t("add_title")}
                        type="text"
                      />
                      <ErrorMessage
                        name={`${prefix}items.${itemIndex}.name`}
                        component="div"
                        className="tw-text-xs tw-text-red-700 tw-h-4"
                      />
                    </div>
                    <div className="tw-grow">
                      <Slider
                        marks={values.options.map((o, i) => ({
                          value: i,
                          label: o.value,
                        }))}
                        min={0}
                        max={
                          values.options.length ? values.options.length - 1 : 0
                        }
                        aria-label="Custom marks"
                        step={null}
                        disabled={true}
                        // valueLabelDisplay="auto"
                        // getAriaValueText={valuetext}
                      />
                    </div>
                    <DeleteChoose
                      id={item.id}
                      onRemove={() => removeItem(itemIndex)}
                      disabled={values.items.length <= 1}
                    />
                  </div>
                </div>
              ))}

            <div className="tw-relative tw-py-4">
              <hr className="tw-border-dashed" />
              <button
                type="button"
                onClick={() => pushItem({ name: "" })}
                className="tw-flex tw-items-center tw-space-s-2 tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-1/2 -tw-translate-y-1/2 tw-bg-white tw-px-3 tw-text-teal-700"
              >
                <div className="tw-h-4 tw-w-4">
                  <PlusIcon />
                </div>
                <div>{t("add_slider")}</div>
              </button>
            </div>
          </>
        )}
      </FieldArray>

      <div className="md:tw-w-1/2">
        <FieldArray name={`${prefix}options`}>
          {({ insert, remove, push }) => (
            <div>
              <div className="tw-space-y-4">
                {values.options?.length &&
                  values.options.map((option, index) => (
                    <div
                      key={index}
                      className="tw-flex tw-items-center tw-space-s-4"
                    >
                      <div className="tw-grow">
                        <Field
                          name={`${prefix}options.${index}.value`}
                          className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                          placeholder={t("add_title")}
                          type="text"
                        />
                        <ErrorMessage
                          name={`${prefix}options.${index}.value`}
                          component="div"
                          className="tw-text-xs tw-text-red-700 tw-h-4"
                        />
                      </div>
                      <DeleteChoose
                        id={option.id}
                        onRemove={() => remove(index)}
                        disabled={values.options.length <= 1}
                      />
                    </div>
                  ))}
              </div>
              <button
                type="button"
                onClick={() => push({ value: "" })}
                className="tw-h-6 tw-w-6 tw-mt-3"
              >
                <PlusIcon />
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </>
  );
}

function SectionQuestion({
  preview,
  values,
  errors,
  setFieldValue,
  setValues,
}) {
  return <div></div>;
}

function CheckboxQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <FieldArray name={`${prefix}answers`}>
        {({ insert, remove, push }) => (
          <>
            <div>
              {question.options.map((option, index) => (
                <div key={index}>
                  <label className="tw-flex tw-items-center tw-space-s-2">
                    <Field
                      name={`${prefix}answers.${0}.value`}
                      type={"checkbox"}
                      value={option.id}
                      checked={values.answers.find((i) => i.value == option.id)}
                      onChange={({ target: { checked, value } }) => {
                        const findInd = values.answers.findIndex(
                          (i) => i.value == value
                        );
                        if (findInd < 0)
                          push({ value: value, option_id: value });
                        else remove(findInd);
                      }}
                    />
                    <div className="tw-text-gray-500 tw-pb-2">
                      {option.value}
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <ErrorMessage
              name={`${prefix}answers.0.value`}
              component="div"
              className="tw-text-xs tw-text-red-700 tw-h-4"
            />
          </>
        )}
      </FieldArray>
    </div>
  );
}

function RadioQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <div>
      {question.options.map((option, index) => (
        <div key={index}>
          <label className="tw-flex tw-items-center tw-space-s-2">
            <Field
              type={"radio"}
              name={`${prefix}answers.${0}.value`}
              value={option.id}
              checked={values.answers[0]?.value == option.id}
              onChange={({ target: { value } }) => {
                setFieldValue(`${prefix}answers.${0}`, {
                  value: value,
                  option_id: value,
                });
              }}
            />
            <ErrorMessage
              name={`${prefix}answers.${0}.value`}
              component="div"
              className="tw-text-xs tw-text-red-700 tw-h-4"
            />
            <div className="tw-text-gray-500 tw-pb-2">{option.value}</div>
          </label>
        </div>
      ))}
    </div>
  );
}

function DropdownQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
  preview = false,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="tw-border tw-border-gray-200 tw-rounded tw-p-2.5">
        <Field
          as="select"
          name={`${prefix}answers.${0}.value`}
          className="tw-block tw-w-full tw-text-gray-400"
          onChange={({ target: { value } }) => {
            setFieldValue(`${prefix}answers.${0}`, {
              value: value,
              option_id: value,
            });
          }}
        >
          <option value="">{t("please_select")}</option>
          {question.options.map((option, index) => (
            <option key={index} value={option.id}>
              {option.value}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name={`${prefix}answers.${0}.value`}
          component="div"
          className="tw-text-xs tw-text-red-700 tw-h-4"
        />
      </div>
      {preview ? (
        <div className="tw-space-y-2 tw-py-4">
          {question.options.map((option, index) => (
            <div
              key={index}
              className="tw-border tw-border-gray-200 tw-rounded tw-p-2.5"
            >
              {option.value}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

function StarQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  const [over, setOver] = useState(0);
  const stars = [...Array(5).keys()];
  return (
    <div
      className="tw-py-4 tw-px-2 tw-flex tw-items-center tw-space-s-3 tw-w-full"
      onMouseLeave={() => setOver(0)}
    >
      {stars.map((item, index) => (
        <button
          type="button"
          key={index}
          onMouseOver={() => setOver(index + 1)}
          onClick={() => {
            setFieldValue(`${prefix}answers.${0}.value`, index + 1);
          }}
        >
          <StarIcon
            className={`tw-h-10 tw-w-10 hover:tw-w-12 hover:tw-h-12 hover:-tw-m-1 ${
              (over ? over : values.answers[0]?.value) > index
                ? "tw-fill-teal-700 tw-stroke-teal-700"
                : "tw-fill-white tw-stroke-gray-500"
            }`}
          />
        </button>
      ))}
    </div>
  );
}

function SmileyQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  const [over, setOver] = useState(0);
  return (
    <div className="tw-py-4 tw-flex tw-items-center tw-space-s-3 tw-w-full">
      {smileyFaces(t).map((item, index) => (
        <button
          type="button"
          disabled={values.answers[0]?.value == index + 1}
          key={index}
          onClick={() => {
            setFieldValue(`${prefix}answers.${0}.value`, index + 1);
          }}
          className={`tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-100 tw-rounded tw-space-y-2 tw-h-28 tw-aspect-square hover:tw-bg-gray-200 tw-transition-all`}
          style={{
            fill: values.answers[0]?.value == index + 1 ? "white" : item.color,
            color: values.answers[0]?.value == index + 1 ? "white" : null,
            backgroundColor:
              values.answers[0]?.value == index + 1 ? item.color : null,
          }}
        >
          {item.icon}
          <div className="tw-text-sm">{item.name}</div>
        </button>
      ))}
    </div>
  );
}

function GraphicalQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-4">
      {question.options.map((option, index) => (
        <div
          key={index}
          className="tw-relative tw-flex tw-space-y-2 tw-w-full tw-aspect-square"
        >
          <button
            type="button"
            onClick={() => {
              setFieldValue(`${prefix}answers.${0}`, {
                value: option.id,
                option_id: option.id,
              });
            }}
            className={`tw-relative tw-mb-14 tw-shrink-0 tw-flex tw-items-center tw-justify-center tw-rounded tw-w-full tw-bg-gray-100 tw-shadow-sm ${
              values.answers[0]?.value == option.id ? "tw-border-2 tw-border-teal-600 tw-p-2" : ""
            }`}
          >
            <img
              src={option.value}
              className="tw-w-full tw-h-full tw-shrink-0 tw-rounded tw-object-cover"
            />
          </button>
          <div className="tw-absolute tw-bottom-0 tw-w-full tw-h-12">
            <div className="tw-block tw-w-full tw-p-2 tw-text-gray-400 tw-text-center">
              {index + 1}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function TextSliderQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <div className="tw-px-8">
      <Slider
        value={
          question.options.findIndex((i) => values.answers[0]?.value == i.id) +
          1
        }
        marks={[
          { value: 0, label: "" },
          ...question.options.map((o, i) => ({ value: i + 1, label: o.value })),
        ]}
        min={0}
        max={question.options.length ? question.options.length : 0}
        aria-label="Custom marks"
        step={null}
        onChange={({ target: { value } }) => {
          if (value > 0) {
            const optionInd = question.options.findIndex(
              (i) => values.answers[0]?.value == i.id
            );
            if (!(optionInd >= 0 && value - 1 == optionInd)) {
              const option = question.options[value - 1];
              setFieldValue(`${prefix}answers.0`, {
                value: option.id,
                option_id: option.id,
              });
            }
          } else {
            setFieldValue(`${prefix}answers`, []);
          }
        }}
        // valueLabelDisplay="auto"
        // getAriaValueText={valuetext}
      />
    </div>
  );
}

// function NumericSliderQuestionResponse({
//   prefix,
//   values,
//   errors,
//   setFieldValue,
//   question,
// }) {
//   return (
//     <Slider
//       value={values.answers[0]?.value ?? 0}
//       marks={question.options.map((o, i) => ({ value: i, label: o.value }))}
//       min={0}
//       max={question.options.length ? question.options.length - 1 : 0}
//       aria-label="Custom marks"
//       step={null}
//       onChange={({ target: { value } }) => {
//         if (!(values.answers[0]?.value == value)) {
//           setFieldValue(`${prefix}answers.${0}.value`, value);
//         }
//       }}
//       // valueLabelDisplay="auto"
//       // getAriaValueText={valuetext}
//     />
//   );
// }

function ConstantSumQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <FieldArray name={`${prefix}answers`}>
      {({ insert, remove, push }) => (
        <div className="tw-space-y-8 tw-px-8">
          {question.items.map((item, itemIndex) => (
            <div key={itemIndex} className="tw-space-y-2">
              <div className="tw-text-gray-400">{item.name}</div>
              <Slider
                value={
                  question.options.findIndex(
                    (i) =>
                      values.answers.find((ans) => ans.item_id == item.id)
                        ?.value == i.id
                  ) + 1
                }
                marks={[
                  { value: 0, label: "" },
                  ...question.options.map((o, i) => ({
                    value: i + 1,
                    label: o.value,
                  })),
                ]}
                min={0}
                max={question.options.length ? question.options.length : 0}
                aria-label="Custom marks"
                step={null}
                onChange={({ target: { value } }) => {
                  const findInd = values.answers.findIndex(
                    (i) => i.item_id == item.id
                  );
                  if (value > 0) {
                    const option = question.options[value - 1];
                    const val = {
                      value: option.id,
                      option_id: option.id,
                      item_id: item.id,
                    };

                    if (findInd < 0) {
                      push(val);
                    } else {
                      const optionInd = question.options.findIndex(
                        (i) => values.answers[findInd]?.value == i.id
                      );
                      if (!(optionInd >= 0 && optionInd == value - 1)) {
                        setFieldValue(`${prefix}answers.${findInd}`, val);
                      }
                    }
                  } else if (findInd >= 0) {
                    remove(findInd);
                  }
                }}
                // valueLabelDisplay="auto"
                // getAriaValueText={valuetext}
              />
            </div>
          ))}
        </div>
      )}
    </FieldArray>
  );
}

function StringQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Field
        type="text"
        name={`${prefix}answers.0.value`}
        value={values.answers[0]?.value ?? ""}
        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
        placeholder={t("add_text_here")}
        onChange={({ target: { value } }) => {
          setFieldValue(`${prefix}answers.${0}.value`, value);
        }}
      />
    </div>
  );
}

function TextQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Field
        as="textarea"
        name={`${prefix}answers.0.value`}
        value={values.answers[0]?.value ?? ""}
        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500 tw-whitespace-pre-wrap tw-h-24"
        placeholder={t("add_text_here")}
        onChange={({ target: { value } }) => {
          setFieldValue(`${prefix}answers.${0}.value`, value);
        }}
      />
    </div>
  );
}

function EmailQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Field
        type="email"
        name={`${prefix}answers.0.value`}
        value={values.answers[0]?.value ?? ""}
        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500 tw-text-start"
        style={{ direction: "ltr" }}
        placeholder={t("add_email_here")}
        onChange={({ target: { value } }) => {
          setFieldValue(`${prefix}answers.${0}.value`, value);
        }}
      />
      <ErrorMessage
        name={`${prefix}answers.0.value`}
        component="div"
        className="tw-text-xs tw-text-red-700"
      />
    </div>
  );
}

function CalendarQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <div className="tw-relative">
      <DatePicker
        popperProps={{
          strategy: "fixed",
        }}
        selected={Date.parse(values.answers[0]?.value ?? "")}
        defaultValue={Date.parse(values.answers[0]?.value ?? "")}
        onChange={(date) => {
          setFieldValue(`${prefix}answers.${0}.value`, date);
        }}
        showTimeSelect
        placeholderText={t("survey.start_time")}
        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
        showTime={{ use12Hours: true }}
        dateFormat={"yyyy-MM-dd hh:mm:ss a"}
      />
      <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
    </div>
  );
}

function BasicMatrexQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <FieldArray name={`${prefix}answers`}>
      {({ insert, remove, push }) => (
        <table>
          <tbody>
            <tr>
              <td className="tw-p-0"></td>
              {question.options?.map((option, optionIndex) => (
                <td className={`tw-p-0`} key={optionIndex}>
                  <div
                    className={`tw-flex tw-items-center tw-justify-center tw-p-2.5 tw-w-full tw-bg-gray-200 tw-text-center ${
                      optionIndex == 0
                        ? "tw-rounded-ts"
                        : optionIndex == question.options.length - 1
                        ? "tw-rounded-te"
                        : ""
                    } tw-border-t tw-border-gray-200`}
                  >
                    <div className="tw-text-gray-500">{option.value}</div>
                  </div>
                </td>
              ))}
            </tr>

            {question.items?.map((item, itemIndex) => (
              <tr key={itemIndex}>
                <td
                  className={`tw-px-2 tw-w-28 ${
                    itemIndex == 0
                      ? "tw-pb-2"
                      : itemIndex == question.items.length - 1
                      ? "tw-pt-2"
                      : "tw-py-2"
                  }`}
                >
                  <div className="tw-text-gray-400">{item.name}</div>
                </td>
                {question.options.map((option, optionIndex) => (
                  <td className={`tw-p-0`} key={optionIndex}>
                    <div
                      className={`tw-flex tw-items-center tw-justify-center tw-h-16 tw-w-full tw-bg-gray-50 ${
                        itemIndex == question.items.length - 1
                          ? "tw-border-b"
                          : ""
                      } ${optionIndex == 0 ? "tw-border-s" : ""} ${
                        optionIndex == question.options.length - 1
                          ? "tw-border-e"
                          : ""
                      } ${
                        itemIndex == question.items.length - 1 &&
                        optionIndex == question.options.length - 1
                          ? "tw-rounded-be"
                          : ""
                      } ${
                        optionIndex == 0 &&
                        itemIndex == question.items.length - 1
                          ? "tw-rounded-bs"
                          : ""
                      } tw-border-gray-200`}
                    >
                      <Field
                        name={`${prefix}answers.${itemIndex}`}
                        type="radio"
                        checked={
                          values.answers.find((ans) => ans.item_id == item.id)
                            ?.value == option.id
                        }
                        onChange={({ target: { value } }) => {
                          const findInd = values.answers.findIndex(
                            (i) => i.item_id == item.id
                          );
                          const ans = {
                            item_id: item.id,
                            value: option.id,
                            option_id: option.id,
                          };
                          if (findInd < 0) push(ans);
                          else
                            setFieldValue(`${prefix}answers.${findInd}`, ans);
                        }}
                        className="tw-h-3 tw-w-3 tw-border tw-border-gray-200 tw-rounded-full"
                      />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FieldArray>
  );
}

function KeyCom({ children }) {
  return (children)
}

function SideBySideMatrexQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();

  const menu = question.options
    .map((option, optionIndex) =>
      question.item_options.map((itemOption, itemOptionIndex) => ({
        itemOption,
        itemOptionIndex,
        option,
        optionIndex,
      }))
    )
    .reduce((p, n) => [...p, ...n], []);
  return (
    <FieldArray name={`${prefix}answers`}>
      {({ insert, remove, push }) => (
        <table>
          <tbody>
            <tr>
              <td className="tw-p-0"></td>
              {question.options?.map((option, optionIndex) => (
                <KeyCom key={optionIndex}>
                  <td
                    colSpan={question.item_options.length}
                    className={`tw-py-2 tw-text-teal-600 tw-font-bold`}
                    key={optionIndex}
                  >
                    {option.value}
                  </td>

                  {optionIndex < question.options.length - 1 ? (
                    <td>
                      <div className="tw-w-4 tw-shrink-0"></div>
                    </td>
                  ) : (
                    <></>
                  )}
                </KeyCom>
              ))}
            </tr>
            <tr>
              <td className="tw-p-0"></td>
              {menu.map(
                ({ option, optionIndex, itemOption, itemOptionIndex }) => (
                  <KeyCom key={`${optionIndex}-${itemOptionIndex}`}>
                    <td
                      className={`tw-p-0`}
                      key={`${optionIndex}-${itemOptionIndex}`}
                    >
                      <div
                        className={`tw-flex tw-items-center tw-justify-center tw-w-full tw-bg-gray-200 tw-text-center ${
                          itemOptionIndex == 0
                            ? "tw-rounded-ts"
                            : itemOptionIndex == question.options.length - 1
                            ? "tw-rounded-te"
                            : ""
                        } tw-border-t tw-border-gray-200`}
                      >
                        <div className="tw-text-gray-500 tw-whitespace-nowrap tw-p-2.5 tw-shrink-0">
                          {itemOption.name}
                        </div>
                      </div>
                    </td>

                    {optionIndex < question.options.length - 1 &&
                    itemOptionIndex == question.item_options.length - 1 ? (
                      <td>
                        <div className="tw-w-4 tw-shrink-0"></div>
                      </td>
                    ) : (
                      <></>
                    )}
                  </KeyCom>
                )
              )}
            </tr>

            {question.items?.map((item, itemIndex) => (
              <tr key={itemIndex}>
                <td
                  className={`tw-px-2 tw-w-28 ${
                    itemIndex == 0
                      ? "tw-pb-2"
                      : itemIndex == question.items.length - 1
                      ? "tw-pt-2"
                      : "tw-py-2"
                  }`}
                >
                  <div className="tw-text-gray-400">{item.name}</div>
                </td>
                {menu.map(
                  ({ option, optionIndex, itemOption, itemOptionIndex }) => (
                    <KeyCom key={`${optionIndex}-${itemOptionIndex}`}>
                      <td
                        className={`tw-p-0`}
                        key={`${optionIndex}-${itemOptionIndex}`}
                      >
                        <div
                          className={`tw-flex tw-items-center tw-justify-center tw-h-16 tw-w-full tw-bg-gray-50 ${
                            itemIndex == question.items.length - 1
                              ? "tw-border-b"
                              : ""
                          } ${itemOptionIndex == 0 ? "tw-border-s" : ""} ${
                            itemOptionIndex == question.item_options.length - 1
                              ? "tw-border-e"
                              : ""
                          } ${
                            itemIndex == question.items.length - 1 &&
                            itemOptionIndex == question.item_options.length - 1
                              ? "tw-rounded-be"
                              : ""
                          } ${
                            itemOptionIndex == 0 &&
                            itemIndex == question.items.length - 1
                              ? "tw-rounded-bs"
                              : ""
                          } tw-border-gray-200`}
                        >
                          <Field
                            name={`${prefix}answers.${itemIndex}.${optionIndex}`}
                            type="radio"
                            checked={
                              values.answers.find(
                                (ans) =>
                                  ans.item_id == item.id &&
                                  ans.option_id == option.id
                              )?.value == itemOption.id
                            }
                            onChange={({ target: { value } }) => {
                              const findInd = values.answers.findIndex(
                                (i) =>
                                  i.item_id == item.id &&
                                  i.option_id == option.id
                              );
                              const ans = {
                                item_id: item.id,
                                option_id: option.id,
                                value: itemOption.id,
                              };
                              if (findInd < 0) push(ans);
                              else
                                setFieldValue(
                                  `${prefix}answers.${findInd}`,
                                  ans
                                );
                            }}
                            className="tw-h-3 tw-w-3 tw-border tw-border-gray-200 tw-rounded-full"
                          />
                        </div>
                      </td>

                      {optionIndex < question.options.length - 1 &&
                      itemOptionIndex == question.item_options.length - 1 ? (
                        <td>
                          <div className="tw-w-4 tw-shrink-0"></div>
                        </td>
                      ) : (
                        <></>
                      )}
                    </KeyCom>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FieldArray>
  );
}

function FileQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  return (
    <label className="tw-flex tw-items-center tw-justify-center tw-py-16 tw-border-[1px] tw-border-gray-100 tw-rounded">
      <div className="tw-flex tw-flex-col tw-items-center tw-space-y-4">
        <UploadIcon className="tw-h-8" />
        <div className="tw-h-4 tw-text-gray-500">
          {values.answers[0]?.value.name ? values.answers[0].value.name : ""}
        </div>
      </div>
      <input
        type="file"
        className="tw-hidden"
        onChange={(e) =>
          setFieldValue(`${prefix}answers.0.value`, e.target.files[0])
        }
        // accept=".png,.jpg,.jpeg,.gif"
        placeholder={t("survey.title")}
      />
    </label>
  );
}

function RankOrderQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!values.answers.length) {
      setFieldValue(
        `${prefix}answers`,
        question.options.map((option) => ({ value: "", option_id: option.id }))
      );
    }
  }, []);

  return (
    <FieldArray className={`${prefix}answers`}>
      {({ insert, remove, push }) => (
        <div className="tw-space-y-3 tw-w-full sm:tw-w-3/5">
          {question.options.map((option, optionIndex) => (
            <div
              key={optionIndex}
              className="tw-flex tw-justify-between tw-items-stretch tw-w-full tw-text-gray-500 tw-border tw-border-gray-300 tw-rounded"
            >
              <div className="tw-grow tw-p-2.5 tw-border-e">{option.value}</div>

              <div className="tw-p-2.5 sm:tw-w-32">
                <Field
                  as="select"
                  id={`${prefix}answers.${optionIndex}`}
                  className="tw-block tw-w-full tw-text-gray-400 tw-rounded-e"
                  value={
                    values.answers.find((ans) => ans?.option_id == option.id)
                      ?.value ?? ""
                  }
                  onChange={({ target: { value } }) => {
                    const findInd = values.answers?.findIndex(
                      (ans) => ans.option_id == option.id
                    );
                    const val = {
                      value: value,
                      option_id: option.id,
                    };
                    if (findInd < 0) {
                      setFieldValue(
                        `${prefix}answers.${values.answers.length}`,
                        val
                      );
                    } else {
                      setFieldValue(`${prefix}answers.${findInd}`, val);
                    }
                  }}
                >
                  <option value="">{t("please_select")}</option>
                  {question.options.map((option, index) => (
                    <option
                      key={index}
                      value={index}
                      disabled={
                        values.answers.find(
                          (ans) => parseInt(ans?.value) === index
                        )?.value ?? 0
                      }
                      className="disabled:tw-bg-gray-200"
                    >
                      {index + 1}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          ))}
        </div>
      )}
    </FieldArray>
  );
}

function OrderingQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!values.answers.length)
      setFieldValue(
        `${prefix}answers`,
        question.options.map((option, index) => ({
          option_id: option.id,
          value: index,
        }))
      );
  });

  const reorderItems = (from, to, list) => {
    var unOrderedQuestions = [...list];
    var sourceItem = unOrderedQuestions.splice(from, 1)[0];
    unOrderedQuestions.splice(to, 0, sourceItem);
    const answers = unOrderedQuestions.map((option, index) => ({
      option_id: option.id,
      value: index,
    }));
    return answers;
  };

  const SortableHandler = SortableHandle(({}) => (
    <div className="tw-h-4 tw-w-4 tw-cursor-move">
      <MemuIcon />
    </div>
  ));

  const SortableItem = SortableElement(({ option }) => {
    return (
      <div>
        <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-p-2 tw-text-gray-500 tw-cursor-pointer">
          <div className="tw-flex tw-items-center tw-space-s-4">
            <SortableHandler />
            <div>{option.value}</div>
          </div>
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ options }) => {
    return (
      <div>
        {options.map((option, optionIndex) => (
          <SortableItem
            key={optionIndex}
            option={option}
            index={optionIndex}
            optionIndex={optionIndex}
          />
        ))}
      </div>
    );
  });
  function getSortedOptions(answers) {
    if (answers.length) {
      const options = question.options.map((option) => ({
        ...option,
        order: answers.find((ans) => ans.option_id == option.id).value,
      }));

      return options.sort((a, b) => a.order - b.order);
    } else return question.options;
  }

  return (
    <div className="tw-space-y-3 tw-w-3/5">
      <div className="tw-flex tw-items-center tw-space-s-2">
        <div>
          {question.options.map((o, i) => (
            <div key={i} className="tw-flex tw-items-center tw-h-10 tw-justify-center tw-text-gray-400 tw-text-lg">
              {i + 1}-
            </div>
          ))}
        </div>
        <div className="tw-grow">
          <SortableList
            onSortEnd={({ oldIndex, newIndex }) => {
              setFieldValue(
                `${prefix}answers`,
                reorderItems(
                  oldIndex,
                  newIndex,
                  getSortedOptions(values.answers)
                )
              );
            }}
            options={getSortedOptions(values.answers)}
            disableAutoscroll
            useDragHandle={true}
          />
        </div>
      </div>
    </div>
  );
}

function DragAndDropQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();

  return (
    <MultipleContainers
      columns={question.options.length}
      options={question.options}
      answers={values.answers}
      setAnswers={(answers) => setFieldValue(`${prefix}answers`, answers)}
    />
  );
}

function ContactQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (!values.answers.length) {
      setFieldValue(
        `${prefix}answers`,
        question.options.map((option) => ({ value: "", option_id: option.id }))
      );
    }
  }, []);
  return (
    <>
      <FieldArray name={`${prefix}answers`}>
        {({ insert, remove, push }) => (
          <div className="tw-space-y-3">
            {question.options.map((option, index) => (
              <label key={index} className="tw-block mb-0">
                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                  {option.value}
                </div>
                <Field
                  type="text"
                  name={`${prefix}answers.${index}.value`}
                  value={
                    values.answers.find((ans) => ans.option_id == option.id)
                      ?.value ?? ""
                  }
                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                  placeholder={option.value}
                  onChange={({ target: { value } }) => {
                    const findInd = values.answers.findIndex(
                      (ans) => ans.option_id == option.id
                    );
                    const val = {
                      value,
                      option_id: option.id,
                    };
                    if (findInd < 0) {
                      push(val);
                    } else {
                      setFieldValue(`${prefix}answers.${findInd}`, val);
                    }
                  }}
                />
                <ErrorMessage
                  name={`${prefix}answers.${index}.value`}
                  component="div"
                  className="tw-text-xs tw-text-red-700 tw-h-4"
                />
              </label>
            ))}
          </div>
        )}
      </FieldArray>
    </>
  );
}

function DateQuestionResponse({
  prefix,
  values,
  errors,
  setFieldValue,
  question,
}) {
  const { t } = useTranslation();

  function getDateTime(str) {
    let time = new Date();
    if (str) {
      const arr = str.split(":");
      time.setHours(arr[0], arr[1], 0);
    } else {
      time.setHours(1, 0, 0);
    }
    return time;
  }

  return (
    <div>
      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("time")}</div>
      <div className="md:tw-flex md:tw-space-s-4">
        <label className="tw-relative tw-block">
          <Field
            as="select"
            id={`${prefix}answers.hour`}
            className="tw-block tw-w-full md:tw-w-32 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500 tw-appearance-none"
            placeholder={t(`the_hour`)}
            value={
              values.answers[0]?.value
                ? (
                    getDateTime(values.answers[0].value).getHours() % 12
                  ).toString()
                : ""
            }
            onChange={({ target: { value } }) => {
              let time = getDateTime(values.answers[0]?.value);
              time.setHours(
                (parseInt(value) % 12) + (time.getHours() >= 12 ? 12 : 0)
              );
              setFieldValue(
                `${prefix}answers.0.value`,
                `${time.getHours()}:${time.getMinutes()}`
              );
            }}
          >
            {values.answers[0]?.value ? (
              <></>
            ) : (
              <option value="">{t(`the_hour`)}</option>
            )}
            {[...Array(12).keys()].map((i, index) => (
              <option value={(index + 1) % 12}>{index + 1}</option>
            ))}
          </Field>
          <ArrowDownIcon className="tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
        </label>
        <label className="tw-relative tw-block">
          <Field
            as="select"
            id={`${prefix}answers.minute`}
            className="tw-block tw-w-full md:tw-w-32 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500 tw-appearance-none"
            placeholder={t(`the_minute`)}
            value={
              values.answers[0]?.value
                ? getDateTime(values.answers[0].value).getMinutes().toString()
                : ""
            }
            onChange={({ target: { value } }) => {
              let time = getDateTime(values.answers[0]?.value);
              time.setMinutes(value);
              setFieldValue(
                `${prefix}answers.0.value`,
                `${time.getHours()}:${time.getMinutes()}`
              );
            }}
          >
            {values.answers[0]?.value ? (
              <></>
            ) : (
              <option value="">{t(`the_minute`)}</option>
            )}
            {[...Array(60).keys()].map((i, index) => (
              <option value={index}>
                {index.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })}
              </option>
            ))}
          </Field>
          <ArrowDownIcon className="tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
        </label>
        <label className="tw-relative tw-block">
          <Field
            as="select"
            id={`${prefix}answers.am`}
            className="tw-block tw-w-full md:tw-w-32 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500 tw-appearance-none"
            placeholder={t(`am`)}
            value={
              values.answers[0]?.value
                ? getDateTime(values.answers[0].value).getHours() >= 12
                  ? "pm"
                  : "am"
                : "am"
            }
            onChange={({ target: { value } }) => {
              let time = getDateTime(values.answers[0]?.value);
              let hour = time.getHours();
              if (value == "am" && hour >= 12) {
                hour -= 12;
              } else if (value == "pm" && hour < 12) {
                hour += 12;
              }
              time.setHours(hour);
              setFieldValue(
                `${prefix}answers.0.value`,
                `${time.getHours()}:${time.getMinutes()}`
              );
            }}
          >
            <option value="am">{t(`am`)}</option>
            <option value="pm">{t(`pm`)}</option>
          </Field>
          <ArrowDownIcon className="tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
        </label>
      </div>
    </div>
  );
}

const QuestionTypes = [
  {
    title: "selection_title",
    children: [
      {
        title: "اختیار فقط",
        type: "select_one",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
            >
              <path
                id="radio-button-checked-svgrepo-com"
                d="M22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12Zm-2,0a8,8,0,1,1-8-8A8,8,0,0,1,20,12Zm-8,4a4,4,0,1,0-4-4A4,4,0,0,0,12,16Z"
                transform="translate(-1.5 -1.5)"
                fill="#24b3b9"
                stroke="#24b3b9"
                stroke-width="1"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        ),
        component: RadioQuestion,
        responseComponent: RadioQuestionResponse,
      },
      {
        title: "اختیار من متعدد",
        type: "select_many",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <g
                id="Edit_Select_Multiple"
                data-name="Edit / Select_Multiple"
                transform="translate(-2 -2)"
              >
                <path
                  id="Vector"
                  d="M3,9V19.4a2.483,2.483,0,0,0,.109,1.054,1,1,0,0,0,.437.437A2.481,2.481,0,0,0,4.6,21H15M17,8l-4,4-2-2M7,13.8V6.2a4.971,4.971,0,0,1,.218-2.108,2,2,0,0,1,.874-.874A4.971,4.971,0,0,1,10.2,3h7.6a4.967,4.967,0,0,1,2.108.218,2,2,0,0,1,.874.874A4.968,4.968,0,0,1,21,6.2v7.6a4.968,4.968,0,0,1-.218,2.108,2,2,0,0,1-.874.874A4.957,4.957,0,0,1,17.8,17H10.2a4.962,4.962,0,0,1-2.105-.218,2,2,0,0,1-.874-.874A4.967,4.967,0,0,1,7,13.8Z"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
        ),
        component: CheckboxQuestion,
        responseComponent: CheckboxQuestionResponse,
      },
      {
        title: "القائمة المنسدلة",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22.503"
              height="19.5"
              viewBox="0 0 22.503 19.5"
            >
              <g
                id="drop-down-list-svgrepo-com"
                transform="translate(0 -26.762)"
              >
                <path
                  id="Path_219"
                  data-name="Path 219"
                  d="M5.713,34.283V37.9H22.5V34.283Zm15.74,2.565H6.763V35.332H21.453ZM5.713,42.094H22.5V38.48H5.713Zm1.049-2.565H21.453v1.515H6.763ZM5.713,46.262H22.5V42.648H5.713ZM6.763,43.7H21.453v1.515H6.763Zm10.027-10.64h4.547v-6.3H0v6.3H16.789Zm0-5.247h3.5v4.2h-3.5Zm-15.74,4.2v-4.2H15.74v4.2ZM17.459,29.4h2.3l-1.149,1.461Z"
                  fill="#24b3b9"
                />
              </g>
            </svg>
          </div>
        ),
        type: "single_choice",
        component: (props) => <OptionsQuestion {...props} title={"options"} />,
        responseComponent: DropdownQuestionResponse,
      },
    ],
  },
  {
    title: "graphical_classification",
    children: [
      {
        title: "تقیم",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
            >
              <g id="star-circle-svgrepo-com" transform="translate(-1 -1)">
                <circle
                  id="Ellipse_28"
                  data-name="Ellipse 28"
                  cx="10"
                  cy="10"
                  r="10"
                  transform="translate(2 2)"
                  fill="none"
                  stroke="#24b3b9"
                  stroke-width="2"
                />
                <path
                  id="Path_240"
                  data-name="Path 240"
                  d="M10.861,9.363C11.368,8.454,11.621,8,12,8s.632.454,1.139,1.363l.131.235a1.468,1.468,0,0,0,.328.473,1.367,1.367,0,0,0,.532.18l.255.058c.984.223,1.476.334,1.593.71s-.218.768-.889,1.553l-.174.2a1.47,1.47,0,0,0-.329.472,1.535,1.535,0,0,0,0,.584l.026.271c.1,1.046.152,1.57-.154,1.8s-.767.02-1.688-.4l-.238-.11A1.365,1.365,0,0,0,12,15.21a1.365,1.365,0,0,0-.531.181l-.238.11c-.921.424-1.382.636-1.688.4s-.256-.756-.154-1.8l.026-.271a1.535,1.535,0,0,0,0-.584,1.472,1.472,0,0,0-.329-.472l-.174-.2c-.671-.784-1.006-1.176-.889-1.553s.609-.488,1.593-.71l.255-.058a1.367,1.367,0,0,0,.532-.18A1.468,1.468,0,0,0,10.73,9.6Z"
                  fill="none"
                  stroke="#24b3b9"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
        ),
        type: "star_rating",
        component: StarQuestion,
        responseComponent: StarQuestionResponse,
      },
      {
        title: "رموز تعبیرية",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.958"
              height="21.958"
              viewBox="0 0 21.958 21.958"
            >
              <g
                id="face-scan-circle-svgrepo-com"
                transform="translate(-1.021 -1.021)"
              >
                <path
                  id="Path_220"
                  data-name="Path 220"
                  d="M9,16a5,5,0,0,0,6,0"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="2"
                />
                <ellipse
                  id="Ellipse_25"
                  data-name="Ellipse 25"
                  cx="1"
                  cy="1.5"
                  rx="1"
                  ry="1.5"
                  transform="translate(14 9)"
                  fill="#24b3b9"
                />
                <ellipse
                  id="Ellipse_26"
                  data-name="Ellipse 26"
                  cx="1"
                  cy="1.5"
                  rx="1"
                  ry="1.5"
                  transform="translate(8 9)"
                  fill="#24b3b9"
                />
                <path
                  id="Path_221"
                  data-name="Path 221"
                  d="M2.2,10A10.012,10.012,0,0,1,10,2.2"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="2"
                />
                <path
                  id="Path_222"
                  data-name="Path 222"
                  d="M2.2,14A10.012,10.012,0,0,0,10,21.8"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="2"
                />
                <path
                  id="Path_223"
                  data-name="Path 223"
                  d="M21.8,10A10.012,10.012,0,0,0,14,2.2"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="2"
                />
                <path
                  id="Path_224"
                  data-name="Path 224"
                  d="M21.8,14A10.012,10.012,0,0,1,14,21.8"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
        ),
        type: "smiley_rating",
        component: SmileyQuestion,
        responseComponent: SmileyQuestionResponse,
      },
      {
        title: "رموز رسومیة",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22.387"
              height="20.327"
              viewBox="0 0 22.387 20.327"
            >
              <g id="photo-svgrepo-com" transform="translate(-1 -2)">
                <path
                  id="Path_226"
                  data-name="Path 226"
                  d="M8,11A2,2,0,1,0,6,9,2,2,0,0,0,8,11Z"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <path
                  id="Path_227"
                  data-name="Path 227"
                  d="M6.561,21C12.13,8.9,16.76,6.78,22,14.63"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <path
                  id="Path_228"
                  data-name="Path 228"
                  d="M18,3H6A4,4,0,0,0,2,7V17a4,4,0,0,0,4,4H18a4,4,0,0,0,4-4V7A4,4,0,0,0,18,3Z"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
        ),
        type: "graphical_rating",
        component: GraphicalQuestion,
        responseComponent: GraphicalQuestionResponse,
      },
      {
        title: "شريط تمرير النص",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30.8"
              height="15.661"
              viewBox="0 0 30.8 15.661"
            >
              <g
                id="Group_135"
                data-name="Group 135"
                transform="translate(1 0)"
              >
                <path
                  id="slider-horizontal-svgrepo-com"
                  d="M5,19.1H19.4m10.8,0h3.6m-3.6,0a3.6,3.6,0,1,1-3.6-3.6A3.6,3.6,0,0,1,30.2,19.1Z"
                  transform="translate(-5 -8.039)"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <g
                  id="Group_132"
                  data-name="Group 132"
                  transform="translate(0.535 0)"
                >
                  <g
                    id="Group_130"
                    data-name="Group 130"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_260"
                      data-name="Path 260"
                      d="M-165.439,223.665a2.78,2.78,0,0,1,1.035.2,2.681,2.681,0,0,1,.867.551,2.749,2.749,0,0,1,.6.839,2.465,2.465,0,0,1,.225,1.047v3.793a.7.7,0,0,1-.208.507.729.729,0,0,1-.513.225.752.752,0,0,1-.522-.2.644.644,0,0,1-.22-.5v-1.317h-2.509v1.283a.689.689,0,0,1-.226.512.718.718,0,0,1-.517.219.755.755,0,0,1-.519-.2.64.64,0,0,1-.224-.5v-3.737a2.832,2.832,0,0,1,.219-1.058,2.743,2.743,0,0,1,.573-.867,2.7,2.7,0,0,1,.862-.585A2.693,2.693,0,0,1-165.439,223.665Zm1.26,2.724a1.266,1.266,0,0,0-.1-.49,1.174,1.174,0,0,0-.269-.4,1.392,1.392,0,0,0-.405-.27,1.237,1.237,0,0,0-.5-.1,1.2,1.2,0,0,0-.472.1,1.345,1.345,0,0,0-.394.253,1.178,1.178,0,0,0-.27.383,1.139,1.139,0,0,0-.1.473v1.013h2.509Z"
                      transform="translate(168.174 -223.665)"
                      fill="#24b3b9"
                    />
                    <path
                      id="Path_261"
                      data-name="Path 261"
                      d="M-156.848,229.338a.713.713,0,0,1,.54.225.747.747,0,0,1,.214.529.755.755,0,0,1-.214.546.722.722,0,0,1-.54.219h-3.962a.794.794,0,0,1-.394-.118.732.732,0,0,1-.26-.276.776.776,0,0,1-.089-.371.836.836,0,0,1,.112-.405l.012-.011,3.084-4.491h-2.487a.74.74,0,0,1-.529-.214.713.713,0,0,1-.225-.54.737.737,0,0,1,.225-.54.721.721,0,0,1,.529-.225h3.961a.779.779,0,0,1,.388.124.794.794,0,0,1,.271.287.8.8,0,0,1,.1.383.78.78,0,0,1-.124.411l-3.106,4.468Z"
                      transform="translate(167.765 -223.665)"
                      fill="#24b3b9"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        ),
        type: "text_slider",
        component: SliderQuestion,
        responseComponent: TextSliderQuestionResponse,
      },
      {
        title: "شريط تمرير رقمي",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30.8"
              height="14.416"
              viewBox="0 0 30.8 14.416"
            >
              <g id="Group_134" data-name="Group 134" transform="translate(1)">
                <path
                  id="slider-horizontal-svgrepo-com"
                  d="M5,13.85H8.6m10.8,0H33.8m-14.4,0a3.6,3.6,0,1,1-3.6-3.6A3.6,3.6,0,0,1,19.4,13.85Z"
                  transform="translate(-5 -4.034)"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <g
                  id="Group_133"
                  data-name="Group 133"
                  transform="translate(17.419 0)"
                >
                  <path
                    id="Path_262"
                    data-name="Path 262"
                    d="M-164.994,255.924a2.612,2.612,0,0,1,1.047.214,2.775,2.775,0,0,1,.867.585,2.851,2.851,0,0,1,.591.867,2.616,2.616,0,0,1,.219,1.069,2.6,2.6,0,0,1-.214,1.052,2.816,2.816,0,0,1-.58.861,2.665,2.665,0,0,1-.861.585,2.668,2.668,0,0,1-1.069.214,2.783,2.783,0,0,1-1.058-.2,2.671,2.671,0,0,1-.867-.569,2.683,2.683,0,0,1-.585-.861,2.693,2.693,0,0,1-.214-1.08,2.661,2.661,0,0,1,.208-1.052,2.68,2.68,0,0,1,.574-.855,2.785,2.785,0,0,1,.867-.584A2.922,2.922,0,0,1-164.994,255.924Zm0,1.474a1.224,1.224,0,0,0-.484.1,1.3,1.3,0,0,0-.4.265,1.257,1.257,0,0,0-.276.4,1.233,1.233,0,0,0-.1.5,1.2,1.2,0,0,0,.1.49,1.315,1.315,0,0,0,.276.405,1.318,1.318,0,0,0,.4.276,1.166,1.166,0,0,0,.484.1,1.2,1.2,0,0,0,.49-.1,1.233,1.233,0,0,0,.4-.276,1.368,1.368,0,0,0,.27-.405,1.2,1.2,0,0,0,.1-.49,1.3,1.3,0,0,0-.371-.878A1.266,1.266,0,0,0-164.994,257.4Z"
                    transform="translate(167.718 -255.865)"
                    fill="#24b3b9"
                  />
                  <path
                    id="Path_263"
                    data-name="Path 263"
                    d="M-157.308,256.224a2.456,2.456,0,0,1,.8.715,2.979,2.979,0,0,1,.461.951,2.721,2.721,0,0,1,.079,1.048,2.624,2.624,0,0,1-.332,1.012l-1.654,2.881a.659.659,0,0,1-.45.3.748.748,0,0,1-.529-.079.722.722,0,0,1-.349-.45.673.673,0,0,1,.068-.539l.416-.732a2.807,2.807,0,0,1-1.227-.394,2.588,2.588,0,0,1-.833-.72,2.7,2.7,0,0,1-.456-.94,2.734,2.734,0,0,1-.062-1.035,2.678,2.678,0,0,1,.349-1.019,2.594,2.594,0,0,1,.715-.816,2.763,2.763,0,0,1,.94-.456,2.769,2.769,0,0,1,1.041-.073A2.605,2.605,0,0,1-157.308,256.224Zm-.27,2.994a1.163,1.163,0,0,0,.163-.467,1.255,1.255,0,0,0-.034-.473,1.384,1.384,0,0,0-.208-.439,1.167,1.167,0,0,0-.383-.343,1.281,1.281,0,0,0-.467-.152,1.25,1.25,0,0,0-.473.035,1.318,1.318,0,0,0-.433.207,1.259,1.259,0,0,0-.338.371,1.352,1.352,0,0,0-.158.474,1.23,1.23,0,0,0,.028.483,1.374,1.374,0,0,0,.2.439,1.125,1.125,0,0,0,.377.338,1.178,1.178,0,0,0,.461.152,1.394,1.394,0,0,0,.484-.028,1.181,1.181,0,0,0,.439-.208A1.258,1.258,0,0,0-157.579,259.218Z"
                    transform="translate(167.325 -255.861)"
                    fill="#24b3b9"
                  />
                </g>
              </g>
            </svg>
          </div>
        ),
        type: "numeric_slider",
        component: SliderQuestion,
        responseComponent: TextSliderQuestionResponse,
      },
      {
        title: "تقسیم بشريط تمرير",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30.8"
              height="20"
              viewBox="0 0 30.8 20"
            >
              <path
                id="slider-horizontal-svgrepo-com"
                d="M5,24.65H19.4M5,13.85H8.6m21.6,10.8h3.6m-3.6,0a3.6,3.6,0,1,1-3.6-3.6A3.6,3.6,0,0,1,30.2,24.65ZM19.4,13.85H33.8m-14.4,0a3.6,3.6,0,1,1-3.6-3.6A3.6,3.6,0,0,1,19.4,13.85Z"
                transform="translate(-4 -9.25)"
                fill="none"
                stroke="#24b3b9"
                strokeLinecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </div>
        ),
        type: "constant_sum",
        component: ConstantSumQuestion,
        responseComponent: ConstantSumQuestionResponse,
      },
    ],
  },
  {
    title: "ordering_title",
    children: [
      {
        title: "الترتیب",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.606"
              height="16.507"
              viewBox="0 0 20.606 16.507"
            >
              <g id="sort-asc-svgrepo-com" transform="translate(0)">
                <path
                  id="Path_234"
                  data-name="Path 234"
                  d="M22.554,7.332a1.125,1.125,0,0,1-1.591,1.591L18.382,6.342V13.38a1.125,1.125,0,0,1-2.251,0V6.342L13.55,8.923a1.125,1.125,0,0,1-1.591-1.591l4.5-4.5a1.122,1.122,0,0,1,.792-.33h.006a1.122,1.122,0,0,1,.792.33l4.5,4.5ZM3.375,14.505a1.125,1.125,0,1,1,0-2.251h9a1.125,1.125,0,0,1,0,2.251ZM2.25,17.882a1.126,1.126,0,0,0,1.125,1.125H18.382a1.125,1.125,0,1,0,0-2.251H3.375A1.125,1.125,0,0,0,2.25,17.882ZM3.375,10a1.125,1.125,0,1,1,0-2.251h4.5a1.125,1.125,0,0,1,0,2.251ZM2.25,4.376A1.126,1.126,0,0,0,3.375,5.5h4.5a1.125,1.125,0,0,0,0-2.251h-4.5A1.125,1.125,0,0,0,2.25,4.376Z"
                  transform="translate(-2.25 -2.5)"
                  fill="#24b3b9"
                />
              </g>
            </svg>
          </div>
        ),
        type: "ordering",
        component: (props) => <OptionsQuestion {...props} title={"options"} />,
        responseComponent: OrderingQuestionResponse,
      },
      {
        title: "ترتیب الرقمي",
        icon: (
          <div>
            <svg
              id="sort-numeric-up-svgrepo-com"
              xmlns="http://www.w3.org/2000/svg"
              width="16.79"
              height="18.93"
              viewBox="0 0 16.79 18.93"
            >
              <path
                id="Path_235"
                data-name="Path 235"
                d="M12.022,9.135a.906.906,0,0,1-.647-.269L8.969,6.46,6.563,8.866A.915.915,0,0,1,5.268,7.571L8.321,4.518a.921.921,0,0,1,1.295,0l3.053,3.053a.921.921,0,0,1,0,1.295A.906.906,0,0,1,12.022,9.135Z"
                transform="translate(-5.003 -4.249)"
                fill="#24b3b9"
              />
              <path
                id="Path_236"
                data-name="Path 236"
                d="M8.416,23.18a.923.923,0,0,1-.916-.916V5.166a.916.916,0,0,1,1.832,0v17.1A.923.923,0,0,1,8.416,23.18Z"
                transform="translate(-4.45 -4.25)"
                fill="#24b3b9"
              />
              <path
                id="Path_237"
                data-name="Path 237"
                d="M17.5,12.688a.923.923,0,0,1-.916-.916V6.814l-.476.256a.916.916,0,0,1-.892-1.6l.794-.44a1.486,1.486,0,0,1,1.49-.147,1.589,1.589,0,0,1,.9,1.453v5.435a.923.923,0,0,1-.916.916Z"
                transform="translate(-2.846 -4.139)"
                fill="#24b3b9"
              />
              <path
                id="Path_238"
                data-name="Path 238"
                d="M17,18.246A2.748,2.748,0,1,1,19.746,15.5,2.752,2.752,0,0,1,17,18.246Zm0-3.664a.916.916,0,1,0,.916.916A.923.923,0,0,0,17,14.582Z"
                transform="translate(-2.956 -2.369)"
                fill="#24b3b9"
              />
              <path
                id="Path_239"
                data-name="Path 239"
                d="M16.252,20.356h-.586a.916.916,0,1,1,0-1.832h.586a1.556,1.556,0,0,0,1.527-1.453c.012-.354.012-.782.012-1.295v-.611a.916.916,0,0,1,1.832,0v.611c0,.55,0,1-.024,1.392a3.4,3.4,0,0,1-3.359,3.188Z"
                transform="translate(-2.846 -2.037)"
                fill="#24b3b9"
              />
            </svg>
          </div>
        ),
        type: "rank_order",
        component: (props) => <OptionsQuestion {...props} title={"options"} />,
        responseComponent: RankOrderQuestionResponse,
      },
      {
        title: "السحب و الافلات",
        icon: (
          <div>
            <svg
              id="drag-drop-svgrepo-com"
              xmlns="http://www.w3.org/2000/svg"
              width="23.414"
              height="23.414"
              viewBox="0 0 23.414 23.414"
            >
              <path
                id="Path_217"
                data-name="Path 217"
                d="M0,0H23.414V23.414H0Z"
                fill="none"
              />
              <path
                id="Path_218"
                data-name="Path 218"
                d="M15.658,12.732l6.794,3.963-2.9.829,2.073,3.591-1.69.976L17.862,18.5l-2.169,2.1ZM13.707,5.9h1.951V7.854h4.878a.976.976,0,0,1,.976.976v3.9H19.561V9.8H9.8v9.756h3.9v1.951H8.829a.976.976,0,0,1-.976-.976V15.658H5.9V13.707H7.854V8.829a.976.976,0,0,1,.976-.976h4.878Zm-9.756,7.8v1.951H2V13.707Zm0-3.9v1.951H2V9.8Zm0-3.9V7.854H2V5.9Zm0-3.9V3.951H2V2Zm3.9,0V3.951H5.9V2Zm3.9,0V3.951H9.8V2Zm3.9,0V3.951H13.707V2Z"
                transform="translate(-0.049 -0.049)"
                fill="#24b3b9"
              />
            </svg>
          </div>
        ),
        type: "drag_and_drop",
        component: (props) => <OptionsQuestion {...props} title={"options"} />,
        responseComponent: DragAndDropQuestionResponse,
      },
    ],
  },
  {
    title: "string_title",
    children: [
      {
        title: "نص صغیر",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.5"
              height="17.5"
              viewBox="0 0 21.5 17.5"
            >
              <g id="text-field-svgrepo-com" transform="translate(-1.25 -3.25)">
                <path
                  id="Path_241"
                  data-name="Path 241"
                  d="M7.884,7.75a9.234,9.234,0,0,0-1.066.037,1.632,1.632,0,0,0-1.53,1.53,9.233,9.233,0,0,0-.037,1.066v.041a.75.75,0,0,0,1.5,0A8.561,8.561,0,0,1,6.776,9.5.277.277,0,0,1,7,9.276a8.561,8.561,0,0,1,.922-.026H8.25v5.5H7a.75.75,0,0,0,0,1.5h4a.75.75,0,0,0,0-1.5H9.75V9.25h.325A8.561,8.561,0,0,1,11,9.276a.277.277,0,0,1,.228.228,8.568,8.568,0,0,1,.026.922.75.75,0,0,0,1.5,0v-.041a9.223,9.223,0,0,0-.037-1.066,1.632,1.632,0,0,0-1.53-1.53,9.234,9.234,0,0,0-1.066-.037Z"
                  fill="#24b3b9"
                />
                <path
                  id="Path_242"
                  data-name="Path 242"
                  d="M14.056,3.25H9.944A36.527,36.527,0,0,0,5.511,3.4a4.711,4.711,0,0,0-2.87,1.238A4.711,4.711,0,0,0,1.4,7.511a36.527,36.527,0,0,0-.153,4.433v.113A36.526,36.526,0,0,0,1.4,16.489a4.711,4.711,0,0,0,1.238,2.87A4.712,4.712,0,0,0,5.511,20.6a36.546,36.546,0,0,0,4.433.153h4.113a36.546,36.546,0,0,0,4.433-.153,4.712,4.712,0,0,0,2.87-1.238,4.712,4.712,0,0,0,1.238-2.87,36.545,36.545,0,0,0,.153-4.432v-.113A36.547,36.547,0,0,0,22.6,7.511a4.712,4.712,0,0,0-1.238-2.87A4.711,4.711,0,0,0,18.489,3.4,36.526,36.526,0,0,0,14.056,3.25ZM3.7,5.7A3.247,3.247,0,0,1,5.711,4.89,35.992,35.992,0,0,1,10,4.75h4a35.994,35.994,0,0,1,4.289.14A3.247,3.247,0,0,1,20.3,5.7a3.247,3.247,0,0,1,.812,2.009A35.985,35.985,0,0,1,21.25,12a35.986,35.986,0,0,1-.14,4.289A3.247,3.247,0,0,1,20.3,18.3a3.247,3.247,0,0,1-2.009.812A35.986,35.986,0,0,1,14,19.25H10a35.984,35.984,0,0,1-4.289-.14A3.247,3.247,0,0,1,3.7,18.3a3.247,3.247,0,0,1-.812-2.009A35.994,35.994,0,0,1,2.75,12a35.993,35.993,0,0,1,.14-4.289A3.247,3.247,0,0,1,3.7,5.7Z"
                  fill="#24b3b9"
                  fill-rule="evenodd"
                />
              </g>
            </svg>
          </div>
        ),
        type: "string",
        component: (props) => <StringQuestion {...props} type="string" />,
        responseComponent: StringQuestionResponse,
      },
      {
        title: "نصي",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.5"
              height="21.5"
              viewBox="0 0 21.5 21.5"
            >
              <g
                id="text-selection-svgrepo-com"
                transform="translate(-1.25 -1.25)"
              >
                <path
                  id="Path_243"
                  data-name="Path 243"
                  d="M9,9h6"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_244"
                  data-name="Path 244"
                  d="M12,15V9"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_245"
                  data-name="Path 245"
                  d="M6,4A2,2,0,1,1,4,2,2,2,0,0,1,6,4Z"
                  fill="none"
                  stroke="#24b3b9"
                  stroke-width="1.5"
                />
                <path
                  id="Path_246"
                  data-name="Path 246"
                  d="M6,20a2,2,0,1,1-2-2A2,2,0,0,1,6,20Z"
                  fill="none"
                  stroke="#24b3b9"
                  stroke-width="1.5"
                />
                <path
                  id="Path_247"
                  data-name="Path 247"
                  d="M22,4a2,2,0,1,1-2-2A2,2,0,0,1,22,4Z"
                  fill="none"
                  stroke="#24b3b9"
                  stroke-width="1.5"
                />
                <path
                  id="Path_248"
                  data-name="Path 248"
                  d="M22,20a2,2,0,1,1-2-2A2,2,0,0,1,22,20Z"
                  fill="none"
                  stroke="#24b3b9"
                  stroke-width="1.5"
                />
                <path
                  id="Path_249"
                  data-name="Path 249"
                  d="M18,4H6"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_250"
                  data-name="Path 250"
                  d="M20,18V12m0-6V8"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_251"
                  data-name="Path 251"
                  d="M18,20H12M6,20H8"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_252"
                  data-name="Path 252"
                  d="M4,6V18"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>
          </div>
        ),
        type: "text",
        component: TextQuestion,
        responseComponent: TextQuestionResponse,
      },
      {
        title: "email",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.403"
              height="19.172"
              viewBox="0 0 20.403 19.172"
            >
              <path
                id="envelope-svgrepo-com"
                d="M23.3,10.159a.927.927,0,1,0,1.854-.066ZM18.926,5.18v.927h.031Zm-7.948,0-.031.927h.031ZM4.751,10.093a.927.927,0,1,0,1.854.066Zm20.4.033a.927.927,0,1,0-1.855,0Zm-.927,7.419.927.033c0-.011,0-.022,0-.033Zm-5.3,4.946.031-.927h-.031Zm-7.948,0v-.927h-.031Zm-5.3-4.946H4.75c0,.011,0,.022,0,.033ZM6.6,10.126a.927.927,0,1,0-1.855,0Zm18.088.8a.927.927,0,0,0-.936-1.6Zm-6.977,3-.468-.8-.008,0Zm-5.53,0,.476-.8-.008,0Zm-6.041-4.6a.927.927,0,1,0-.936,1.6Zm19.007.768a6.059,6.059,0,0,0-6.258-5.84l.062,1.854A4.2,4.2,0,0,1,23.3,10.159Zm-6.227-5.84H10.977V6.108h7.948Zm-7.917,0a6.059,6.059,0,0,0-6.258,5.84l1.854.066a4.2,4.2,0,0,1,4.342-4.052ZM23.3,10.126v7.419h1.855V10.126Zm0,7.386a4.2,4.2,0,0,1-4.342,4.052l-.062,1.854a6.059,6.059,0,0,0,6.258-5.84Zm-4.373,4.052H10.977v1.855h7.948Zm-7.979,0A4.2,4.2,0,0,1,6.6,17.513l-1.854.066a6.059,6.059,0,0,0,6.258,5.84ZM6.6,17.546V10.126H4.75v7.419Zm17.153-8.22-6.509,3.8.936,1.6,6.509-3.8Zm-6.517,3.807a4.465,4.465,0,0,1-4.579,0l-.951,1.593a6.32,6.32,0,0,0,6.481,0Zm-4.587,0-6.509-3.8-.936,1.6,6.509,3.8Z"
                transform="translate(-4.75 -4.25)"
                fill="#24b3b9"
              />
            </svg>
          </div>
        ),
        type: "email",
        component: (props) => <StringQuestion {...props} type="email" />,
        responseComponent: EmailQuestionResponse,
      },
      {
        title: "نموذج الاتصال",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.18"
              height="20.015"
              viewBox="0 0 20.18 20.015"
            >
              <g id="_x30_6_phone_talking" transform="translate(-0.719 -0.953)">
                <g
                  id="Group_126"
                  data-name="Group 126"
                  transform="translate(0.998 1.219)"
                >
                  <g
                    id="Group_125"
                    data-name="Group 125"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_212"
                      data-name="Path 212"
                      d="M36.359,18.062H36.34a.309.309,0,0,0-.307.317.325.325,0,0,0,.326.317.317.317,0,0,0,0-.633Z"
                      transform="translate(-24.937 -12.728)"
                      fill="#24b3b9"
                      stroke="#24b3b9"
                      stroke-width="0.5"
                    />
                    <path
                      id="Path_213"
                      data-name="Path 213"
                      d="M44.476,18.062h-.018a.309.309,0,0,0-.308.317.324.324,0,0,0,.326.317.317.317,0,0,0,0-.633Z"
                      transform="translate(-30.484 -12.728)"
                      fill="#24b3b9"
                      stroke="#24b3b9"
                      stroke-width="0.5"
                    />
                    <path
                      id="Path_214"
                      data-name="Path 214"
                      d="M52.595,18.062h-.019a.309.309,0,0,0-.307.317.325.325,0,0,0,.326.317.317.317,0,0,0,0-.633Z"
                      transform="translate(-36.031 -12.728)"
                      fill="#24b3b9"
                      stroke="#24b3b9"
                      stroke-width="0.5"
                    />
                    <path
                      id="Path_215"
                      data-name="Path 215"
                      d="M15,22.54c-1.631-.92-2.119-.845-3.363-.142-.638.424-1.406.165-2.742-.922-.262-.214-.525-.452-.782-.707s-.494-.521-.708-.783c-1.007-1.235-1.281-2.362-.954-2.7.963-1.036.745-1.887-.106-3.394-.512-.969-1.075-1.933-1.823-2.154a2.387,2.387,0,0,0-1.589.418,4.531,4.531,0,0,0-1.53,1.9,6.375,6.375,0,0,0-.092,4.193,14.817,14.817,0,0,0,3.685,5.64,14.8,14.8,0,0,0,5.64,3.684,7.038,7.038,0,0,0,2.068.312,5.411,5.411,0,0,0,2.126-.4,4.53,4.53,0,0,0,1.9-1.53,2.374,2.374,0,0,0,.418-1.59C16.921,23.611,15.957,23.048,15,22.54Zm-.3.556c.587.31,1.678.887,1.842,1.441a1.044,1.044,0,0,1-.082.532l-4.285-2.245C12.973,22.379,13.3,22.31,14.692,23.1ZM4.341,12.344c.555.164,1.132,1.256,1.447,1.85.83,1.472.828,1.936.294,2.552L3.769,12.44a1.511,1.511,0,0,1,.475-.108A.343.343,0,0,1,4.341,12.344ZM14.577,26.9a5.8,5.8,0,0,1-3.753.069,14.144,14.144,0,0,1-5.381-3.526l0,0a14.165,14.165,0,0,1-3.527-5.381,5.808,5.808,0,0,1,.07-3.753,3.908,3.908,0,0,1,1.226-1.567L5.718,17.41a4.164,4.164,0,0,0,1.193,2.977c.227.278.479.558.751.831s.551.523.83.75c.978.8,2,1.465,2.967,1.2l4.71,2.468A3.886,3.886,0,0,1,14.577,26.9Z"
                      transform="translate(-0.998 -8.381)"
                      fill="#24b3b9"
                      stroke="#24b3b9"
                      stroke-width="0.5"
                    />
                    <path
                      id="Path_216"
                      data-name="Path 216"
                      d="M37.964,4.244a5.66,5.66,0,0,0-7.631-2.38h0a5.65,5.65,0,0,0-2.023,8.221l-.855,1.466a.317.317,0,0,0,.3.475l2.454-.217a5.652,5.652,0,0,0,7.753-7.565Zm-2.674,7.07a5,5,0,0,1-4.851-.1.317.317,0,0,0-.159-.043h-.028l-1.943.172.65-1.114a.317.317,0,0,0-.019-.349,5.127,5.127,0,0,1-.427-.677,5.026,5.026,0,0,1,2.113-6.776h0a5.019,5.019,0,0,1,4.663,8.889Z"
                      transform="translate(-18.975 -1.219)"
                      fill="#24b3b9"
                      stroke="#24b3b9"
                      stroke-width="0.5"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        ),
        type: "contact_information",
        component: (props) => (
          <OptionsQuestion {...props} type="contact_information" />
        ),
        responseComponent: ContactQuestionResponse,
      },
    ],
  },
  {
    title: "array",
    children: [
      {
        title: "المصفوفة الأساسیة",
        icon: (
          <div>
            <svg
              id="layer1"
              xmlns="http://www.w3.org/2000/svg"
              width="20.09"
              height="19.5"
              viewBox="0 0 20.09 19.5"
            >
              <path
                id="rect7392"
                d="M-6072.479,5897.96a.459.459,0,0,0-.459.459v4.005a.459.459,0,0,0,.459.459h3.808a.46.46,0,0,0,.459-.459v-4.005a.46.46,0,0,0-.459-.459Zm7.682,0a.459.459,0,0,0-.459.459v4.005a.459.459,0,0,0,.459.459h3.808a.459.459,0,0,0,.459-.459v-4.005a.459.459,0,0,0-.459-.459Zm7.682,0a.459.459,0,0,0-.459.459v4.005a.459.459,0,0,0,.459.459h3.808a.459.459,0,0,0,.459-.459v-4.005a.459.459,0,0,0-.459-.459Zm-14.9.919h2.888v3.086h-2.888Zm7.682,0h2.889v3.086h-2.889Zm7.681,0h2.889v3.086h-2.889Zm-15.823,6.369a.46.46,0,0,0-.459.459v4.005a.459.459,0,0,0,.459.459h3.808a.46.46,0,0,0,.459-.459v-4.005a.46.46,0,0,0-.459-.459Zm7.682,0a.46.46,0,0,0-.459.459v4.005a.459.459,0,0,0,.459.459h3.808a.459.459,0,0,0,.459-.459v-4.005a.46.46,0,0,0-.459-.459Zm7.682,0a.46.46,0,0,0-.459.459v4.005a.459.459,0,0,0,.459.459h3.808a.459.459,0,0,0,.459-.459v-4.005a.46.46,0,0,0-.459-.459Zm-14.9.919h2.888v3.086h-2.888Zm7.682,0h2.889v3.086h-2.889Zm7.681,0h2.889v3.086h-2.889Zm-8.141,6.368a.46.46,0,0,0-.459.46v4a.46.46,0,0,0,.459.46h3.808a.46.46,0,0,0,.459-.46v-4a.46.46,0,0,0-.459-.46Zm.46.919h2.889v3.086h-2.889Z"
                transform="translate(6072.938 -5897.96)"
                fill="#24b3b9"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        ),
        type: "basic_matrix",
        component: BasicMatrexQuestion,
        responseComponent: BasicMatrexQuestionResponse,
      },
      {
        title: "مصفوفة جنبًا إلى جنب",
        icon: (
          <div>
            <svg
              id="layer1"
              xmlns="http://www.w3.org/2000/svg"
              width="19.565"
              height="19.171"
              viewBox="0 0 19.565 19.171"
            >
              <path
                id="rect1634"
                d="M-6361.048,4941.879a.459.459,0,0,0-.459.459v7.419a.46.46,0,0,0,.459.46h7.419a.46.46,0,0,0,.459-.46v-7.419a.46.46,0,0,0-.459-.459Zm11.227,0a.459.459,0,0,0-.459.459v7.419a.46.46,0,0,0,.459.46h7.419a.46.46,0,0,0,.46-.46v-7.419a.46.46,0,0,0-.46-.459Zm-10.768.919h6.5v6.5h-6.5Zm11.228,0h6.5v6.5h-6.5Zm-11.687,9.914a.46.46,0,0,0-.459.459v7.419a.46.46,0,0,0,.459.459h7.419a.46.46,0,0,0,.459-.459v-7.419a.459.459,0,0,0-.459-.459Zm11.227,0a.459.459,0,0,0-.459.459v7.419a.46.46,0,0,0,.459.459h7.419a.46.46,0,0,0,.46-.459v-7.419a.46.46,0,0,0-.46-.459Zm-10.767.919h6.5v6.5h-6.5Zm11.227,0h6.5v6.5h-6.5Z"
                transform="translate(6361.508 -4941.879)"
                fill="#24b3b9"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        ),
        type: "side_by_side_matrix",
        component: SideBySideMatrexQuestion,
        responseComponent: SideBySideMatrexQuestionResponse,
      },
    ],
  },
  {
    title: "miscellaneous",
    children: [
      {
        title: "تاريخ - وقت",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.5"
              height="21"
              viewBox="0 0 21.5 21"
            >
              <g
                id="calendar-mark-svgrepo-com"
                transform="translate(-1.25 -1.75)"
              >
                <path
                  id="Path_194"
                  data-name="Path 194"
                  d="M7,4V2.5"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_195"
                  data-name="Path 195"
                  d="M17,4V2.5"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <circle
                  id="Ellipse_24"
                  data-name="Ellipse 24"
                  cx="1.5"
                  cy="1.5"
                  r="1.5"
                  transform="translate(15 15)"
                  fill="none"
                  stroke="#24b3b9"
                  stroke-width="1.5"
                />
                <path
                  id="Path_196"
                  data-name="Path 196"
                  d="M21.5,9H10.75M2,9H5.875"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_197"
                  data-name="Path 197"
                  d="M14,22H10c-3.771,0-5.657,0-6.828-1.172S2,17.771,2,14V12C2,8.229,2,6.343,3.172,5.172S6.229,4,10,4h4c3.771,0,5.657,0,6.828,1.172S22,8.229,22,12v2c0,3.771,0,5.657-1.172,6.828A4.325,4.325,0,0,1,18,21.9"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>
          </div>
        ),
        type: "date",
        component: DateQuestion,
        responseComponent: DateQuestionResponse,
      },
      {
        title: "تقويم",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.5"
              height="21"
              viewBox="0 0 21.5 21"
            >
              <g id="calendar-svgrepo-com" transform="translate(-1.25 -1.75)">
                <path
                  id="Path_198"
                  data-name="Path 198"
                  d="M2,12C2,8.229,2,6.343,3.172,5.172S6.229,4,10,4h4c3.771,0,5.657,0,6.828,1.172S22,8.229,22,12v2c0,3.771,0,5.657-1.172,6.828S17.771,22,14,22H10c-3.771,0-5.657,0-6.828-1.172S2,17.771,2,14Z"
                  fill="none"
                  stroke="#24b3b9"
                  stroke-width="1.5"
                />
                <path
                  id="Path_199"
                  data-name="Path 199"
                  d="M7,4V2.5"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_200"
                  data-name="Path 200"
                  d="M17,4V2.5"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_201"
                  data-name="Path 201"
                  d="M2.5,9h19"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_202"
                  data-name="Path 202"
                  d="M18,17a1,1,0,1,1-1-1A1,1,0,0,1,18,17Z"
                  fill="#24b3b9"
                />
                <path
                  id="Path_203"
                  data-name="Path 203"
                  d="M18,13a1,1,0,1,1-1-1A1,1,0,0,1,18,13Z"
                  fill="#24b3b9"
                />
                <path
                  id="Path_204"
                  data-name="Path 204"
                  d="M13,17a1,1,0,1,1-1-1A1,1,0,0,1,13,17Z"
                  fill="#24b3b9"
                />
                <path
                  id="Path_205"
                  data-name="Path 205"
                  d="M13,13a1,1,0,1,1-1-1A1,1,0,0,1,13,13Z"
                  fill="#24b3b9"
                />
                <path
                  id="Path_206"
                  data-name="Path 206"
                  d="M8,17a1,1,0,1,1-1-1A1,1,0,0,1,8,17Z"
                  fill="#24b3b9"
                />
                <path
                  id="Path_207"
                  data-name="Path 207"
                  d="M8,13a1,1,0,1,1-1-1A1,1,0,0,1,8,13Z"
                  fill="#24b3b9"
                />
              </g>
            </svg>
          </div>
        ),
        type: "calender",
        component: CalendarQuestion,
        responseComponent: CalendarQuestionResponse,
      },
      {
        title: "مجموعة اسئلة",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.625"
              height="19.5"
              viewBox="0 0 20.625 19.5"
            >
              <g
                id="question-and-answer-svgrepo-com"
                transform="translate(-98.953 -242)"
              >
                <path
                  id="Path_232"
                  data-name="Path 232"
                  d="M280.394,261.5l-2.781-2.576h-6.068a1.747,1.747,0,0,1-1.744-1.744V243.744A1.747,1.747,0,0,1,271.544,242H288.68a1.747,1.747,0,0,1,1.744,1.744V257.18a1.747,1.747,0,0,1-1.744,1.744h-5.61Zm-8.849-17.756V257.18h6.748l2.078,1.923,2-1.923h6.31V243.744Z"
                  transform="translate(-170.847)"
                  fill="#24b3b9"
                />
                <path
                  id="Path_233"
                  data-name="Path 233"
                  d="M499.087,363.585H497.06l-.007.367a.873.873,0,0,1-.872.855h0a.842.842,0,0,1-.842-.842v-.017l.024-1.249a.874.874,0,0,1,.838-.855.645.645,0,0,1,.081,0h1.929v-1.6h-3.236a.872.872,0,0,1,0-1.744h4.108a.872.872,0,0,1,.872.872v3.34A.875.875,0,0,1,499.087,363.585Zm-3.781,3.236v-.088a.872.872,0,1,1,1.744,0v.088a.872.872,0,0,1-1.744,0Z"
                  transform="translate(-387.594 -112.577)"
                  fill="#24b3b9"
                />
              </g>
            </svg>
          </div>
        ),
        type: "section",
        component: (props) => <SectionQuestion {...props} type="section" />,
        responseComponent: CheckboxQuestionResponse,
      },
      {
        title: "رفع ملف",
        icon: (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.25"
              height="20.25"
              viewBox="0 0 20.25 20.25"
            >
              <g
                id="upload-square-svgrepo-com"
                transform="translate(-1.25 -1.25)"
              >
                <path
                  id="Path_253"
                  data-name="Path 253"
                  d="M11.813,16.563V10m0,0,2.813,2.813M11.813,10,9,12.813"
                  transform="translate(-0.437 -0.5)"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_254"
                  data-name="Path 254"
                  d="M15.5,7H8"
                  transform="translate(-0.375 -0.313)"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_255"
                  data-name="Path 255"
                  d="M20.75,11.375c0,4.419,0,6.629-1.373,8s-3.583,1.373-8,1.373-6.629,0-8-1.373S2,15.794,2,11.375s0-6.629,1.373-8S6.956,2,11.375,2s6.629,0,8,1.373c.913.913,1.219,2.2,1.321,4.252"
                  fill="none"
                  stroke="#24b3b9"
                  strokeLinecap="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>
          </div>
        ),
        type: "single_file",
        component: FileQuestion,
        responseComponent: FileQuestionResponse,
      },
    ],
  },
];

export default QuestionTypes;

export function getAllQuestionTypes() {
  var items = [];
  QuestionTypes.forEach((group) => {
    items = [...items, ...group.children];
  });
  return items;
}

export function getQuestionType(type) {
  return getAllQuestionTypes().find((i) => i.type == type);
}
