import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import noResult from "../../../assets/image/Noresult.png";
import UserCard from "../../Shared/Components/UserCard/UserCard";
import classes from "./trainersCard.module.css";

function TrainersCard({ trainers }) {
  require("./trainersCard.css");
  const { t } = useTranslation();
  const { id } = useParams;

  return (
    <>
      {trainers.length !== 0 &&
        trainers?.map((trainer, index) => (
          <NavLink
            to={`/trainers/trainer-details/${trainer.id}`}
            key={index}
            className={classes.flexcard}
          >
            <div>
              <UserCard
                name={trainer.name_ar}
                rate={trainer.rating}
                postionTitle={trainer.job_title_en}
                description={trainer.description_ar}
                img={trainer.img}
                countCourses={trainer.countCourses}
                countUsers={trainer.countUsers}
                trainer={trainer}
              />
            </div>
          </NavLink>
        ))}
    </>
  );
}

export default TrainersCard;
