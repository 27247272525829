import { Pagination } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  deleteOffer,
  getOfferList,
  postOfferStatus,
  resetOffer,
} from "../../../../../Redux/Actions/offer/offer.action";
import { getAllCourses } from "../../../../../Services/api/CoursesList";
import { exportOfferList } from "../../../../../Services/api/OfferList";
import deleteIcon from "../../../../../assets/icons/delete.svg";
import HomeIcon from "../../../../../assets/icons/home.svg";
import editExhIcon from "../../../../../assets/icons/pen.svg";
import DeleteIcon from "../../../../../assets/icons/warning.svg";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import SwitchToggle from "../../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./offers.module.css";
import { filterActions } from "./offersAction";
import TableActionsColumn from "../../../../Shared/Components/TableActionsColumn/TableActionsColumn";

const List = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { get_offers, post_offers_status, delete_offer, loading } = useSelector(
    (state) => state.offerReducer
  );
  const [showFilter, setShowFilter] = useState(false);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentOffersFilter, setCurrentOffersFilter] = useState({});
  const [filterData, setFilterData] = useState({
    name: "",
    status: "",
    mechanism: "",
    start_date: "",
    end_date: "",
    course: "",
    program: "",
    parent_type: "",
  });
  const [deletedId, setDeletedId] = useState();
  const [changeStatusId, setChangeStatusId] = useState();
  const [isExportLoading, setIsExportLoading] = useState(false);

  const [offers, setOffers] = useState([]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const statusHandler = async (id, status) => {
    try {
      dispatch(postOfferStatus(id));
    } catch (err) {}
  };

  const deleteObjectById = (idToDelete) => {
    const updatedArray = offers.filter((obj) => obj.id !== idToDelete);
    setOffers(updatedArray);
  };

  const deleteHandler = async (id) => {
    dispatch(deleteOffer(id));
    setIsLoading(true);
    setShowDeleteModal(false);
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      status: "",
      mechanism: "",
      start_date: "",
      end_date: "",
      course: "",
      program: "",
      parent_type: "",
    });
    setIsResetApplied(true);
    setIsLoading(true);
    dispatch(getOfferList({ page: currentPage, perPage: 10 }));
  };

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const updateObjectById = (id, newObject) => {
    setOffers((prevObjects) =>
      prevObjects.map((obj) => (obj.id === id ? newObject : obj))
    );
  };

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  useEffect(() => {
    if (delete_offer.status === 200) {
      deleteObjectById(deletedId);
      setIsLoading(false);
    }
  }, [delete_offer]);

  useEffect(() => {
    setIsResetApplied(false);
    dispatch(getOfferList({ ...filterData, page: currentPage, perPage: 10 }));
  }, [currentPage, isResetApplied]);

  useEffect(() => {
    setIsLoading(true);
    const filter = getFilterData();
    dispatch(getOfferList({ ...filter, page: currentPage, perPage: 10 }));
    setCurrentOffersFilter(filterData);
  }, []);

  useEffect(() => {
    if (get_offers.status === 200) {
      setOffers(get_offers.data.offers.data);
      setTotalPages(get_offers.data.offers.meta.totalPages);
      setIsLoading(false);
    }
  }, [get_offers]);

  useEffect(() => {
    if (post_offers_status.status === 200 && post_offers_status.data.status) {
      updateObjectById(changeStatusId, post_offers_status.data.response);
    }
  }, [post_offers_status]);

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.mechanism && (filter.mechanism = filterData.mechanism);
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);
    filterData.parent_type && (filter.parent_type = filterData.parent_type);
    filterData.status && (filter.status = filterData.status);
    (filterData.course || filterData.program) &&
      (filterData.parent_type == 1
        ? (filter.parent_id = filterData.course)
        : (filter.parent_id = filterData.program));

    return filter;
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportOfferList(currentOffersFilter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const displayMode = (parent_type) => {
    if (parent_type === 1) {
      return t("rate.label.course");
    } else {
      return t("general.program");
    }
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "offer",
      page: t("financial.offer.offer"),
      active: true,
    },
  ];

  const mechanismOptions = [
    {
      id: 1,
      value: 2,
      label: t("coupon.label.per_value"),
    },
    {
      id: 2,
      value: 1,
      label: t("coupon.label.co_value"),
    },
  ];

  const actionsList = [
    {
      id: "edit",
      icon: <img src={editExhIcon} alt="" />,
      action: (id) => {
        history.push(
          `${
            localStorage.getItem("type") == "accountant"
              ? "/accountant"
              : "/admin"
          }/offer/edit/${id}`
        );
        dispatch(resetOffer());
      },
      color: "#13B5BD",
    },
    {
      id: "delete",
      icon: <img src={deleteIcon} alt="" />,
      action: (id) => {
        setDeletedId(id);
        setShowDeleteModal(true);
      },
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },

    {
      id: "title",
      name: t("financial.label.title"),
      key: "title",
      cell: ({ rowData }) => <>{rowData.name}</>,
      sortable: false,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },

    {
      id: "discount_type_machinsim",
      name: t("financial.label.discount_type"),
      key: "discount_type_machinsim",
      cell: ({ rowData }) => (
        <>
          {rowData?.mechanism == 1
            ? t("coupon.label.co_value")
            : t("coupon.label.per_value")}
        </>
      ),
    },
    {
      id: "discount_type",
      name: t("offers_value"),
      key: "discount_type",
      cell: ({ rowData }) => (
        <>
          {rowData.mechanism === "1"
            ? `${rowData.value} ${t("trainer.rs")}`
            : `${rowData.value}%`}
        </>
      ),
      sortable: false,
    },

    {
      id: "parent_type",
      name: t("financial.label.parent_type"),
      key: "parent_type",
      cell: ({ rowData }) => (
        <>
          <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.parent_type == 1 ? "#8599B6" : "#12C689",
              backgroundColor:
                rowData?.parent_type == 1 ? "#C1CEE222" : "#C1E2C822",
            }}
          >
            {displayMode(rowData?.parent_type) || "-"}
          </span>
        </>
      ),
      sortable: false,
    },

    {
      id: "course_program",
      name: t("financial.label.course_program"),
      key: "course_program",
      cell: ({ rowData }) => (
        <span
          style={{
            color: "#046c77",
          }}
        >
          {rowData?.parent?.name}
        </span>
      ),
      sortable: false,
    },
    {
      id: "note_for_user",
      name: t("note_for_user"),
      key: "note_for_user",
      cell: ({ rowData }) => (
        <>
          <div style={{ width: 300 }}>
            <p className={classes["note_table"]}>{rowData?.notes || "-"}</p>
          </div>
        </>
      ),
    },

    {
      id: "start_date_time",
      name: t("financial.label.start_date_time"),
      key: "start_date_time",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.start_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.start_date)}
        </>
      ),
      sortable: false,
    },

    {
      id: "end_date_time",
      name: t("financial.label.end_date_time"),
      key: "end_date_time",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.end_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.end_date)}
        </>
      ),
      sortable: false,
    },

    {
      id: "adding_date",
      name: t("chat.addingdate"),
      key: "adding_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {moment(rowData.created_at).format("A hh:mm")}
        </>
      ),
      sortable: false,
    },

    {
      id: "actions",
      name: t("financial.label.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn
          actions={actionsList}
          id={rowData.id}
          disabled={new Date(rowData.date) <= Date.now()}
        />
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("financial.label.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status ? true : false}
          id={rowData.id}
          onStatusChange={() => {
            setChangeStatusId(rowData.id);
            statusHandler(rowData.id);
          }}
        />
      ),
      sortable: false,
    },
  ];

  const status = [
    { id: 1, value: true, text: t("crud.values.active") },
    { id: 2, value: false, text: t("crud.values.inactive") },
  ];

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
      value: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
      value: 2,
    },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("financial.label.title"),
      type: "text",
      placeholder: t("financial.label.title"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "mechanism",
      label: t("coupon.label.machinsim"),
      component: "select",
      placeholder: t("coupon.label.machinsim"),
      initialValue: t("coupon.label.machinsim"),
      name: "mechanism",
      value: filterData.mechanism,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: mechanismOptions || [],
    },

    {
      id: "start_date",
      component: "datePicker",
      label: t("financial.label.start_date_time"),
      type: "date",
      placeholder: t("financial.label.start_date_time"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "end_date",
      component: "datePicker",
      label: t("financial.label.end_date_time"),
      type: "date",
      placeholder: t("financial.label.end_date_time"),
      name: "end_date",
      value: filterData.end_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "parent_type",
      label: t("coupon.label.select_course_or_program"),
      component: "select",
      placeholder: t("coupon.label.select_course_or_program"),
      initialValue: t("coupon.label.select_course_or_program"),
      name: "parent_type",
      value: filterData.parent_type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: parentTypeOptions || [],
    },

    ...(filterData.parent_type == 1
      ? [
          {
            id: "course",
            label: t("coupon.label.select_course"),
            component: "select",
            placeholder: t("coupon.label.select_course"),
            initialValue: t("coupon.label.select_course"),
            name: "course",
            value: filterData.course,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: courses?.length
              ? courses?.map((course) => ({
                  id: course.id,
                  value: course.id,
                  label: course.name,
                }))
              : [],
          },
        ]
      : []),
    ...(filterData.parent_type == 2
      ? [
          {
            id: "program",
            label: t("coupon.label.select_program"),
            component: "select",
            placeholder: t("coupon.label.select_program"),
            initialValue: t("coupon.label.select_program"),
            name: "program",
            value: filterData.program,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: programs?.length
              ? programs?.map((program) => ({
                  id: program.id,
                  value: program.id,
                  label: program.name,
                }))
              : [],
          },
        ]
      : []),
    {
      id: "status",
      label: t("general.status"),
      component: "select",
      placeholder: t("general.status"),
      initialValue: t("general.status"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: status?.length
        ? status?.map((trainer) => ({
            id: trainer.value,
            value: trainer.value,
            label: trainer.text,
          }))
        : [],
    },

    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("chat.reset"),
      classes: "col-12 col-md-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("chat.search"),
      classes: "col-12 col-md-2",
      action: () => {
        const filter = getFilterData();
        dispatch(getOfferList(filter));
        setCurrentOffersFilter(filter);
        setIsLoading(true);
        setCurrentPage(1);
      },
    },
  ];

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { not_in_program: 1 } : {}),
      mode: "Paid",
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      })
      .finally(() => {
        setIsCoursesLoading(false);
        setIsProgramsLoading(false);
      });
  };

  useEffect(() => {
    getAllCoursesProgramsHandler({
      is_program: false,
    });
    getAllCoursesProgramsHandler({
      is_program: true,
    });
  }, []);

  return (
    <div className={classes["chat_list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      {loading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["chat_list__container"]}>
          <div className={`row ${classes.breadcrumbs}`}>
            <Breadcrumb list={breadcrumbList} />
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("financial.offer.offer")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    addOffer: () => {
                      history.push(
                        `${
                          localStorage.getItem("type") == "accountant"
                            ? "/accountant"
                            : "/admin"
                        }/offer/add`
                      );
                      dispatch(resetOffer());
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && <FilterBox fields={filterFields} />}
                <MainTable
                  columns={columns}
                  data={offers}
                  isLoading={isLoading}
                  sortAction={sortActionHandler}
                />
                <Pagination
                  count={totalPages}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setIsLoading(true);
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
              {showDeleteModal && (
                <SharedModal
                  icon={DeleteIcon}
                  title={t("financial.offer.approve")}
                  confirmMsg={t("financial.offer.msg_popup")}
                  confirmText={t("chat.confirm")}
                  cancelText={t("chat.cancel")}
                  onCancel={() => {
                    setShowDeleteModal(false);
                  }}
                  onConfirm={() => {
                    deleteHandler(deletedId);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
