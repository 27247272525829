import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  updateSystemUserStatus,
  acceptSystemUserStatus,
  rejectSystemUserStatus,
} from "../../../Redux/Actions/systemUsers/systemUsersList";
import DeleteIcon from "../../../assets/icons/delete.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import editIcon from "../../../assets/icons/pen.svg";
import permissionIcon from "../../../assets/icons/permissions.svg";
import { Lang } from "../../../utils";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import SwitchToggle from "../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./systemUsersList.module.css";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";
import userCheck from "../../../assets/image/user-check.svg";
import userX from "../../../assets/image/user-x.svg";
import ModelWithInput from "../../Shared/Components/ModelWithInput/ModelWithInput";

const SystemUsersListTable = ({ sortAction, rolesEditHandle, currentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAcceptModel, setShowAcceptModel] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [showCertificateModel, setShowCertificateModel] = useState(false);
  const [certifacates, setCertifacates] = useState([]);

  const { systemUsersList, systemUsersListLoading } = useSelector(
    (state) => state.systemUsersListReducer
  );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };
  useEffect(() => {}, [systemUsersList]);

  const updateSystemUserStatusHandler = (id) => {
    dispatch(
      updateSystemUserStatus({
        id,
      })
    );
  };
  const openCertifacateModel = (certifacates) => {
    setCertifacates(certifacates);
    setShowCertificateModel(true);
  };
  const acceptHandler = (id) => {
    setShowAcceptModel(true);
    setUserId(id);
  };

  const ConfirmAcceptHandler = () => {
    if (!userId) return;
    dispatch(acceptSystemUserStatus({ id: userId, status: "approved" }));
    setShowAcceptModel(false);
  };

  const rejectHandler = (id) => {
    setShowRejectModel(true);
    setUserId(id);
  };

  const ConfirmRejectHandler = (reject_reason) => {
    if (!userId) return;
    dispatch(
      rejectSystemUserStatus({
        id: userId,
        status: "rejected",
        reject_reason: reject_reason,
      })
    );
    setShowRejectModel(false);
  };

  const systemUsersActionsList = [
    {
      id: "accept",
      icon: <img src={userCheck} alt="" />,
      action: (id) => {
        acceptHandler(id);
      },
      tooltip: t("general.accept_model_title"),
      color: "#24b3b9",
    },

    {
      id: "reject",
      icon: <img src={userX} alt="" />,
      action: (id) => {
        rejectHandler(id);
      },
      tooltip: t("general.reject_model_title"),
      color: "#b92424",
    },
  ];

  const displayGender = (gender) => {
    return Number(gender) === 1 ? t("male") : t("female");
  };

  const displayIsMedical = (isMedical) => {
    return Number(isMedical) === 1 ? t("yes") : t("no");
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (currentPage * 10 - 10)}</>
      ),
    },
    {
      id: "name",
      name: t("system_users.name"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-name"]}>
          {rowData?.name || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "email",
      name: t("system_users.email"),
      key: "email",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-email__wrapper"]}>
          <a
            className={classes["system-users-list__table-email"]}
            href={`mailto: ${rowData?.email}`}
          >
            {rowData?.email}
          </a>
        </div>
      ),
      sortable: false,
    },
    {
      id: "alternative_email",
      name: t("system_users.alternative_email"),
      key: "alternative_email",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-email__wrapper"]}>
          <a
            className={classes["system-users-list__table-email"]}
            href={
              rowData?.alternative_email
                ? `mailto: ${rowData?.alternative_email}`
                : "#"
            }
          >
            {rowData?.alternative_email || "-"}
          </a>
        </div>
      ),
      sortable: false,
    },
    {
      id: "university_email",
      name: t("system_users.university_email"),
      key: "university_email",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-email__wrapper"]}>
          <a
            className={classes["system-users-list__table-email"]}
            href={
              rowData?.university_email
                ? `mailto: ${rowData?.university_email}`
                : "#"
            }
          >
            {rowData?.university_email || "-"}
          </a>
        </div>
      ),
      sortable: false,
    },
    {
      id: "register_type",
      name: t("system_users.register_type"),
      key: "register_type",
      cell: ({ rowData }) => (
        <div
          className={`${classes["system-users-list__table-register-type"]} ${
            classes[
              `system-users-list__table-register-type--${
                rowData?.type_login?.value || ""
              }`
            ]
          }`}
        >
          {rowData?.type_login?.text || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "jobs",
      name: t("system_users.jobs"),
      key: "role",
      cell: ({ rowData }) => (
        <ul className={classes["system-users-list__table-jobs"]}>
          {!!rowData.roles &&
            rowData.roles.map((job) => (
              <li
                className={classes["system-users-list__table-jobs-item"]}
                key={job?.id}
              >
                {job?.label || "-"}
              </li>
            ))}
        </ul>
      ),
      sortable: false,
    },
    {
      id: "phone",
      name: t("system_users.phone"),
      key: "phone",
      cell: ({ rowData }) => (
        <div style={{ direction: "ltr" }}>{rowData?.phone || "-"}</div>
      ),
      sortable: false,
    },
    {
      id: "id_number",
      name: t("system_users.id_number"),
      key: "id_number",
      cell: ({ rowData }) => <>{rowData?.id_number || "-"}</>,
      sortable: false,
    },
    {
      id: "gender",
      name: t("system_users.gender"),
      key: "gender",
      cell: ({ rowData }) => (
        <>{rowData?.gender ? displayGender(rowData.gender) : "-"}</>
      ),
      sortable: false,
    },
    {
      id: "nationality",
      name: t("system_users.nationality"),
      key: "nationality",
      cell: ({ rowData }) => <>{rowData?.nationality?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "country",
      name: t("system_users.country"),
      key: "country",
      cell: ({ rowData }) => <>{rowData?.country?.name || "-"}</>,
      sortable: false,
    },
    // {
    //   id: "is_medical",
    //   name: t("system_users.is_medical"),
    //   key: "is_medical",
    //   cell: ({ rowData }) => (
    //     <>{displayIsMedical(rowData?.is_medical) || "-"}</>
    //   ),
    //   sortable: false,
    // },
    {
      id: "major",
      name: t("system_users.major"),
      key: "major",
      cell: ({ rowData }) => (
        <>
          {i18n.language === Lang.AR
            ? rowData?.specialty?.name_ar
            : rowData?.specialty?.name_en || "-"}
        </>
      ),
      sortable: false,
    },
    {
      id: "department",
      name: t("system_users.department"),
      key: "department",
      cell: ({ rowData }) => <>{rowData?.department?.name || "-"}</>,
      sortable: false,
    },
    // {
    //   id: "scghs",
    //   name: t("system_users.scghs"),
    //   key: "scghs",
    //   cell: ({ rowData }) => <>{rowData?.scghs || "-"}</>,
    //   sortable: false,
    // },
    {
      id: "email_confirmation",
      name: t("system_users.email_confirmation"),
      key: "email_confirmation",
      cell: ({ rowData }) => (
        <>{rowData?.email_verified_at ? t("yes") : t("no")}</>
      ),
      sortable: false,
    },
    {
      id: "register_date",
      name: t("system_users.register_date"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: true,
    },
    {
      id: "certifications",
      name: t("private_profile.certificates"),
      key: "certifications",
      cell: ({ rowData }) => (
        <>
          <span
            style={{ color: "#025299", cursor: "pointer" }}
            onClick={() => openCertifacateModel(rowData.certifications)}
          >
            {t("general.attachment")}
          </span>
        </>
      ),
    },
    {
      id: "actions",
      name: t("system_users.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn actions={systemUsersActionsList} id={rowData.id} />
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("system_users.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status == 1}
          id={rowData.id}
          onStatusChange={() => {
            updateSystemUserStatusHandler(rowData.id);
          }}
        />
      ),
      sortable: false,
    },
  ];
  console.log("systemUsersList", systemUsersList);
  return (
    <>
      <MainTable
        columns={columns}
        data={systemUsersList || []}
        isLoading={systemUsersListLoading}
        sortAction={sortAction}
      />
      {showAcceptModel && (
        <ModelWithInput
          openModal={showAcceptModel}
          setOpenModal={setShowAcceptModel}
          modalTitle={t("trainerAccept")}
          buttonText={t("accept")}
          type={"aproveAccept"}
          handleSubmit={ConfirmAcceptHandler}
          inputLabel={t("financial.payments_request.rejected_reason")}
        />
      )}
      {showRejectModel && (
        <ModelWithInput
          openModal={showRejectModel}
          setOpenModal={setShowRejectModel}
          modalTitle={t("trainer_reject")}
          buttonText={t("accept")}
          type={"textarea"}
          handleSubmit={ConfirmRejectHandler}
          inputLabel={t("financial.payments_request.rejected_reason")}
        />
      )}
      {showCertificateModel && (
        <ModelWithInput
          openModal={showCertificateModel}
          setOpenModal={setShowCertificateModel}
          modalTitle={t("private_profile.certificates")}
          certifacates={certifacates}
          // buttonText={t("accept")}
          type={"certificate"}
          // handleSubmit={ConfirmRejectHandler}
          // inputLabel={t("financial.payments_request.rejected_reason")}
        />
      )}
    </>
  );
};

export default SystemUsersListTable;
