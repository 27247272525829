import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { applyCoupon } from "../../../../Services/api/courses/courseProvider";
import CourseDetailsHeader from "../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../Shared/Components/Input/Input";
import classes from "./trainingCoursePayment.module.css";
import Select from "react-select";

export default function CoursePaymentDetails({
  handleSubmit,
  courseDetails,
  couponCode,
  setCouponCode,
  validCoupon,
  setValidCoupon,
  values,
  errors,
  setFieldValue,
  trainees,
  isGift,
}) {
  const { t, i18n } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);
  const [opened, setOpened] = useState(true);
  const [coursePrice, setCoursePrice] = useState(
    Number(courseDetails.is_program)
      ? courseDetails.payments.filter(
          (payment) => payment.is_payment_due === true && !payment.is_paid
        )[0]?.amount || 0
      : courseDetails.price
  );

  const applyCouponCode = () => {
    applyCoupon(courseDetails.id, couponCode)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setValidCoupon({
            code: couponCode,
            value: res.data.coupon.value,
            mechanism: res.data.coupon.mechanism,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.data.msg}
          </span>
        );
      });
  };

  useEffect(() => {
    if (coursePrice) {
      let price = Number(coursePrice);
      if (
        courseDetails.offers.length > 0 &&
        courseDetails.offers[0].user_can_use_now
      ) {
        if (courseDetails.offers[0].mechanism === "1") {
          price -= Number(courseDetails.offers[0].value);
        } else {
          price -= (Number(courseDetails.offers[0].value) / 100) * price;
        }
      }
      if (Object.keys(validCoupon).length > 0) {
        if (validCoupon.mechanism === "1") {
          price -= Number(validCoupon.value);
        } else {
          price -= (Number(validCoupon.value) / 100) * price;
        }
      }
      if (courseDetails.taxes.length > 0) {
        courseDetails.taxes.forEach((tax) => {
          if (tax.user_can_use_now) {
            if (tax.mechanism === "1") {
              price += Number(tax.value);
            } else {
              price += (Number(tax.value) / 100) * price;
            }
          }
        });
      }
      setTotalPrice(price);
    }
  }, [courseDetails, validCoupon]);

  const calculateValue = (value, mechanism) => {
    if (mechanism === "1") {
      return value;
    } else {
      return (value / 100) * coursePrice;
    }
  };

  let pricesList = [
    {
      id: "main_price",
      className: classes["payment-details-price-item"],
      title: t("payments.main_price"),
      value: coursePrice,
    },
    ...(courseDetails.offers.length > 0 &&
    courseDetails.offers[0].user_can_use_now
      ? [
          {
            id: "offer",
            className: classes["payment-details-price-item-minus"],
            title: courseDetails.offers[0].name,
            percentage:
              courseDetails.offers[0].mechanism === "2"
                ? `(${courseDetails.offers[0].value}%)`
                : null,
            value: calculateValue(
              courseDetails.offers[0].value,
              courseDetails.offers[0].mechanism
            ),
          },
        ]
      : []),
    ...(courseDetails.taxes.length > 0
      ? courseDetails.taxes.map((tax) => {
          if (tax.user_can_use_now) {
            return {
              id: tax.id,
              className: classes["payment-details-price-item-plus"],
              title: tax.name,
              percentage: tax.mechanism === "2" ? `(${tax.value}%)` : null,
              value: calculateValue(tax.value, tax.mechanism),
            };
          }
        })
      : []),
    ...(Object.keys(validCoupon).length > 0
      ? [
          {
            id: validCoupon.id,
            className: classes["payment-details-price-item-minus"],
            title: t("payments.coupon_code") + " " + validCoupon.code,
            percentage:
              validCoupon.mechanism === "2" ? `(${validCoupon.value}%)` : null,
            value: calculateValue(validCoupon.value, validCoupon.mechanism),
          },
        ]
      : []),
  ];

  pricesList = pricesList.filter((item) => item);

  return (
    <div className={classes["payment-details"]}>
      {isGift && (
        <>
          <div className={classes["payment-field"]}>
            <div className={classes["payment-details-title"]}>
              <label htmlFor="trainee_id">{t("trainee_list_label")}</label>
            </div>
            <div className={classes["traniee_msg"]}>
              <span>{t("trainee_list_label_msg")}</span>
            </div>
            <Select
              name="trainee_id"
              id="trainee_id"
              options={trainees?.map((trainee) => {
                return {
                  id: trainee.id,
                  value: trainee.id,
                  label: `${
                    i18n.language === "ar" ? trainee.name_ar : trainee.name_en
                  }  -  ${trainee.email}`,
                };
              })}
              placeholder={t("gifted_to")}
              onChange={(item) => {
                setFieldValue("trainee_id", item.value);
              }}
            />
            <p
              className={`form-input-error-space ${classes["select-field-error"]}`}
            >
              {errors?.trainee_id ? errors.trainee_id : null}
            </p>
          </div>{" "}
        </>
      )}
      {Number(courseDetails.is_program) ? (
        <div
          className={
            classes[`payment-info-container${opened ? "-opened" : ""}`]
          }
        >
          <CourseDetailsHeader
            title={
              t("payments.program_total_price") +
              " " +
              courseDetails.price +
              t("rs")
            }
            opened={opened}
            setOpened={setOpened}
          />
          {opened &&
            courseDetails.payments.map((payment, index) => (
              <div className={classes["payment-info-item-box"]}>
                <div className={classes["payment-info-item"]}>
                  <div className={classes["payment-info-item-title"]}>
                    {t("trainer.program.payment") + " " + (index + 1)}
                  </div>
                  <div className={classes["payment-info-item-value"]}>
                    {payment.amount}
                    {t("rs")}
                  </div>
                </div>
                <div className={classes["payment-info-item"]}>
                  <div className={classes["payment-info-item-title"]}></div>
                  <div className={classes["payment-info-item-value"]}>
                    {`${moment(payment.payment_date).format(
                      "DD/MM/YYYY"
                    )} - ${moment(payment.end_date).format("DD/MM/YYYY")}`}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : null}
      <div className={classes["payment-details-container"]}>
        <div className={classes["payment-details-title"]}>
          {!Number(courseDetails.is_program)
            ? t("payments.summaryOne")
            : `${t("payments.summaryTwo")}`}
        </div>
        <div className={classes["payment-details-price-container"]}>
          {pricesList.map((price) => (
            <div className={price.className}>
              <div className={classes["payment-details-price"]}>
                <div className={classes["payment-details-price-title"]}>
                  {price.title}
                </div>
                {price.percentage && (
                  <div className={classes["payment-details-price-percentage"]}>
                    {price.percentage}
                  </div>
                )}
              </div>
              <div className={classes["payment-details-price-value"]}>
                {price.value} {t("rs")}
              </div>
            </div>
          ))}
          <div className={classes["payment-details-total-price-item"]}>
            <div className={classes["payment-details-total-price-title"]}>
              {t("payments.total_price")}
            </div>
            <div className={classes["payment-details-total-price-value"]}>
              {totalPrice} {t("rs")}
            </div>
          </div>
        </div>
        {/* <div className={classes["payment-details-coupon-container"]}>
          <Input
            label={t("payments.coupon_code")}
            className={classes["payment-details-coupon-input"]}
            placeholder={t("payments.coupon_code")}
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <CustomButton
            action={applyCouponCode}
            type="button"
            disable={couponCode === "" || Object.keys(validCoupon).length > 0}
            colors="#036c77"
            value={t("payments.apply")}
            classes={`${classes["payment-details-coupon-button"]} ${
              couponCode === "" || Object.keys(validCoupon).length > 0
                ? classes["payment-details-coupon-button-disabled"]
                : ""
            }`}
          />
        </div> */}
        <CustomButton
          action={handleSubmit}
          disable={false}
          colors="#036c77"
          value={t("payments.pay")}
          classes={classes["payment-details-pay-button"]}
        />
      </div>
    </div>
  );
}
