import { useTranslation } from "react-i18next";
import { Lang } from "../../../../utils";
import DialogModal from "../../../Shared/Components/DialogModal/DialogModal";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";

const TicketsTransfersModal = ({ transferData, setShowTicketTransfersModal }) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "ticket_type",
      name: t("support.ticket_view.ticket_type"),
      key: "ticket_type",
      cell: ({ rowData }) => <div className="ticket-transfers__field">{rowData?.ticket_type?.name || "-"}</div>,
    },
    {
      id: "ticket_subject",
      name: t("support.ticket_view.ticket_subject"),
      key: "ticket_subject",
      cell: ({ rowData }) => (
        <div className="ticket-transfers__field">
          {i18n.language == Lang.AR ? rowData?.ticket_subject?.name_ar || "-" : rowData?.ticket_subject?.name_en || "-"}
        </div>
      ),
    },
    {
      id: "supporter_name",
      name: t("support.ticket_view.supporter_name"),
      key: "supporter_name",
      cell: ({ rowData }) => (
        <ul className="ticket-transfers__user">
          {!!rowData?.ticket_type?.users?.length &&
            rowData?.ticket_type?.users?.map((user) => (
              <li className="ticket-transfers__user-name" key={user?.id}>
                {user?.name || "-"}
              </li>
            ))}
        </ul>
      ),
    },
    {
      id: "comment",
      name: t("general.comment"),
      key: "comment",
      cell: ({ rowData }) => <div className="ticket-transfers__field">{rowData?.private_comment || "-"}</div>,
    },
  ];

  console.log("transferData", transferData);

  return (
    <DialogModal
      onCancel={() => {
        setShowTicketTransfersModal(false);
      }}
      className="tw-bg-white sm:tw-rounded-lg tw-shadow-xl tw-p-4 tw-space-y-4 ticket-transfers-modal"
    >
      <MainBoxHead title={t("support.ticket_view.ticket_transfers")} />
      <MainTable columns={columns} data={transferData || []} isLoading={false} />
    </DialogModal>
  );
};

export default TicketsTransfersModal;
