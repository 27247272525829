import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Rating } from "@mui/material";
import IconsTooltip from "../../../../Components/tooltips/IconsTooltip";

function TrainerModal(props) {
  require("./trainer.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [rateings, setRateings] = useState([]);

  useEffect(() => {
    let courses = props.courses;
    if (courses && courses.instructed_courses) {
      courses.instructed_courses.map((item) => {
        let rateSum = 0;
        let rateCount = 0;
        courses.rateings.map((rate, index) => {
          if (item.id == rate.course_id) {
            rateSum += rate.stars;
            rateCount++;
          }
          if (courses.rateings.length == index + 1) {
            item.star = rateSum / rateCount;
          }
          item.trainerId = courses.id;
          item.ratings = courses.rateings;
        });
      });
      setCourses(courses.instructed_courses);
      setRateings(courses.rateings);
    }
  }, [props.courses]);

  return (
    <>
      <div
        className={"tajah-basic-modal-md"}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={"tajah-basic-modal-header"}>
          <h4>{props.title}</h4>
          <div>
            <i
              className="fas fa-times tajah-modal-dismiss"
              onClick={() => {
                props.trainerRateModalRef.current.dismissModal();
              }}
            ></i>
          </div>
        </div>
        <div className={"tajah-basic-modal-body"}>
          <div
            className="table-responsive"
            style={{
              width: "90%",
              margin: "auto",
            }}
          >
            {courses.length == 0 ? (
              <p style={{ textAlign: "center" }}>{t("rate.label.notFound")}</p>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th scope="col">#</th>
                    <th scope="col">{t("rate.label.course")}</th>
                    <th scope="col">{t("rate.label.rate")}</th>
                    <th scope="col">{t("rate.label.description")}</th>
                  </tr>
                </thead>
                <tbody>
                  {courses.map((course, index) => {
                    return (
                      <tr style={{ textAlign: "center" }} key={course.id}>
                        <td scope="row">{index + 1}</td>
                        <td>{course.name}</td>
                        <td>
                          <Rating
                            value={course.star == NaN ? 0 : course.star}
                            readOnly
                          />
                        </td>
                        <td>
                          {rateings.length == 0 ? (
                            <span style={{ fontSize: 14 }}>
                              {t("notFoundRates")}
                            </span>
                          ) : (
                            <IconsTooltip
                              title={t("modal.survey.buttons.data")}
                              content={
                                <i
                                  className="fal fa-eye"
                                  onClick={() => {
                                    history.push({
                                      pathname: `/${
                                        props.role ? props.role : "admin"
                                      }/trainer-rate/list/${
                                        course.trainerId
                                      }/course/${course.id}`,
                                      state: {
                                        ratings:
                                          course.ratings.length == 0
                                            ? []
                                            : course.ratings,
                                      },
                                    });
                                    window.location.reload(false);
                                  }}
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                    backgroundColor: "white",
                                    borderWidth: 0,
                                    fontSize: 20,
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              }
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TrainerModal;
