import { useTranslation } from "react-i18next";
import ViewReports from "../../../../../assets/icons/analyze.svg";
import chatIcon from "../../../../../assets/icons/chat1.png";
import ReviewIcon from "../../../../../assets/icons/customer-review.png";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import DownloadIcon from "../../../../../assets/icons/download2.svg";
import EditIcon from "../../../../../assets/icons/edit.svg";
import examIcon from "../../../../../assets/icons/exam.png";
import PreviewIcon from "../../../../../assets/icons/eye.svg";
import GoalsIcon from "../../../../../assets/icons/flag.png";
import editIcon from "../../../../../assets/icons/pen.svg";
import AddIcon from "../../../../../assets/icons/plus-green.svg";
import AssignmentIcon from "../../../../../assets/icons/reports.svg";
import CheckIcon from "../../../../../assets/icons/save.png";
import SettingsIcon from "../../../../../assets/icons/settings.svg";
import SurveyIcon from "../../../../../assets/icons/survey.svg";
import UsersIcon from "../../../../../assets/icons/user-group.svg";

export const LessonRowActions = ({
  settingsAction,
  addAction,
  surveyAction,
  previewAction,
  downloadAction,
  editAction,
  deleteAction,
  usersAction,
  saveAction,
  assignmentsAction,
  goalsAction,
}) => {
  const { t, i18n } = useTranslation();
  const actionsList = [
    ...(settingsAction
      ? [
          {
            id: "sittings",
            icon: <img src={SettingsIcon} alt="" />,
            action: settingsAction,
            toolTip: t("restrictions"),
          },
        ]
      : []),
    ...(usersAction
      ? [
          {
            id: "users",
            icon: <img src={ViewReports} alt="" />,
            action: usersAction,
            toolTip: t("detailed_report"),
          },
        ]
      : []),
    {
      id: "goals",
      icon: <img src={GoalsIcon} alt="" />,
      action: goalsAction,
      toolTip: t("goals"),
    },
    {
      id: "assignments",
      icon: <img src={examIcon} alt="" />,
      action: assignmentsAction,
      toolTip: t("exams"),
    },
    ...(addAction
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            action: addAction,
            toolTip: t("survey.add"),
          },
        ]
      : []),
    ...(surveyAction
      ? [
          {
            id: "survey",
            icon: <img src={ReviewIcon} alt="" />,
            action: surveyAction,
            toolTip: t("survey.surveyTitle"),
          },
        ]
      : []),
    ...(downloadAction
      ? [
          {
            id: "download",
            icon: <img src={DownloadIcon} alt="" />,
            action: downloadAction,
            toolTip: t("download"),
          },
        ]
      : []),
    {
      id: "preview",
      icon: <img src={PreviewIcon} alt="" />,
      action: previewAction,
      toolTip: t("preview_content"),
    },

    ...(editAction
      ? [
          {
            id: "edit",
            icon: <img src={editIcon} alt="" />,
            action: editAction,
            toolTip: t("general.edit"),
          },
        ]
      : []),
    ...(saveAction
      ? [
          {
            id: "save",
            icon: <img src={CheckIcon} alt="" />,
            action: saveAction,
            toolTip: t("Exhibition.save"),
          },
        ]
      : []),
    ...(deleteAction
      ? [
          {
            id: "delete",
            icon: <img src={DeleteIcon} alt="" />,
            action: deleteAction,
            toolTip: t("general.delete"),
          },
        ]
      : []),
  ];
  return actionsList;
};
