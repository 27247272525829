import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { platformsListConstants } from "../../Constants/PlatformManagement/PlatformManagement.constant";
import i18n from "../../../i18n/i18n";

// GET platforms LIST
export const fetchPlatformsList = ({ page = 1, perPage = 10, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: platformsListConstants.GET_PLATFORMS_LIST_REQUEST,
    });

    await connector
      .get(env.groups, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        // console.log(response);
        if (response?.status === 200) {
          dispatch({
            type: platformsListConstants.GET_PLATFORMS_LIST_SUCCESS,
            payload: {
              groups: response?.data,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: platformsListConstants.GET_PLATFORMS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: platformsListConstants.GET_PLATFORMS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

export const deletePlatform = (id, group_id) => {
  return async (dispatch) => {
    dispatch({
      type: platformsListConstants.DELETE_PLATFORM_REQUEST,
    });
    await connector
      .delete(URLBuilder(env.groups_update, { id: id }), {
        params: {
          ...(group_id ? { group_id: group_id } : {}),
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response.status && response.status === 200) {
          dispatch({
            type: platformsListConstants.DELETE_PLATFORM_SUCCESS,
            payload: { id },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {i18n.language === "ar"
                ? "تم حذف الاعلان بنجاح"
                : "The Advertise Deleted successfully"}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: platformsListConstants.DELETE_PLATFORM_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: platformsListConstants.DELETE_PLATFORM_REJECTED,
          payload: err.response.data.msg,
        });
        // console.log(err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };
};
