import React, { useEffect, useState } from "react";
import PublishRequestsForm from "./PublishRequestsForm/PublishRequestsForm";
import { getPublishRequestDetails } from "../../../Services/api/PublishReq/PublishReqProvider";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

export default function PublishRequestsPreview() {
  const [reqDetails, setReqDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getPublishRequestDetails(id)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setReqDetails(res.data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <PublishRequestsForm
      loading={loading}
      ReqDetails={reqDetails}
      type={"Preview"}
      role="guest"
    />
  );
}
