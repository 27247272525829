import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import { systemUsersListConstant } from "../../Constants/systemUsers/systemUsersList";
import i18n from "../../../i18n/i18n";

// GET SYSTEM USERS LIST
export const fetchSystemUsersList = ({
  page = 1,
  perPage = 10,
  filter,
  isSponser,
}) => {
  return async (dispatch) => {
    dispatch({
      type: systemUsersListConstant.GET_SYSTEM_USERS_LIST_REQUEST,
    });

    const url = isSponser ? env.get_sponsors_list : env.get_roles;
    await connector
      .get(url, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          console.log("response", response);
          dispatch({
            type: systemUsersListConstant.GET_SYSTEM_USERS_LIST_SUCCESS,
            payload: {
              users: response.data.response.Users,
              roles: response.data.response.AllRoles,
              genders: response.data.response.genders,
              // medicalFields: response.data.response.medical_fields,
              specialties: response.data.response.specialties,
              countries: response.data.response.countries,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: systemUsersListConstant.GET_SYSTEM_USERS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: systemUsersListConstant.GET_SYSTEM_USERS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

export const updateSystemUserStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REQUEST,
    });

    await connector
      .post(env.active_users, { user_id: id })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};
export const acceptSystemUserStatus = ({ id, status }) => {
  return async (dispatch) => {
    dispatch({
      type: systemUsersListConstant.ACCEPT_SYSTEM_USER_STATUS_REQUEST,
    });

    await connector
      .post(URLBuilder(env.trainer_accept, { id: id, status: status }), {
        _method: "put",
      })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: systemUsersListConstant.ACCEPT_SYSTEM_USER_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {i18n.language == "ar"
                ? "تم القبول بنجاح"
                : "Accepted successfully"}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: systemUsersListConstant.ACCEPT_SYSTEM_USER_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};
export const rejectSystemUserStatus = ({ id, status, reject_reason }) => {
  return async (dispatch) => {
    dispatch({
      type: systemUsersListConstant.REJECT_SYSTEM_USER_STATUS_REQUEST,
    });

    await connector
      .post(URLBuilder(env.trainer_accept, { id: id, status: status }), {
        reject_reason: reject_reason,
        _method: "put",
      })
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          dispatch({
            type: systemUsersListConstant.REJECT_SYSTEM_USER_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {i18n.language == "ar"
                ? "تم الرفض بنجاح"
                : "Rejected successfully"}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: systemUsersListConstant.REJECT_SYSTEM_USER_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};
