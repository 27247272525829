import React from "react";
import Img from "../../../../assets/image/BgCat.svg";
import classes from "./CategoryCard.module.css";
import Cat from "../../../../assets/icons/categoryiCON.svg";
import { useTranslation } from "react-i18next";

export default function CategoryCard({ title }) {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={classes.CategoryCard}
      style={{ backgroundImage: `url(${Img})` }}
    >
      <div className={classes.title_arrow}>
        {/* <img src={Arrow} alt="" className={classes.ArrowCard} /> */}
        <p> {title}</p>
      </div>
      <div className={classes.icon_cont}>
        <img src={Cat} alt="" />
      </div>
    </div>
  );
}
