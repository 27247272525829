import React, {useState} from "react";

import {useTranslation} from "react-i18next";

import i18n from "../../i18n/i18n";
import {Lang} from "../../utils";

import {SideBySideMatrix} from '../../Modules/Admin/survey/types/SideBySideMatrix';
import { Selection, SingleChoice } from "../../Modules/Admin/survey/types/Selection";
import { SliderQuestion } from "../../Modules/Admin/survey/types/SliderQuestion";
import { BasicMatrix } from "../../Modules/Admin/survey/types/BasicMatrix";

function TraineesModal(props) {
  require("./QuestionModal.css");

  const {t} = useTranslation();
    
  const [types, setTypes] = useState([
    {
        name: 'radio',
        label_ar: 'اختيار فقط',
        label_en: 'Select One',
        for: 'section1',
        id: 1,
        value: 'select_one',
        content: <p>radio</p>
    },
    {
        name: 'checkbox',
        label_ar: 'اخنيار من متعدد',
        label_en: 'Select Many',
        for: 'section1',
        id: 2,
        value: 'select_many',
        content: <p>chckbox</p>
    },
    {
        name: 'dropdown',
        label_ar: 'القائمة المنسدلة',
        label_en: 'Dropdown',
        for: 'section1',
        value: 'single_choice',
        id: 3,
        content: <p>dropdown</p>
    },
    {
        name: 'textslider',
        label_ar: 'شريط تمرير النص',
        label_en: 'Text Slider',
        for: 'section1',
        value: 'text_slider',
        id: 4,
        content: <p>text slider</p>
    },
    {
        name: 'ordering',
        label_ar: 'الترتيب',
        label_en: 'Ordering',
        for: 'section1',
        value: 'ordering',
        id: 5,
        content: <p>ordering</p>
    },
    {
        name: 'rankorder',
        label_ar: 'ترتيب الرقمي',
        label_en: 'Rank Order',
        for: 'section1',
        value: 'rank_order',
        id: 6,
        content: <p>rank order</p>
    },
    {
        name: 'constantsum',
        label_ar: 'تقسيم بشريط تمرير ',
        label_en: 'Constant Sum',
        for: 'section1',
        value: 'constant_sum',
        id: 7,
        content: <p>constant sum</p>
    },
    {
        name: 'singleText',
        label_ar: 'نص صغير',
        label_en: 'Small Text',
        id: 8,
        for: 'section2',
        value: 'string',
        content: <p>text</p>
    },
    {
        name: 'text', 
        label_ar: 'نصي', 
        label_en: 'Text', 
        id: 9, for: 'section2', 
        value: 'text', 
        content: <p>text</p>
    },
    {
        name: 'email',
        label_ar: 'ايميل',
        label_en: 'Email',
        id: 10,
        for: 'section2',
        value: 'email',
        content: <p>email</p>
    },
    {
        name: 'rate',
        label_ar: 'تقيم',
        label_en: 'Star Rating',
        id: 11,
        for: 'section2',
        value: 'star_rating',
        content: <p>rate</p>
    },
    {
        name: 'date',
        label_ar: 'تاريخ',
        label_en: 'Date',
        id: 12,
        for: 'section2',
        value: 'date',
        content: <p>date</p>
    },
    {
        name: 'calender',
        label_ar: 'تقويم',
        label_en: 'Calender',
        id: 13,
        for: 'section2',
        value: 'calender',
        content: <p>calender</p>
    },
    {
        name: 'numericslider',
        label_ar: 'شريط تمرير رقمي',
        label_en: 'Numeric Slider',
        id: 14,
        for: 'section2',
        value: 'numeric_slider',
        content: <p>numeric slider</p>
    },
    {
        name: 'file',
        label_ar: 'رفع ملف',
        label_en: 'Upload File',
        for: 'section2',
        id: 15,
        value: 'single_file',
        content: <p>file</p>
    },
    {
        name: 'shape',
        label_ar: 'رموز تعبيرية',
        label_en: 'Smiley Rating',
        id: 17,
        for: 'section3',
        value: 'smiley_rating',
        content: <p>shape</p>
    },
    {
        name: 'graphic',
        label_ar: 'رموز رسومية',
        label_en: 'Graphical Rating',
        id: 18,
        for: 'section3',
        value: 'graphical_rating',
        content: <p>graphical</p>
    },
    {
        name: 'draganddrop',
        label_ar: 'السحب و الافلات',
        label_en: 'Drag and Drop',
        for: 'section1',
        value: 'drag_and_drop',
        id: 19,
        content: <p>dropdown</p>
    },
    {
        name: 'contactform',
        label_ar: 'نموذج الاتصال',
        label_en: 'Contact Form',
        for: 'section1',
        value: 'contact_information',
        id: 20,
        content: <p>dropdown</p>
    },
    {
        name: 'sidebysidematrixsurvey',
        label_ar: 'مصفوفة جنبًا إلى جنب',
        label_en: 'Side By Side Matrix',
        id: 22,
        for: 'section4',
        value: 'side_by_side_matrix',
        content: <p>Side By Side Matrix survey</p>
    },
    {
        name: 'basicmatrix',
        label_ar: 'المصفوفة الأساسية',
        label_en: 'basic matrix',
        id: 23,
        for: 'section4',
        value: 'basic_matrix',
        content: <p>basic matrix</p>
    },
    {
        name: 'section',
        label_ar: 'مجموعة اسئلة',
        label_en: 'Questions Group',
        id: 21,
        for: 'section4',
        value: 'section',
        content: <p>shape</p>
    },
  ])

  const [name, setName] = useState(props.question?.name);
  const [type, setType] = useState(props.question?.type);
  const [data, setData] = useState({});
  const [isRequired, setIsRequired] = useState(props.question?.is_required || 0);

  const handleTypeChange = (e) => {
    // console.log(e.target.value);
    setType(e.target.value)
  }

  const saveSideBySideQuestion = (data) => {
    setData(data);
    // console.log(data);
  }

  const saveQestionData = (data) => {
    setData(data);
  }

  const save = () => {
    var question = props.question? {...props.question, ...data, type, name, is_required: parseInt(isRequired)} : {...data, type, name, is_required: parseInt(isRequired)};
    props.saveQuestion(question, props.questionIndex);
    props.dismissModal();
  }

  return (
      <>
        <div
            className={"tajah-basic-modal-md"}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <div className={"tajah-basic-modal-header"}>
            {props.type == "trainees"?(
              <h4>{t('survey.addOrUpdateQuestion')}</h4>
            ):(
              <h6 style={{color:'red'}}>{t('survey.addOrUpdateQuestion')}</h6>
            )}
                <div>
                    <i
                        className="fas fa-times tajah-modal-dismiss"
                        onClick={() => {
                            props.dismissModal();
                        }}
                    ></i>
                </div>
            </div>
            <div className={"tajah-basic-modal-body"}>

              <div className="row" style={{marginTop: '3%', position: 'relative', zIndex: 0}}>
                  {/* Title */}
                  <div className="col-md-6">
                      <label htmlFor="name" className="admin_add_label">
                          {t("survey.questionTitle")}
                      </label>
                      <input
                          className="admin_add_input"
                          style={{borderBottom: '1px solid #e7e7e7'}}
                          name="title"
                          id="title"
                          defaultValue={name}
                          onChange={(e) => setName(e.target.value)}/>
                  </div>
                  {/* Question Type */}
                  <div className="col-md-6">
                      <label
                          htmlFor="questions"
                          className="admin_add_label"
                      >
                          {t("survey.choose_question")} :
                      </label>
                      <select
                          id="questions"
                          name="questions"
                          style={{borderBottom: '1px solid #e7e7e7'}}
                          // value={values.id}
                          onChange={(e) => handleTypeChange(e)}
                          // onBlur={handleBlur}
                          className="admin_add_input"
                          defaultValue={type}
                      >
                          <option value="0"></option>
                          {types.map((currentype) => {
                              return (
                                  <option key={currentype.id} value={currentype.value}>
                                      {i18n.language == Lang.AR ? currentype.label_ar : currentype.label_en}
                                  </option>
                              );
                          })}
                      </select>
                      {/* <p className={"form-input-error-space"}>
                          {errors.questions ? errors.questions : null}
                      </p> */}
                  </div>
                  {/* Question Requirement */}
                  <div className="col-md-6">
                      <label
                          htmlFor="is_required"
                          className="admin_add_label"
                      >
                          {t("survey.is_required")} :
                      </label>
                      <select
                          id="is_required"
                          name="is_required"
                          style={{borderBottom: '1px solid #e7e7e7'}}
                          // value={values.id}
                          onChange={(e) => setIsRequired(e.target.value)}
                          // onBlur={handleBlur}
                          className="admin_add_input"
                          defaultValue={isRequired}
                      >
                          <option value={0}>{t("No")}</option>
                          <option value={1}>{t("Yes")}</option>
                      </select>
                      {/* <p className={"form-input-error-space"}>
                          {errors.questions ? errors.questions : null}
                      </p> */}
                  </div>
              </div>

              <div className="row" style={{marginTop: '3%', position: 'relative', zIndex: 0}}>
                {/* Side By Side Matrix */}
                {
                  type == 'side_by_side_matrix'?
                    <SideBySideMatrix mode="edit" handleSaveAction={saveSideBySideQuestion} question={props.question}></SideBySideMatrix>  : null
                }
                {
                  type == 'basic_matrix'?
                    <BasicMatrix mode="edit" handleSaveAction={saveSideBySideQuestion} question={props.question}/>  : null
                }
                {/* Multiple Choices */}
                {['select_one', 'select_many', 'single_choice', 'ordering', 'rank_order', 'smiley_rating', 'contact_information', 'graphical_rating', 'drag_and_drop'].includes(type) ?
                    <Selection 
                        mode="edit" 
                        saveQuestionData={saveQestionData} 
                        question={props.question}  
                        selectionType={type} 
                        deleteOptionFromServer={props.deleteOptionFromServer}
                    /> : null
                }
                {/* Sliders */}
                {['text_slider', 'constant_sum', 'numeric_slider'].includes(type) ?
                    <Selection mode="edit" saveQuestionData={saveQestionData} question={props.question}  selectionType={type}/> : null
                }
              </div>
            </div>

            {/* Buttons */}
            <div className="row" style={{marginTop: '3%'}}>
                <div className="col-md-6">
                    <button
                        type="button"
                        // disabled={isSubmitting}
                        className="admin_add_button"
                        onClick={save}
                    >
                        {t('survey.buttons.save')}
                    </button>
                </div>
                <div className="col-md-6">
                    <button
                        type="button"
                        // disabled={isSubmitting}
                        className="admin_add_button"
                        onClick={() => props.dismissModal()}
                    >
                        {t('survey.buttons.cancel')}
                    </button>
                </div>
            </div>

        </div>
      </>
  );
}

export default TraineesModal;
