import React, { useEffect, useRef } from "react";
import { answerLessonActivity } from "../../../../../Services/api/courses/courseProvider";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/youtube.svg";
import { toast } from "react-toastify";

export default function HTMLQuestionView({ question, setFieldValue }) {
  const iframeRef = useRef(null);

  function saveRes(ev) {
    if (ev.origin !== process.env.REACT_APP_SERVER_PATH.replace(/\/$/, "")) {
      return;
    }
    console.log(ev.data);
    if (ev.data === "true" || ev.data === "false") {
      let res = ev.data === "true" ? 1 : 0;
      console.log(res, "HTML ANSWER");
      setFieldValue("answer", res)
    }
  }
  useEffect(() => {
    if (question.download) {
      window.addEventListener("message", saveRes);
      return () => window.removeEventListener("message", saveRes);
    }
  }, []);

  return (
    <>
      {question.download ? (
        <iframe
          ref={iframeRef}
          title={"HTML"}
          id={"iframeScormQuestion" + question.id}
          src={question.download}
          key={question.id}
          width={"100%"}
          height={"644px"}
        ></iframe>
      ) : (
        <button
          type="button"
          disabled={!question.download}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon
            className={`tw-w-16 tw-h-16 ${
              question.download ? "tw-stroke-teal-600" : "tw-stroke-gray-400"
            }`}
          />
        </button>
      )}
    </>
  );
}
