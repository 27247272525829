import { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Modules/Shared/Components/Input/Input";
import openEye from "../../assets/icons/openEye.svg";
import closeEye from "../../assets/icons/closeEye.svg";
import "./input.css";

function PasswordInput(props) {
  const { t } = useTranslation();
  const [type, setType] = useState("password");
  const [content, setContent] = useState("");

  const passwordHandleChange = (event) => {
    setContent(event.target.value);
    props.setContent(event.target.value);
  };

  const showHide = (e) => {
    e.preventDefault();
    setType(type === "input" ? "password" : "input");
  };

  return (
    <div className="validator_input_component_container">
      <div>
        <Input
          type={type}
          value={content}
          onChange={passwordHandleChange}
          className={`component_input ${props.classes}`}
          required={
            typeof props.setRequired === "undefined" ? false : props.setRequired
          }
          placeholder={
            props.confirmation ? t("confirmPassword") : t("password")
          }
          label={props.confirmation ? t("confirmPassword") : t("password")}
        />
      </div>
      <div className="validator_input_icon_container" onClick={showHide}>
        {type == "password" ? (
          <img src={openEye} alt="" />
        ) : (
          <img src={closeEye} alt="" />
        )}
      </div>
    </div>
  );
}

export default PasswordInput;
