import { eventsListConstants } from "../../Constants/events/events.constants";

const initialState = {
  eventsList: [],
  eventsListMeta: {},
  eventsListLoading: false,
  eventsListError: null,
  eventsListSuccess: false,

  updateEventStatusLoading: false,
  updateEventStatusError: null,
  updateEventStatusSuccess: false,
};

const eventsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case eventsListConstants.GET_EVENTS_LIST_REQUEST:
      return {
        ...state,
        eventsListLoading: true,
      };
    case eventsListConstants.GET_EVENTS_LIST_SUCCESS:
      return {
        ...state,
        eventsListLoading: false,
        eventsListSuccess: true,
        eventsList: action.payload.events.data,
        eventsListMeta: action.payload.events.meta,
      };
    case eventsListConstants.GET_EVENTS_LIST_REJECTED:
      return {
        ...state,
        eventsListLoading: false,
        eventsListError: action.payload,
        eventsListSuccess: false,
      };

    //  CANCEL EVENT
    case eventsListConstants.CANCEL_EVENT_REQUEST:
      return {
        ...state,
        cancelEventLoading: true,
      };

    case eventsListConstants.CANCEL_EVENT_SUCCESS:
      return {
        ...state,
        cancelEventLoading: false,
        cancelEventSuccess: true,
        cancelEventError: null,

        eventsList: state.eventsList.map((event) =>
          event.id === action.payload.id
            ? { ...event, canceled: true }
            : { ...event }
        ),
      };

    case eventsListConstants.CANCEL_EVENT_REJECTED:
      return {
        ...state,
        cancelEventLoading: false,
        cancelEventError: action.payload,
        cancelEventSuccess: false,
      };

    default:
      return state;
  }
};

export default eventsListReducer;
