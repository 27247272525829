import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";
import { getNeededDataCreateAdvertisement } from "../../../Services/api/Advertisement/AdProvider";

const PlatformManagementFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
}) => {
  const { t, i18n } = useTranslation();

  const statusOptions = [
    {
      label: t("general.active"),
      id: 1,
      value: "active",
    },
    {
      label: t("general.inactive"),
      id: 2,
      value: "inactive",
    },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("title"),
      type: "text",
      placeholder: t("title"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "status",
      label: t("general.status"),
      component: "select",
      placeholder: t("general.status"),
      initialValue: t("general.status"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: statusOptions || [],
    },

    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];
  return <FilterBox fields={filterFields} />;
};

export default PlatformManagementFilter;
