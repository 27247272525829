import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { getCountries } from "../../../../../Services/api/profile/profileProvider";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { toServerTime } from "../../../../../Services/Timezone";
import { getAllCourses } from "../../../../../Services/api/CoursesList";
import { addTax } from "../../../../../Services/api/Financial/FinancialProvider";
import HomeIcon from "../../../../../assets/icons/home.svg";
import SelectWithSearch from "../../../../Shared/Components/SelectWithSearch/SelectWithSearch.jsx";
import { formatDate, formatTime } from "../../../../../utils/formatDate";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../../Shared/Components/DataTable/DataTable";
import Input from "../../../../Shared/Components/Input/Input";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../../Shared/Components/MainDatePicker/MainDatePicker";
import AddTaxAction from "./AddTaxAction";
import classes from "./Add.module.css";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";

function Add(props) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);
  const [hoursDatepickerFir, setHoursDatepickerFir] = useState(null);
  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  //   const [trainees, setTrainees] = useState([]);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);

  //   const [isTraineesLoading, setIsTraineesLoading] = useState(true);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "coupon-list",
      page: t("financial.tax.tax"),
      pagePath: `${
        localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"
      }/tax`,
    },
    {
      id: "create_exhibition",
      page: t("financial.tax.add_tax"),
      active: true,
    },
  ];

  const mechanismOptions = [
    {
      value: 1,
      label: t("amount_tax"),
    },
    {
      value: 2,
      label: t("coupon.label.per_value"),
    },
  ];

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
    },
  ];

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { not_in_program: 1 } : {}),
      mode: "Paid",
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          // console.log(res);
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      })
      .finally(() => {
        setIsCoursesLoading(false);
        setIsProgramsLoading(false);
      });
  };

  useEffect(() => {
    currentDate();

    getCountries()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCountries(res.data.countries);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCoursesProgramsHandler({
      is_program: false,
    });
    getAllCoursesProgramsHandler({
      is_program: true,
    });
    setIsLoading(false);
  }, []);

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };
  const currentDateEnd = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
  };

  const coursesFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
    {
      id: "code",
      column: ({ rowData }) => <>{rowData.code || "-"}</>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <>{rowData.run_serial_number || "-"}</>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => <>{rowData.start_date || "-"}</>,
    },
  ];

  const programsFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
    {
      id: "code",
      column: ({ rowData }) => <>{rowData.code || "-"}</>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <>{rowData.run_serial_number || "-"}</>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => <>{rowData.start_date || "-"}</>,
    },
  ];
  return (
    <>
      <div className={classes["create_tax"]}>
        <div className="container-fluid">
          <div className={classes["create_tax__container"]}>
            <div className="row">
              <div className="col-12">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            {!isLoading && (
              <div className="row">
                <div className="col-12">
                  <MainBox>
                    <Formik
                      initialValues={{
                        name: "",
                        notes: "",
                        value: "",
                        mechanism: "1",
                        parentType: 1,
                        parent_id: "",
                        nationalities: [],
                        // users: [],
                        start_date: setHours(
                          setMinutes(new Date(), minutesDatepicker),
                          hoursDatepicker
                        ),
                        end_date: setHours(
                          setMinutes(new Date(), minutesDatepicker),
                          hoursDatepicker
                        ),
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        try {
                          let dataToBeUploaded = {
                            ...values,
                          };
                          dataToBeUploaded.start_date = toServerTime(
                            formatDate(new Date(dataToBeUploaded.start_date)) +
                              " " +
                              formatTime(new Date(dataToBeUploaded.start_date))
                          );

                          dataToBeUploaded.end_date = toServerTime(
                            formatDate(new Date(dataToBeUploaded.end_date)) +
                              " " +
                              formatTime(new Date(dataToBeUploaded.end_date))
                          );
                          dataToBeUploaded.nationalities =
                            dataToBeUploaded.nationalities.map(
                              (country) => country.id
                            );

                          // console.log(dataToBeUploaded);

                          let response = await addTax(dataToBeUploaded);
                          if (response.status === 201 && response.data.status) {
                            setIsLoading(true);
                            history.push(
                              `${
                                localStorage.getItem("type") == "accountant"
                                  ? "/accountant"
                                  : "/admin"
                              }/tax`
                            );
                            setIsLoading(false);
                          } else {
                            toast.error(
                              <span
                                style={{ fontSize: 13, fontWeight: "bold" }}
                              >
                                {response?.data?.message
                                  ? response?.data?.message
                                  : "Failure in service"}
                              </span>
                            );
                            setSubmitting(false);
                          }
                        } catch (err) {
                          console.log("err", err);
                          // setSubmitting(false);
                          toast.error(
                            <>
                              {err?.data?.errors ? (
                                Object.keys(err?.data?.errors)?.map((key) => {
                                  return (
                                    <span style={{ fontSize: 12 }}>
                                      {err?.data?.errors[key]}
                                    </span>
                                  );
                                })
                              ) : (
                                <span
                                  style={{ fontSize: 13, fontWeight: "bold" }}
                                >
                                  {err?.data?.msg}
                                </span>
                              )}
                            </>
                          );
                        }
                      }}
                      validateOnChange={hasSubmitted}
                      validate={(values) => {
                        setHasSubmitted(true);
                        const errors = {};
                        if (!values.name) {
                          errors.name = t("crud.errors.required");
                        }
                        if (!values.notes) {
                          errors.notes = t("crud.errors.required");
                        }

                        if (!values.start_date) {
                          errors.start_date = t("crud.errors.required");
                        }

                        if (!values.end_date) {
                          errors.end_date = t("crud.errors.required");
                        }

                        if (!values.value) {
                          errors.value = t("crud.errors.required");
                        }
                        // if (values.nationalities == 0) {
                        //   errors.nationalities = t("crud.errors.required");
                        // }

                        // if (values.users.length == 0) {
                        //   errors.users = t("crud.errors.required");
                        // }
                        if (!values.parentType) {
                          errors.parentType = t("crud.errors.required");
                        }
                        if (!values.parent_id) {
                          errors.parent_id = t("crud.errors.required");
                        }

                        return errors;
                      }}
                    >
                      {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        handleBlur,
                        setFieldValue,
                        validateForm,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          className={classes["create_tax__form"]}
                        >
                          <MainBoxHead title={t("financial.tax.add_tax")} />
                          <div
                            className={`row ${classes["create_tax__form__fields"]}`}
                          >
                            <div
                              className={`${classes["create_tax__form__field"]} col-12`}
                            >
                              <Input
                                label={t("financial.tax.name")}
                                type="text"
                                name="name"
                                placeholder={t("financial.tax.name")}
                                onChange={handleChange}
                                error={errors.name}
                                value={values.name}
                              />
                            </div>
                            <div
                              className={`${classes["create_tax__form__field"]} col-12`}
                            >
                              <Input
                                label={t("note_for_user")}
                                type="text"
                                name="notes"
                                placeholder={t("note_for_user")}
                                onChange={handleChange}
                                error={errors.notes}
                                value={values.notes}
                              />
                            </div>
                            <div
                              className={`${classes["create_tax__form__field"]} col-12 col-md-6`}
                            >
                              <label htmlFor="mechanism">
                                {t("machinsim_tax")}
                              </label>
                              <Select
                                name="mechanism"
                                id="mechanism"
                                options={mechanismOptions}
                                value={mechanismOptions.find(
                                  (option) => option.value == values.mechanism
                                )}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                onChange={(item) => {
                                  setFieldValue("mechanism", item.value);
                                }}
                                placeholder={t("machinsim_tax")}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.mechanism ? errors.mechanism : null}
                              </p>
                            </div>
                            <div
                              className={`${classes["create_tax__form__field"]} col-12 col-md-6`}
                            >
                              <Input
                                label={t("financial.tax.value_tax")}
                                type="number"
                                name="value"
                                placeholder={t("financial.tax.value_tax")}
                                onChange={handleChange}
                                error={errors.value}
                                min={0.01}
                                value={values.value}
                              />
                            </div>
                            <div
                              className={`${classes["create_tax__form__field"]} col-12 col-md-6`}
                            >
                              <MainDatePicker
                                label={t("general.start_date_time")}
                                name="start_date"
                                error={errors.start_date}
                                value={values.start_date}
                                onChange={(date) => {
                                  setFieldValue("start_date", date);

                                  let CurrentDate = new Date();
                                  let CurrentDateFormate =
                                    CurrentDate.toISOString().split("T")[0];
                                  let DataPickerValue = date
                                    .toISOString()
                                    .split("T")[0];
                                  if (
                                    new Date(DataPickerValue).getTime() >
                                    new Date(CurrentDateFormate).getTime()
                                  ) {
                                    setHoursDatepicker(0);
                                    setMinutesDatepicker(0);
                                  } else {
                                    currentDate();
                                  }
                                }}
                                minDate={new Date()}
                                minTime={setHours(
                                  setMinutes(new Date(), minutesDatepicker),
                                  hoursDatepicker
                                )}
                                maxTime={setHours(
                                  setMinutes(new Date(), 30),
                                  23
                                )}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.start_date ? errors.start_date : null}
                              </p>
                            </div>
                            <div
                              className={`${classes["create_tax__form__field"]} col-12 col-md-6`}
                            >
                              <MainDatePicker
                                label={t("general.end_date_time")}
                                name="end_date"
                                error={errors.end_date}
                                value={values.end_date}
                                onChange={(date) => {
                                  setFieldValue("end_date", date);

                                  let CurrentDate = new Date();
                                  let CurrentDateFormate =
                                    CurrentDate.toISOString().split("T")[0];
                                  let DataPickerValue = date
                                    .toISOString()
                                    .split("T")[0];
                                  if (
                                    new Date(DataPickerValue).getTime() >
                                    new Date(CurrentDateFormate).getTime()
                                  ) {
                                    setHoursDatepickerEnd(0);
                                    setMinutesDatepickerEnd(0);
                                  } else {
                                    currentDateEnd();
                                  }
                                }}
                                minDate={new Date()}
                                minTime={setHours(
                                  setMinutes(new Date(), minutesDatepicker),
                                  hoursDatepicker
                                )}
                                maxTime={setHours(
                                  setMinutes(new Date(), 30),
                                  23
                                )}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.end_date ? errors.end_date : null}
                              </p>
                            </div>

                            <div
                              className={`${classes["create_tax__form__field"]} col-12`}
                            >
                              <div className={classes["select-field"]}>
                                <label htmlFor={"nationalities"}>
                                  {t("nationality_expcted")}
                                </label>

                                <Select
                                  isMulti
                                  name={"nationalities"}
                                  onChange={(value) => {
                                    setFieldValue("nationalities", value);
                                  }}
                                  className={classes["select"]}
                                  options={countries.map((country) => {
                                    return {
                                      id: country.id,
                                      value: country.id,
                                      label: `${country.nationality} - ${country.name}`,
                                    };
                                  })}
                                  getOptionValue={(country) => country.id}
                                  placeholder={t("nationality_expcted")}
                                />
                                {
                                  <p
                                    className={`form-input-error-space ${classes["select-field-error"]}`}
                                  >
                                    {errors.nationalities &&
                                      errors.nationalities}
                                  </p>
                                }
                              </div>
                            </div>
                            <div
                              className={`${classes["create_tax__form__field"]} col-12`}
                            >
                              <label htmlFor="course_program">
                                {t("coupon.label.select_course_or_program")}
                              </label>
                              <Select
                                name="course_program"
                                id="course_program"
                                options={parentTypeOptions}
                                value={parentTypeOptions.find(
                                  (option) => option.id == values.parentType
                                )}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.id}
                                onChange={(item) => {
                                  setFieldValue("parentType", item.id);
                                  setFieldValue("parent_id", "");
                                }}
                                placeholder={t(
                                  "coupon.label.select_course_or_program"
                                )}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.parentType ? errors.parentType : null}
                              </p>
                            </div>
                            <div
                              className={`${classes["create_tax__form__field"]} col-12`}
                            >
                              <label htmlFor="courses_programs">
                                {values.parentType == 1
                                  ? t("coupon.label.select_course")
                                  : t("coupon.label.select_program")}
                              </label>
                              <DataTable
                                title={
                                  values.parentType == 1
                                    ? t("coupon.label.select_course")
                                    : t("coupon.label.select_program")
                                }
                                selectedItems={[values.parent_id]}
                                isLoading={
                                  values.parentType == 1
                                    ? isCoursesLoading
                                    : isProgramsLoading
                                }
                                data={
                                  values.parentType == 1 ? courses : programs
                                }
                                fields={
                                  values.parentType == 1
                                    ? coursesFields
                                    : programsFields
                                }
                                isMultiple={false}
                                searchableFields={
                                  values.parentType == 1
                                    ? [
                                        "id",
                                        "name",
                                        "code",
                                        "run_serial_number",
                                      ]
                                    : [
                                        "id",
                                        "name",
                                        "code",
                                        "run_serial_number",
                                      ]
                                }
                                selectedHandler={(item) => {
                                  setFieldValue("parent_id", item?.id);
                                }}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.parent_id ? errors.parent_id : null}
                              </p>
                            </div>
                            <AddTaxAction
                              submitAction={handleSubmit}
                              isSubmitting={isSubmitting}
                            />
                          </div>
                          {isSubmitting && <SkeletonCardOverlay />}
                        </form>
                      )}
                    </Formik>
                  </MainBox>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Add;
