import axios from "axios";
import env from "../../Services/api/Environment";

export default class UploadAdapter {
    constructor( loader ) {
        // Save Loader instance to update upload progress.
        this.loader = loader;
    }

    upload = async () => {
        let file = null;
        try{
            file = await this.loader.file
        }catch (err){
            console.log("ERROR FETCHING IMAGE::");
            console.log(err);
        }

        const data = new FormData();
        data.append('typeOption', 'upload_image');
        data.append('upload', file);

        return new Promise((resolve, reject) => {
            if(file){
                axios({
                    url: env.baseurl + '/lesson/content/image',
                    method: 'post',
                    data,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS",
                    },
                    withCredentials: false
                }).then(res => {
                    console.log(res)
                    var resData = res.data;
                    resData.default = resData.url;
                    resolve(resData);
                }).catch(error => {
                    console.log(error)
                    reject(error)
                });
            }else{
                reject("Cannot fetch file");
            }
        });
    }

    abort() {
        // Reject promise returned from upload() method.
    }
}
