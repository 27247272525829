import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { livechatConstant } from "../../Constants/livechat/livechat.constant";

export function getlivechatSettings() {
  return (dispatch) => {
    connector
      .get(env.livechat_setting)
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_SETTING,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

export const postLivechatSendMessage = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_send_message, payload)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_SEND_MESSAGE,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

// private_chat_send_message
export const postPrivateChatSendMessage = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.private_chat_send_message, payload)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_SEND_MESSAGE,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export const postLivechatCloseSession = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_close_session, { chat_session_id: payload })
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_SEND_MESSAGE,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export function getlivechatSession() {
  return (dispatch) => {
    connector
      .get(env.livechat_sessions)
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_SESSION,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

export const postLivechatStatus = () => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_status)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_STATUS,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export const postLivechatAutoReply = () => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_autoreply)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_AUTO_REPLY,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export function getlivechatReply(filter) {
  return (dispatch) => {
    connector
      .get(env.livechat_reply, {
        params: {
          ...filter,
        },
      })
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_REPLY,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

export function getSupportTicketReply(filter) {
  return (dispatch) => {
    connector
      .get(env.support_ticket_reply, {
        params: {
          ...filter,
        },
      })
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_REPLY,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

export function getlivechatReplyById(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.livechat_reply_by_id, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_REPLY_BY_ID,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

export const postLivechatAddReply = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_add_edit_reply, payload)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_ADD_REPLY,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export const postLivechatEditReply = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_add_edit_reply, payload)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_EDIT_REPLY,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export const postSupportTicketAddReply = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.support_ticket_reply, payload)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_ADD_REPLY,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export const postSupportTicketEditReply = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.support_ticket_reply, payload)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_EDIT_REPLY,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export const postLivechatReplyStatus = (id) => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_reply_status, { reply_id: id })
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_REPLY_STATUS,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export function deleteLivechatReply(id) {
  return (dispatch) => {
    connector
      .delete(URLBuilder(env.livechat_delete_reply, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.DELETE_LIVECHAT_REPLY,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data.msg ? res.data.msg : "success"}</span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message || err.response.data.msg}</span>
        );
      });
  };
}

export function resetLivechat() {
  return (dispatch) => {
    dispatch({
      type: livechatConstant.RESET_LIVECHAT,
    });
  };
}

export const postLivechatStandardMessages = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_standardـmessages, payload)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_STANDERMESSAGE,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export function getlivechatSupportUsers(filter) {
  return (dispatch) => {
    connector
      .get(env.livechat_support_users, {
        params: {
          ...filter,
        },
      })
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_SUPPORT_USERS,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

export const postLivechatUpdateSupportUser = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_update_support_users, payload)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_UPDATE_SUPPORT_USERS,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export const postLivechatSupportUsersStatus = (id) => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_support_users_status, { user_id: id })
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_SUPPORT_USERS_STATUS,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export function deleteLivechatSupportUsers(id) {
  return (dispatch) => {
    connector
      .delete(URLBuilder(env.livechat_delete_support_users, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.DELETE_LIVECHAT_SUPPORT_USERS,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data.msg ? res.data.msg : "success"}</span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message || err.response.data.msg}</span>
        );
      });
  };
}

export function getlivechatSupportUsersById(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.livechat_support_users_by_id, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_SUPPORT_USERS_BY_ID,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

export const postLivechatSessionAssign = (id) => {
  return async (dispatch) => {
    await connector
      .post(env.livechat_session_assign, { chat_session_id: id })
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_LIVECHAT_SESSION_ASSIGN,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
      });
  };
};

export function getlivechatViewSession(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.livechat_view_session, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_VIEW_SESSION,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

export function livechatChangeMessage() {
  return (dispatch) => {
    dispatch({
      type: livechatConstant.LIVECHAT_CHANGE_MESSAGE,
    });
  };
}

export function getlivechatMessage(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.livechat_messages, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_MESSAGES,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

export function getlivechatSchedule(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.livechat_schedule, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_LIVECHAT_SCHEDULE,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

// get private chat sessions
export function getPrivateChatSessions(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.livechat_private_chat_sessions, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_PRIVATE_CHAT_SESSIONS,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

// get trainees list for course
export function getCourseTrainees(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.get_course_trainees, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_COURSE_TRAINEES,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}

// send first message to trainee
export const postFirstMessage = (payload) => {
  return async (dispatch) => {
    await connector
      .post(env.send_first_private_message, payload)
      .then((res) => {
        dispatch({
          type: livechatConstant.POST_FIRST_MESSAGE,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message || err.response.data.msg}</span>
        );
      });
  };
};

// get Trainee Chat Sessions
export function getTraineeChatSessions(id) {
  return (dispatch) => {
    dispatch({
      type: livechatConstant.GET_TRAINEE_CHAT_SESSIONS_REQUEST,
    });
    connector
      .get(URLBuilder(env.get_trainee_chat_sessions, { id: id }))
      .then((res) => {
        dispatch({
          type: livechatConstant.GET_TRAINEE_CHAT_SESSIONS_SUCCESS,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        dispatch({
          type: livechatConstant.GET_TRAINEE_CHAT_SESSIONS_FAILURE,
          payload: err.response.data,
        });
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.message}</span>);
      });
  };
}
