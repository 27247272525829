import React from "react";
import { CourseProvider } from "../../Services/api/courses/courseContext";
import { DepartmentProvider } from "../../Services/api/toutorials/categoryContext";
import { ClassificationProvider } from "../../Services/api/toutorials/classificationContext";
import { TrainerProvider } from "../../Services/api/toutorials/trainerContext";
import { Certification, CoursesList, MainSearch, Program, Trainers } from "./";
import MainSection from "./MainSection/MainSection";
import BriefSection from "./BriefSection/BriefSection";
import Categories from "./categories/Categories";
import Banner from "./Banner/Banner";
import FollowDream from "./FollowDreamsSection/FollowDream";
import PublishSeaction from "./PublishSeaction/PublishSeaction";

function Home() {
  return (
    <>
      <DepartmentProvider>
        <ClassificationProvider>
          <CourseProvider>
            <MainSection />
            {/* <Certification /> */}
            {/* <MainSearch /> */}
            <BriefSection />
            <Categories />
            <CoursesList />
            <Banner />
          </CourseProvider>
        </ClassificationProvider>
      </DepartmentProvider>
      {/* <Certification /> */}
      {/* <Tracks /> */}
      {/* <Program /> */}
      <TrainerProvider>
        <Trainers />
      </TrainerProvider>
      <PublishSeaction />
      <FollowDream />
      {/* <Testimonial/>    */}
      {/* <Partners/>  */}
    </>
  );
}

export default Home;
