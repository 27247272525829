import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import ModelWithInput from "../../../Shared/Components/ModelWithInput/ModelWithInput";
import userCheck from "../../../../assets/image/user-check.svg";
import userX from "../../../../assets/image/user-x.svg";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import {
  rejectPeymentRequest,
  acceptPeymentRequestStatus,
} from "../../../../Redux/Actions/peymentRequestAdmin/peymentRequestAdmin.action";
import Tabs from "../../../Shared/Components/Tabs/Tabs";
import classes from "./PaymentRequests.module.css";
import { useState } from "react";
const PaymentRequestsProgramsTable = ({
  activeTab,
  setActiveTab,
  paymentDetailsHandler,
  setFilterData,
  paymentRequests,
  isProgram,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showAcceptModel, setShowAcceptModel] = useState(false);
  const [peymentRequestId, setPeymentRequestId] = useState(null);
  const [showRejectModel, setShowRejectModel] = useState(false);

  const peymentRequestsTableActionsList = [
    {
      id: "accept",
      icon: <img src={userCheck} alt="" />,
      action: (id) => {
        acceptHandler(id);
      },
      tooltip: t("general.accept_model_title"),
    },

    {
      id: "reject",
      icon: <img src={userX} alt="" />,
      action: (id) => {
        rejectHandler(id);
      },
      tooltip: t("general.reject_model_title"),
    },
  ];

  const acceptHandler = (id) => {
    setShowAcceptModel(true);
    setPeymentRequestId(id);
  };

  const ConfirmAcceptHandler = (amount) => {
    if (!peymentRequestId) return;
    dispatch(acceptPeymentRequestStatus({ id: peymentRequestId, amount }));
    setShowAcceptModel(false);
  };

  const rejectHandler = (id) => {
    setShowRejectModel(true);
    setPeymentRequestId(id);
  };

  const ConfirmRejectHandler = (reject_reason) => {
    if (!peymentRequestId) return;
    dispatch(rejectPeymentRequest({ id: peymentRequestId, reject_reason }));
    setShowRejectModel(false);
  };

  const tabs = [
    {
      id: "waiting_requests",
      key: 1,
      label: t("financial.payments_request.waiting_req"),
    },

    {
      id: "requests_approved",
      key: 2,
      label: t("financial.payments_request.approved_req"),
    },
    {
      id: "requests_rejected",
      key: 3,
      label: t("financial.payments_request.rejected_req"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "photo",
      name: t("general.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["departments-trainers__table-photo"]}>
          {rowData?.user?.img ? (
            <img src={rowData?.user?.img} alt="" />
          ) : (
            <i class="fa fa-user" aria-hidden="true"></i>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "username",
      name: t("financial.payments_request.username"),
      key: "username",
      cell: ({ rowData }) => (
        <>
          {i18n.language === "ar"
            ? rowData?.user?.name_ar
            : rowData?.user?.name_en}
        </>
      ),
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => (
        <a
          className={classes["departments-trainees__table-email"]}
          href={`mailto: ${rowData?.user?.email}`}
        >
          {rowData?.user?.email}
        </a>
      ),
    },
    ////////////////////
    {
      id: "user_role",
      name: t("financial.payments_request.user_role"),
      key: "user_role",
      cell: ({ rowData }) => (
        <>
          {rowData.user.roles.map((role) =>
            i18n.language === "ar" ? (
              <>
                {role.label}
                <br />
              </>
            ) : (
              <>
                {role.name}
                <br />
              </>
            )
          )}
        </>
      ),
    },
    //////////////////////
    {
      id: "notes",
      name: t("financial.payments_request.notes"),
      key: "notes",
      cell: ({ rowData }) => (
        <>
          {
            <div style={{ width: 300 }}>
              <p className={classes["note_table"]}>{rowData?.note || "-"}</p>
            </div>
          }
        </>
      ),
    },

    {
      id: "program_title",
      name: t("financial.payments_request.program_title"),
      key: "program_title",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData?.type === "Course"
              ? rowData?.course?.program?.name || "-"
              : rowData?.course?.name}
          </span>
        </>
      ),
    },

    {
      id: "program_code",
      name: t("financial.payments_request.program_code"),
      key: "program_code",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData?.type === "Course"
              ? rowData?.course?.program?.code || "-"
              : rowData?.course?.code}
          </span>
        </>
      ),
    },
    {
      id: "course_title",
      name: t("financial.payments_request.course_title"),
      key: "course_title",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData.type === "Program" ? "-" : rowData?.course?.name || "-"}
          </span>{" "}
        </>
      ),
    },

    {
      id: "course_code",
      name: t("financial.payments_request.course_code"),
      key: "course_code",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData.type === "Program" ? "-" : rowData?.course?.code || "-"}
          </span>
        </>
      ),
    },

    {
      id: "course_run_serial_number",
      name: t("financial.payments_request.course_run_serial_number"),
      key: "course_run_serial_number",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData.type === "Program"
              ? "-"
              : rowData?.course?.run_serial_number || "-"}
          </span>
        </>
      ),
    },
    {
      id: "trainer_total_amount",
      name: t("financial.payments_request.trainer_total_amount"),
      key: "trainer_total_amount",
      cell: ({ rowData }) => (
        <>
          {rowData?.dues.length !== 0
            ? rowData?.dues.map((due) =>
                due.trainer_id == rowData?.user_id
                  ? due.value
                    ? `${due.value} ${t("rs")}`
                    : "-"
                  : ""
              )
            : "-"}
        </>
      ),
    },
    ...(activeTab === 2
      ? [
          {
            id: "added_amount_when_accepted",
            name: t("financial.payments_request.added_amount_when_accepted"),
            key: "added_amount_when_accepted",
            cell: ({ rowData }) => <>{`${rowData?.amount} ${t("rs")}`}</>,
          },
        ]
      : []),
    {
      id: "trainer_received_amounts",
      name: t("financial.payments_request.trainer_received_amounts"),
      key: "trainer_received_amounts",
      cell: ({ rowData }) => <>{`${rowData?.totalPaid} ${t("rs")}`}</>,
    },

    ...(activeTab === 3
      ? [
          {
            id: "rejected_reason",
            name: t("financial.payments_request.rejected_reason"),
            key: "rejected_reason",
            cell: ({ rowData }) => <>{rowData?.reject_reason}</>,
          },
        ]
      : []),
    {
      id: "request_date",
      name: t("financial.payments_request.request_date"),
      key: "request_date",
      cell: ({ rowData }) => (
        <>
          {rowData?.created_at ? (
            <>
              {moment(rowData?.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.created_at)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    ...(activeTab === 1
      ? [
          {
            id: "actions",
            name: t("general.actions"),
            key: "actions",
            cell: ({ rowData }) => (
              <TableActionsColumn
                actions={peymentRequestsTableActionsList}
                id={rowData.id}
              />
            ),
          },
        ]
      : []),
    ...(activeTab === 2 || activeTab === 3
      ? [
          {
            id: "action_date",
            name: t("financial.payments_request.action_date"),
            key: "action_date",
            cell: ({ rowData }) => (
              <>
                {rowData?.processed_at ? (
                  <>
                    {moment(rowData?.processed_at).format("DD/MM/YYYY")}
                    <br />
                    {utcToLocal(rowData?.processed_at)}
                  </>
                ) : (
                  "-"
                )}
              </>
            ),
          },
        ]
      : []),
    ...(activeTab === 2 || activeTab === 3
      ? [
          {
            id: "action_by",
            name: t("financial.payments_request.action_by"),
            key: "action_by",
            cell: ({ rowData }) => (
              <>
                {i18n.language === "ar"
                  ? rowData?.processor?.name_ar
                  : rowData?.processor?.name_en}
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className={classes["departments-trainees__tables"]}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onSelectTab={(key) => {
          setFilterData({
            username: "",
            email: "",
            user_role: "",
            course_title: "",
            course_code: "",
            course_serial_number: "",
            action_by: "",
            ...(isProgram ? { program_code: "", program_title: "" } : {}),
          });
          setActiveTab(key);
        }}
      />
      <MainTable
        columns={columns}
        data={paymentRequests || []}
        isLoading={false}
      />
      {showAcceptModel && (
        <ModelWithInput
          openModal={showAcceptModel}
          setOpenModal={setShowAcceptModel}
          modalTitle={t("general.accept_model_title")}
          buttonText={t("general.submit")}
          type={"numberInput"}
          handleSubmit={ConfirmAcceptHandler}
          inputLabel={t("financial.payments_request.rejected_reason")}
        />
      )}
      {showRejectModel && (
        <ModelWithInput
          openModal={showRejectModel}
          setOpenModal={setShowRejectModel}
          modalTitle={t("general.reject_model_title")}
          buttonText={t("general.submit")}
          type={"textarea"}
          handleSubmit={ConfirmRejectHandler}
          inputLabel={t("financial.payments_request.rejected_reason")}
        />
      )}
    </div>
  );
};

export default PaymentRequestsProgramsTable;
