import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTrainerProfile } from "../../../../Services/api/toutorials/trainerProvider";
import edit from "../../../../assets/image/edit(1).png";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import EditOverView from "../../../trainersCreateCourses/addOverview/EditOverView";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import twitter from "../../../../assets/image/twitter.png";
import facebook from "../../../../assets/image/facebook.png";
import linkedin from "../../../../assets/image/linkedin.png";
import editIcon from "../../../../assets/icons/pen.svg";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";

function AboutMe(props) {
  require("./addOverview.css");
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState("");
  const [job_title, setJob_title] = useState("");
  const [trainerInfo, setTrainerInfo] = useState([]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "private_profile",
      page: t("sidebar.label.private_profile"),
      pagePath: "/private-profile",
    },
    {
      id: `peyment_request`,
      page: t("private_profile.about_me"),
      pagePath: "#",
      active: true,
    },
  ];
  const getTrainerInfoHandler = () => {
    getTrainerProfile()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setDescription(
            i18n.language === Lang.AR
              ? res.data.response.info.description_ar
              : res.data.response.info.description_en
          );
          setJob_title(
            i18n.language === Lang.AR
              ? res.data.response.info.job_title_ar
              : res.data.response.info.job_title_en
          );
          setTrainerInfo(res.data.response.info);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTrainerInfoHandler();
  }, []);

  const editHandler = () => {
    setEdit(!edit);
  };
  const actionsList = [
    {
      id: "edit",
      icon: <img src={editIcon} alt="" />,
      action: editHandler,
      theme: "edit",
    },
  ];

  return (
    <>
      {edit === false ? (
        <div style={{ padding: "2rem 0" }}>
          <div className="container-fluid min-hight-78-200">
            <div className="row">
              <div className="col-12">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 sm:tw-px-2 tw-py-8">
                <MainBox style={{ minHeight: "500px", gap: 0 }}>
                  <MainBoxHead actions={actionsList} title={t("addoverview")} />
                  <div style={{ marginBottom: 20 }}>
                    <div
                      className="head_box"
                      style={{
                        padding: "5px 0",
                      }}
                    >
                      <div className="overview_add">
                        <label className="label_about_me">
                          {t("job_title")}
                        </label>
                        {/* <div className="overview_add_bottom">
                          {t("pleaseaddbr")}
                        </div> */}
                      </div>
                    </div>

                    <div className="overview_list_aboutme ">
                      {job_title ? job_title : <div>{t("no_record")}</div>}
                    </div>
                  </div>
                  <div style={{ marginBottom: 20 }}>
                    <div
                      className="head_box"
                      style={{
                        padding: "5px 0",
                      }}
                    >
                      <div className="overview_add">
                        <label className="label_about_me">
                          {t("addoverview")}
                        </label>

                        {/* <div className="overview_add_bottom">
                          {t("pleaseaddbr")}
                        </div> */}
                      </div>
                    </div>
                    <div className="overview_list_aboutme ">
                      {description ? (
                        parse(`${description}`)
                      ) : (
                        <div>{t("no_record")}</div>
                      )}
                    </div>
                  </div>

                  <div className="icon_cont_about_me">
                    {trainerInfo.linkedin && (
                      <a href={trainerInfo.linkedin}>
                        <img src={linkedin} alt="" />
                      </a>
                    )}
                    {trainerInfo.facebook && (
                      <a href={trainerInfo.facebook}>
                        <img src={facebook} alt="" />
                      </a>
                    )}
                    {trainerInfo.twitter && (
                      <a href={trainerInfo.twitter}>
                        <img src={twitter} alt="" />
                      </a>
                    )}
                  </div>
                </MainBox>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <EditOverView
            editHandler={editHandler}
            trainerInfo={trainerInfo}
            getTrainerInfoHandler={getTrainerInfoHandler}
          />
        </>
      )}
    </>
  );
}

export default AboutMe;
