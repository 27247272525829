import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function addToCart(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_gift_to_cart, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function listMyGifts(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.list_my_gifts, {
          params: params,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function listGiveGifts(filter) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.list_give_gifts, {
          params: {
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function listReceivedGifts(filter) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.list_my_gifts, {
          params: {
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function listPayment(filter, type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.list_payment_gifts, { type: type }), {
          params: {
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function cancelGift(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.cancel_gift, { ids: [id] })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
