export const platformsListConstants = {
  // GET ALL   LIST
  GET_PLATFORMS_LIST_REQUEST: "GET_PLATFORMS_LIST_REQUEST",
  GET_PLATFORMS_LIST_SUCCESS: "GET_PLATFORMS_LIST_SUCCESS",
  GET_PLATFORMS_LIST_REJECTED: "GETPLATFORMS_LIST_REJECTED",

  // ACTIVE
  ACTIVE_PLATFORM_REQUEST: "ACTIVE_PLATFORM_REQUEST",
  ACTIVE_PLATFORM_SUCCESS: "ACTIVE_PLATFORM_SUCCESS",
  ACTIVE_PLATFORM_REJECTED: "ACTIVE_PLATFORM_REJECTED",
  // DEACTIVE
  DEACTIVE_PLATFORM_REQUEST: "DEACTIVE_PLATFORM_REQUEST",
  DEACTIVE_PLATFORM_SUCCESS: "DEACTIVE_PLATFORM_SUCCESS",
  DEACTIVE_PLATFORM_REJECTED: "DEACTIVE_PLATFORM_REJECTED",
  // DELETE
  DELETE_PLATFORM_REQUEST: "DELETE_PLATFORM_REQUEST",
  DELETE_PLATFORM_SUCCESS: "DELETE_PLATFORM_SUCCESS",
  DELETE_PLATFORM_REJECTED: "DELETE_PLATFORM_REJECTED",
};
