import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { addCourseDepartment } from "../../../Services/api/courses/courseProvider";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";


function AddDepartment() {
  require("./department.css")
  const { t } = useTranslation();
  const history = useHistory();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  return (
    <>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="admin_label">
            <NavLink to="/admin">
              {t("admin.label.admin_label")}
            </NavLink>
            <p style={{padding:"0 5px"}}>/</p>
            <NavLink to="/admin/department">
                {t("admin.label.list_department")}
            </NavLink>
            <p style={{padding:"0 5px"}}>/</p>
            {t("admin.label.add_department")}
          </div>
          {isLoading ?
          <div style={{
            height:"60vh"
          }}>
            <SkeletonCard />
          </div>
            :
          <div className="add_admin" style={{
            marginBottom: 0
          }}>
            <Formik
              initialValues={{
                name_ar: "",
                name_en: "",
                description_ar: "",
                description_en: "",
              }}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                let errorObj = {};
                try {
                  let response = await addCourseDepartment(values);
                  if (response.status === 201 && response.data.status) {
                    history.push("/admin/department");
                  } else {
                    alert("Failure");
                  }
                  setSubmitting(false);
                } catch (err) {
                  if(err.response.data.errors && err.response.data.errors.name_en){
                    errorObj.name_en = err.response.data.errors.name_en;
                  }
                  if(err.response.data.errors && err.response.data.errors.name_ar){
                    errorObj.name_ar = err.response.data.errors.name_ar;
                  }
                  if(Object.keys(errorObj).length !== 0){
                    setErrors(errorObj)
                  }
                }
              }}
              validateOnChange={hasSubmitted}
              validate={(values) => {
                setHasSubmitted(true);
                const errors = {};
                if (!values.name_ar) {
                  errors.name_ar = t("crud.errors.required");
                }

                if (!values.name_en) {
                  errors.name_en = t("crud.errors.required");
                }

                if (!values.description_ar) {
                  errors.description_ar = t("crud.errors.required");
                }

                if (!values.description_en) {
                  errors.description_en = t("crud.errors.required");
                }
                return errors;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="admin_add_form">
                  <div>
                    <label htmlFor="name_ar" className="admin_add_label">
                      {t("crud.placeholders.name_ar")}
                    </label>
                    <input
                      id="name_ar"
                      type="text"
                      name="name_ar"
                      className="admin_add_input"
                      onChange={handleChange}
                      placeholder={t("crud.placeholders.name_ar")}
                      value={values.name_ar}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.name_ar ? errors.name_ar : null}
                    </p>
                  </div>

                  <div>
                    <label htmlFor="name_en" className="admin_add_label">
                      {t("crud.placeholders.name_en")}
                    </label>
                    <input
                      id="name_en"
                      type="text"
                      name="name_en"
                      className="admin_add_input"
                      onChange={handleChange}
                      placeholder={t("crud.placeholders.name_en")}
                      value={values.name_en}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.name_en ? errors.name_en : null}
                    </p>
                  </div>

                  <div>
                    <label htmlFor="description_ar" className="admin_add_label">
                      {t("crud.placeholders.description_ar")}
                    </label>
                    <input
                      id="description_ar"
                      type="text"
                      name="description_ar"
                      className="admin_add_input"
                      onChange={handleChange}
                      placeholder={t("crud.placeholders.description_ar")}
                      value={values.description_ar}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.description_ar ? errors.description_ar : null}
                    </p>
                  </div>

                  <div>
                    <label htmlFor="description_en" className="admin_add_label">
                      {t("crud.placeholders.description_en")}
                    </label>
                    <input
                      id="description_en"
                      type="text"
                      name="description_en"
                      className="admin_add_input"
                      onChange={handleChange}
                      placeholder={t("crud.placeholders.description_en")}
                      value={values.description_en}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.description_en ? errors.description_en : null}
                    </p>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="admin_add_button"
                    >
                      {t("crud.placeholders.submit")}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>}
        </div>
      </div>
    </div>
    </>
  );
}

export default AddDepartment;
