import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MainSectionCarousel.css";

const MainSectionCarousel = ({ children, slidesToShow, courses }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,

    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          zIndex: 9,
        }}
      >
        {/* <ArrowBackIosNewTwoToneIcon /> */}
      </div>
    ),
    nextArrow: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          zIndex: 9,
        }}
      >
        {/* <ArrowForwardIosTwoToneIcon /> */}
      </div>
    ),
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="MainSectionCarousel">
      <Slider {...settings}>{children}</Slider>
      {/* <div style={{ textAlign: "center", marginTop: -24 }}>
        {Array.from({ length: settings.responsive.length + 1 }).map(
          (_, index) => (
            <button
              key={index}
              onClick={() => handleDotClick(index)}
              className={`slick-dot ${
                index === currentSlide ? "slick-active" : ""
              }`}
              tabIndex={0}
            ></button>
          )
        )}
      </div> */}
    </div>
  );
};

export default MainSectionCarousel;
