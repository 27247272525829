import React, { useContext, useEffect, useState, useRef } from "react";
import moment from "moment";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CourseContext } from "../../../../../Services/api/courses/courseContext";
import i18n from "../../../../../i18n/i18n";
import StarsRating from "../../../../Shared/Components/StarsRating/StarsRating";
import CourseVideos from "./CourseVideos";
import classes from "./trainingCourseContent.module.css";
import homeIcon from "../../../../../assets/icons/Group 3.svg";
import Airplay from "../../../../../assets/icons/airplayIcon.svg";
import Play from "../../../../../assets/icons/PlayIcon.svg";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import { toast } from "react-toastify";
import { joinFreeCourse } from "../../../../../Services/api/courses/courseProvider";
import warning from "../../../../../assets/icons/warning-green.svg";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import WarningModal from "../../../../Shared/Components/WarningModal/WarningModal";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import ModelWithInput from "../../../../Shared/Components/ModelWithInput/ModelWithInput";
export default function TrainingCourseContentInfo({
  isProgram,
  inProgram,
  setSubscription,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const courseDetailsContext = useContext(CourseContext);
  const course = courseDetailsContext.courseDetails;
  const token = localStorage.getItem("token");
  const roles = JSON.parse(localStorage.getItem("roles"));
  const freeCourseModalRef = useRef();
  const redirectToLoginModalRef = useRef();
  const [trainers, setTrainers] = useState([]);
  const [subTrainers, setSubTrainers] = useState([]);
  const [isUserJoined, setIsUserJoined] = useState(false);
  const [showVidModal, setShowVidModal] = useState(false);
  const [showFreeCourseModal, setShowFreeCourseModal] = useState(false);
  const [showRedirectToLoginModal, setShowRedirectToLoginModal] =
    useState(false);
  const [cannotRegisterBadge, setCannotRegisterBadge] = useState(false);
  const [rejectedBadge, setRejectedBadge] = useState(false);

  useEffect(() => {
    if (course.payment_status === "Rejected") {
      setRejectedBadge(t("payment_rejected_message"));
    }
    if (!isUserJoined) {
      if (
        course.trainee_status === false &&
        (course.is_joined_free || course.is_assigned || course.is_purchased)
      ) {
        setCannotRegisterBadge(t("trainee_blocked"));
      } else if (course.is_refunded) {
        setCannotRegisterBadge(t("payment_refunded_message"));
      } else if (course.payment_status === "Waiting") {
        setCannotRegisterBadge(t("waiting_to_accept_message"));
      }
      // else if (course.is_scfhsv && !userSCGHS) {
      //   setCannotRegisterBadge(t("scfhsv_message"));
      // }
      else if (
        course.payment_status === "approved" &&
        moment(new Date()).isBefore(getLocaleTime(new Date(course.start_date)))
      ) {
        setCannotRegisterBadge(
          t(`${course.is_program ? "program" : "course"}_not_started`)
        );
      } else if (
        course.start_register_date &&
        moment(getLocaleTime(new Date(course.start_register_date))).isAfter(
          moment()
        )
      ) {
        setCannotRegisterBadge(t("registration_not_started"));
      } else if (
        course.end_register_date &&
        moment(getLocaleTime(new Date(course.end_register_date))).isBefore(
          moment()
        )
      ) {
        setCannotRegisterBadge(t("registration_ended"));
      } else if (course.participators - course.number_of_users <= 0) {
        setCannotRegisterBadge(t("no_seats_available"));
      } else if (!course.registable) {
        setCannotRegisterBadge(t("prerequest_msg"));
      } else {
        setCannotRegisterBadge(false);
      }
    } else {
      setCannotRegisterBadge(false);
    }
  }, [course, isUserJoined]);

  useEffect(() => {
    if (course.trainer && course.sub_trainers) {
      setTrainers(inProgram ? [] : [course.trainer]);
      setSubTrainers([...course.sub_trainers]);
    }
  }, [course]);

  const openFreeCourseModal = () => {
    setShowFreeCourseModal(true);
    freeCourseModalRef.current.showModal();
  };

  const closeFreeCourseModal = () => {
    setShowFreeCourseModal(false);
    freeCourseModalRef.current.dismissModal();
  };
  const openRedirectToLoginModal = () => {
    setShowRedirectToLoginModal(true);
    redirectToLoginModalRef.current.showModal();
  };

  const closeRedirectToLoginModal = () => {
    setShowRedirectToLoginModal(false);
    redirectToLoginModalRef.current.dismissModal();
  };

  const joinFreeCourseAPI = () => {
    joinFreeCourse(course.id, course.is_program ? "program" : "course")
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setSubscription(true);
          closeFreeCourseModal();
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t(res.data.msg)}
            </span>
          );
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
        closeFreeCourseModal();
      });
  };
  function sliceHtmlText(htmlString) {
    // Create a temporary div element
    var tempDiv = document.createElement("div");

    // Set the innerHTML of the div to the provided HTML string
    tempDiv.innerHTML = htmlString;

    // Use textContent to get the text content
    var slicedText = tempDiv.textContent || tempDiv.innerText;
    // console.log(slicedText);
    return slicedText;
  }

  useEffect(() => {
    setIsUserJoined(
      (course.is_joined_free || course.is_assigned || course.is_purchased) &&
        !course.is_refunded &&
        course.trainee_status === true
    );
  }, [course]);

  return (
    <div
      className={"container-fluid" + " " + classes.training_course_content_info}
    >
      <div className={classes.training_top}>
        <div className={classes.training_over_view}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
              <img src={homeIcon} alt="" />
              <h2 className={classes.training_over_view_title}>
                {t("whatToTeach")}
              </h2>
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              {course?.video ? (
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    width: i18n.language == "ar" ? 150 : 180,
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowVidModal(true)}
                >
                  <img src={Play} alt="" />
                  <p style={{ color: "#00D09C" }}>{t("vid")}</p>
                </div>
              ) : (
                <></>
              )}
              {!inProgram && (
                <CustomButton
                  value={isUserJoined ? t("show_content") : t("enroll")}
                  colors={"#025299"}
                  type={"button"}
                  action={() => {
                    if (isUserJoined) {
                      if (
                        course.self_paced &&
                        moment(new Date()).isAfter(
                          getLocaleTime(new Date(course.start_date))
                        ) &&
                        course.end_date == ""
                      ) {
                        return history.push(
                          `/training-courses/${course.id}/content`
                        );
                      }
                      if (
                        moment(new Date()).isAfter(
                          getLocaleTime(new Date(course.start_date))
                        ) &&
                        moment(new Date()).isBefore(
                          getLocaleTime(new Date(course.end_date))
                        )
                      ) {
                        return history.push(
                          `/training-courses/${course.id}/content`
                        );
                      } else if (
                        moment(new Date()).isBefore(
                          getLocaleTime(new Date(course.start_date))
                        )
                      ) {
                        return toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {course.is_program
                              ? t("program_not_started")
                              : t("course_not_started")}
                          </span>
                        );
                      } else if (
                        moment(new Date()).isAfter(
                          getLocaleTime(new Date(course.end_date))
                        )
                      ) {
                        return toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {course.is_program
                              ? t("program_ended")
                              : t("course_ended")}
                          </span>
                        );
                      }
                    }
                    if (!token) {
                      return openRedirectToLoginModal();
                    }
                    if (!roles?.includes("trainee")) {
                      return toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {t("registration_not_allowed")}
                        </span>
                      );
                    }
                    if (course.mode === "Free") {
                      openFreeCourseModal();
                    } else if (course.mode === "Paid") {
                      history.push(
                        `/${course.is_program ? "program" : "course"}/${
                          course.id
                        }/payment`
                      );
                    }
                  }}
                  disable={
                    isUserJoined && course.trainee_status
                      ? false
                      : !course.registable || cannotRegisterBadge
                  }
                  classes={
                    isUserJoined && course.trainee_status
                      ? classes.btn
                      : !course.registable || cannotRegisterBadge
                      ? `${classes["payment-details-coupon-button-disabled"]} ${classes.btn}`
                      : classes.btn
                  }
                />
              )}
            </div>
          </div>
          <div className={classes.desc}>
            <p
              dangerouslySetInnerHTML={{
                __html: course.description,
              }}
              className={classes.descText}
            />
          </div>
          {/* <div className={classes.training_video}>
            <CourseVideos />
          </div> */}
        </div>
      </div>
      <div className={classes.training_top} style={{ marginTop: 0 }}>
        <div className={classes.training_over_view}>
          <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
            <img src={homeIcon} alt="" />
            <h2 className={classes.training_over_view_title}>
              {course.is_program ? t("courses") : t("courseContent_detials")}
            </h2>
          </div>
          <div className={classes.desc}>
            {!course.is_program && course.chapaters.length > 0 ? (
              <div className={classes.training_plan}>
                <div className={classes.training_plan_content}>
                  {course.chapaters?.map((chapter, index) => (
                    <div
                      key={chapter.id}
                      className={classes.training_plan_content_item}
                    >
                      <img
                        src={Airplay}
                        alt=""
                        style={{ height: 20, width: 23 }}
                      />
                      <div className={classes.training_plan_content_item_title}>
                        {chapter.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : course.is_program && course.courses.length > 0 ? (
              <div className={classes.training_plan}>
                <div className={classes.training_plan_program_content}>
                  {course.courses?.map((course, index) => (
                    <div
                      key={course.id}
                      className={classes.training_plan_program_content_item}
                      onClick={() =>
                        history.push(`/training-courses/${course.id}/details`)
                      }
                    >
                      <div
                        className={
                          classes.training_plan_program_content_item_image
                        }
                      >
                        <img src={course.img} alt="course" />
                      </div>
                      <div
                        className={
                          classes.training_plan_program_content_item_title
                        }
                      >
                        {course.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {!course.is_program && (
        <div className={classes.training_top} style={{ marginTop: 0 }}>
          <div className={classes.training_over_view}>
            <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
              <img src={homeIcon} alt="" />
              <h2 className={classes.training_over_view_title}>
                {t("courseTrainers")}
              </h2>
            </div>
            <div className={classes.trainers_box}>
              {trainers.map((trainer) => (
                <div
                  key={trainer.id}
                  className={classes.trainer_item}
                  onClick={() =>
                    history.push(`/trainers/trainer-details/${trainer.id}`)
                  }
                >
                  <img
                    src={trainer.img}
                    alt="trainer"
                    className={classes.trainer_item_image}
                  />
                  <div>
                    <div className={classes.trainer_item_title}>
                      {i18n.language === "ar"
                        ? trainer.name_ar
                        : trainer.name_en}
                    </div>
                    <div className={classes.trainer_des}>
                      <p>
                        {sliceHtmlText(
                          i18n.language === "ar"
                            ? trainer.description_ar
                            : trainer.description_en
                        ).slice(0, 77)}{" "}
                        ....
                      </p>
                    </div>
                    <div className={classes["flip-star"]}>
                      <StarsRating
                        rating={trainer.ratings_avg_stars || 0}
                        cutStyle={classes["star-font-size"]}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {subTrainers.map((trainer) => {
                if (course.trainer.name === trainer.name) {
                  return null;
                } else {
                  return (
                    <div
                      key={trainer.id}
                      className={classes.trainer_item}
                      onClick={() =>
                        history.push(`/trainers/trainer-details/${trainer.id}`)
                      }
                    >
                      <img
                        src={trainer.img}
                        alt="trainer"
                        className={classes.trainer_item_image}
                      />
                      <div>
                        <div className={classes.trainer_item_title}>
                          {i18n.language === "ar"
                            ? trainer.name_ar
                            : trainer.name_en}
                        </div>
                        <div className={classes.trainer_des}>
                          <p
                          // dangerouslySetInnerHTML={{
                          //   __html:
                          //     i18n.language === "ar"
                          //       ? trainer.description_ar
                          //       : trainer.description_en,
                          // }}
                          >
                            {sliceHtmlText(
                              i18n.language === "ar"
                                ? trainer.description_ar
                                : trainer.description_en
                            ).slice(0, 77)}{" "}
                            ....
                          </p>
                        </div>
                        <div className={classes["flip-star"]}>
                          <StarsRating
                            rating={trainer.ratings_avg_stars || 0}
                            cutStyle={classes["star-font-size"]}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
      <div className={classes.training_top} style={{ marginTop: 0 }}>
        <div className={classes.training_over_view}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
            <div>
              {!inProgram && (
                <CustomButton
                  value={isUserJoined ? t("show_content") : t("enroll")}
                  colors={"#025299"}
                  type={"button"}
                  action={() => {
                    if (isUserJoined) {
                      if (
                        moment(new Date()).isAfter(
                          getLocaleTime(new Date(course.start_date))
                        ) &&
                        moment(new Date()).isBefore(
                          getLocaleTime(new Date(course.end_date))
                        )
                      ) {
                        return history.push(
                          `/training-courses/${course.id}/content`
                        );
                      } else if (
                        moment(new Date()).isBefore(
                          getLocaleTime(new Date(course.start_date))
                        )
                      ) {
                        return toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {course.is_program
                              ? t("program_not_started")
                              : t("course_not_started")}
                          </span>
                        );
                      } else if (
                        moment(new Date()).isAfter(
                          getLocaleTime(new Date(course.end_date))
                        )
                      ) {
                        return toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {course.is_program
                              ? t("program_ended")
                              : t("course_ended")}
                          </span>
                        );
                      }
                    }
                    if (!token) {
                      return openRedirectToLoginModal();
                    }
                    if (
                      !roles?.includes("trainee") &&
                      !roles?.includes("admin")
                    ) {
                      return toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {t("registration_not_allowed")}
                        </span>
                      );
                    }
                    if (course.mode === "Free") {
                      openFreeCourseModal();
                    } else if (course.mode === "Paid") {
                      history.push(
                        `/${course.is_program ? "program" : "course"}/${
                          course.id
                        }/payment`
                      );
                    }
                  }}
                  disable={
                    isUserJoined && course.trainee_status
                      ? false
                      : !course.registable || cannotRegisterBadge
                  }
                  classes={
                    isUserJoined && course.trainee_status
                      ? classes.btn
                      : !course.registable || cannotRegisterBadge
                      ? `${classes["payment-details-coupon-button-disabled"]} ${classes.btn}`
                      : classes.btn
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <BasicModal ref={freeCourseModalRef}>
        {showFreeCourseModal && (
          <SharedModal
            title={t("trainer.course.enroll")}
            name={course.name}
            cancelText={t("general.cancel")}
            confirmMsg={t("trainer.course.enroll_free_course_msg")}
            confirmText={t("enroll")}
            onConfirm={joinFreeCourseAPI}
            onCancel={closeFreeCourseModal}
          />
        )}
      </BasicModal>
      <BasicModal ref={redirectToLoginModalRef}>
        {showRedirectToLoginModal && (
          <WarningModal
            img={warning}
            label={t("general.alert")}
            description={
              course.is_program
                ? t("program_loginFirst")
                : t("course_loginFirst")
            }
            dismissText={t("general.dismiss")}
            dismissColor="#DF4B43"
            successText={t("general.login")}
            successColor="#26b3b9"
            action={() => {
              closeRedirectToLoginModal();
              history.push("/login");
            }}
            dismiss={closeRedirectToLoginModal}
          />
        )}
      </BasicModal>
      {showVidModal && (
        <ModelWithInput
          openModal={showVidModal}
          setOpenModal={setShowVidModal}
          modalTitle={t("vid")}
          // buttonText={t("accept")}
          vidLink={course?.video}
          type={"vid"}
        />
      )}
    </div>
  );
}
