import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getSearchData,
  resetSearch,
  sendSearch,
} from "../../../Redux/Actions/search.action";
import { exportSearch } from "../../../Services/api/search";
import HomeIcon from "../../../assets/icons/home.svg";
import { validEmail } from "../../../utils/validations";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import SearchContainer from "./SearchContainer";
import SearchFilter from "./SearchFilter";
import SearchSaveModal from "./SearchSaveModal";
import SearchTable from "./SearchTable/SearchTable";
import { filterActions } from "./SearchTable/SearchTableActions";
import classes from "./search.module.css";

const Search = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    search,
    searchTabs,
    searchLoading,
    sendSearchLoading,
    searchColumns,
    sendSearchSuccess,
    searchList,
    searchMeta,
  } = useSelector((state) => state.searchReducer);
  const [activeSearchList, setActiveSearchList] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState("");
  const [saveSearchError, setSaveSearchError] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emails, setEmails] = useState([]);
  const [searchReport, setSearchReport] = useState("0");
  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "dashboard",
      page: t("admin.label.dashboard"),
      pagePath:
        localStorage.getItem("type") == "group-admin"
          ? `/group-admin`
          : localStorage.getItem("type") == "manager"
          ? `/manager`
          : "/admin",
    },
    {
      id: "exhibition",
      page: t("search.search_reports"),
      pagePath: "#",
    },
    {
      id: "exhibition",
      page: t("search.conduct_search"),
      active: true,
    },
  ];

  const getSearchDataHandler = (tab = "") => {
    dispatch(
      getSearchData({
        tab,
      })
    );
  };

  useEffect(() => {
    getSearchDataHandler();
  }, []);

  const handleAddingActiveField = (value) => {
    switch (value.type) {
      // case "date": {
      //   return {
      //     activeValue: ""
      //   }
      // }
      case "number":
        if (value?.childs && Object.keys(value?.childs).length) {
          return {
            activeValue: "",
            activeRadio: "",
          };
        } else {
          return {
            activeValue: "",
          };
        }
      case "radio":
        return {
          activeValue: null,
          radioFilter: "",
        };
      case "radio_date":
        return {
          activeRadioDate: "",
          radioFilter: "",
          activeValue: "",
          activeArrayValue: [],
        };
      default:
        return {
          activeValue: "",
        };
    }
  };

  const filterActiveSearchList = (tab) => {
    const activeSearchList = [];
    Object.entries(search[tab].filters).forEach(([key, value]) => {
      activeSearchList.push({
        id: key,
        field: {
          ...value,
          ...handleAddingActiveField(value),
        },
      });
    });
    setActiveSearchList(activeSearchList);
  };

  useEffect(() => {
    if (!Object.keys(search).length) return;
    filterActiveSearchList(activeTab);
  }, [search]);

  useEffect(() => {
    if (!searchTabs.length) return;
    getSearchDataHandler(searchTabs[0]?.name || "support_tickets");
    setActiveTab(searchTabs[0]?.name || "support_tickets");
  }, [searchTabs]);

  const onMainTabChangeHandler = (tab) => {
    resetSearchListHandler();
    setActiveTab(tab);
    if (search?.[tab]?.filters && Object.values(search?.[tab]?.filters)?.length)
      return filterActiveSearchList(tab);
    getSearchDataHandler(tab || "support_tickets");
  };

  const updateFieldValues = (id, value, key) => {
    const activeSearchListCopy = [...activeSearchList];
    const activeSearchListIndex = activeSearchListCopy.findIndex(
      (item) => item.id === id
    );
    const isValueArray = Array.isArray(value);
    if (isValueArray) {
      activeSearchListCopy[activeSearchListIndex].field[key] =
        Array.isArray(activeSearchListCopy[activeSearchListIndex].field[key]) &&
        activeSearchListCopy[activeSearchListIndex].field[key]?.join(",") ===
          value?.join(",")
          ? null
          : value;
    } else {
      activeSearchListCopy[activeSearchListIndex].field[key] =
        activeSearchListCopy[activeSearchListIndex].field[key] === value
          ? null
          : value;
    }
    setActiveSearchList(activeSearchListCopy);
  };

  const updateFieldArrayValues = (id, value, key) => {
    const activeSearchListCopy = [...activeSearchList];
    const activeSearchListIndex = activeSearchListCopy.findIndex(
      (item) => item.id === id
    );
    const filterValue = value.filter;
    const existingFilterIndex = activeSearchListCopy[
      activeSearchListIndex
    ].field[key]?.findIndex((item) => item.filter === filterValue);
    if (existingFilterIndex !== -1) {
      activeSearchListCopy[activeSearchListIndex].field[key][
        existingFilterIndex
      ] = value;
    } else {
      activeSearchListCopy[activeSearchListIndex].field[key].push(value);
    }
    setActiveSearchList(activeSearchListCopy);
  };

  const resetFieldArrayValues = (id, key) => {
    const activeSearchListCopy = [...activeSearchList];
    const activeSearchListIndex = activeSearchListCopy.findIndex(
      (item) => item.id === id
    );
    activeSearchListCopy[activeSearchListIndex].field[key] = [];
    setActiveSearchList(activeSearchListCopy);
  };

  const resetSearchHandler = () => {
    setShowSaveSearchModal(false);
    setSaveSearchName("");
    setSaveSearchError("");
    setEmailValue("");
    setEmailError("");
    setEmails([]);
    setSearchReport("0");
  };

  const getSubmitSearchData = () => {
    const data = {
      table: activeTab,
      columns: [],
    };
    activeSearchList.map((item) => {
      const { type, column, filter } = item.field;
      if (item.field.available_for_column) {
        data.columns.push(column);
      }
      if (item.field.available_for_filter) {
        switch (type) {
          case "number":
            if (item.field.childs && Object.keys(item.field.childs).length) {
              if (
                item.field.activeValue !== "not-specified" &&
                item.field.activeValue
              ) {
                data.filters = {
                  ...data.filters,
                  [item.field.activeRadio]: item.field.activeValue,
                };
              }
            } else {
              if (item.field.activeValue) {
                data.filters = {
                  ...data.filters,
                  [filter]: item.field.activeValue,
                };
              }
            }
            break;
          case "radio":
            if (
              item.field.activeValue !== null &&
              item.field.activeValue !== "not-specified"
            ) {
              data.filters = {
                ...data.filters,
                [item.field.radioFilter ? item.field.radioFilter : filter]:
                  item.field.activeValue,
              };
            }
            break;
          case "date":
            if (item.field.activeValue) {
              data.filters = {
                ...data.filters,
                [filter]: moment(item.field.activeValue).format("YYYY-MM-DD"),
              };
            }
            break;
          case "datetime":
            if (item.field.activeValue) {
              data.filters = {
                ...data.filters,
                [filter]: moment(item.field.activeValue).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
              };
            }
            break;
          case "radio_date":
            if (item.field.activeValue) {
              data.filters = {
                ...data.filters,
                [item.field.radioFilter]: moment(
                  item.field.activeValue
                ).isValid()
                  ? moment(item.field.activeValue).format("YYYY-MM-DD")
                  : item.field.activeValue,
              };
            }
            if (item?.field?.activeArrayValue?.length) {
              item?.field?.activeArrayValue?.forEach((date) => {
                data.filters = {
                  ...data.filters,
                  [date?.filter]: date?.value
                    ? moment(date?.value).format("YYYY-MM-DD")
                    : date?.value,
                };
              });
            }
            break;
          default:
            if (item.field.activeValue) {
              data.filters = {
                ...data.filters,
                [filter]: item.field.activeValue,
              };
            }
            break;
        }
      }
    });

    const filterData = {
      ...data,
      columns: Array.from(new Set(data.columns)),
    };
    return filterData;
  };

  const submitSearchHandler = async (saveSearch = false, page = 1) => {
    const data = getSubmitSearchData();
    if (saveSearch) {
      data.save_search = 1;
      data.search_name = saveSearchName;
      data.search_report = searchReport;
    }
    if (searchReport) {
      data.emails = emails;
    }
    dispatch(
      sendSearch({
        page: page,
        perPage: 10,
        data,
        resetSearchHandler,
      })
    );
  };

  const saveNameChangeHandler = (value) => {
    setSaveSearchName(value);
    setSaveSearchError("");
  };

  const saveSearchHandler = () => {
    if (!saveSearchName) {
      setSaveSearchError(t("crud.errors.required"));
      return;
    }
    if (searchReport != "0" && !emails.length) {
      setEmailError(t("crud.errors.required"));
      return;
    }
    submitSearchHandler(true);
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    const data = getSubmitSearchData();
    try {
      await exportSearch(data, type, activeTab);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const resetSearchListHandler = () => {
    dispatch(resetSearch());
    const activeSearchListCopy = [...activeSearchList];
    activeSearchListCopy.forEach((item) => {
      item.field.activeValue = item?.field?.type === "radio" ? null : "";
      item.field.activeArrayValue = [];
      item.field.activeRadio = "";
      item.field.radioFilter = "";
      item.field.activeRadioDate = "";
    });
  };

  const emailChangeHandler = (value) => {
    setEmailValue(value.trim());
    if (value.trim()) {
      setEmailError("");
    }
  };

  const addEmailHandler = () => {
    if (!emailValue.trim()) {
      setEmailError(t("crud.errors.required"));
      return;
    }
    if (emailValue.trim() && !validEmail(emailValue)) {
      setEmailError(t("crud.errors.errorEmail"));
      return;
    }
    if (emails.includes(emailValue.trim())) {
      setEmailError(t("crud.errors.email_already_exist"));
      return;
    }
    setEmails((prev) => [...prev, emailValue.trim()]);
    setEmailValue("");
    setEmailError("");
  };

  const deleteEmailHandler = (email) => {
    setEmails((prev) => prev.filter((item) => item !== email));
  };

  return (
    <div className={classes["search"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["search__wrapper"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {searchLoading && !searchTabs?.length ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <MainBox>
                  <MainBoxHead title={t("search.search_reports")} />

                  <SearchFilter
                    activeTab={activeTab}
                    searchTabs={searchTabs}
                    activeSearchList={activeSearchList}
                    onMainTabChange={onMainTabChangeHandler}
                    updateFieldValues={updateFieldValues}
                    updateFieldArrayValues={updateFieldArrayValues}
                    resetFieldArrayValues={resetFieldArrayValues}
                    submitSearchHandler={submitSearchHandler}
                    showSaveSearchModalHandler={() => {
                      setShowSaveSearchModal(true);
                    }}
                    sendSearchLoading={sendSearchLoading}
                    resetSearchListHandler={resetSearchListHandler}
                    searchLoading={searchLoading}
                  />
                  {sendSearchSuccess && Object.keys(searchColumns).length && (
                    <>
                      <MainBoxHead
                        title={`${t("search.total_search_results")}: ${
                          searchMeta?.total || 0
                        }`}
                        actions={filterActions({
                          exportPdf: () => {
                            exportHandler("pdf");
                          },
                          exportExcel: () => {
                            exportHandler("xlsx");
                          },
                        })}
                      />
                      <SearchTable
                        submitSearchHandler={submitSearchHandler}
                        tableColumns={searchColumns}
                        tableData={searchList}
                        isLoading={sendSearchLoading}
                        totalPages={searchMeta?.totalPages}
                      />
                    </>
                  )}
                </MainBox>
              )}
            </div>
          </div>
        </div>
        {showSaveSearchModal && (
          <SearchSaveModal
            title={t("search.save_search")}
            confirmText={t("general.save")}
            cancelText={t("general.cancellation")}
            label={t("search.save_search_name")}
            onConfirm={() => {
              saveSearchHandler();
            }}
            onCancel={() => {
              setShowSaveSearchModal(false);
            }}
            isLoading={sendSearchLoading}
            value={saveSearchName}
            onChange={saveNameChangeHandler}
            error={saveSearchError}
            searchReport={searchReport}
            setSearchReport={setSearchReport}
            onEmailChange={emailChangeHandler}
            emailValue={emailValue}
            emailError={emailError}
            emails={emails}
            addEmailHandler={addEmailHandler}
            deleteEmailHandler={deleteEmailHandler}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
