import React, { createContext, useState } from "react";

const DepartmentContext = createContext();

function DepartmentProvider(props) {
  const [department, setDepartment] = useState([]);
  const [departmentID, setDepartmentID] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


  return (
    <DepartmentContext.Provider
      value={{ department, setDepartment, isLoading, setIsLoading,
        departmentID, setDepartmentID
      }}
    >
      {props.children}
    </DepartmentContext.Provider>
  );
}

export { DepartmentContext, DepartmentProvider };

