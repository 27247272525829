import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import logoVision2030 from "../../../../assets/image/Saudi_Vision_2030_logo.svg.png";
import DynamicHeaderWithLogin from "./DynamicHeaderWithLogin";
import DynamicHeaderWithOutLogin from "./DynamicHeaderWithoutLogin/DynamicHeaderWithOutLogin";
import Qassem_logo from "../../../../assets/image/Qassem_logo.svg";

import HeaderLogo from "../../../../assets/image/Header.png";

import adminDoc from "../../../../assets/image/admin-doc.png";
import notification from "../../../../assets/image/notification.png";
import { Lang } from "../../../../utils";

// import BurgerMenu from "../../../../assets/icons/burger-menu.svg";
import BurgerMenu from "../../../../assets/image/MenuQassem.png";
import MainDrawer from "./MainDrawer/MainDrawer";
import classes from "./Links.module.css";

function Links(props) {
  require("./header.css");
  const { t, i18n } = useTranslation();
  const type_login = localStorage.getItem("type_login");

  const authContext = useContext(AuthContext);
  document.body.dir = i18n.dir();

  const [showDrawer, setShowDrawer] = useState(false);
  const [addAnimationSide, setAddAnimationSide] = useState(false);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("name_er");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("type");
    localStorage.removeItem("completed_profile");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
  };

  const toggleDrawerHandler = (event) => {
    event.preventDefault();
    setShowDrawer((prev) => !prev);
  };

  const onConfirm = () => {
    setAddAnimationSide(true);
    setTimeout(() => {
      setShowDrawer(false);
    }, 300);
  };

  useEffect(() => {
    if (showDrawer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showDrawer]);

  return (
    <div className={classes["header_shadow"]}>
      {showDrawer && (
        <>
          <MainDrawer
            addAnimationSide={addAnimationSide}
            setAddAnimationSide={setAddAnimationSide}
            dismissSideBar={onConfirm}
            roles={authContext.roles}
          />
          <div className={classes.backdrop_main} onClick={onConfirm} />
        </>
      )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <nav className={classes.mainNav}>
              <div className={classes.navbar_logo_main}>
                {width <= 1200 && (
                  <div
                    className={classes["hamburger-pointer"]}
                    onClick={toggleDrawerHandler}
                  >
                    <img
                      src={BurgerMenu}
                      alt=""
                      style={{
                        transform: `${
                          i18n.language === "ar" && "rotateY(180deg)"
                        }`,
                        width: 30,
                        minWidth: 30,
                        height: 40,
                        minHeight: 40,
                      }}
                    />
                  </div>
                )}

                <NavLink exact to="/">
                  {/* <img src={logoTebx} className="logo_tebx" alt="..." /> */}
                  <img
                    className={classes.image_logo_new}
                    src={Qassem_logo}
                    alt="..."
                  />
                </NavLink>
              </div>

              <div className={classes.flex_menu}>
                {!authContext.auth.token ? (
                  <DynamicHeaderWithLogin />
                ) : (
                  <DynamicHeaderWithOutLogin />
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Links;

// <div className="userProfile">
//   {authContext.auth.token ? (
//     <>
//       <div className="dropdown">
//         <button
//           type="button"
//           className="btn dropdown-toggle header-dropdown header_nav_link "
//           data-toggle="dropdown"
//         >
//           {localStorage.getItem("img") == "null" ? (
//             <img src={adminDoc} alt="" className="admin_img" />
//           ) : (
//             <img
//               src={localStorage.getItem("img")}
//               alt="..."
//               className="admin_img"
//             />
//           )}
//           {i18n.language === Lang.AR
//             ? localStorage.getItem("name_ar") != null
//               ? localStorage.getItem("name_ar").split(" ")[0]
//               : null
//             : localStorage.getItem("name_en") != null
//             ? localStorage.getItem("name_en").split(" ")[0]
//             : null}
//         </button>
//         <div className="dropdown-menu">
//           {/* todo: */}
//           {authContext.roles.includes("admin") ? (
//             <NavLink className="dropdown-item" to="/admin">
//               {t("admin.label.dashboard")}
//             </NavLink>
//           ) : authContext.roles.includes("accountant") ? (
//             <NavLink className="dropdown-item" to="/accountant">
//               {t("admin.label.dashboard")}
//             </NavLink>
//           ) : null}
//           <NavLink className="dropdown-item" to="/edit-profile">
//             {t("edit_personal_profile")}
//           </NavLink>
//           <NavLink className="dropdown-item" to="/private-profile">
//             {t("account_info")}
//           </NavLink>
//           {type_login === "normal" ? (
//             <NavLink className="dropdown-item" to="/update-password">
//               {t("accountde")}
//             </NavLink>
//           ) : null}
//           <button className="dropdown-item" type="submit" onClick={logout}>
//             {t("logout")}
//           </button>
//         </div>
//       </div>
//       <span
//         className="header_text header_nav_link"
//         style={{
//           display: "none",
//         }}
//       >
//         <img src={notification} alt="..." className="header_notif" />
//       </span>
//     </>
//   ) : null}
// </div>;

// <div className="collapse navbar-collapse" id="navbarSupportedContent">
//   <ul className="navbar-nav mr-auto">
//     <li className="nav-item">
//       <NavLink to="/" className="nav-item nav-link header_nav_links ">
//         {t("Main")}
//       </NavLink>
//     </li>
//     <li className="nav-item">
//       <NavLink
//         to="/about-us"
//         className="nav-item nav-link header_nav_links "
//       >
//         {t("AboutUs")}
//       </NavLink>
//     </li>
//     <li className="nav-item">
//       <NavLink
//         to="/training-courses"
//         className="nav-item nav-link header_nav_links "
//       >
//         {t("TrainingCourses")}
//       </NavLink>
//     </li>
//     <li className="nav-item">
//       <NavLink
//         to="/trainers"
//         className="nav-item nav-link header_nav_links "
//       >
//         {t("Trainers")}
//       </NavLink>
//     </li>
//     <li className="nav-item">
//       <NavLink
//         to="/contactUs"
//         className="nav-item nav-link header_nav_links"
//       >
//         {t("ConnectUs")}
//       </NavLink>
//     </li>
//     <li className="nav-item">
//       <NavLink
//         to="/terms-and-conditions"
//         className="nav-item nav-link header_nav_links"
//       >
//         {t("TermsAndConditions")}
//       </NavLink>
//     </li>
//     <li className="nav-item">
//       <NavLink
//         to="/terms-and-conditions"
//         className="nav-item nav-link header_nav_links"
//       >
//         {t("TermsAndConditions")}
//       </NavLink>
//     </li>
//     <li>
//       <div>
//         {!authContext.auth.token ? (
//           <DynamicHeaderWithLogin />
//         ) : (
//           <DynamicHeaderWithOutLogin />
//         )}
//       </div>
//     </li>
//   </ul>
// </div>;
