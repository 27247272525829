import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Lang } from "../../../utils";
import i18n from "../../../i18n/i18n";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import classes from "./trainerDetailsHeader.module.css";
import Title from "../../Shared/Components/Title/Title";
import couicon from "../../../assets/icons/digital-book.png";
import Icon1 from "../../../assets/icons/icon1.svg";
import Icontwo from "../../../assets/icons/Icon2.svg";
import ArrowIcon from "../../../assets/icons/right-arrow-white.svg";
import studentsicon from "../../../assets/icons/white-stu.png";
import StarsRating from "../../Shared/Components/StarsRating/StarsRating";
import { useHistory } from "react-router";
function TrainersDetailsHeader({ trainerDetails, loading }) {
  require("./trainerDetailsHeader.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes([
      {
        id: 1,
        name_ar: "مساق",
        name_en: "Courses",
        color: "black",
        number: trainerDetails && trainerDetails.countCourses,
        icon: couicon,
      },
      {
        id: 2,
        name_ar: "متدرب",
        name_en: "Trainees",
        color: "black",
        number: trainerDetails && trainerDetails.countUsers,
        icon: studentsicon,
      },
    ]);
  }, [trainerDetails, loading]);

  return (
    <div className={classes.headerContainer}>
      <div className={`container-fluid ${classes.headerContent}`}>
        <div className={classes.headerImage}>
          <img
            src={trainerDetails && trainerDetails?.img}
            alt="..."
            className={classes.image}
          />
        </div>
        <div className={classes["header-container"]}>
          <div className={classes["headed-detials-mid"]}>
            <div className={classes.title}>
              <Title
                title={
                  i18n.language === Lang.AR
                    ? trainerDetails && trainerDetails?.name_ar
                    : trainerDetails && trainerDetails?.name_en
                }
                titleColor="#fff"
                fontSize={36}
              />
              <div className={classes["flip-star"]}>
                <StarsRating
                  rating={trainerDetails?.rating || 0}
                  cutStyle={classes["star-font-size"]}
                />
              </div>
            </div>
            <div className={classes["star-dep-cat"]}>
              <p>{` ${
                i18n.language === "ar"
                  ? trainerDetails?.job_title_ar
                  : trainerDetails?.job_title_en
              }`}</p>
              <p>&nbsp;| &nbsp;</p>
              <p>{`${t("department")} : ${
                i18n.language === "ar"
                  ? trainerDetails?.department?.name_ar
                  : trainerDetails?.department?.name_en || t("unspecified")
              }`}</p>
              <p>&nbsp;| &nbsp;</p>
              <p>{`${t("department_list.specialty")} : ${
                i18n.language === "ar"
                  ? trainerDetails?.specialty?.name_ar
                  : trainerDetails?.specialty?.name_en || t("unspecified")
              }`}</p>
            </div>
            <div className={classes.desc_trainer}>
              {/* /////////// */}
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === "ar"
                      ? trainerDetails?.description_ar
                      : trainerDetails?.description_en,
                }}
              ></p>
            </div>
            <div className={classes.num_courses}>
              <div className={classes.numContainer}>
                <img src={Icon1} alt="" />
                {trainerDetails && trainerDetails?.countCourses}
              </div>
              <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp; &nbsp;</span>
              <div className={classes.numContainer}>
                <img src={Icontwo} alt="" />
                {trainerDetails && trainerDetails?.countUsers}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: "1 1 auto" }}>
          <div className={classes["back-share"]}>
            <div
              className={classes["shareBackBtn"]}
              onClick={() => history.goBack()}
            >
              <img src={ArrowIcon} alt="" className={classes["arrowIcon"]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainersDetailsHeader;
