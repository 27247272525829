import { Pagination } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../../Components";
import {
  deleteTeam,
  exportTeams,
  getTeams,
  toggleTeamActivity,
} from "../../../../../Services/api/teams/TeamsProvider";
import { ReactComponent as CroseIcon } from "../../../../../assets/icons/crose.svg";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import { ReactComponent as EyeIcon } from "../../../../../assets/icons/eye.svg";
import FilterIcon from "../../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../../assets/icons/home.svg";
import PdfIcon from "../../../../../assets/icons/pdf.svg";
import { ReactComponent as PenIcon } from "../../../../../assets/icons/pen.svg";
import AddIcon from "../../../../../assets/icons/plus.svg";
import XlsIcon from "../../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import SwitchToggle from "../../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./exhibitionList.module.css";
import moment from "moment";
import toLocalTimezone from "../../../../../Services/Timezone";

export default function CourseTeamsList({ role }) {
  require("./List.css");

  const { t } = useTranslation();
  const { courseId } = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [teams, setTeams] = useState([]);
  const [filter, setFilter] = useState({});

  const [filterPayload, setFilterPayload] = useState({});

  const [order, setOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [keyPrefix, setKeyPrefix] = useState("__default_key__");

  const [showDeleteModalByRow, setShowDeleteModalByRow] = useState(null);

  const rend = () => Math.random().toString(36);

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const disableOrEnableQuiz = (row, index) => {
    let isActive = row.status?.value == "active";
    setIsUpdating(true);
    toggleTeamActivity(row.id)
      .then(() => {
        refetchTeams();
        setIsUpdating(false);
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {!isActive
              ? t("coworking_space_enabled")
              : t("coworking_space_disabled")}
          </span>
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.data?.msg}
          </span>
        );
        setIsUpdating(false);
      });
  };

  function getFilter() {
    const keys = Object.keys(filter);
    let currentFilter = { ...filter };

    if (currentFilter.members_count) {
      const parseValue = JSON.parse(currentFilter.members_count);
      currentFilter = {
        ...currentFilter,
        min_members_count: parseValue.min,
        max_members_count: parseValue.max,
      };
      delete currentFilter.members_count;
    }

    if (
      currentFilter.success_rate != undefined &&
      currentFilter.success_rate != null
    ) {
      const parseValue = JSON.parse(currentFilter.success_rate);
      currentFilter = {
        ...currentFilter,
        min_success_rate: parseValue.min,
        max_success_rate: parseValue.max,
      };

      delete currentFilter.success_rate;
    }

    function boolToInt(value) {
      return value === "true" ? 1 : value === "false" ? 0 : null;
    }

    return currentFilter;
  }

  // const localImportExamXML = async (file) => {
  //   if (!file) return;
  //   try {
  //     let formData = new FormData();
  //     formData.append("file", file);
  //     setIsUpdating(true);
  //     await importExamXML(formData);
  //     refetchQuiz();
  //     toast.success(
  //       <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("trainer.quiz.exam_imported_successfully")}</span>
  //     );
  //   } catch (err) {
  //     setIsUpdating(false);

  //     toast.error(
  //       <>
  //         <span
  //           style={{
  //             fontSize: 13,
  //             fontWeight: "bold",
  //             display: "block",
  //             paddingBottom: "10px",
  //           }}
  //         >
  //           {err.response?.data?.msg ?? t("trainer.quiz.failed_to_import_exam")}
  //         </span>
  //         <ul>
  //           {Object.keys(err.response?.data?.errors ?? {}).map((key) => {
  //             return (
  //               <li
  //                 style={{
  //                   direction: i18n.language == Lang.AR ? "rtl" : "ltr",
  //                 }}
  //               >
  //                 <span
  //                   style={{
  //                     fontSize: 11,
  //                   }}
  //                 >
  //                   {err.response.data.errors[key]}
  //                 </span>
  //               </li>
  //             );
  //           })}
  //         </ul>
  //       </>
  //     );
  //   }
  // };

  // const exportQuizZip = async (id) => {
  //   setIsUpdating(true);
  //   try {
  //     await exportQuizXML(id);
  //   } catch (err) {

  //   }
  //   setIsUpdating(false);
  // };

  const localExportTeams = async (type) => {
    let newFilter = {
      ...filter,
    };
    setIsUpdating(true);
    try {
      await exportTeams(type, courseId, newFilter);
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
    }
  };

  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      title: t("filter"),
      action: () => {
        setIsFilter((val) => !val);
      },
    },
    ...(role !== "partner"
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            title: t("add_coworking_space"),
            theme: "add",
            action: () => {
              history.push(`/${role}/course/${courseId}/teams/add`);
            },
          },
          // {
          //   id: "json",
          //   icon: (
          //     <label htmlFor="import-json" className="tw-m-0 tw-p-4">
          //       <JsonExportIcon className="tw-h-5 tw-w-5" />
          //     </label>
          //   ),
          //   title: t(`${t("trainer.quiz.import")} JSON`),
          //   theme: "pdf",
          //   color: "#8599b6",
          //   action: () => {},
          // },
          // {
          //   id: "zip",
          //   icon: (
          //     <label htmlFor="import-zip" className="tw-m-0 tw-p-4">
          //       <ZipExportIcon className="tw-h-5 tw-w-5" />
          //     </label>
          //   ),
          //   title: t(`${t("trainer.quiz.import")} ZIP`),
          //   theme: "pdf",
          //   color: "#2e3e5f",
          //   action: () => {},
          // },
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            title: "PDF",
            action: () => localExportTeams("pdf"),
          },
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            title: "Excel",
            action: () => localExportTeams("xlsx"),
          },
        ]
      : []),
  ];

  const quizActionsList = [
    // {
    //   id: "participants",
    //   icon: (row) => <UserIcon className="tw-w-5 tw-h-5" />,
    //   title: (row) => t("participants"),
    //   // disabled: (row) => row.monitored,
    //   action: (row) => {
    //     history.push(`/${role}/course/${courseId}/teams/${row.id}/participants`);
    //   },
    // },
    ...(role !== "partner"
      ? [
          // {
          //   id: "zip-export",
          //   icon: (row) => <ImportIcon className="tw-w-5 tw-h-5" />,
          //   title: (row) => t(`${t("trainer.quiz.tooltip.exportFile")} ZIP`),
          //   action: (row) => {
          //     exportQuizZip(row.id);
          //   },
          // },
          // {
          //   id: "import-zip",
          //   disabled: (row) => row.monitored,
          //   icon: (row) => (
          //     <label className="tw-m-0">
          //       <input
          //         id={`import-zip-${row.id}`}
          //         key={`import-zip-${row.id}-${keyPrefix}`}
          //         type="file"
          //         accept=".zip"
          //         className="tw-hidden"
          //         onChange={({ target: { files } }) => {
          //           setKeyPrefix(rend());
          //           localEditExamXML(files[0], row.id);
          //         }}
          //       />
          //       <ExportIcon className="tw-w-5 tw-h-5" />
          //     </label>
          //   ),
          //   title: (row) => t(`${t("trainer.quiz.import_questions")} ZIP`),
          //   action: (row) => {},
          // },
        ]
      : []),
    // {
    //   id: "view-details",
    //   icon: (row) => <EyeIcon className="tw-w-5 tw-h-5" />,
    //   title: (row) => t("general.view_details"),
    //   action: (row) => {
    //     history.push(`/${role}/course/${courseId}/teams/${row.id}`);
    //   },
    // },
    ...(role !== "partner"
      ? [
          {
            id: "edit",
            icon: (row) => <PenIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t("edit"),
            action: (row) => {
              history.push(`/${role}/course/${courseId}/teams/${row.id}/edit`);
            },
          },
          {
            id: "delete",
            icon: (row) => <CroseIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t("delete"),
            action: (row, index = null) => {
              setShowDeleteModalByRow(row);
            },
          },
        ]
      : []),
  ];

  const columns = [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>
      ),
    },
    // name
    {
      id: "name",
      name: t("name"),
      key: "name",
      sortable: true,
      cell: ({ rowData }) => (
        <span className="cell-font tw-w-36 text2lines">{rowData.name}</span>
      ),
    },
    // members_count
    {
      id: "members_count",
      name: t("members_count"),
      key: "members_count",
      cell: ({ rowData }) => (
        <span className="cell-font tw-whitespace-nowrap">
          {rowData.members_count}
        </span>
      ),
    },
    // created_at
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      sortable: true,
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData.created_at ? (
            <>
              <div>
                {moment(toLocalTimezone(rowData.created_at)).format(
                  "DD/MM/YYYY"
                )}
              </div>
              <div>
                {moment(toLocalTimezone(rowData.created_at)).format("hh:mm A")}
              </div>
            </>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    // // show_mark
    // {
    //   id: "show_mark",
    //   name: t("show_grade"),
    //   key: "show_mark",
    //   cell: ({ rowData }) => (
    //     <span
    //       className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
    //       style={{
    //         color: rowData.show_mark ? "#4ec1c6" : "#ff5656",
    //         backgroundColor: rowData.show_mark ? "#e3f5f6" : "#ffe4e4",
    //       }}
    //     >
    //       {t(rowData.show_mark ? t("Yes") : t("No"))}
    //     </span>
    //   ),
    // },
    // actions
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData, rowIndex }) => (
        <div
          className={`${classes["exhibition_list__actions"]} tw-flex tw-items-center tw-justify-around`}
        >
          {quizActionsList.map((action, index) => (
            <div
              className={
                action.disabled && action.disabled(rowData, rowIndex)
                  ? "tw-pointer-events-none tw-opacity-50"
                  : ""
              }
            >
              <IconsTooltip
                title={action.title(rowData)}
                content={
                  <button
                    key={action.id}
                    disabled={
                      action.disabled && action.disabled(rowData, rowIndex)
                    }
                    className={classes["exhibition_list__actions-btn"]}
                    onClick={() => {
                      action.action(rowData, rowIndex);
                    }}
                  >
                    {action.icon(rowData, rowIndex)}
                  </button>
                }
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      id: "status",
      name: t("Exhibition.status_exh"),
      key: "status",
      sortable: false,
      cell: ({ rowData, rowIndex }) => (
        <SwitchToggle
          key={rowData.id}
          status={rowData.status?.value == "active"}
          id={rowData.id}
          onStatusChange={() => {
            disableOrEnableQuiz(rowData, rowIndex);
          }}
          disabled={role === "partner"}
        />
      ),
    },
  ];

  const filterFields = [
    // name
    {
      id: "name",
      component: "input",
      type: "text",
      placeholder: t("name"),
      label: t("name"),
      name: "name",
      value: filter.name ?? "",
      classes: "col-12 col-md-6",
      onReset: () =>
        filterChangeHandler({ target: { value: "", name: "name" } }),
      onchange: filterChangeHandler,
    },
    // members_count
    {
      id: "members_count",
      component: "select",
      placeholder: t("members_count"),
      label: t("members_count"),
      initialValue: t("members_count"),
      name: "members_count",
      value: filter.members_count ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: [
        {
          id: "1",
          value: JSON.stringify({ max: 10, min: 0 }),
          label: "0 - 10",
        },
        {
          id: "2",
          value: JSON.stringify({ max: 20, min: 11 }),
          label: "11 - 20",
        },
        {
          id: "3",
          value: JSON.stringify({ max: 30, min: 21 }),
          label: "21 - 30",
        },
        {
          id: "4",
          value: JSON.stringify({ max: 40, min: 31 }),
          label: "31 - 40",
        },
        {
          id: "5",
          value: JSON.stringify({ max: 50, min: 41 }),
          label: "41 - 50",
        },
        {
          id: "6",
          value: JSON.stringify({ max: 100, min: 51 }),
          label: "51 - 100",
        },
        {
          id: "7",
          value: JSON.stringify({ min: 101, max: "" }),
          label: t("survey.morethan"),
        },
      ],
    },
    // status
    {
      id: "status",
      component: "select",
      placeholder: t("status"),
      label: t("status"),
      initialValue: t("status"),
      name: "status",
      value: filter.status ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        { value: "active", label: t("general.active") },
        { value: "inactive", label: t("general.inactive") },
      ],
    },
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-2 tw-ms-auto",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setFilter({});
        setFilterPayload({});
        if (currentPage == 1) {
          refetchTeams({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2",
      action: () => {
        setFilterPayload(getFilter());
        if (currentPage == 1) {
          refetchTeams();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  const fetchTeams = (params = getFilter()) => {
    params = {
      ...params,
      perPage: 10,
    };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getTeams(courseId, params);
        if (response.status == 200) {
          setTeams(response.data.data);
          setMeta(response.data.meta);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  const sortActionHandler = async (columnKey) => {
    let key = columnKey;

    setOrder({
      order: key,
      dir: order?.order == key && order?.dir == "ASC" ? "DESC" : "ASC",
    });
  };

  const refetchTeams = async (params = getFilter()) => {
    try {
      let customFilter = {
        ...params,
        ...order,
        page: currentPage,
      };
      await fetchTeams(customFilter);
    } catch (e) {
      console.error(e)
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  useEffect(() => {
    refetchTeams();
  }, [currentPage, order]);

  const breadcrumbList = [
    ...(role == "admin"
      ? [
          {
            id: t("admin.label.admin_label"),
            page: t("admin.label.admin_label"),
            pagePath: "/admin",
          },
        ]
      : [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
        ]),
    {
      id: t("sidebar.label.courses"),
      page: t("sidebar.label.courses"),
      pagePath: `/${role}/courses`,
    },
    {
      id: t("Courseoutline"),
      page: t("Courseoutline"),
      pagePath: `/${role}/course/${courseId}`,
    },
    {
      id: t("coworking_spaces"),
      page: t("coworking_spaces"),
      active: true,
    },
  ];

  return (
    <>
      {showDeleteModalByRow && (
        <SharedModal
          icon={DeleteIcon}
          title={t("coworking_space_delete_label")}
          name={showDeleteModalByRow.title}
          confirmMsg={t("coworking_space_delete_description")}
          confirmText={t("trainer.quiz.modal.delete.accept")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModalByRow(null);
          }}
          onConfirm={async () => {
            try {
              setIsUpdating(true);
              const res = await deleteTeam(showDeleteModalByRow.id);
              if (res.status && res.status == 200 && res.data.status) {
                setShowDeleteModalByRow(null);
                setIsUpdating(false);
                refetchTeams();
              } else {
                throw res;
              }
            } catch (error) {
              setShowDeleteModalByRow(null);
              setIsUpdating(false);
              toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {error.data?.msg}
                </span>
              );
            }
          }}
          // isLoading={iseDeleteLoading}
        />
      )}
      {/* <input
        id="import-zip"
        key={`import-zip-${keyPrefix}`}
        type="file"
        accept=".zip"
        className="tw-hidden"
        onChange={({ target: { files } }) => {
          setKeyPrefix(rend());
          localImportExamXML(files[0]);
        }}
      /> */}
      <div className="tw-ms-auto"></div>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <div className="tw-space-y-4 tw-mb-8">
            <MainBox>
              <MainBoxHead
                title={t("coworking_spaces")}
                actions={actionsList}
              />
              {isFilter && <FilterBox fields={filterFields} />}
              <MainTable
                columns={columns}
                data={teams}
                sortAction={sortActionHandler}
              />
              <Pagination
                count={meta.totalPages}
                page={currentPage}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                className="main-pagination"
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}
