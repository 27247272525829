import FilterIcon from "../../assets/icons/filter.svg";
import PdfIcon from "../../assets/icons/pdf.svg";
import XlsIcon from "../../assets/icons/xls.svg";
import i18n from "../../i18n/i18n";
import { Lang } from "../../utils";

export const filterActions = ({
  showFilter,
  exportPdf,
  exportExcel,
  pushToBankTransfers,
  pushToUserWallet,
  openFinancialFund,
}) => {
  const actionsList = [
    // ...(openFinancialFund
    //   ? [
    //       {
    //         id: "Fund",
    //         icon: i18n.language === Lang.AR ? "الصندوق" : "Financial Fund",
    //         action: openFinancialFund,
    //         theme: "second-theme",
    //       },
    //     ]
    //   : []),
    ...(pushToUserWallet
      ? [
          {
            id: "Users",
            icon:
              i18n.language === Lang.AR ? "محافظ المستخدمين" : "Users Wallets",
            action: pushToUserWallet,
            theme: "first-theme",
          },
        ]
      : []),
    ...(pushToBankTransfers
      ? [
          {
            id: "Bank",
            icon:
              i18n.language === Lang.AR ? "الحوالات البنكيه" : "Bank Transfers",
            action: pushToBankTransfers,
            theme: "last-theme",
          },
        ]
      : []),
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};
