import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import closeEye from "../../../../assets/icons/closeEye.svg";
import openEye from "../../../../assets/icons/openEye.svg";
import classes from "./input.module.css";

const Input = ({
  type,
  placeholder,
  name,
  onChange,
  label,
  error,
  min,
  max,
  currency,
  required,
  value,
  readonly = false,
  direction,
  note,
  contactUs,
  noteLogin,
  disabled = false,
  isPassword = false,
  autoComplete = "off",
  style,
  maxLength,
}) => {
  const { i18n } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");

  return (
    <div className={classes["input-field"]}>
      <label htmlFor={name} style={contactUs ? { color: "#fff" } : {}}>
        {label}
        {currency && ` (${currency})`}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      {noteLogin && (
        <div className={classes["input-field-noteLogin"]}>{noteLogin}</div>
      )}
      <input
        type={isPassword ? passwordType : type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        min={min}
        max={max}
        maxLength={maxLength}
        value={value}
        readOnly={readonly}
        autoComplete={autoComplete}
        dir={direction}
        style={
          direction && direction !== i18n.dir()
            ? { textAlign: "end", ...style }
            : { ...style }
        }
        disabled={disabled}
      />
      {isPassword && (
        <div className={classes["input-field-icon-container"]}>
          {passwordType === "password" ? (
            <img src={openEye} alt="" onClick={() => setPasswordType("text")} />
          ) : (
            <img
              src={closeEye}
              alt=""
              onClick={() => setPasswordType("password")}
            />
          )}
        </div>
      )}
      {note && <div className={classes["input-field-note"]}>{note}</div>}
      <p className={`form-input-error-space ${classes["input-field-error"]}`}>
        {error && error}
      </p>
    </div>
  );
};

export default Input;
