import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";

export const filterActions = ({ exportPdf, exportExcel, openModal }) => {
  const actionsList = [
    {
      id: "filter",
      type: "button",
      label:
        i18n.language === Lang.AR
          ? "ايداع جديد في المحفظة"
          : "New deposit in wallet",
      action: openModal,
      // theme: "new-button",
      style: {
        borderRadius: 6,
        backgroundColor: "#24b3b9",
        border: "none",
        width: 200,
        color: "#fff",
      },
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};
