import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import { specialtiesListConstants } from "../../Constants/specialties/specialtiesList.constants";

// GET SPECIALTIES LIST
export const fetchSpecialtiesList = ({ page, perPage, filter }) => {
  const payload = {};
  page && (payload.page = page);
  perPage && (payload.perPage = perPage);
  return async (dispatch) => {
    dispatch({
      type: specialtiesListConstants.GET_SPECIALTIES_LIST_REQUEST,
    });

    await connector
      .get(env.get_specialties, {
        params: {
          ...payload,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: specialtiesListConstants.GET_SPECIALTIES_LIST_SUCCESS,
            payload: {
              specialties: response.data.data.specialties,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: specialtiesListConstants.GET_SPECIALTIES_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: specialtiesListConstants.GET_SPECIALTIES_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

// UPDATE SPECIALTY STATUS
export const updateCourseStatus = ({ id, status }) => {
  return async (dispatch) => {
    dispatch({
      type: specialtiesListConstants.UPDATE_SPECIALTYS_STATUS_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.update_specialty_status, {
          id,
        }),
        {
          status,
        }
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: specialtiesListConstants.UPDATE_SPECIALTYS_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: specialtiesListConstants.UPDATE_SPECIALTYS_STATUS_REQUEST,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: specialtiesListConstants.UPDATE_SPECIALTYS_STATUS_REQUEST,
          payload: err,
        });
      });
  };
};
