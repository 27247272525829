import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import PenIcon from "./../../../../../assets/icons/pen.svg";
import SearchIcon from "./../../../../../assets/icons/search.svg";
import SeenGreenIcon from "./../../../../../assets/icons/seenGreen.svg";
import SeenWhiteIcon from "./../../../../../assets/icons/seenWhite.svg";
import UnseenIcon from "./../../../../../assets/icons/unseen.svg";
import profileAvatar from "./../../../../../assets/image/profile-avatar.png";
import NewMsg from "./NewMsg";
import classes from "./chatsList.module.css";

function ChatsList({
  chats,
  changeSeen,
  fetchMessages,
  handleSetSession,
  courseId,
}) {
  const { t } = useTranslation();
  const newMsgRef = useRef();

  const [selectedID, setSelectedID] = useState("");
  const [openNewMsg, setOpenNewMsg] = useState(false);
  const [filteredChats, setFilteredChats] = useState(chats);
  const [prevFilter, setPrevFilter] = useState("");

  const handleOpenNewMsg = () => {
    setOpenNewMsg(true);
    newMsgRef.current.showModal();
  };

  const handleCloseNewMsg = () => {
    setOpenNewMsg(false);
    newMsgRef.current.dismissModal();
  };

  const handleFilter = (e) => {
    setPrevFilter(e.target.value);
    const filtered = chats.filter(
      (chat) =>
        chat.trainee.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        chat.trainee.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredChats(filtered);
  };

  useEffect(() => {
    setFilteredChats(chats);
    handleFilter({ target: { value: prevFilter } });
  }, [chats]);

  return (
    <div className={classes.containerUsersList}>
      <div className={classes.usersListHeader}>
        <p className={classes.usersListTitle}>{t("chat.massages")}</p>
        <div className={classes.newMsg} onClick={handleOpenNewMsg}>
          <img src={PenIcon} alt="" className={classes.penIcon} />
          <span>{t("chat.newMassage")}</span>
        </div>
      </div>
      <div className={classes.usersSearch}>
        <img src={SearchIcon} alt="" className={classes.searchIcon} />
        <input
          type="text"
          placeholder={t("chat.searchUser")}
          className={classes.searchInput}
          onChange={handleFilter}
        />
      </div>
      <div className={classes.usersList}>
        {filteredChats &&
          filteredChats.map((chat, index) => (
            <div
              className={`${classes.card} ${
                selectedID == chat.id && classes.selected
              }`}
              key={index}
              onClick={() => {
                setSelectedID(chat.id);
                changeSeen(chat.id);
                fetchMessages(chat.id);
                handleSetSession(chat.id);
              }}
            >
              <div className={classes.cardContent}>
                <div className={classes.cardImg}>
                  {chat.trainee.img ? (
                    <img src={chat.trainee.img} alt="" />
                  ) : (
                    <img src={profileAvatar} alt="" />
                  )}
                </div>

                <div className={classes.contentText}>
                  <div className={classes.chatName}>{chat.trainee.name}</div>
                  <div className={classes.chatEmail}>
                    <p className={classes.email_wrap}>
                      {t("chat.email")}:
                      {chat.email ? chat.email : chat.trainee.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.cardStatus}>
                {chat.is_seen ? (
                  <img
                    src={
                      selectedID == chat.id
                        ? SeenWhiteIcon
                        : chat.is_seen
                        ? SeenGreenIcon
                        : UnseenIcon
                    }
                    alt=""
                  />
                ) : (
                  <div className={classes["new-message"]}></div>
                )}
              </div>
            </div>
          ))}
      </div>
      <BasicModal ref={newMsgRef}>
        {openNewMsg && (
          <NewMsg handleClose={handleCloseNewMsg} courseId={courseId} />
        )}
      </BasicModal>
    </div>
  );
}

export default ChatsList;
