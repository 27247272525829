export const activityCommentListConstants = {
  // GET ALL ACTIVITYCOMMENTS LIST
  GET_ACTIVITYCOMMENTS_LIST_REQUEST: "GET_ACTIVITYCOMMENTS_LIST_REQUEST",
  GET_ACTIVITYCOMMENTS_LIST_SUCCESS: "GET_ACTIVITYCOMMENTS_LIST_SUCCESS",
  GET_ACTIVITYCOMMENTS_LIST_REJECTED: "GET_ACTIVITYCOMMENTS_LIST_REJECTED",

  // GET ALL CHAPTERCOMMENTS LIST
  GET_CHAPTERCOMMENTS_LIST_REQUEST: "GET_CHAPTERCOMMENTS_LIST_REQUEST",
  GET_CHAPTERCOMMENTS_LIST_SUCCESS: "GET_CHAPTERCOMMENTS_LIST_SUCCESS",
  GET_CHAPTERCOMMENTS_LIST_REJECTED: "GET_CHAPTERCOMMENTS_LIST_REJECTED",

  // DELETE ACTIVITYCOMMENT
  DELETE_ACTIVITYCOMMENT_REQUEST: "DELETE_ACTIVITYCOMMENT_REQUEST",
  DELETE_ACTIVITYCOMMENT_SUCCESS: "DELETE_ACTIVITYCOMMENT_SUCCESS",
  DELETE_ACTIVITYCOMMENT_REJECTED: "DELETE_ACTIVITYCOMMENT_REJECTED",

  // UPDATE ACTIVITYCOMMENT IS_PUBLISHED STATUS
  UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_REQUEST:
    "UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_REQUEST",
  UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_SUCCESS:
    "UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_SUCCESS",
  UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_REJECTED:
    "UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_REJECTED",

  // UPDATE ACTIVITYCOMMENT IS_BANNED STATUS
  UPDATE_IS_BANNEDACTIVITYCOMMENT_REQUEST:
    "UPDATE_IS_BANNEDACTIVITYCOMMENT_REQUEST",
  UPDATE_IS_BANNEDACTIVITYCOMMENT_SUCCESS:
    "UPDATE_IS_BANNEDACTIVITYCOMMENT_SUCCESS",
  UPDATE_IS_BANNEDACTIVITYCOMMENT_REJECTED:
    "UPDATE_IS_BANNEDACTIVITYCOMMENT_REJECTED",

  // DELETE CHAPTERCOMMENT
  DELETE_CHAPTERCOMMENT_REQUEST: "DELETE_CHAPTERCOMMENT_REQUEST",
  DELETE_CHAPTERCOMMENT_SUCCESS: "DELETE_CHAPTERCOMMENT_SUCCESS",
  DELETE_CHAPTERCOMMENT_REJECTED: "DELETE_CHAPTERCOMMENT_REJECTED",

  // UPDATE CHAPTERCOMMENT IS_PUBLISHED STATUS
  UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_REQUEST:
    "UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_REQUEST",
  UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_SUCCESS:
    "UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_SUCCESS",
  UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_REJECTED:
    "UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_REJECTED",

  // UPDATE CHAPTERCOMMENT IS_BANNED STATUS
  UPDATE_IS_BANNEDCHAPTERCOMMENT_REQUEST:
    "UPDATE_IS_BANNEDCHAPTERCOMMENT_REQUEST",
  UPDATE_IS_BANNEDCHAPTERCOMMENT_SUCCESS:
    "UPDATE_IS_BANNEDCHAPTERCOMMENT_SUCCESS",
  UPDATE_IS_BANNEDCHAPTERCOMMENT_REJECTED:
    "UPDATE_IS_BANNEDCHAPTERCOMMENT_REJECTED",

  // ADD ACTIVITYCOMMENT STATUS
  ADD_ACTIVITYCOMMENT_REQUEST: "ADD_ACTIVITYCOMMENT_REQUEST",
  ADD_ACTIVITYCOMMENT_SUCCESS: "ADD_ACTIVITYCOMMENT_SUCCESS",
  ADD_ACTIVITYCOMMENT_REJECTED: "ADD_ACTIVITYCOMMENT_REJECTED",
  // LIKE ACTIVITYCOMMENT STATUS
  LIKE_ACTIVITYCOMMENT_REQUEST: "LIKE_ACTIVITYCOMMENT_REQUEST",
  LIKE_ACTIVITYCOMMENT_SUCCESS: "LIKE_ACTIVITYCOMMENT_SUCCESS",
  LIKE_ACTIVITYCOMMENT_REJECTED: "LIKE_ACTIVITYCOMMENT_REJECTED",
  // UNLIKE ACTIVITYCOMMENT STATUS
  UNLIKE_ACTIVITYCOMMENT_REQUEST: "UNLIKE_ACTIVITYCOMMENT_REQUEST",
  UNLIKE_ACTIVITYCOMMENT_SUCCESS: "UNLIKE_ACTIVITYCOMMENT_SUCCESS",
  UNLIKE_ACTIVITYCOMMENT_REJECTED: "UNLIKE_ACTIVITYCOMMENT_REJECTED",

  // LIKE CHAPTERCOMMENT STATUS
  LIKE_CHAPTERCOMMENT_REQUEST: "LIKE_CHAPTERCOMMENT_REQUEST",
  LIKE_CHAPTERCOMMENT_SUCCESS: "LIKE_CHAPTERCOMMENT_SUCCESS",
  LIKE_CHAPTERCOMMENT_REJECTED: "LIKE_CHAPTERCOMMENT_REJECTED",
  // UNLIKE CHAPTERCOMMENT STATUS
  UNLIKE_CHAPTERCOMMENT_REQUEST: "UNLIKE_CHAPTERCOMMENT_REQUEST",
  UNLIKE_CHAPTERCOMMENT_SUCCESS: "UNLIKE_CHAPTERCOMMENT_SUCCESS",
  UNLIKE_CHAPTERCOMMENT_REJECTED: "UNLIKE_CHAPTERCOMMENT_REJECTED",

  // PUBLISH_ALL ACTIVITYCOMMENT STATUS
  PUBLISH_ALL_ACTIVITYCOMMENT_REQUEST: "PUBLISH_ALL_ACTIVITYCOMMENT_REQUEST",
  PUBLISH_ALL_ACTIVITYCOMMENT_SUCCESS: "PUBLISH_ALL_ACTIVITYCOMMENT_SUCCESS",
  PUBLISH_ALL_ACTIVITYCOMMENT_REJECTED: "PUBLISH_ALL_ACTIVITYCOMMENT_REJECTED",
  // BAN_ALL ACTIVITYCOMMENT STATUS
  BAN_ALL_ACTIVITYCOMMENT_REQUEST: "BAN_ALL_ACTIVITYCOMMENT_REQUEST",
  BAN_ALL_ACTIVITYCOMMENT_SUCCESS: "BAN_ALL_ACTIVITYCOMMENT_SUCCESS",
  BAN_ALL_ACTIVITYCOMMENT_REJECTED: "BAN_ALL_ACTIVITYCOMMENT_REJECTED",
};
