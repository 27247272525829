import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import {
  exportRefundRequset,
  getRefunds,
} from "../../../../Services/api/Financial/FinancialProvider";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import { refundActions } from "./RefundRequestsActions.jsx";
import RefundRequestsFilter from "./RefundRequestsFilter";
import RefundRequestsTable from "./RefundRequestsTable";

function RefundRequests(props) {
  require("./RefundList.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [meta, setMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});
  const [refunds, setRefunds] = useState([]);
  const [filterData, setFilterData] = useState({
    status: "",
    revision_by: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      status: "",
      revision_by: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.status && (filter.status = filterData.status);
    filterData.revision_by && (filter.revision_by = filterData.revision_by);

    return filter;
  };

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const fetchAllRefund = () => {
    setIsLoading(true);
    const filter = getFilterData();
    setCurrentFilter(filter);

    return new Promise((resolve, reject) => {
      getRefunds({ page: currentPage, perPage: 10, filter })
        .then((res) => {
          if (res.status && res.status === 200 && res.data.status) {
            setRefunds(res.data.data.refund_requests);
            setMeta(res.data.data.meta);
            setIsLoading(false);
            resolve();
          } else {
            reject("Error");
          }
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("failed_fetching")}
            </span>
          );
        });
    });
  };

  useEffect(() => {
    fetchAllRefund();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportRefundRequset(type, clearEmptyProp(getFilterData()));
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className="container-fluid">
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="row">
        <div className="col-12">
          <MainBox className="mt-4">
            <MainBoxHead
              title={t("private_profile.refund_requests")}
              actions={refundActions({
                showFilter: showFilterHandler,
                exportPdf: () => {
                  exportHandler("pdf");
                },
                exportExcel: () => {
                  exportHandler("xlsx");
                },
              })}
            />
            {showFilter && (
              <RefundRequestsFilter
                filterData={filterData}
                changeHandler={changeHandler}
                resetHandler={resetHandler}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                filterHandler={fetchAllRefund}
                resetFilterHandler={resetFilterHandler}
              />
            )}
            <RefundRequestsTable refunds={refunds} isLoading={isLoading} />
            <Pagination
              count={meta.totalPages}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              className="main-pagination"
              onChange={(e, page) => {
                setCurrentPage(page);
              }}
            />
          </MainBox>
        </div>
      </div>
    </div>
  );
}

export default RefundRequests;
