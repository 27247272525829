import React, {useState} from "react";
import box from "../../../../assets/image/box.png";
import add from "../../../../assets/image/+.png";
import add2 from "../../../../assets/image/+2.png";
import icon2 from "../../../../assets/image/Icon2.png";
import edit from "../../../../assets/image/edit(1).png";
import order from "../../../../assets/image/Order.png";
import trash from "../../../../assets/image/trash.png";
import gear from "../../../../assets/image/gear.png";
import ContentExplanation from "../contentExplanation/contentExplanation";
import {NavLink} from "react-router-dom";

function CourseOutlineContent() {
  const [newChapter, setNewChapter] = useState(true);

  const newChapterHandler = () => {
    setNewChapter(false);
    console.log(newChapter);
  };

  let newChapterContent = "";

  if (newChapter === true) {
    newChapterContent = (
      <div className="chapter_flex">
        <div className="new_chapter">
          <div className="header_new_chapter">
            لم تقم بإضافة أي محتوى إلى هذه الدورة التدريبية بعد
          </div>
          <img src={box} alt="" className="box_new_chapter" />
          <button
            className="course_outline_btn add_outline"
            onClick={newChapterHandler}
          >
            <img src={add} alt="" className="add_icon" />
            أضف فصل جديد
          </button>
        </div>
      </div>
    );
  } else {
    newChapterContent = (
      <div className="chapter_flex">
        <div className="new_chapter">
          <div className="new_chapter_season">
            <div className="new_chapter_select_season">
              <img src={icon2} alt="" className="new_chapter_select_arrow" />
              <select name="" id="" className="new_chapter_select">
                <option value="">الفصل الأول</option>
              </select>
              <button className="edit_select_chapter">
                <img src={edit} alt="" className="edit_select_chapter_icon" />
              </button>
            </div>
            <div className="new_season_icons">
              <img src={gear} alt="" className="new_season_icon" />
              <img src={trash} alt="" className="new_season_icon" />
              <img src={order} alt="" className="new_season_icon" />
            </div>
          </div>

          <div className="collapse multi-collapse add_new_le" id="tebx">
            <div className="add_new_lesson card card-body">
              <div className="new_chapter_season">
                <div className="new_chapter_select_season">
                  <img
                    src={icon2}
                    alt=""
                    className="new_chapter_select_arrow"
                  />
                  <select name="" id="" className="new_chapter_select">
                    <option value="">مقدمة</option>
                  </select>
                  <button className="edit_select_chapter">
                    <img
                      src={edit}
                      alt=""
                      className="edit_select_chapter_icon"
                    />
                  </button>
                </div>
                <div className="new_season_icons">
                  <img src={gear} alt="" className="new_season_icon" />
                  <img src={trash} alt="" className="new_season_icon" />
                  <img src={order} alt="" className="new_season_icon" />
                </div>
              </div>
              <NavLink
                to="/course-outline/add-new-lesson"
                className="course_outline_btn add_new_chapter"
              >
                <img src={add2} alt="" className="add_icon" />
                أضف درس جديد
              </NavLink>
            </div>
          </div>
          <a
            className="course_outline_btn add_outline add_new_season"
            data-toggle="collapse"
            href="#tebx"
            role="button"
            aria-expanded="false"
            aria-controls="tebx"
            onClick={newChapterHandler}
          >
            <img src={add} alt="" className="add_icon" />
            أضف فصل فرعي جديد
          </a>
        </div>
        <div className="new_chapter">
          <a className="course_outline_btn add_new_chapter">
            <img src={add2} alt="" className="add_icon" />
            أضف فصل جديد
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="course_outline_content">
      {newChapterContent}
      <div className="explain_content">
        <ContentExplanation />
      </div>
    </div>
  );
}

export default CourseOutlineContent;
