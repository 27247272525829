import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { fetchSpecialtiesList } from "../../../../Redux/Actions/specialties/specialtiesList.action";
import { exportSpecialtiesList } from "../../../../Services/api/SpecialtiesList";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import SpecialtiesListFilter from "./SpecialtiesListFilter";
import SpecialtiesListTable from "./SpecialtiesListTable";
import { filterActions } from "./SpecialtiesListTableAction";
import classes from "./specialtiesList.module.css";

const SpecialtiesList = ({ role }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { specialtiesListMeta } = useSelector(
    (state) => state.specialtiesListReducer
  );

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    name: "",
    description: "",
    created_at: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "exhibition",
      page: t("specialties"),
      active: true,
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      description: "",
      created_at: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.description && (filter.description = filterData.description);
    filterData.created_at && (filter.date = filterData.created_at);
    return filter;
  };

  const getSpecialtiesListHandler = async () => {
    const filter = getFilterData();
    dispatch(
      fetchSpecialtiesList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
  };

  useEffect(() => {
    getSpecialtiesListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      await exportSpecialtiesList(filter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["specialties-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["specialties-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("specialties")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    addAction: () => {
                      if (role === "groupAdmin") {
                        history.push("/group-admin/category/add");
                      } else if (role === "manager") {
                        history.push("/manager/category/add");
                      } else {
                        history.push("/admin/category/add");
                      }
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                    viewAllDepartmentsLabel: t("view_all_departments"),
                    viewAllDepartmentsAction: () => {
                      if (role === "groupAdmin") {
                        history.push("/group-admin/department");
                      } else if (role === "manager") {
                        history.push("/manager/department");
                      } else {
                        history.push("/admin/department");
                      }
                    },
                  })}
                />
                {showFilter && (
                  <SpecialtiesListFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={() => {
                      getSpecialtiesListHandler();
                    }}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}

                <SpecialtiesListTable currentPage={currentPage} role={role} />
                <Pagination
                  count={specialtiesListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialtiesList;
