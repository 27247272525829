import CircularProgress from "@mui/material/CircularProgress";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { LessonContentContext } from "../../../../../../../Providers/LessonContentProvider";
import ActivityItem from "./ActivityItem";

function ListLessonActivities(props) {
  require("./List.css");
  const lessonContentContext = useContext(LessonContentContext);
  const handleCloseModal = () => lessonContentContext.setIsModalOpen(false);

  return (
    <div className={"tajah-lesson-activity-list-canvas"}>
      <ActivityItem
        activity={props.discussion}
        handleCloseModal={handleCloseModal}
        isAdmin={props.isAdmin}
        isTrainer={props.isTrainer}
        isManager={props.isManager}
        isPartner={props.isPartner}
      />
    </div>
  );
}

export default ListLessonActivities;
