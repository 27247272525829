import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import HomeIcon from "../../../../../assets/icons/home.svg";

import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { getAllUsers } from "../../../../../Services/api/survey/SurveyProvider";
import i18n from "../../../../../i18n/i18n";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";

import Select from "react-select";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import {
  addTeam,
  editTeam,
  getCreateTeam,
  getEditTeam,
  getTeamById,
} from "../../../../../Services/api/teams/TeamsProvider";

export default function CourseTeamForm({ role }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errs, setErrors] = useState({});

  const [team, setTeam] = useState({});
  const [trainees, setTrainees] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [userFetching, setUserFetching] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const { id, courseId } = useParams();

  const formikRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    initData();
  }, []);

  async function initData() {
    try {
      setIsLoading(true);
      const res = id
        ? await getEditTeam(courseId, id)
        : await getCreateTeam(courseId);

      if (res.status && res.status == 200 && res.data.status) {
        setStatuses(res.data.statuses);
      } else {
        throw res;
      }
      if (id) {
        const team = res.data.data;
        setTeam(team);
        fetchUsers(getFormInitialValues(team));
      } else {
        fetchUsers(getFormInitialValues());
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUsers = async (values) => {
    try {
      setUserFetching(true);
      setTrainees([]);
      let response = await getAllUsers({
        search: values.search ? values.search : null,
        includes: values.search ? null : values.members,
        course_trainee: courseId,
      });
      if (response.status == 200) {
        setTrainees(response.data.users);
        setUserFetching(false);
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
      setUserFetching(false);
    }
  };

  function getFormInitialValues(team = {}) {
    return {
      name: team.name ?? "",
      members: team.members ? team.members.map((member) => member.id) : [],
      status: team.status?.value ?? "active",
      search: "",
    };
  }

  function breadcrumbList() {
    let breadcrumbList = [];
    if (role === "admin") {
      breadcrumbList = [
        {
          id: t("admin.label.admin_label"),
          page: t("admin.label.admin_label"),
          pagePath: "/admin",
        },
      ];
    } else {
      breadcrumbList = [
        {
          id: "home",
          page: <img src={HomeIcon} alt="" />,
          pagePath: "/",
        },
      ];
    }
    breadcrumbList = [
      ...breadcrumbList,
      {
        id: t("sidebar.label.courses"),
        page: t("sidebar.label.courses"),
        pagePath: `/${role}/courses`,
      },
      {
        id: t("Courseoutline"),
        page: t("Courseoutline"),
        pagePath: `/${role}/course/${courseId}`,
      },
      {
        id: t("coworking_spaces"),
        page: t("coworking_spaces"),
        pagePath: `/${role}/course/${courseId}/teams`,
      },
    ];
    if (id) {
      breadcrumbList.push({
        id: id,
        page: team.name,
        active: true,
      });
    } else {
      breadcrumbList.push({
        id: t("add_coworking_space"),
        page: t("add_coworking_space"),
        active: true,
      });
    }
    return breadcrumbList;
  }

  return (
    <>
      <div className="container-fluid">
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {isLoading ? (
          <div
            style={{
              height: "65vh",
              width: "100%",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 sm:tw-px-2 tw-py-8">
                {/*TODO*/}
                <Breadcrumb list={breadcrumbList()} />
              </div>
            </div>
            <div className={`tw-mb-4`}>
              <Formik
                ref={formikRef}
                initialValues={getFormInitialValues(team)}
                onSubmit={async (values, { setErrors }) => {
                  // payload preperation section
                  setIsUpdating(true);
                  var data = {};

                  data.name = values.name;

                  data.members = values.members;

                  data.status = values.status;

                  try {
                    var response;
                    if (!id) {
                      response = await addTeam(courseId, data);
                      setIsUpdating(false);
                      if (response.data.status) {
                        setErrors({});
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("trainer.quiz.added_successfully")}
                          </span>,
                          {
                            autoClose: 3000,
                          }
                        );
                      } else {
                        throw response;
                      }
                    } else {
                      response = await editTeam(courseId, id, data);
                      setIsUpdating(false);
                      if (response.data.status) {
                        setErrors({});
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("trainer.quiz.edited_successfully")}
                          </span>,
                          {
                            autoClose: 3000,
                          }
                        );
                      } else {
                        throw response;
                      }
                    }
                    if (history.length !== 1) history.goBack();
                    else history.push(`/${role}/course/${courseId}/teams`);
                  } catch (err) {
                    console.error(err);
                    setIsUpdating(false);
                    if (err.data?.errors) {
                      function mapError(err) {
                        if (err[0] && typeof err[0] === "string") return err[0];
                        else {
                          Object.keys(err).forEach(function (key, index) {
                            err[key] = mapError(err[key]);
                          });
                          return err;
                        }
                      }
                      const errors = mapError(err.data.errors);
                      setErrors(errors);
                    }
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {err.data?.msg ?? "Failure in service"}
                      </span>
                    );
                  }
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!values.name) {
                    errors.name = t("crud.errors.required");
                  } else if (values.name.length > 180) {
                    errors.name = t("The field text is too long");
                  }

                  if (!values.members.length) {
                    errors.members = t("crud.errors.required");
                  }
                  console.log(errors)
                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({
                  setFieldValue,
                  setValues,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
                        <div className="tw-flex tw-items-center tw-justify-between">
                          <div className="tw-text-teal-500 tw-text-lg sm:tw-text-2xl">
                            {id
                              ? t("edit_coworking_space")
                              : t("add_coworking_space")}
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              setFieldValue(
                                "status",
                                !values.status == "active"
                                  ? "inactive"
                                  : "active"
                              );
                            }}
                            className={`tw-relative tw-w-20 tw-py-2 tw-px-1 tw-rounded-full tw-flex tw-items-center tw-justify-between tw-transition-all ${
                              values.status == "active"
                                ? "tw-bg-teal-800 tw-text-white tw-pl-7"
                                : "tw-bg-gray-200 !tw-text-gray-700 tw-pr-7"
                            }`}
                          >
                            <div className="tw-text-xs tw-white-space-nowrap tw-grow tw-text-center">
                              {t(
                                !values.status == "active"
                                  ? "Exhibition.inactive"
                                  : "Exhibition.active"
                              )}
                            </div>
                            <div
                              className={`tw-absolute tw-top-1/2 -tw-translate-y-1/2 ${
                                values.status == "active"
                                  ? "tw-left-2"
                                  : "tw-right-2"
                              } tw-h-5 tw-w-5 tw-bg-white tw-shadow tw-rounded-full`}
                            ></div>
                          </button>
                        </div>
                        <div className="tw-space-y-4">
                          <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                            <div>
                              <label className="tw-block mb-0">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("name")}
                                </div>

                                <Field
                                  name="name"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-3 tw-text-gray-500"
                                  placeholder={t("name")}
                                  maxLength={180}
                                />
                              </label>
                              <ErrorMessage
                                name={`name`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>
                          </div>

                          <div>
                            <div className="tw-flex tw-items-center tw-space-s-4 tw-mb-2">
                              <div className="tw-text-gray-600 tw-font-medium">
                                {t("sponsor.traineeName")}
                              </div>
                            </div>
                            <FieldArray name="members">
                              {({ insert, remove, push }) => (
                                <div className="tw-border tw-rounded tw-divide-y">
                                  <div className="tw-bg-gray-100 tw-p-4 md:tw-flex tw-items-center tw-justify-between">
                                    <div className="tw-flex tw-items-center tw-space-s-4">
                                      <div className="tw-font-semibold tw-text-gray-500">
                                        {t("select_names")}
                                      </div>
                                    </div>
                                    <div className="tw-flex tw-items-center tw-space-s-8">
                                      <Field
                                        name="search"
                                        className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent"
                                        placeholder={t(
                                          "search_by_name_or_email"
                                        )}
                                        onChange={({ target: { value } }) => {
                                          setFieldValue("search", value);
                                          fetchUsers({
                                            ...values,
                                            search: value,
                                          });
                                        }}
                                      />
                                      <li className="fal fa-search tw-text-teal-500 tw-w-6"></li>
                                    </div>
                                  </div>
                                  <div className="tw-py-4 tw-pe-4">
                                    <div className="tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-max-h-[40vh] tw-pe-4">
                                      {userFetching ? (
                                        [...Array(5).keys()].map((i) => (
                                          <div
                                            key={i}
                                            className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
                                          >
                                            <div className="tw-flex tw-items-center tw-space-s-4">
                                              <div
                                                className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded tw-border-gray-300`}
                                              ></div>
                                              <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
                                            </div>
                                            <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
                                          </div>
                                        ))
                                      ) : trainees.length ? (
                                        trainees.map((user) => (
                                          <button
                                            key={user.id}
                                            type="button"
                                            onClick={() => {
                                              const userIndex =
                                                values.members?.findIndex(
                                                  (i) => i == user.id
                                                );
                                              if (
                                                userIndex != undefined &&
                                                userIndex >= 0
                                              )
                                                remove(userIndex);
                                              else push(user.id);
                                            }}
                                            className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full`}
                                          >
                                            <div className="tw-flex tw-items-center tw-space-s-4">
                                              <div
                                                className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded  ${
                                                  values.members.includes(
                                                    user.id
                                                  )
                                                    ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                                                    : "tw-border-gray-300"
                                                }`}
                                              ></div>
                                              <div>{user.name}</div>
                                            </div>
                                            <div>{user.email}</div>
                                          </button>
                                        ))
                                      ) : (
                                        <div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">
                                          {t("there_no_matching_users")}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </FieldArray>
                            <ErrorMessage
                              name={`members`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>
                        </div>
                        <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white disabled:tw-bg-teal-700/50"
                          >
                            {t("survey.buttons.save")}
                          </button>
                        </div>
                      </div>
                    </>
                  </form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
}
