import master from "../../../assets/image/mastercard.png";
import React from "react";
import {useTranslation} from "react-i18next";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe,} from "@stripe/react-stripe-js";
import {addProgramToCart, payCourse,} from "../../../Services/api/courses/courseProvider";
import {useHistory, useParams} from "react-router";

function StripeMethod(props) {
  require("./StripeMethod.css")
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { id, paymentId } = useParams();
  const history = useHistory();

  const handlePayment = async (event) => {
    event.preventDefault();
    const cardNumberElement = elements.getElement(CardNumberElement);
    try {
      let stripeResponse = await stripe.createToken(cardNumberElement);
      let addToCartResponse = await addProgramToCart(paymentId,props.paymentEntity.amount);
      if (addToCartResponse.status === 201 && addToCartResponse.data.status) {
        let paymentResponse = await payCourse(
            {
              cart_id: addToCartResponse.data.cart.id,
              payment_type: "stripe",
              token:stripeResponse.token.id
            }
        );
        history.push("/table-payment/"+id);
      }
    } catch (error) {
      alert("Cannot Pay");
      console.log(error);
      console.log("ERERERERE");
    }
  };

  return (
    <>
      <div className="">
        <div className="stripe_label">
          <img src={master} alt="" className="img_card_pay" />
          <div className="">
            <div className="top_text">{t("visa")}</div>
            <div className="bottom_text">{t("master")}</div>
          </div>
        </div>
      </div>
      <form onSubmit={handlePayment}>
        <div>
          <label htmlFor="cardName" className="form_label">
            {t("numcard")}
          </label>
          <CardNumberElement />
          <label htmlFor="cardName" className="form_label">
            {t("datecard")}
          </label>
          <CardExpiryElement />
          <label htmlFor="cardName" className="form_label">
            {t("vsscard")}
          </label>
          <CardCvcElement />
        </div>

        <div className="d-block payment_button">
          <input
            type="submit"
            className="stripe_btn"
            value={`${t("programs.values.pay_now")} : ${props.paymentEntity.amount} ${t("Rial")}`}
          />
        </div>
      </form>
    </>
  );
}

export default StripeMethod;
