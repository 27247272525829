import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/crose.svg";
import { useState } from "react";

export default function QuestionBank({ onClose, onSelect, bankQuestions }) {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const { t, i18n } = useTranslation();

  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-py-8 tw-h-full tw-w-full">
      <div
        className={`tw-bg-white tw-shadow tw-rounded tw-w-full tw-max-h-full md:tw-w-2/3 lg:tw-w-3/4 xl:tw-w-1/2 tw-divide-y inner-scrollbar`}
        style={{ overflowY: "overlay" }}
      >
        <div className="tw-flex tw-items-center tw-justify-between tw-px-8 tw-py-4">
          <div className="tw-text-xl tw-text-teal-700">
            {t("trainer.quiz.question_bank")}
          </div>
          <button type="button" onClick={onClose}>
            <CloseIcon className="tw-h-6 tw-w-6" />
          </button>
        </div>
        <div className="tw-px-8 tw-py-3">
          <div className="tw-mb-2 tw-space-y-4">
            {bankQuestions.map((question) => (
              <button
                type="button"
                onClick={() =>
                  setSelectedQuestions((questions) =>
                    questions.includes(question.id)
                      ? questions.filter((item) => item != question.id)
                      : [...questions, question.id]
                  )
                }
                className="tw-w-full tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-flex tw-space-s-2 tw-items-center"
              >
                <div className="tw-w-3 tw-h-3 tw-border tw-border-gray-300 tw-rounded tw-flex tw-items-center tw-justify-center">
                  {selectedQuestions.includes(question.id) ? (
                    <div className="tw-w-2 tw-h-2 tw-rounded-sm tw-bg-teal-400"></div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2 tw-text-gray-400">
                  <div
                    dangerouslySetInnerHTML={{ __html: question.title }}
                  ></div>
                  <div>({question.type?.name})</div>
                </div>
              </button>
            ))}
          </div>

          <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
            <button
              type="button"
              onClick={() => onSelect(selectedQuestions)}
              className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
            >
              {t("ok")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
