import { useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../../../assets/icons/home.svg";
import { useEffect } from "react";
import { getQuestionType } from "../QuestionTypes";
import { getResponseSurvey } from "../../../../Services/api/survey/SurveyProvider";
import { Formik } from "formik";

export default function SurveyResponse({ isTrainer, fromTrainer = false }) {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState({});
  const { id, responseId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    getResponseSurvey(responseId)
      .then((res) => {
        // const r = {
        //   ...res.data.response,
        //   answers: res.data.response.answers.map((answer) => ({
        //     value: answer.value,
        //     option: answer.option_id,
        //     item: answer.item_id,
        //     question_id: answer.question_id,
        //   })),
        // };
        console.log(res.data.response);
        setResponse(res.data.response);
        setIsLoading(false);
      })
      .catch((err) => {});
  }, []);

  const breadcrumbList = [
    isTrainer
      ? {
          id: "home",
          page: <img src={HomeIcon} alt="" />,
          pagePath: "/",
        }
      : {
          id: t("admin.label.admin_label"),
          page: t("admin.label.admin_label"),
          pagePath: "/admin",
        },
    {
      id: t("survey.title"),
      page: t("survey.title"),
      pagePath: isTrainer ? "/trainer/survey/list" : `/admin/survey${fromTrainer ? '/trainer' :''}/list`,
    },
    {
      id: id,
      page: response.survey?.name,
      pagePath: isTrainer
        ? `/trainer/survey/preview/${id}`
        : `/admin/survey${fromTrainer ? '/trainer' :''}/preview/${id}`,
    },
    {
      id: t("participants"),
      page: t("participants"),
      pagePath: isTrainer
        ? `/trainer/survey/${id}/participants`
        : `/admin/survey${fromTrainer ? '/trainer' :''}/${id}/participants`,
    },
    {
      id: 0,
      page: response.name ?? response.user?.name,
      active: true,
    },
  ];

  return (
    <>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isLoading ? (
          <SkeletonCardOverlay skeletonWidth="100" />
        ) : (
          <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
            <div className="tw-text-teal-600 tw-font-bold tw-text-xl">
              {response.name ?? response.user?.name}
            </div>
            <div>
              <div className="tw-text-teal-500 tw-font-bold tw-mb-4">
                {t("answers")}
              </div>
              <Formik
                initialValues={{
                  questions: response.survey.questions.map((question) => ({
                    id: question.id,
                    answers: response.answers.filter(
                      (answer) => answer.question_id == question.id
                    ),
                  })),
                }}
                onSubmit={async (values) => {}}
                validate={(values) => {
                  const errors = {};
                  return errors;
                }}
                validateOnChange={false}
              >
                {({ values, errors, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="tw-space-y-4">
                      {response.survey.questions.map((question, index) => {
                        const questionType = getQuestionType(question.type);
                        return (
                          <div className="tw-border tw-rounded tw-p-4">
                            <div className="tw-text-lg tw-text-gray-400 tw-mb-4">
                              {`${question.name} (${t(
                                `survey_types.${question.type}`
                              )} - ${
                                question.is_required
                                  ? t("survey.required")
                                  : t("survey.optional")
                              })`}
                            </div>
                            {question.type == "single_file" ? (
                              <div className="tw-p-4 tw-flex tw-items-center tw-justify-center">
                                <a
                                  href={
                                    values.questions[index].answers[0]?.value
                                  }
                                  target="_blank"
                                  download
                                  className="tw-appearance-none tw-w-1/2 tw-text-center tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white hover:!tw-text-white hover:tw-bg-teal-600"
                                >
                                  {t("download")}
                                </a>
                              </div>
                            ) : question.type == "text" ||
                              question.type == "string" ? (
                              <div className="tw-whitespace-pre-line tw-px-4">
                                {values.questions[index].answers[0]?.value}
                              </div>
                            ) : (
                              <div className="tw-overflow-x-auto inner-scrollbar tw-pb-4">
                                <div className="tw-pointer-events-none">
                                  <questionType.responseComponent
                                    question={question}
                                    prefix={`questions.${index}.`}
                                    values={values.questions[index]}
                                    errors={errors}
                                    setFieldValue={(name, value) => {}}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
