import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { IconsTooltip } from "../../../Components";
import {
  getAllCourses,
  getCourseAnalysisById,
} from "../../../Services/api/Analysis/AnalysisProvider";
import numberOfRegistered from "../../../assets/image/analysis/number-of-registered.png";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import AnalysisCard from "./AnalysisCard";

function CourseAnalysis() {
  require("./Analysis.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [analysis, setAnalysis] = useState({});
  const [meetings, setMeetings] = useState([]);
  const [quizes, setQuizes] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (courseId !== null) {
      getCourseAnalysisById(courseId)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setIsLoading(false);
            setAnalysis(res.data.response);
            setMeetings(res.data.response.course_meetings);
            setQuizes(res.data.response.quiz_list);
          }
        })
        .catch((err) => {});
    }
  }, [courseId]);

  useEffect(() => {
    getAllCourses()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCourse(res.data.courses);
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="admin_label">
            <NavLink to="/admin">{t("Home")}</NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("sidebar.label.courseAnalysis")}
          </div>
          <>
            <div className="counter_analysis">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  width: "100%",
                  gap: "50px",
                }}
              >
                <div className="select_analysis" style={{ width: "100%" }}>
                  <div className="select_label">{t("analysis.allCourse")}</div>
                  <Select
                    name="couese"
                    id="couese"
                    options={course}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isClearable={true}
                    onChange={(item) => {
                      setCourseId(item.id);
                    }}
                    placeholder={t("analysis.all")}
                  />
                </div>
              </div>
              {courseId == null ? (
                <h5
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "70px 0",
                  }}
                >
                  {t("analysis.courseError")}
                </h5>
              ) : (
                <>
                  {isLoading ? (
                    <div
                      style={{
                        height: "25vh",
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      <SkeletonCard />
                    </div>
                  ) : (
                    <div className="counter_analysis_grid_top"></div>
                  )}
                  {isLoading ? (
                    <div
                      style={{
                        height: "25vh",
                        marginTop: "20px",
                      }}
                    >
                      <SkeletonCard />
                    </div>
                  ) : (
                    <>
                      <>
                        <div>
                          <div className="counter_analysis_grid">
                            <AnalysisCard
                              img={numberOfRegistered}
                              label={t("analysis.users_count_failed")}
                              counter={analysis.failed_trainees}
                            />
                            <AnalysisCard
                              img={numberOfRegistered}
                              label={t("analysis.users_count_passed")}
                              counter={analysis.passed_trainees}
                            />
                            <AnalysisCard
                              img={numberOfRegistered}
                              label={t("analysis.users_count_total")}
                              counter={analysis.total_trainees}
                            />
                          </div>
                          <div className="row">
                            <div
                              className="col-md-8"
                              style={{ paddingRight: 0 }}
                            >
                              <div
                                className="table-responsive tabel-analysis"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {t("analysis.meetings")}
                                </div>
                                <table
                                  className="table table-bordered"
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    border: "1px solid #dcdcdc",
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">
                                        {t("analysis.meetingName")}
                                      </th>
                                      <th scope="col">
                                        {t("analysis.attendance")}
                                      </th>
                                      <th scope="col">
                                        {t("analysis.absence")}
                                      </th>
                                      <th scope="col">{t("analysis.total")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {meetings.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.meeting_agenda}</td>
                                        <td>
                                          {item.attendance == null
                                            ? 0
                                            : item.attendance}
                                        </td>
                                        <td>
                                          {item.absence == null
                                            ? 0
                                            : item.absence}
                                        </td>
                                        <td>
                                          {item.total == null ? 0 : item.total}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div
                              className="col-md-4"
                              style={{ paddingLeft: 0 }}
                            >
                              <div
                                className="table-responsive tabel-analysis"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {t("analysis.quizes")}
                                </div>
                                <table
                                  className="table table-bordered"
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    border: "1px solid #dcdcdc",
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">
                                        {t("analysis.quizName")}
                                      </th>
                                      <th scope="col">{t("analysis.edit")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {quizes.map((item, index) => (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.title}</td>
                                        <td>
                                          <IconsTooltip
                                            title={t(
                                              "modal.survey.buttons.data"
                                            )}
                                            content={
                                              <i
                                                className="fal fa-eye"
                                                onClick={() => {
                                                  history.push({
                                                    pathname: `/admin/course-analysis/${courseId}/quiz-analysis/${item.id}`,
                                                  });
                                                }}
                                                style={{
                                                  marginLeft: 5,
                                                  marginRight: 5,
                                                  backgroundColor:
                                                    "transparent",
                                                  borderWidth: 0,
                                                  fontSize: 20,
                                                  color: "black",
                                                  cursor: "pointer",
                                                }}
                                              ></i>
                                            }
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default CourseAnalysis;
