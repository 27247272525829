import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

function DatePickerWithIcon(props) {
  const { t } = useTranslation();
  const [content, setContent] = useState("");

  const DatePickerHandleChange = (date) => {
    setContent(date);
    props.setContent(date);
  };

  useEffect(() => {
    if (props.initialValue != null) {
      setContent(Date.parse(props.initialValue));
    }
  });

  return (
    <div className="input_component_container">
      <div className="input_container">
        <DatePicker
          selected={content}
          onChange={(date) => DatePickerHandleChange(date)}
          className="admin_add_input"
          dateFormat={"yyyy-MM-dd"}
          placeholder={t("birth")}
          showYearDropdown
          maxDate={props.type == "survey" ? null : new Date()}
        />
        <div className="input_icon_container">{props.icon}</div>
      </div>
    </div>
  );
}

export default DatePickerWithIcon;
