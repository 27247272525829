import env from "./Environment";
import connector from "./Handler";
import URLBuilder from "./UrlBuilder";

export function addActivityRate(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_activity_rate, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function addNote(data, id, model) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.add_note, { model: model }), {
          notable_id: id,
          note: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function editNote(data, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.add_note, { model: id }), {
          _method: "PUT",
          note: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function DeleteNote(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.add_note, { model: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
