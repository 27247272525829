import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrainerById,
  resetTrainer,
} from "../../Redux/Actions/trainerProfile/trainerProfile.action";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import TrainersHeader from "./TrainersHeader/TrainersHeader";
import classes from "./trainers.module.css";
import TrainersCard from "./trainersCard/TrainerCard";

import SearchIcon from "../../assets/icons/SearchHome.svg";
import ClearIcon from "../../assets/icons/delete-gray.svg";
import { getSpecialties } from "../../Services/api/profile/profileProvider";
import { useTranslation } from "react-i18next";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";
import { useHistory } from "react-router-dom";
import HomeIcon from "../../assets/icons/Group 3.svg";
import MainBox from "../Shared/Components/MainBox/MainBox";
import { getAllTrainers } from "../../Services/api/HomeCoursesProvider";
import { Pagination } from "@mui/material";
import LoadingSpinner from "../Shared/Components/LoadingSpinner/LoadingSpinner";
function Trainers() {
  require("./trainers.css");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { get_trainer } = useSelector((state) => state.trainerProfileReducer);
  const [searchName, setSearchName] = useState("");
  const [trainers, setTrainers] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [meta, setMeta] = useState([]);
  const [loadMoreButton, setLoadMoreButton] = useState();
  const [specialties, setSpecialties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [trainersLoading, setTrainersLoading] = useState(true);
  // get_trainer

  const getfilterData = () => {
    const filter = { perPage: 12, page: currentPage };
    searchName && (filter.name = searchName);
    return filter;
  };

  useEffect(() => {
    setTrainersLoading(true);
    const apiCall = getAllTrainers;
    const filter = getfilterData();

    apiCall({ filter })
      .then((res) => {
        // console.log(res);
        if (res.status && res.status === 200) {
          setTrainers(res.data.response.data);
          setMeta(res.data.response.meta);
          setLoading(false);
          setTrainersLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [currentPage]);

  const handleSearch = (e) => {
    setSearchName(e.target.value);
  };
  const handleSubmit = (isrest) => {
    setTrainersLoading(true);
    const apiCall = getAllTrainers;
    const filter = getfilterData();
    if (isrest) {
      filter.name = "";
    }
    apiCall({ filter })
      .then((res) => {
        // console.log(res);
        if (res.status && res.status === 200) {
          setTrainers(res.data.response.data);
          setMeta(res.data.response.meta);
          setLoading(false);
          setTrainersLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {!loading ? (
        <div className="container-fluid" style={{ padding: "20px" }}>
          <div className="row" style={{ marginTop: "20px" }}>
            <div className={classes["title_search"]}>
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  flex: "2 1 auto",
                  alignItems: "center",
                }}
              >
                <img
                  src={HomeIcon}
                  alt=""
                  onClick={() => history.push("/")}
                  style={{ cursor: "pointer" }}
                />

                <p className={classes["title"]}>{t("Trainers")}</p>
              </div>
              <div className={classes["search_container"]}>
                <div className={classes["search-box"]}>
                  <img src={SearchIcon} alt="" />
                  <input
                    type="text"
                    placeholder={t("homeSearch")}
                    name="search"
                    value={searchName}
                    onChange={handleSearch}
                    style={{ width: "100%" }}
                  />
                  {searchName && (
                    <img
                      src={ClearIcon}
                      alt=""
                      onClick={() => {
                        setSearchName("");
                        handleSubmit(true);
                      }}
                    />
                  )}
                </div>
                <CustomButton
                  value={t("searchbtn")}
                  colors={"#025299"}
                  type={"button"}
                  action={() => handleSubmit()}
                  classes={classes.searchbtn}
                />
              </div>
            </div>
            <MainBox className="border-8" style={{ width: "100%" }}>
              {trainersLoading ? (
                <div className={classes["loading__container"]}>
                  <LoadingSpinner />
                </div>
              ) : (
                <div className={classes["courses_card_grid"]}>
                  <TrainersCard trainers={trainers} loading={loading} />
                </div>
              )}
              <Pagination
                count={meta?.totalPages || 0}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                page={currentPage}
                className="main-pagination"
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        </div>
      ) : (
        <SkeletonCardOverlay />
      )}
    </>
  );
}

export default Trainers;
