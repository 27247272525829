import { activityCommentListConstants } from "../../Constants/activityComment/activityComment.constant";

const initialState = {
  activity: [],
  activityCommentsList: [],
  activityCommentsListMeta: {},
  activityCommentsListLoading: false,
  activityCommentsListError: null,
  activityCommentsListSuccess: false,

  chapter: [],
  chapterCommentsList: [],
  chapterCommentsListLoading: false,
  chapterCommentsListError: null,
  chapterCommentsListSuccess: false,

  updateActivityCommentLoading: false,
  updateActivityCommentError: null,
  updateActivityCommentSuccess: false,

  banAllActivityCommentLoading: false,
  banAllActivityCommentError: null,
  banAllActivityCommentSuccess: false,

  publishAllActivityCommentLoading: false,
  publishAllActivityCommentError: null,
  publishAllActivityCommentSuccess: false,

  addActivityCommentLoading: false,
  addActivityCommentError: null,
  addActivityCommentSuccess: false,

  likeActivityCommentLoading: false,
  likeActivityCommentError: null,
  likeActivityCommentSuccess: false,

  unlikeActivityCommentLoading: false,
  unlikeActivityCommentError: null,
  unlikeActivityCommentSuccess: false,

  deleteActivityCommentLoading: false,
  deleteActivityCommentError: null,
  deleteActivityCommentSuccess: false,
};

const activityCommentsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityCommentListConstants.GET_ACTIVITYCOMMENTS_LIST_REQUEST:
      return {
        ...state,
        activityCommentsListLoading: true,
      };
    case activityCommentListConstants.GET_ACTIVITYCOMMENTS_LIST_SUCCESS:
      return {
        ...state,
        activityCommentsListLoading: false,
        activityCommentsListSuccess: true,
        activity: action.payload.activityComments,
        activityCommentsList: action.payload.activityComments.comments, ///////////
        // activityCommentsListMeta: action.payload.activityComments.meta, ///////////////
      };
    case activityCommentListConstants.GET_ACTIVITYCOMMENTS_LIST_REJECTED:
      return {
        ...state,
        activityCommentsListLoading: false,
        activityCommentsListError: action.payload,
        activityCommentsListSuccess: false,
      };

    // CHAPTER
    case activityCommentListConstants.GET_CHAPTERCOMMENTS_LIST_REQUEST:
      return {
        ...state,
        chapterCommentsListLoading: true,
      };
    case activityCommentListConstants.GET_CHAPTERCOMMENTS_LIST_SUCCESS:
      return {
        ...state,
        chapterCommentsListLoading: false,
        chapterCommentsListSuccess: true,
        chapter: action.payload.chapterComments,
        chapterCommentsList: action.payload.chapterComments?.comments, ///////////
        // activityCommentsListMeta: action.payload.activityComments.meta, ///////////////
      };
    case activityCommentListConstants.GET_CHAPTERCOMMENTS_LIST_REJECTED:
      return {
        ...state,
        chapterCommentsListLoading: false,
        chapterCommentsListError: action.payload,
        chapterCommentsListSuccess: false,
      };

    // DELETE ACTIVITYCOMMENT
    case activityCommentListConstants.DELETE_ACTIVITYCOMMENT_REQUEST:
      return {
        ...state,
        deleteActivityCommentLoading: true,
      };

    case activityCommentListConstants.DELETE_ACTIVITYCOMMENT_SUCCESS:
      return {
        ...state,
        deleteActivityCommentLoading: false,
        deleteActivityCommentSuccess: true,
        deleteActivityCommentError: null,

        activityCommentsList: action.payload.isReplay
          ? state.activityCommentsList.map((comment) =>
              comment.id === action.payload.parent_id
                ? {
                    ...comment,
                    replies: comment.replies.filter(
                      (replay) => replay.id !== action.payload.id
                    ),
                  }
                : comment
            )
          : state.activityCommentsList.filter(
              (activityComment) => activityComment.id !== action.payload.id
            ),
      };

    case activityCommentListConstants.DELETE_ACTIVITYCOMMENT_REJECTED:
      return {
        ...state,
        deleteActivityCommentLoading: false,
        deleteActivityCommentError: action.payload,
        deleteActivityCommentSuccess: false,
      };

    // UPDATE IS PUBLISHED ACTIVITYCOMMENT
    case activityCommentListConstants.UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_REQUEST:
      return {
        ...state,
        updateActivityCommentLoading: true,
      };

    case activityCommentListConstants.UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_SUCCESS:
      return {
        ...state,
        updateActivityCommentLoading: false,
        updateActivityCommentSuccess: true,
        updateActivityCommentError: null,

        activityCommentsList: action.payload.isReplay
          ? state.activityCommentsList.map((comment) => {
              if (comment.id === action.payload.parent_id) {
                return {
                  ...comment,
                  replies: comment.replies.map((replay) =>
                    replay.id === action.payload.id
                      ? {
                          ...replay,
                          is_published: !replay.is_published,
                          is_banned: false,
                        }
                      : { ...replay }
                  ),
                };
              }
              return comment;
            })
          : state.activityCommentsList.map((activityComment) => {
              if (activityComment.id === action.payload.id) {
                return {
                  ...activityComment,
                  is_published: !activityComment.is_published,
                  is_banned: false,
                };
              }
              return activityComment;
            }),
      };

    case activityCommentListConstants.UPDATE_IS_PUBLISHEDACTIVITYCOMMENT_REJECTED:
      return {
        ...state,
        updateActivityCommentLoading: false,
        updateActivityCommentError: action.payload,
        updateActivityCommentSuccess: false,
      };
    // UPDATE IS BANNed ACTIVITYCOMMENT
    case activityCommentListConstants.UPDATE_IS_BANNEDACTIVITYCOMMENT_REQUEST:
      return {
        ...state,
        updateActivityCommentLoading: true,
      };

    case activityCommentListConstants.UPDATE_IS_BANNEDACTIVITYCOMMENT_SUCCESS:
      return {
        ...state,
        updateActivityCommentLoading: false,
        updateActivityCommentSuccess: true,
        updateActivityCommentError: null,

        activityCommentsList: action.payload.isReplay
          ? state.activityCommentsList.map((comment) => {
              if (comment.id === action.payload.parent_id) {
                return {
                  ...comment,
                  replies: comment.replies.map((replay) =>
                    replay.id === action.payload.id
                      ? {
                          ...replay,
                          is_banned: !replay.is_banned,
                          is_published: false,
                        }
                      : { ...replay }
                  ),
                };
              }
              return comment;
            })
          : state.activityCommentsList.map((activityComment) => {
              if (activityComment.id === action.payload.id) {
                return {
                  ...activityComment,
                  is_banned: !activityComment.is_banned,
                  is_published: false,
                };
              }
              return activityComment;
            }),
      };

    case activityCommentListConstants.UPDATE_IS_BANNEDACTIVITYCOMMENT_REJECTED:
      return {
        ...state,
        updateActivityCommentLoading: false,
        updateActivityCommentError: action.payload,
        updateActivityCommentSuccess: false,
      };
    // DELETE CHAPTERCOMMENT
    case activityCommentListConstants.DELETE_CHAPTERCOMMENT_REQUEST:
      return {
        ...state,
        deleteActivityCommentLoading: true,
      };

    case activityCommentListConstants.DELETE_CHAPTERCOMMENT_SUCCESS:
      return {
        ...state,
        deleteActivityCommentLoading: false,
        deleteActivityCommentSuccess: true,
        deleteActivityCommentError: null,

        chapterCommentsList: action.payload.isReplay
          ? state.chapterCommentsList.map((comment) =>
              comment.id === action.payload.parent_id
                ? {
                    ...comment,
                    replies: comment.replies.filter(
                      (replay) => replay.id !== action.payload.id
                    ),
                  }
                : comment
            )
          : state.chapterCommentsList.filter(
              (activityComment) => activityComment.id !== action.payload.id
            ),
      };

    case activityCommentListConstants.DELETE_CHAPTERCOMMENT_REJECTED:
      return {
        ...state,
        deleteActivityCommentLoading: false,
        deleteActivityCommentError: action.payload,
        deleteActivityCommentSuccess: false,
      };

    // UPDATE IS PUBLISHED CHAPTERCOMMENT
    case activityCommentListConstants.UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_REQUEST:
      return {
        ...state,
        updateActivityCommentLoading: true,
      };

    case activityCommentListConstants.UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_SUCCESS:
      return {
        ...state,
        updateActivityCommentLoading: false,
        updateActivityCommentSuccess: true,
        updateActivityCommentError: null,

        chapterCommentsList: action.payload.isReplay
          ? state.chapterCommentsList.map((comment) => {
              if (comment.id === action.payload.parent_id) {
                return {
                  ...comment,
                  replies: comment.replies.map((replay) =>
                    replay.id === action.payload.id
                      ? {
                          ...replay,
                          is_published: !replay.is_published,
                          is_banned: false,
                        }
                      : { ...replay }
                  ),
                };
              }
              return comment;
            })
          : state.chapterCommentsList.map((activityComment) => {
              if (activityComment.id === action.payload.id) {
                return {
                  ...activityComment,
                  is_published: !activityComment.is_published,
                  is_banned: false,
                };
              }
              return activityComment;
            }),
      };

    case activityCommentListConstants.UPDATE_IS_PUBLISHEDCHAPTERCOMMENT_REJECTED:
      return {
        ...state,
        updateActivityCommentLoading: false,
        updateActivityCommentError: action.payload,
        updateActivityCommentSuccess: false,
      };
    // UPDATE IS BANNed CHAPTERCOMMENT
    case activityCommentListConstants.UPDATE_IS_BANNEDCHAPTERCOMMENT_REQUEST:
      return {
        ...state,
        updateActivityCommentLoading: true,
      };

    case activityCommentListConstants.UPDATE_IS_BANNEDCHAPTERCOMMENT_SUCCESS:
      return {
        ...state,
        updateActivityCommentLoading: false,
        updateActivityCommentSuccess: true,
        updateActivityCommentError: null,

        chapterCommentsList: action.payload.isReplay
          ? state.chapterCommentsList.map((comment) => {
              if (comment.id === action.payload.parent_id) {
                return {
                  ...comment,
                  replies: comment.replies.map((replay) =>
                    replay.id === action.payload.id
                      ? {
                          ...replay,
                          is_banned: !replay.is_banned,
                          is_published: false,
                        }
                      : { ...replay }
                  ),
                };
              }
              return comment;
            })
          : state.chapterCommentsList.map((activityComment) => {
              if (activityComment.id === action.payload.id) {
                return {
                  ...activityComment,
                  is_banned: !activityComment.is_banned,
                  is_published: false,
                };
              }
              return activityComment;
            }),
      };

    case activityCommentListConstants.UPDATE_IS_BANNEDCHAPTERCOMMENT_REJECTED:
      return {
        ...state,
        updateActivityCommentLoading: false,
        updateActivityCommentError: action.payload,
        updateActivityCommentSuccess: false,
      };

    // ADD ACTIVITYCOMMENT
    case activityCommentListConstants.ADD_ACTIVITYCOMMENT_REQUEST:
      return {
        ...state,
        addActivityCommentLoading: true,
      };

    case activityCommentListConstants.ADD_ACTIVITYCOMMENT_SUCCESS:
      return {
        ...state,
        addActivityCommentLoading: false,
        addActivityCommentSuccess: true,
        addActivityCommentError: null,

        // activityCommentsListLoading: false,
        // activityCommentsListSuccess: true,
        // activityCommentsList: action.payload.activityComments.data, ///////////
        // activityCommentsListMeta: action.payload.activityComments.meta, ////////////
      };

    case activityCommentListConstants.ADD_ACTIVITYCOMMENT_REJECTED:
      return {
        ...state,
        addActivityCommentLoading: false,
        addActivityCommentError: action.payload,
        addActivityCommentSuccess: false,
      };
    // LIKE ACTIVITYCOMMENT
    case activityCommentListConstants.LIKE_ACTIVITYCOMMENT_REQUEST:
      return {
        ...state,
        likeActivityCommentLoading: true,
      };

    case activityCommentListConstants.LIKE_ACTIVITYCOMMENT_SUCCESS:
      return {
        ...state,
        likeActivityCommentLoading: false,
        likeActivityCommentSuccess: true,
        likeActivityCommentError: null,

        activityCommentsList: state.activityCommentsList.map((comment) =>
          action.payload.id === comment.id
            ? {
                ...comment,
                is_liked: true,
                likes: comment.likes + 1,
              }
            : { ...comment }
        ),

        // activityCommentsListLoading: false,
        // activityCommentsListSuccess: true,
        // activityCommentsList: action.payload.activityComments.data, ///////////
        // activityCommentsListMeta: action.payload.activityComments.meta, ////////////
      };

    case activityCommentListConstants.LIKE_ACTIVITYCOMMENT_REJECTED:
      return {
        ...state,
        unlikeActivityCommentLoading: false,
        likeActivityCommentError: action.payload,
        likeActivityCommentSuccess: false,
      };
    // UNLIKE ACTIVITYCOMMENT
    case activityCommentListConstants.UNLIKE_ACTIVITYCOMMENT_REQUEST:
      return {
        ...state,
        unlikeActivityCommentLoading: true,
      };

    case activityCommentListConstants.UNLIKE_ACTIVITYCOMMENT_SUCCESS:
      return {
        ...state,
        unlikeActivityCommentLoading: false,
        unlikeActivityCommentSuccess: true,
        unlikeActivityCommentError: null,

        activityCommentsList: state.activityCommentsList.map((comment) =>
          action.payload.id === comment.id
            ? {
                ...comment,
                is_liked: false,
                likes: comment.likes - 1,
              }
            : { ...comment }
        ),
      };

    case activityCommentListConstants.UNLIKE_ACTIVITYCOMMENT_REJECTED:
      return {
        ...state,
        unlikeActivityCommentLoading: false,
        unlikeActivityCommentError: action.payload,
        unlikeActivityCommentSuccess: false,
      };
    // LIKE CHAPTERCOMMENT
    case activityCommentListConstants.LIKE_CHAPTERCOMMENT_REQUEST:
      return {
        ...state,
        likeActivityCommentLoading: true,
      };

    case activityCommentListConstants.LIKE_CHAPTERCOMMENT_SUCCESS:
      return {
        ...state,
        likeActivityCommentLoading: false,
        likeActivityCommentSuccess: true,
        likeActivityCommentError: null,

        chapterCommentsList: state.chapterCommentsList.map((comment) =>
          action.payload.id === comment.id
            ? {
                ...comment,
                is_liked: true,
                likes: comment.likes + 1,
              }
            : { ...comment }
        ),

        // activityCommentsListLoading: false,
        // activityCommentsListSuccess: true,
        // activityCommentsList: action.payload.activityComments.data, ///////////
        // activityCommentsListMeta: action.payload.activityComments.meta, ////////////
      };

    case activityCommentListConstants.LIKE_CHAPTERCOMMENT_REJECTED:
      return {
        ...state,
        unlikeActivityCommentLoading: false,
        likeActivityCommentError: action.payload,
        likeActivityCommentSuccess: false,
      };
    // UNLIKE CHAPTERCOMMENT
    case activityCommentListConstants.UNLIKE_CHAPTERCOMMENT_REQUEST:
      return {
        ...state,
        unlikeActivityCommentLoading: true,
      };

    case activityCommentListConstants.UNLIKE_CHAPTERCOMMENT_SUCCESS:
      return {
        ...state,
        unlikeActivityCommentLoading: false,
        unlikeActivityCommentSuccess: true,
        unlikeActivityCommentError: null,

        chapterCommentsList: state.chapterCommentsList.map((comment) =>
          action.payload.id === comment.id
            ? {
                ...comment,
                is_liked: false,
                likes: comment.likes - 1,
              }
            : { ...comment }
        ),
      };

    case activityCommentListConstants.UNLIKE_CHAPTERCOMMENT_REJECTED:
      return {
        ...state,
        unlikeActivityCommentLoading: false,
        unlikeActivityCommentError: action.payload,
        unlikeActivityCommentSuccess: false,
      };
    // PUBLISH ALL ACTIVITYCOMMENT
    case activityCommentListConstants.PUBLISH_ALL_ACTIVITYCOMMENT_REQUEST:
      return {
        ...state,
        publishAllActivityCommentLoading: true,
      };

    case activityCommentListConstants.PUBLISH_ALL_ACTIVITYCOMMENT_SUCCESS:
      return {
        ...state,
        publishAllActivityCommentLoading: false,
        publishAllActivityCommentSuccess: true,

        ...(action.payload.isChapter
          ? {
              chapterCommentsList: state.chapterCommentsList.map(
                (activityComment) => {
                  return {
                    ...activityComment,
                    is_published: true,
                    is_banned: false,
                  };
                }
              ),
            }
          : {
              activityCommentsList: state.activityCommentsList.map(
                (activityComment) => {
                  return {
                    ...activityComment,
                    is_published: true,
                    is_banned: false,
                  };
                }
              ),
            }),

        // activityCommentsListLoading: false,
        // activityCommentsListSuccess: true,
        // activityCommentsList: action.payload.activityComments.data, ///////////
        // activityCommentsListMeta: action.payload.activityComments.meta, ////////////
      };

    case activityCommentListConstants.PUBLISH_ALL_ACTIVITYCOMMENT_REJECTED:
      return {
        ...state,
        publishAllActivityCommentLoading: false,
        publishAllActivityCommentError: action.payload,
        publishAllActivityCommentSuccess: false,
      };
    // BAN ALL ACTIVITYCOMMENT
    case activityCommentListConstants.BAN_ALL_ACTIVITYCOMMENT_REQUEST:
      return {
        ...state,
        banAllActivityCommentLoading: true,
      };

    case activityCommentListConstants.BAN_ALL_ACTIVITYCOMMENT_SUCCESS:
      console.log(action.payload.isChapter);
      return {
        ...state,
        banAllActivityCommentLoading: false,
        banAllActivityCommentSuccess: true,
        ...(action.payload.isChapter
          ? {
              chapterCommentsList: state.chapterCommentsList.map(
                (activityComment) => {
                  return {
                    ...activityComment,
                    is_published: false,
                    is_banned: true,
                  };
                }
              ),
            }
          : {
              activityCommentsList: state.activityCommentsList.map(
                (activityComment) => {
                  return {
                    ...activityComment,
                    is_published: false,
                    is_banned: true,
                  };
                }
              ),
            }),

        // activityCommentsListLoading: false,
        // activityCommentsListSuccess: true,
        // activityCommentsList: action.payload.activityComments.data, ///////////
        // activityCommentsListMeta: action.payload.activityComments.meta, ////////////
      };

    case activityCommentListConstants.BAN_ALL_ACTIVITYCOMMENT_REJECTED:
      return {
        ...state,
        banAllActivityCommentLoading: false,
        banAllActivityCommentError: action.payload,
        banAllActivityCommentSuccess: false,
      };

    default:
      return state;
  }
};

export default activityCommentsListReducer;
