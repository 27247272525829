import { Pagination } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { fetchProgramPaymentList } from "../../../../Redux/Actions/programPaymentList/programPaymentList.action";
import { exportProgramPayment } from "../../../../Services/api/Roles/RolesProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
// import EditRole from "../../Roles/Edit";
import { filterActions } from "./ProgramPaymentListActions";
import classes from "./ProgramPaymentList.module.css";

import ProgramPaymentListTable from "./ProgramPaymentListTable";
import ProgramPaymentListFilter from "./ProgramPaymrntListFilter";

const ProgramPaymentList = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const { programPaymentListMeta } = useSelector(
    (state) => state.programPaymentListReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [currentProgramPaymentFilter, setCurrentProgramPaymentFilter] =
    useState({});

  const [dataToExport, setDataToExport] = useState([]);

  const [filterData, setFilterData] = useState({
    program_name: "",
    payment_date: "",
    end_date: "",
  });

  const resetFilterHandler = () => {
    setFilterData({
      program_name: "",
      payment_date: "",
      end_date: "",
    });
    setSortColumn("");
    setSortOrder("");
    setIsResetApplied(true);
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.program_name && (filter.program_name = filterData.program_name);
    filterData.payment_date && (filter.payment_date = filterData.payment_date);
    filterData.end_date && (filter.end_date = filterData.end_date);

    return filter;
  };

  const getprogrampaymentListHandler = () => {
    const filter = getFilterData();
    setDataToExport(filter);

    dispatch(
      fetchProgramPaymentList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
    setCurrentProgramPaymentFilter(filter);
  };

  useEffect(() => {
    getprogrampaymentListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, sortColumn, sortOrder]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "sponser",
      page: t("financial.label.programPayment"),
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportProgramPayment(
        currentProgramPaymentFilter,
        type,
        false,
        true
      );
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["system-users-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["system-users-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("financial.label.programPayment")}
                  actions={filterActions({
                    showFilter: showFilterHandler,

                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <ProgramPaymentListFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getprogrampaymentListHandler}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}

                <ProgramPaymentListTable sortAction={sortActionHandler} />
                <Pagination
                  count={programPaymentListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramPaymentList;
