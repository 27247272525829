import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  ListTypes,
  sessionPreview,
} from "../../../../../Services/api/Zoom/ZoomProvider";
import ZoomSessionItem from "./ZoomSessionItem";

export default function AddZoomSession({
  typesZoom,
  meeting,
  openSessionCreationModal,
  isAdmin,
  isTrainer,
  isManager,
  isPartner,
}) {
  require("./addzoomsession.css");
  const { id } = useParams();
  // const [dataPreview, setDataPreview] = useState([]);
  // const [typesZoom, setTypesZoom] = useState([]);

  // async function executeOnLoad() {
  //   let response = await sessionPreview(id);
  //   if (response.status && response.status === 200) {
  //     setDataPreview(response.data.meetings);
  //   }
  //   let response_types = await ListTypes();
  //   if (response_types.status && response_types.status === 200) {
  //     setTypesZoom(response_types.data.data);
  //   }
  // }
  return (
    <div className={"tajah-lesson-zoomSession-list-canvas"}>
      <ZoomSessionItem
        meeting={meeting}
        typesZoom={typesZoom}
        openSessionCreationModal={openSessionCreationModal}
        isAdmin={isAdmin}
        isTrainer={isTrainer}
        isManager={isManager}
        isPartner={isPartner}
      />
    </div>
  );
}
