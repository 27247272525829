import {useState} from "react";
import {useHistory} from "react-router-dom";

function VerifyEmail(props) {
  require("./verifyEmail.css");
  const [verify, setVerify] = useState(false);
  const history = useHistory();
  //  const location = useLocation();

  // useEffect(() => {
  //     const token =history.location.pathname.split('/');
  //     console.log('kkkk',token[token.length-1])
  //     const res = verifyEmail(token[token.length-1]);
  //     console.log('handle',res)
  //     if(res){
  //         setVerify(true);
  //         history.push('/login');
  //     }else{
  //         setVerify(false);
  //         console.log(res);
  //     }
  // },[]) ;

  return (
    <div>
      {verify && <span className="textStyle">success verify your email</span>}
    </div>
  );
}

export default VerifyEmail;
