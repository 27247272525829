import env from "../Environment";
import { exportApi } from "../Essential";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getCourseObjectives(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_objectives, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCourseTrainees(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_trainees, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getLessonObjectives(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_objectives, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getExamData(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_exam, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportExamData(type, id, params) {
  return exportApi(
    type,
    URLBuilder(env.get_exam, { id: id }),
    params,
    "quiz-questions"
  );
}

export function getQuestion(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_question, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getQuestionTypes() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_question_types)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getExams(quizable_type, quizable_id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.get_exams, {
            quizable_type: quizable_type,
            quizable_id: quizable_id,
          })
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getQuizParticipants(quizId, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.get_quiz_participants, {
            quiz_id: quizId,
          }),
          {
            params: params,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportQuizParticipants(type, quizId, params) {
  return exportApi(
    type,
    URLBuilder(env.get_quiz_participants, {
      quiz_id: quizId,
    }),
    params,
    "participants"
  );
}

export function getAssessments(courseId, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.get_assessments, {
            course_id: courseId,
          }),
          {
            params: params,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportAssessments(type, courseId, filter) {
  return exportApi(
    type,
    URLBuilder(env.get_assessments, {
      course_id: courseId,
    }),
    filter,
    "assessments"
  );
}

export function toggleQuizActivity(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .put(URLBuilder(env.quiz_status_toggle, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportQuizes(quizable_type, quizable_id, filter) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.get_exams, {
            quizable_type: quizable_type,
            quizable_id: quizable_id,
          }),
          {
            responseType: "blob",
            params: {
              export: true,
            },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "exams" + ".xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function launchQuestionUrl(id, registration) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.launch_question, {
            id: id,
          }),
          {
            params: {
              registration: registration,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function launchacctivityUrl(id, registration, answer = false) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.launch_content, {
            id: id,
          }),
          {
            params: {
              registration: registration,
              answer: answer ? 1 : 0
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getQuestionBankItems(id, payload = {}) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_question_bank_items, { id: id }), {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportQuestionsFromQuestionBank(type, id, params = {}) {
  return exportApi(
    type,
    URLBuilder(env.get_question_bank_items, {
      id: id,
    }),
    params,
    "questions"
  );
}

export function exportQuiz(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector.post(URLBuilder(env.export_quiz, { id: id })).then(
        (response) => {
          const url = window.URL.createObjectURL(
            new Blob([JSON.stringify(response.data)])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "quiz" + ".json"); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportQuizXML(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.export_quiz_xml, { id: id }), {
          responseType: "blob",
        })
        .then(
          (response) => {
            var reader = new FileReader();
            reader.addEventListener("loadend", function () {
              console.log(reader.result);
            });

            reader.readAsText(response.data);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "quiz" + ".zip"); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteExam(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.delete_exam, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function sendMarks(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.send_marks, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteQuestionFromQuestionBank(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_question_to_question_bank, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function statusToggleQuestionFromQuestionBank(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.status_question_to_question_bank, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editQuestionToQuestionBank(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_question_to_question_bank, { id: id }), {
          ...payload,
          _method: "put",
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addQuestionToQuestionBank(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_question_to_question_bank, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function importExam(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.import_quiz, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editExamXML(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_quiz_xml, { id: id }), payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function importExamXML(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.import_quiz_xml, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportQuestionBank(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.export_question_bank, { id: id }), {
          responseType: "blob",
        })
        .then(
          (response) => {
            var reader = new FileReader();
            reader.addEventListener("loadend", function () {
              console.log(reader.result);
            });

            reader.readAsText(response.data);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "question-bank" + ".zip"); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function importQuestionBank(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.import_question_bank, { id: id }), payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function importQuestionToQuestionBank(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.import_question_to_question_bank, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addFileToQuestionBank(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_file_to_question_bank, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addAttachementFileToQuestion(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_attachement_file_to_question, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addQuiz(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_exam, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editQuiz(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_exam, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getExamGrades(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_exam_grades, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function setQuestionGrade(examId, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(
          URLBuilder(env.set_exam_grade, {
            id: examId,
          }),
          payload
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTraineeExamAnswers(examId, traineeId, responseId) {
  let link = URLBuilder(env.get_trainee_exam_answers, {
    examId: examId,
    traineeId: traineeId,
  });
  link += `?response=${responseId}`;
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(link)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportTraineeExamAnswers(
  type,
  examId,
  traineeId = 0,
  responseId
) {
  let link = URLBuilder(env.get_trainee_exam_answers, {
    examId: examId,
    traineeId: traineeId,
  });
  if (responseId) link += `?response=${responseId}`;

  return exportApi(type, link, {}, "responses");
}

export function getQuizStatement(courseId, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.get_course_trainees_quiz, {
            courseId: courseId,
          }),
          {
            params: params,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportCourseTraineesQuiz(type, courseId, params = {}) {
  return exportApi(
    type,
    URLBuilder(env.get_course_trainees_quiz, {
      courseId: courseId,
    }),
    params,
    "course-quiz"
  );
}

export function getExamQuestions(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_exam_questions, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function orderExamQuestions(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.order_exam_questions, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
