import { Pagination } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchCourseDetails } from "../../Redux/Actions/courses/coursesList.action";
import { exportPeyment } from "../../Services/api/Payments";
import { AuthContext } from "../../Services/api/auth/AuthContext";
import { getPayments } from "../../Services/api/toutorials/PaymentsProvaider";
import HomeIcon from "../../assets/icons/home.svg";
import Breadcrumb from "../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../Shared/Components/MainBoxHead/MainBoxHead";
import PaymentDetailsModal from "../Shared/Components/PaymentDetailsModal/PaymentDetailsModal";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import PaymentsFilter from "./PaymentsFilter";
import PaymentsTable from "./PaymentsTable";
import { filterActions } from "./PaymentsTableActions";
import classes from "./payments.module.css";

const Payments = ({ isProgram, type }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const role = location?.state?.role
    ? location?.state?.role
    : roles.includes("admin")
    ? "admin"
    : roles.includes("manager")
    ? "manager"
    : "partner";

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentsMeta, setPaymentsMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [currentPaymentsFilter, setCurrentPaymentsFilter] = useState({});

  const [filterData, setFilterData] = useState({
    payer_name: "",
    payer_email: "",
    payment_method: "",
    payable_type:
      type === "course"
        ? "course"
        : type === "program"
        ? "program_payment"
        : "",
    role: "",
    payable_name: "",
    payable_id: id || "",
    payable_serial_number: "",
    payable_code: "",
    id_number: "",
    status: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "payments",
      page:
        type === "financial"
          ? t("sidebar.label.financial_movement")
          : t("payments.title"),
      active: true,
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      payer_name: "",
      payer_email: "",
      payment_method: "",
      payable_type:
        type === "course"
          ? "course"
          : type === "program"
          ? "program_payment"
          : "",
      role: "",
      payable_name: "",
      payable_id: id || "",
      payable_serial_number: "",
      payable_code: "",
      id_number: "",
      status: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.payer_name && (filter.payer_name = filterData.payer_name);
    filterData.payer_email && (filter.payer_email = filterData.payer_email);
    filterData.payment_method &&
      (filter.payment_method = filterData.payment_method);
    filterData.role && (filter.role = filterData.role);
    filterData.payable_serial_number &&
      (filter.payable_serial_number = filterData.payable_serial_number);
    filterData.payable_code && (filter.payable_code = filterData.payable_code);
    filterData.payable_name && (filter.payable_name = filterData.payable_name);
    filterData.id_number && (filter.id_number = filterData.id_number);
    type === "financial" &&
      filterData.status &&
      (filter.status = filterData.status);

    type === "program" && (filter.program_id = id);
    type === "course" && (filter.course_id = id);
    type === "financial" &&
      filterData.payable_id &&
      (filter.payable_id = filterData.payable_id);

    type === "course" && (filter.payable_type = "course");
    type === "program" && (filter.payable_type = "program_payment");
    type === "financial" &&
      filterData.payable_type &&
      (filter.payable_type = filterData.payable_type);

    type !== "financial" &&
      (filter.status =
        activeTab === 1
          ? "Waiting"
          : activeTab === 2
          ? "Approved"
          : activeTab === 3
          ? "Rejected"
          : "");
    return filter;
  };

  const getPaymentsAction = () => {
    setLoading(true);
    const filter = getFilterData();

    getPayments({ page: currentPage, perPage: 10, filter })
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          console.log(res.data);
          setPayments(res.data.data.payments);
          setPaymentsMeta(res.data.data.meta);
          setLoading(false);
        }
        setCurrentPaymentsFilter(filter);
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getPaymentsAction();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportPeyment(currentPaymentsFilter, type, id);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["departments-trainees"]}>
      <div className="container-fluid">
        {loading && <SkeletonCardOverlay skeletonWidth="100" />}
        <div className={classes["departments-trainees__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={
                    type === "financial"
                      ? t("sidebar.label.financial_movement")
                      : t("payments.title")
                  }
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                    ...(type === "financial"
                      ? {
                          pushToBankTransfers: () => {
                            history.push(
                              `/${
                                localStorage.getItem("type") === "accountant"
                                  ? "accountant"
                                  : localStorage.getItem("type") ===
                                    "group-admin"
                                  ? "group-admin"
                                  : localStorage.getItem("type") === "manager"
                                  ? "manager"
                                  : "admin"
                              }/bank-transfers`
                            );
                          },
                          pushToUserWallet: () => {
                            history.push(
                              `/${
                                localStorage.getItem("type") === "accountant"
                                  ? "accountant"
                                  : localStorage.getItem("type") ===
                                    "group-admin"
                                  ? "group-admin"
                                  : localStorage.getItem("type") === "manager"
                                  ? "manager"
                                  : "admin"
                              }/users-wallet`
                            );
                          },
                          // openFinancialFund: () => {
                          //   history.push(
                          //     `/${localStorage.getItem("type") === "accountant" ? "accountant" : "admin"}/fund`
                          //   );
                          // },
                        }
                      : {}),
                    isAdmin: authContext.inAdminPanel,
                  })}
                />
                {showFilter && (
                  <PaymentsFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getPaymentsAction}
                    resetFilterHandler={resetFilterHandler}
                    role={role}
                    type={type}
                  />
                )}

                <PaymentsTable
                  resetFilterHandler={resetFilterHandler}
                  payments={payments}
                  role={role}
                  getPayments={getPaymentsAction}
                  type={type}
                  loading={loading}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  currentPage={currentPage}
                />
                <Pagination
                  count={paymentsMeta.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
              {showPaymentModal && (
                <PaymentDetailsModal
                  closeModal={() => {
                    setShowPaymentModal(false);
                  }}
                  // approveAction={approveActionHandler}
                  // rejectAction={rejectActionHandler}
                  // approveLoading={approvePaymentByBoothLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
