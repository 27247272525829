import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { getlessonObjectives, saveObjectLesson } from "../../Services/api/EduGoals/EduGoalsProvider";
import { getCourseObjectives } from "../../Services/api/exams/ExamsProvider";
import { ReactComponent as CloseIcon } from "../../assets/icons/crose.svg";

export default function EduGoalsModal({ courseId, lessonId, refuse, accept, role }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [lessonObjective, setLessonObjective] = useState([]);

  useEffect(() => {
    getListOfEdu();
  }, []);

  const getListOfEdu = async () => {
    try {
      let response = await getlessonObjectives(lessonId);
      setLessonObjective(response.data.objectives);
      setIsLoading(false);
    } catch (err) {
      toast.error(
        <span
          style={{
            fontSize: 13,
            fontWeight: "bold",
            display: "block",
            paddingBottom: "10px",
          }}
        >
          {err.response.data.msg}
        </span>
      );
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      getCourseObjectives(courseId, inputValue).then((res) => {
        resolve(res.data.data.objectives.data);
      });
    });

  return (
    <div
      className={`tw-bg-white tw-shadow tw-rounded tw-w-full tw-max-h-full tw-divide-y inner-scrollbar`}
      style={{ overflowY: "overlay" }}
    >
      <div className="tw-flex tw-items-center tw-justify-between tw-px-8 tw-py-4">
        <div className="tw-text-xl tw-text-teal-700">{t("trainer.quiz.crud.objectives")}</div>
        <button type="button" onClick={refuse}>
          <CloseIcon className="tw-h-6 tw-w-6" />
        </button>
      </div>
      <div className="tw-px-8 tw-py-4">
        {isLoading ? (
          <> </>
        ) : (
          <Formik
            initialValues={{
              objectives: lessonObjective,
            }}
            onSubmit={async (values) => {
              try {
                let Payload = {
                  objectives: values.objectives.map((obj) => obj.id),
                  lesson_id: lessonId,
                };
                let response = await saveObjectLesson(Payload);
                if (response.status === 200) {
                  toast.success(
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "bold",
                        display: "block",
                        paddingBottom: "10px",
                      }}
                    >
                      {response.data.msg}
                    </span>
                  );
                  accept();
                }
              } catch (err) {
                toast.error(
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      display: "block",
                      paddingBottom: "10px",
                    }}
                  >
                    {err.response.data.msg}
                  </span>
                );
              }
            }}
            validate={(values) => {
              setHasSubmitted(true);

              let errors = {};

              if (Object.keys(errors).length) {
                console.log({ values, errors });
              }
              return errors;
            }}
            validateOnChange={hasSubmitted}
          >
            {({
              setFieldValue,
              setValues,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="tw-space-y-8">
                  <div className="tw-space-y-3">
                    {/* objectives */}
                    <div>
                      <label className="tw-block">
                        {/* <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("trainer.quiz.crud.objectives")}
                        </div> */}
                        <AsyncSelect
                          placeholder={t("trainer.quiz.crud.select.objectives")}
                          cacheOptions
                          defaultOptions
                          isClearable
                          defaultValue={values.objectives}
                          loadOptions={promiseOptions}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isMulti
                          onChange={(items) => {
                            console.log(items);
                            setFieldValue("objectives", items);
                          }}
                          isDisabled={role === "partner" ? true : false}
                        />
                      </label>

                      <ErrorMessage name={`objectives`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
                    </div>
                  </div>
                  {role !== "partner" ? (
                    <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-py-2 tw-px-16 tw-rounded tw-text-white"
                      >
                        {t("ok")}
                      </button>
                    </div>
                  ) : null}
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}
