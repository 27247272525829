import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import i18n from "../../../i18n/i18n";
import {userActive, userList,} from "../../../Services/api/toutorials/AdminUserProvider";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";

function ActiveAccount(props) {
  require("./account.css");
  document.body.dir = i18n.dir();
  const { t } = useTranslation();
  const [userLists, SetUserLists] = useState([]);
  const [active, setActive] = useState("Active");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    userList()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          SetUserLists(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  }, []);

  const activeHandle = (id) => {
    setIsLoading(true);
    userActive(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          userList()
            .then((res) => {
              if (res.status && res.status == 200 && res.data.status) {
                SetUserLists(res.data.data);
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log("ERROR");
              console.log(err);
            });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="admin_label">
              <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("admin.label.active_account")}
            </div>
            {isLoading ? (
              <div
                style={{
                  height: "70vh",
                }}
              >
                <SkeletonCard />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                  margin: "0 auto",
                  padding: "50px",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  boxShadow: " 2px 2px 10px #ddd",
                }}
              >
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t("crud.list.name_ar")}</th>
                        <th scope="col">{t("crud.list.name_en")}</th>
                        <th scope="col">{t("crud.list.email")}</th>
                        <th scope="col">{t("crud.list.status")}</th>
                        <th scope="col">{t("crud.list.actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userLists.map((user) => (
                        <tr key={user.id}>
                          <td scope="col">#</td>
                          <td scope="col">{user.name_ar}</td>
                          <td scope="col">{user.name_en}</td>
                          <td scope="col">{user.email}</td>
                          <td scope="col">
                            {user.status === 1
                              ? t("crud.values.active")
                              : t("crud.values.inactive")}
                          </td>
                          {user.status === 1 ? (
                            <td
                              scope="col"
                              onClick={() => activeHandle(user.id)}
                            >
                              <i
                                className="fas fa-minus-circle"
                                style={{
                                  color: "#dc3545",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "25px",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </td>
                          ) : (
                            <td
                              scope="col"
                              onClick={() => activeHandle(user.id)}
                            >
                              <i
                                className="fas fa-check-circle"
                                style={{
                                  color: "#006d77",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "25px",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ActiveAccount;
