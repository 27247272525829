import { Checkbox, Input, InputLabel, MenuItem, Radio, Select, Option, Slider, Stepper, Step, StepLabel, Button, StepContent, TextField } from "@material-ui/core";
import React, {useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import i18n from "../../../../i18n/i18n";
import {Lang} from "../../../../utils";
import { Rating } from "@mui/material";
import {toast} from "react-toastify";


export const InputQuestion=(props)=>{
  require('./style.css');

  const {t} = useTranslation();

  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (props.question) {
        const localAnswers = props.answers?.filter((answer => answer.question.id == props.question.id));
        setAnswers(localAnswers || []);
        console.log(props.answers);
    }
  }, [props.question]);

  const prepareFile = (e) => {
    let file = e.target.files[0];
    if (file.name.split(".").pop() == "exe") {
      toast.error(
        <span style={{ fontSize: "14" }}>{t("notAbleExeFile")}</span>
      );
    } else {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {};
        reader.onerror = function (error) {};
    }
    return file;
  }

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(email);
  }

  const setAnswer = (e) => {
    if (e.target.type == 'file') {
      props.updateAnswers(props.question.id, [{value: prepareFile(e), isFile: true}]);
    } else if(e.target.type == 'email') {
      if (! validateEmail(e.target.value)) {
        console.log('wrong value');
        // e.target.style.borderColor = '#f00';
        return;
      } else {
        // e.target.style.borderColor = 'unset';
        props.updateAnswers(props.question.id, [{value: e.target.value}]);
      }
    } else {
      props.updateAnswers(props.question.id, [{value: e.target.value}]);
    }
  }

  const setRatingAnswer = (value) => {
    // console.log(value);
    setAnswers([{value}]);
    props.updateAnswers(props.question.id, [{value}]);
  }

  const getInputValue = () => {
    // console.log(answers);
    // console.log(answers[0]?.value);
    return answers[0]?.value || "";
  }

  

  const downloadfile=(value)=>{
    window.location.href=value
   }

   function get_url_extension( url ) {
       return url.split(/[#?]/)[0].split('.').pop().trim();
   }



  const renderInput = (type) => {
    const value = getInputValue();
    return <div style={{width: '100%'}}>
        <Input 
          type={type}
          onChange={(e) => setAnswer(e)}
          disabled={! props.canAnswer}
          defaultValue={value}
          value={props.canAnswer? null : value}
          style={{width: '100%'}}
        />
    </div>
  }

  const renderFileInput = (type) => {
    const value = getInputValue();
    return <div style={{width: '100%'}}>
      {props.surveyMode != 'presentation' || props.canAnswer? 
        <Input 
          type={type}
          onChange={(e) => setAnswer(e)}
          disabled={! props.canAnswer}
          style={{width: '100%'}}
        /> : 
        <>
          {props.question.type == 'single_file'? 
            <>
              {["jpg", "jpeg", "png"].includes(get_url_extension(value)) ? (
                <span className="label-name">{<img src={value} style={{width:300,height:300}}/>}</span>
              ):(
                <button style={{backgroundColor:'transparent',border:0}} onClick={()=>downloadfile(value)}>
                  <span className="label-name" style={{cursor:'pointer'}}>
                    <span style={{textDecoration:'underline'}}>{t('survey.downloadFile')}</span>{"   "}
                    <i className="fal fa-arrow-down" style={{color:'black',fontSize:14}}></i>
                  </span>
                </button>
              )}
            </> : null
          }
        </>
      }
    </div>
  }

  const renderTextAreaInput = () => {
    const value = getInputValue();
    return <div style={{width: '100%'}}>
      <TextField
        multiline
        fullWidth
        rows={4}
        defaultValue={value}
        value={props.canAnswer? null : value}
        onChange={(e) => setAnswer(e)}
        size="large"
        readOnly={!props.canAnswer}
      />
    </div>
  }

  return(<>
    <div style={{display: 'block'}}>
    
      <div>
        {/* String */}
        {props.selectionType == 'string'?
           renderInput('text') : null
        }
        {/* Email */}
        {props.selectionType == 'email'?
          renderInput('email') : null
        }
        {/* Phone */}
        {props.selectionType == 'phone'?
          renderInput('tel') : null
        }
        {/* Date */}
        {props.selectionType == 'date'?
          renderInput('date') : null
        }
        {/* File */}
        {props.selectionType == 'single_file'?
          renderFileInput('file') : null
        }
        {/* Calender */}
        {props.selectionType == 'calender'?
          renderInput('datetime-local') : null
        }
        {/* Rating */}
        {props.selectionType == 'star_rating'?
          <Rating
            className={i18n.language == Lang.AR ? "rateStyle" : ''}
            // name={`${question.name}`}
            defaultValue={getInputValue()}
            value={getInputValue()}
            onChange={(e, value) => setRatingAnswer(value)}
            size="large"
            readOnly={!props.canAnswer}
          /> : null
        }
        {/* Text */}
        {props.selectionType == 'text'? 
          renderTextAreaInput() : null
        }
      </div>
    
    </div>
  </>)
}