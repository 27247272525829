import React, { useEffect } from "react";
import classes from "./GroupCard.module.css";
import Cat from "../../../assets/icons/categoryiCON.svg";
import Checkbox from "../../Shared/Components/Checkbox/Checkbox";
export default function GroupCard({ group, setSelectedGroup, selectedGroup }) {
  useEffect(() => {}, [setSelectedGroup, selectedGroup]);
  return (
    <div className={classes.GroupCard}>
      <div className={classes.CheckboxCont}>
        <Checkbox
          name="has_button"
          onChange={(event) => {
            setSelectedGroup(group.id);
          }}
          checked={selectedGroup === group.id}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.groupImg}>
          <img src={group.img || Cat} alt="" height={90} width={90} />
        </div>
        <div className={classes.name_desc}>
          <p>{group.name}</p>
          <span>{group.description}</span>
        </div>
      </div>
    </div>
  );
}
