import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {Typography} from "@mui/material";

function OrderQuestionDraggableItem(props) {
    require("./DraggableItem.css");
    const {t} = useTranslation();

    const handleDragStart = (e) => {
        props.disableSorting();
        props.setSourceItemId(props.id);
        const maskBlock = document.createElement("div");
        maskBlock.className = "tajah-accordion-holder-drag-mask-block";
        document.getElementById("tajah-question-order-item-" + props.id).appendChild(maskBlock);

        document.querySelectorAll(".tajah-draggable-item").forEach((item) => {
            const nodeStringIdentifier = item.id;
            const nodeId = nodeStringIdentifier.substr(nodeStringIdentifier.lastIndexOf('-') + 1);
            if (nodeId != props.id) {
                item.appendChild(generateMask(nodeId));
            }
        })

        if (e) {
            e.target.style.opacity = '0.4';
        }
    }

    const handleDragEnd = (e) => {
        document.querySelectorAll(".tajah-accordion-holder-drag-mask").forEach((item) => {
            item.remove();
        })

        document.querySelectorAll(".tajah-accordion-holder-drag-mask-block")?.forEach((item) => {
            item.remove();
        })

        if (e) {
            e.target.style.opacity = '1';
        }
    }

    const handleDragEnter = (e) => {
        if (e) {
            e.target.classList.add('tajah-drag-over');
        }
    }

    const handleDragLeave = (e) => {
        if (e) {
            e.target.classList.remove('tajah-drag-over');
        }
    }

    const handleOnDrop = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            // console.log(parseInt(e.target.id.slice(2)))
            // console.log(parseInt(e.target.id.slice(3)))
            // console.log(e.target.id)
            // console.log(props.options)
            // console.log(parseInt(e.target.id.slice(26)))
            // const parsedId = parseInt(e.target.id.slice(26));
            const parsedId = parseInt(e.target.id.slice(2));
            props.setTargetItemId(parsedId);
            console.log(parsedId)
        }
    }

    const generateMask = (nodeId = null) => {
        const mask = document.createElement("div");
        mask.className = "tajah-accordion-holder-drag-mask";
        const maskUpper = document.createElement("div");
        maskUpper.id = "u-" + (nodeId ? nodeId : props.id);
        maskUpper.className = "tajah-accordion-holder-drag-mask-upper";
        mask.appendChild(maskUpper);

        return mask;
    }

    useEffect(() => {
        console.log(props.id)
        const draggableNode = document.getElementById("tajah-question-order-item-" + props.id);
        draggableNode.addEventListener('dragstart', handleDragStart);
        draggableNode.addEventListener('dragenter', handleDragEnter);
        draggableNode.addEventListener('dragleave', handleDragLeave);
        draggableNode.addEventListener('dragend', handleDragEnd);
        draggableNode.addEventListener('dragover', function(e) {
            e.preventDefault();
        });
        draggableNode.addEventListener('drop', handleOnDrop);
    }, []);


    return (
        <>
            <div
                className={localStorage.getItem('type') == "trainer" ? `tajah-draggable-item tajah-draggable-item-additives` : `tajah-draggable-item tajah-draggable-item-additives-center`}
                draggable={true}
                id={"tajah-question-order-item-" + props.id}
                style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}
            >
                <Typography sx={{
                    color: "white"
                }}>{props.name}</Typography>
                {localStorage.getItem('type') == "trainer" ? (
             <i
             onClick={() => {
                 props.deleteItem(props.id);
             }}
             title={"Delete"}
             style={{
                 fontSize: 20,
                 color:'white',
                 cursor:'pointer'

             }} className="fas fa-trash"></i>
                ):null}
            </div>
        </>
    )
}

export default OrderQuestionDraggableItem;
