import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";
export const filterActions = ({
  showFilter,
  exportPdf,
  exportExcel,
  paymentDue,
  paymentDone,
}) => {
  const actionsList = [
    {
      id: "paymentDue",
      type: "button",
      outline: true,
      style: {
        border: "2px solid #24b3b9",
        borderRadius: 6,
        width: i18n.language === "ar" ? 173 : 210,
        height: 50,
      },
      label:
        i18n.language === "ar" ? "دفعات برامج مستحقة" : "Program due payments",
      btnTextColor: "#24b3b9",
      action: paymentDue,
    },
    {
      id: "paymentDone",
      type: "button",
      outline: true,
      style: {
        border: "2px solid #8599B6",
        borderRadius: 6,
        width: i18n.language === "ar" ? 173 : 210,
        height: 50,
      },
      label:
        i18n.language === "ar"
          ? "دفعات برامج  منتهية"
          : "Program done payments",
      btnTextColor: "#8599B6",
      action: paymentDone,
    },
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    // {
    //   id: "pdf",
    //   icon: <img src={PdfIcon} alt="" />,
    //   theme: "pdf",
    //   action: exportPdf,
    // },
    // {
    //   id: "xls",
    //   icon: <img src={XlsIcon} alt="" />,
    //   theme: "xls",
    //   action: exportExcel,
    // },
  ];
  return actionsList;
};
