import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";

const UserWalletListFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
}) => {
  const { t, i18n } = useTranslation();
  const { systemRolesList, systemGenders, systemSpecialties, systemCountries } =
    useSelector((state) => state.systemUsersListReducer);
  const [systemDepartment, setSystemDepartment] = useState([]);

  const registerTypeOptions = [
    { id: "facebook", label: t("social.facebook"), value: "facebook" },
    { id: "twitter", label: t("social.twitter"), value: "twitter" },
    { id: "linkedIn", label: t("social.linkedIn"), value: "linkedin-openid" },
    { id: "google", label: t("social.google"), value: "google" },
    { id: "normal", label: t("social.normal"), value: "normal" },
    { id: "SSO", label: t("social.SSO"), value: "sso" },
  ];

  const statusOptions = [
    {
      id: "active",
      value: "1",
      label: t("system_users.active"),
    },
    {
      id: "inactive",
      value: "0",
      label: t("system_users.inactive"),
    },
  ];

  const switchedFromSSO = [
    {
      id: "yes",
      value: "true",
      label: t("yes"),
    },
    {
      id: "no",
      value: "false",
      label: t("no"),
    },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("system_users.name"),
      type: "text",
      placeholder: t("system_users.name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "email",
      component: "input",
      label: t("system_users.email"),
      type: "text",
      placeholder: t("system_users.email"),
      name: "email",
      value: filterData.email,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "phone",
      component: "input",
      label: t("system_users.phone"),
      type: "text",
      placeholder: t("system_users.phone"),
      name: "phone",
      value: filterData.phone,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "id_number",
      component: "input",
      label: t("system_users.id_number"),
      type: "text",
      placeholder: t("system_users.id_number"),
      name: "id_number",
      value: filterData.id_number,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "jobs",
      label: t("system_users.jobs"),
      component: "select",
      placeholder: t("system_users.jobs"),
      initialValue: t("system_users.jobs"),
      name: "jobs",
      value: filterData.jobs,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: systemRolesList?.length
        ? systemRolesList?.map((role) => ({
            id: role.id,
            value: role.id,
            label: role.label,
          }))
        : [],
    },
    {
      id: "gender",
      label: t("system_users.gender"),
      component: "select",
      placeholder: t("system_users.gender"),
      initialValue: t("system_users.gender"),
      name: "gender",
      value: filterData.gender,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: systemGenders?.length
        ? systemGenders?.map((gender) => ({
            id: gender.id,
            value: gender.id,
            label: gender.name,
          }))
        : [],
    },
    {
      id: "major",
      label: t("system_users.major"),
      component: "select",
      placeholder: t("system_users.major"),
      initialValue: t("system_users.major"),
      name: "major",
      value: filterData.major,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: (e) => {
        changeHandler(e);
        setSystemDepartment(
          systemSpecialties?.find(
            (specialty) => specialty.id === Number(e.target.value)
          )?.departments
        );
      },
      options: systemSpecialties?.length
        ? systemSpecialties?.map((specialty) => ({
            id: specialty.id,
            value: specialty.id,
            label:
              i18n.language === "ar" ? specialty.name_ar : specialty.name_en,
          }))
        : [],
    },
    {
      id: "department",
      label: t("system_users.department"),
      component: "select",
      placeholder: t("system_users.department"),
      initialValue: t("system_users.department"),
      name: "department",
      value: filterData.department,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: systemDepartment?.length
        ? systemDepartment?.map((department) => ({
            id: department.id,
            value: department.id,
            label: department.name,
          }))
        : [],
    },
    {
      id: "registerDate",
      label: t("system_users.register_date"),
      component: "datePicker",
      placeholder: t("system_users.register_date"),
      name: "registerDate",
      value: filterData.registerDate,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
    },
    {
      id: "registerType",
      label: t("system_users.register_type"),
      component: "select",
      placeholder: t("system_users.register_type"),
      initialValue: t("system_users.register_type"),
      name: "registerType",
      value: filterData.registerType,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: registerTypeOptions,
    },
    {
      id: "countries",
      label: t("system_users.country"),
      component: "select",
      placeholder: t("system_users.country"),
      initialValue: t("system_users.country"),
      name: "country",
      value: filterData.country,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: systemCountries?.length
        ? systemCountries?.map((country) => ({
            id: country.id,
            value: country.id,
            label: country.name,
          }))
        : [],
    },
    {
      id: "nationality",
      label: t("system_users.nationality"),
      component: "select",
      placeholder: t("system_users.nationality"),
      initialValue: t("system_users.nationality"),
      name: "nationality",
      value: filterData.nationality,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: systemCountries?.length
        ? systemCountries?.map((nationality) => ({
            id: nationality.id,
            value: nationality.id,
            label: nationality.nationality,
          }))
        : [],
    },
    {
      id: "status",
      label: t("system_users.status"),
      component: "select",
      placeholder: t("system_users.status"),
      initialValue: t("system_users.status"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: statusOptions,
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];
  return <FilterBox fields={filterFields} />;
};

export default UserWalletListFilter;
