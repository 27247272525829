import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import HomeIcon from "../../../assets/icons/home.svg";
import { Lang } from "../../../utils";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import Select from "react-select";
import Input from "../../Shared/Components/Input/Input";
import InputFile from "../../Shared/Components/InputFile/InputFile";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./CertificateTemplates.module.css";
import { addBackgroundImage } from "../../../Services/api/certificate/certificateTemplateProvider";

const CertificateTemplatesForm = ({
  submitHandler,
  type = "create",
  certificateData,
  loading = false,
  role,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [background, setBackground] = useState("");
  const [backgroundImgURL, setBackgroundImgURL] = useState("");

  const dispatch = useDispatch();

  const backgroundSubmitHandler = async (values) => {
    let response = await addBackgroundImage(values);
    if (response?.status === 200 && response?.data.status) {
      setBackground(response.data.data.background);
      setBackgroundImgURL(response.data.data.url);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg
            ? response.data.msg
            : t("certificate.messages.create_successfully")}
        </span>
      );
    } else {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("certificate.messages.failed_create")}
        </span>
      );
    }
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("sidebar.label.content_development"),
      pagePath: "#",
    },
    {
      id: `certificate_templates`,
      page: t("sidebar.label.certificate_templates"),
      pagePath:
        localStorage.getItem("type") == "group-admin"
          ? `/group-admin/certificateTemplates`
          : localStorage.getItem("type") == "manager"
          ? `/manager/certificateTemplates`
          : "/admin/certificateTemplates",
    },
    {
      id: `manage_certificate`,
      page:
        type === "create"
          ? t("certificate.create_certificate")
          : i18n.language == Lang.AR
          ? certificateData?.name_ar
          : certificateData?.name_en,
      pagePath: "#",
      active: true,
    },
  ];

  const getLogo = () => {
    if (!certificateData?.logo) return "";
    return {
      preview: certificateData?.logo,
    };
  };
  const getBackground = () => {
    if (!certificateData?.background) return "";
    return {
      preview: certificateData?.background,
    };
  };

  const getSignature = () => {
    if (!certificateData?.signature) return "";
    return {
      preview: certificateData?.signature,
    };
  };

  const orientationOptions = [
    {
      id: 1,
      label: t("landscape"),
      value: "landscape",
    },
    {
      id: 2,
      label: t("portrait"),
      value: "portrait",
    },
  ];

  return (
    <div className={classes["course"]}>
      <div className="container-fluid">
        <div className={classes["course__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {loading || isLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      height: "100vh",
                    }}
                  >
                    <SkeletonCardOverlay />
                  </div>
                </div>
              ) : (
                <MainBox>
                  <Formik
                    initialValues={{
                      image: "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        let data = { ...values };
                        if (values.image?.file) {
                          data.image = values.image?.file;
                        } else {
                          delete data.image;
                        }
                        const formData = new FormData();
                        for (let key in data) {
                          if (!data[key]) continue;
                          if (Array.isArray(data[key])) {
                            data[key].forEach((item) => {
                              formData.append(`${key}[]`, item);
                            });
                          } else {
                            formData.append(key, data[key]);
                          }
                        }
                        await backgroundSubmitHandler(
                          type === "create" ? formData : data
                        );
                        setSubmitting(false);
                      } catch (err) {
                        console.log(err);
                        toast.error(
                          <>
                            <span
                              style={{
                                fontSize: 13,
                                fontWeight: "bold",
                                display: "block",
                                paddingBottom: "10px",
                              }}
                            >
                              {err.response.data.msg}
                            </span>
                            <ul>
                              {Object.keys(err.response.data.errors).map(
                                (key) => {
                                  return (
                                    <li>
                                      <span style={{ fontSize: 11 }}>
                                        {err.response.data.errors[key]}
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        );
                      }
                    }}
                    validateOnChange={true}
                    validate={(values) => {
                      // const errors = {};
                      // if (type === "create" && !values?.logo?.file) {
                      //   errors.logo = t("crud.errors.required");
                      // }
                      // if (
                      //   type === "edit" &&
                      //   !values?.logo?.file &&
                      //   !values?.logo?.preview
                      // ) {
                      //   errors.logo = t("crud.errors.required");
                      // }
                      // return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["course__form"]}
                      >
                        <MainBoxHead
                          title={
                            type === "create"
                              ? t("certificate.create_certificate")
                              : t("certificate.edit_certificate")
                          }
                        />
                        <div
                          className={`row ${classes["course__form__fields"]}`}
                        >
                          <div
                            className={`${classes["course__form__notes"]} col-12`}
                          >
                            <p>{t("notes")} :</p>
                            <p>* {t("message_1")}</p>
                            <p>* {t("message_2")}</p>
                            <p>* {t("message_3")}</p>
                            <p>* {t("message_4")}</p>
                            <p>* {t("message_5")}</p>
                            <p>* {t("message_6")}</p>
                            <p>* {t("message_7")}</p>
                            <p>* {t("message_8")}</p>
                            <p>* {t("message_9")}</p>
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <InputFile
                              name="image"
                              type="document"
                              documentWithoutTitle={true}
                              accept={"image/*,"}
                              label={t("background_img")}
                              placeholder={t("background_img")}
                              setFieldValue={setFieldValue}
                              value={values.image}
                              error={errors.image}
                            />
                          </div>
                          {type !== "preview" && (
                            <div
                              className={`${classes["course__form__submit__container"]} col-12`}
                            >
                              <CustomButton
                                type="submit"
                                colors="#036c77"
                                value={
                                  type === "create"
                                    ? t("use_background")
                                    : t("edit_background")
                                }
                                disable={isSubmitting || type === "cannotEdit"}
                                loading={isSubmitting}
                              />
                            </div>
                          )}
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                  <Formik
                    initialValues={{
                      name_ar: certificateData?.name_ar || "",
                      name_en: certificateData?.name_en || "",
                      background: background,
                      description_ar: certificateData?.description_ar || "",
                      description_en: certificateData?.description_en || "",
                      orientation: certificateData?.orientation || "landscape",
                      // logo: certificateData?.logo ? getLogo() : "",
                      // signature: certificateData?.signature
                      //   ? getSignature()
                      //   : "",
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                      let errorObj = {};
                      try {
                        let data = { ...values, background: background };
                        console.log(data);
                        if (values.logo?.file) {
                          data.logo = values.logo?.file;
                        } else {
                          delete data.logo;
                        }
                        if (values.signature?.file) {
                          data.signature = values.signature?.file;
                        } else {
                          delete data.signature;
                        }
                        if (background === "") {
                          delete data.background;
                        }

                        const formData = new FormData();
                        for (let key in data) {
                          if (!data[key]) continue;
                          if (Array.isArray(data[key])) {
                            data[key].forEach((item) => {
                              formData.append(`${key}[]`, item);
                            });
                          } else {
                            formData.append(key, data[key]);
                          }
                        }
                        // console.log(data);
                        // console.log(formData);
                        await submitHandler(
                          type === "create" ? formData : data
                        );
                        setSubmitting(false);
                      } catch (err) {
                        if (
                          err.response.data.errors &&
                          err.response.data.errors.name_en
                        ) {
                          errorObj.name_en = err.response.data.errors.name_en;
                        }
                        if (
                          err.response.data.errors &&
                          err.response.data.errors.name_ar
                        ) {
                          errorObj.name_ar = err.response.data.errors.name_ar;
                        }
                        if (Object.keys(errorObj).length !== 0) {
                          setErrors(errorObj);
                        }
                        toast.error(
                          <>
                            <span
                              style={{
                                fontSize: 13,
                                fontWeight: "bold",
                                display: "block",
                                paddingBottom: "10px",
                              }}
                            >
                              {err.response.data.msg}
                            </span>
                            <ul>
                              {Object.keys(err.response.data.errors).map(
                                (key) => {
                                  return (
                                    <li>
                                      <span style={{ fontSize: 11 }}>
                                        {err.response.data.errors[key]}
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        );
                      }
                    }}
                    validateOnChange={true}
                    validate={(values) => {
                      const errors = {};
                      if (!values.name_ar) {
                        errors.name_ar = t("crud.errors.required");
                      }

                      if (!values.name_en) {
                        errors.name_en = t("crud.errors.required");
                      }

                      if (
                        !values.description_ar?.replace(/<[^>]*>/g, "")?.trim()
                      ) {
                        errors.description_ar = t("crud.errors.required");
                      }

                      if (
                        !values.description_en?.replace(/<[^>]*>/g, "")?.trim()
                      ) {
                        errors.description_en = t("crud.errors.required");
                      }

                      // if (type === "create" && !values?.logo?.file) {
                      //   errors.logo = t("crud.errors.required");
                      // }

                      // if (type === "create" && !values?.signature?.file) {
                      //   errors.signature = t("crud.errors.required");
                      // }

                      // if (
                      //   type === "edit" &&
                      //   !values?.logo?.file &&
                      //   !values?.logo?.preview
                      // ) {
                      //   errors.logo = t("crud.errors.required");
                      // }

                      // if (
                      //   type === "edit" &&
                      //   !values?.signature?.file &&
                      //   !values?.signature?.preview
                      // ) {
                      //   errors.signature = t("crud.errors.required");
                      // }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["course__form"]}
                      >
                        {type === "cannotEdit" && (
                          <div className={classes["course__warning__note"]}>
                            {t("trainer.course.cannot_edit")}
                          </div>
                        )}
                        {/* {type === "preview" && (
                          <div className={classes["course__manage-content"]}>
                            <CustomButton
                              type="button"
                              value={t("trainer.course.manage_course_content")}
                              colors="#036c77"
                              action={() => {
                                history.push(
                                  `/admin/course/profile/${courseData?.id}`
                                );
                              }}
                            />
                          </div>
                        )} */}

                        <div
                          className={`row ${classes["course__form__fields"]}`}
                        >
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <Input
                              label={t("certificate_templates_name_ar")}
                              type="text"
                              name="name_ar"
                              placeholder={t("certificate_templates_name_ar")}
                              onChange={handleChange}
                              error={errors.name_ar}
                              value={values.name_ar}
                              required
                              readonly={
                                type === "preview" || type === "cannotEdit"
                              }
                            />
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <Input
                              label={t("certificate_templates_name_en")}
                              type="text"
                              name="name_en"
                              placeholder={t("certificate_templates_name_en")}
                              onChange={handleChange}
                              error={errors.name_en}
                              value={values.name_en}
                              required
                              readonly={
                                type === "preview" || type === "cannotEdit"
                              }
                            />
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12 `}
                          >
                            <label htmlFor="orientation">
                              {t("orientation")}
                            </label>
                            <Select
                              name="orientation"
                              id="orientation"
                              options={orientationOptions}
                              value={orientationOptions.find(
                                (option) => option.value == values.orientation
                              )}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setFieldValue("orientation", item.value);
                              }}
                              placeholder={t("orientation")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.orientation ? errors.orientation : null}
                            </p>
                          </div>

                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description"
                              style={{ color: "#777777" }}
                            >
                              {t("certificate_templates_desc_ar")}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <SunEditor
                              placeholder={t("certificate_templates_desc_ar")}
                              value={values.description_ar}
                              defaultValue={values.description_ar}
                              onChange={(e) => {
                                handleChange({
                                  target: {
                                    name: "description_ar",
                                    value: e,
                                  },
                                });
                              }}
                              setOptions={{
                                height:
                                  values.orientation === "landscape"
                                    ? 700
                                    : 900,
                                contentResizable: false,
                                buttonList: [
                                  ["undo", "redo", "fontSize", "formatBlock"],
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript",
                                    "removeFormat",
                                  ],
                                  [
                                    "fontColor",
                                    "hiliteColor",
                                    "outdent",
                                    "indent",
                                    "align",
                                    "horizontalRule",
                                    "list",
                                    "table",
                                  ],
                                  ["link", "image", "showBlocks", "codeView"],
                                ],
                              }}
                              setDefaultStyle={`font-family: dinNextRegular; font-size: 16px; direction: ltr;min-height:${
                                values.orientation === "landscape"
                                  ? "700px"
                                  : "900px"
                              };max-height:${
                                values.orientation === "landscape"
                                  ? "700px"
                                  : "900px"
                              }; background-image:url(${
                                backgroundImgURL || certificateData?.background
                              }); background-size:cover ;`}
                              disable={
                                type === "preview" || type === "cannotEdit"
                              }
                            />

                            <p className="form-input-error-space">
                              {errors?.description_ar && errors?.description_ar}
                            </p>
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description"
                              style={{ color: "#777777" }}
                            >
                              {t("certificate_templates_desc_en")}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <SunEditor
                              placeholder={t("certificate_templates_desc_en")}
                              value={values.description_en}
                              defaultValue={values.description_en}
                              onChange={(e) => {
                                handleChange({
                                  target: { name: "description_en", value: e },
                                });
                              }}
                              setOptions={{
                                height:
                                  values.orientation === "landscape"
                                    ? 700
                                    : 900,
                                contentResizable: false,
                                buttonList: [
                                  ["undo", "redo", "fontSize", "formatBlock"],
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript",
                                    "removeFormat",
                                  ],
                                  [
                                    "fontColor",
                                    "hiliteColor",
                                    "outdent",
                                    "indent",
                                    "align",
                                    "horizontalRule",
                                    "list",
                                    "table",
                                  ],
                                  ["link", "image", "showBlocks", "codeView"],
                                ],
                              }}
                              setDefaultStyle={`font-family: dinNextRegular; font-size: 16px; min-height:${
                                values.orientation === "landscape"
                                  ? "700px"
                                  : "900px"
                              }; max-height:${
                                values.orientation === "landscape"
                                  ? "700px"
                                  : "900px"
                              };background-image:url(${
                                backgroundImgURL || certificateData?.background
                              }); background-size:cover `}
                              disable={
                                type === "preview" || type === "cannotEdit"
                              }
                            />
                            <p className="form-input-error-space">
                              {errors?.description_en && errors?.description_en}
                            </p>
                          </div>
                          {/* <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <InputFile
                              name="logo"
                              type="document"
                              documentWithoutTitle={true}
                              accept={"image/*,"}
                              label={t("logo_img")}
                              placeholder={t("logo_img")}
                              setFieldValue={setFieldValue}
                              value={values.logo}
                              error={errors.logo}
                            />
                          </div>
                          <div
                            className={`${classes["course__form__field"]} col-12`}
                          >
                            <InputFile
                              name="signature"
                              type="document"
                              documentWithoutTitle={true}
                              accept={"image/*,"}
                              label={t("signature_img")}
                              placeholder={t("signature_img")}
                              setFieldValue={setFieldValue}
                              value={values.signature}
                              error={errors.signature}
                            />
                          </div> */}

                          {type !== "preview" && (
                            <div
                              className={`${classes["course__form__submit__container"]} col-12`}
                            >
                              <CustomButton
                                type="submit"
                                colors="#036c77"
                                value={
                                  type === "create"
                                    ? t("general.submit")
                                    : t("general.save")
                                }
                                disable={isSubmitting || type === "cannotEdit"}
                                loading={isSubmitting}
                              />
                            </div>
                          )}
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateTemplatesForm;
