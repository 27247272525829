import React from "react";
import classes from "./Calender.module.css";
import moment from "moment";
import DateIcon from "../../../../assets/icons/dateIcon.svg";
import LocationIcon from "../../../../assets/icons/location1.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import Img from "../../../../assets/image/EventDefault.png";

export default function EventCard({ event }) {
  return (
    <div className={classes.CardCont}>
      <div className={classes.imgCont}>
        <img src={event.cover || Img} alt="" className={classes.img} />
      </div>
      <div className={classes.infoCont}>
        <p style={{ fontSize: 16 }}>{event.title}</p>
        {event.type.value === "my-courses" ? (
          <div
            className={classes.Des}
            dangerouslySetInnerHTML={{ __html: event.description }}
          ></div>
        ) : (
          <span className={classes.Des}>
            {event.description.slice(0, 80)}
            {event.description.length > 80 ? "..." : ""}
          </span>
        )}

        <div className={classes.location_date}>
          <span className={classes.iconscontainer}>
            <img src={DateIcon} alt="" />{" "}
            <p className={classes.iconText}>
              {moment(event.date).format("YYYY-MM-DD")}
              <br />
              {utcToLocal(event.date)}
            </p>
          </span>
          <span className={classes.iconscontainer}>
            <img src={LocationIcon} alt="" />
            <p className={classes.iconText}>{event.location}</p>
          </span>
        </div>
      </div>
    </div>
  );
}
