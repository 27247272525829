import { Box, CircularProgress } from "@material-ui/core";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { LessonContentContext } from "../../../../../Providers/LessonContentProvider";
import { fetchActivityCommentsList } from "../../../../../Redux/Actions/activityComment/activityComment.action";
import { editActivityToLesson } from "../../../../../Services/api/courses/courseProvider";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import Input from "../../../../Shared/Components/Input/Input";
import Select from "../../../../Shared/Components/Select/Select";
import classes from "./discFormModal.module.css";

function DiscFormModal({ chapter, title, dismissModal, getCommentsListHandler, activityId }) {
  require("./discFormModal.css");
  const lessonContentContext = useContext(LessonContentContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);

  return (
    <>
      <div
        className="add_activity_modal"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="add_activity_modal_header">
          <h2 className="add_activity_modal_title">{title}</h2>
          <div className="add_activity_modal_close" onClick={dismissModal}>
            <img src={DeleteIcon} alt="close" />
          </div>
        </div>
        <Formik
          initialValues={{
            name: chapter?.name || "",
            desc: chapter?.desc || "",
            file_size: chapter?.file_size || "",
            interactions_num: chapter?.interactions_num || "",
            interactions_agree: chapter?.interactions_agree == true ? "1" : "0",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            // setSubmitting(true);
            // setIsLocalSubmitting(true);
            let formData = new FormData();
            Object.keys(values).forEach((field) => {
              formData.append(field, values[field]);
            });

            try {
              await editActivityToLesson(formData, activityId);

              getCommentsListHandler();
              dismissModal();
              setIsLocalSubmitting(false);
              setSubmitting(false);
            } catch (error) {
              console.log(error);
              setIsLocalSubmitting(false);
              setSubmitting(false);
              return;
            }
          }}
          validate={(values) => {
            setHasSubmitted(true);
            const errors = {};
            if (!values.name) {
              errors.name = t("crud.errors.required");
            }

            if (!values.desc) {
              errors.desc = t("crud.errors.required");
            }

            if (!values.file_size) {
              errors.file_size = t("crud.errors.required");
            }

            if (!values.interactions_num) {
              errors.interactions_num = t("crud.errors.required");
            }

            return errors;
          }}
          validateOnChange={hasSubmitted}
        >
          {({ setFieldValue, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="add_activity_modal_form">
              <div className="add_activity_modal_form_fields">
                <Input
                  type="text"
                  placeholder={t("trainer.course.lesson.activity.crud.name")}
                  label={t("trainer.course.lesson.activity.name")}
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  error={errors.name}
                  required
                />

                <div>
                  <label htmlFor="description">{t("trainer.course.lesson.activity.description")}</label>
                  <SunEditor
                    name="desc"
                    onChange={(desc) => {
                      setFieldValue("desc", desc);
                    }}
                    value={values.desc}
                    defaultValue={values.desc}
                    setOptions={{
                      height: 200,
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                        ["removeFormat"],
                        ["fontColor", "hiliteColor", "outdent", "indent"],
                        ["align", "horizontalRule", "list", "table"],
                        ["link", "image", "video"],
                      ],
                    }}
                  />
                  <div>
                    {errors && errors.desc ? (
                      <span className={"attachment_panel_input_error"}>{errors.desc}</span>
                    ) : null}
                  </div>
                </div>

                <Input
                  type="number"
                  placeholder={t("trainer.course.lesson.activity.crud.rpu")}
                  label={t("trainer.course.lesson.activity.rpu")}
                  name="interactions_num"
                  onChange={handleChange}
                  value={values.interactions_num}
                  error={errors.interactions_num}
                  min={1}
                  required
                />

                <Input
                  type="number"
                  placeholder={t("trainer.course.lesson.activity.crud.max_size")}
                  label={t("trainer.course.lesson.activity.max_size")}
                  name="file_size"
                  onChange={handleChange}
                  value={values.file_size}
                  error={errors.file_size}
                  required
                />

                <Select
                  name="interactions_agree"
                  label={t("trainer.course.lesson.activity.require_approval")}
                  onchange={handleChange}
                  options={[
                    { label: t("no"), value: 0, id: 0 },
                    { label: t("yes"), value: 1, id: 1 },
                  ]}
                  error={errors.interactions_agree}
                  value={values.interactions_agree}
                  initialValue={t("trainer.course.lesson.activity.require_approval")}
                  required
                />
                <div className="add_activity_actions">
                  <div className={`${classes["create_tax__form__field-actions"]} col-12`}>
                    <button
                      className={classes["create_tax__form__field-actions-submit"]}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress color="white" size={30} />
                        </Box>
                      ) : (
                        t("crud.placeholders.submit")
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default DiscFormModal;
