import env from "./Environment";
import connector from "./Handler";
import URLBuilder from "./UrlBuilder";

export function getAllAvailableCourses({ tab, filter }) {
  console.log(filter);
  const myPromise = new Promise((resolve, reject) => {
    try {
      if (tab >= 0 && tab !== null) {
        connector
          .get(URLBuilder(env.get_all_courses_home_id, { id: tab }), {
            params: { ...filter },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        connector
          .get(env.get_all_courses_home, {
            params: filter,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getAllAvailablePrograms({ tab, filter }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      if (tab >= 0 && tab !== null) {
        connector
          .get(URLBuilder(env.get_all_programs_home_id, { id: tab }), {
            params: { ...filter },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        connector
          .get(env.get_all_programs_home, {
            params: { ...filter },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAllTrainers({ filter }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_trainer, {
          params: { ...filter },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAllStatistics() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_counts)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAdHome() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_Ad)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getAllSpecialties(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_all_specialties, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAllSpecialty() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_all_specialty)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
