import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Lang } from "../../../../utils";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";

import classes from "./ProgramPaymentList.module.css";

const ProgramPaymentListTable = ({ sortAction, rolesEditHandle }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { programPaymentList, programPaymentListLoading } = useSelector(
    (state) => state.programPaymentListReducer
  );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const columns = [
    {
      id: "num",
      name: "#",
      key: "num",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "program_name",
      name: t("trainer.program.name"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-name"]}>
          {rowData?.program.name || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "course_name",
      name: t("courses_list.course_name"),
      key: "course_name",
      cell: ({ rowData }) => <>{rowData?.courses[0]?.name}</>,
    },

    // {
    //   id: "payment_refund",
    //   name: t("payment_setting"),
    //   key: "payment_refund",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.payments ? (
    //         <>
    //           {rowData?.payments?.map((item, indx) =>
    //             item.id == rowData?.id ? (
    //               <>
    //                 {t("payment_setting")} {indx + 1}
    //               </>
    //             ) : (
    //               <></>
    //             )
    //           )}
    //         </>
    //       ) : (
    //         "-"
    //       )}
    //     </>
    //   ),
    // },
    {
      id: "value",
      name: t("general.amount"),
      key: "value",
      cell: ({ rowData }) => (
        <>{`${rowData?.amount} ${t("general.rs")}` || "-"}</>
      ),
      sortable: false,
    },

    {
      id: "payment_date",
      name: t("general.payment_date"),
      key: "payment_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.payment_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.payment_date)}
        </>
      ),
      sortable: false,
    },
    {
      id: "end_date",
      name: t("general.end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.end_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.end_date)}
        </>
      ),
      sortable: false,
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: false,
    },
    // {
    //   id: "add_by",
    //   name: t("add_by"),
    //   key: "add_by",
    //   cell: ({ rowData }) => <>{rowData?.created_by.name || "-"}</>,
    // sortable: false,
    // },
  ];

  return (
    <MainTable
      columns={columns}
      data={programPaymentList || []}
      isLoading={programPaymentListLoading}
      sortAction={sortAction}
    />
  );
};

export default ProgramPaymentListTable;
