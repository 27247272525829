import { useTranslation } from "react-i18next";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";

const BankTransfersFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  resetFilterHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  activeTab,
  courses,
  programs,
  isProgram,
}) => {
  const { t, i18n } = useTranslation();

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
      value: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
      value: 2,
    },
  ];

  const filterFields = [
    {
      id: "payer_name",
      component: "input",
      label: t("financial.payments_request.username"),
      type: "text",
      placeholder: t("financial.payments_request.username"),
      name: "payer_name",
      value: filterData.payer_name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "payer_email",
      component: "input",
      label: t("general.email"),
      type: "text",
      placeholder: t("general.email"),
      name: "payer_email",
      value: filterData.payer_email,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    // {
    //   id: "role",
    //   label: t("financial.payments_request.user_role"),
    //   component: "select",
    //   placeholder: t("financial.payments_request.user_role"),
    //   initialValue: t("financial.payments_request.user_role"),
    //   name: "role",
    //   value: filterData.role,
    //   classes: "col-12 col-md-6 col-xl-2",
    //   onchange: changeHandler,
    //   options: [
    //     {
    //       id: "1",
    //       value: true,
    //       label: t("yes"),
    //     },
    //     {
    //       id: "2",
    //       value: false,
    //       label: t("no"),
    //     },
    //   ],
    // },
    {
      id: "payable_type",
      label: t("payments.payable_type"),
      component: "select",
      placeholder: t("payments.payable_type"),
      initialValue: t("payments.payable_type"),
      name: "payable_type",
      value: filterData.payable_type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: [
        {
          id: "course",
          value: "course",
          label: t("trainer.course.label"),
        },
        {
          id: "program_payment",
          value: "program_payment",
          label: t("trainer.program.label"),
        },
        {
          id: "booth",
          value: "booth",
          label: t("booth.label"),
        },
        {
          id: "exhibition_visit",
          value: "exhibition_visit",
          label: t("Exhibition.label"),
        },
      ],
    },
    ...(filterData.payable_type === "course"
      ? [
          {
            id: "payable_name",
            label: t("coupon.label.select_course"),
            component: "select",
            placeholder: t("coupon.label.select_course"),
            initialValue: t("coupon.label.select_course"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: courses?.length
              ? courses?.map((course) => ({
                  id: course.id,
                  value: course.name,
                  label: course.name,
                }))
              : [],
          },
          {
            id: "payable_code",
            component: "input",
            label: t("financial.payments_request.course_code"),
            type: "text",
            placeholder: t("financial.payments_request.course_code"),
            name: "payable_code",
            value: filterData.payable_code,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "payable_serial_number",
            component: "input",
            label: t("financial.payments_request.course_run_serial_number"),
            type: "text",
            placeholder: t("financial.payments_request.course_run_serial_number"),
            name: "payable_serial_number",
            value: filterData.payable_serial_number,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : filterData.payable_type === "program_payment"
      ? [
          {
            id: "payable_name",
            label: t("coupon.label.select_program"),
            component: "select",
            placeholder: t("coupon.label.select_program"),
            initialValue: t("coupon.label.select_program"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: programs?.length
              ? programs?.map((program) => ({
                  id: program.id,
                  value: program.name,
                  label: program.name,
                }))
              : [],
          },
          {
            id: "payable_code",
            component: "input",
            label: t("financial.payments_request.program_code"),
            type: "text",
            placeholder: t("financial.payments_request.program_code"),
            name: "payable_code",
            value: filterData.payable_code,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "payable_serial_number",
            component: "input",
            label: t("financial.payments_request.program_run_serial_number"),
            type: "text",
            placeholder: t("financial.payments_request.program_run_serial_number"),
            name: "payable_serial_number",
            value: filterData.payable_serial_number,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : [
          {
            id: "payable_name",
            component: "input",
            label: t("payments.payable_name"),
            type: "text",
            placeholder: t("payments.payable_name"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]),
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default BankTransfersFilter;
