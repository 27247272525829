import { coursesListConstant } from "../../Constants/courses/coursesList.constants";

const initialState = {
  coursesList: [],
  allCourses: [],
  coursesListTrainers: [],
  coursesListStatuses: [],
  coursesListModes: [],
  coursesListCategories: [],
  coursesListMeta: {},
  coursesListLoading: false,
  allCoursesListLoading: false,
  coursesListError: null,
  allCoursesListError: null,
  coursesListSuccess: false,
  allCoursesListSuccess: false,

  courseDetails: {},
  courseDetailsLoading: false,
  courseDetailsError: null,

  courseTree: {},
  courseTreeLoading: false,
  courseTreeError: null,

  sendNotificationLoading: false,

  subChapterDetails: {},
  subChapterDetailsLoading: false,
  subChapterDetailsError: null,

  lessonDetails: {},
  lessonDetailsLoading: false,
  lessonDetailsError: null,

  noteLoading: false,
  noteError: null,
  noteSuccess: false,

  partnerCoursesList: [],
  partnerCoursesListMeta: {},
  partnerCoursesListLoading: false,
  partnerCoursesListError: null,

  programPaymentsList: [],
  programPaymentsListLoading: false,
  programPaymentsListSuccess: false,
  addProgramPaymentLoading: false,
  addProgramPaymentSuccess: false,
  updateProgramPaymentLoading: false,
  updateProgramPaymentSuccess: false,
  deleteProgramPaymentLoading: false,
  deleteProgramPaymentSuccess: false,

  updateCourseStatusLoading: false,
  updateCourseStatusError: null,
  updateCourseStatusSuccess: false,

  deleteCourseLoading: false,
  deleteCourseError: null,
  deleteCourseSuccess: false,

  addIntroVideoLoading: false,
  addIntroVideoResponse: null,
  addIntroVideoError: null,

  saveWatchingProgressLoading: false,
  saveWatchingProgressSuccess: false,
  saveWatchingProgressError: null,
};

const coursesListReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET COURSES LIST
    case coursesListConstant.GET_COURSES_LIST_REQUEST:
      return {
        ...state,
        coursesListLoading: true,
      };
    case coursesListConstant.GET_COURSES_LIST_SUCCESS:
      return {
        ...state,
        coursesListLoading: false,
        coursesListSuccess: true,
        coursesList: action.payload.courses.data,
        coursesListMeta: action.payload.courses.meta,
        coursesListTrainers: action.payload.trainers,
        coursesListStatuses: action.payload.statuses,
        coursesListModes: action.payload.modes,
        coursesListCategories: action.payload.categories,
      };
    case coursesListConstant.GET_COURSES_LIST_REJECTED:
      return {
        ...state,
        coursesListLoading: false,
        coursesListError: action.payload,
        coursesListSuccess: false,
      };

    // GET PARTNER COURSES LIST
    case coursesListConstant.GET_PARTNER_COURSES_LIST_REQUEST:
      return {
        ...state,
        partnerCoursesListLoading: true,
      };
    case coursesListConstant.GET_PARTNER_COURSES_LIST_SUCCESS:
      return {
        ...state,
        partnerCoursesListLoading: false,
        partnerCoursesList: action.payload.data,
        partnerCoursesListMeta: action.payload.meta,
      };
    case coursesListConstant.GET_PARTNER_COURSES_LIST_REJECTED:
      return {
        ...state,
        partnerCoursesListLoading: false,
        partnerCoursesListError: action.payload,
      };

    // GET ALL COURSES LIST
    case coursesListConstant.GET_ALL_COURSES_LIST_REQUEST:
      return {
        ...state,
        allCoursesListLoading: true,
      };
    case coursesListConstant.GET_ALL_COURSES_LIST_SUCCESS:
      return {
        ...state,
        allCoursesListLoading: false,
        allCoursesListSuccess: true,
        allCourses: action.payload,
      };
    case coursesListConstant.GET_ALL_COURSES_LIST_REJECTED:
      return {
        ...state,
        allCoursesListLoading: false,
        allCoursesListError: action.payload,
        allCoursesListSuccess: false,
      };

    // UPDATE COURSE STATUS
    case coursesListConstant.UPDATE_COURSE_STATUS_REQUEST:
      return {
        ...state,
        updateCourseStatusLoading: true,
      };

    case coursesListConstant.UPDATE_COURSE_STATUS_SUCCESS:
      return {
        ...state,
        updateCourseStatusLoading: false,
        updateCourseStatusSuccess: true,
        updateCourseStatusError: null,

        coursesList: state.coursesList.map((course) => {
          if (course.id === action.payload.id) {
            return {
              ...course,
              is_visible: !course.is_visible,
            };
          }
          return course;
        }),
      };

    case coursesListConstant.UPDATE_COURSE_STATUS_REJECTED:
      return {
        ...state,
        updateCourseStatusLoading: false,
        updateCourseStatusError: action.payload,
        updateCourseStatusSuccess: false,
      };

    // DELETE COURSE
    case coursesListConstant.DELETE_COURSE_REQUEST:
      return {
        ...state,
        deleteCourseLoading: true,
      };

    case coursesListConstant.DELETE_COURSE_SUCCESS:
      return {
        ...state,
        deleteCourseLoading: false,
        deleteCourseSuccess: true,
        deleteCourseError: null,

        coursesList: state.coursesList.filter(
          (course) => course.id !== action.payload.id
        ),
      };

    case coursesListConstant.DELETE_COURSE_REJECTED:
      return {
        ...state,
        deleteCourseLoading: false,
        deleteCourseError: action.payload,
        deleteCourseSuccess: false,
      };

    //ADD INTRO VIDEO
    case coursesListConstant.ADD_INTRO_VIDEO_REQUEST:
      return {
        ...state,
        addIntroVideoLoading: true,
      };
    case coursesListConstant.ADD_INTRO_VIDEO_SUCCESS:
      return {
        ...state,
        addIntroVideoLoading: false,
        addIntroVideoResponse: action.payload,
      };
    case coursesListConstant.ADD_INTRO_VIDEO_REJECTED:
      return {
        ...state,
        addIntroVideoLoading: false,
        addIntroVideoError: action.payload,
      };

    // GET PROGRAM PAYMENT LIST
    case coursesListConstant.GET_PROGRAM_PAYMENT_LIST_REQUEST:
      return {
        ...state,
        programPaymentsListLoading: true,
      };
    case coursesListConstant.GET_PROGRAM_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        programPaymentsListLoading: false,
        programPaymentsListSuccess: true,
        programPaymentsList: action.payload,
        addProgramPaymentSuccess: false,
        updateProgramPaymentSuccess: false,
      };
    case coursesListConstant.GET_PROGRAM_PAYMENT_LIST_REJECTED:
      return {
        ...state,
        programPaymentsListLoading: false,
        programPaymentsListSuccess: false,
      };

    // ADD PROGRAM PAYMENT
    case coursesListConstant.ADD_PROGRAM_PAYMENT_REQUEST:
      return {
        ...state,
        addProgramPaymentLoading: true,
      };
    case coursesListConstant.ADD_PROGRAM_PAYMENT_SUCCESS:
      return {
        ...state,
        addProgramPaymentLoading: false,
        addProgramPaymentSuccess: true,
      };
    case coursesListConstant.ADD_PROGRAM_PAYMENT_REJECTED:
      return {
        ...state,
        addProgramPaymentLoading: false,
        addProgramPaymentSuccess: false,
      };

    // UPDATE PROGRAM PAYMENT
    case coursesListConstant.UPDATE_PROGRAM_PAYMENT_REQUEST:
      return {
        ...state,
        updateProgramPaymentLoading: true,
      };
    case coursesListConstant.UPDATE_PROGRAM_PAYMENT_SUCCESS:
      return {
        ...state,
        updateProgramPaymentLoading: false,
        updateProgramPaymentSuccess: true,
      };
    case coursesListConstant.UPDATE_PROGRAM_PAYMENT_REJECTED:
      return {
        ...state,
        updateProgramPaymentLoading: false,
        updateProgramPaymentSuccess: false,
      };

    // DELETE PROGRAM PAYMENT
    case coursesListConstant.DELETE_PROGRAM_PAYMENT_REQUEST:
      return {
        ...state,
        deleteProgramPaymentLoading: true,
      };
    case coursesListConstant.DELETE_PROGRAM_PAYMENT_SUCCESS:
      return {
        ...state,
        programPaymentsList: state.programPaymentsList.filter(
          (payment) => payment.id !== action.payload
        ),
        deleteProgramPaymentLoading: false,
        deleteProgramPaymentSuccess: true,
      };
    case coursesListConstant.DELETE_PROGRAM_PAYMENT_REJECTED:
      return {
        ...state,
        deleteProgramPaymentLoading: false,
        deleteProgramPaymentSuccess: false,
      };

    // GET COURSE DETAILS
    case coursesListConstant.GET_COURSE_DETAILS_REQUEST:
      return {
        ...state,
        courseDetailsLoading: true,
      };
    case coursesListConstant.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        courseDetailsLoading: false,
        courseDetails: action.payload,
      };
    case coursesListConstant.GET_COURSE_DETAILS_REJECTED:
      return {
        ...state,
        courseDetailsLoading: false,
        courseDetailsError: action.payload,
      };

    // GET COURSE TREE
    case coursesListConstant.GET_COURSE_TREE_REQUEST:
      return {
        ...state,
        courseTreeLoading: true,
      };
    case coursesListConstant.GET_COURSE_TREE_SUCCESS:
      return {
        ...state,
        courseTreeLoading: false,
        courseTree: action.payload,
      };
    case coursesListConstant.GET_COURSE_TREE_REJECTED:
      return {
        ...state,
        courseTreeLoading: false,
        courseTreeError: action.payload,
      };

    // SEND NOTIFICATION TO GIFT SENDER
    case coursesListConstant.SEND_NOTIFICATION_TO_GIFT_SENDER_REQUEST:
      return {
        ...state,
        sendNotificationLoading: true,
      };
    case coursesListConstant.SEND_NOTIFICATION_TO_GIFT_SENDER_SUCCESS:
      return {
        ...state,
        sendNotificationLoading: false,
      };
    case coursesListConstant.SEND_NOTIFICATION_TO_GIFT_SENDER_REJECTED:
      return {
        ...state,
        sendNotificationLoading: false,
      };

    // GET COURSE SUBCHAPTER DETAILS
    case coursesListConstant.GET_COURSE_SUBCHAPTER_DETAILS_REQUEST:
      return {
        ...state,
        subChapterDetailsLoading: true,
      };
    case coursesListConstant.GET_COURSE_SUBCHAPTER_DETAILS_SUCCESS:
      return {
        ...state,
        subChapterDetailsLoading: false,
        subChapterDetails: action.payload,
      };
    case coursesListConstant.GET_COURSE_SUBCHAPTER_DETAILS_REJECTED:
      return {
        ...state,
        subChapterDetailsLoading: false,
        subChapterDetailsError: action.payload,
      };

    // GET COURSE LESSON DETAILS
    case coursesListConstant.GET_COURSE_LESSON_DETAILS_REQUEST:
      return {
        ...state,
        lessonDetailsLoading: true,
      };
    case coursesListConstant.GET_COURSE_LESSON_DETAILS_SUCCESS:
      return {
        ...state,
        lessonDetailsLoading: false,
        lessonDetails: action.payload,
      };
    case coursesListConstant.GET_COURSE_LESSON_DETAILS_REJECTED:
      return {
        ...state,
        lessonDetailsLoading: false,
        lessonDetailsError: action.payload,
      };

    case coursesListConstant.SAVE_WATCHING_PROGRESS_REQUEST:
      return {
        ...state,
        saveWatchingProgressLoading: true,
      };
    case coursesListConstant.SAVE_WATCHING_PROGRESS_SUCCESS:
      if (action.payload.watchable_type === "content") {
        return {
          ...state,
          saveWatchingProgressLoading: false,
          saveWatchingProgressSuccess: true,
          lessonDetails: {
            ...state.lessonDetails,
            lessonContent: state.lessonDetails.lessonContent.map((content) => {
              if (content.id === action.payload.watchable_id) {
                return {
                  ...content,
                  is_watched: true,
                };
              }
              return content;
            }),
          },
        };
      }
      if (action.payload.watchable_type === "activity") {
        return {
          ...state,
          saveWatchingProgressLoading: false,
          saveWatchingProgressSuccess: true,
          lessonDetails: {
            ...state.lessonDetails,
            lessonActivities: state.lessonDetails.lessonActivities.map(
              (activity) => {
                if (activity.id === action.payload.watchable_id) {
                  return {
                    ...activity,
                    is_watched: true,
                  };
                }
                return activity;
              }
            ),
          },
        };
      }
      if (action.payload.watchable_type === "session") {
        return {
          ...state,
          saveWatchingProgressLoading: false,
          saveWatchingProgressSuccess: true,
          lessonDetails: {
            ...state.lessonDetails,
            zoomClassRoomList: state.lessonDetails.zoomClassRoomList.map(
              (session) => {
                if (session.id === action.payload.watchable_id) {
                  return {
                    ...session,
                    is_watched: true,
                  };
                }
                return session;
              }
            ),
          },
        };
      }
      if (action.payload.watchable_type === "discussion") {
        return {
          ...state,
          saveWatchingProgressLoading: false,
          saveWatchingProgressSuccess: true,
          lessonDetails: {
            ...state.lessonDetails,
            lessonDiscussions: state.lessonDetails.lessonDiscussions.map(
              (discussion) => {
                if (discussion.id === action.payload.watchable_id) {
                  return {
                    ...discussion,
                    is_watched: true,
                  };
                }
                return discussion;
              }
            ),
          },
        };
      }
      return {
        ...state,
        saveWatchingProgressLoading: false,
        saveWatchingProgressSuccess: true,
      };
    case coursesListConstant.SAVE_WATCHING_PROGRESS_REJECTED:
      return {
        ...state,
        saveWatchingProgressLoading: false,
        saveWatchingProgressError: action.payload,
      };

    case coursesListConstant.ADD_NOTE_TO_LESSON_REQUEST:
      return {
        ...state,
        noteLoading: true,
      };
    case coursesListConstant.ADD_NOTE_TO_LESSON_SUCCESS:
      return {
        ...state,
        lessonDetails: {
          ...state.lessonDetails,
          ...(action.payload.notable_type === "content"
            ? {
                lessonContent: state.lessonDetails.lessonContent.map((item) =>
                  item.id === action.payload.id
                    ? { ...item, notes: [action.payload.note] }
                    : { ...item }
                ),
              }
            : action.payload.notable_type === "activity"
            ? {
                lessonActivities: state.lessonDetails.lessonActivities.map(
                  (item) =>
                    item.id === action.payload.id
                      ? { ...item, notes: [action.payload.note] }
                      : { ...item }
                ),
              }
            : action.payload.notable_type === "discussions"
            ? {
                lessonDiscussions: state.lessonDetails.lessonDiscussions.map(
                  (item) =>
                    item.id === action.payload.id
                      ? { ...item, notes: [action.payload.note] }
                      : { ...item }
                ),
              }
            : {
                zoomClassRoomList: state.lessonDetails.zoomClassRoomList.map(
                  (item) =>
                    item.id === action.payload.id
                      ? { ...item, notes: [action.payload.note] }
                      : { ...item }
                ),
              }),
        },

        noteLoading: false,
        noteSuccess: true,
      };
    case coursesListConstant.ADD_NOTE_TO_LESSON_REJECTED:
      return {
        ...state,
        noteLoading: false,
        noteSuccess: false,
      };
    case coursesListConstant.EDIT_NOTE_TO_LESSON_REQUEST:
      return {
        ...state,
        noteLoading: true,
      };
    case coursesListConstant.EDIT_NOTE_TO_LESSON_SUCCESS:
      return {
        ...state,
        lessonDetails: {
          ...state.lessonDetails,
          ...(action.payload.notable_type === "content"
            ? {
                lessonContent: state.lessonDetails.lessonContent.map((item) =>
                  item.id === action.payload.lessonId
                    ? { ...item, notes: [action.payload.note] }
                    : { ...item }
                ),
              }
            : action.payload.notable_type === "activity"
            ? {
                lessonActivities: state.lessonDetails.lessonActivities.map(
                  (item) =>
                    item.id === action.payload.lessonId
                      ? { ...item, notes: [action.payload.note] }
                      : { ...item }
                ),
              }
            : action.payload.notable_type === "discussions"
            ? {
                lessonDiscussions: state.lessonDetails.lessonDiscussions.map(
                  (item) =>
                    item.id === action.payload.lessonId
                      ? { ...item, notes: [action.payload.note] }
                      : { ...item }
                ),
              }
            : {
                zoomClassRoomList: state.lessonDetails.zoomClassRoomList.map(
                  (item) =>
                    item.id === action.payload.lessonId
                      ? { ...item, notes: [action.payload.note] }
                      : { ...item }
                ),
              }),
        },

        noteLoading: false,
        noteSuccess: true,
      };
    case coursesListConstant.EDIT_NOTE_TO_LESSON_REJECTED:
      return {
        ...state,
        noteLoading: false,
        noteSuccess: false,
      };
    case coursesListConstant.DELETE_NOTE_TO_LESSON_REQUEST:
      return {
        ...state,
        noteLoading: true,
      };
    case coursesListConstant.DELETE_NOTE_TO_LESSON_SUCCESS:
      return {
        ...state,
        lessonDetails: {
          ...state.lessonDetails,
          ...(action.payload.notable_type === "content"
            ? {
                lessonContent: state.lessonDetails.lessonContent.map((item) =>
                  item.id === action.payload.lessonId
                    ? {
                        ...item,
                        notes: item.notes.filter(
                          (item) => item.id !== action.payload.id
                        ),
                      }
                    : { ...item }
                ),
              }
            : action.payload.notable_type === "activity"
            ? {
                lessonActivities: state.lessonDetails.lessonActivities.map(
                  (item) =>
                    item.id === action.payload.lessonId
                      ? { ...item, notes: action.payload.note }
                      : { ...item }
                ),
              }
            : action.payload.notable_type === "discussions"
            ? {
                lessonDiscussions: state.lessonDetails.lessonDiscussions.map(
                  (item) =>
                    item.id === action.payload.lessonId
                      ? { ...item, notes: action.payload.note }
                      : { ...item }
                ),
              }
            : {
                zoomClassRoomList: state.lessonDetails.zoomClassRoomList.map(
                  (item) =>
                    item.id === action.payload.lessonId
                      ? { ...item, notes: action.payload.note }
                      : { ...item }
                ),
              }),
        },

        noteLoading: false,
        noteSuccess: true,
      };
    case coursesListConstant.DELETE_NOTE_TO_LESSON_REJECTED:
      return {
        ...state,
        noteLoading: false,
        noteSuccess: false,
      };

    default:
      return state;
  }
};

export default coursesListReducer;
