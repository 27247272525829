import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./starsRating.module.css";

const StarsRating = ({ rating, cutStyle, defaultColor, ratingComment }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={classes["stars__rating"]}>
      <div
        className={`${classes["stars__rating-starts"]} ${cutStyle}`}
        style={{
          "--rating": rating,
          "--defaultColor": defaultColor ?? "#d2d4d7",
        }}
        title={`${ratingComment ? ratingComment : ""}`}
      ></div>
    </div>
  );
};

export default StarsRating;
