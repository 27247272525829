export const coursesListConstant = {
  // GET COURSES LIST
  GET_COURSES_LIST_REQUEST: "GET_COURSES_LIST_REQUEST",
  GET_COURSES_LIST_SUCCESS: "GET_COURSES_LIST_SUCCESS",
  GET_COURSES_LIST_REJECTED: "GET_COURSES_LIST_REJECTED",

  // GET PARTNER COURSES LIST
  GET_PARTNER_COURSES_LIST_REQUEST: "GET_PARTNER_COURSES_LIST_REQUEST",
  GET_PARTNER_COURSES_LIST_SUCCESS: "GET_PARTNER_COURSES_LIST_SUCCESS",
  GET_PARTNER_COURSES_LIST_REJECTED: "GET_PARTNER_COURSES_LIST_REJECTED",

  // GET ALL COURSES LIST
  GET_ALL_COURSES_LIST_REQUEST: "GET_ALL_COURSES_LIST_REQUEST",
  GET_ALL_COURSES_LIST_SUCCESS: "GET_ALL_COURSES_LIST_SUCCESS",
  GET_ALL_COURSES_LIST_REJECTED: "GET_ALL_COURSES_LIST_REJECTED",

  // UPDATE COURSE STATUS
  UPDATE_COURSE_STATUS_REQUEST: "UPDATE_COURSE_STATUS_REQUEST",
  UPDATE_COURSE_STATUS_SUCCESS: "UPDATE_COURSE_STATUS_SUCCESS",
  UPDATE_COURSE_STATUS_REJECTED: "UPDATE_COURSE_STATUS_REJECTED",

  // DELETE COURSE
  DELETE_COURSE_REQUEST: "DELETE_COURSE_REQUEST",
  DELETE_COURSE_SUCCESS: "DELETE_COURSE_SUCCESS",
  DELETE_COURSE_REJECTED: "DELETE_COURSE_REJECTED",

  // ADD INTRO VIDEO
  ADD_INTRO_VIDEO_REQUEST: "ADD_INTRO_VIDEO_REQUEST",
  ADD_INTRO_VIDEO_SUCCESS: "ADD_INTRO_VIDEO_SUCCESS",
  ADD_INTRO_VIDEO_REJECTED: "ADD_INTRO_VIDEO_REJECTED",

  // GET PROGRAM PAYMENT LIST
  GET_PROGRAM_PAYMENT_LIST_REQUEST: "GET_PROGRAM_PAYMENT_LIST_REQUEST",
  GET_PROGRAM_PAYMENT_LIST_SUCCESS: "GET_PROGRAM_PAYMENT_LIST_SUCCESS",
  GET_PROGRAM_PAYMENT_LIST_REJECTED: "GET_PROGRAM_PAYMENT_LIST_REJECTED",

  // ADD PROGRAM PAYMENT
  ADD_PROGRAM_PAYMENT_REQUEST: "ADD_PROGRAM_PAYMENT_REQUEST",
  ADD_PROGRAM_PAYMENT_SUCCESS: "ADD_PROGRAM_PAYMENT_SUCCESS",
  ADD_PROGRAM_PAYMENT_REJECTED: "ADD_PROGRAM_PAYMENT_REJECTED",

  // UPDATE PROGRAM PAYMENT
  UPDATE_PROGRAM_PAYMENT_REQUEST: "UPDATE_PROGRAM_PAYMENT_REQUEST",
  UPDATE_PROGRAM_PAYMENT_SUCCESS: "UPDATE_PROGRAM_PAYMENT_SUCCESS",
  UPDATE_PROGRAM_PAYMENT_REJECTED: "UPDATE_PROGRAM_PAYMENT_REJECTED",

  // DELETE PROGRAM PAYMENT
  DELETE_PROGRAM_PAYMENT_REQUEST: "DELETE_PROGRAM_PAYMENT_REQUEST",
  DELETE_PROGRAM_PAYMENT_SUCCESS: "DELETE_PROGRAM_PAYMENT_SUCCESS",
  DELETE_PROGRAM_PAYMENT_REJECTED: "DELETE_PROGRAM_PAYMENT_REJECTED",

  // GET COURSE DETAILS
  GET_COURSE_DETAILS_REQUEST: "GET_COURSE_DETAILS_REQUEST",
  GET_COURSE_DETAILS_SUCCESS: "GET_COURSE_DETAILS_SUCCESS",
  GET_COURSE_DETAILS_REJECTED: "GET_COURSE_DETAILS_REJECTED",

  // GET COURSE TREE
  GET_COURSE_TREE_REQUEST: "GET_COURSE_TREE_REQUEST",
  GET_COURSE_TREE_SUCCESS: "GET_COURSE_TREE_SUCCESS",
  GET_COURSE_TREE_REJECTED: "GET_COURSE_TREE_REJECTED",

  // GET COURSE SUBCHAPTER DETAILS
  GET_COURSE_SUBCHAPTER_DETAILS_REQUEST:
    "GET_COURSE_SUBCHAPTER_DETAILS_REQUEST",
  GET_COURSE_SUBCHAPTER_DETAILS_SUCCESS:
    "GET_COURSE_SUBCHAPTER_DETAILS_SUCCESS",
  GET_COURSE_SUBCHAPTER_DETAILS_REJECTED:
    "GET_COURSE_SUBCHAPTER_DETAILS_REJECTED",

  // GET COURSE LESSON DETAILS
  GET_COURSE_LESSON_DETAILS_REQUEST: "GET_COURSE_LESSON_DETAILS_REQUEST",
  GET_COURSE_LESSON_DETAILS_SUCCESS: "GET_COURSE_LESSON_DETAILS_SUCCESS",
  GET_COURSE_LESSON_DETAILS_REJECTED: "GET_COURSE_LESSON_DETAILS_REJECTED",

  // SAVE WATCHING PROGRESS
  SAVE_WATCHING_PROGRESS_REQUEST: "SAVE_WATCHING_PROGRESS_REQUEST",
  SAVE_WATCHING_PROGRESS_SUCCESS: "SAVE_WATCHING_PROGRESS_SUCCESS",
  SAVE_WATCHING_PROGRESS_REJECTED: "SAVE_WATCHING_PROGRESS_REJECTED",

  // sendNotificationToGiftSender
  SEND_NOTIFICATION_TO_GIFT_SENDER_REQUEST:
    "SEND_NOTIFICATION_TO_GIFT_SENDER_REQUEST",
  SEND_NOTIFICATION_TO_GIFT_SENDER_SUCCESS:
    "SEND_NOTIFICATION_TO_GIFT_SENDER_SUCCESS",
  SEND_NOTIFICATION_TO_GIFT_SENDER_REJECTED:
    "SEND_NOTIFICATION_TO_GIFT_SENDER_REJECTED",

  //add note
  ADD_NOTE_TO_LESSON_REQUEST: "ADD_NOTE_TO_LESSON_REQUEST",
  ADD_NOTE_TO_LESSON_SUCCESS: "ADD_NOTE_TO_LESSON_SUCCESS",
  ADD_NOTE_TO_LESSON_REJECTED: "ADD_NOTE_TO_LESSON_REJECTED",
  // edit note
  EDIT_NOTE_TO_LESSON_REQUEST: "EDIT_NOTE_TO_LESSON_REQUEST",
  EDIT_NOTE_TO_LESSON_SUCCESS: "EDIT_NOTE_TO_LESSON_SUCCESS",
  EDIT_NOTE_TO_LESSON_REJECTED: "EDIT_NOTE_TO_LESSON_REJECTED",
  // delete note
  DELETE_NOTE_TO_LESSON_REQUEST: "DELETE_NOTE_TO_LESSON_REQUEST",
  DELETE_NOTE_TO_LESSON_SUCCESS: "DELETE_NOTE_TO_LESSON_SUCCESS",
  DELETE_NOTE_TO_LESSON_REJECTED: "DELETE_NOTE_TO_LESSON_REJECTED",
};
