import React, {useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from "chart.js";
import {Bar} from "react-chartjs-2";
import {useTranslation} from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart(props) {
  const { t } = useTranslation();
  const [sum, setSum] = useState([]);

  let courses = props.courses

  let course = [];
  useEffect(() => {
    for (let i = 0; i < 12; i++) {
      for (let j = 0; j < courses.length; j++) {
        if (courses[j].month == i + 1) {
          course.push(courses[j]);
        }
      }
      if (course[i] == undefined) {
        course.push({
          year: new Date().getFullYear(),
          month: i + 1,
          sum: "0",
        });
      }
    }
    let sumCourse = course.map((c) => {
      return c.sum;
    });
    setSum(sumCourse);
  }, [props.courses]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  const labels = [
    t("dashBoard.label.jan"),
    t("dashBoard.label.feb"),
    t("dashBoard.label.mar"),
    t("dashBoard.label.api"),
    t("dashBoard.label.may"),
    t("dashBoard.label.jun"),
    t("dashBoard.label.jul"),
    t("dashBoard.label.aug"),
    t("dashBoard.label.spe"),
    t("dashBoard.label.oct"),
    t("dashBoard.label.nov"),
    t("dashBoard.label.dec"),
  ];

  const data = {
    labels,
    datasets: [
      {
        label: t("dashBoard.label.course"),
        data: [...sum],
        backgroundColor: "rgba(78, 121, 167, 0.7)",
      },
    ],
  };

  return (
    <Bar
      options={options}
      data={data}
      style={{
        background: "#fff",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "2px 2px 10px #ddd",
      }}
    />
  );
}

export default BarChart;
