import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getNotifications,
  readNotifications,
} from "../../../../../Services/api/Notifications/notificationsProvider";
import { AuthContext } from "../../../../../Services/api/auth/AuthContext";
import i18n from "../../../../../i18n/i18n";

import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { useHistory } from "react-router";
import { NavLink, withRouter } from "react-router-dom";
import toLocalTimezone from "../../../../../Services/Timezone";
import notification from "../../../../../assets/image/notification.png";
import profileAvatar from "../../../../../assets/image/profile-avatar.png";
import ar from "../../../../../assets/image/saudi-arabia.png";
import en from "../../../../../assets/image/united-kingdom.png";
import GlobIcon from "../../../../../assets/icons/globIcon.svg";

import { Lang } from "../../../../../utils";
import UserDropDown from "./UserDropDwon/UserDropDown";

function DynamicHeaderWithOutLogin(props) {
  require("./DynamicHeaderWithoutLogin.css");
  const [ListOfNotification, setListOfNotification] = useState([]);
  const [ReadNotification, setReadNotification] = useState(false);
  const [IconReadNotification, setIconReadNotification] = useState(false);
  const [LoadComponent, setLoadComponent] = useState(false);
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();
  document.body.dir = i18n.dir();
  const theme = useTheme();
  const type_login = localStorage.getItem("type_login");

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenChat");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("name_ar");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("type_login");
    localStorage.removeItem("chatId");
    localStorage.removeItem("userId");
    localStorage.removeItem("chat_username");
    localStorage.removeItem("chat_email");
    localStorage.removeItem("type");
    localStorage.removeItem("completed_profile");
    localStorage.removeItem("group");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
    window.location.reload();
  };

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };

  let buttonLang = <div onClick={() => changeLanguage("ar")}>AR</div>;
  if (document.body.dir === "rtl") {
    buttonLang = (
      <div className="lang_style" onClick={() => changeLanguage("en")}>
        <div className="lang_btn_qassem">
          <img src={GlobIcon} alt="" style={{ width: 20, height: 20 }} />

          <div className="">English</div>
          <img src={en} alt="" />
        </div>
      </div>
    );
  } else {
    buttonLang = (
      <div className="lang_style" onClick={() => changeLanguage("ar")}>
        <div className="lang_btn_qassem">
          <img src={ar} alt="" />
          <div className="">العربية</div>
          <img src={GlobIcon} alt="" style={{ width: 20, height: 20 }} />
        </div>
      </div>
    );
  }

  const _getNotifications = async () => {
    let res = await getNotifications();
    if (res.status == 201) {
      console.log(res.data.response.data);
      let notDataArr = res.data.response;

      if (Object.keys(notDataArr).length > 0 || notDataArr.length > 0) {
        setListOfNotification(notDataArr.data);
        setIconReadNotification(true);
      } else {
        setListOfNotification([]);
        setIconReadNotification(false);
      }
    }
  };

  useEffect(() => {
    let interval,
      role = localStorage.getItem("type");
    _getNotifications();
    interval = setInterval(() => {
      _getNotifications();
    }, 300000); // Call After 5min
    return () => {
      clearInterval(interval);
    };
  }, []);
  const userOptions = [
    ...(authContext.roles.includes("admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/admin"),
          },
        ]
      : authContext.roles.includes("accountant")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/accountant"),
          },
        ]
      : authContext.roles.includes("group-admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/group-admin"),
          },
        ]
      : authContext.roles.includes("manager")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/manager"),
          },
        ]
      : []),

    {
      label: t("selectGroups"),
      onClick: () => history.push("/my-groups"),
    },
    {
      label: t("account_info"),
      onClick: () => history.push("/private-profile"),
    },
    {
      label: t("edit_personal_profile"),
      onClick: () => history.push("/edit-profile"),
    },
    ...(type_login === "normal"
      ? [
          {
            label: t("accountde"),
            onClick: () => history.push("/update-password"),
          },
        ]
      : []),

    // {
    //   label: t("admin.label.list_tickets"),
    //   onClick: () =>
    //     history.push(
    //       `${
    //         localStorage.getItem("type") === "admin"
    //           ? "/admin/tickets"
    //           : "/tickets"
    //       }`
    //     ),
    // },
    {
      label: t("logout"),
      onClick: logout,
    },
  ];

  let notification_read = localStorage.getItem("ReadNotification");
  const loginNav = (
    <>
      <UserDropDown options={userOptions} />
      {/* Notification */}
      <div className="dropdown">
        <button
          type="button"
          className="btn dropdown-toggle header-dropdown header_nav_link notification"
          data-toggle="dropdown"
          onClick={_getNotifications}
        >
          <i
            className="fa fa-bell"
            style={{ fontSize: "25px", color: "#333" }}
          ></i>
          {!ReadNotification ? (
            notification_read === "true" ? null : IconReadNotification ? (
              <span className="notification-recently"></span>
            ) : null
          ) : null}
        </button>
        <div
          className="dropdown-menu notifications-box scroll-y"
          onMouseMove={() => {
            if (ListOfNotification.length > 0) {
              setReadNotification(true);
              console.log("notification_read", notification_read);
              let listIds = [];

              if (!notification_read || notification_read != "true") {
                localStorage.setItem("ReadNotification", "true");
                ListOfNotification.map((list, key) => {
                  listIds.push(list.id);
                  if (ListOfNotification.length - 1 === key) {
                    readNotifications(listIds);
                    localStorage.setItem("ReadNotification", "false");
                    localStorage.removeItem("ReadNotification");
                    setReadNotification(false);
                  }
                });
              }
            }
          }}
        >
          <h4> {t("notification")} </h4>
          {ListOfNotification.length == 0 ? (
            <div className="box-notify text-center">
              <p>{t("notNotification")}</p>
            </div>
          ) : null}
          {ListOfNotification.length != 0 &&
            ListOfNotification.map((notification, key) => {
              return (
                <div
                  className={`box-notify ${
                    !notification.is_read && !ReadNotification
                      ? notification_read === "true"
                        ? null
                        : " new"
                      : null
                  }`}
                  key={key}
                >
                  <div className="type-time">
                    <span>
                      {` ${notification.type} ${
                        (!notification.is_read && !ReadNotification) ||
                        notification_read != "true"
                          ? t("new_noti")
                          : ""
                      } `}
                    </span>
                    <span>
                      {moment(
                        toLocalTimezone(notification.created_at)
                      ).fromNow()}
                    </span>
                  </div>
                  <p> {notification.msg} </p>
                </div>
              );
            })}
        </div>
      </div>
      {/* End */}
    </>
  );

  return (
    <div className="header_dinamic">
      {loginNav}
      {buttonLang}
    </div>
  );
}

export default withRouter(DynamicHeaderWithOutLogin);

// <div className="dropdown user-dropdown">
//   <button
//     type="button"
//     className="dropdown-toggle header-dropdown header_nav_link new_header_qassem"
//     data-toggle="dropdown"
//   >
//     {localStorage.getItem("img") == "null" ? (
//       <img src={profileAvatar} className="admin_img" />
//     ) : (
//       <img src={localStorage.getItem("img")} alt="" className="admin_img" />
//     )}
//     <span className="profile_name_qassem">
//       {i18n.language === Lang.AR
//         ? localStorage.getItem("name_ar") !== null
//           ? localStorage.getItem("name_ar").split(" ")[0]
//           : null
//         : localStorage.getItem("name_en") != null
//         ? localStorage.getItem("name_en").split(" ")[0]
//         : null}
//     </span>
//   </button>
//   <div className="dropdown-menu">
//     {/* todo: */}
//     {authContext.roles.includes("admin") ? (
//       <NavLink className="dropdown-item" to="/admin">
//         {t("admin.label.dashboard")}
//       </NavLink>
//     ) : authContext.roles.includes("accountant") ? (
//       <NavLink className="dropdown-item" to="/accountant">
//         {t("admin.label.dashboard")}
//       </NavLink>
//     ) : authContext.roles.includes("group-admin") ? (
//       <NavLink className="dropdown-item" to="/group-admin">
//         {t("admin.label.dashboard")}
//       </NavLink>
//     ) : null}
//     <NavLink className="dropdown-item" to="/edit-profile">
//       {t("edit_personal_profile")}
//     </NavLink>
//     <NavLink className="dropdown-item" to="/my-groups">
//       {t("selectGroups")}
//     </NavLink>
//     <NavLink className="dropdown-item" to="/private-profile">
//       {t("account_info")}
//     </NavLink>
//     {type_login === "normal" ? (
//       <NavLink className="dropdown-item" to="/update-password">
//         {t("accountde")}
//       </NavLink>
//     ) : null}
//     <button className="dropdown-item" type="submit" onClick={logout}>
//       {t("logout")}
//     </button>
//   </div>
// </div>;
