export const livechatConstant = {
  // loading
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",

  // user
  GET_LIVECHAT_SETTING: "GET_LIVECHAT_SETTING",
  POST_LIVECHAT_SEND_MESSAGE: "POST_LIVECHAT_SEND_MESSAGE",
  POST_LIVECHAT_CLOSE_SESSION: "POST_LIVECHAT_CLOSE_SESSION",

  // admin
  GET_LIVECHAT_SESSION: "GET_LIVECHAT_SESSION",
  POST_LIVECHAT_AUTO_REPLY: "POST_LIVECHAT_AUTO_REPLY",
  POST_LIVECHAT_STATUS: "POST_LIVECHAT_STATUS",

  GET_LIVECHAT_REPLY: "GET_LIVECHAT_REPLY",
  POST_LIVECHAT_ADD_REPLY: "POST_LIVECHAT_ADD_REPLY",
  POST_LIVECHAT_EDIT_REPLY: "POST_LIVECHAT_EDIT_REPLY",
  POST_LIVECHAT_REPLY_STATUS: "POST_LIVECHAT_REPLY_STATUS",
  DELETE_LIVECHAT_REPLY: "DELETE_LIVECHAT_REPLY",
  GET_LIVECHAT_REPLY_BY_ID: "GET_LIVECHAT_REPLY_BY_ID",

  POST_LIVECHAT_STANDERMESSAGE: "POST_LIVECHAT_STANDERMESSAGE",

  GET_LIVECHAT_SUPPORT_USERS: "GET_LIVECHAT_SUPPORT_USERS",
  POST_LIVECHAT_UPDATE_SUPPORT_USERS: "POST_LIVECHAT_UPDATE_SUPPORT_USERS",
  GET_LIVECHAT_SUPPORT_USERS_BY_ID: "GET_LIVECHAT_SUPPORT_USERS_BY_ID",
  DELETE_LIVECHAT_SUPPORT_USERS: "DELETE_LIVECHAT_SUPPORT_USERS",
  POST_LIVECHAT_SUPPORT_USERS_STATUS: "POST_LIVECHAT_SUPPORT_USERS_STATUS",

  POST_LIVECHAT_SESSION_ASSIGN: "POST_LIVECHAT_SESSION_ASSIGN",
  GET_LIVECHAT_VIEW_SESSION: "GET_LIVECHAT_VIEW_SESSION",
  GET_LIVECHAT_MESSAGES: "GET_LIVECHAT_MESSAGES",

  LIVECHAT_CHANGE_MESSAGE: "LIVECHAT_CHANGE_MESSAGE",
  GET_LIVECHAT_SCHEDULE: "GET_LIVECHAT_SCHEDULE",

  GET_PRIVATE_CHAT_SESSIONS: "GET_PRIVATE_CHAT_SESSIONS",
  GET_COURSE_TRAINEES: "GET_COURSE_TRAINEES",
  POST_FIRST_MESSAGE: "POST_FIRST_MESSAGE",

  RESET_LIVECHAT: "RESET_LIVECHAT",

  GET_TRAINEE_CHAT_SESSIONS_REQUEST: "GET_TRAINEE_CHAT_SESSIONS_REQUEST",
  GET_TRAINEE_CHAT_SESSIONS_SUCCESS: "GET_TRAINEE_CHAT_SESSIONS_SUCCESS",
  GET_TRAINEE_CHAT_SESSIONS_FAILURE: "GET_TRAINEE_CHAT_SESSIONS_FAILURE",
};
