import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCoursesList, fetchCoursesList } from "../../../../Redux/Actions/courses/coursesList.action";
import toLocalTimezone, { toServerTime } from "../../../../Services/Timezone";
import deleteIcon from "../../../../assets/icons/delete.svg";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import DataTable from "../../../Shared/Components/DataTable/DataTable";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Title from "../../../Shared/Components/Title/Title";
import classes from "./programPaymentList.module.css";

export default function ProgramPaymentModal({
  programPayment,
  programId,
  closeModal,
  submitHandler,
  paymentsAmount,
  programPrice,
  startRegisterDate,
  firstPayment,
}) {
  const { t } = useTranslation();
  const { coursesList, programPaymentsList } = useSelector((state) => state.coursesListReducer);
  const [startDateHours, setStartDateHours] = useState(null);
  const [startDateMinutes, setStartDateMinutes] = useState(null);
  const [endDateHours, setEndDateHours] = useState(null);
  const [endDateMinutes, setEndDateMinutes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [coursesListOptions, setCoursesListOptions] = useState([]);

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "paymentDate") {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endDate") {
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    }
  };

  useEffect(() => {
    currentDate();
    const coursesAlreadyAdded = programPaymentsList
      .map((item) => item.courses)
      .flat()
      .map((item) => item.id);
    const currentCoursesList = programPayment?.courses.map((item) => item.id) || [];
    setCoursesListOptions(
      coursesList.filter((item) => !coursesAlreadyAdded.includes(item.id) || currentCoursesList.includes(item.id))
    );
    setLoading(false);
  }, []);

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours === "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  const getLocaleTime = (date) => {
    const offset = new Date().getTimezoneOffset();
    const localeTime = date.getTime() - offset * 60 * 1000;
    return new Date(localeTime);
  };

  const coursesFields = [
    // {
    //   id: "order",
    //   column: ({ rowData, rowIndex }) => {
    //     return <>{rowIndex + 1}</>;
    //   },
    // },
    {
      id: "id",
      column: ({ rowData }) => <>{rowData.id}</>,
    },
    {
      id: "name",
      column: ({ rowData }) => <>{rowData.name || "-"}</>,
    },
    {
      id: "code",
      column: ({ rowData }) => <>{rowData.code || "-"}</>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <>{rowData.run_serial_number || "-"}</>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => <>{rowData.start_date ? toLocalTimezone(rowData.start_date) : "-"}</>,
    },
  ];

  return (
    <MainBox className={classes["program__payment__modal"]}>
      {loading ? (
        <SkeletonCardOverlay skeletonWidth="100" />
      ) : (
        <>
          <div className={classes["program__payment__modal__header"]}>
            <div className={classes["program__payment__modal__header__title"]}>
              <Title title={t(`trainer.program.${programPayment ? "edit_program_payment" : "add_program_payment"}`)} />
              {firstPayment && (
                <p className={classes["program__payment__modal__header__note"]}>
                  {t("trainer.program.first_payment_note")}
                </p>
              )}
            </div>
            <img src={deleteIcon} alt="close" onClick={closeModal} />
          </div>
          <Formik
            initialValues={{
              amount: programPayment?.amount || "",
              course_ids: programPayment?.courses.map((item) => item.id) || [],
              payment_date: firstPayment
                ? getLocaleTime(new Date(startRegisterDate))
                : programPayment?.payment_date
                ? getLocaleTime(new Date(programPayment?.payment_date))
                : setHours(setMinutes(new Date(), startDateMinutes), startDateHours),
              end_date: programPayment?.end_date
                ? getLocaleTime(new Date(programPayment?.end_date))
                : setHours(setMinutes(new Date(), endDateMinutes), endDateHours),
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const dataToSend = {
                ...values,
                program_id: programId,
                payment_date: toServerTime(formatDate(values.payment_date) + " " + formatTime(values.payment_date)),
                end_date: toServerTime(formatDate(values.end_date) + " " + formatTime(values.end_date)),
              };

              submitHandler({ id: programPayment?.id, data: dataToSend });
              setSubmitting(false);
            }}
            validateOnChange={true}
            validate={(values) => {
              const errors = {};
              console.log("values :>> ", values);
              if (!values.amount) {
                errors.amount = t("crud.errors.required");
              }
              if (values.amount && values.amount <= 0) {
                errors.amount = t("crud.errors.greater_than_zero");
              }
              if (
                programPayment &&
                values.amount &&
                values.amount > Number(programPrice - (Number(paymentsAmount) - Number(programPayment?.amount)))
              ) {
                errors.amount = t("trainer.program.max_amount");
              } else if (
                !programPayment &&
                values.amount &&
                values.amount > Number(programPrice - Number(paymentsAmount))
              ) {
                errors.amount = t("trainer.program.max_amount");
              }
              if (!values.course_ids.length) {
                errors.course_ids = t("crud.errors.required");
              }

              return errors;
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              handleBlur,
              setFieldValue,
              validateForm,
            }) => (
              <form onSubmit={handleSubmit} className={classes["program__payment__modal__form"]}>
                <div className={`row ${classes["program__payment__modal__form__fields"]}`}>
                  <div className={`${classes["program__payment__modal__form__field"]} col-12`}>
                    <Input
                      label={t("trainer.program.amount")}
                      type="number"
                      name="amount"
                      placeholder={t("trainer.program.amount")}
                      onChange={handleChange}
                      error={errors.amount}
                      value={values.amount}
                      note={t(
                        `${t("trainer.program.amount_note")} ${
                          programPayment
                            ? Number(programPrice) - (Number(paymentsAmount) - Number(programPayment?.amount))
                            : Number(programPrice) - Number(paymentsAmount)
                        } ${t("rs")}`
                      )}
                      required
                    />
                  </div>
                  <div className={`${classes["program__payment__modal__form__field"]} col-12 col-md-6`}>
                    <MainDatePicker
                      label={t(`trainer.course.payment_date`)}
                      name="payment_date"
                      error={errors.payment_date}
                      value={values.payment_date}
                      onChange={(date) => {
                        setFieldValue("payment_date", date);
                        values.end_date < date && setFieldValue("end_date", date);
                        let CurrentDate = new Date();
                        let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                        let DataPickerValue = date.toISOString().split("T")[0];
                        if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                          setStartDateHours(0);
                          setStartDateMinutes(0);
                        } else {
                          currentDate("paymentDate");
                        }
                      }}
                      minDate={new Date()}
                      minTime={setHours(setMinutes(new Date(), startDateMinutes), startDateHours)}
                      maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      required
                      readOnly={firstPayment}
                    />
                  </div>
                  <div className={`${classes["program__payment__modal__form__field"]} col-12 col-md-6`}>
                    <MainDatePicker
                      label={t(`trainer.course.payment_end_date`)}
                      name="end_date"
                      error={errors.end_date}
                      value={values.end_date}
                      onChange={(date) => {
                        setFieldValue("end_date", date);
                        let CurrentDate = new Date();
                        let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                        let DataPickerValue = date.toISOString().split("T")[0];
                        if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                          setStartDateHours(0);
                          setStartDateMinutes(0);
                        } else {
                          currentDate("endDate");
                        }
                      }}
                      minDate={new Date(values.payment_date)}
                      minTime={setHours(setMinutes(new Date(), startDateMinutes), startDateHours)}
                      maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      required
                    />
                  </div>
                  <div className={`${classes["program__payment__modal__form__field"]} col-12`}>
                    <DataTable
                      label={t("trainer.program.courses")}
                      selectedItems={values.course_ids}
                      isLoading={false}
                      data={coursesListOptions}
                      fields={coursesFields}
                      error={errors.course_ids}
                      searchableFields={["id", "name", "run_serial_number", "code", "payment_date"]}
                      selectedHandler={(value) => {
                        setFieldValue(
                          "course_ids",
                          values.course_ids.includes(value.id)
                            ? values.course_ids.filter((item) => item !== value.id)
                            : [...values.course_ids, value.id]
                        );
                      }}
                      isMultiple
                      canSelectAll
                      toggleSelectAllHandler={(value) => {
                        setFieldValue(
                          "course_ids",
                          values.course_ids.length === coursesListOptions.length
                            ? []
                            : coursesListOptions.map((item) => item.id)
                        );
                      }}
                      required
                    />
                  </div>
                  <div className={`${classes["program__payment__modal__form__submit__container"]} col-12`}>
                    <CustomButton
                      type="submit"
                      colors="#036c77"
                      value={t("trainer.course.add_course_button")}
                      classes={classes["program__payment__modal__form__submit__button"]}
                      disable={isSubmitting}
                      loading={isSubmitting}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </MainBox>
  );
}
