import i18n from "../../../i18n/i18n";
import { publishReqsListConstants } from "../../Constants/publishReq/publishReq.constant";

const initialState = {
  publishReqsList: [],
  publishReqsListMeta: {},
  publishReqsListLoading: false,
  publishReqsListError: null,
  publishReqsListSuccess: false,

  RejectReqLoading: false,
  RejectReqError: null,
  RejectReqSuccess: false,

  //   deleteAdvertisementLoading: false,
  //   deleteAdvertisementSuccess: false,
  //   deleteAdvertisementError: null,
};

const publishReqsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case publishReqsListConstants.GET_PUBLISHREQS_LIST_REQUEST:
      return {
        ...state,
        publishReqsListLoading: true,
      };
    case publishReqsListConstants.GET_PUBLISHREQS_LIST_SUCCESS:
      return {
        ...state,
        publishReqsListLoading: false,
        publishReqsListSuccess: true,
        publishReqsList: action.payload.requests.data,
        publishReqsListMeta: action.payload.requests.meta,
      };
    case publishReqsListConstants.GET_PUBLISHREQS_LIST_REJECTED:
      return {
        ...state,
        publishReqsListLoading: false,
        publishReqsListError: action.payload,
        publishReqsListSuccess: false,
      };

    //  ACcept req
    case publishReqsListConstants.ACCEPT_PUBLISHREQ_REQUEST:
      return {
        ...state,
        RejectReqLoading: true,
      };

    case publishReqsListConstants.ACCEPT_PUBLISHREQ_SUCCESS:
      return {
        ...state,
        RejectReqLoading: false,
        RejectReqSuccess: true,
        RejectReqError: null,

        publishReqsList: state.publishReqsList.map((req) =>
          req.id === action.payload.id
            ? { ...req, status: i18n.language === "ar" ? "اعتمدت" : "Approved" }
            : { ...req }
        ),
      };

    case publishReqsListConstants.ACCEPT_PUBLISHREQ_REJECTED:
      return {
        ...state,
        RejectReqLoading: false,
        RejectReqError: action.payload,
        RejectReqSuccess: false,
      };
    //  REJECT req
    case publishReqsListConstants.REJECT_PUBLISHREQ_REQUEST:
      return {
        ...state,
        RejectReqLoading: true,
      };

    case publishReqsListConstants.REJECT_PUBLISHREQ_SUCCESS:
      return {
        ...state,
        RejectReqLoading: false,
        RejectReqSuccess: true,
        RejectReqError: null,

        publishReqsList: state.publishReqsList.map((req) =>
          req.id === action.payload.id
            ? { ...req, status: i18n.language === "ar" ? "رفضت" : "Rejected" }
            : { ...req }
        ),
      };

    case publishReqsListConstants.REJECT_PUBLISHREQ_REJECTED:
      return {
        ...state,
        RejectReqLoading: false,
        RejectReqError: action.payload,
        RejectReqSuccess: false,
      };
    //  DELETE_ Advertisement
    // case publishReqsListConstants.DELETE_PUBLISHREQ_REQUEST:
    //   return {
    //     ...state,
    //     deleteAdvertisementLoading: true,
    //   };

    // case publishReqsListConstants.DELETE_PUBLISHREQ_SUCCESS:
    //   return {
    //     ...state,
    //     deleteAdvertisementLoading: false,
    //     deleteAdvertisementSuccess: true,
    //     deleteAdvertisementError: null,

    //     advertisementsList: state.advertisementsList.filter(
    //       (advertisement) => advertisement.id !== action.payload.id
    //     ),
    //   };

    // case publishReqsListConstants.DELETE_PUBLISHREQ_REJECTED:
    //   return {
    //     ...state,
    //     deleteAdvertisementLoading: false,
    //     deleteAdvertisementError: action.payload,
    //     deleteAdvertisementSuccess: false,
    //   };

    default:
      return state;
  }
};

export default publishReqsListReducer;
