import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import SkeletonCard from "./../../Shared/Components/Spinner/SkeletonCard";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { Redirect, useHistory, useParams } from "react-router";
import logoCertificate from "../../../assets/image/logoCertificate.png";
import {
  getcertificate,
  getDownloadcertificate,
} from "../../../Services/api/certificate/CertificateProvider";

export default function Certificate() {
  require("./certificate.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [certificate, setcertificate] = useState([]);

  useEffect(() => {
    _getcertificate(id);
  }, []);
  const _getcertificate = async (id) => {
    const res = await getcertificate(id);

    if (res.status == 200) {
      setIsLoading(false);
      setcertificate(res.data.response);
    }
  };
  const downloadCertificat = async () => {
    const res = await getDownloadcertificate(id);
  };

  return (
    <div style={{ background: "#fff" }}>
      <div
        className="container-fluid profile_wrapper"
        style={{ paddingTop: "60px" }}
      >
        <div className="admin_label" style={{ margin: 0 }}>
          <p style={{ padding: "0 5px", margin: 0 }}>/</p>
          <NavLink to="/">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px", margin: 0 }}>/</p>
          {t("certificate.certificate")}
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div
          className={
            isLoading ? null : "table_bank_tran table_bank_tran-certificate"
          }
        >
          {isLoading ? (
            <div style={{ height: "65vh", width: "100%" }}>
              <SkeletonCard />
            </div>
          ) : (
            <>
              {
                certificate && certificate.survey ? (
                  i18n.language == "en" ? (
                    <>
                      <div
                        style={{
                          width: "60%",
                          border: "1px solid #4d918d0d",
                          borderBottom: "15px solid #4d918d",
                        }}
                      >
                        <div
                          style={{ background: "#eff3f1", textAlign: "center" }}
                        >
                          <img
                            src={logoCertificate}
                            alt="logo certificate"
                            width="120"
                          />
                        </div>
                        <div
                          style={{
                            background: "#fff",
                            textAlign: "center",
                            padding: "20px 0",
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "cursive",
                              fontSize: "28px",
                              color: "#367e7b",
                            }}
                          >
                            certificate of attendance
                          </h3>
                          <p
                            style={{
                              margin: "0",
                              padding: "20px 30px",
                              fontSize: "18px",
                              lineHeight: "30px",
                              color: "#515151",
                            }}
                          >
                            The Deanship of E-Learning and Distance Learning at
                            Imam Abdulrahman Bin Faisal University in Dammam is
                            pleased to award Mr. / Mrs.{" "}
                            <span>{certificate.trainee_name_en} </span>a
                            Certificate of attendance for the course
                            <span> {certificate.title}. </span>
                            <br />
                            from Date
                            <span> {certificate.start_date} </span>
                            To date
                            <span> {certificate.end_date} </span>
                            in ({certificate.hours}) hours
                          </p>
                        </div>
                        <div
                          style={{ display: "flex", padding: "4px 0px 20px 0" }}
                        >
                          <div
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            {/* <h4 style={{ fontSize: "18px",fontFamily: "cursive" }}>  </h4> */}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "18px",
                                fontFamily: "cursive",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontFamily: "auto",
                                  display: "block",
                                  paddingBottom: "10px",
                                  color: "#595959",
                                }}
                              >
                                Signature
                              </span>
                              {certificate.manager_en}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <button
                        style={{
                          margin: "30px 20px",
                          border: "1px solid #4d918d",
                          background: "#4d918d",
                          color: "#fff",
                          padding: " 5px 25px",
                          borderRadius: "5px",
                        }}
                        onClick={() => downloadCertificat()}
                      >
                        Download Certificat{" "}
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "60%",
                          border: "1px solid #4d918d0d",
                          borderBottom: "15px solid #4d918d",
                        }}
                      >
                        <div
                          style={{ background: "#eff3f1", textAlign: "center" }}
                        >
                          <img
                            src={logoCertificate}
                            alt="logo certificate"
                            width="120"
                          />
                        </div>
                        <div
                          style={{
                            background: "#fff",
                            textAlign: "center",
                            padding: "20px 0",
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "cursive",
                              fontSize: "28px",
                              color: "#367e7b",
                            }}
                          >
                            شهادة حضور
                          </h3>
                          <p
                            style={{
                              margin: "0",
                              padding: "20px 30px",
                              fontSize: "18px",
                              lineHeight: "30px",
                              color: "#515151",
                            }}
                          >
                            تسر عمادة التعليم الالكتروني والتعلم عن بعد في جامعة
                            الإمام عبدالرحمن بن فيصل في الدمام بأن تمنح السيد /
                            السيدة
                            <span> {certificate.trainee_name_ar} </span>
                            شهادة حضور دورة
                            <span> {certificate.title}. </span>
                            <br />
                            خلال الفترة من
                            <span> {certificate.start_date} </span>
                            الي
                            <span> {certificate.end_date} </span>
                            عدد الساعات ({certificate.hours})
                          </p>
                        </div>
                        <div
                          style={{ display: "flex", padding: "4px 0px 20px 0" }}
                        >
                          <div
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <h4 style={{ fontSize: "18px" }}>
                              <span
                                style={{
                                  fontSize: "13px",
                                  display: "block",
                                  paddingBottom: "10px",
                                  color: "#595959",
                                }}
                              >
                                توقيع
                              </span>
                              {certificate.manager_ar}
                            </h4>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          ></div>
                        </div>
                      </div>
                      <button
                        style={{
                          margin: "30px 20px",
                          border: "1px solid #4d918d",
                          background: "#4d918d",
                          color: "#fff",
                          padding: " 5px 25px",
                          borderRadius: "5px",
                        }}
                        onClick={() => downloadCertificat()}
                      >
                        تحميل الشهادة
                      </button>
                    </>
                  )
                ) : certificate?.supplement_id && certificate.surveies ? (
                  <Redirect
                    to={`/survey/${certificate.surveies[0]?.id}/course/${certificate.supplement_id}`}
                  />
                ) : (
                  console.log(certificate)
                )
                // <>
                //     <p> {t('certificate.survey_filled_out')}</p>
                //     <a
                //         style={{
                //             margin: "30px 20px",
                //             border: "1px solid #4d918d",
                //             background: "#4d918d",
                //             color: "#fff",
                //             padding:" 5px 25px",
                //             borderRadius: "5px"}}
                //             href={`/survey/${certificate.surveies[0]?.id}/course/${certificate.supplement_id`}
                //             >{t('certificate.Refer_fill_out')}</a>
                // </>
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
}
