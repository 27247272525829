import moment from "moment";
import React, { useEffect, useRef } from "react";
import toLocalTimezone from "../../../../../Services/Timezone";
import logo from "../../../../../assets/image/Logo Tebx.png";
import classes from "./massages.module.css";
import i18n from "../../../../../i18n/i18n";

const Massages = ({ messages, height }) => {
  const messagesContainer = useRef(null);
  const currentUserid = localStorage.getItem("userId");
  function addLinksToHtml(str) {
    // Regular expression to match URLs
    var urlRegex = /(https?:\/\/[^\s]+)/g;

    // Check if there are any matches
    var hasMatches = str.match(urlRegex);

    if (hasMatches) {
      // Replace all matches with a single <a> tag containing all links
      str = str.replace(urlRegex, function (match) {
        return '<a href="' + match + '" target="_blank">' + match + "</a>";
      });
    }

    return str;
  }

  const displayImage = (item) => {
    return item.user_id != currentUserid &&
      item.user_id != messages[messages.indexOf(item) - 1]?.user_id ? (
      <img
        src={item.user.img || item.chat.user.img || item.user.image || logo}
        alt=""
      />
    ) : null;
  };

  const displayDate = (item) => {
    return moment(new Date(toLocalTimezone(item.created_at))).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  };

  useEffect(() => {
    messagesContainer.current.scrollTop =
      messagesContainer.current.scrollHeight;
  }, [messages]);

  return (
    <div
      className={classes.messagesContainer}
      style={{ maxHeight: height }}
      ref={messagesContainer}
    >
      {messages.length > 0 &&
        messages.reverse().map((item, index) => {
          return (
            <div className={classes.messageContainer} key={item.id || index}>
              <div
                className={`${classes["content-container"]} ${
                  item.user_id == currentUserid ? classes.right : classes.left
                }`}
              >
                {i18n.language === "ar" ? (
                  <>
                    <div className={classes["message-content"]}>
                      <div>
                        <span className={classes.date}>
                          {displayDate(item)}
                        </span>
                      </div>
                      <div
                        className={classes["message-text"]}
                        dangerouslySetInnerHTML={{
                          __html: addLinksToHtml(item.message),
                        }}
                      >
                        {/* {item.message} */}
                      </div>
                    </div>
                    <div className={classes["message-image"]}>
                      {displayImage(item)}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes["message-image"]}>
                      {displayImage(item)}
                    </div>
                    <div className={classes["message-content"]}>
                      <div>
                        <span className={classes.date}>
                          {displayDate(item)}
                        </span>
                      </div>
                      <div
                        className={classes["message-text"]}
                        dangerouslySetInnerHTML={{
                          __html: addLinksToHtml(item.message),
                        }}
                      >
                        {/* {item.message} */}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Massages;
