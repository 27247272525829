import React from "react";
import creditCard from "../../../../assets/image/dashboardFinancial/credit-card.png";
import paying from "../../../../assets/image/dashboardFinancial/paying.png";
import onlineLearning from "../../../../assets/image/dashboardFinancial/online-learning.png";
import reciever from "../../../../assets/image/dashboardFinancial/reciever.png";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

function Image(props) {
  require("./SimpleFloatCounter.css");
    const history = useHistory();
    const linkHandler = () => {
        if (props.link) {
            if (localStorage.getItem("type") == "admin"){
                history.push(`${props.link}`);
            }else{
                let accountantPath = props.link.replace('admin', 'accountant')
                console.log(accountantPath)
                history.push(`${accountantPath}`);
            }
        }
    }
  return (
    <div className={`count-row ${props.link ? ' link-cursor': ''}`} onClick={linkHandler}>
      <img src={props.img} alt="" className="count-row-img" />
      <div className="count-row-count">{props.count}</div>
      <div className="count-row-label">{props.label}</div>
    </div>
  );
}


function SimpleFloatCounter(props) {
  const { t } = useTranslation();

  require("./SimpleFloatCounter.css");
  return (
      <div>
    <div className="simple-float-counter-left">
      <Image
        img={reciever}
        label={t("dashBoard.label.totalIncome")}
        count={props.counter && props.counter.totalIncome}
        link={"admin/financial/total-income-report"}
      />
      <hr />
      <Image
        img={paying}
        label={t("dashBoard.label.totaloutcome")}
        count={props.counter && props.counter.totalRefundsAmount}
      />
      <hr />
      <Image
        img={creditCard}
        label={t("dashBoard.label.freeCourse")}
        count={props.counter && props.counter.totalRegisterInFreeCourses}
      />
      <hr />
      <Image
        img={onlineLearning}
        label={t("dashBoard.label.payedCourse")}
        count={props.counter && props.counter.totalRegisterInPaidCourses}
      />
    </div>
        <div className="simple-float-counter-right">
        <Image
            img={onlineLearning}
            label={t("dashBoard.label.totalTaxes")}
            count={props.counter && props.counter.totalTaxes}
            link={"admin/financial/total-income-report"}
        />
        <hr />
        <Image
            img={reciever}
            label={t("dashBoard.label.totalApprovedBankTransfer")}
            count={props.counter && props.counter.totalApprovedBankTransfer}
            link={"admin/financial/total-income-report"}
        />
        <hr />
        <Image
            img={reciever}
            label={t("dashBoard.label.totalPaidOnCourses")}
            count={props.counter && props.counter.totalPaidOnCourses}
            link={"admin/financial/course-due-report"}
        />
        <hr />
        <Image
            img={creditCard}
            label={t("dashBoard.label.totalDiscounts")}
            count={props.counter && props.counter.totalDiscounts}
            link={"admin/financial/total-income-report"}
        />
    </div>
      </div>
  );
}

export default SimpleFloatCounter;
