import React from "react";
import classes from "./textarea.module.css";
const Textarea = ({ placeholder, name, rows = 5, onChange, value, label, error, required }) => {
  return (
    <div className={classes["textarea-field"]}>
      <label for={name}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <textarea
        placeholder={placeholder}
        name={name}
        rows={rows}
        onChange={(e) => {
          onChange(e);
        }}
        value={value}
      ></textarea>
      {error && <p className={`form-input-error-space ${classes["textarea-field-error"]}`}>{error}</p>}
    </div>
  );
};

export default Textarea;
