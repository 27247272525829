import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../../Components";
import { educationalGoalsActions } from "../../../../../Redux/Actions/educationalGoals/educationalGoals.actions";
import { ObjectivesExport } from "../../../../../Services/api/EduGoals/EduGoalsProvider";
import deleteIcon from "../../../../../assets/icons/delete.svg";
import HomeIcon from "../../../../../assets/icons/home.svg";
import PdfIcon from "../../../../../assets/icons/pdf.svg";
import editObjIcon from "../../../../../assets/icons/pen.svg";
import AddIcon from "../../../../../assets/icons/plus.svg";
import DeleteIcon from "../../../../../assets/icons/warning.svg";
import XlsIcon from "../../../../../assets/icons/xls.svg";
import i18n from "../../../../../i18n/i18n";
import { Lang } from "../../../../../utils";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import SwitchToggle from "../../../../Shared/Components/SwitchToggle/SwitchToggle";
import AddTargetModal from "./AddTargetModal/AddTargetModal";
import styles from "./newContent.module.css";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    overflowY: "auto",
    height: "90%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
    "&::-webkit-scrollbar": {
      width: 10,
    },
    "@media (max-width: 1280px)": {
      width: "90%",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#DADADA",
      borderRadius: 10,
    },
  },
  alertOctagon: {
    marginBottom: 38,
    width: 80,
    height: 80,
  },
  confirmPaymentTxt: {
    textAlign: "center",
    fontSize: 24,
    color: "#555555",
    fontWeight: 400,
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    justifyContent: "space-between",
  },
  yesBtn: {
    backgroundColor: "#24B3B9",
    color: "#ffffff",
    borderRadius: 6,
    borderColor: "#EBEBEB",
    width: 150,
    height: 50,
  },
  noBtn: {
    backgroundColor: "#CFCFCF",
    color: "#858585",
    borderRadius: 6,
    borderColor: "#EBEBEB",
    width: 150,
    height: 50,
  },
  btnBack: {
    webkitAppearance: "button",
    backgroundColor: "transparent",
    backgroundImage: "none",
  },
}));

const NewContent = ({ role }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [order, setOrder] = useState({});
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedObjective, setSelectedObjective] = useState({});
  const [deletedObjective, setDeletedObjective] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isUpdating, setIsUpdating] = useState(false);

  const { objectives, loading, addObjectiveLoading, updateStatusLoading } = useSelector(
    (state) => state.educationalGoalsReducer
  );

  useEffect(() => {
    dispatch(educationalGoalsActions.getAllObjectives(id, currentPage));
  }, [id, currentPage]);

  const updateAddModalVisibilityState = (value) => {
    setAddModalVisible(value);
  };

  const updateIsEditedState = (value) => {
    setIsEdited(value);
  };

  const sortActionHandler = async (columnKey) => {
    let key = columnKey;

    setOrder({
      order: key,
      dir: order?.order === key && order?.dir === "ASC" ? "DESC" : "ASC",
    });
  };

  const objectivesActionsList = [
    {
      id: "edit",
      icon: <img src={editObjIcon} alt="" />,
      title: `${t("edit")}`,
      action: (rowData) => {
        setAddModalVisible(true);
        setIsEdited(true);
        setSelectedObjective({ ...rowData });
      },
    },
    {
      id: "delete",
      icon: <img src={deleteIcon} alt="" />,
      title: `${t("delete")}`,
      action: (rowData) => {
        setDeletedObjective({ ...rowData });
        setShowDeleteModal(true);
      },
    },
  ];

  const columns = [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1 + (objectives.meta.currentPage - 1) * 10}</>,
    },
    {
      id: "name",
      name: `${t("edu.goal_title")}`,
      key: `${i18n.language === Lang.AR ? "name_ar" : "name_en"}`,
      cell: ({ rowData, rowIndex }) => <p className={styles["note_table"]}>{rowData[`name_${i18n.language}`]}</p>,
    },
    {
      id: "description",
      name: `${t("edu.goal_details")}`,
      key: `${i18n.language === Lang.AR ? "description_ar" : "description_en"}`,
      cell: ({ rowData, rowIndex }) => (
        <p
          className={styles["note_table"]}
          dangerouslySetInnerHTML={{
            __html: rowData[`description_${i18n.language}`],
          }}
        ></p>
      ),
    },
    ...(role !== "partner"
      ? [
          {
            id: "actions",
            name: t("edu.actions"),
            key: "actions",
            cell: ({ rowData, rowIndex }) => (
              <div className={`${styles.objectives_list__actions} tw-flex tw-items-center`}>
                {objectivesActionsList.map((action, index) => (
                  <IconsTooltip
                    title={action.title}
                    content={
                      <button
                        key={action.id}
                        className={styles.objectives_list__actions_btn}
                        onClick={() => {
                          action.action(rowData, rowIndex);
                        }}
                      >
                        {action.icon}
                      </button>
                    }
                  />
                ))}
              </div>
            ),
          },
        ]
      : []),
    {
      id: "status",
      name: `${t("edu.status")}`,
      key: "status",
      cell: ({ rowData, rowIndex }) => (
        <SwitchToggle
          key={rowData.id}
          status={rowData.status}
          id={rowData.id}
          onStatusChange={() => {
            dispatch(educationalGoalsActions.updateEducationObjectStatus(rowData.id));
          }}
          disabled={role === "partner"}
        />
      ),
    },
  ];

  const actionsList = [
    ...(role !== "partner"
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            theme: "add",
            action: () => {
              setAddModalVisible(true);
            },
          },
        ]
      : []),
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: () => {
        setIsUpdating(true);
        ObjectivesExport(id, null, "pdf")
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_success")}</span>);
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_faild")}</span>);
          });
      },
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: async () => {
        setIsUpdating(true);
        ObjectivesExport(id, null, "xls")
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_success")}</span>);
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("export_faild")}</span>);
          });
      },
    },
  ];

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "Edu_goals",
      page: t("Courseoutline"),
      pagePath: role ? `/${role}/course/${id}` : `/course/${id}`,
    },
    {
      id: "Edu_goals",
      page: t("edu.Edu_goals"),
      active: true,
    },
  ];

  const deleteObjectiveHandler = () => {
    dispatch(educationalGoalsActions.deleteEducationalObjective(deletedObjective.id));
    !addObjectiveLoading && setShowDeleteModal(false);
  };

  return (
    <>
      <div className={styles["tax-list"]}>
        {(isUpdating || updateStatusLoading || loading || addObjectiveLoading) && (
          <SkeletonCardOverlay skeletonWidth="100" />
        )}
        <div className="container-fluid">
          <div className={styles["tax-list__container"]}>
            <div className="row">
              <div className="col-12">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <MainBoxHead title={t("edu.Edu_goals")} actions={actionsList} />
                  <MainTable
                    columns={columns}
                    data={objectives.data}
                    isLoading={loading}
                    sortAction={sortActionHandler}
                  />
                  <Pagination
                    count={!loading && objectives.meta.totalPages}
                    page={currentPage}
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    className="main-pagination"
                    onChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                  />
                </MainBox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={addModalVisible}
        onClose={() => setAddModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={addModalVisible}>
          <Box className={classes.modalContainer}>
            <AddTargetModal
              modalState={updateAddModalVisibilityState}
              courseID={id}
              isEdited={isEdited}
              isEditedState={updateIsEditedState}
              selectedObjective={selectedObjective}
            />
          </Box>
        </Fade>
      </Modal>

      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon}
          title={t("edu.edu_delete_title")}
          name={deletedObjective[`name${i18n.language}`]}
          confirmMsg={t("edu.edu_delete_confirmMsg")}
          confirmText={t("edu.confirm")}
          cancelText={t("cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            deleteObjectiveHandler();
          }}
          isLoading={addObjectiveLoading}
        />
      )}
    </>
  );
};

export default NewContent;
