import PdfIcon from "../../../../assets/icons/pdf.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";

export const discActions = ({ editAction, exportPdf, exportExcel, banAllAction, publishAllAction, deleteAction }) => {
  const actionsList = [
    ...(banAllAction
      ? [
          {
            id: "introVideo",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #8599B6",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label: i18n.language === "ar" ? "حظر جميع التعليقات" : "Add an introductory video",
            btnTextColor: "#8599B6",
            action: banAllAction,
          },
        ]
      : []),
    ...(publishAllAction
      ? [
          {
            id: "introVideo",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #24b3b9",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label: i18n.language === "ar" ? "نشر جميع التعليقات" : "Add an introductory video",
            btnTextColor: "#24b3b9",
            action: publishAllAction,
          },
        ]
      : []),
    ...(deleteAction
      ? [
          {
            id: "deleteAction",
            type: "button",
            outline: true,
            style: {
              border: "2px solid red",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label: i18n.language === "ar" ? "حذف المحتوى" : "Delete Content",
            btnTextColor: "red",
            action: deleteAction,
          },
        ]
      : []),
    ...(editAction
      ? [
          {
            id: "edit",
            icon: <img src={editIcon} alt="" />,
            action: editAction,
            toolTip: i18n.language === Lang.AR ? "تعديل" : "Edit",
            theme: "edit",
          },
        ]
      : []),
    ...(exportPdf
      ? [
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            action: exportPdf,
          },
        ]
      : []),
    ...(exportExcel
      ? [
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            action: exportExcel,
          },
        ]
      : []),
  ];
  return actionsList;
};
