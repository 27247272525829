import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import ProgramHeader from "../ProgramHeader/ProgramHeader";
import hour from "../../../assets/image/hour.png";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import {useHistory, useParams} from "react-router";
import {fetchProgram} from "../../../Services/api/program/programContext";

function PaymentProgram(props) {
    require("./PaymentProgram.css")
    const {t} = useTranslation();
    const { id } = useParams();
    const history = useHistory();

    const [program, setProgram] = useState(null);
    const [isContentLoaded, setIsContentLoaded] = useState(false);

    const fetchProgramDetails = () => {
        fetchProgram(id)
            .then((res) => {
                if (res.status && res.status == 200 && res.data.status) {
                    let lockUpcoming = false;
                    res.data.program.courses.forEach((item) => {
                        if (lockUpcoming) {
                            item.lock = true;
                        } else {
                            if (item.progress.progress == 0 || item.progress.progress != item.progress.total) {
                                lockUpcoming = true;
                                item.progressValue = item.progress.total == 0 ? '0%' : (item.progress.progress / item.progress.total * 100) + '%';
                            } else if (item.progress.progress == item.progress.total) {
                                item.done = true;
                            }
                        }
                    })
                    setProgram(res.data.program);
                    setIsContentLoaded(true);
                }
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
            });
    }

    useEffect(() => {
        if (props.history.location.state && props.history.location.state.payment) {
            console.log(props.history.location.state.payment);
            if(props.history.location.state && props.history.location.state.program) {
                setProgram(props.history.location.state.program)
            }else {
                fetchProgramDetails();
            }
        } else {
            if (id != null){
                history.push('/table-payment/'+id);
            }else {
                history.push('/');
            }
        }
    }, [])

    return (
        <>
            <ProgramHeader label={program?.title} />
            <div className="container-fluid">
                <div className="table_payment_label">
                    <NavLink to="/">{t("Home")}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    <NavLink to={`/program/${id}`}>{program?.title}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    <NavLink to={`/register-program/${id}`}>{t("register_program")}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    <NavLink to={`/table-payment/${id}`}>{t("table_payment")}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    {t("payment")}
                </div>
                <div className="payment_card">
                    <div className="program_padding">
                        <p className="top_label">{t("safepurchase")}</p>
                        <p>{t("allscr")}</p>
                    </div>
                    <div className="program_padding">
                        <div className="header_program">
                            <div
                                className="program_collapse"
                                data-toggle="collapse"
                                href="#payment-program"
                                aria-expanded="false"
                                aria-controls="payment-program"
                            >
                                <img
                                    src={props.history.location.state.program.cover}
                                    alt=""
                                />
                                <div className="mx-2 payment_program_name ">
                                    {props.history.location.state.program.title}
                                </div>
                                <i class="fas fa-chevron-down"></i>
                            </div>
                            {/* <div
                                className="modal_video"
                                style={{
                                    padding: "0",
                                }}
                            >
                                <img src={video} alt="..."/>
                                <div className="">{t("videode")}</div>
                            </div> */}
                        </div>
                        <div className="collapse" id="payment-program">
                            {props.history.location.state.program.courses.map((course,index)=>(
          <div className="card_border">
          <div className="header_program">
              <div className="program_collapse">
                  <img
                      src={course.img}
                      alt=""
                  />
                  <div className="program_title">
                      <div className="program_label">
                          {course.name}
                      </div>
                      <div>{course.description}</div>
                  </div>
              </div>
              <div className="program_title">
                  <div className="card_center">
                      <img src={hour} alt="" className="img_hour"/>
                      <div className=""> {course.number_hours} {t("hour")} </div>
                  </div>
              </div>
          </div>
      </div>
                            ))}

                        </div>
                    </div>
                    <div className="payment_price program_padding">
                        {t("programs.label.overAll_amount")} : {props.history.location.state.payment.amount} {t("Rial")}
                    </div>
                    <div className="payment_price program_padding">
                        {t("programs.msg.amount_msg")}
                    </div>
                    <div className="overall_price_payment">
                        <div>
                            <div className="fix-margin">
                                {t("price")} : {props.history.location.state.payment.amount} {t("Rial")}
                            </div>
                            <div className="">
                                {t("programs.label.wallet")} : 0 {t("Rial")}
                            </div>
                        </div>
                        <div className="">
                            {t("programs.label.pay")} : {props.history.location.state.payment.amount} {t("Rial")}
                        </div>
                    </div>
                    {0 == 1 ? (
                        <div className="program_padding">
                            <div>{t("programs.msg.wallet_msg")}</div>
                            <div className="payment_price">
                                {t("programs.label.current_wallet_balance")}: 2000 {t("Rial")}
                            </div>
                            <div className="payment_price">
                                {t("programs.label.wallet_balance_after_payment")} : 0
                                {t("Rial")}
                            </div>
                            <button
                                type="submit"
                                className="program_btn"
                            >
                                {t("programs.values.pay_now")} : 0 {t("Rial")}
                            </button>
                        </div>
                    ) : (
                        <PaymentMethod paymentEntity={props.history.location.state.payment}/>
                    )}
                </div>
            </div>
        </>
    );
}

export default PaymentProgram;
