import ChatBtn from "../../../../../assets/icons/Chatbtn.svg";
import ReviewIcon from "../../../../../assets/icons/customer-review.png";
import EduGoalsIcon from "../../../../../assets/icons/edu_goals.svg";
import ExamIcon from "../../../../../assets/icons/exam.png";
import AddIcon from "../../../../../assets/icons/plus.svg";
import QuestionBankIcon from "../../../../../assets/icons/question_bank.svg";
import SurveyIcon from "../../../../../assets/icons/survey.svg";
import Groups from "../../../../../assets/icons/ManagmentIcon.svg";
import MessageIcon from "../../../../../assets/icons/send-2.svg";
import ActivityIcon from "../../../../../assets/icons/ActivityIcon.svg";
import EyeIcon from "../../../../../assets/icons/eye.svg";

export const headerActions = ({
  t,
  addAction,
  surveyAction,
  surveyList,
  eduGoalsAction,
  questionBankAction,
  quizStatement,
  DetailedReportAction,
  chatAction,
  groupsAction,
  sendMessagesAction,
  activitiesStatement,
  showAsTrainee,
}) => {
  const actionsList = [
    ...(DetailedReportAction
      ? [
          {
            id: "introVideo",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #8599B6",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label: t("detail_report"),

            btnTextColor: "#8599B6",
            action: DetailedReportAction,
          },
        ]
      : []),
    ...(chatAction
      ? [
          {
            id: "chat",
            icon: <img src={ChatBtn} alt="" width={"100%"} height={"100%"} />,
            action: chatAction,
            title: t("chat.privateChat"),
          },
        ]
      : []),
    ...(groupsAction
      ? [
          {
            id: "groups",
            icon: <img src={Groups} alt="" width={"100%"} height={"100%"} />,
            theme: "export",
            action: groupsAction,
            title: t("groups"),
          },
        ]
      : []),
    ...(surveyAction
      ? [
          {
            id: "survey",
            icon: <img src={ReviewIcon} alt="" width={"100%"} />,
            action: surveyAction,
            title: t("survey.surveyTitle"),
          },
        ]
      : []),
    ...(sendMessagesAction
      ? [
          {
            id: "sendMessages",
            icon: <img src={MessageIcon} alt="" />,
            theme: "add",
            action: sendMessagesAction,
            title: t("send_messages"),
          },
        ]
      : []),
    ...(addAction
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            theme: "add",
            action: addAction,
            title: t("survey.add"),
          },
        ]
      : []),
    ...(surveyList
      ? [
          {
            id: "surveyList",
            icon: <img src={SurveyIcon} alt="" />,
            theme: "",
            action: surveyList,
            title: t("survey.listHeading"),
          },
        ]
      : []),
    ...(quizStatement
      ? [
          {
            id: "statement",
            icon: <img src={ExamIcon} alt="" style={{ maxWidth: "100%" }} />,
            theme: "",
            action: quizStatement,
            title: t("course_quiz_report"),
          },
        ]
      : []),
    ...(activitiesStatement
      ? [
          {
            id: "activities_statement",
            icon: <img src={ActivityIcon} alt="" className="tw-h-5 tw-w-5" />,
            theme: "add",
            action: activitiesStatement,
            title: t("course_activities_report"),
          },
        ]
      : []),
    {
      id: "eduGoals",
      icon: <img src={EduGoalsIcon} alt="" />,
      theme: "export",
      action: eduGoalsAction,
      title: t("edu.Edu_goals"),
    },
    {
      id: "showAsTrainee",
      icon: <img src={EyeIcon} alt="" className="tw-h-6 tw-w-6" />,
      theme: "",
      action: showAsTrainee,
      title: t("showAsTrainee"),
    },

    {
      id: "questionBank",
      icon: <img src={QuestionBankIcon} alt="" />,
      theme: "file",
      title: t("questionBank"),
      action: questionBankAction,
    },
  ];
  return actionsList;
};
