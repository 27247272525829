import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {TrainingCourseProfileContext} from "../../../../../Services/api/toutorials/TrainingCourseProfileContext";

function TrainingCourseSyllabusLoadingContent(props) {
  require("./TrainingCourseSyllabusLoadingContent.css");
  const { t } = useTranslation();
  const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);
  const [opacityTransitionEnded, setOpacityTransitionEnded] = useState(false);

  useEffect(() => {
    const transition = document.getElementsByClassName(
      "training-course-syllabus-loading-section-container"
    );
    "transitionend webkitTransitionEnd oTransitionEnd otransitionend"
      .split(" ")
      .forEach((item) => {
        transition[0].addEventListener(item, removeLoadingPanel);
      });

      //Remove load when no results are found:
      setTimeout(()=>{
        for(var i = 0; i < transition.length; i++){
            transition[i].className += " unactive";
        }
      }, 500)

  }, []);

  const removeLoadingPanel = () => {
    setOpacityTransitionEnded(true);
  };

  return (
    <>
      <div
        className={
          "training-course-syllabus-loading-section-container" +
          (opacityTransitionEnded ? " unactive" : "")
        }
        style={{
          opacity: trainingCourseProfileContext.syllabus.length > 0 ? 0 : 1,
        }}
      >
        <div className="training-course-syllabus-section-container">
          <div className="training-course-syllabus-section-container-border">
            <div className="training-course-syllabus-section-container-row">
              <div className="training-course-syllabus-section-container-row-header">
                <div
                  className="skeleton-box skeleton-margin-top-bottom"
                  style={{ width: "50%" }}
                ></div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div
                  className={
                    "training-course-syllabus-section-container-row-lesson-header"
                  }
                >
                  <div
                    className="skeleton-box skeleton-margin-top-bottom"
                    style={{ width: "35%" }}
                  ></div>
                </div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div
                  className={
                    "training-course-syllabus-section-container-row-lesson-header"
                  }
                >
                  <div
                    className="skeleton-box skeleton-margin-top-bottom"
                    style={{ width: "45%" }}
                  ></div>
                </div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div
                  className={
                    "training-course-syllabus-section-container-row-lesson-header"
                  }
                >
                  <div
                    className="skeleton-box skeleton-margin-top-bottom"
                    style={{ width: "60%" }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="training-course-syllabus-section-container-row">
              <div className="training-course-syllabus-section-container-row-header">
                <div
                  className="skeleton-box skeleton-margin-top-bottom"
                  style={{ width: "33%" }}
                ></div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div
                  className={
                    "training-course-syllabus-section-container-row-lesson-header"
                  }
                >
                  <div
                    className="skeleton-box skeleton-margin-top-bottom"
                    style={{ width: "29%" }}
                  ></div>
                </div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div
                  className={
                    "training-course-syllabus-section-container-row-lesson-header"
                  }
                >
                  <div
                    className="skeleton-box skeleton-margin-top-bottom"
                    style={{ width: "53%" }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="training-course-syllabus-section-container-row">
              <div className="training-course-syllabus-section-container-row-header">
                <div
                  className="skeleton-box skeleton-margin-top-bottom"
                  style={{ width: "43%" }}
                ></div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div
                  className={
                    "training-course-syllabus-section-container-row-lesson-header"
                  }
                >
                  <div
                    className="skeleton-box skeleton-margin-top-bottom"
                    style={{ width: "60%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrainingCourseSyllabusLoadingContent;
