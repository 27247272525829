import { Chart as ChartJS, PointElement } from "chart.js";
import { useEffect, useState, useTransition } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import toLocalTimezone from "../../../Services/Timezone";
import {
  getCourseAnalysisById,
  getQuizAnalysisById,
  getTraineeAnalysisById,
} from "../../../Services/api/Analysis/AnalysisProvider";
import {
  exportAnalysis,
  exportCourseAnalysis,
  exportQuizAnalysis,
  exportTraineeAnalysis,
} from "../../../Services/api/AnalysisExport";
import { getAssessments } from "../../../Services/api/exams/ExamsProvider";
import { courseBlock } from "../../../Services/api/ipBlocked/ipBlockedProvider";
import { getAllUsers } from "../../../Services/api/survey/SurveyProvider";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import SearchTabs from "../Search/SearchTabs";
import { mainActions } from "./AnalysisActions";
import classes from "./search.module.css";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import moment from "moment";
ChartJS.register(PointElement);
const colors = [
  "#2e3e5f",
  "#24b3b9",
  "#8599b6",
  "#845EC2",
  "#D65DB1",
  "#FF6F91",
  "#FF9671",
  "#FFC75F",
  "#F9F871",
  "#2C73D2",
  "#008E9B",
  "#008F7A",
  "#B39CD0",
  "#FBEAFF",
  "#C34A36",
];

const generalOptions = {
  indexAxis: "x",
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: false,
      ticks: {
        precision: 0,
      },
      grid: {
        borderWidth: "rgba(0, 0, 0, 0)",
      },
    },
    y: {
      stacked: false,
      ticks: {
        precision: 0,
      },
      grid: {
        borderWidth: "rgba(0, 0, 0, 0)",
      },
    },
  },
};

const pickColor = (index) => {
  if (colors.length > index) {
    return colors[index];
  } else {
    return "#FCF8FF";
  }
};

export default function LearningAnalysis({ role }) {
  const { t, i18n } = useTranslation();
  const [isExportLoading, setIsExportLoading] = useState();
  const [course, setCourse] = useState({});
  const [secondCourse, setSecondCourse] = useState({});

  const [quiz, setQuiz] = useState({});
  const [trainee, setTrainee] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [searchTabs, setSearchTabs] = useState([
    { text: t("course_analysis"), value: 1 },
    { text: t("quiz_analysis"), value: 2 },
    { text: t("trainee_analysis"), value: 3 },
  ]);

  const onMainTabChangeHandler = (tab) => {
    // resetSearchListHandler();
    setActiveTab(tab);
    // if(search?.[tab]?.filters && Object.values(search?.[tab]?.filters)?.length) return filterActiveSearchList(tab);
    // getSearchDataHandler(tab || "exhibitions");
    setCourse(null);
    setSecondCourse(null);
    setQuiz(null);
    setTrainee(null);
  };

  const courseOptions = (search) =>
    new Promise((resolve) => {
      courseBlock({ search }).then((res) => {
        resolve(res.data.courses);
      });
    });

  const quizOptions = (search) =>
    new Promise((resolve) => {
      getAssessments(course?.id, { title: search, active: 1 }).then((res) => {
        resolve(res.data.data.quizzes);
      });
    });

  const traineeOptions = (search) =>
    new Promise((resolve) => {
      getAllUsers({
        search: search,
        roles: ["trainee"],
        course: course?.id,
      }).then((res) => {
        resolve(res.data.users);
      });
    });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    ...(role == "admin"
      ? [
          {
            id: "dashboard",
            page: t("admin.label.dashboard"),
            pagePath: "/admin",
          },
        ]
      : []),
    {
      id: "learning-analysis",
      page: t("learning_analysis.title"),
      active: true,
    },
  ];

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      if (activeTab === 1 && course?.id && role != "trainee") {
        await exportCourseAnalysis(type, course?.id, secondCourse?.id);
      } else if (
        activeTab === 2 &&
        course?.id &&
        quiz?.id &&
        role != "trainee"
      ) {
        await exportQuizAnalysis(course?.id, quiz?.id, type);
      } else if (
        ((activeTab === 3 && trainee?.id) || role == "trainee") &&
        course?.id
      ) {
        await exportTraineeAnalysis(course?.id, trainee?.id, type);
      } else {
        toast.error(t("learning_analysis.export_error"));
      }
      setIsExportLoading(false);
    } catch (e) {
      console.error(e);
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <>
      <div className={classes["search"]}>
        {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
        <div className="container-fluid">
          <div className={classes["search__wrapper"]}>
            <div className="row">
              <div className="col-12">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <MainBoxHead
                    title={t("learning_analysis.title")}
                    actions={mainActions({
                      exportPdf: () => {
                        exportHandler("pdf");
                      },
                      exportExcel: () => {
                        exportHandler("xlsx");
                      },
                    })}
                  />
                  <>
                    {role == "trainee" ? (
                      <> </>
                    ) : (
                      <div className={classes["search__box__item-fields"]}>
                        <SearchTabs
                          tabs={searchTabs}
                          activeTab={activeTab}
                          onChange={onMainTabChangeHandler}
                          size={"small"}
                        />
                      </div>
                    )}
                    <div
                      className="tw-grid tw-grid-cols-2 tw-gap-4"
                      style={{ zIndex: 99 }}
                    >
                      <label className="tw-block">
                        <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("survey.choose_course")}
                        </div>

                        <AsyncSelect
                          placeholder={t("survey.choose_course")}
                          cacheOptions
                          value={course}
                          defaultOptions
                          isClearable
                          loadOptions={courseOptions}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(item) => {
                            setCourse(item);
                            setQuiz(null);
                            setTrainee(null);
                          }}
                        />
                      </label>
                      {activeTab == 1 && course?.id && role != "trainee" ? (
                        <label className="tw-block">
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t("select_second_course")}
                          </div>

                          <AsyncSelect
                            placeholder={t("select_second_course")}
                            cacheOptions
                            value={secondCourse}
                            defaultOptions
                            isClearable
                            loadOptions={courseOptions}
                            isOptionDisabled={(option) =>
                              option.id == course?.id
                            }
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(item) => {
                              setSecondCourse(item);
                            }}
                          />
                        </label>
                      ) : activeTab == 2 && course?.id && role != "trainee" ? (
                        <label className="tw-block">
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t("survey.Choose_quiz")}
                          </div>

                          <AsyncSelect
                            key={`quiz-${course.id}`}
                            placeholder={t("survey.Choose_quiz")}
                            cacheOptions
                            defaultOptions
                            isClearable
                            value={quiz}
                            loadOptions={quizOptions}
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.id}
                            onChange={(item) => {
                              setQuiz(item);
                            }}
                          />
                        </label>
                      ) : activeTab == 3 && course?.id && role != "trainee" ? (
                        <label className="tw-block">
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t("survey.Choose_trainee")}
                          </div>

                          <AsyncSelect
                            key={`trainee-${course.id}`}
                            placeholder={t("survey.Choose_trainee")}
                            cacheOptions
                            value={trainee}
                            defaultOptions
                            isClearable
                            loadOptions={traineeOptions}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(item) => {
                              setTrainee(item);
                            }}
                          />
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      {activeTab == 1 && course?.id && role != "trainee" ? (
                        <CourseAnalysis
                          key={course?.id}
                          course={course}
                          secondCourse={secondCourse}
                        />
                      ) : activeTab == 2 && quiz?.id && role != "trainee" ? (
                        <QuizAnalysis
                          key={quiz?.id}
                          course={course}
                          quiz={quiz}
                        />
                      ) : (activeTab == 3 && trainee?.id) ||
                        (role == "trainee" && course?.id) ? (
                        <TraineeAnalysis
                          key={trainee?.id}
                          course={course}
                          trainee={trainee}
                          role={role}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                </MainBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CourseAnalysis({ course, secondCourse }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [analysis, setAnalysis] = useState({});
  const [secondAnalysis, setSecondAnalysis] = useState({});

  const { t } = useTranslation();
  useEffect(() => {
    setIsLoading(true);
    if (course?.id) {
      getCourseAnalysisById(course.id, secondCourse?.id)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setAnalysis(res.data.course);
            setSecondAnalysis(res.data.second_course);
            setIsLoading(false);
          }
        })
        .catch((err) => {});
    }
  }, [course, secondCourse]);

  const data1 = [
    {
      point: t("coures_num"),
      result: (anslys) => anslys.id,
    },
    {
      point: t("course_name"),
      result: (anslys) => anslys.name,
    },
    {
      point: t("start_date"),
      result: (anslys) =>
        moment(toLocalTimezone(anslys.start_date)).format("YYYY-MM-DD hh:mm A"),
    },
    {
      point: t("end_date"),
      result: (anslys) =>
        moment(toLocalTimezone(anslys.end_date)).format("YYYY-MM-DD hh:mm A"),
    },
    {
      point: t("training_manager"),
      result: (anslys) => anslys.manager.name,
    },
    {
      point: t("course_trainers"),
      result: (anslys) => anslys.trainers?.map((item) => item.name)?.join(", "),
    },
    {
      point: t("registered_num"),
      result: (anslys) => anslys.trainees_count,
    },
    {
      point: t("course_rating"),
      result: (anslys) => anslys.rating,
    },
  ];

  const data2 = [
    {
      point: t("course_per_trainees"),
      result: (anslys) => `${anslys.students_reach_rate}%`,
    },
    {
      point: t("course_finish"),
      result: (anslys) => `${anslys.course_completion_rate}%`,
    },
    {
      point: t("certifacte_per"),
      result: (anslys) => `${anslys.certification_rate}%`,
    },
    {
      point: t("Percentage_of_coverage_of_course_learning_objectives"),
      result: (anslys) => `${anslys.objectives_watch_rate}%`,
    },
    {
      point: t("Percentage_of_achievement_of_course_learning_objectives"),
      result: (anslys) => `${anslys.objectives_progress_rate}%`,
    },
    {
      point: t("course_completion_count"),
      result: (anslys) => anslys.course_completion_count,
    },
    {
      point: t("course_not_completion_count"),
      result: (anslys) =>
        anslys.trainees_count - anslys.course_completion_count > 0
          ? anslys.trainees_count - anslys.course_completion_count
          : 0,
    },
    {
      point: t("general_progress_rate"),
      result: (anslys) => `${anslys.general_progress_rate}%`,
    },
    {
      point: t("general_watch_rate"),
      result: (anslys) => `${anslys.general_watch_rate}%`,
    },
  ];

  const data3 = [
    {
      point: t("course_activities_count"),
      result: (anslys) => anslys.activities_count,
    },
    {
      point: t("course_sessions_count"),
      result: (anslys) => anslys.sessions_count,
    },
    {
      point: t("course_videos_count"),
      result: (anslys) => anslys.videos_count,
    },
    {
      point: t("course_files_count"),
      result: (anslys) => anslys.files_count,
    },
    {
      point: t("course_quiz_count"),
      result: (anslys) => anslys.quiz_count,
    },
    {
      point: t("course_discussions_count"),
      result: (anslys) => anslys.discussions_count,
    },
    {
      point: t("course_surveys_count"),
      result: (anslys) => anslys.surveys_count,
    },
    {
      point: t("course_surveys_responses_count"),
      result: (anslys) => anslys.surveys_responses_count,
    },
    {
      point: t("course_attendance_at_sessions"),
      result: (anslys) => anslys.attendance_at_sessions,
    },
    {
      point: t("avg_attendance_at_sessions"),
      result: (anslys) => anslys.avg_attendance_at_sessions,
    },
    {
      point: t("num_involved_trainee_discussions"),
      result: (anslys) => anslys.avg_discussions_posts,
    },
    {
      point: t("Percentage_of_students_completion_of_assigned_assignments"),
      result: (anslys) => `${anslys.duties_completion_rate}%`,
    },
    {
      point: t("per_trainees_survey"),
      result: (anslys) => `${anslys.survay_completion_rate}%`,
    },
    {
      point: t("per_pass_trainees"),
      result: (anslys) => `${anslys.quiz_passing_rate}%`,
    },
    {
      point: t("per_quiz_finished"),
      result: (anslys) => `${anslys.quiz_progress_rate}%`,
    },
    {
      point: t("avg_result_trainees"),
      result: (anslys) => `${anslys.avg_quiz_scores}%`,
    },
    {
      point: t("videos_watchs_count"),
      result: (anslys) => anslys.videos_watchs_count,
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "point",
      name: t("measurement_point"),
      key: "point",
      // sortable: true,
      cell: ({ rowData }) => (
        <div className="tw-text-start">{rowData.point}</div>
      ),
    },
    {
      id: "result",
      name: `${t("result")} (${analysis.name})`,
      key: "result",
      cell: ({ rowData }) => <>{rowData.result(analysis)}</>,
    },
    ...(secondAnalysis?.id
      ? [
          {
            id: "second_result",
            name: `${t("result")} (${secondAnalysis.name})`,
            key: "second_result",
            cell: ({ rowData }) => <>{rowData.result(secondAnalysis)}</>,
          },
        ]
      : []),
  ];

  const sortActionHandler = async (columnKey) => {};

  return (
    <>
      {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
      {isLoading ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <div className="tw-space-y-8">
          <div>
            <div class="tw-mb-2 tw-font-bold tw-text-gray-600">
              {t(`Information`)}
            </div>
            <MainTable
              columns={columns}
              data={data1}
              sortAction={sortActionHandler}
              isBordered={true}
            />
          </div>
          <div>
            <div class="tw-mb-2 tw-font-bold tw-text-gray-600">
              {t(`course_information`)}
            </div>
            <MainTable
              columns={columns}
              data={data2}
              sortAction={sortActionHandler}
              isBordered={true}
            />
          </div>
          <div>
            <div class="tw-mb-2 tw-font-bold tw-text-gray-600">
              {t(`course_content`)}
            </div>
            <MainTable
              columns={columns}
              data={data3}
              sortAction={sortActionHandler}
              isBordered={true}
            />
          </div>
          {analysis.countries && (
            <div>
              <div className="tw-font-bold tw-mb-2">
                {t("Number_of_participants_by_country")}
              </div>
              <div className="tw-h-60 tw-mb-4">
                <Bar
                  options={generalOptions}
                  data={{
                    labels: analysis.countries.map((item) =>
                      item.country ? item.country.name : t("undefined")
                    ),
                    datasets: [
                      {
                        label: t("first_course"),
                        backgroundColor: analysis.countries.map((item, i) =>
                          pickColor(0)
                        ),
                        data: analysis.countries.map((item) => item.count),
                        maxBarThickness: 120,
                        //   grouped: true,
                      },
                      ...(secondAnalysis?.id
                        ? [
                            {
                              label: t("second_course"),
                              backgroundColor: secondAnalysis.countries.map(
                                (item, i) => pickColor(1)
                              ),
                              data: secondAnalysis.countries.map(
                                (item) => item.count
                              ),
                              maxBarThickness: 120,
                              //   grouped: true,
                            },
                          ]
                        : []),
                    ],
                  }}
                  key={`course-${course.id}`}
                />
              </div>
            </div>
          )}
          {analysis.specialties && (
            <div>
              <div className="tw-font-bold tw-mb-2">
                {t("Number of participants by specialty")}
              </div>
              <div className="tw-h-60 tw-mb-4">
                <Bar
                  options={generalOptions}
                  data={{
                    labels: analysis.specialties.map((item) =>
                      item.specialty ? item.specialty.name : t("undefined")
                    ),
                    datasets: [
                      {
                        label: t("first_course"),
                        backgroundColor: analysis.specialties.map((item, i) =>
                          pickColor(0)
                        ),
                        data: analysis.specialties.map((item) => item.count),
                        maxBarThickness: 120,
                        //   grouped: true,
                      },
                      ...(secondAnalysis?.id
                        ? [
                            {
                              label: t("second_course"),
                              backgroundColor: secondAnalysis.specialties.map(
                                (item, i) => pickColor(1)
                              ),
                              data: secondAnalysis.specialties.map(
                                (item) => item.count
                              ),
                              maxBarThickness: 120,
                              //   grouped: true,
                            },
                          ]
                        : []),
                    ],
                  }}
                  key={`course-${course.id}`}
                />
              </div>
            </div>
          )}
          {analysis.trainees && (
            <div>
              <div className="tw-font-bold tw-mb-2">
                {t("Number of quizzes by trainee")}
              </div>
              <div className="tw-h-60 tw-mb-4">
                <Bar
                  options={generalOptions}
                  data={{
                    labels: analysis.trainees.map((item) =>
                      item.trainee ? item.trainee.name : t("undefined")
                    ),
                    datasets: [
                      {
                        label: t("first_course"),
                        backgroundColor: analysis.trainees.map((item, i) =>
                          pickColor(0)
                        ),
                        data: analysis.trainees.map((item) => item.quiz_count),
                        maxBarThickness: 120,
                        //   grouped: true,
                      },
                      ...(secondAnalysis?.id
                        ? [
                            {
                              label: t("second_course"),
                              backgroundColor: secondAnalysis.trainees.map(
                                (item, i) => pickColor(1)
                              ),
                              data: secondAnalysis.trainees.map(
                                (item) => item.quiz_count
                              ),
                              maxBarThickness: 120,
                              //   grouped: true,
                            },
                          ]
                        : []),
                    ],
                  }}
                  key={`course-${course.id}`}
                />
              </div>
            </div>
          )}
          {analysis.trainees && (
            <div>
              <div className="tw-font-bold tw-mb-2">
                {t("Number of activities by trainee")}
              </div>
              <div className="tw-h-60 tw-mb-4">
                <Bar
                  options={generalOptions}
                  data={{
                    labels: analysis.trainees.map((item) =>
                      item.trainee ? item.trainee.name : t("undefined")
                    ),
                    datasets: [
                      {
                        label: t("first_course"),
                        backgroundColor: analysis.trainees.map((item, i) =>
                          pickColor(0)
                        ),
                        data: analysis.trainees.map(
                          (item) => item.activities_count
                        ),
                        maxBarThickness: 120,
                        //   grouped: true,
                      },
                      ...(secondAnalysis?.id
                        ? [
                            {
                              label: t("second_course"),
                              backgroundColor: secondAnalysis.trainees.map(
                                (item, i) => pickColor(1)
                              ),
                              data: secondAnalysis.trainees.map(
                                (item) => item.activities_count
                              ),
                              maxBarThickness: 120,
                              //   grouped: true,
                            },
                          ]
                        : []),
                    ],
                  }}
                  key={`course-${course.id}`}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function QuizAnalysis({ course, quiz }) {
  const [isLoading, setIsLoading] = useState(true);
  const [analysis, setAnalysis] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    setIsLoading(true);
    getQuizAnalysisById(course.id, quiz.id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setAnalysis(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  }, []);

  const tableData = [
    {
      id: 1,
      measurement_point: t("quiz_name"),
      result: analysis.title,
    },
    {
      id: 2,
      measurement_point: t("course_name"),
      result: analysis.course?.name,
    },
    {
      id: 3,
      measurement_point: t("avg_grade"),
      result: `${analysis.avg_grade} (${analysis.avg_scores}%)`,
    },
    {
      id: 4,
      measurement_point: t("high_grade_actual"),
      result: analysis.highest_actual_score,
    },
    {
      id: 5,
      measurement_point: t("low_grade_actual"),
      result: analysis.lowest_actual_score,
    },
    {
      id: 6,
      measurement_point: t("per_trainee_applied"),
      result: `${analysis.percentage_taking}%`,
    },
    {
      id: 7,
      measurement_point: t("avg_timr_in_quiz"),
      result: `${analysis.average_time} ${t("seconds")}`,
    },
    {
      id: 8,
      measurement_point: t("avg_atemppts_in_quiz"),
      result: analysis.average_attempts,
    },
    {
      id: 9,
      measurement_point: t("num_complete_atemppts"),
      result: analysis.attempts_completed,
    },
    {
      id: 10,
      measurement_point: t("num_uncomplte_atemppts"),
      result: analysis.incomplete_attempts,
    },
    {
      id: 11,
      measurement_point: t("questions_count"),
      result: analysis.questions_count,
    },
    {
      id: 12,
      measurement_point: t("type_qusetions_num"),
      result: analysis.questions_types?.map((item) => (
        <div>{`${item.type.name}: ${item.count}`}</div>
      )),
    },
    {
      id: 13,
      measurement_point: t("start_date"),
      result: moment(toLocalTimezone(analysis.start_date)).format(
        "YYYY-MM-DD hh:mm A"
      ),
    },
    {
      id: 14,
      measurement_point: t("end_date"),
      result: moment(toLocalTimezone(analysis.end_date)).format(
        "YYYY-MM-DD hh:mm A"
      ),
    },
    {
      id: 15,
      measurement_point: t("per_goals_achived"),
      result: `${analysis.objectives_progress_rate}%`,
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <p style={{ color: "#000" }}>{rowData.id}</p>
      ),
    },
    {
      id: "measurement_point",
      name: t("measurement_point"),
      key: "measurement_point",
      cell: ({ rowData }) => (
        <p style={{ color: "#000" }}>{rowData.measurement_point}</p>
      ),
    },
    {
      id: "result",
      name: t("result"),
      key: "result",
      cell: ({ rowData }) => <p style={{ color: "#000" }}>{rowData.result}</p>,
    },
  ];

  const questionColumns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <p style={{ color: "#000" }}>{rowIndex + 1}</p>
      ),
    },
    {
      id: "question_body",
      name: t("question_body"),
      key: "question_body",
      cell: ({ rowData }) => <p style={{ color: "#000" }}>{rowData.title}</p>,
    },
    {
      id: "questionType",
      name: t("questionType"),
      key: "questionType",
      cell: ({ rowData }) => (
        <p style={{ color: "#000" }}>{rowData.type.name}</p>
      ),
    },
    {
      id: "question_grade",
      name: t("trainee.quiz.question_grade"),
      key: "question_grade",
      cell: ({ rowData }) => <p style={{ color: "#000" }}>{rowData.degree}</p>,
    },
    {
      id: "Percentage_of_correct_answers",
      name: t("Percentage_of_correct_answers"),
      key: "Percentage_of_correct_answers",
      cell: ({ rowData }) => (
        <p style={{ color: "#000" }}>{rowData.percentage_correct_answers}%</p>
      ),
    },
    {
      id: "Difficulty_level",
      name: t("Difficulty_level"),
      key: "Difficulty_level",
      cell: ({ rowData }) => (
        <p style={{ color: "#000" }}>{rowData.level_name}</p>
      ),
    },
    {
      id: "question_objectives_progress_rate",
      name: t("question_objectives_progress_rate"),
      key: "question_objectives_progress_rate",
      cell: ({ rowData }) => (
        <p style={{ color: "#000" }}>{rowData.objectives_progress_rate}%</p>
      ),
    },
  ];

  const objectivesColumns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <p style={{ color: "#000" }}>{rowData.id}</p>
      ),
    },
    {
      id: "name",
      name: t("name"),
      key: "name",
      cell: ({ rowData }) => <p style={{ color: "#000" }}>{rowData.name}</p>,
    },
    {
      id: "objective_progress_rate",
      name: t("objective_progress_rate"),
      key: "objective_progress_rate",
      cell: ({ rowData }) => (
        <p style={{ color: "#000" }}>{rowData.objective_progress_rate}%</p>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <div className="tw-space-y-4">
          <MainTable
            columns={columns}
            data={tableData || []}
            isBordered={true}
          />

          <div>
            <div class="tw-mb-2 tw-font-bold tw-text-gray-600">
              {t(`quiz_questions`)}
            </div>
            <MainTable
              columns={questionColumns}
              data={analysis.questions || []}
              isBordered={true}
            />
          </div>

          <div>
            <div class="tw-mb-2 tw-font-bold tw-text-gray-600">
              {t(`quiz_objectives`)}
            </div>
            <MainTable
              columns={objectivesColumns}
              data={analysis.objectives || []}
              isBordered={true}
            />
          </div>
        </div>
      )}
    </>
  );
}

function TraineeAnalysis({ course, trainee, role }) {
  const [isLoading, setIsLoading] = useState(true);
  const [analysis, setAnalysis] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    setIsLoading(true);
    getTraineeAnalysisById(course.id, role == "trainee" ? null : trainee.id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setAnalysis(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  }, [course, trainee]);

  const tableData = [
    {
      id: 1,
      measurement_point: t("general_watch_rate_trainee"),
      result: `${analysis.general_watch_rate_trainee}%`,
      measurement_point_2: t("general_watch_rate"),
      result_2: `${analysis.general_watch_rate}%`,
    },
    {
      id: 2,
      measurement_point: t("general_progress_rate_trainee"),
      result: `${analysis.general_progress_rate_trainee}%`,
      measurement_point_2: t("general_progress_rate"),
      result_2: `${analysis.general_progress_rate}%`,
    },
    {
      id: 3,
      measurement_point: t(
        "The_percentage_of_the_trainees_coverage_of_the_course_objectives"
      ),
      result: `${analysis.objectives_watch_rate_trainee}%`,
      measurement_point_2: t(
        "Percentage_of_coverage_of_course_learning_objectives"
      ),
      result_2: `${analysis.objectives_watch_rate}%`,
    },
    {
      id: 4,
      measurement_point: t(
        "The_percentage_of_the_trainee_achieving_the_course_objectives"
      ),
      result: `${analysis.objectives_progress_rate_trainee}%`,
      measurement_point_2: t(
        "Percentage_of_achievement_of_course_learning_objectives"
      ),
      result_2: `${analysis.objectives_progress_rate}%`,
    },
    {
      id: 5,
      measurement_point: t(
        "Average_number_of_trainee_posts_in_eligible_discussion_forums"
      ),
      result: `${analysis.avg_discussions_posts_trainee}`,
      measurement_point_2: t("num_involved_trainee_discussions"),
      result_2: `${analysis.avg_discussions_posts}`,
    },
    {
      id: 6,
      measurement_point: t(
        "The_percentage_of_the_trainees_completion_of_the_assigned_duties"
      ),
      result: `${analysis.duties_completion_rate_trainee}%`,
      measurement_point_2: t(
        "Percentage_of_students_completion_of_assigned_assignments"
      ),
      result_2: `${analysis.duties_completion_rate}%`,
    },
    {
      id: 7,
      measurement_point: t(
        "The_percentage_of_the_trainees_completion_of_the_required_questionnaires"
      ),
      result: `${analysis.survay_completion_rate_trainee}%`,
      measurement_point_2: t(
        "Percentage_of_students_completion_of_the_due_questionnaires"
      ),
      result_2: `${analysis.survay_completion_rate}%`,
    },
    {
      id: 8,
      measurement_point: t("The_percentage_of_the_trainee_passing_the_tests"),
      result: `${analysis.quiz_passing_rate_trainee}%`,
      measurement_point_2: t("Percentage_of_trainees_passing_the_tests"),
      result_2: `${analysis.quiz_passing_rate}%`,
    },
    {
      id: 9,
      measurement_point: t(
        "The_percentage_of_the_trainees_progress_in_the_tests"
      ),
      result: `${analysis.quiz_progress_rate_trainee}%`,
      measurement_point_2: t("Percentage_of_trainees_progress_in_exams"),
      result_2: `${analysis.quiz_progress_rate}%`,
    },
    {
      id: 10,
      measurement_point: t("Average_trainee_results_in_tests"),
      result: `${analysis.avg_quiz_scores_trainee}%`,
      measurement_point_2: t(
        "Average_results_of_students_in_the_required_tests"
      ),
      result_2: `${analysis.avg_quiz_scores}%`,
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <p style={{ color: "#000" }}>{rowData.id}</p>
      ),
    },
    {
      id: "measurement_point",
      name: t("measurement_point"),
      key: "measurement_point",
      cell: ({ rowData }) => (
        <p style={{ color: "#000" }}>{rowData.measurement_point}</p>
      ),
    },
    {
      id: "result",
      name: t("result"),
      key: "result",
      cell: ({ rowData }) => <p style={{ color: "#000" }}>{rowData.result}</p>,
    },
    ...(role == "trainee"
      ? []
      : [
          {
            id: "measurement_point_2",
            name: t("measurement_point"),
            key: "measurement_point_2",
            cell: ({ rowData }) => (
              <p style={{ color: "#000" }}>{rowData.measurement_point_2}</p>
            ),
          },
          {
            id: "result_2",
            name: t("result"),
            key: "result_2",
            cell: ({ rowData }) => (
              <p style={{ color: "#000" }}>{rowData.result_2}</p>
            ),
          },
        ]),
  ];

  const baseData = [
    {
      id: 1,
      measurement_point: t("trainers_name"),
      result: `${analysis.name}`,
    },
    {
      id: 2,
      measurement_point: t("major"),
      result: `${analysis.category?.name ?? "-"}`,
    },
    {
      id: 3,
      measurement_point: t("course_name"),
      result: `${analysis.course?.name}`,
    },
    {
      id: 4,
      measurement_point: t("Last_access_to_the_course"),
      result: analysis.last_seen
        ? moment(toLocalTimezone(analysis.last_seen)).format(
            "YYYY-MM-DD hh:mm A"
          )
        : "-",
    },
    {
      id: 5,
      measurement_point: t("Training_start_date_for_the_course"),
      result: analysis.start_date
        ? moment(toLocalTimezone(analysis.start_date)).format(
            "YYYY-MM-DD hh:mm A"
          )
        : "-",
    },
    {
      id: 5,
      measurement_point: t("The_date_the_trainee_finishes_the_course"),
      result: analysis.end_date
        ? moment(toLocalTimezone(analysis.end_date)).format(
            "YYYY-MM-DD hh:mm A"
          )
        : "-",
    },
  ];

  const baseColumns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <p style={{ color: "#000" }}>{rowData.id}</p>
      ),
    },
    {
      id: "measurement_point",
      name: t("measurement_point"),
      key: "measurement_point",
      cell: ({ rowData }) => (
        <p style={{ color: "#000" }}>{rowData.measurement_point}</p>
      ),
    },
    {
      id: "result",
      name: t("result"),
      key: "result",
      cell: ({ rowData }) => <p style={{ color: "#000" }}>{rowData.result}</p>,
    },
  ];

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <>
          <div className="tw-space-y-4">
            <MainTable
              columns={baseColumns}
              data={baseData || []}
              isBordered={true}
            />

            <div>
              {role == "trainee" ? (
                <></>
              ) : (
                <div class="tw-mb-2 tw-font-bold tw-text-gray-600">
                  {t(
                    `Student_results_compared_to_average_results_in_the_course`
                  )}
                </div>
              )}

              <MainTable
                columns={columns}
                data={tableData || []}
                isBordered={true}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
