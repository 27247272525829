import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { changePassword } from "../../../../../Services/api/profile/profileProvider";
import CloseEye from "../../../../../assets/icons/closeEye.svg";
import OpenEye from "../../../../../assets/icons/openEye.svg";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../../Shared/Components/Input/Input";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";

function ResetPassword() {
  require("./resetPassword.css");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [oldType, setOldType] = useState("password");
  const [newType, setNewType] = useState("password");
  const [confirmationType, setConfirmationType] = useState("password");
  const { t } = useTranslation();

  const showHideOld = (e) => {
    e.preventDefault();
    setOldType(oldType === "input" ? "password" : "input");
  };

  const showHideNew = (e) => {
    e.preventDefault();
    setNewType(newType === "input" ? "password" : "input");
  };

  const showHideConfirmation = (e) => {
    e.preventDefault();
    setConfirmationType(confirmationType === "input" ? "password" : "input");
  };

  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-12">
          <MainBox>
            <div className="profile-title-container">
              <h1 className="profile-title">{t("accountde")}</h1>
            </div>
            <Formik
              initialValues={{
                old_password: "",
                password: "",
                password_confirmation: "",
              }}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                let errorObj = {};
                try {
                  let response = await changePassword(values);
                  if (response.status === 200 && response.data.status) {
                    alert("Success");
                    localStorage.setItem("token", response.data.accessToken);
                  } else {
                    alert("Failure");
                  }
                  setSubmitting(false);
                } catch (err) {
                  if (err.response.data && err.response.data.msg) {
                    errorObj.old_password = err.response.data.msg;
                  }
                  if (Object.keys(errorObj).length !== 0) {
                    setErrors(errorObj);
                  }
                }
              }}
              validateOnChange={hasSubmitted}
              validate={(values) => {
                setHasSubmitted(true);

                const errors = {};

                if (!values.old_password) {
                  errors.old_password = t("crud.errors.required");
                }

                if (!values.password_confirmation) {
                  errors.password_confirmation = t("crud.errors.required");
                }

                if (values.password_confirmation !== values.password) {
                  errors.password_confirmation = t("confirmationPassword");
                }

                if (!values.password) {
                  errors.password = t("crud.errors.required");
                } else {
                  if (values.password.length < 8) {
                    errors.password = t("8char");
                  }

                  const containsUppercase = /[A-Z]/.test(values.password);
                  const containsLowercase = /[a-z]/.test(values.password);
                  const containsNumber = /[0-9]/.test(values.password);
                  const containsSymbol = /[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]/.test(values.password);
                  const isValidLength = values.password.length >= 8 && values.password.length <= 16;

                  if (!containsUppercase) {
                    errors.password = t("password_validation.uppercase");
                  }

                  if (!containsLowercase) {
                    errors.password = t("password_validation.lowercase");
                  }

                  if (!containsNumber) {
                    errors.password = t("password_validation.digit");
                  }

                  if (!containsSymbol) {
                    errors.password = t("password_validation.symbol");
                  }

                  if (!isValidLength) {
                    errors.password = t("password_validation.long");
                  }
                }

                return errors;
              }}
            >
              {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} className="form">
                  <div className="personal-details-container">
                    <div className="width-100">
                      <div className="personal_input">
                        <Input
                          type={oldType}
                          label={`${t("currpass")} :`}
                          name="old_password"
                          placeholder={t("currpass")}
                          onChange={handleChange}
                          value={values.old_password}
                          error={errors.old_password}
                        />
                        <div className="auth_eye_2" onClick={showHideOld}>
                          {oldType === "input" ? <img src={CloseEye} alt="" /> : <img src={OpenEye} alt="" />}
                        </div>
                      </div>
                    </div>
                    <div className="width-100">
                      <div className="personal_input">
                        <Input
                          type={newType}
                          label={`${t("newpass")} :`}
                          name="password"
                          placeholder={t("newpass")}
                          onChange={handleChange}
                          value={values.password}
                          error={errors.password}
                        />
                        <div className="auth_eye_2" onClick={showHideNew}>
                          {newType === "input" ? <img src={CloseEye} alt="" /> : <img src={OpenEye} alt="" />}
                        </div>
                      </div>
                    </div>
                    <div className="width-100">
                      <div className="personal_input">
                        <Input
                          type={confirmationType}
                          label={`${t("renewpass")} :`}
                          name="password_confirmation"
                          placeholder={t("renewpass")}
                          onChange={handleChange}
                          value={values.password_confirmation}
                          error={errors.password_confirmation}
                        />
                        <div className="auth_eye_2" onClick={showHideConfirmation}>
                          {confirmationType === "input" ? <img src={CloseEye} alt="" /> : <img src={OpenEye} alt="" />}
                        </div>
                      </div>
                    </div>
                    <div className="width-100">
                      <div className="profile-save-container">
                        <CustomButton
                          value={t("save")}
                          colors={"#036c77"}
                          type="submit"
                          loading={isSubmitting}
                          disable={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </MainBox>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
