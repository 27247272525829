import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";
import { getNeededDataCreateAdvertisement } from "../../../Services/api/Advertisement/AdProvider";

const AdvertisementsFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
}) => {
  const { t, i18n } = useTranslation();

  const [types, setTypes] = useState([]);
  const [audience_types, setAudience_types] = useState([]);
  useEffect(() => {
    getNeededDataCreateAdvertisement()
      .then((res) => {
        if (res.status === 200) {
          setAudience_types(
            res.data.data.audience_types.map((audience) => ({
              ...audience,
              label: audience.text,
            }))
          );
          setTypes(
            res.data.data.types.map((type) => ({ ...type, label: type.text }))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const statusOptions = [
    {
      label: t("general.active"),
      id: 1,
      value: "active",
    },
    {
      label: t("general.inactive"),
      id: 2,
      value: "inactive",
    },
  ];

  const filterFields = [
    {
      id: "title",
      component: "input",
      label: t("title"),
      type: "text",
      placeholder: t("title"),
      name: "title",
      value: filterData.title,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "type",
      label: t("type"),
      component: "select",
      placeholder: t("type"),
      initialValue: t("type"),
      name: "type",
      value: filterData.type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: types || [],
    },
    {
      id: "audience_type",
      label: t("audience_type"),
      component: "select",
      placeholder: t("audience_type"),
      initialValue: t("audience_type"),
      name: "audience_type",
      value: filterData.audience_type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: audience_types || [],
    },

    {
      id: "from_date",
      label: t("general.search_start_date"),
      component: "datePicker",
      placeholder: t("general.search_start_date"),
      name: "from_date",
      value: filterData.from_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
    },
    {
      id: "to_date",
      label: t("general.search_end_date"),
      component: "datePicker",
      placeholder: t("general.search_end_date"),
      name: "to_date",
      value: filterData.to_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
    },
    {
      id: "status",
      label: t("general.status"),
      component: "select",
      placeholder: t("general.status"),
      initialValue: t("general.status"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: statusOptions || [],
    },

    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];
  return <FilterBox fields={filterFields} />;
};

export default AdvertisementsFilter;
