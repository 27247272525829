import React, { useContext, useEffect, useState } from "react";
import {
  edittermsAndConditionsAdmin,
  termsAndConditions,
} from "../../Services/api/toutorials/termsAndConditionsProvider";
import i18n from "../../i18n/i18n";
import { useHistory } from "react-router-dom";
import { Lang } from "../../utils";

import { useTranslation } from "react-i18next";
import HomeIcon from "../../assets/icons/Group 3.svg";
import classes from "./termsAndConditions.module.css";
import MainBox from "../Shared/Components/MainBox/MainBox";

function TermsAndConditions(props) {
  const { t } = useTranslation();
  const history = useHistory();
  // require("./termsAndConditions.css");
  const [termsCondition, setTermsCondition] = useState("");

  useEffect(() => {
    termsAndConditions()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTermsCondition(res.data.terms);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ margin: "40px 0" }}>
          <div
            style={{
              display: "flex",
              gap: "15px",
              flex: "2 1 auto",
              alignItems: "center",
            }}
          >
            <img
              src={HomeIcon}
              alt=""
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />

            <p className={classes["title"]}>{t("TermsAndConditionsTitle")}</p>
          </div>
        </div>
        <MainBox>
          {/* <Typography
            component={"h1"}
            variant="h2"
            color={"primary.main"}
            fontWeight={500}
            mb={"32px"}
            fontFamily="dinNextRegular"
          >
            {t("TermsAndConditionsTitle")}
          </Typography> */}

          <div
            dangerouslySetInnerHTML={{
              __html:
                i18n.language == Lang.AR
                  ? termsCondition?.content_ar
                  : termsCondition?.content_en,
            }}
          ></div>
        </MainBox>{" "}
      </div>
    </>
  );
}

export default TermsAndConditions;
