import { Pagination } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import clearEmptyProp from "../../../Helpers/ClearEmptyProp";

import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";

import PlatformManagementFilter from "./PlatformManagementFilter";
import PlatformManagementTable from "./PlatformManagementTable";
import { filterActions } from "./PlatformManagementActions";
import classes from "./PlatformManagement.module.css";

import { fetchPlatformsList } from "../../../Redux/Actions/PlatformManagement/PlatformManagement.action";

const PlatformManagement = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  //   const { systemUsersListMeta } = useSelector(
  //     (state) => state.systemUsersListReducer
  //   );
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const { platformsListMeta } = useSelector(
    (state) => state.platformsListReducer
  );

  const [dataToExport, setDataToExport] = useState([]);

  const [filterData, setFilterData] = useState({
    name: "",
    status: "",
  });

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      status: "",
    });
    setSortColumn("");
    setSortOrder("");
    setIsResetApplied(true);
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.status && (filter.status = filterData.status);

    sortColumn && (filter.column = sortColumn);
    sortOrder && (filter.dir = sortOrder);

    return filter;
  };

  const getplatformsListHandler = () => {
    const filter = getFilterData();
    setDataToExport(filter);

    dispatch(
      fetchPlatformsList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
  };

  useEffect(() => {
    getplatformsListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, sortColumn, sortOrder]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "events",
      page: t("PlatformManagementlist"),
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      //   await exportRoles(clearEmptyProp(dataToExport), type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["events-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["events-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("PlatformManagementlist")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    addAction: () => {
                      history.push("/admin/PlatformManagement/add");
                    },
                    // exportPdf: () => {
                    //   exportHandler("pdf");
                    // },
                    // exportExcel: () => {
                    //   exportHandler("xlsx");
                    // },
                  })}
                />
                {showFilter && (
                  <PlatformManagementFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getplatformsListHandler}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}

                <PlatformManagementTable
                  sortAction={sortActionHandler}
                  currentPage={currentPage}
                />
                <Pagination
                  count={platformsListMeta.last_page || 0} /////////
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformManagement;
