import React from "react";
import { useHistory } from "react-router";
import classes from "./CounterNumbers.module.css";

function CounterNumbers(props) {
  const history = useHistory();
  require("./CounterNumbers.css");

  const linkHandler = () => {
    if (
      props.link == "/admin/bank-transfers" ||
      props.link == "/accountant/bank-transfers"
    ) {
      history.push({
        pathname: `${props.link}`,
        state: {
          status: 3,
        },
      });
    } else if (
      props.link == "/admin/tickets" ||
      props.link == "/accountant/tickets"
    ) {
      history.push({
        pathname: `${props.link}`,
        state: {
          status: "closed",
        },
      });
    } else if (
      props.link == "/admin/roles/trainer" ||
      props.link == "/admin/roles/trainer"
    ) {
      history.push({
        pathname: `/admin/roles`,
        state: {
          status: "trainer",
        },
      });
    } else if (
      props.link == "/admin/roles/trainee" ||
      props.link == "/admin/roles/trainee"
    ) {
      history.push({
        pathname: `/admin/roles`,
        state: {
          status: "trainee",
        },
      });
    } else if (
      props.name == "corner" ||
      props.name == "activeCoupons" ||
      props.name == "courses"
    ) {
    } else {
      history.push(`${props.link}`);
    }
  };

  return (
    <div
      className={`${classes.card_cont} ${props.link ? " link-cursor" : ""}`}
      style={{ backgroundColor: props.color }}
      onClick={linkHandler}
    >
      <img src={props.image} alt="" className="card-image" />
      <div>
        <p className={classes.Num_card}>
          {props.number}
          {/* {props.link ? <i className="fa fa-arrow-left"></i> : null} */}
        </p>
        <p className={classes.label_card}>{props.label}</p>
      </div>
    </div>
  );
}

export default CounterNumbers;
