import { Formik } from "formik";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { paymentAction } from "../../../Services/api/toutorials/PaymentsProvaider";
import exit from "../../../assets/icons/remove-red.svg";
import ApproveIcon from "../../../assets/image/user-check.svg";
import RejectIcon from "../../../assets/image/user-x.svg";
import { utcToLocal } from "../../../utils/utcToLocal";
import BankModal from "../../Payments/BankModal";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import ModelWithInput from "../../Shared/Components/ModelWithInput/ModelWithInput";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";
import Tabs from "../../Shared/Components/Tabs/Tabs";
import classes from "./bankTransfers.module.css";

const BankTransfersTable = ({
  activeTab,
  setActiveTab,
  setFilterData,
  bankTransfers,
  getBankTransfers,
  currentPage,
}) => {
  const { t, i18n } = useTranslation();
  const approvePaymentModalRef = useRef(null);
  const rejectPaymentModalRef = useRef(null);
  const bankModalRef = useRef(null);
  const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false);
  const [showRejectPaymentModal, setShowRejectPaymentModal] = useState(false);
  const [paymentActionData, setPaymentActionData] = useState(null);
  const [showBankModal, setShowBankModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const paymentsActionsList = [
    {
      id: "approve",
      icon: <img src={ApproveIcon} alt="" />,
      action: (id) => {
        openApprovePaymentModal(id);
      },
      tooltip: t("general.approve"),
    },
    {
      id: "reject",
      icon: <img src={RejectIcon} alt="" />,
      action: (id) => {
        openRejectPaymentModal(id);
      },
      tooltip: t("general.reject"),
    },
  ];

  const openApprovePaymentModal = (id) => {
    setShowApprovePaymentModal(true);
    setPaymentActionData({ id: id, status: "Approved" });
    approvePaymentModalRef.current.showModal();
  };

  const closeApprovePaymentModal = () => {
    approvePaymentModalRef.current.dismissModal();
    setShowApprovePaymentModal(false);
    setPaymentActionData(null);
  };

  const openRejectPaymentModal = (id) => {
    setShowRejectPaymentModal(true);
    setPaymentActionData({ id: id, status: "Rejected" });
    rejectPaymentModalRef.current.showModal();
  };

  const closeRejectPaymentModal = () => {
    rejectPaymentModalRef.current.dismissModal();
    setShowRejectPaymentModal(false);
    setPaymentActionData(null);
  };

  const approvePaymentHandler = () => {
    paymentAction({
      id: paymentActionData.id,
      status: paymentActionData.status,
    })
      .then((res) => {
        toast.success(res.data.msg);
        getBankTransfers();
      })
      .catch((err) => {
        console.log(err);
        toast.error(t("general.error"));
      })
      .finally(() => {
        closeApprovePaymentModal();
      });
  };

  const openBankModalHandler = (payment) => {
    setShowBankModal(true);
    bankModalRef.current.showModal();
    setPaymentData(payment);
  };

  const closeBankModalHandler = () => {
    setShowBankModal(false);
    bankModalRef.current.dismissModal();
    setPaymentData(null);
  };

  const paymentDisplay = {
    Bank: t("general.bank"),
    Wallet: t("general.wallet"),
    tebX: t("general.tebX"),
  };

  const displayPaymentType = {
    course: t("trainer.course.label"),
    program_payment: t("trainer.program.label"),
    booth: t("booth.label"),
    exhibition_visit: t("Exhibition.label"),
    wallet: t("wallet_payment"),
  };

  const displayGift = (is_gift) => {
    if (is_gift) {
      return t("yes");
    }
    return t("no");
  };

  const tabs = [
    {
      id: "waiting_requests",
      key: 1,
      label: t("financial.payments_request.waiting_req"),
    },

    {
      id: "requests_approved",
      key: 2,
      label: t("financial.payments_request.approved_req"),
    },
    {
      id: "requests_rejected",
      key: 3,
      label: t("financial.payments_request.rejected_req"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (currentPage * 10 - 10)}</>
      ),
    },
    {
      id: "photo",
      name: t("general.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["departments-trainees__table-photo"]}>
          {rowData?.user?.img ? (
            <img src={rowData?.user?.img} alt="" />
          ) : (
            <i class="fa fa-user" aria-hidden="true"></i>
          )}
        </div>
      ),
    },
    {
      id: "name",
      name: t("user_name"),
      key: "name",
      cell: ({ rowData }) => (
        <>
          {i18n.language === "ar"
            ? rowData?.user?.name_ar
            : rowData?.user?.name_en || "-"}
        </>
      ),
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => (
        <a
          className={classes["departments-trainees__table-email"]}
          href={`mailto: ${rowData?.user?.email}`}
        >
          {rowData?.user?.email || "-"}
        </a>
      ),
    },
    {
      id: "roles",
      name: t("roles"),
      key: "roles",
      cell: ({ rowData }) => (
        <>
          {rowData?.user?.roles?.map((role) => role.label).join(" | ") || "-"}
        </>
      ),
    },
    {
      id: "payment_type",
      name: t("payments.payable_type"),
      key: "payment_type",
      cell: ({ rowData }) => (
        <>{displayPaymentType[rowData.payable_type] || "-"}</>
      ),
    },
    {
      id: "payment_method",
      name: t("payments.payment_method"),
      key: "payment_method",
      cell: ({ rowData }) => (
        <div className={classes["payment-method"]}>
          {rowData.payment_method === "Bank" ? (
            <button
              className={classes["payment-method--bank"]}
              onClick={() => {
                openBankModalHandler(rowData);
              }}
            >
              {paymentDisplay[rowData.payment_method] || "-"}
            </button>
          ) : (
            <p
              style={{
                margin: 0,
              }}
            >
              {paymentDisplay[rowData.payment_method] || "-"}
            </p>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      id: "payable_name",
      name: t("payments.payable_name"),
      key: "payable_name",
      cell: ({ rowData }) => <>{rowData?.payable_name || "-"}</>,
    },
    {
      id: "payment_courses",
      name: t("payments.payment_courses"),
      key: "payment_courses",
      cell: ({ rowData }) =>
        rowData?.covered_courses?.length > 0 ? (
          <p>
            {rowData?.covered_courses?.map((course) => course.name).join(" | ")}
          </p>
        ) : (
          <p>-</p>
        ),
    },
    // {
    //   id: "gift",
    //   name: t("gift"),
    //   key: "gift",
    //   cell: ({ rowData }) => <>{displayGift(rowData?.is_gift) || "-"}</>,
    // },
    // {
    //   id: "origianl_price",
    //   name: t("payments.original_price"),
    //   key: "origianl_price",
    //   cell: ({ rowData }) => (
    //     <>{`${rowData?.origianl_price} ${t("rs")}` || "-"}</>
    //   ),
    // },
    {
      id: "payment_price",
      name: t("payments.payment_price"),
      key: "payment_price",
      cell: ({ rowData }) => (
        <>{`${Number(rowData?.paid_amount)} ${t("rs")}` || "-"}</>
      ),
    },
    // {
    //   id: "discount_price",
    //   name: t("payments.discount_price"),
    //   key: "discount_price",
    //   cell: ({ rowData }) => (
    //     <>{`${Number(rowData?.offer_amount)} ${t("rs")}` || "-"}</>
    //   ),
    // },
    // {
    //   id: "tax_price",
    //   name: t("payments.tax_price"),
    //   key: "tax_price",
    //   cell: ({ rowData }) => (
    //     <>{`${Number(rowData?.tax_amount)} ${t("rs")}` || "-"}</>
    //   ),
    // },
    // {
    //   id: "coupon_price",
    //   name: t("payments.coupon_price"),
    //   key: "coupon_price",
    //   cell: ({ rowData }) => (
    //     <>{`${Number(rowData?.coupon_amount)} ${t("rs")}` || "-"}</>
    //   ),
    // },
    ...(activeTab === 2 || activeTab === 3
      ? [
          {
            id: "processed_by",
            name: t("general.processed_by"),
            key: "processed_by",
            cell: ({ rowData }) => (
              <>
                {rowData?.processor
                  ? i18n.language === "ar"
                    ? rowData.processor.name_ar
                    : rowData.processor.name_en
                  : "-"}
              </>
            ),
          },
        ]
      : []),
    ...(activeTab === 3
      ? [
          {
            id: "rejection_reason",
            name: t("financial.payments_request.rejected_reason"),
            key: "rejection_reason",
            // cell: ({ rowData }) => <>{rowData?.rejection_reason || "-"}</>,
            cell: ({ rowData }) => (
              <div style={{ width: 300 }}>
                <p
                  className={classes["note_table"]}
                  dangerouslySetInnerHTML={{
                    __html: rowData?.rejection_reason || "-",
                  }}
                ></p>
              </div>
            ),
          },
        ]
      : []),
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) =>
        rowData?.created_at ? (
          <>
            {moment(rowData?.created_at).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.created_at)}
          </>
        ) : (
          "-"
        ),
    },
    ...(activeTab === 2 || activeTab === 3
      ? [
          {
            id: "processed_at",
            name: t("general.processed_at"),
            key: "processed_at",
            cell: ({ rowData }) =>
              rowData?.processed_at ? (
                <>
                  {moment(rowData?.processed_at).format("DD/MM/YYYY")}
                  <br />
                  {utcToLocal(rowData?.processed_at)}
                </>
              ) : (
                "-"
              ),
          },
        ]
      : []),
    ...(activeTab === 1
      ? [
          {
            id: "actions",
            name: t("general.actions"),
            key: "actions",
            cell: ({ rowData }) => (
              <TableActionsColumn
                actions={paymentsActionsList}
                id={rowData.id}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <div className={classes["departments-trainees__tables"]}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onSelectTab={(key) => {
          setFilterData({
            payer_name: "",
            payer_email: "",
            payable_type: "",
            role: "",
            payable_name: "",
            payable_serial_number: "",
            payable_code: "",
          });
          setActiveTab(key);
        }}
      />
      <MainTable
        columns={columns}
        data={bankTransfers || []}
        isLoading={false}
      />
      <BasicModal ref={approvePaymentModalRef}>
        {showApprovePaymentModal && (
          <SharedModal
            title={t("payments.approve")}
            cancelText={t("general.cancel")}
            confirmMsg={t("payments.approve_msg")}
            confirmText={t("general.approve")}
            onConfirm={approvePaymentHandler}
            onCancel={closeApprovePaymentModal}
          />
        )}
      </BasicModal>
      <BasicModal ref={rejectPaymentModalRef}>
        {showRejectPaymentModal && (
          <MainBox className="p-0 rounded">
            <div className={classes["reject-payment-modal"]}>
              <div className={classes["reject-payment-modal__header"]}>
                <h3>{t("general.reject")}</h3>
                <img src={exit} alt="" onClick={closeRejectPaymentModal} />
              </div>
              <div className={classes["reject-payment-modal__body"]}>
                <Formik
                  initialValues={{
                    rejection_reason: "",
                  }}
                  onSubmit={(values) => {
                    const data = {
                      id: paymentActionData.id,
                      status: paymentActionData.status,
                      rejectionReason: values.rejection_reason,
                    };
                    paymentAction(data)
                      .then((res) => {
                        toast.success(res.data.msg);
                        getBankTransfers();
                      })
                      .catch((err) => {
                        console.log(err);
                        toast.error(t("general.error"));
                      })
                      .finally(() => {
                        closeRejectPaymentModal();
                      });
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.rejection_reason) {
                      errors.rejection_reason = t("crud.errors.required");
                    }
                    return errors;
                  }}
                  validateOnChange={true}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    errors,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="rejection_reason">
                          {t("financial.payments_request.rejected_reason")}
                        </label>
                        <textarea
                          id="rejection_reason"
                          name="rejection_reason"
                          style={{
                            border: "1px solid rgb(201, 200, 200)",
                            display: "block",
                            width: "500px",
                            borderRadius: "6px",
                            padding: "15px",
                          }}
                          maxLength={3000}
                          value={values.rejection_reason}
                          onChange={handleChange}
                          placeholder={t(
                            "financial.payments_request.rejected_reason"
                          )}
                          defaultValue={values.rejection_reason}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.rejection_reason
                            ? errors.rejection_reason
                            : ""}
                        </p>
                      </div>
                      <div className={classes["reject-payment-modal__submit"]}>
                        <CustomButton
                          value={t("submit")}
                          type="submit"
                          action={handleSubmit}
                          colors="#036c77"
                          disabled={false}
                          classes={classes["reject-payment-modal__submit-btn"]}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </MainBox>
        )}
      </BasicModal>
      <BasicModal ref={bankModalRef}>
        {showBankModal && (
          <BankModal
            closeBankModalHandler={closeBankModalHandler}
            paymentData={paymentData}
          />
        )}
      </BasicModal>
    </div>
  );
};

export default BankTransfersTable;
