import { ContactInformation } from "./";
import { Grid, Stack } from "@mui/material";
import { ContainerBox } from "../Shared/Components";
import HomeIcon from "../../assets/icons/Group 3.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import classes from "./contactUs.module.css";
import ContactUsUpper from "./contactUsUpper/ContactUsUpper";
import ContactUsForm from "./ContactUs-Form/ContactUsForm";
function ContactUs() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <>
      <div className="container-fluid" style={{ marginBottom: 40 }}>
        <div className="row" style={{ margin: "40px 0" }}>
          <div
            style={{
              display: "flex",
              gap: "15px",
              flex: "2 1 auto",
              alignItems: "center",
            }}
          >
            <img
              src={HomeIcon}
              alt=""
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />

            <p className={classes["title"]}>{t("contactUs")}</p>
          </div>
        </div>
        <ContactUsUpper />
      </div>
      <ContactUsForm />
    </>
  );
}

export default ContactUs;
