import Pusher from "pusher-js";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SpinnerProvider } from "./Components/Spinner/SpinnerContext";
import withRoot from "./Hoc/withRoot";
// import ChatBubble from "./Modules/LiveChat/ChatBubble";
import { useDispatch, useSelector } from "react-redux";
import MainHeader from "./Modules/Shared/Components/Header/MainHeader";
import BasicModalInstance from "./Modules/Shared/Components/Modal/BasicModalInstance/BasicModalInstance";
import Footer from "./Modules/Shared/Components/footer/footer";
import { ModalContextProvider } from "./Providers/ModalProvider";
import { PusherProvider } from "./Providers/PusherProvider";
import { getlivechatSettings } from "./Redux/Actions/livechat/livechat.action";
import NetworkDetector from "./Services/NetworkDetector";
import { getPreferences } from "./Services/api/Preferences/PreferencesService";
import { getListType } from "./Services/api/TypeFile/TypeFileProvider";
import { AuthProvider } from "./Services/api/auth/AuthContext";
import { FooterContext } from "./Services/api/toutorials/FooterContext";

// Pusher.logToConsole = true;
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const footerCtx = useContext(FooterContext);
  const query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const [livechatSetting, setLivechatSetting] = useState({});
  const { livechat_setting } = useSelector((state) => state.livechatReducer);

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    appId: process.env.REACT_APP_PUSHER_APP_ID,
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    secret: process.env.REACT_APP_PUSHER_APP_SECRET,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    useTLS: false,
    channelAuthorization: {
      endpoint: process.env.REACT_APP_PUSHER_AUTH_URL,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS",
      },
    },
  });

  const fetchPreferences = () => {
    const myPromise = new Promise((resolve, reject) => {
      getPreferences().then(
        (res) => {
          if (res.status == 200 || res.status == 201) {
            const fetchedSettings = res.data.setting;
            const toBeSavedObject = {
              chat_service: fetchedSettings.chat_service ? true : false,
              support_tickets_service: fetchedSettings.support_tickets_service ? true : false,
              welcome_message_service: fetchedSettings.welcome_message_service ? true : false,
              support_tickets_faq_service: fetchedSettings.support_tickets_faq_service ? true : false,
              courses_faq_service: fetchedSettings.courses_faq_service ? true : false,
            };
            localStorage.setItem("system_preferences", JSON.stringify(toBeSavedObject));
          }
          resolve();
        },
        (error) => {
          reject();
        }
      );
    });

    return myPromise;
  };
  // Save File Type:
  const fetchFileType_Admin = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          if (res.status == 200 || res.status == 201) {
            const fetchedTypes = res.data.data;
            localStorage.setItem("system_files_types", JSON.stringify(fetchedTypes));
          }
          resolve();
        },
        (error) => {
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      fetchPreferences();
      fetchFileType_Admin();
    }
    dispatch(getlivechatSettings());
  }, []);

  // const fetchSetting = () => {
  //   if (livechat_setting?.status === 200) {
  //     setLivechatSetting(livechat_setting.data.setting.chat_allowed);
  //   }
  // };

  // useEffect(() => {
  //   fetchSetting();
  // }, [livechat_setting]);

  return (
    <SpinnerProvider>
      <ScrollToTop />
      <AuthProvider>
        <PusherProvider instance={pusher}>
          <ModalContextProvider>
            <BasicModalInstance></BasicModalInstance>
            <div
              style={{
                minHeight: "calc(100vh - 130.6px)",
              }}
            >
              <MainHeader />
            </div>
            {/* {livechatSetting ? null : <ChatBubble></ChatBubble>} */}
            {footerCtx.footer && <Footer query={query.get("method_block")} />}
          </ModalContextProvider>
        </PusherProvider>
      </AuthProvider>
      <ToastContainer />
    </SpinnerProvider>
  );
}

export default NetworkDetector(withRoot(App));
