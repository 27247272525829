import React, {useEffect, useState} from "react";
import i18n from "../../../../../i18n/i18n";
import {useTranslation} from "react-i18next";
import logo from "../../../../../assets/image/logo.png";
import {profileInfo} from "../../../../../Services/api/toutorials/AdminUserProvider";
import TrainingCourseSpinnerCard
    from "../../../../TrainingCourses/TrainingCoursesLayout/TrainingCourseSpinnerCard/TrainingCourseSpinnerCard";
import noResult from "../../../../../assets/image/Noresult.png";

import {useHistory} from "react-router";

function AllCoursesStd(props) {
  require("./allCoursesStd.css");
  document.body.dir = i18n.dir();
  const history = useHistory();
  const { t } = useTranslation();
  const [allCourses, setAllCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    profileInfo()
      .then((res) => {
        if (res.status && res.status == 201 && res.data.status) {
          console.log(res.data);
          setLoading(false);
          setAllCourses(res.data.response);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="head_box mt-5">
              <div className="head_lable">{t("courseteach")}</div>
            </div>
            <div className={allCourses.courses && allCourses.courses.length == 0 ? "" : "courses_card_grid"}>
              {loading == true ? (
                <>
                  <TrainingCourseSpinnerCard />
                  <TrainingCourseSpinnerCard />
                  <TrainingCourseSpinnerCard />
                </>
              ) : allCourses.courses && allCourses.courses.length == 0 ? (
                <span
                  className="course-details"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "50px"
                  }}
                >
                  <img src={noResult} alt="" />
                  <h5>{t("no_record")}</h5>
                </span>
              ) : (
                allCourses.courses &&
                allCourses.courses.map((course) => (
                  <div className="courses_card_std" key={course.id}>
                    <div
                      className="courses_card_first_std"
                      style={{
                        backgroundImage: "url(" + course.img + ")",
                      }}
                    >
                      <div className="courses_name">
                        <div className="logo_hover">
                          <img src={logo} alt="" className="logo_hover_width" />
                        </div>
                        <div className="">{course.name}</div>
                      </div>
                    </div>
                    <div className="courses_card_second_std">
                      <div className="courses_card_point">
                        <div className=""></div>
                        <div className="">
                          {(!course.progress.total &&
                          !course.quiz_progress.total) ? '100%'
                            : Math.round(((course.progress.progress + course.quiz_progress.progress) /
                                (course.progress.total + course.quiz_progress.total)) *
                              100) + '%'
                            }

                        </div>
                      </div>
                      {/* <div className="">
                        {t("point")} {course.progress.progress} /
                        {course.progress.total}
                      </div> */}
                    </div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${
                            (!course.progress.total &&
                              !course.quiz_progress.total) ? 100
                                : Math.round(((course.progress.progress + course.quiz_progress.progress) /
                                    (course.progress.total + course.quiz_progress.total)) *
                                  100)
                                
                          }%`,
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div
                      className="courses_card_button"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/training-courses/${course.id}/profile`);
                      }}
                    >
                      <button className="courses_card_button_in">
                        {t("contstudy")}
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCoursesStd;
