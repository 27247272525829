import connector from "../Handler";
import env from "../Environment";

export async function getAllDepartment() {
  try {
    const response = connector.get(env.get_all_department);
    //   console.log('get all res = ', response);
    return response;
  } catch (error) {
    console.log(error);
  }
}
