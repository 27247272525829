import React, {Component} from "react";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function NetworkDetector (ComposedComponent) {
  class NetworkDetector extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isDisconnected: false,
      };
    }

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener("online", this.handleConnectionChange);
      window.addEventListener("offline", this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener("online", this.handleConnectionChange);
      window.removeEventListener("offline", this.handleConnectionChange);
    }

    handleConnectionChange = () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        // const webPing = setInterval(
        //   () => {
        //     fetch("//google.com", {
        //       mode: "no-cors",
        //     })
        //       .then(() => {
        //         this.setState({ isDisconnected: false }, () => {
        //           return clearInterval(webPing);
        //         });
        //       }).catch(() => this.setState({ isDisconnected: true }));
        //   }, 2000,
        // );
        return;
      }
      const connects = this.setState({ isDisconnected: true });
      toast("Error Connection", {
        position: "top-right",
      });
      return connects;

    }
    render() {

      const { isDisconnected } = this.state;
      return (
        <div>
          { isDisconnected ? (
            <ToastContainer/>
          ) : null}
          <ComposedComponent {...this.props} />
        </div>
      );
    }

  }

  return React.memo(NetworkDetector);
}
