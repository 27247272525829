import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Lang } from "../../../utils";
import classes from "./mainSection.module.css";

const MainSectionSelection = ({
  name,
  options,
  placeholder,
  onChange,
  value,
  isHomeCourses,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {isHomeCourses ? (
        <Select
          isClearable={true}
          name={name}
          id={name}
          className={classes["main__filter__selection-field"]}
          options={options}
          getOptionLabel={(option) =>
            i18n.language === Lang.AR ? option.name_ar : option.name_en
          }
          getOptionValue={(option) => option.id}
          onChange={(item, removedValue) => {
            onChange(name, item?.id, removedValue);
          }}
          value={value || null}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              background: "transparent",
              cursor: "pointer",
              border: state.isFocused ? 0 : 0,
              boxShadow: state.isFocused ? 0 : 0,
              "&:hover": {
                border: state.isFocused ? 0 : 0,
              },
            }),
          }}
        />
      ) : (
        <Select
          isClearable={true}
          name={name}
          id={name}
          className={classes["main__filter__selection-field"]}
          options={options}
          getOptionLabel={(option) =>
            i18n.language === Lang.AR ? option.name_ar : option.name_en
          }
          getOptionValue={(option) => option.id}
          onChange={(item, removedValue) => {
            onChange(name, item?.id, removedValue);
          }}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              background: "transparent",
              cursor: "pointer",
              border: state.isFocused ? 0 : 0,
              boxShadow: state.isFocused ? 0 : 0,
              "&:hover": {
                border: state.isFocused ? 0 : 0,
              },
            }),
          }}
        />
      )}
    </>
  );
};

export default MainSectionSelection;
