import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  updateSystemUserStatus,
  acceptSystemUserStatus,
  rejectSystemUserStatus,
} from "../../../Redux/Actions/systemUsers/systemUsersList";
import DeleteIcon from "../../../assets/icons/deleteTable.svg";

import EditIcon from "../../../assets/icons/pen.svg";

import { Lang } from "../../../utils";
import MainTable from "../../Shared/Components/MainTable/MainTable";

import classes from "./Events.module.css";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import { cancelEvent } from "../../../Redux/Actions/events/events.action";
import { toast } from "react-toastify";

const EventsTable = ({ sortAction, currentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [sendEmails, setSendEmails] = useState(0);

  const confirmCancelEventHandler = () => {
    if (!eventId) return;
    dispatch(cancelEvent(eventId, sendEmails));
    setShowCancelModal(false);
  };
  const cancelHandler = (id) => {
    setShowCancelModal(true);
    setEventId(id);
  };

  const { eventsList, eventsListLoading } = useSelector(
    (state) => state.eventsListReducer
  );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };
  //   useEffect(() => {}, [systemUsersList]);

  const eventsActionsList = [
    {
      id: "edit",
      icon: <img src={EditIcon} alt="" />,
      action: (id, name) => {
        if (name) {
          return toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("cant_edit_cancel")}
            </span>
          );
        } else {
          if (localStorage.getItem("type") === "group-admin") {
            history.push(`/group-admin/events/${id}/edit`);
          } else if (localStorage.getItem("type") === "manager") {
            history.push(`/manager/events/${id}/edit`);
          } else {
            history.push(`/admin/events/${id}/edit`);
          }
        }
      },
      tooltip: t("general.edit"),
      color: "#24b3b9",
    },

    {
      id: "cancel",
      icon: <img src={DeleteIcon} alt="" />,
      action: (id, name) => {
        if (name) {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("already_canceled")}
            </span>
          );
        } else {
          cancelHandler(id);
        }
      },
      tooltip: t("cancel"),
      color: "#e62626",
    },

    {},
  ];
  const displayMode = (canceled) => {
    if (canceled) {
      return t("canceled");
    } else {
      return t("inTime");
    }
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (currentPage * 10 - 10)}</>
      ),
    },
    {
      id: "title",
      name: t("title"),
      key: "title",
      cell: ({ rowData }) => <div>{rowData?.title || "-"}</div>,
      sortable: false,
    },
    {
      id: "type",
      name: t("type"),
      key: "type",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{ backgroundColor: `${rowData?.type?.color}` }}
        >
          {rowData?.type?.text || "-"}
        </span>
      ),
      sortable: false,
    },
    {
      id: "audience_type",
      name: t("audience_type"),
      key: "audience_type",
      cell: ({ rowData }) => <div>{rowData?.audience_type?.text || "-"}</div>,
      sortable: false,
    },
    {
      id: "date",
      name: t("general.event_date"),
      key: "date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.date)}
        </>
      ),
      sortable: true,
    },
    {
      id: "location",
      name: t("location_title"),
      key: "location",
      cell: ({ rowData }) => <div>{rowData?.location || "-"}</div>,
      sortable: false,
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: true,
    },
    {
      id: "status",
      name: t("status"),
      key: "status",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: "#fff",
            backgroundColor: rowData?.canceled
              ? "rgba(165, 8, 8, 0.685)"
              : "#12C689",
          }}
        >
          {displayMode(rowData?.canceled) || "-"}
        </span>
      ),
      sortable: false,
    },

    {
      id: "actions",
      name: t("system_users.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn
          actions={eventsActionsList}
          id={rowData.id}
          name={rowData.canceled}
        />
      ),
      sortable: false,
    },
  ];
  // console.log("systemUsersList", systemUsersList);
  return (
    <>
      <MainTable
        columns={columns}
        data={eventsList || []}
        isLoading={eventsListLoading}
        sortAction={sortAction}
      />
      {showCancelModal && (
        <SharedModal
          icon={DeleteIcon}
          title={t("event_cancel_title")}
          confirmMsg={t("event_cancel_msg")}
          confirmText={t("general.confirm")}
          cancelText={t("general.cancel")}
          isCancel={true}
          checkBoxMsg={t("send_emails")}
          setCheckboxValue={setSendEmails}
          onCancel={() => {
            setShowCancelModal(false);
          }}
          onConfirm={(send_email) => {
            confirmCancelEventHandler(send_email);
          }}
          isLoading={false}
        />
      )}
    </>
  );
};

export default EventsTable;
