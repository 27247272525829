import React from "react";
import ArrowRight from "../../../assets/icons/arrow.png";
import classes from "./usersProfile.module.css";
import { useTranslation } from "react-i18next";

export default function UsersProfileItemBox({
  onClick,
  title,
  description,
  icon,
}) {
  const { t } = useTranslation();
  return (
    <div onClick={onClick} className={classes["profile-item-box"]}>
      <div className={classes.titleCont}>
        <div className={classes["profile-icon"]}>
          <img src={icon} alt="" />
        </div>
        <div className={classes["profile-name"]}>{title}</div>
      </div>
      <div className={classes["profile-description"]}>
        {t("more")}
        <div className={classes.imgCont}>
          <img src={ArrowRight} alt="" />
        </div>
      </div>
    </div>
  );
}
