import React from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from "chart.js";
import {Bar} from "react-chartjs-2";
import {useTranslation} from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart(props) {
  const { t } = useTranslation();

  let lessonProgress = props.lessonProgress;

  let titleArray = [];
  let lesson = lessonProgress.map((l) => titleArray.push(l.title));

  let progressArray = [];
  let progress = lessonProgress.map((l) => progressArray.push(l.progress));

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  const labels = [...titleArray];

  const data = {
    labels,
    datasets: [
      {
        label: t("lessons"),
        data: [...progressArray],
        backgroundColor: "rgba(78, 121, 167, 0.7)",
      },
    ],
  };

  return (
    <Bar
      options={options}
      data={data}
      style={{
        background: "#fff",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "2px 2px 10px #ddd",
      }}
    />
  );
}

export default BarChart;
