import { phoneConstant } from "../../Constants/Phone/phone.constant";

const initialState = {
  phoneCode: "",
};

const phoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case phoneConstant.GET_PHONE_CODE:
      return {
        ...state,
        phoneCode: action.payload,
      };

    default:
      return state;
  }
};

export default phoneReducer;
