import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getFaqList } from "../../Services/api/FAQ/FaqListProvider";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";

export default function FaqList() {
  require("./faqlist.css");

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [faq, setFaq] = useState([]);
  const [filter, setFilter] = useState([]);
  const [meta, setMeta] = useState({});
  const [selectOptionVal, setSelectOptionVal] = useState(null);

  useEffect(() => {
    // setIsLoading(false);
    async function executeOnLoad() {
      let initialParams = {
        perPage: 10,
      };
      let response = await getFaqList(initialParams);

      if (response.status && response.status == 200) {
        setIsLoading(false);
        setFaq(response.data.data.faq.data);
        setMeta(response.data.data.faq.meta);
        setFilter(response.data.data.filter.ticketTypes);
        setSelectOptionVal(1);
      }
    }

    executeOnLoad();
  }, []);

  const handlePageClick = (event) => {
    let Page = event.selected + 1;
    FAQList(null, 10, null, Page);
  };
  const handleChange = async (event) => {
    let ticketTypeId = event.target.value;
    setSelectOptionVal(ticketTypeId);
    if (ticketTypeId == "all") {
      FAQList(null, 10);
    } else {
      FAQList(ticketTypeId, 10);
    }
  };
  const FAQList = async (ticketTypeId, perPage, text, page) => {
    try {
      let Params = {
        ticketTypeIds: ticketTypeId ? [ticketTypeId] : null,
        perPage: perPage,
        text: text ? text : null,
        page: page ? page : null,
      };
      let response = await getFaqList(Params);

      if (response.status && response.status == 200) {
        setFaq(response.data.data.faq.data);
        setMeta(response.data.data.faq.meta);
        setFilter(response.data.data.filter.ticketTypes);
      }
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };
  const handelSubmit = async (event) => {
    event.preventDefault();
    let SearchInputVal = event.target.search.value;
    FAQList(selectOptionVal, 10, SearchInputVal);
  };

  function addNewlines(str, type) {
    let len = "";
    if (type == "question") {
      len = 38;
    } else {
      len = 60;
    }
    var result = "";
    while (str.length > 0) {
      result += str.substring(0, len) + "\n";
      str = str.substring(len);
    }
    return result;
  }
  return (
    <div className="faq-list-pg">
      <div className="header_profile">
        <div className="container-fluid profile_wrapper">
          <div className="profile_grid">
            <div className="profile_dhead">
              <h3>{t("FAQ.answer_bank")}</h3>
            </div>
            <div className="btn-tickets">
              <NavLink to="/tickets" className="">
                {t("sidebar.label.support_tickets")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="row">
          <div className="col-12">
            <div className="admin_label">
              <p style={{ padding: "0 5px" }}>/</p>
              {t("FAQ.answer_bank")}
              <p style={{ padding: "0 5px" }}>/</p>
              {t("FAQ.common_questions")}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper FAQ-from">
        <div className="row">
          <div className="col-md-3">
            <select
              name="type_id"
              id="type_id"
              className="admin_add_input"
              // value={selectOptionVal}
              onChange={handleChange}
            >
              <option value={"all"}> {t("FAQ.All_questions")} </option>
              {filter.length > 0 &&
                filter.map((filter, key) => {
                  return (
                    <option key={key} value={filter.id}>
                      {" "}
                      {filter.name}{" "}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-md-9">
            <form onSubmit={handelSubmit}>
              <input
                type="text"
                name="search"
                placeholder={t("block.placeholders.search")}
              />
              <input type="submit" value={t("block.placeholders.search")} />
            </form>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="row">
          <div className="col-12">
            <div className={isLoading ? null : "table_bank_tran faq_box_white"}>
              {isLoading ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <>
                  <h3 className="table_header">{t("FAQ.list_questions")}</h3>
                  <div className="box_faq">
                    <div class="accordion" id="accordion">
                      {faq.length > 0 &&
                        faq.map((faq, key) => {
                          return (
                            <div class="card" key={key}>
                              <div class="card-header" id={`heading_${faq.id}`}>
                                <h2 class="mb-0">
                                  <button
                                    class="btn btn-link"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#collapse_${faq.id}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse_${faq.id}`}
                                  >
                                    {i18n.language === "ar"
                                      ? addNewlines(faq.question_ar, "question")
                                      : addNewlines(faq.question, "question")}
                                  </button>
                                </h2>
                              </div>
                              <div
                                id={`collapse_${faq.id}`}
                                class={`collapse ${key == 0 ? " show" : ""}`}
                                aria-labelledby={`heading_${faq.id}`}
                                data-parent="#accordion"
                              >
                                <div class="card-body">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: `${
                                        i18n.language === "ar"
                                          ? addNewlines(faq.answer_ar, "answer")
                                          : addNewlines(faq.answer, "answer")
                                      }`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {faq.length == 0 ? (
                        <p
                          style={{
                            color: "#a4a4a4",
                            textAlign: "center",
                            margin: "0",
                          }}
                        >
                          {" "}
                          {t("FAQ.FAQ_no_answer")}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </>
              )}
              <ReactPaginate
                nextLabel={t("next")}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={meta.totalPages}
                previousLabel={t("previous")}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
