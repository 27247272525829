import { platformsListConstants } from "../../Constants/PlatformManagement/PlatformManagement.constant";

const initialState = {
  platformsList: [],
  platformsListMeta: {},
  platformsListLoading: false,
  platformsListError: null,
  platformsListSuccess: false,

  deletePlatformLoading: false,
  deletePlatformSuccess: false,
  deletePlatformError: null,
};

const platformsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case platformsListConstants.GET_PLATFORMS_LIST_REQUEST:
      return {
        ...state,
        platformsListLoading: true,
      };
    case platformsListConstants.GET_PLATFORMS_LIST_SUCCESS:
      return {
        ...state,
        platformsListLoading: false,
        platformsListSuccess: true,
        platformsList: action.payload.groups.data,
        platformsListMeta: action.payload.groups.meta,
      };
    case platformsListConstants.GET_PLATFORMS_LIST_REJECTED:
      return {
        ...state,
        platformsListLoading: false,
        platformsListError: action.payload,
        platformsListSuccess: false,
      };

    //  DELETE_ Advertisement
    case platformsListConstants.DELETE_PLATFORM_REQUEST:
      return {
        ...state,
        deletePlatformLoading: true,
      };

    case platformsListConstants.DELETE_PLATFORM_SUCCESS:
      return {
        ...state,
        deletePlatformLoading: false,
        deletePlatformSuccess: true,
        deletePlatformError: null,

        platformsList: state.platformsList.filter(
          (platform) => platform.id !== action.payload.id
        ),
      };

    case platformsListConstants.DELETE_PLATFORM_REJECTED:
      return {
        ...state,
        deletePlatformLoading: false,
        deletePlatformError: action.payload,
        deletePlatformSuccess: false,
      };

    default:
      return state;
  }
};

export default platformsListReducer;
