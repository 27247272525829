import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { exportCouponUsers } from "../../../../Services/api/coupon/couponProvider";
import {
  getCouponInfo,
  getCouponUsers,
} from "../../../../Services/api/coupon/couponProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import { Lang } from "../../../../utils";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../Shared/Components/DataTable/DataTable";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import classes from "./couponDetails.module.css";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import { filterActions } from "./CouponTableActionUser";
import CouponUsersTableFilter from "./CouponUsersTableFilter";
import CouponUserTable from "./CouponUserTable";
import { Pagination } from "@mui/material";

const CouponDetails = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [currentCouponFilter, setCurrentCouponFilter] = useState({});
  const [filterData, setFilterData] = useState({
    payer_name: "",
    payer_email: "",
    creation_date: "",
  });
  const [tableData, setTableData] = useState(null);
  const [tableMeta, setTableMeta] = useState({});

  const [couponDetails, setCouponDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      payer_name: "",
      payer_email: "",
      creation_date: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = { perPage: 10, page: currentPage };
    filterData.payer_name && (filter.payer_name = filterData.payer_name);
    filterData.payer_email && (filter.payer_email = filterData.payer_email);
    filterData.creation_date &&
      (filter.creation_date = filterData.creation_date);

    return filter;
  };

  const getTableDataHandler = async () => {
    setIsTableLoading(true);
    const filter = getFilterData();
    await getCouponUsers(id, filter) /////////////
      .then((res) => {
        console.log(res);
        if (res.status && res.status == 200 && res.data.status) {
          setTableData(res?.data?.data?.users?.data);
          setTableMeta(res?.data?.data?.users?.meta);
          setCurrentCouponFilter(filter);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return history.push("/admin/page-not-found");
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {error?.response?.data?.msg
              ? error?.response?.data?.msg
              : "Failure in service"}
          </span>
        );
        console.log("ERROR");
        console.log(error);
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  useEffect(() => {
    getTableDataHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const getCouponHandler = async () => {
    setIsLoading(true);
    await getCouponInfo(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCouponDetails(res?.data?.response);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return history.push("/admin/page-not-found");
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {error?.response?.data?.msg
              ? error?.response?.data?.msg
              : "Failure in service"}
          </span>
        );
        console.log("ERROR");
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "coupon-list",
      page: t("coupon.label.coupon"),
      pagePath: `${
        localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"
      }/coupon`,
    },
    {
      id: "create_exhibition",
      page: couponDetails?.name,
      active: true,
    },
  ];

  const traineesFields = [
    {
      id: "name",
      column: ({ rowData }) => (
        <div>
          {i18n.language === Lang.AR ? rowData?.name_ar : rowData?.name_en}
        </div>
      ),
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.email}</div>,
    },
  ];

  const coursesFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
    {
      id: "date",
      column: ({ rowData }) => (
        <div>{moment(rowData?.end_register_date).format("DD/MM/YYYY")}</div>
      ),
    },
  ];

  const programsFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
  ];
  useEffect(() => {
    getCouponHandler();
  }, [id]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportCouponUsers(type, currentCouponFilter, id);
      console.log("export");
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["coupon-details"]}>
      <div className="container-fluid">
        <div className={classes["coupon-details__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isLoading ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <MainBox>
                  {!couponDetails?.is_valid && (
                    <div className={classes["coupon-details__warning__note"]}>
                      {t("coupon.messages.coupon_in_valid")}
                    </div>
                  )}
                  <MainBoxHead
                    title={couponDetails?.name}
                    actions={[
                      {
                        id: "coupon-status",
                        type: "switch",
                        value: true,
                        active: t("general.active"),
                        inactive: t("general.inactive"),
                        checked: couponDetails?.is_valid,
                        disabled: true,
                      },
                    ]}
                  />
                  <div
                    className={`row ${classes["coupon-details__form__fields"]}`}
                  >
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12`}
                    >
                      <Input
                        label={t("coupon.placeholders.coupon_name")}
                        type="text"
                        name="name"
                        placeholder={t("coupon.placeholders.coupon_name")}
                        value={couponDetails?.name}
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t("coupon.label.machinsim")}
                        type="text"
                        name="mechanism"
                        placeholder={t("coupon.label.machinsim")}
                        value={
                          couponDetails?.mechanism == "1"
                            ? t("coupon.label.per_value")
                            : t("coupon.label.co_value")
                        }
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t("coupon.placeholders.value_coupon")}
                        type="text"
                        name="value"
                        placeholder={t("coupon.placeholders.value_coupon")}
                        value={couponDetails?.value}
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t("general.start_date_time")}
                        type="text"
                        name="start_date"
                        placeholder={t("general.start_date_time")}
                        value={moment(
                          getLocaleTime(new Date(couponDetails?.start_date))
                        ).format("YYYY-MM-DD A hh:mm")}
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t("general.end_date_time")}
                        type="text"
                        name="end_date"
                        placeholder={t("general.end_date_time")}
                        value={moment(
                          getLocaleTime(new Date(couponDetails?.end_date))
                        ).format("YYYY-MM-DD A hh:mm")}
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12`}
                    >
                      <Formik>
                        {() => (
                          <DataTable
                            label={t("coupon.label.direct_to")}
                            title={t("select_names")}
                            selectedItems={
                              couponDetails?.trainees.map((item) => item.id) ||
                              []
                            }
                            data={couponDetails?.trainees || []}
                            fields={traineesFields}
                            isMultiple={true}
                            searchableFields={["name_ar", "name_en", "id"]}
                            disabled={true}
                            canSelectAll={true}
                          />
                        )}
                      </Formik>
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12`}
                    >
                      <Input
                        label={t("general.type")}
                        type="text"
                        name="course_program"
                        placeholder={t("general.type")}
                        value={
                          couponDetails?.parent_type == 1
                            ? t("coupon.label.course")
                            : t("coupon.label.program")
                        }
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12`}
                    >
                      <Formik>
                        {() => (
                          <DataTable
                            label={
                              couponDetails?.parent_type == 1
                                ? t("coupon.label.course")
                                : t("coupon.label.program")
                            }
                            title={t("select_names")}
                            selectedItems={
                              couponDetails?.parent?.id
                                ? [couponDetails?.parent?.id]
                                : []
                            }
                            data={
                              couponDetails?.parent
                                ? [couponDetails?.parent]
                                : []
                            }
                            fields={
                              couponDetails?.parent_type == 1
                                ? coursesFields
                                : programsFields
                            }
                            searchableFields={
                              couponDetails?.parent_type == 1
                                ? ["id", "name", "end_register_date"]
                                : ["id", "name"]
                            }
                            disabled={true}
                          />
                        )}
                      </Formik>
                    </div>
                  </div>
                  <MainBoxHead
                    title={t("coupon.label.coupon")}
                    actions={filterActions({
                      showFilter: showFilterHandler,

                      exportPdf: () => {
                        exportHandler("pdf");
                      },
                      exportExcel: () => {
                        exportHandler("xlsx");
                      },
                    })}
                  />
                  {showFilter && (
                    <CouponUsersTableFilter
                      filterData={filterData}
                      changeHandler={changeHandler}
                      resetHandler={resetHandler}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      filterHandler={getTableDataHandler}
                      resetFilterHandler={resetFilterHandler}
                    />
                  )}
                  <CouponUserTable
                    isTableLoading={isTableLoading}
                    tableData={tableData}
                  />
                  <Pagination
                    count={tableMeta?.totalPages || 0}
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    className="main-pagination"
                    onChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                  />
                </MainBox>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponDetails;
