import { ErrorMessage, Field, Formik } from "formik";
import { toast } from "react-toastify";
import toLocalTimezone, { toServerTime } from "../../Services/Timezone";
import { addCloneFromSurvey } from "../../Services/api/survey/SurveyProvider";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DatePicker from "react-datepicker";

function CalendarIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 22"
    >
      <g id="calendar_9_" data-name="calendar (9)" transform="translate(-2 -1)">
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="18"
          height="18"
          rx="2"
          transform="translate(3 4)"
          fill="none"
          stroke="#24b3b9"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_1"
          data-name="Line 1"
          y2="4"
          transform="translate(16 2)"
          fill="none"
          stroke="#24b3b9"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_2"
          data-name="Line 2"
          y2="4"
          transform="translate(8 2)"
          fill="none"
          stroke="#24b3b9"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_3"
          data-name="Line 3"
          x2="18"
          transform="translate(3 10)"
          fill="none"
          stroke="#24b3b9"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

function CloseIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
    >
      <g id="x-circle_2_" data-name="x-circle (2)" transform="translate(-1 -1)">
        <circle
          id="Ellipse_23"
          data-name="Ellipse 23"
          cx="10"
          cy="10"
          r="10"
          transform="translate(2 2)"
          fill="none"
          stroke="#e62626"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_35"
          data-name="Line 35"
          x1="6"
          y2="6"
          transform="translate(9 9)"
          fill="none"
          stroke="#e62626"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_36"
          data-name="Line 36"
          x2="6"
          y2="6"
          transform="translate(9 9)"
          fill="none"
          stroke="#e62626"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

export default function CloneSurveyModal({ survey, isTrainer, onClose }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const isPuclicSurvey =
    survey.lesson_id == null &&
    survey.course_id == null &&
    survey.exhibition_id == null;
  console.log({isPuclicSurvey, survey});
  const { t } = useTranslation();
  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-py-8 tw-h-full tw-w-full">
      <div
        className={`tw-bg-white tw-shadow tw-rounded tw-w-full tw-max-h-full md:tw-w-2/3 lg:tw-w-3/4 xl:tw-w-1/2 tw-divide-y inner-scrollbar`}
        style={{ overflowY: "overlay" }}
      >
        <div className="tw-flex tw-items-center tw-justify-between tw-px-8 tw-py-4">
          <div className="tw-text-xl tw-text-teal-700">
            {t("survey.surveyClone")}
          </div>
          <button type="button" onClick={onClose}>
            <CloseIcon className="tw-h-6 tw-w-6" />
          </button>
        </div>
        <Formik
          initialValues={{
            name: `${survey.name} (copy)` ?? "",
            start_time:
              survey.start_time && isPuclicSurvey
                ? toLocalTimezone(survey.start_time)
                : "",
            end_time:
              survey?.end_time && isPuclicSurvey
                ? toLocalTimezone(survey?.end_time)
                : "",
            is_disabled: true,
          }}
          onSubmit={async (values) => {
            const data = {
              name: values.name,
              start_time: isPuclicSurvey
                ? toServerTime(values.start_time)
                : null,
              end_time: isPuclicSurvey ? toServerTime(values.end_time) : null,
              is_disabled: values.is_disabled ? 1 : 0,
            };
            try {
              const res = await addCloneFromSurvey(data, survey.id);
              onClose();
              toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {t("survey.messages.cloneSurvey")}
                </span>
              );
            } catch (error) {
              toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {error?.data?.msg ?? "Error Message"}
                </span>
              );
            }
          }}
          validate={(values) => {
            setHasSubmitted(true);
            const errors = {};
            console.log(values);
            if (!values.name) {
              errors.name = t("crud.errors.required");
            }
            if (isPuclicSurvey) {
              if (
                !values.start_time ||
                new Date(values.start_time) < Date.now()
              ) {
                errors.start_time = t("survey.errors.startTime");
              }
              if (
                !values.end_time ||
                new Date(values.end_time) < values.start_time
              ) {
                errors.end_time = t("survey.errors.endTime");
              }
            }

            if (Object.keys(errors).length) {
              console.log(errors);
            }
            return errors;
          }}
          validateOnChange={hasSubmitted}
        >
          {({
            setFieldValue,
            setValues,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-px-8 tw-py-4 tw-space-y-4">
                <div className="tw-space-y-4 tw-mb-4">
                  <div>
                    <div className="tw-flex tw-items-end tw-justify-between">
                      <label
                        htmlFor="name"
                        className="tw-text-gray-600 tw-font-medium tw-mb-2"
                      >
                        {t("survey.name")}
                      </label>
                      <button
                        type="button"
                        onClick={() => {
                          setFieldValue("is_disabled", !values.is_disabled);
                        }}
                        className={`tw-relative tw-w-20 tw-mb-2 tw-py-2 tw-px-1 tw-rounded-full tw-flex tw-items-center tw-justify-between tw-transition-all ${
                          !values.is_disabled
                            ? "tw-bg-teal-800 tw-text-white tw-pl-7"
                            : "tw-bg-gray-200 !tw-text-gray-700 tw-pr-7"
                        }`}
                      >
                        <div className="tw-text-xs tw-white-space-nowrap tw-grow tw-text-center">
                          {t(values.is_disabled ? "Exhibition.inactive" : "Exhibition.active")}
                        </div>
                        <div
                          className={`tw-absolute tw-top-1/2 -tw-translate-y-1/2 ${
                            !values.is_disabled ? "tw-left-2" : "tw-right-2"
                          } tw-h-5 tw-w-5 tw-bg-white tw-shadow tw-rounded-full`}
                        ></div>
                      </button>
                    </div>
                    <div>
                      <Field
                        id="name"
                        name="name"
                        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        placeholder={t("survey.name")}
                      />
                      <ErrorMessage
                        name={`name`}
                        component="div"
                        className="tw-text-xs tw-text-red-700 tw-h-4"
                      />
                    </div>
                  </div>
                  {isPuclicSurvey ? (
                    <>
                      <div>
                        <label className="tw-block">
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t('start_date')}
                          </div>

                          <div className="tw-relative">
                            <DatePicker
                              popperProps={{
                                strategy: "fixed",
                              }}
                              selected={Date.parse(values.start_time)}
                              defaultValue={Date.parse(values.start_time)}
                              onChange={(date) => {
                                setFieldValue("start_time", date);
                                if (date > values.end_time)
                                  setFieldValue("end_time", "");
                              }}
                              showTimeSelect
                              placeholderText={t("survey.start_time")}
                              className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                              showTime={{ use12Hours: true }}
                              dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                              minDate={new Date()}
                              minTime={
                                values.start_time &&
                                new Date(
                                  new Date(values.start_time).toDateString()
                                ) > new Date(new Date().toDateString())
                                  ? undefined
                                  : Date.now()
                              }
                              maxTime={
                                new Date(
                                  new Date(values.start_time).toDateString()
                                ) > new Date(new Date().toDateString())
                                  ? undefined
                                  : new Date().setHours(23, 59, 0, 0)
                              }
                            />
                            <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
                          </div>
                        </label>

                        <ErrorMessage
                          name={`start_time`}
                          component="div"
                          className="tw-text-xs tw-text-red-700 tw-h-4"
                        />
                      </div>

                      <div>
                        <label className="tw-block">
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t('end_date')}
                          </div>

                          <div className="tw-relative">
                            <DatePicker
                              popperProps={{
                                strategy: "fixed",
                              }}
                              selected={Date.parse(values.end_time)}
                              defaultValue={Date.parse(values.end_time)}
                              onChange={(date) =>
                                setFieldValue("end_time", date)
                              }
                              showTimeSelect
                              placeholderText={t("survey.end_time")}
                              className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                              showTime={{ use12Hours: true }}
                              dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                              minDate={
                                values.start_time
                                  ? Date.parse(values.start_time)
                                  : new Date()
                              }
                              minTime={
                                values.start_time
                                  ? values.end_time &&
                                    new Date(
                                      new Date(values.end_time).toDateString()
                                    ) >
                                      new Date(
                                        new Date(
                                          values.start_time
                                        ).toDateString()
                                      )
                                    ? undefined
                                    : Date.parse(values.start_time)
                                  : values.end_time &&
                                    new Date(
                                      new Date(values.end_time).toDateString()
                                    ) > new Date(new Date().toDateString())
                                  ? undefined
                                  : Date.now()
                              }
                              maxTime={
                                (values.start_time &&
                                  values.end_time &&
                                  new Date(
                                    new Date(values.end_time).toDateString()
                                  ) >
                                    new Date(
                                      new Date(values.start_time).toDateString()
                                    )) ||
                                (!values.start_time &&
                                  values.end_time &&
                                  new Date(
                                    new Date(values.end_time).toDateString()
                                  ) > new Date(new Date().toDateString()))
                                  ? undefined
                                  : new Date().setHours(23, 59, 0, 0)
                              }
                            />
                            <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
                          </div>
                        </label>

                        <ErrorMessage
                          name={`end_time`}
                          component="div"
                          className="tw-text-xs tw-text-red-700 tw-h-4"
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="tw-bg-teal-700 disabled:tw-bg-teal-700/70 tw-py-2 tw-px-16 tw-rounded tw-text-white"
                  >
                    {t("clone")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
