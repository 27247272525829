import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CourseProfileContext } from "../../../../../Services/api/courses/Trainer/CourseProfileContext";

function LoadingContent(props) {
  require("./loadingContent.css");
  const { t } = useTranslation();
  const courseProfileContext = useContext(CourseProfileContext);
  const [opacityTransitionEnded, setOpacityTransitionEnded] = useState(false);

  useEffect(() => {
    const transition = document.getElementsByClassName("training-course-syllabus-loading-section-container");
    "transitionend webkitTransitionEnd oTransitionEnd otransitionend".split(" ").forEach((item) => {
      transition[0].addEventListener(item, removeLoadingPanel);
    });
  }, []);

  const removeLoadingPanel = () => {
    setOpacityTransitionEnded(true);
  };

  return (
    <>
      <div
        className={"training-course-syllabus-loading-section-container" + (opacityTransitionEnded ? " unactive" : "")}
        style={{
          opacity: courseProfileContext.isChaptersLoaded ? 0 : 1,
        }}
      >
        <div className="training-course-syllabus-section-container" style={{ padding: "unset" }}>
          <div
            className="training-course-syllabus-section-container-border"
            style={{ opacity: courseProfileContext.isChaptersLoaded ? 0 : 1 }}
          >
            <div className="training-course-syllabus-section-container-row">
              <div className="training-course-syllabus-section-container-row-header">
                <div className="skeleton-box skeleton-margin-top-bottom" style={{ width: "50%" }}></div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div className={"training-course-syllabus-section-container-row-lesson-header"}>
                  <div className="skeleton-box skeleton-margin-top-bottom" style={{ width: "35%" }}></div>
                </div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div className={"training-course-syllabus-section-container-row-lesson-header"}>
                  <div className="skeleton-box skeleton-margin-top-bottom" style={{ width: "45%" }}></div>
                </div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div className={"training-course-syllabus-section-container-row-lesson-header"}>
                  <div className="skeleton-box skeleton-margin-top-bottom" style={{ width: "60%" }}></div>
                </div>
              </div>
            </div>

            {/* <div className="training-course-syllabus-section-container-row">
                            <div className="training-course-syllabus-section-container-row-header">
                                <div className="skeleton-box skeleton-margin-top-bottom" style={{ width: "33%" }}></div>
                            </div>
                            <div className="training-course-syllabus-section-container-row-lesson">
                                <div className={"training-course-syllabus-section-container-row-lesson-header"}>
                                    <div className="skeleton-box skeleton-margin-top-bottom" style={{ width: "29%" }}></div>
                                </div>
                            </div>
                            <div className="training-course-syllabus-section-container-row-lesson">
                                <div className={"training-course-syllabus-section-container-row-lesson-header"}>
                                    <div className="skeleton-box skeleton-margin-top-bottom" style={{ width: "53%" }}></div>
                                </div>
                            </div>
                        </div> */}

            <div className="training-course-syllabus-section-container-row">
              <div className="training-course-syllabus-section-container-row-header">
                <div className="skeleton-box skeleton-margin-top-bottom" style={{ width: "43%" }}></div>
              </div>
              <div className="training-course-syllabus-section-container-row-lesson">
                <div className={"training-course-syllabus-section-container-row-lesson-header"}>
                  <div className="skeleton-box skeleton-margin-top-bottom" style={{ width: "60%" }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoadingContent;
