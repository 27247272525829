import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import notFoundFAQ from "../../../../../assets/image/FAQNotFound.png";
// eslint-disable-next-line no-unused-vars
import { toast } from "react-toastify";
import { getDiscussion } from "../../../../../Services/api/DiscussionBoard/DiscussionProvider";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";

const Content = (props) => {
  require("./content.css");
  const { t } = useTranslation();
  const location = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { id } = useParams();
  const { courseId } = useParams();
  const history = useHistory();
  const [discussionBoard, setDiscussionBoard] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getListDiscussion(id);
  }, []);

  const getListDiscussion = async (id) => {
    try {
      let response = await getDiscussion(id);
      if (response.data) {
        setDiscussionBoard(response.data.discussion);
        setIsLoading(false);
        props.setBtnShow(false);
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response.status == 404) {
        setDiscussionBoard(null);
      } else {
        toast.error(
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response.data.msg}
          </span>
        );
      }
    }
  };

  return (
    <div className="add-discussions">
      <div className="container-fluid profile_wrapper">
        <div className="row">
          <div className="col-12">
            <div
              className="admin_label"
              style={{
                paddingTop: "5%",
                paddingBottom: "5%",
              }}
            >
              <NavLink to="/">{t("admin.label.admin_label")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              <NavLink
                to={
                  location.state && location.state.userType == "trainee"
                    ? `/training-courses/${courseId}/profile`
                    : `/trainer/course/${courseId}`
                }
              >
                {t("discussionBoard.course")}
              </NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("discussionBoard.discussion")}
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            height: "55vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : discussionBoard ? (
        <div className="list-goals">
          <div style={{ display: "flex", paddingBottom: "30px" }}>
            <h4>{t("discussionBoard.Discussing_chapter")}</h4>
            <div className="icon-disc">
              <NavLink
                className="btn-edit"
                to={{
                  pathname: `/course/${courseId}/discussion-board/edit/${id}`,
                  state: { data: discussionBoard },
                }}
              >
                {t("discussionBoard.EditContent")}
                <i class="fas fa-edit" style={{ padding: "0 5px" }}></i>
              </NavLink>
              <NavLink
                to={{
                  pathname: `/course/${courseId}/discussion-board/${id}`,
                }}
              >
                {t("discussionBoard.Review")}
                <i
                  class="fas fa-eye"
                  style={{
                    color: "#333",
                    cursor: "pointer",
                    padding: "0 5px",
                  }}
                ></i>
              </NavLink>
            </div>
          </div>

          <div>
            <h4 className="m-0">{discussionBoard.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: discussionBoard.description }}></div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="goals-not-found">
              <p> {t("discussionBoard.not_add_discussion")}</p>
              <img src={notFoundFAQ} alt="" className="box_new_chapter" />
              <div>
                <NavLink to={`/course/${courseId}/discussion-board/add/${id}`} className="btn-add-goals">
                  <i class="fas fa-plus"></i>
                  {t("discussionBoard.addDiscussion")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Content;
