import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import classes from "./walletList.module.css";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import moment from "moment";
import { utcToLocal } from "../../../../utils/utcToLocal";

const WalletListTable = () => {
  const { walletTransactions, isLoading } = useSelector(
    (state) => state.userWalletReducer
  );

  const { t } = useTranslation();

  const displayPaymentType = {
    course: t("trainer.course.label"),
    program_payment: t("trainer.program.label"),
    booth: t("booth.label"),
    exhibition_visit: t("Exhibition.label"),
  };
  const displayStatus = {
    Waiting: <span className={classes["waiting"]}>{t("general.waiting")}</span>,
    Approved: (
      <span className={classes["approved"]}>{t("general.approved")}</span>
    ),
    Rejected: (
      <span className={classes["rejected"]}>{t("general.rejected")}</span>
    ),
    Canceled: (
      <span className={classes["canceled"]}>{t("general.canceled")}</span>
    ),
  };
  const displayGift = (is_gift) => {
    if (is_gift) {
      return t("yes");
    }
    return t("no");
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "transaction_type",
      name: t("wallet.label.WDtransactiontype"),

      key: "transaction_type",
      cell: ({ rowData }) => <div>{rowData?.payable_target}</div>,
      sortable: false,
    },
    {
      id: "payment_type",
      name: t("payments.payable_type"),
      key: "payment_type",
      cell: ({ rowData }) => (
        <>{displayPaymentType[rowData?.payable_type] || "-"}</>
      ),
    },
    {
      id: "payable_name",
      name: t("payments.payable_name"),
      key: "payable_name",
      cell: ({ rowData }) => <>{rowData?.payable_name || "-"}</>,
    },
    {
      id: "payment_courses",
      name: t("payments.payment_courses"),
      key: "payment_courses",
      cell: ({ rowData }) =>
        rowData?.covered_courses?.length > 0 ? (
          <p>
            {rowData?.covered_courses?.map((course) => course.name).join(" | ")}
          </p>
        ) : (
          <p>-</p>
        ),
    },
    // {
    //   id: "amount",
    //   name: t("wallet.label.WDamount"),
    //   key: "amount",
    //   cell: ({ rowData }) => (
    //     <ul>
    //       {rowData.amount} {t("rs")}
    //     </ul>
    //   ),
    //   sortable: false,
    // },
    // {
    //   id: "nametransactiondirection",
    //   name: t("wallet.label.WDnametransactiondirection"),

    //   key: "nametransactiondirection",
    //   cell: ({ rowData }) => <div>{rowData?.morph_data?.name || "-"}</div>,
    //   sortable: false,
    // },
    // {
    //   id: "transactiondirection",
    //   name: t("wallet.label.WDtransactiondirection"),

    //   key: "transactiondirection",
    //   cell: ({ rowData }) => <div>{rowData?.morph_type || "-"}</div>,
    //   sortable: false,
    // },
    // {
    //   id: "gift",
    //   name: t("gift"),
    //   key: "gift",
    //   cell: ({ rowData }) => <>{displayGift(rowData?.is_gift) || "-"}</>,
    // },
    // {
    //   id: "origianl_price",
    //   name: t("payments.original_price"),
    //   key: "origianl_price",
    //   cell: ({ rowData }) => (
    //     <>{`${rowData?.origianl_price} ${t("rs")}` || "-"}</>
    //   ),
    // },
    {
      id: "payment_price",
      name: t("payments.payment_price"),
      key: "payment_price",
      cell: ({ rowData }) => (
        <>{`${Number(rowData?.paid_amount)} ${t("rs")}` || "-"}</>
      ),
    },
    // {
    //   id: "discount_price",
    //   name: t("payments.discount_price"),
    //   key: "discount_price",
    //   cell: ({ rowData }) => (
    //     <>{`${Number(rowData?.offer_amount)} ${t("rs")}` || "-"}</>
    //   ),
    // },
    // {
    //   id: "tax_price",
    //   name: t("payments.tax_price"),
    //   key: "tax_price",
    //   cell: ({ rowData }) => (
    //     <>{`${Number(rowData?.tax_amount)} ${t("rs")}` || "-"}</>
    //   ),
    // },
    // {
    //   id: "coupon_price",
    //   name: t("payments.coupon_price"),
    //   key: "coupon_price",
    //   cell: ({ rowData }) => (
    //     <>{`${Number(rowData?.coupon_amount)} ${t("rs")}` || "-"}</>
    //   ),
    // },
    // {
    //   id: "processed_by",
    //   name: t("general.processed_by"),
    //   key: "processed_by",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.processor ? (i18n.language === "ar" ? rowData.processor.name_ar : rowData.processor.name_en) : "-"}
    //     </>
    //   ),
    // },
    {
      id: "rejection_reason",
      name: t("financial.payments_request.rejected_reason"),
      key: "rejection_reason",
      // cell: ({ rowData }) => <>{rowData?.rejection_reason || "-"}</>,
      cell: ({ rowData }) => (
        <div
          dangerouslySetInnerHTML={{ __html: rowData?.rejection_reason || "-" }}
        ></div>
      ),
    },
    {
      id: "created_at",
      name: t("wallet.label.WDtransactiondata"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {rowData?.created_at ? (
            <>
              {moment(rowData?.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.created_at)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
      sortable: false,
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => <>{displayStatus[rowData?.status] || "-"}</>,
    },
  ];
  return (
    <MainTable
      columns={columns}
      data={walletTransactions || []}
      isLoading={isLoading}
    />
  );
};

export default WalletListTable;
