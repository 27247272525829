import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getlivechatViewSession,
  livechatChangeMessage,
  postPrivateChatSendMessage,
} from "../../../../../Redux/Actions/livechat/livechat.action";
import CloseIcon from "../../../../../assets/icons/CloseChat.svg";
import ChatBubbleInputs from "../../../../LiveChat/ChatBubbleInputs";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner/LoadingSpinner";
import Select from "../../../../Shared/Components/Select/Select";
import Massages from "../../../../Trainer/Course/CoursePrivateChat/Massages/Massages";
import classes from "./privateChatPopup.module.css";

const PrivateChatPopup = ({
  handleClosePrivateChat,
  setMessages,
  messages,
  sessionId,
  setSessionId,
  sessions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { livechat_view_session } = useSelector(
    (state) => state.livechatReducer
  );

  const [inputMessage, setInputMessage] = useState("");
  const [messagesLoading, setMessagesLoading] = useState(true);
  const [openSelect, setOpenSelect] = useState(false);

  function sendMessage() {
    if (inputMessage.trim() !== "") {
      let formData = new FormData();
      formData.append("chat_session_id", sessionId);

      formData.append("message", inputMessage);
      dispatch(postPrivateChatSendMessage(formData));
      dispatch(livechatChangeMessage());

      setInputMessage("");
    }
  }

  const clickOutside = (e) => {
    if (!e.target.closest("#privateChatPopup")) {
      handleClosePrivateChat();
    }
  };

  const fetchMessages = (id) => {
    setMessagesLoading(true);
    dispatch(getlivechatViewSession(id));
  };

  useEffect(() => {
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, []);

  useEffect(() => {
    if (sessions.length > 0) {
      setSessionId(sessions[0].id);
      dispatch(getlivechatViewSession(sessions[0].id));
    }
  }, []);

  useEffect(() => {
    if (livechat_view_session.status === 200) {
      setMessages(livechat_view_session.data.response.messages);
      setMessagesLoading(false);
    }
  }, [livechat_view_session]);

  const handler = (event) => {
    if (event.key == "Enter") sendMessage();
  };

  return (
    <div id="privateChatPopup" style={{ position: "relative" }}>
      <div id="privateChatPopup" className={classes.privateChatPopup}>
        <div className={classes.privateChatPopupContent}>
          <div className={classes.privateChatPopupHeader}>
            <h3>{t("chat.massages")}</h3>
            <div className={classes.closeIcon} onClick={handleClosePrivateChat}>
              <img src={CloseIcon} alt="close" />
            </div>
          </div>
          <div className={classes.privateChatPopupBody}>
            {sessions.length > 0 && (
              <div className={classes.trainersList}>
                <div
                  className={classes["custom-select"]}
                  onClick={() => setOpenSelect(!openSelect)}
                >
                  <div className={classes["custom-select__selected"]}>
                    <span>
                      {
                        sessions.find((session) => session.id === sessionId)
                          ?.trainer.name
                      }
                    </span>
                  </div>
                  <ul
                    className={`${classes["custom-select__list"]} ${
                      openSelect ? classes["open"] : ""
                    }`}
                  >
                    {sessions.map((session) => (
                      <li
                        key={session.id}
                        onClick={() => {
                          setOpenSelect(false);
                          if (sessionId === session.id) return;
                          fetchMessages(session.id);
                          setSessionId(session.id);
                        }}
                      >
                        {session.trainer.name}
                        {!session.is_seen && (
                          <span className={classes["new-message"]}></span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className={classes.messages}>
              {messagesLoading ? (
                <div
                  className={classes["loading__container"]}
                  style={{ height: "85%" }}
                >
                  <LoadingSpinner />
                </div>
              ) : (
                <Massages messages={messages} height="300px" />
              )}
            </div>
          </div>
        </div>
        <ChatBubbleInputs
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleOnEnter={(e) => handler(e)}
          messages={messages.reverse()}
          sendMessage={sendMessage}
          selectbox={true}
        ></ChatBubbleInputs>
      </div>
    </div>
  );
};

export default PrivateChatPopup;
