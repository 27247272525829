import React from "react";
import classes from "./PublishSeaction.module.css";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import SideImg from "../../../assets/image/sideImg_REQ.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
export default function PublishSeaction() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes.mainCont}>
      <div className={classes.contentCont}>
        <p>{t("publish_seaction_title")}</p>
        <span>
          هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي
          القارئ عن التركيز على الشكل الخارجي للنص او شكل تموضع الفقرات في الفحة
          التي يقرأها شكل{" "}
        </span>
        <div>
          <CustomButton
            type="button"
            colors="rgb(3, 162, 178)"
            value={t("course_publish_request")}
            classes={classes.btnPublish}
            action={() => history.push("/publish-request")}
          />
        </div>
      </div>
      <div className={classes.imgCont}>
        <img src={SideImg} alt="" />
      </div>
    </div>
  );
}
