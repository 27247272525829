import React from "react";
import classes from "./FollowDream.module.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";

export default function FollowDream() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <div className={classes.mainClass}>
      <p className={classes.dreams}>{t("follow_dreams")}</p>
      <CustomButton
        value={t("enroll")}
        colors={"rgb(3, 162, 178)"}
        classes={classes.btnRegister}
        action={() =>
          localStorage.type
            ? history.push("/training-courses")
            : history.push("/register")
        }
      />
    </div>
  );
}
