import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import ProgramHeader from "../ProgramHeader/ProgramHeader";
import infoProgram from "../../../assets/image/info-program.png";
import {useHistory, useParams} from "react-router";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import {fetchProgram, programsPayments} from "../../../Services/api/program/programContext";

function RegisterProgram(props) {
  require("./RegisterProgram.css");
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [checkBox, setCheckBox] = useState(false);
  const [programsPayment, setProgramsPayment] = useState([])
  const [program, setProgram] = useState(null);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  const fetchProgramDetails = () => {
    fetchProgram(id)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            let lockUpcoming = false;
            res.data.program.courses.forEach((item) => {
              if (lockUpcoming) {
                item.lock = true;
              } else {
                if (item.progress.progress == 0 || item.progress.progress != item.progress.total) {
                  lockUpcoming = true;
                  item.progressValue = item.progress.total == 0 ? '0%' : (item.progress.progress / item.progress.total * 100) + '%';
                } else if (item.progress.progress == item.progress.total) {
                  item.done = true;
                }
              }
            })
            setProgram(res.data.program);
            setIsContentLoaded(true);
          }
        })
        .catch((err) => {
          console.log("ERROR");
          console.log(err);
        });
  }

  useEffect(() => {
    if(props.location && props.location.program){
      setProgram(props.location.program);
    }else {
      fetchProgramDetails();
    }
    programsPayments(id)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setProgramsPayment(res.data.payments);
            setIsContentLoaded(true);
          }
        })
        .catch((err) => {
          console.log("ERROR");
          console.log(err);
        });
  }, []);

  const checkBoxHandler = (e) => {
    setCheckBox(e.target.checked);
  };

  const submitForm = (e) => {
    e.preventDefault();
    history.push(`/table-payment/${id}`,{program: program});
  };

  return (
    <>
      <ProgramHeader label={program?.title} />
      <div className="container-fluid">
        <div className="register_label">
          <NavLink to="/">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to={`/program/${id}`}>{program?.title}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {t("register_program")}
        </div>

        <div className="warning_msg">
          <img src={infoProgram} alt="" />
          <p>{t("programs.msg.register_msg")}</p>
        </div>

        {!isContentLoaded ? (
          <div className="skeleton">
            <SkeletonCard />
          </div>
        ) : (
          <div className="batch_program">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">{t("programs.label.payment_number")}</th>
                    <th scope="col">{t("programs.label.due_date")}</th>
                    <th scope="col">{t("programs.label.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {programsPayment.map((payment, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{payment.payment_date}</td>
                      <td>
                        {payment.amount} {t("Rial")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <form onSubmit={submitForm}>
              <div className="checkBox_input">
                <input
                  type="checkbox"
                  name="checkbox"
                  id="approve"
                  checked={checkBox}
                  onChange={checkBoxHandler}
                />
                <label htmlFor="approve">{t("programs.msg.approve_msg")}</label>
              </div>

              <button
                type="submit"
                className="register_btn"
                disabled={!checkBox}
              >
                {t("programs.values.register_now")}
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default RegisterProgram;
