import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";

export function getAnalysis() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.analysis)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

// GET COURSES LIST
export const fetchCoursesListCloneModel = ({
  page = 1,
  perPage = 10,
  role,
}) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.courses_list, { role }), {
          params: {
            page,
            perPage,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error?.response?.data?.message}
        </span>
      );
      reject(error);
    }
  });

  return myPromise;
};
