import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import { traineeCertificatesConstants } from "../../Constants/certificates/trainee-certificates.constants";

// GET TRAINEE CERTIFICATES LIST
export const fetchTraineeCertificatesList = ({ id,page, perPage, filter }) => {
	return async (dispatch) => {
		dispatch({
			type: traineeCertificatesConstants.GET_TRAINEE_CERTIFICATES_LIST_REQUEST,
		});

		await connector
			.get(URLBuilder(env.certificates_id, { id }), {
				params: {
					page,
					perPage,
					...filter,
				},
			})
			.then((response) => {
				if (response?.status === 200 && response?.data?.status) {
					dispatch({
						type: traineeCertificatesConstants.GET_TRAINEE_CERTIFICATES_LIST_SUCCESS,
						payload: {
							list: response?.data?.data?.certificates,
              meta: response?.data?.data?.meta,
						},
					});
				} else {
					toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
					dispatch({
						type: traineeCertificatesConstants.GET_TRAINEE_CERTIFICATES_LIST_REJECTED,
						payload: response?.data?.msg,
					});
				}
			})
			.catch((err) => {
				console.log("err", err);
				toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
				dispatch({
					type: traineeCertificatesConstants.GET_TRAINEE_CERTIFICATES_LIST_REJECTED,
					payload: err,
				});
			});
	};
};
