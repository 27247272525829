import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
// import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import {
	aboutUS,
	editAboutUSAdmin,
} from "../../../Services/api/toutorials/aboutUsProvider";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import { SunEditorText } from "../../Shared/Components";
import { Stack } from "@mui/material";

function AboutUsEdit(props) {
	require("./aboutUs.css");
	const { t } = useTranslation();
	const history = useHistory();
	const [aboutUsAr, setAboutUsAr] = useState("");
	const [aboutUsEn, setAboutUsEn] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		aboutUS()
			.then((res) => {
				if (res.status && res.status == 200 && res.data.status) {
					setAboutUsEn(res.data.aboutUS.description_en);
					setAboutUsAr(res.data.aboutUS.description_ar);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log("ERROR");
				console.log(err);
			});
	}, []);

	const editAboutUs = () => {
		setIsLoading(true);
		const dataValue = { description_en: aboutUsEn, description_ar: aboutUsAr };

		editAboutUSAdmin(dataValue)
			.then((res) => {
				if (res.status && res.status == 200 && res.data.status) {
					history.push("/admin/about-us");
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log("ERROR");
				console.log(err);
				setIsLoading(false);
			});
	};
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="admin_label">
						<NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
						<p style={{ padding: "0 5px" }}>/</p>
						<NavLink to="/admin/about-us">
							{t("sidebar.label.about_us_edit")}
						</NavLink>
						<p style={{ padding: "0 5px" }}>/</p>
						{t("sidebar.label.about-us")}
					</div>
					<div className="admin_card">
						<>
							{isLoading ? (
								<div
									style={{
										height: "34.5vh",
									}}
								>
									<SkeletonCard />
								</div>
							) : (
								<div style={{ width: "90%" }}>
									<Stack
										sx={{
											mt: "32px",
											mb: "12px",
											"& .se-tooltip": {
												gap: "10px",
											},
										}}
									>
										<label htmlFor="">{t("label-about-ar")}:</label>
										<SunEditorText
											value={aboutUsAr}
											setValue={setAboutUsAr}
											dirEditor={"rtl"}
										/>
									</Stack>
								</div>
							)}
						</>
						<>
							{isLoading ? (
								<div
									style={{
										height: "34.5vh",
									}}
								>
									<SkeletonCard />
								</div>
							) : (
								<div style={{ width: "90%" }}>
									<Stack
										sx={{
											mt: "32px",
											mb: "12px",
											"& .se-tooltip": {
												gap: "10px",
											},
										}}
									>
										<label htmlFor="">{t("label-about-en")}:</label>
										<SunEditorText
											value={aboutUsEn}
											setValue={setAboutUsEn}
											dirEditor={"ltr"}
										/>
									</Stack>
								</div>
							)}
						</>

						{isLoading ? (
							<div
								style={{
									height: "5vh",
									width: "200px",
								}}
							>
								<SkeletonCard />
							</div>
						) : (
							<div className="overview_button_div" style={{ width: "90%" }}>
								<button
									className={"overview_button"}
									onClick={(e) => {
										e.stopPropagation();
										editAboutUs();
									}}
								>
									{t("save")}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutUsEdit;
