import env from "../Environment";

import connector from "../Handler";

export function readNotifications(ids) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.read_notifications, { ids: ids })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getNotifications() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(`${env.get_notifications}?get_all=1`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
