import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  editDepartments,
  getDepartments,
} from "../../../../Services/api/courses/courseProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import ManageDepartments from "./ManageDepartments";

const EditDepartment = ({ role }) => {
  const { id } = useParams();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const specialtyId = state?.specialtyId;

  const [departmentDetails, setDepartmentDetails] = useState(null);
  const [getDepartmentLoading, setGetDepartmentLoading] = useState(false);
  const getDepartmentHandler = async () => {
    setGetDepartmentLoading(true);
    getDepartments(id)
      .then((response) => {
        if (response.status && response.status == 200 && response.data.status) {
          setDepartmentDetails(response.data.department);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return history.push("/admin/page-not-found");
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {error?.response?.data?.msg
              ? error?.response?.data?.msg
              : "Failure in service"}
          </span>
        );
        console.log("ERROR");
        console.log(error);
      })
      .finally(() => {
        setGetDepartmentLoading(false);
      });
  };

  useEffect(() => {
    if (!id) return;
    getDepartmentHandler();
  }, []);

  const submitHandler = async (values) => {
    let response = await editDepartments(id, values);
    if (response.status === 200 && response.data.status) {
      if (specialtyId) {
        history.push(`/${role ? role : "admin"}/category/${specialtyId}`);
      } else {
        history.push(`/${role ? role : "admin"}/department`);
      }
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg
            ? response.data.msg
            : t("department_manage.department_updated_successfully")}
        </span>,
        {
          onClose: () => {},
          autoClose: 1000,
        }
      );
    } else {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("department_manage.failed_creating_department")}
        </span>
      );
    }
  };

  return (
    <>
      {getDepartmentLoading ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <ManageDepartments
          type={"edit"}
          departmentDetails={departmentDetails}
          submitHandler={submitHandler}
        />
      )}
    </>
  );
};

export default EditDepartment;
