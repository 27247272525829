import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useLocation, withRouter } from "react-router-dom"; // TODO:
import { toast } from "react-toastify";
import PasswordInput from "../../../Components/Input/PasswordInput";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import {
  changePassword,
  changeToAlternativeEmail,
  getAlternativeEmail,
  login,
  loginSso,
} from "../../../Services/api/auth/AuthProvider";
import { getProfileInfo } from "../../../Services/api/profile/profileProvider";
import { FooterContext } from "../../../Services/api/toutorials/FooterContext";
import warning from "../../../assets/icons/warning-green.svg";
import i18n from "../../../i18n/i18n";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import Input from "../../Shared/Components/Input/Input";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import "../Register/register.css";
import Social from "../Social/Social";
import InvalidEmailModal from "./invalidEmailModal/InvalidEmailModal";
import Logo from "../../../assets/image/Qassem_logo.svg";

function Login(props) {
  require("./login.css");
  document.body.dir = i18n.dir();
  const { t } = useTranslation();
  const { state } = useLocation();
  const authContext = useContext(AuthContext);
  const footerCtx = useContext(FooterContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [disabled, setDisabled] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const [showInvalidEmailModal, setShowInvalidEmailModal] = useState(false);
  const invalidEmailModal = useRef(null);
  const [isAlternativeEmail, setIsAlternativeEmail] = useState(false);
  const [alternativeEmail, setAlternativeEmail] = useState("");

  useEffect(() => {
    footerCtx.setFooter(false);
    return () => {
      footerCtx.setFooter(true);
    };
  }, []);

  const passwordHandleChange = (password) => {
    setPassword(password);
  };

  function validateForm() {
    return forgotPassword
      ? email.length > 0
      : email.length > 0 && password.length > 6;
  }

  const handleOpenInvalidEmailModal = () => {
    setShowInvalidEmailModal(true);
    invalidEmailModal.current.showModal();
  };

  const handleCloseInvalidEmailModal = () => {
    setShowInvalidEmailModal(false);
    invalidEmailModal.current.dismissModal();
  };

  const handleGetAlternativeEmail = () => {
    getAlternativeEmail(email)
      .then((res) => {
        if (res.status == 200) {
          setIsAlternativeEmail(true);
          setAlternativeEmail(res.data.email);
          showForgotPassword();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    const regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (email === "" || email.trim() === "") {
      event.preventDefault();
      setError(<div className="error_login">{t("error_msg_empty_email")}</div>);
    } else if (password === "" || password.trim() === "") {
      event.preventDefault();
      setError(
        <div className="error_login">{t("error_msg_empty_password")}</div>
      );
    } else if (password.length < 6) {
      event.preventDefault();
      setError(
        <div className="error_login">{t("error_msg_length_password")}</div>
      );
    } else if (regex.test(email) === false) {
      event.preventDefault();
      setError(<div className="error_login">{t("error_msg_shape_email")}</div>);
    } else {
      authContext.setIsPerformingAuthenticationOperation(true);
      setDisabled(true);
      event.preventDefault();
      const emailUniversity = email.split("@")[1];
      if (emailUniversity === "iau.edu.sa") {
        loginSso(email, password)
          .then((res) => {
            if (res.data.user.accessToken) {
              let roles = [];
              res.data.user.roles.forEach((item) => {
                roles.push(item.name);
              });
              localStorage.setItem("roles", JSON.stringify(roles));
              localStorage.setItem("token", res.data.user.accessToken);
              localStorage.setItem("name_ar", res.data.user.name_ar);
              localStorage.setItem("name_en", res.data.user.name_en);
              localStorage.setItem("email", res.data.user.email);
              localStorage.setItem("is_admin", res.data.user.is_admin);
              localStorage.setItem("img", res.data.user.img);
              localStorage.setItem("userId", res.data.user.id);

              authContext.setAuth({ token: res.data.user.accessToken });
              authContext.setRoles(JSON.stringify(roles));
              setDisabled(false);
              if (res.data.user.is_admin === true) {
                if (res.data.user.roles[0].name === "group-admin") {
                  history.push("/group-admin");
                } else if (res.data.user.roles[0].name === "manager") {
                  history.push("/manager");
                } else {
                  history.push("/admin");
                }
                window.location.reload();
              } else {
                window.location.href =
                  process.env.REACT_APP_FRONT_BASE_URL + "private-profile";
              }
            }
          })
          .catch((error) => {
            setDisabled(false);
            authContext.setIsPerformingAuthenticationOperation(false);
            console.log(error);
            if (
              error.response.data.msg === "This email was swapped!" ||
              error.response.data.msg === "تم إستبدال هذا البريد الإلكتروني"
            ) {
              toast.error(
                <span style={{ fontSize: 14 }}>{error.response.data.msg}</span>
              );
            } else {
              toast.error(
                <span style={{ fontSize: 14 }}>{error.response.data.msg}</span>
              );
              handleOpenInvalidEmailModal();
            }
            if (error == "Error: Request failed with status code 422") {
              setError(<div className="error_login">{t("error_msg_sso")}</div>);
            }
          });
      } else {
        login(email, password)
          .then((res) => {
            console.log(res.data.user);
            if (res?.data?.user?.accessToken) {
              let roles = [];
              res.data.user.roles.length > 0 &&
                res.data.user.roles.forEach((item) => {
                  roles.push(item.name);
                });
              localStorage.setItem("roles", JSON.stringify(roles));
              localStorage.setItem(
                "type",
                res.data.user.roles[0]?.name || "trainee"
              );
              localStorage.setItem("token", res.data.user.accessToken);
              localStorage.setItem("tokenChat", res.data.user.accessToken);
              localStorage.setItem("name_ar", res.data.user.name_ar);
              localStorage.setItem("name_en", res.data.user.name_en);
              localStorage.setItem("email", res.data.user.email);
              localStorage.setItem("is_admin", res.data.user.is_admin);
              localStorage.setItem("img", res.data.user.img);
              localStorage.setItem("userId", res.data.user.id);
              localStorage.setItem("type_login", res.data.user.type_login);
              localStorage.setItem("phone", res.data.user.phone);
              localStorage.setItem("group", res.data.group?.id);
              // localStorage.removeItem("tokenChat");
              localStorage.removeItem("chat_username");
              localStorage.removeItem("chat_email");
              localStorage.removeItem("chatId");
              authContext.setAuth({ token: res.data.user.accessToken }); // todo:
              authContext.setRoles(JSON.stringify(roles));
              authContext.setIsPerformingAuthenticationOperation(false);
              if (
                res.data.user.is_admin === true &&
                res.data.user.roles[0].name === "group-admin"
              ) {
                history.push("/group-admin");
                window.location.reload();
              } else if (
                res.data.user.is_admin === true &&
                res.data.user.roles[0].name === "manager"
              ) {
                history.push("/manager");
                window.location.reload();
              } else if (res.data.user.is_admin === true) {
                history.push("/admin");

                window.location.reload();
              } else if (
                res.data.user.roles.length > 0 &&
                res.data.user.roles[0].name === "accountant"
              ) {
                history.push("/accountant");
                window.location.reload();
              } else if (res.data.user.roles.length === 0) {
                history.push("/");
              } else {
                history.push("/private-profile");
                window.location.reload();
              }
            }
          })
          .catch((error) => {
            console.log(error);
            authContext.setIsPerformingAuthenticationOperation(false);
            if (error?.data?.msg === "Account Suspended!") {
              setError(
                <div className="error_login">{t("error_msg_Suspended")}</div>
              );
            } else if (error?.data?.msg === "تم إرسال رابط التحقق") {
              setError(
                <div className="error_login">
                  {t("error_msg_link_confirmation")}
                </div>
              );
            } else if (
              error?.data?.msg === "The provided credentials are incorrect"
            ) {
              setError(<div className="error_login">{t("error_msg")}</div>);
            } else if (error?.data?.errNum === 500) {
              setError(
                <div className="error_login">{t("error_msg_server")}</div>
              );
            }
          });
      }
    }
    setDisabled(false);
  };

  const showHide = (e) => {
    e.preventDefault();
    setType(type === "input" ? "password" : "input");
  };

  let spinnerLoading = "";
  if (disabled === true) {
    spinnerLoading = <div className="lds-dual-ring"></div>;
  } else {
    spinnerLoading = "";
  }

  const showForgotPassword = () => {
    setForgotPassword(true);
    setError("");
  };

  const showLogin = () => {
    setForgotPassword(false);
  };

  const sendEmailForgotPassword = async () => {
    authContext.setIsPerformingAuthenticationOperation(true);
    let data = {
      email: email,
    };

    try {
      isAlternativeEmail
        ? await changeToAlternativeEmail(data)
        : await changePassword(data);
      toast.success(<span style={{ fontSize: 14 }}>{t("sentToEmail")}</span>);
    } catch (error) {
      console.log(error);
      toast.error(<span style={{ fontSize: 14 }}>{t("notSentToEmail")}</span>);
    } finally {
      authContext.setIsPerformingAuthenticationOperation(false);
    }
  };

  return (
    <>
      <div className="auth_forms_login">
        <div className="auth-home-login">
          <img src={Logo} alt="" className={"logo_qassem_login"} />
        </div>
        <p className="auth_label">
          {forgotPassword ? t("updatePassword") : t("welcome_back")}
        </p>
        <span className="small_note">{t("login")}</span>
        <form
          onSubmit={handleSubmit}
          className="auth_form_flex_col auth_form_form"
        >
          <div>
            <Input
              type="text"
              placeholder={
                isAlternativeEmail
                  ? alternativeEmail
                  : forgotPassword
                  ? t("Email")
                  : t("email_login")
              }
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              label={isAlternativeEmail ? t("alternative_email") : t("Email")}
              direction="ltr"
              // noteLogin={!forgotPassword && t("email_note")}
            />
          </div>
          {forgotPassword ? null : (
            <PasswordInput setContent={passwordHandleChange} />
          )}
          <div style={{ textAlign: "center", margin: "15px 0" }}>
            <button
              className="forgotPasswordBtn"
              onClick={() =>
                forgotPassword ? showLogin() : showForgotPassword()
              }
              type="button"
            >
              {forgotPassword ? t("loginBtn") : t("updatePasswordBtn")}
            </button>
          </div>
          <CustomButton
            value={
              forgotPassword
                ? t("sendEmailForgotPassword")
                : t("EnterMyAccount")
            }
            type={forgotPassword ? "button" : "submit"}
            variant="contained"
            action={forgotPassword ? () => sendEmailForgotPassword() : null}
            disable={!validateForm() || disabled}
            loading={disabled}
            colors="#025299"
            classes="auth_btn"
          />
          <div className="error_login">{error}</div>
          {state &&
          state.accountStatus &&
          state.accountStatus === "inactive" ? (
            <div className="error_login">{t("error_msg_Suspended")}</div>
          ) : null}
        </form>
        <div
          className="auth_form_flex_col"
          style={{ width: "100%", height: "100%" }}
        >
          <Social />
        </div>
      </div>
      <BasicModal ref={invalidEmailModal}>
        {showInvalidEmailModal && (
          <InvalidEmailModal
            img={warning}
            label={t("login_failed")}
            labelClassName="login-invalid-email-label"
            // subLabel={t("login_failed_msg")}
            descriptionOne={t("graduated_condition_msg_one")}
            descriptionTwo={t("graduated_condition_msg_two")}
            // descriptionThree={t("graduated_condition_msg_three")}
            descriptionClassName="login-invalid-email-description"
            successText={t("replace")}
            successColor="#036c77"
            action={() => {
              handleGetAlternativeEmail();
              handleCloseInvalidEmailModal();
            }}
            dismiss={handleCloseInvalidEmailModal}
            dismissText={t("cancel")}
            dismissColor="#9e9e9e"
          />
        )}
      </BasicModal>
    </>
  );
}

export default withRouter(Login);
