import React, { useEffect, useState } from "react";
import classes from "./Categories.module.css";
import CardCarousel from "../../Shared/Components/SlickCarousel/SlickCarousel";
import { getAllSpecialty } from "../../../Services/api/HomeCoursesProvider";
import CategoryCard from "../../Shared/Components/categoryCard/CategoryCard";
import { useTranslation } from "react-i18next";
export default function Categories() {
  const [specialtisList, setSpecialtisList] = useState([]);
  const { t } = useTranslation();
  const getSpecialties = () => {
    getAllSpecialty()
      .then((res) => {
        if (res.status && res.status === 200) {
          setSpecialtisList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSpecialties();
  }, []);
  return (
    <div style={{ padding: "0" }}>
      <div className={classes.CategoriesCont}>
        <p className={classes.catTitle}>{t("allCategories")}</p>
        <span>
          هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي
          القارئ
        </span>
        <div className={classes.cardsCont}>
          <CardCarousel>
            {specialtisList.map((specialty) => (
              <CategoryCard title={specialty.name} />
            ))}
          </CardCarousel>
        </div>
      </div>
    </div>
  );
}
