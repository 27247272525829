import DomainIcon from "@mui/icons-material/Domain";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SubjectIcon from "@mui/icons-material/Subject";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classes from "../search.module.css";
const SearchStatistics = () => {
  const { t, i18n } = useTranslation();

  const { freeSearchStatistics } = useSelector((state) => state.searchReducer);
  const statistics = [
    {
      label: t("survey.title"),
      value: freeSearchStatistics?.surveys || 0,
      color: "#046C77",
      icon: <LeaderboardIcon className="tw-h-full tw-w-full tw-fill-white" />,
    },
    {
      label: t("courses"),
      value: freeSearchStatistics?.courses || 0,
      color: "#046C77dd",
      icon: (
        <FormatAlignCenterIcon className="tw-h-full tw-w-full tw-fill-white" />
      ),
    },
    {
      label: t("general.programs"),
      value: freeSearchStatistics?.programs || 0,
      color: "#046C77cc",
      icon: <SubjectIcon className="tw-h-full tw-w-full tw-fill-white" />,
    },
  ];

  return (
    <div className={classes["statics_container"]}>
      {statistics.map((item) => (
        <div
          className="tw-flex tw-items-center tw-space-s-4 tw-p-4 tw-text-white tw-rounded"
          style={{ backgroundColor: item.color }}
        >
          <div className="tw-h-10 tw-w-10">{item.icon}</div>
          <div>
            <div className="tw-text-2xl">{item.value}</div>
            <div className="tw-text-sm">{item.label}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SearchStatistics;
