export const certificatesTemplateConstants = {
  // GET CERTIFICATES TEMPLATES LIST
  GET_CERTIFICATES_TEMPLATES_LIST_REQUEST: "GET_CERTIFICATES_TEMPLATES_LIST_REQUEST",
  GET_CERTIFICATES_TEMPLATES_LIST_SUCCESS: "GET_CERTIFICATES_TEMPLATES_LIST_SUCCESS",
  GET_CERTIFICATES_TEMPLATES_LIST_REJECTED: "GET_CERTIFICATES_TEMPLATES_LIST_REJECTED",

  // UPDATE CERTIFICATES TEMPLATES STATUS
  UPDATE_CERTIFICATES_TEMPLATES_STATUS_REQUEST: "UPDATE_CERTIFICATES_TEMPLATES_STATUS_REQUEST",
  UPDATE_CERTIFICATES_TEMPLATES_STATUS_SUCCESS: "UPDATE_CERTIFICATES_TEMPLATES_STATUS_SUCCESS",
  UPDATE_CERTIFICATES_TEMPLATES_STATUS_REJECTED: "UPDATE_CERTIFICATES_TEMPLATES_STATUS_REJECTED",

  // Delete Certificate Template
  DELETE_CERTIFICATES_TEMPLATE_REQUEST: "DELETE_CERTIFICATES_TEMPLATE_REQUEST",
  DELETE_CERTIFICATES_TEMPLATE_SUCCESS: "DELETE_CERTIFICATES_TEMPLATE_SUCCESS",
  DELETE_CERTIFICATES_TEMPLATE_REJECTED: "DELETE_CERTIFICATES_TEMPLATE_REJECTED",

  // ASSIGN CERTIFICATE Template
  ASSIGN_CERTIFICATES_TEMPLATE_REQUEST: "ASSIGN_CERTIFICATES_TEMPLATE_REQUEST",
  ASSIGN_CERTIFICATES_TEMPLATE_SUCCESS: "ASSIGN_CERTIFICATES_TEMPLATE_SUCCESS",
  ASSIGN_CERTIFICATES_TEMPLATE_REJECTED: "ASSIGN_CERTIFICATES_TEMPLATE_REJECTED",

}