import { useTheme } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, withRouter } from "react-router-dom";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import adminDoc from "../../../assets/image/admin-doc.png";
import dollar from "../../../assets/image/dollar.png";
import help from "../../../assets/image/help.png";
import not from "../../../assets/image/notification.png";
import settings from "../../../assets/image/settings.png";
import sup from "../../../assets/image/support.png";
import user from "../../../assets/image/user(2).png";
import Qassem_logo from "../../../assets/image/Qassem_logo.svg";
import GlobIcon from "../../../assets/icons/globIcon.svg";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import Sidebar from "../sidebar/sidebar";
// import { useTheme } from "styled-components";
import moment from "moment";
import toLocalTimezone from "../../../Services/Timezone";
import {
  getNotifications,
  readNotifications,
} from "../../../Services/api/Notifications/notificationsProvider";
import HeaderLogo from "../../../assets/image/Header.png";
import ar from "../../../assets/image/saudi-arabia.png";
import en from "../../../assets/image/united-kingdom.png";
import classes from "./adminHeader.module.css";
import UserDropDown from "../../Shared/Components/Header/DynamicHeaderWithoutLogin/UserDropDwon/UserDropDown";
import { useHistory } from "react-router-dom";
function AdminHeader(props) {
  require("./adminHeader.css");
  const { t } = useTranslation();
  const history = useHistory();
  document.body.dir = i18n.dir();
  const theme = useTheme();
  const type_login = localStorage.getItem("type_login");
  const authContext = useContext(AuthContext);
  const [sidebar, setSidebar] = useState(false);
  const [ListOfNotification, setListOfNotification] = useState([]);
  const [ReadNotification, setReadNotification] = useState(false);
  const [IconReadNotification, setIconReadNotification] = useState(false);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("type");
    localStorage.removeItem("name_ar");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("phone");
    localStorage.removeItem("completed_profile");
    localStorage.removeItem("group");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
  };

  const sidebarHandler = (event) => {
    event.preventDefault();
    setSidebar(!sidebar);
  };

  const [addAnimationSide, setAddAnimationSide] = useState(false);

  const onConfirm = () => {
    setAddAnimationSide(true);
    setTimeout(() => {
      setSidebar(false);
    }, 300);
  };

  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [sidebar]);

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };

  let buttonLang = <div onClick={() => changeLanguage("ar")}>AR</div>;
  if (document.body.dir === "rtl") {
    buttonLang = (
      <div className="lang_style" onClick={() => changeLanguage("en")}>
        <div className="lang_btn_qassem">
          <img src={GlobIcon} alt="" style={{ width: 20, height: 20 }} />
          <div className="">English</div>
          <img src={en} alt="" />
        </div>
      </div>
    );
  } else {
    buttonLang = (
      <div className="lang_style" onClick={() => changeLanguage("ar")}>
        <div className="lang_btn_qassem">
          <img src={ar} alt="" />
          <div className="">العربية</div>
          <img src={GlobIcon} alt="" style={{ width: 20, height: 20 }} />
        </div>
      </div>
    );
  }
  const _getNotifications = async () => {
    let res = await getNotifications();
    if (res.status === 201) {
      let notDataArr = res.data.response;

      if (Object.keys(notDataArr).length > 0 || notDataArr.length > 0) {
        setListOfNotification(notDataArr.data);
        setIconReadNotification(true);
      } else {
        setListOfNotification([]);
        setIconReadNotification(false);
      }
    }
  };

  useEffect(() => {
    let interval,
      role = localStorage.getItem("type");
    _getNotifications();
    interval = setInterval(() => {
      _getNotifications();
    }, 300000); // Call After 5min
    return () => {
      clearInterval(interval);
    };
  }, []);
  const userOptions = [
    ...(authContext.roles.includes("admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/admin"),
          },
        ]
      : authContext.roles.includes("accountant")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/accountant"),
          },
        ]
      : authContext.roles.includes("group-admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/group-admin"),
          },
        ]
      : authContext.roles.includes("manager")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/manager"),
          },
        ]
      : []),

    {
      label: t("selectGroups"),
      onClick: () => history.push("/my-groups"),
    },
    {
      label: t("account_info"),
      onClick: () => history.push("/private-profile"),
    },
    {
      label: t("edit_personal_profile"),
      onClick: () => history.push("/edit-profile"),
    },
    ...(type_login === "normal"
      ? [
          {
            label: t("accountde"),
            onClick: () => history.push("/update-password"),
          },
        ]
      : []),

    // {
    //   label: t("admin.label.list_tickets"),
    //   onClick: () =>
    //     history.push(
    //       `${
    //         localStorage.getItem("type") === "admin"
    //           ? "/admin/tickets"
    //           : "/tickets"
    //       }`
    //     ),
    // },
    {
      label: t("logout"),
      onClick: logout,
    },
  ];
  let notification_read = localStorage.getItem("ReadNotification");
  return (
    <div className={classes["header_shadow"]}>
      {sidebar === true ? (
        <>
          <Sidebar
            addAnimationSide={addAnimationSide}
            setAddAnimationSide={setAddAnimationSide}
            dismissSideBar={onConfirm}
            roles={props.roles}
          />
          <div className="backdrop_admin" onClick={onConfirm} />
        </>
      ) : null}

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <nav className={classes.adminNav}>
              <div className={classes.navbar_logo_admin}>
                <div
                  className={classes["hamburger-pointer"]}
                  onClick={sidebarHandler}
                >
                  <div className="hamburger-button" />
                  <div className="hamburger-button" />
                  <div className="hamburger-button" />
                </div>
                <NavLink exact to="/">
                  <img
                    src={Qassem_logo}
                    alt="..."
                    className={classes.image_logo_new}
                  />
                </NavLink>
                {/* <div className="line_logo"></div> */}
              </div>
              <div className={classes.flex_menu}>
                {buttonLang}
                <UserDropDown options={userOptions} />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AdminHeader);

//  <button
//               className="navbar-toggler"
//               type="button"
//               data-toggle="collapse"
//               data-target="#navbarSupportedContent"
//               aria-controls="navbarSupportedContent"
//               aria-expanded="false"
//               aria-label="Toggle navigation"
//             >
//               <span className="navbar-toggler-icon"></span>
//             </button>
//             <div
//               className="collapse navbar-collapse navbarSmallDeviceForAdmin"
//               id="navbarSupportedContent"
//             >
//               <div className="flex_menu">
//                 <div className="dropdown dropdown_admin">
//                   <button
//                     type="button"
//                     className=" dropdown-toggle header-dropdown header_nav_link dropdown_admin new_header_qassem"
//                     data-toggle="dropdown"
//                   >
//                     {localStorage.getItem("img") === "null" ? (
//                       <img src={adminDoc} alt="" className="admin_img" />
//                     ) : (
//                       <img
//                         src={localStorage.getItem("img")}
//                         alt="..."
//                         className="admin_img"
//                       />
//                     )}
//                     <span className="profile_name_qassem">
//                       {i18n.language === Lang.AR
//                         ? localStorage.getItem("name_ar") != null
//                           ? localStorage.getItem("name_ar").split(" ")[0]
//                           : null
//                         : localStorage.getItem("name_en") != null
//                         ? localStorage.getItem("name_en").split(" ")[0]
//                         : null}
//                     </span>
//                   </button>
//                   <div className="dropdown-menu">
//                     {/* todo: */}
//                     {authContext.roles.includes("admin") ? (
//                       <NavLink className="dropdown-item" to="/admin">
//                         {t("admin.label.dashboard")}
//                       </NavLink>
//                     ) : authContext.roles.includes("accountant") ? (
//                       <NavLink className="dropdown-item" to="/accountant">
//                         {t("admin.label.dashboard")}
//                       </NavLink>
//                     ) : authContext.roles.includes("group-admin") ? (
//                       <NavLink className="dropdown-item" to="/group-admin">
//                         {t("admin.label.dashboard")}
//                       </NavLink>
//                     ) : null}
//                     <NavLink className="dropdown-item" to="/edit-profile">
//                       {t("edit_personal_profile")}
//                     </NavLink>
//                     <NavLink className="dropdown-item" to="/my-groups">
//                       {t("selectGroups")}
//                     </NavLink>
//                     <NavLink className="dropdown-item" to="/private-profile">
//                       {t("account_info")}
//                     </NavLink>
//                     {type_login === "normal" ? (
//                       <NavLink
//                         className="dropdown-item"
//                         to="/update-password"
//                       >
//                         {t("accountde")}
//                       </NavLink>
//                     ) : null}
//                     <button
//                       className="dropdown-item"
//                       type="submit"
//                       onClick={logout}
//                     >
//                       {t("logout")}
//                     </button>
//                   </div>
//                 </div>
//                 {/* Notification */}
//                 <div className="dropdown">
//                   <button
//                     type="button"
//                     className="btn dropdown-toggle header-dropdown header_nav_link notification"
//                     data-toggle="dropdown"
//                     onClick={_getNotifications}
//                   >
//                     <i
//                       className="fa fa-bell"
//                       style={{ fontSize: "25px", color: "#333" }}
//                     ></i>
//                     {!ReadNotification ? (
//                       notification_read ===
//                       "true" ? null : IconReadNotification ? (
//                         <span className="notification-recently"></span>
//                       ) : null
//                     ) : null}
//                   </button>
//                   <div
//                     className="dropdown-menu notifications-box scroll-y"
//                     onMouseMove={() => {
//                       if (ListOfNotification.length > 0) {
//                         setReadNotification(true);
//                         console.log("notification_read", notification_read);
//                         let listIds = [];

//                         if (
//                           !notification_read ||
//                           notification_read != "true"
//                         ) {
//                           localStorage.setItem("ReadNotification", "true");
//                           ListOfNotification.map((list, key) => {
//                             listIds.push(list.id);
//                             if (ListOfNotification.length - 1 === key) {
//                               readNotifications(listIds);
//                               localStorage.setItem(
//                                 "ReadNotification",
//                                 "false"
//                               );
//                               localStorage.removeItem("ReadNotification");
//                               setReadNotification(false);
//                             }
//                           });
//                         }
//                       }
//                     }}
//                   >
//                     <h4> {t("notification")} </h4>
//                     {ListOfNotification.length === 0 ? (
//                       <div className="box-notify text-center">
//                         <p>{t("notNotification")}</p>
//                       </div>
//                     ) : null}
//                     {ListOfNotification.length != 0 &&
//                       ListOfNotification.map((notification, key) => {
//                         return (
//                           <div
//                             className={`box-notify ${
//                               !notification.is_read && !ReadNotification
//                                 ? notification_read === "true"
//                                   ? null
//                                   : " new"
//                                 : null
//                             }`}
//                             key={key}
//                           >
//                             <div className="type-time">
//                               <span>
//                                 {` ${notification.type} ${
//                                   (!notification.is_read &&
//                                     !ReadNotification) ||
//                                   notification_read != "true"
//                                     ? t("new_noti")
//                                     : ""
//                                 } `}
//                               </span>
//                               <span>
//                                 {moment(
//                                   toLocalTimezone(notification.created_at)
//                                 ).fromNow()}
//                               </span>
//                             </div>
//                             <p> {notification.msg} </p>
//                           </div>
//                         );
//                       })}
//                   </div>
//                 </div>
//                 {/* End */}
//                 {buttonLang}
//               </div>
//               <hr />
//               <ul className="navbar-nav mr-auto ">
//                 <li className="dropdown margin_en">
//                   <a
//                     href="#"
//                     className="dropdown-toggle"
//                     data-toggle="dropdown"
//                     role="button"
//                     aria-haspopup="true"
//                     aria-expanded="false"
//                   >
//                     <div className="">
//                       <img src={user} alt="" className="menu_icon" />
//                       {t("sidebar.label.user_management")}
//                     </div>
//                     <span className="caret"></span>
//                   </a>
//                   <ul className="dropdown-menu">
//                     <li>
//                       <NavLink to="/admin/roles">
//                         {t("sidebar.label.user_management")}
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="dropdown">
//                   <a
//                     href="#"
//                     className="dropdown-toggle"
//                     data-toggle="dropdown"
//                     role="button"
//                     aria-haspopup="true"
//                     aria-expanded="false"
//                   >
//                     <div className="">
//                       <img src={dollar} alt="" className="menu_icon" />
//                       {t("sidebar.label.financial_management")}
//                     </div>
//                     <span className="caret"></span>
//                   </a>
//                   <ul className="dropdown-menu">
//                     <li>
//                       <NavLink to="/admin/bank-transfers">
//                         {t("sidebar.label.bank_transfers")}
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>

//                 <li className="dropdown">
//                   <a
//                     href="#"
//                     className="dropdown-toggle"
//                     data-toggle="dropdown"
//                     role="button"
//                     aria-haspopup="true"
//                     aria-expanded="false"
//                   >
//                     <div className="">
//                       <img src={settings} alt="" className="menu_icon" />
//                       {t("sidebar.label.content_development")}
//                     </div>
//                     <span className="caret"></span>
//                   </a>
//                   <ul className="dropdown-menu">
//                     <li>
//                       <NavLink to="/admin/category">
//                         {t("sidebar.label.categories")}
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink to="/admin/department">
//                         {t("sidebar.label.department")}
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 <li className="dropdown">
//                   <a
//                     href="#"
//                     className="dropdown-toggle"
//                     data-toggle="dropdown"
//                     role="button"
//                     aria-haspopup="true"
//                     aria-expanded="false"
//                   >
//                     <div className="">
//                       <img src={help} alt="" className="menu_icon" />
//                       {t("sidebar.label.general_settings")}
//                     </div>
//                     <span className="caret"></span>
//                   </a>
//                   <ul className="dropdown-menu">
//                     <li>
//                       <NavLink to="/admin/about-us">
//                         {t("sidebar.label.about-us")}
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li>
//                 {/* <li className="dropdown">
//                   <a
//                     href="#"
//                     className="dropdown-toggle"
//                     data-toggle="dropdown"
//                     role="button"
//                     aria-haspopup="true"
//                     aria-expanded="false"
//                   >
//                     <div className="">
//                       <img src={help} alt="" className="menu_icon" />
//                       {t("sidebar.label.technical_support")}
//                     </div>
//                     <span className="caret"></span>
//                   </a>
//                   <ul className="dropdown-menu">
//                     <li>
//                       <NavLink to="">
//                         {t("sidebar.label.technical_support")}
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink to="">
//                         {t("sidebar.label.technical_support")}
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li> */}
//                 {/* <li className="dropdown">
//                   <a
//                     href="#"
//                     className="dropdown-toggle"
//                     data-toggle="dropdown"
//                     role="button"
//                     aria-haspopup="true"
//                     aria-expanded="false"
//                   >
//                     <div className="">
//                       <img src={computer} alt="" className="menu_icon" />
//                       {t("sidebar.label.virtual_galleries")}
//                     </div>
//                     <span className="caret"></span>
//                   </a>
//                   <ul className="dropdown-menu">
//                     <li>
//                       <NavLink to="">
//                         {t("sidebar.label.create_virtual_galleries")}
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink to="">{t("sidebar.label.add_file")}</NavLink>
//                     </li>
//                     <li>
//                       <NavLink to="">{t("sidebar.label.add_share")}</NavLink>
//                     </li>
//                     <li>
//                       <NavLink to="">
//                         {t("sidebar.label.add_prodect")}
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li> */}
//                 {/* <li className="dropdown">
//                   <a
//                     href="#"
//                     className="dropdown-toggle"
//                     data-toggle="dropdown"
//                     role="button"
//                     aria-haspopup="true"
//                     aria-expanded="false"
//                   >
//                     <div className="">
//                       <img src={help} alt="" className="menu_icon" />
//                       {t("sidebar.label.path_restriction")}
//                     </div>
//                     <span className="caret"></span>
//                   </a>
//                   <ul className="dropdown-menu">
//                     <li>
//                       <NavLink to="">
//                         {t("sidebar.label.path_restriction")}
//                       </NavLink>
//                     </li>
//                     <li>
//                       <NavLink to="">
//                         {t("sidebar.label.path_restriction")}
//                       </NavLink>
//                     </li>
//                   </ul>
//                 </li> */}
//               </ul>
//             </div>
