import React, {useEffect, useState} from "react";

export const AuthContext = React.createContext();

export function AuthProvider(props) {
  const [auth, setAuth] = useState({});
  const [isPerformingAuthenticationOperation, setIsPerformingAuthenticationOperation] = useState(false);
  const [inAdminPanel, setInAdminPanel] = useState(false);
  const [roles, setRoles] = useState(() => {
    let rolesString = localStorage.getItem("roles")

    if(rolesString){
      return JSON.parse(rolesString);
    }else{
      return []
    }
  })

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setAuth({
        token,
      });
    }

  }, []);



  return (
    <AuthContext.Provider value={{
      auth,
      setAuth,
      roles,
      setRoles,
      inAdminPanel,
      setInAdminPanel,
      isPerformingAuthenticationOperation,
      setIsPerformingAuthenticationOperation
    }}>
      { props.children }
    </AuthContext.Provider>
  );
}
