import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {useTranslation} from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart(props) {
  const { t } = useTranslation();

  let couponName = props.coupons
    .map((c) => {
      return c.name;
    })
  let couponCount = props.coupons
    .map((c) => {
      return c.used_count;
    })

  const data = {
    labels: [...couponName],
    datasets: [
      {
        label: "Coupons",
        data: [...couponCount],
        backgroundColor: [
          "rgba(255, 99, 132, 0.7)",
          "rgba(54, 162, 235, 0.7)",
          "rgba(255, 206, 86, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(255, 159, 64, 0.7)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div
        style={{
          margin: 10,
        }}
      >
        {t("dashBoard.label.coupons")}
      </div>
      {props.coupons.length == 0 ? (
        <h3
          style={{
            height: "430px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {t("dashBoard.label.noCoupons")}
        </h3>
      ) : (
        <div style={{width:500,height:500,marginLeft:'auto',marginRight:'auto'}}>
        <Doughnut
          data={data}
          height="878px !important"
          width="878px !important"
          style={{ padding: "20px" }}
          options={{
            plugins: {
                legend: {
                    display: false
                }
            }
          }}
        />
        </div>
      )}
    </>
  );
}
