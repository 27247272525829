import { Pagination } from "@mui/material";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchAllCoursesList,
  fetchCoursesList,
} from "../../../Redux/Actions/courses/coursesList.action";
import toLocalTimezone, { toServerTime } from "../../../Services/Timezone";
import { fetchCoursesListCloneModel } from "../../../Services/api/courses/cloneCourse";
import { cloneCourse } from "../../../Services/api/courses/courseProvider";
import deleteIcon from "../../../assets/icons/delete.svg";
import Checkbox from "../../Shared/Components/Checkbox/Checkbox";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import DataTable from "../../Shared/Components/DataTable/DataTable";
import Input from "../../Shared/Components/Input/Input";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainDatePicker from "../../Shared/Components/MainDatePicker/MainDatePicker";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import Title from "../../Shared/Components/Title/Title";
import classes from "./courses.module.css";

export default function CloneCourseModal({
  programId,
  closeModal,
  resetFilter,
  role,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allCourses } = useSelector((state) => state.coursesListReducer);
  const [startDateHours, setStartDateHours] = useState(null);
  const [startDateMinutes, setStartDateMinutes] = useState(null);
  const [endDateHours, setEndDateHours] = useState(null);
  const [endDateMinutes, setEndDateMinutes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [coursesList, setcoursesList] = useState([]);
  const [coursesListMeta, setcoursesListMeta] = useState([]);
  const [coursesListLoading, setcoursesListLoading] = useState(false);

  const getCoursesListHandler = () => {
    setcoursesListLoading(true);
    fetchCoursesListCloneModel({
      page: currentPage,
      perPage: 10,
      role,
    })
      .then((res) => {
        // console.log(res);
        if (res.status && res.status == 200 && res.data.status) {
          setcoursesList(res.data.data.courses.data);
          setcoursesListMeta(res.data.data.courses.meta);
          setcoursesListLoading(false);
        }
      })
      .catch((err) => {
        setcoursesListLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getCoursesListHandler();
  }, [currentPage]);
  useEffect(() => {
    currentDate();
    // dispatch(fetchAllCoursesList());
    setLoading(false);
  }, []);

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "startDate") {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endDate") {
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    }
  };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours === "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  const coursesFields = [
    {
      id: "order",
      column: ({ rowData, rowIndex }) => {
        return <>{rowIndex + 1}</>;
      },
    },
    {
      id: "id",
      column: ({ rowData }) => <>{rowData.id}</>,
    },
    {
      id: "name",
      column: ({ rowData }) => <>{rowData.name || "-"}</>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <>{rowData.run_serial_number || "-"}</>,
    },
    {
      id: "code",
      column: ({ rowData }) => <>{rowData.code || "-"}</>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => (
        <>{rowData.start_date ? toLocalTimezone(rowData.start_date) : "-"}</>
      ),
    },
  ];

  return (
    <MainBox className={classes["clone-course-modal"]}>
      {loading ? (
        <SkeletonCardOverlay skeletonWidth="100" />
      ) : (
        <>
          <div className={classes["clone-course-modal__header"]}>
            <Title title={t("clone_course")} />
            <img src={deleteIcon} alt="close" onClick={closeModal} />
          </div>
          <Formik
            initialValues={{
              clone_course_id: "",
              name: "",
              mode: "Paid",
              price: "",
              start_date: setHours(
                setMinutes(new Date(), startDateMinutes),
                startDateHours
              ),
              end_date: setHours(
                setMinutes(new Date(), endDateMinutes),
                endDateHours
              ),
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              const dataToSend = {
                name: values.name,
                price: values.price,
                start_date: toServerTime(
                  formatDate(values.start_date) +
                    " " +
                    formatTime(values.start_date)
                ),
                end_date: toServerTime(
                  formatDate(values.end_date) +
                    " " +
                    formatTime(values.end_date)
                ),
                program_id: programId,
                mode: values.mode,
              };

              if (values.mode === "Paid" && !programId) {
                dataToSend.price = values.price;
              }

              if (programId) {
                delete dataToSend.mode;
              } else {
                // dataToSend.is_vat_exempted = values.is_vat_exempted;
              }

              cloneCourse(values.clone_course_id, dataToSend)
                .then(async (response) => {
                  if (response.status === 200 && response.data.status) {
                    toast.success(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {response.data.msg ? response.data.msg : "success"}
                      </span>,
                      {
                        autoClose: 1000,
                      }
                    );
                    closeModal();
                    resetFilter();
                  } else {
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {response.data.msg
                          ? response.data.msg
                          : "Failure in service"}
                      </span>
                    );
                    setSubmitting(false);
                  }
                })
                .catch((err) => {
                  toast.error(
                    <>
                      <span
                        style={{
                          fontSize: 13,
                          fontWeight: "bold",
                          display: "block",
                          paddingBottom: "10px",
                        }}
                      >
                        {err.response.data.msg}
                      </span>
                      <ul>
                        {Object.keys(err.response.data.errors).map((key) => {
                          return (
                            <li>
                              <span style={{ fontSize: 11 }}>
                                {err.response.data.errors[key]}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  );
                  setSubmitting(false);
                });
            }}
            validateOnChange={true}
            validate={(values) => {
              const errors = {};
              console.log("values :>> ", values);
              if (!values.clone_course_id) {
                errors.clone_course_id = t("crud.errors.required");
              }
              if (!values.name) {
                errors.name = t("crud.errors.required");
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              handleBlur,
              setFieldValue,
              validateForm,
            }) => (
              <form onSubmit={handleSubmit} className={classes["course__form"]}>
                <div
                  className={`row ${classes["clone-course-modal__form__fields"]}`}
                >
                  <div
                    className={`${classes["clone-course-modal__form__field"]} col-12`}
                  >
                    <DataTable
                      isCourseClone={true}
                      label={t("trainer.program.clone_course")}
                      selectedItems={values.clone_course_id}
                      isLoading={coursesListLoading}
                      data={coursesList}
                      fields={coursesFields}
                      error={errors.clone_course_id}
                      searchableFields={[
                        "id",
                        "name",
                        "run_serial_number",
                        "code",
                        "start_date",
                      ]}
                      selectedHandler={(value) => {
                        setFieldValue("clone_course_id", value.id);
                      }}
                      required
                      disabled={isSubmitting}
                    />
                    <div style={{ marginTop: 10 }}>
                      <Pagination
                        count={coursesListMeta?.totalPages || 0}
                        showFirstButton
                        showLastButton
                        size="small"
                        variant="outlined"
                        shape="rounded"
                        className="main-pagination"
                        onChange={(e, page) => {
                          setCurrentPage(page);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`${classes["clone-course-modal__form__field"]} col-12`}
                  >
                    <Input
                      label={t("trainer.course.name")}
                      type="text"
                      name="name"
                      placeholder={t(`trainer.course.name`)}
                      onChange={handleChange}
                      error={errors.name}
                      value={values.name}
                      required
                      disabled={isSubmitting}
                    />
                  </div>
                  <div
                    className={`${classes["clone-course-modal__form__field"]} col-12 col-md-6`}
                  >
                    <MainDatePicker
                      label={t(`trainer.course.start_date`)}
                      name="start_date"
                      error={errors.start_date}
                      value={values.start_date}
                      onChange={(date) => {
                        setFieldValue("start_date", date);
                        values.end_date < date &&
                          setFieldValue("end_date", date);
                        let CurrentDate = new Date();
                        let CurrentDateFormate =
                          CurrentDate.toISOString().split("T")[0];
                        let DataPickerValue = date.toISOString().split("T")[0];
                        if (
                          new Date(DataPickerValue).getTime() >
                          new Date(CurrentDateFormate).getTime()
                        ) {
                          setStartDateHours(0);
                          setStartDateMinutes(0);
                        } else {
                          currentDate("startDate");
                        }
                      }}
                      minDate={new Date()}
                      minTime={setHours(
                        setMinutes(new Date(), startDateMinutes),
                        startDateHours
                      )}
                      maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      required
                      readOnly={isSubmitting}
                    />
                  </div>
                  <div
                    className={`${classes["clone-course-modal__form__field"]} col-12 col-md-6`}
                  >
                    <MainDatePicker
                      label={t(`trainer.course.end_date`)}
                      name="end_date"
                      error={errors.end_date}
                      value={values.end_date}
                      onChange={(date) => {
                        setFieldValue("end_date", date);

                        let CurrentDate = new Date();
                        let CurrentDateFormate =
                          CurrentDate.toISOString().split("T")[0];
                        let DataPickerValue = date.toISOString().split("T")[0];
                        if (
                          new Date(DataPickerValue).getTime() >
                          new Date(CurrentDateFormate).getTime()
                        ) {
                          setStartDateHours(0);
                          setStartDateMinutes(0);
                        } else {
                          currentDate("endDate");
                        }
                      }}
                      minDate={new Date(values.start_date)}
                      minTime={setHours(
                        setMinutes(new Date(), startDateMinutes),
                        startDateHours
                      )}
                      maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      required
                      readOnly={isSubmitting}
                    />
                  </div>
                  {!programId && (
                    <div
                      className={`${classes["clone-course-modal__form__field"]} col-12 col-md-6`}
                    >
                      <Checkbox
                        name="mode"
                        label={t(`trainer.course.is_free`)}
                        onChange={(event) => {
                          setFieldValue(
                            "mode",
                            event.target.checked ? "Free" : "Paid"
                          );
                          setFieldValue(
                            "price",
                            event.target.checked ? "0.0" : ""
                          );
                        }}
                        checked={values.mode === "Free"}
                        error={errors.mode}
                      />
                    </div>
                  )}
                  {!programId && (
                    <div
                      className={`${classes["clone-course-modal__form__field"]} col-12`}
                    >
                      <Input
                        label={t(`trainer.course.price`)}
                        type="number"
                        name="price"
                        placeholder={t(`trainer.course.price`)}
                        onChange={handleChange}
                        error={errors.price}
                        value={values.price}
                        readonly={values.mode !== "Paid"}
                        required={values.mode === "Paid"}
                      />
                    </div>
                  )}

                  <div
                    className={`${classes["clone-course-modal__form__submit__container"]} col-12`}
                  >
                    <CustomButton
                      type="submit"
                      colors="#036c77"
                      value={t("trainer.course.add_course_button")}
                      classes={
                        classes["clone-course-modal__form__submit__button"]
                      }
                      disable={isSubmitting}
                      loading={isSubmitting}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </MainBox>
  );
}
