import connector from "./Handler";



export function exportApi(type, path, params, name = "export") {
  let extension = "";
  type === "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(path, {
          responseType: "blob",
          params: {
            ...params,
            export: extension,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute("download", name + "." + "pdf");
            } else {
              link.setAttribute("download", name + "." + "xlsx");
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getUser() {
    try {
    //   const response = await 
    return connector.get('https://jsonplaceholder.typicode.com/todos', {
        params: {
        }
      });

    //   return response;

    } catch (error) {
      // console.error('error');
    }
  }


  

//   import { getUser } from './Services/api/Essential'


// getUser().then ( res => {
//   // console.log(res)

// }).catch ( error => {

//   console.error('error');


// })