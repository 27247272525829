export const publishReqsListConstants = {
  // GET ALL   LIST
  GET_PUBLISHREQS_LIST_REQUEST: "GET_PUBLISHREQS_LIST_REQUEST",
  GET_PUBLISHREQS_LIST_SUCCESS: "GET_PUBLISHREQS_LIST_SUCCESS",
  GET_PUBLISHREQS_LIST_REJECTED: "GETPUBLISHREQS_LIST_REJECTED",

  // ACTIVE
  ACCEPT_PUBLISHREQ_REQUEST: "ACCEPT_PUBLISHREQ_REQUEST",
  ACCEPT_PUBLISHREQ_SUCCESS: "ACCEPT_PUBLISHREQ_SUCCESS",
  ACCEPT_PUBLISHREQ_REJECTED: "ACCEPT_PUBLISHREQ_REJECTED",
  // DEACTIVE
  REJECT_PUBLISHREQ_REQUEST: "REJECT_PUBLISHREQ_REQUEST",
  REJECT_PUBLISHREQ_SUCCESS: "REJECT_PUBLISHREQ_SUCCESS",
  REJECT_PUBLISHREQ_REJECTED: "REJECT_PUBLISHREQ_REJECTED",
  // DELETE
  DELETE_PUBLISHREQ_REQUEST: "DELETE_PUBLISHREQ_REQUEST",
  DELETE_PUBLISHREQ_SUCCESS: "DELETE_PUBLISHREQ_SUCCESS",
  DELETE_PUBLISHREQ_REJECTED: "DELETE_PUBLISHREQ_REJECTED",
};
