import { Checkbox, Input, InputLabel, MenuItem, Radio, Select, Option, Slider, Stepper, Step, StepLabel, Button, StepContent } from "@material-ui/core";
import React, {useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Rating } from "@mui/material";


export const SliderQuestion=(props)=>{
  // require('./style.css');

  const optionCreationBoxRef = useRef();
  const optionLabelCreationBoxRef = useRef();

  const [item, setItem] = useState("");
  const [optionValue, setOptionValue] = useState("");
  const [optionLabel, setOptionLabel] = useState("");
  const [newOption, setNewOption] = useState("");
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);

  const sortOptions = (unsotredOptions) => {
    var sortedOptions = [...unsotredOptions];
    sortedOptions.sort(function (a, b) {
      return a.value - b.value;
    }); 
    return sortedOptions;
  }

  useEffect(() => {
    if (props.question) { 
      // sort the options
      if (props.question.options) {
        setOptions(sortOptions(props.question.options));
        setAnswers(props.question.answers);
      }
      // iniate itmes
      if (props.question.items) {
        setItems(props.question.items);
      }
    }
  }, [props.question]);

  // update the question options
  useEffect(() => {
    if (props.mode == "edit") {
        props.saveQuestionData({options, items});
    }
  }, [options, items]);

  // compute min & max
  useEffect(() => {
    computeMinMax(options);
  }, [options]);


  // initiate answers
  useEffect(() => {
    if (props.mode != "edit") {
        // props.saveQuestionData({options});
    }
  }, [answers]);
  // To prepare the max and min values for the slider component
  const computeMinMax = () => {
    var min, max = 0;
    // var max = options.length ? options[0] : 0;
    if (options.length) {
      min = options[0].value;
      max = options[options.length -1].value;
    }
    setMin(min);
    setMax(max);
    
    console.log(`Min: ${min} - Max: ${max}`);
  }

  const addOption = (e, index) => {
    if (e.keyCode === 13) {
      const field = e.target.name;
      const value = e.target.value;
        saveOption(field, value, index)
    }
  }

  const addItem = (e) => {
    if (e.keyCode === 13) {
        saveItem(e.target.value);
    }
  }

  const removeOptions = (index) => {
    var filteredOptions = options.filter((option, i) => i != index);
    setOptions(filteredOptions);
  }

  const saveOption = () => {
    var label = optionLabel != ""? optionLabel : optionValue;
    var value = parseInt(optionValue);
    // validate the option's value 
    if (isNaN(value)) {
      alert('Wrong value passed as option value');
      return;
    }
    if (optionValue == "") {
      alert("Option value cannot be empty string");
      return;
    }
    var option = {value, label}
    var sortedOptions = sortOptions([...options, option]);
    // console.log(sortedOptions);
    setOptions(sortedOptions);
    setOptionLabel("");
    setOptionValue("");
    optionCreationBoxRef.current.value = "";
    optionLabelCreationBoxRef.current.value = "";
    optionCreationBoxRef.current.focus();
  }

  const updateLabel = (index, label) => {
    var option = options[index];
    option.label = label;
    setOptions(options);
    console.log(options);
  }

  const updateValue = (index, value) => {
    var option = options[index];
    option.value = value;
    setOptions(options);
    console.log(options);
  }

  const updateOption = (index) => {
    saveOption(index);
  }

  const isSelected = (option) => {
    // console.log(`${item} - ${option} - ${item_option}`);
    let answer = answers.filter(answer => answer == option);
    // console.log(answer);
    return answer.length? true : false;
  }

  const setAnswer = (option) => {
    var found = false;
    var answerIndex = -1;
    var answerIndexs = [];
    var currentAnswers = [...answers];

    currentAnswers.map((answer, index) => {
      if (option == answer) {
        // set related item options
        answerIndexs.push(index);
        found = true;
        answerIndex = index;
      }
    });
    // decide to add the option or remove it
    if (! found) { // add the option
      //clear other options in case it was a radio button
      if (props.selectionType == 'select_one') {
        currentAnswers = [];
      }
      // add the selected option to the answers list
      currentAnswers.push(option);
    } else { // remove the option
      currentAnswers.splice(answerIndex, 1)
    }
    setAnswers(currentAnswers);
    console.log(currentAnswers);
  }

  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const saveItem = (value) => {
    value = value? value : item;
    console.log(value);
    if (!isValidItem(value)) return;
    // add the item
    setItems([...items, value]);
  }

  const updateItem = (index, e) => {
    var value = e.target.value;
    if (!isValidItem(value)) return;
    // update the item
    var updateItems = [...items];
    updateItems[index] = value;
    setItems(updateItems);
  }
  // check item validity
  const isValidItem = (item) => {
    if (item == "") {
      alert("Item cannot be empty string");
      return false;
    }
    return true;
  }

  return(<>
    {/* <div>
      <Checkbox onChange={(e) => setIsPreview(e.target.checked)}/>
    </div> */}
    <div style={{display: 'block'}}>
    {
        props.mode == 'edit' && ! isPreview?
          <div>
            <Stepper activeStep={activeStep}>
              <Step label={'Options'} completed={false}>
                <StepLabel>Options</StepLabel>
              </Step> 
              <Step label="Items" completed={false}>
                <StepLabel>Items</StepLabel>
              </Step>
            </Stepper>
            {/* First Step Content */}
            <div style={{display: activeStep == 0? "" : "none"}}>
              {options.map((option, index) => {
                return <div key={`singile-choice-option-${index}-${option.value}`}>
                  <input 
                      onChange={(e) => updateValue(index, e.target.value)} 
                      // onKeyDown={(e) => addOption(e, index)} 
                      defaultValue={option.value}
                      placeholder="Please enter option's value"
                      name="value"
                  />
                  <input 
                      onChange={(e) => updateLabel(index, e.target.value)} 
                      // onKeyDown={(e) => addOption(e, index)} 
                      defaultValue={option.label}
                      placeholder="Please enter option's label"
                      name="label"
                  /> 
                    <button onClick={() => removeOptions(index)}>Delete</button>
                </div>
              })}
              {/* Add new */}
              <div>
                <input 
                    onChange={(e) => setOptionValue(e.target.value)} 
                    onKeyDown={(e) => addOption(e)}
                    ref={optionCreationBoxRef}
                    name="value"
                />
                <input 
                  onChange={(e) => setOptionLabel(e.target.value)} 
                  onKeyDown={(e) => addOption(e)}
                  ref={optionLabelCreationBoxRef}
                  name="label"
                />
                <button onClick={() => saveOption()}>Save</button>
              </div>
            </div>
            {/* Second Step Content */}
            <div style={{display: activeStep == 1? "" : "none"}}>
              {
                items.map((item, index) => {
                  return <div key={`slider-item-${index}-${item}`}>
                    <input defaultValue={item} onChange={(e) => updateItem(index, e)}/>
                  </div>
                })
              }
              <input onKeyUp={(e) => addItem(e)} onChange={(e) => setItem(e.target.value)}/><button onClick={() => saveItem()}>Add</button>
            </div>
          </div>
          : 
          <div>
            {/* Slider */}
            {['text_slider', 'numeric_slider'].includes(props.selectionType)?
              <div style={{width: '50%'}}>
                <Slider 
                  marks={options} 
                  min={min} 
                  max={max}
                  aria-label="Custom marks"
                  step={null}
                  // valueLabelDisplay="auto"
                  // getAriaValueText={valuetext}
                />
              </div> : null
            }
            {/* Multiple Sliders */}
            {['constant_sum'].includes(props.selectionType)?
              <>
                {items.map((item, index) => {
                  return <div style={{width: '50%'}}>
                    <span>{item}</span>
                    <Slider 
                      marks={options} 
                      min={min} 
                      max={max}
                      aria-label="Custom marks"
                      step={null}
                      // valueLabelDisplay="auto"
                      // getAriaValueText={valuetext}
                    />
                </div>
                })}
              </> :null
            }
          </div>
    }
    
      <Button
        color="inherit"
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: 1 }}
      >
        Back
      </Button>
      <Button onClick={handleNext}>
        Next
      </Button>
    </div>
  </>)
}