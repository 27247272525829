import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { fetchRefundsList } from "../../../../Redux/Actions/refundSetting/refundSetting.action";
import HomeIcon from "../../../../assets/icons/home.svg";

import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./refundList.module.css";

import { getAllCourses } from "../../../../Services/api/CoursesList";
import { exportRefundList } from "../../../../Services/api/RefundList";
import RefundSettingFilter from "./RefundSettingFilter";
import { filterActionsrRefund } from "./RefundSettingListActions";
import RefundTable from "./RefundTable";

function RefundSettingList(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [filterData, setFilterData] = useState({
    refund_title: "",
    mechanism: "",
    created_by: "",
    parent_type: "",
    course: "",
    program: "",
    status: "",
    start_date: "",
    end_date: "",
  });

  const { refundsListMeta } = useSelector((state) => state.refundsListReducer);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("admin.label.admin_label"),
      pagePath: "#",
    },
    {
      id: `refund`,
      page: t("financial.refund.refunds"),
      pagePath: `/${
        localStorage.getItem("type") === "accountant"
          ? "accountant"
          : localStorage.getItem("type") == "group-admin"
          ? "group-admin"
          : "admin"
      }/refund`,
    },
    {
      id: `content_development_refund`,
      page: t("financial.label.refundSetting"),
      pagePath: "#",
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      refund_title: "",
      mechanism: "",
      created_by: "",
      parent_type: "",
      course: "",
      program: "",
      status: "",
      start_date: "",
      end_date: "",
    });
    setIsResetApplied(true);
  };

  const getRefundListHandler = () => {
    const filter = getFilterData();

    dispatch(
      fetchRefundsList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
  };

  const getFilterData = () => {
    const filter = {};
    filterData.refund_title && (filter.refund_title = filterData.refund_title);
    filterData.mechanism && (filter.mechanism = filterData.mechanism);
    filterData.created_by &&
      (filterData.created_by == 1
        ? (filter.by_admin = 1)
        : (filter.by_accountant = 1));
    filterData.parent_type && (filter.parent_type = filterData.parent_type);
    filterData.status && (filter.status = filterData.status);
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);
    (filterData.course || filterData.program) &&
      (filterData.parent_type == 1
        ? (filter.parent_id = filterData.course)
        : (filter.parent_id = filterData.program));

    return filter;
  };

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { not_in_program: 1 } : {}),
      mode: "Paid",
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      })
      .finally(() => {
        setIsCoursesLoading(false);
        setIsProgramsLoading(false);
      });
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      await exportRefundList(filter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  useEffect(() => {
    getAllCoursesProgramsHandler({
      is_program: false,
    });
    getAllCoursesProgramsHandler({
      is_program: true,
    });
  }, []);
  useEffect(() => {
    getRefundListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  return (
    <div className={classes["refund-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["refund-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("financial.label.refundSetting")}
                  actions={filterActionsrRefund({
                    showFilter: showFilterHandler,
                    addAction: () => {
                      history.push(
                        `${
                          localStorage.getItem("type") == "accountant"
                            ? "/accountant"
                            : localStorage.getItem("type") == "group-admin"
                            ? "/group-admin"
                            : "/admin"
                        }/refund-setting/add`
                      );
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <RefundSettingFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getRefundListHandler}
                    resetFilterHandler={resetFilterHandler}
                    courses={courses}
                    programs={programs}
                  />
                )}

                <RefundTable />
                <Pagination
                  count={refundsListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundSettingList;
