import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FilterBox from "../../../../Shared/Components/FilterBox/FilterBox";

const WalletDetailsListFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
}) => {
  const { t, i18n } = useTranslation();
  const { userWalletFilter } = useSelector((state) => state.userWalletReducer);

  function redesignObject(inputObject) {
    const redesignedObject = {};

    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        const array = inputObject[key];
        redesignedObject[key] = array.map((item) => {
          const newItem = { label: item.name || item.text };
          if (item.hasOwnProperty("name")) {
            newItem.value = item.name;
          } else {
            newItem.value = item.value;
          }
          return newItem;
        });
      }
    }

    return redesignedObject;
  }

  const filterFields = [
    {
      id: "payable_type",
      label: t("payments.payable_type"),
      component: "select",
      placeholder: t("payments.payable_type"),
      initialValue: t("payments.payable_type"),
      name: "payable_type",
      value: filterData.payable_type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: [
        {
          id: "course",
          value: "course",
          label: t("trainer.course.label"),
        },
        {
          id: "program_payment",
          value: "program_payment",
          label: t("trainer.program.label"),
        },
        {
          id: "booth",
          value: "booth",
          label: t("booth.label"),
        },
        {
          id: "exhibition_visit",
          value: "exhibition_visit",
          label: t("Exhibition.label"),
        },
      ],
    },
    ...(filterData.payable_type === "course"
      ? [
          {
            id: "payable_name",
            label: t("coupon.label.select_course"),
            component: "select",
            placeholder: t("coupon.label.select_course"),
            initialValue: t("coupon.label.select_course"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: redesignObject(userWalletFilter)?.courses,
          },
          {
            id: "payable_code",
            component: "input",
            label: t("financial.payments_request.course_code"),
            type: "text",
            placeholder: t("financial.payments_request.course_code"),
            name: "payable_code",
            value: filterData.payable_code,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "payable_serial_number",
            component: "input",
            label: t("financial.payments_request.course_run_serial_number"),
            type: "text",
            placeholder: t("financial.payments_request.course_run_serial_number"),
            name: "payable_serial_number",
            value: filterData.payable_serial_number,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),
    ...(filterData.payable_type === "program_payment"
      ? [
          {
            id: "payable_name",
            label: t("coupon.label.select_program"),
            component: "select",
            placeholder: t("coupon.label.select_program"),
            initialValue: t("coupon.label.select_program"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: redesignObject(userWalletFilter)?.programs,
          },
          {
            id: "payable_code",
            component: "input",
            label: t("financial.payments_request.program_code"),
            type: "text",
            placeholder: t("financial.payments_request.program_code"),
            name: "payable_code",
            value: filterData.payable_code,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "payable_serial_number",
            component: "input",
            label: t("financial.payments_request.program_run_serial_number"),
            type: "text",
            placeholder: t("financial.payments_request.program_run_serial_number"),
            name: "payable_serial_number",
            value: filterData.payable_serial_number,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),
    ...(filterData.payable_type === "exhibition_visit"
      ? [
          {
            id: "payable_name",
            label: t("coupon.label.select_exhibition"),
            component: "select",
            placeholder: t("coupon.label.select_exhibition"),
            initialValue: t("coupon.label.select_exhibition"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: redesignObject(userWalletFilter)?.exhibitions,
          },
        ]
      : []),
    ...(filterData.payable_type !== "exhibition_visit" &&
    filterData.payable_type !== "program_payment" &&
    filterData.payable_type !== "course"
      ? [
          {
            id: "payable_name",
            component: "input",
            label: t("payments.payable_name"),
            type: "text",
            placeholder: t("payments.payable_name"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-3 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-3",
      action: () => {
        if (currentPage !== 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];
  return <FilterBox fields={filterFields} />;
};

export default WalletDetailsListFilter;
