import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import {  useSelector } from "react-redux";
import { toast } from "react-toastify";
import EvaluationApi from "../../../../Services/api/evaluation";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import { Lang } from "../../../../utils";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import EvaluationPopUp from "./EvaluationPopUp";
import classes from "./systemUsersList.module.css";

const EvaluationListTable = ({ sortAction, rows, setTableData, isExportLoading, courseId, role }) => {
  const [addCriteria, setAddCriteria] = useState(false);
  const [id, setId] = useState(null);
  const handleCloseAddCriteria = () => {
    setAddCriteria(false);
  };
  const handleOpenAddCriteria = () => {
    setAddCriteria(true);
  };
  const handleDeleteCriteria = async (id) => {
    try {
      await EvaluationApi.destroy(id);
      setTableData((prev) => {
        return { ...prev, data: prev.data.filter((item) => item.id !== id) };
      });
      toast.success(<span style={{ fontSize: 14 }}>{t("criterionSuccessfullyDeleted")}</span>);
    } catch {}
  };

  // const { systemUsersList, systemUsersListLoading } = useSelector(
  // 	(state) => state.systemUsersListReducer
  // );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const updateEvaluationStatusHandler = async (id, rowData) => {
    try {
      await EvaluationApi.changeStatus(id);
      setTableData((prev) => {
        const newArr = prev.data.map((item) => {
          if (item.id === id) {
            return { ...item, status: item.status == 1 ? "0" : "1" };
          } else {
            return item;
          }
        });

        return {
          ...prev,
          data: newArr,
        };
      });
    } catch (error) {
      // console.log(error.response.data.msg);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.response.data.msg}</span>);
    }
  };

  const evaluationActionsList = [
    {
      id: "edit",
      icon: <img src={editIcon} alt="" />,
      action: (id) => {
        setId(id);
        handleOpenAddCriteria();
      },
      tooltip: t("system_users.edit"),
    },
    {
      id: "delete",
      icon: <img src={DeleteIcon} alt="" />,
      action: (id) => {
        setId(id);
        handleDeleteCriteria(id);
      },
      tooltip: t("system_users.delete"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => (
        <div
          style={{
            minWidth: "50px",
          }}
        >
          {rowData?.id}
        </div>
      ),
    },

    {
      id: "name",
      name: (
        <div
          style={{
            textAlign: "start",
          }}
        >
          {t("answerCriteria.addNewCriteriaTitle")}
        </div>
      ),
      key: i18n.language === Lang.AR ? "title_ar " : "title_en",
      cell: ({ rowData }) => (
        <div
          className={classes["system-users-list__table-name"]}
          style={{
            maxWidth: "12rem",
            textAlign: "start",
          }}
          // onClick={() => {
          // 	console.log(rowData);
          // }}
        >
          {rowData?.title || "-"}
        </div>
      ),
      sortable: false,
      align: "start",
    },
    {
      id: "weight",
      name: t("criteriaWeight"),
      key: "weight",
      cell: ({ rowData }) => <div className={classes["system-users-list__table-name"]}>{rowData?.weight || "-"}</div>,
      sortable: false,
    },

    {
      id: "register_date",
      name: t("dateAdded"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: false,
    },
    ...(role !== "partner"
      ? [
          {
            id: "actions",
            name: t("system_users.actions"),
            key: "actions",
            cell: ({ rowData }) => (
              <div
                className={classes["system-users-list__table__actions"]}
                style={{
                  justifyContent: "center",
                  minWidth: "110px",
                }}
              >
                {evaluationActionsList.map((action, index) => {
                  return (
                    <button
                      key={action.id}
                      className={classes["system-users-list__table__actions-btn"]}
                      title={action.tooltip}
                      onClick={() => {
                        if (action.id === "edit" && new Date(rowData.end_time) <= Date.now()) {
                          return;
                        }
                        action.action(rowData.id);
                      }}
                      disabled={action.id === "edit" && new Date(rowData.end_time) <= Date.now()}
                    >
                      {action.icon}
                    </button>
                  );
                })}
              </div>
            ),
            sortable: false,
            align: "center",
          },
        ]
      : []),
    {
      id: "status",
      name: t("system_users.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status == 1}
          id={rowData.id}
          onStatusChange={() => {
            updateEvaluationStatusHandler(rowData.id, rowData);
          }}
          disabled={role === "partner"}
        />
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <MainTable columns={columns} data={rows || []} sortAction={sortAction} isLoading={isExportLoading} />
      {addCriteria && (
        <EvaluationPopUp
          id={id}
          courseId={courseId}
          setTableData={setTableData}
          addCriteria={addCriteria}
          handleCloseAddCriteria={handleCloseAddCriteria}
        />
      )}
    </>
  );
};

export default EvaluationListTable;
