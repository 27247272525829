import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import HeaderLogo from "../../../../../assets/image/Header.png";
import Qassem_logo from "../../../../../assets/image/Qassem_logo.svg";

// mui icons
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DescriptionIcon from "@mui/icons-material/Description";
import FormatAlignCenterOutlinedIcon from "@mui/icons-material/FormatAlignCenterOutlined";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import WalletIcon from "@mui/icons-material/Wallet";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import i18n from "../../../../../i18n/i18n";
import { Lang } from "../../../../../utils";
import Menu from "../../../../../assets/icons/Menu1.svg";

const routes = {
  profile: "/edit-profile",
  home: "/",
  AboutUs: "/about-us",
  courses: "/training-courses",
  program: "/training-programs",
  ProfessionalTrainers: "/trainers",
  generalSurveys: "/general-surveys",
  support_tickets: "/tickets",
  TermsAndConditions: "/terms-and-conditions",
  ConnectUs: "/contactUs",
  live_chat: "/livechat",
};

function MainDrawer({
  dismissSideBar,
  setAddAnimationSide,
  addAnimationSide,
  roles,
}) {
  require("./sidebar.css");
  const history = useHistory();
  const { t } = useTranslation();
  let is_logged_in = localStorage.getItem("token") === null ? false : true;

  const checkRolesIncludes = (role) => {
    return roles.includes(role);
  };
  const sideBarItems = [
    {
      title: t("Main"),
      itemId: routes["home"],
      //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
    },
    {
      title: t("AboutUs"),
      itemId: routes["AboutUs"],
      //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
    },
    {
      title: t("courses"),
      itemId: routes["courses"],
      //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
    },
    {
      title: t("program"),
      itemId: routes["program"],
      //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
    },
    {
      title: t("ProfessionalTrainers"),
      itemId: routes["ProfessionalTrainers"],
      //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
    },

    {
      title: t("sidebar.label.generalSurveys"),
      itemId: routes["generalSurveys"],
      //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
    },
    ...(is_logged_in
      ? [
          {
            title: t("sidebar.label.support_tickets"),
            itemId: routes["support_tickets"],
            //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
          },
        ]
      : []),
    {
      title: t("TermsAndConditions"),
      itemId: routes["TermsAndConditions"],
      //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
    },
    {
      title: t("ConnectUs"),
      itemId: routes["ConnectUs"],
      //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
    },
    //  ...(chatAllowed
    //    ? [
    //        {
    //          title: t("sidebar.label.live_chat"),
    //          itemId: routes["live_chat"],
    //          //   elemBefore: () => <PermIdentityOutlinedIcon color="#046C77" />,
    //        },
    //      ]
    //    : []),
  ];

  const checkItemIdIncludes = (itemId) => {
    const itemIds = [
      routes["home"],
      routes["AboutUs"],
      routes["courses"],
      routes["program"],
      routes["ProfessionalTrainers"],
      routes["Exhibition"],
      routes["generalSurveys"],
      routes["support_tickets"],
      routes["TermsAndConditions"],
      routes["financialReport"],
      routes["ConnectUs"],
      routes["live_chat"],
    ];
    return itemIds.includes(itemId);
  };

  const closeSideBar = () => {
    dismissSideBar();
  };

  useEffect(() => {
    setTimeout(() => {
      setAddAnimationSide(false);
    }, 300);
  }, [addAnimationSide]);

  return (
    <div
      className="sidebar inner-scrollbar"
      style={{
        transform: `${
          addAnimationSide && document.body.dir == "rtl"
            ? "translateX(100%)"
            : addAnimationSide && document.body.dir == "ltr"
            ? "translateX(-100%)"
            : null
        }`,
      }}
    >
      <div className="logo-sidebar">
        <NavLink exact to="/">
          <img src={Qassem_logo} alt="" />
        </NavLink>
        <img
          src={Menu}
          alt=""
          onClick={closeSideBar}
          style={{ width: 50, height: 35 }}
        />
      </div>
      {is_logged_in && (
        <div className="sidebar-header">
          <div className="user-image-Qassim">
            <img
              className="user-image"
              src={localStorage.getItem("img")}
              alt=""
            />
          </div>
          <div className="">
            <span style={{ fontSize: 22, fontWeight: 500 }}>
              {t("sidebar.label.hello_on_you")}
            </span>
            <h4>
              {i18n.language === Lang.AR
                ? localStorage.getItem("name_ar") != null
                  ? localStorage.getItem("name_ar").split(" ")[0]
                  : null
                : localStorage.getItem("name_en") != null
                ? localStorage.getItem("name_en").split(" ")[0]
                : null}
            </h4>
          </div>
        </div>
      )}

      <Navigation
        className="nav_sidebar"
        onSelect={({ itemId }) => {
          if (checkItemIdIncludes(itemId)) {
            history.push(itemId);
            closeSideBar();
          }
        }}
        items={sideBarItems}
      />
    </div>
  );
}

export default MainDrawer;
