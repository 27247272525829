import { walletConstants } from "../../Constants/wallet/wallet.constants";

const initialState = {
  userWalletTransactions: [],
  userWalletMeta: {},
  userWalletDashboard: {},
  userWallet: {},
  userWalletFilter: {},

  walletTransactions: [],
  walletMeta: {},
  walletDashboard: {},
  wallet: {},
  walletFilter: {},
  isLoading: false,
  walletCredit: {},
};

const userWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case walletConstants.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case walletConstants.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case walletConstants.GET_USER_WALLET_BY_ID:
      return {
        ...state,
        isLoading: false,
        userWallet: action.payload.user,
        userWalletDashboard: action.payload.dashboard,
        userWalletMeta: action.payload.meta,
        userWalletTransactions: action.payload.transactions,
        userWalletFilter: action.payload.filter,
      };
    case walletConstants.GET_USER_WALLET:
      return {
        ...state,
        isLoading: false,
        wallet: action.payload.user,
        walletDashboard: action.payload.dashboard,
        walletMeta: action.payload.meta,
        walletTransactions: action.payload.transactions,
        walletFilter: action.payload.filter,
      };

    case walletConstants.POST_PAY:
      return {
        ...state,
        walletCredit: action.payload,
      };

    default:
      return state;
  }
};

export default userWalletReducer;
