export const systemUsersListConstant = {
  // GET SYSTEM USERS LIST
  GET_SYSTEM_USERS_LIST_REQUEST: "GET_SYSTEM_USERS_LIST_REQUEST",
  GET_SYSTEM_USERS_LIST_SUCCESS: "GET_SYSTEM_USERS_LIST_SUCCESS",
  GET_SYSTEM_USERS_LIST_REJECTED: "GET_SYSTEM_USERS_LIST_REJECTED",

  // UPDATE SYSTEM USER STATUS
  UPDATE_SYSTEM_USER_STATUS_REQUEST: "UPDATE_SYSTEM_USER_STATUS_REQUEST",
  UPDATE_SYSTEM_USER_STATUS_SUCCESS: "UPDATE_SYSTEM_USER_STATUS_SUCCESS",
  UPDATE_SYSTEM_USER_STATUS_REJECTED: "UPDATE_SYSTEM_USER_STATUS_REJECTED",
  // ACCEPT SYSTEM USER STATUS
  ACCEPT_SYSTEM_USER_STATUS_REQUEST: "ACCEPT_SYSTEM_USER_STATUS_REQUEST",
  ACCEPT_SYSTEM_USER_STATUS_SUCCESS: "ACCEPT_SYSTEM_USER_STATUS_SUCCESS",
  ACCEPT_SYSTEM_USER_STATUS_REJECTED: "ACCEPT_SYSTEM_USER_STATUS_REJECTED",
  // REJECT SYSTEM USER STATUS
  REJECT_SYSTEM_USER_STATUS_REQUEST: "REJECT_SYSTEM_USER_STATUS_REQUEST",
  REJECT_SYSTEM_USER_STATUS_SUCCESS: "REJECT_SYSTEM_USER_STATUS_SUCCESS",
  REJECT_SYSTEM_USER_STATUS_REJECTED: "REJECT_SYSTEM_USER_STATUS_REJECTED",
};
