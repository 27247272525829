import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { AboutUsContext } from "../../../Services/api/toutorials/aboutusContext";
import { aboutUS } from "../../../Services/api/toutorials/aboutUsProvider";
import { NavLink } from "react-router-dom";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Lang } from "../../../utils";

function AboutUsAdmin(props) {
	require("./aboutUs.css");
	const theme = useTheme();
	const upMd = useMediaQuery(theme.breakpoints.up("md"));

	const { t, i18n } = useTranslation();
	const [textarea, setTextarea] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const aboutUsContext = useContext(AboutUsContext);

	useEffect(() => {
		aboutUS()
			.then((res) => {
				if (res.status && res.status == 200 && res.data.status) {
					console.log(res.data.aboutUS);
					aboutUsContext.setAboutus(res.data.aboutUS);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log("ERROR");
				console.log(err);
			});
	}, []);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="admin_label">
						<NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
						<p style={{ padding: "0 5px" }}>/</p>
						{t("sidebar.label.about-us")}
					</div>
					<div className="admin_card">
						<div
							className=""
							style={{ maxWidth: "90%", wordWrap: "break-word" }}
						>
							<div className="">
								{isLoading ? (
									<div
										className="my-5"
										style={{
											height: "15vh",
										}}
									>
										<SkeletonCard />
									</div>
								) : (
									<>
										<label htmlFor="">{t("label-about-ar")}:</label>
										<Box
											sx={{
												margin: "10px 0 30px",
												img: {
													width: { xs: "100%", md: "auto" },
												},
												"& p": {
													textAlign: "justify",
												},
												"& .se-image-container:first-of-type img": {
													width: 500,
													maxHeight: 500,
													shapeOutside: "url(assets/images/about-img.webp)",
													shapeMargin: upMd ? "45px" : "0",
													marginRight:
														upMd && i18n.language == Lang.AR
															? "45px"
															: upMd && i18n.language == Lang.EN
															? "0"
															: "auto",
													marginLeft:
														upMd && i18n.language == Lang.AR
															? "0"
															: upMd && i18n.language == Lang.EN
															? "45px"
															: "auto",
													marginBottom: upMd ? "10px" : "45px",
													float:
														upMd && i18n.language == Lang.AR
															? "left"
															: upMd && i18n.language == Lang.EN
															? "right"
															: "none",
													borderRadius: "27px",
													maxWidth: "100%",
												},
											}}
										>
											{parse(`${aboutUsContext.aboutus.description_ar}`)}
										</Box>
									</>
								)}
							</div>
							<div className="">
								{isLoading ? (
									<div
										className="my-5"
										style={{
											height: "15vh",
										}}
									>
										<SkeletonCard />
									</div>
								) : (
									<>
										<label htmlFor="">{t("label-about-en")}:</label>
										<Box
											sx={{
												margin: "10px 0 30px",
												img: {
													width: { xs: "100%", md: "auto" },
												},
												"& p": {
													textAlign: "justify",
												},
												"& .se-image-container:first-of-type img": {
													width: 500,
													height: 500,
													shapeOutside: "url(assets/images/about-img.webp)",
													shapeMargin: upMd ? "45px" : "0",
													marginRight:
														upMd && i18n.language == Lang.AR
															? "45px"
															: upMd && i18n.language == Lang.EN
															? "0"
															: "auto",
													marginLeft:
														upMd && i18n.language == Lang.AR
															? "0"
															: upMd && i18n.language == Lang.EN
															? "45px"
															: "auto",
													marginBottom: upMd ? "10px" : "45px",
													float:
														upMd && i18n.language == Lang.AR
															? "left"
															: upMd && i18n.language == Lang.EN
															? "right"
															: "none",
													borderRadius: "27px",
													maxWidth: "100%",
												},
											}}
										>
											{parse(`${aboutUsContext.aboutus.description_en}`)}
										</Box>
									</>
								)}
							</div>
						</div>
						{isLoading ? (
							<div
								style={{
									height: "5vh",
									width: "200px",
								}}
							>
								<SkeletonCard />
							</div>
						) : (
							<NavLink to="/admin/about-us-edit" style={{ width: "90%" }}>
								<div className="overview_button_div">
									<button className="overview_button">{t("edit")}</button>
								</div>
							</NavLink>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutUsAdmin;
