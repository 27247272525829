import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteCertificateTemplate,
  updateCertificatesTemplateStatus,
} from "../../../Redux/Actions/certificates/certificates-template.action";
import DeleteIcon from "../../../assets/icons/delete.svg";
import EyeIcon from "../../../assets/icons/eye.svg";
import editIcon from "../../../assets/icons/pen.svg";
import SelectedIcon from "../../../assets/icons/tap-selected.png";
import SelectIcon from "../../../assets/icons/tap.png";

import { Lang } from "../../../utils";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../Shared/Components/SwitchToggle/SwitchToggle";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "./CertificateTemplates.module.css";

const CertificateTemplatesTable = ({
  assignCertificateHandler,
  previewCertificateHandler,
  courseId,
  role,
  currentPage,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [certificateId, setCertificateId] = useState(null);
  const history = useHistory();

  const {
    certificatesList,
    certificatesMeta,
    certificatesListLoading,
    deleteCertificatesTemplateLoading,
    selectedCertificate,
  } = useSelector((state) => state.certificatesTemplateReducer);

  const updateCertificatesTemplateStatusHandler = (id) => {
    dispatch(
      updateCertificatesTemplateStatus({
        id,
      })
    );
  };

  const deleteHandler = (id) => {
    setShowDeleteModal(true);
    setCertificateId(id);
  };

  const confirmDeleteCertificateTemplateHandler = () => {
    if (!certificateId) return;
    dispatch(deleteCertificateTemplate(certificateId));
    setShowDeleteModal(false);
  };

  const CertificateTemplatesActionsList = (rowData) => {
    return [
      ...(courseId
        ? [
            {
              id: "assign",
              icon:
                selectedCertificate == rowData?.id ? (
                  <img
                    src={SelectedIcon}
                    alt=""
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                ) : (
                  <img
                    src={SelectIcon}
                    alt=""
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                ),
              action: (id) => {
                assignCertificateHandler(id);
              },
              tooltip: t("general.select"),
            },
          ]
        : []),
      {
        id: "view",
        icon: <img src={EyeIcon} alt="" />,
        action: (id) => {
          previewCertificateHandler(id);
        },
        tooltip: t("certificate.view_certificate"),
      },
      ...(!courseId
        ? [
            {
              id: "edit",
              icon: <img src={editIcon} alt="" />,
              action: (id) => {
                history.push({
                  pathname: `${
                    role === "admin" ? "/admin" : ""
                  }/certificateTemplates/edit/${id}`,
                  state: { role },
                });
              },
              tooltip: t("general.edit"),
            },

            {
              id: "delete",
              icon: <img src={DeleteIcon} alt="" />,
              action: (id) => {
                deleteHandler(id);
              },
              tooltip: t("general.delete"),
            },
          ]
        : []),
    ];
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (currentPage * 10 - 10)}</>
      ),
    },
    {
      id: "name",
      name: t("certificate_templates_name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["coupon-list__table-name"]}>
          {i18n.language == Lang.AR
            ? rowData?.name_ar || "-"
            : rowData?.name_en || "-"}
        </div>
      ),
    },
    // {
    //   id: "certificate_templates_desc_ar",
    //   name: t("certificate_templates_desc_ar"),
    //   key: "certificate_templates_desc_ar",
    //   cell: ({ rowData }) => (
    //     <div
    //       className={classes["certificate-desc"]}
    //       dangerouslySetInnerHTML={{
    //         __html: rowData?.description_ar || "-",
    //       }}
    //     ></div>
    //   ),
    // },
    // {
    //   id: "certificate_templates_desc_en",
    //   name: t("certificate_templates_desc_en"),
    //   key: "certificate_templates_desc_en",
    //   cell: ({ rowData }) => (
    //     <div
    //       className={classes["certificate-desc"]}
    //       dangerouslySetInnerHTML={{
    //         __html: rowData?.description_en || "-",
    //       }}
    //     ></div>
    //   ),
    // },

    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn
          actions={CertificateTemplatesActionsList(rowData)}
          id={rowData.id}
        />
      ),
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status}
          id={rowData.id}
          onStatusChange={() => {
            updateCertificatesTemplateStatusHandler(rowData.id);
          }}
        />
      ),
    },
  ];
  return (
    <>
      <MainTable
        columns={columns}
        data={certificatesList || []}
        isLoading={certificatesListLoading}
      />
      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon}
          title={t("certificate.messages.delete_certificate")}
          confirmMsg={t("certificate.messages.delete_certificate_msg")}
          confirmText={t("general.confirm")}
          cancelText={t("general.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            confirmDeleteCertificateTemplateHandler();
          }}
          isLoading={deleteCertificatesTemplateLoading}
        />
      )}
    </>
  );
};

export default CertificateTemplatesTable;
