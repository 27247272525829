import React from "react";
import classes from "./Banner.module.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Img from "../../../assets/image/Oval.png";
import Guy from "../../../assets/image/guy.png";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
export default function Banner() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <div className={classes.BannerCont}>
      <div className={classes.first}>
        <span>
          {" "}
          هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي
          القارئ
        </span>
        <p className={classes.maintext}>
          وتمنح شهادات اجازات أكاديمية لخريجيها
        </p>
        <CustomButton
          value={t("enroll")}
          colors={"rgb(3, 162, 178)"}
          classes={classes.btnRegister}
          action={() =>
            localStorage.type
              ? history.push("/training-courses")
              : history.push("/register")
          }
        />
      </div>
      <div
        className={classes.second}
        style={{
          backgroundImage: `url(${Img})`,
          transform: `${i18n.language === "en" ? "rotateY(180deg)" : ""}`,
        }}
      >
        <img src={Guy} alt="" className={classes.img} />
      </div>
    </div>
  );
}
