import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as ContentIcon } from "../../../../../assets/icons/Content.svg";
import { ReactComponent as ContentsIcon } from "../../../../../assets/icons/ContentIcon.svg";
import { ReactComponent as SoundIcon } from "../../../../../assets/icons/Sound.svg";
import { ReactComponent as VideoIcon } from "../../../../../assets/icons/Video.svg";
import { ReactComponent as PdfIcon } from "../../../../../assets/icons/black-pdf.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/crose.svg";
import DialogModal from "../../../../Shared/Components/DialogModal/DialogModal";
import AudioView from "../ContentView/AudioView";
import FileView from "../ContentView/FileView";
import PdfView from "../ContentView/PdfView";
import VideoView from "../ContentView/VideoView";
import LessonItem from "./LessonItem";
import ScormQuestionView from "../QuestionsTypes/ScormQuestionView";

export default function LessonContentItem({
  courseId,
  chapterId,
  unitId,
  lessonId,
  content,
  onOpen,
  onClose,
  isOpen: showModel,
  end,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  // const [showModel, setShowModel] = useState(0);

  const params = {
    icon: <ContentsIcon className="tw-h-5" />,
    name: t("trainer.course.lesson.content"),
    title: content.title,
    buttons: [
      {
        label:
          content.type == "audio"
            ? t("listen")
            : content.type == "scorm"
            ? t("participate")
            : t("view"),
        onClick: onOpen,
      },
    ],
    footer: {
      flags: {
        include_view: content.include_view_per,
        include_achivement: content.include_achivement_per,
        is_watched: content.is_watched,
        is_achieved: content.is_achieved,
      },
      end: end,
      columns: [
        {
          label: t("contentType"),
          value:
            content.type == "text" || content.type == "file" ? (
              <ContentIcon className="tw-h-4 tw-w-4" />
            ) : content.type == "video" ? (
              <VideoIcon className="tw-h-4 tw-w-4" />
            ) : content.type == "audio" ? (
              <SoundIcon className="tw-h-4 tw-w-4" />
            ) : content.type == "pdf" ? (
              <PdfIcon className="tw-h-4 tw-w-4" />
            ) : (
              <></>
            ),
        },
      ],
    },
  };
  return (
    <>
      {showModel ? (
        <DialogModal
          onCancel={onClose}
          className="tw-w-full sm:tw-w-4/5 md:tw-w-3/4 lg:tw-w-2/3 xl:tw-w-1/2"
        >
          <LessonContentModel
            onClose={onClose}
            onSuccess={onClose}
            content={content}
          />
        </DialogModal>
      ) : (
        <></>
      )}
      <LessonItem {...params} />
    </>
  );
}

function LessonContentModel({ content, onClose }) {
  const { t } = useTranslation();
  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-py-8 tw-w-full">
      <div
        className={`tw-bg-white tw-shadow tw-rounded tw-w-full tw-divide-y inner-scrollbar`}
        style={{ overflowY: "overlay", maxHeight: "90vh" }}
      >
        <div className="tw-flex tw-items-center tw-justify-between tw-px-8 tw-py-4">
          <div className="tw-text-xl tw-text-teal-700">{content.title}</div>
          <button type="button" onClick={onClose}>
            <CloseIcon className="tw-h-6 tw-w-6" />
          </button>
        </div>
        <div className="tw-px-8 tw-py-4">
          {content.type === "video" ? (
            <>
              <div>
                <VideoView question={content} />
              </div>
            </>
          ) : content.type === "audio" ? (
            <>
              <div>
                <AudioView question={content} />
              </div>
            </>
          ) : content.type === "pdf" ? (
            <>
              <div>
                <PdfView question={content} />
              </div>
            </>
          ) : content.type === "file" ? (
            <>
              <div>
                <FileView question={content} />
              </div>
            </>
          ) : content.type == "scorm" ? (
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.content,
                }}
                style={{ marginBottom: 20 }}
              ></div>
              <ScormQuestionView question={content} />
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: content.content,
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
