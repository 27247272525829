import { Stack } from "@mui/material";
import { BoxCard } from "../../Shared/Components";
import { ContactMail, ContactPhone } from "../../Shared/icons";
import ContactInfoBox from "./ContactInfoBox";

function ContactInformation() {
  return (
    <Stack spacing={"20px"}>
      <BoxCard sx={{ p: "0px !important" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d525812.4675171729!2d43.41344480704567!3d26.321868197388785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x158201a2e51ea661%3A0x4280bf3bc0653d99!2sQassim%20University!5e0!3m2!1sen!2ssa!4v1707392847121!5m2!1sen!2ssa"
          title="Contact us map"
          width="100%"
          height="767px"
          style={{ border: 0, borderRadius: "6px" }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </BoxCard>
      <ContactInfoBox
        icon={<ContactMail />}
        title={"emailus"}
        subTitle={"askqus"}
        btnText={"leaveMessage"}
        href={"#mailto:someone@example.com"}
      />
      <ContactInfoBox
        icon={<ContactPhone />}
        title={"lovehear"}
        subTitle={"wearehappytores"}
        btnText={"+971 529 234 252"}
        href={"https://api.whatsapp.com/send?phone=971529234252"}
      />
    </Stack>
  );
}

export default ContactInformation;
