import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function addEvent(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.events, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function updateEvent(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.update_event, { id: id }), payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getNeededDataCreateEvent() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.createNeededData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getNeededDataEditEvent(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.editNeededData, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getEventTypes() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.eventTypes)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getEventPerMonth(from_date, to_date) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.calender, {
          params: { from_date: from_date, to_date: to_date },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getEventPerDay(date) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.calender, {
          params: { date: date },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
