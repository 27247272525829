import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import check from "../../../../assets/image/Path 146.png";

function ResetPassword() {
  require("./resetPassword.css");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [type, setType] = useState("password");
  const [typere, setTypere] = useState("password");
  const { t } = useTranslation();

  const showHideFirst = (e) => {
    e.preventDefault();
    setType(type === "input" ? "password" : "input");
  };

  const showHideSecond = (e) => {
    e.preventDefault();
    setTypere(type === "input" ? "password" : "input");
  };

  let emailIcon = "";
  if (email.length > 10) {
    emailIcon = <img src={check} alt="" className="auth_circle_icon_2" />;
  } else {
    emailIcon = "";
  }

  let passwordIcon = "";
  if (password.length > 10) {
    passwordIcon = <img src={check} alt="" className="auth_circle_icon_2" />;
  } else {
    passwordIcon = "";
  }

  return (
    <div className="personal_info_card_st">
      <div className="personal_info_card">
        <div className="personal_form">
          <form id="contact" className="form">
            <div className="row">
              <div className="col-lg-12 form-group">
                <div className="personal_input">
                  <label className="personal_label">{t("emails")} : </label>
                  {emailIcon}
                  <input
                    className="personal_input_in"
                    id="name"
                    name="name"
                    placeholder=""
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-12 form-group">
                <div className="personal_input">
                  <label className="personal_label">{t("currpass")} : </label>
                  {passwordIcon}
                  <input
                    className="personal_input_in"
                    id="name"
                    name="name"
                    placeholder=""
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-12 form-group">
                <div className="personal_input">
                  <label className="personal_label">{t("newpass")} : </label>
                  <div className="auth_eye_2" onClick={showHideFirst}>
                    {type === "input" ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </div>
                  <input
                    className="personal_input_in"
                    id="name"
                    name="name"
                    placeholder=""
                    type={type}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-12 form-group">
                <div className="personal_input">
                  <label className="personal_label">{t("renewpass")} : </label>
                  <div className="auth_eye_2" onClick={showHideSecond}>
                    {typere === "input" ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </div>
                  <input
                    className="personal_input_in"
                    id="name"
                    name="name"
                    placeholder=""
                    type={typere}
                    value={rePassword}
                    onChange={(e) => setRePassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-md-12 form-group">
                  <input
                    className="personal_button"
                    type="submit"
                    value={t("save")}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
