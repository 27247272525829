import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { peymentRequestsAdminConstants } from "../../Constants/peymentRequestAdmin/peymentRequestAdmin.constants";

// GET PEYMENTREQUESTS LIST
export const fetchPeymentRequestsAdminList = ({
  page = 1,
  perPage = 10,
  filter,
}) => {
  return async (dispatch) => {
    dispatch({
      type: peymentRequestsAdminConstants.GET_PEYMENTREQUESTSADMIN_LIST_REQUEST,
    });

    await connector
      .get(env.get_peyment_request_admin, {
        params: {
          page,
          perPage,
          ...filter,
        }, /////////////////////
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          console.log(response?.data.payouts);
          dispatch({
            type: peymentRequestsAdminConstants.GET_PEYMENTREQUESTSADMIN_LIST_SUCCESS,
            payload: {
              peymentRequests: response?.data?.payouts, //////
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: peymentRequestsAdminConstants.GET_PEYMENTREQUESTSADMIN_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: peymentRequestsAdminConstants.GET_PEYMENTREQUESTSADMIN_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

// REJECT PEYMENTREQUEST
export const rejectPeymentRequest = ({ id, reject_reason }) => {
  return async (dispatch) => {
    dispatch({
      type: peymentRequestsAdminConstants.REJECT_PEYMENTREQUESTADMIN_REQUEST,
    });
    await connector
      .post(URLBuilder(env.peyment_action, { id: id }), {
        status: "Rejected",
        reject_reason,
        force_process: 1,
      }) ///////////////////////////
      .then((response) => {
        console.log("response", response);
        if (
          response.status &&
          response.status === 201 &&
          response.data.status
        ) {
          dispatch({
            type: peymentRequestsAdminConstants.REJECT_PEYMENTREQUESTADMIN_SUCCESS,
            payload: { id },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: peymentRequestsAdminConstants.REJECT_PEYMENTREQUESTADMIN_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: peymentRequestsAdminConstants.REJECT_PEYMENTREQUESTADMIN_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};

// ACCEPT PEYMENTREQUEST STATUS
export const acceptPeymentRequestStatus = ({ id, amount }) => {
  return async (dispatch) => {
    dispatch({
      type: peymentRequestsAdminConstants.ACCEPT_PEYMENTREQUESTADMIN_STATUS_REQUEST,
    });

    await connector
      .post(URLBuilder(env.peyment_action, { id: id }), {
        status: "Accepted",
        amount,
        force_process: 1,
      })
      .then((response) => {
        if (response?.status === 201 && response?.data?.status) {
          dispatch({
            type: peymentRequestsAdminConstants.ACCEPT_PEYMENTREQUESTADMIN_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: peymentRequestsAdminConstants.ACCEPT_PEYMENTREQUESTADMIN_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: peymentRequestsAdminConstants.ACCEPT_PEYMENTREQUESTADMIN_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};
