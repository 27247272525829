import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../Components";
import toLocalTimezone from "../../../../Services/Timezone";
import {
  deleteExam,
  editExamXML,
  exportAssessments,
  exportQuiz,
  exportQuizXML,
  getAssessments,
  importExam,
  importExamXML,
  sendMarks,
  toggleQuizActivity,
} from "../../../../Services/api/exams/ExamsProvider";
import { ReactComponent as AnalyzeIcon } from "../../../../assets/icons/analyze.svg";
import { ReactComponent as CroseIcon } from "../../../../assets/icons/crose.svg";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import FilterIcon from "../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/import.svg";
import { ReactComponent as JsonExportIcon } from "../../../../assets/icons/json-export.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import { ReactComponent as PenIcon } from "../../../../assets/icons/pen.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import { ReactComponent as SendIcon } from "../../../../assets/icons/send.svg";
import { ReactComponent as UserIcon } from "../../../../assets/icons/user.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import { ReactComponent as ZipExportIcon } from "../../../../assets/icons/zip-export.svg";
import { ReactComponent as JsonImportIcon } from "../../../../assets/icons/zip-import.svg";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./exhibitionList.module.css";

export default function AssessmentsList({ quizable, role }) {
  require("./List.css");

  const { t } = useTranslation();
  const { courseId, quizableId } = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [quizzes, setQuizzes] = useState([]);
  const [filter, setFilter] = useState({});

  const [filterPayload, setFilterPayload] = useState({});

  const [order, setOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [keyPrefix, setKeyPrefix] = useState("__default_key__");

  const [ratingRow, setRatingRow] = useState(null);
  const [showDeleteModalByRow, setShowDeleteModalByRow] = useState(null);

  const [selectedItemToBeReOpened, setSelectedItemToBeReOpened] = useState(null);

  const rend = () => Math.random().toString(36);

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
    console.log(filter);
  };

  const disableOrEnableQuiz = (row, index) => {
    let isActive = row.active;
    setIsUpdating(true);
    toggleQuizActivity(row.id)
      .then(() => {
        refetchQuiz();
        setIsUpdating(false);
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {!isActive ? t("trainer.quiz.messages.enabled") : t("trainer.quiz.messages.disabled")}
          </span>
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data?.msg}</span>);
        setIsUpdating(false);
      });
  };

  function getFilter() {
    const keys = Object.keys(filter);
    let currentFilter = { ...filter };

    if (currentFilter.questions_count) {
      const parseValue = JSON.parse(currentFilter.questions_count);
      currentFilter = {
        ...currentFilter,
        min_questions_count: parseValue.min,
        max_questions_count: parseValue.max,
      };
      delete currentFilter.questions_count;
    }

    if (currentFilter.success_rate != undefined && currentFilter.success_rate != null) {
      const parseValue = JSON.parse(currentFilter.success_rate);
      currentFilter = {
        ...currentFilter,
        min_success_rate: parseValue.min,
        max_success_rate: parseValue.max,
      };

      delete currentFilter.success_rate;
    }

    function boolToInt(value) {
      return value === "true" ? 1 : value === "false" ? 0 : null;
    }

    currentFilter.answers_corrected = currentFilter.answers_corrected;
    currentFilter.show_mark = boolToInt(currentFilter.show_mark);
    currentFilter.monitored = boolToInt(currentFilter.monitored);
    currentFilter.include_view_per = boolToInt(currentFilter.include_view_per);
    currentFilter.include_achivement_per = boolToInt(currentFilter.include_achivement_per);

    return currentFilter;
  }

  const localEditExamXML = async (file, id) => {
    if (!file) return;
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("quizable_id", quizableId);
      formData.append("quizable_type", quizable);
      setIsUpdating(true);
      await editExamXML(formData, id);
      refetchQuiz();
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("trainer.quiz.exam_edited_successfully")}</span>
      );
    } catch (err) {
      setIsUpdating(false);
      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response?.data?.msg ?? t("trainer.quiz.failed_to_edit_exam")}
          </span>
          <ul>
            {Object.keys(err.response?.data?.errors ?? {}).map((key) => {
              return (
                <li
                  style={{
                    direction: i18n.language == Lang.AR ? "rtl" : "ltr",
                  }}
                >
                  <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  const localImportExamXML = async (file) => {
    if (!file) return;
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("quizable_id", quizableId);
      formData.append("quizable_type", quizable);
      setIsUpdating(true);
      await importExamXML(formData);
      refetchQuiz();
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("trainer.quiz.exam_imported_successfully")}</span>
      );
    } catch (err) {
      setIsUpdating(false);

      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response?.data?.msg ?? t("trainer.quiz.failed_to_import_exam")}
          </span>
          <ul>
            {Object.keys(err.response?.data?.errors ?? {}).map((key) => {
              return (
                <li
                  style={{
                    direction: i18n.language == Lang.AR ? "rtl" : "ltr",
                  }}
                >
                  <span
                    style={{
                      fontSize: 11,
                    }}
                  >
                    {err.response.data.errors[key]}
                  </span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  const localImportExam = async (file) => {
    if (!file) return;
    let formData = new FormData();
    formData.append("file", file);
    try {
      formData.append("quizable_id", quizableId);
      formData.append("quizable_type", quizable);
      setIsUpdating(true);
      await importExam(formData);
      refetchQuiz();
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.quiz.exam_imported_successfully")}
          <br />
          {t("When re-importing the test, the files must be re-added")}
        </span>
      );
    } catch (err) {
      setIsUpdating(false);

      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response?.data?.msg ?? t("trainer.quiz.failed_to_import_exam")}
          </span>
          <ul>
            {Object.keys(err.response?.data?.errors ?? {}).map((key) => {
              return (
                <li
                  style={{
                    direction: i18n.language == Lang.AR ? "rtl" : "ltr",
                  }}
                >
                  <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  const exportQuizZip = async (id) => {
    setIsUpdating(true);
    try {
      await exportQuizXML(id);
    } catch (err) {
      console.log(err);
    }
    setIsUpdating(false);
  };

  const exportQuizJson = async (id) => {
    setIsUpdating(true);
    try {
      await exportQuiz(id);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("When re-importing the test, the files must be re-added")}
        </span>
      );
    } catch (err) {
      console.log(err);
    }
    setIsUpdating(false);
  };

  const localExportQuizes = async (type) => {
    let newFilter = {
      ...filter,
      quizable_type: quizable,
      quizable_id: quizableId,
    };
    setIsUpdating(true);
    try {
      await exportAssessments(type, courseId, newFilter);
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
    }
  };

  const actionsList = [
    {
      id: "answerCriteria",
      type: "button",
      label: t("answerCriteria.table_title"),
      color: "#8599B6",
      action: () => {
        history.push(`/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/evaluation`);
      },
    },
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      title: t("filter"),
      action: () => {
        setIsFilter((val) => !val);
      },
    },
    ...(role !== "partner"
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            title: t("trainer.quiz.add_exam"),
            theme: "add",
            action: () => {
              history.push(`/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/add`);
            },
          },
          {
            id: "json",
            icon: (
              <label htmlFor="import-json" className="tw-m-0 tw-p-4">
                <JsonExportIcon className="tw-h-5 tw-w-5" />
              </label>
            ),
            title: t(`${t("trainer.quiz.import")} JSON`),
            theme: "pdf",
            color: "#8599b6",
            action: () => {},
          },
          {
            id: "zip",
            icon: (
              <label htmlFor="import-zip" className="tw-m-0 tw-p-4">
                <ZipExportIcon className="tw-h-5 tw-w-5" />
              </label>
            ),
            title: t(`${t("trainer.quiz.import")} ZIP`),
            theme: "pdf",
            color: "#2e3e5f",
            action: () => {},
          },
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            title: t(`${t("trainer.quiz.export")} PDF`),
            action: () => localExportQuizes("pdf"),
          },
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            title: t(`${t("trainer.quiz.export")} Excel`),
            action: () => localExportQuizes("xlsx"),
          },
        ]
      : []),
  ];

  const quizActionsList = [
    {
      id: "participants",
      icon: (row) => <UserIcon className="tw-w-5 tw-h-5" />,
      title: (row) => t("participants"),
      disabled: (row) => row.monitored,
      action: (row) => {
        history.push(`/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${row.id}/participants`);
      },
    },
    {
      id: "analysis",
      icon: (row) => <AnalyzeIcon className="tw-w-5 tw-h-5" />,
      title: (row) => t("analysis.statistics"),
      disabled: (row) => row.monitored,
      action: (row) => {
        history.push(`/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${row.id}/statistics`);
      },
    },
    ...(role !== "partner"
      ? [
          {
            id: "export",
            icon: (row) => <JsonImportIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t(`${t("trainer.quiz.tooltip.exportFile")} JSON`),
            action: (row) => {
              exportQuizJson(row.id);
            },
          },
          {
            id: "share",
            icon: (row) => <SendIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t("trainer.quiz.tooltip.send_marks"),
            action: async (row) => {
              setIsUpdating(true);
              try {
                await sendMarks(row.id);
                refetchQuiz();
                toast.success(
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    {t("trainer.quiz.grades_sent_successfully")}
                  </span>
                );
              } catch (err) {
                console.log(err);
                toast.error(
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    {t("trainer.quiz.failed_to_send_grades")}
                  </span>
                );
              }
              setIsUpdating(false);
            },
          },
          {
            id: "zip-export",
            icon: (row) => <ImportIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t(`${t("trainer.quiz.tooltip.exportFile")} ZIP`),
            action: (row) => {
              exportQuizZip(row.id);
            },
          },
          {
            id: "import-zip",
            disabled: (row) => row.monitored,
            icon: (row) => (
              <label className="tw-m-0">
                <input
                  id={`import-zip-${row.id}`}
                  key={`import-zip-${row.id}-${keyPrefix}`}
                  type="file"
                  accept=".zip"
                  className="tw-hidden"
                  onChange={({ target: { files } }) => {
                    setKeyPrefix(rend());
                    localEditExamXML(files[0], row.id);
                  }}
                />
                <ExportIcon className="tw-w-5 tw-h-5" />
              </label>
            ),
            title: (row) => t(`${t("trainer.quiz.import_questions")} ZIP`),
            action: (row) => {},
          },
        ]
      : []),
    {
      id: "view-details",
      icon: (row) => <EyeIcon className="tw-w-5 tw-h-5" />,
      title: (row) => t("general.view_details"),
      action: (row) => {
        history.push(`/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/${row.id}`);
      },
    },
    ...(role !== "partner"
      ? [
          {
            id: "edit",
            icon: (row) => <PenIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t("edit"),
            action: (row) => {
              history.push(`/${role}/course/${courseId}/${quizable}/${quizableId}/assessment/edit/${row.id}`);
            },
          },
          {
            id: "delete",
            icon: (row) => <CroseIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t("delete"),
            action: (row, index = null) => {
              console.log(row);
              setShowDeleteModalByRow(row);
            },
          },
        ]
      : []),
  ];

  const columns = [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>,
    },
    // title
    {
      id: "title",
      name: t("quiz_title"),
      key: "title",
      cell: ({ rowData }) => <span className="cell-font tw-w-36 text2lines">{rowData.title}</span>,
    },
    // is_answers_corrected
    {
      id: "is_answers_corrected",
      name: t("quiz_grading"),
      key: "is_answers_corrected",
      cell: ({ rowData }) => (
        <span
          className={`cell-font tw-p-1 tw-rounded ${
            parseInt(rowData.users_answered_count)
              ? rowData.is_answers_corrected
                ? "tw-text-amber-500 tw-bg-amber-500/10"
                : "tw-text-gray-400 tw-bg-gray-400/10"
              : "tw-text-gray-400 tw-bg-gray-300/10"
          }`}
        >
          {parseInt(rowData.users_answered_count)
            ? rowData.is_answers_corrected
              ? t("completed")
              : t("not_completed")
            : t("no_participants")}
        </span>
      ),
    },
    // questions_count
    {
      id: "questions_count",
      name: t("qustion_number"),
      key: "questions_count",
      cell: ({ rowData }) => <span className="cell-font tw-whitespace-nowrap">{rowData.questions_count}</span>,
    },
    // success_rate
    {
      id: "success_rate",
      name: t("pass_percentage"),
      key: "success_rate",
      cell: ({ rowData }) => <span className="cell-font tw-whitespace-nowrap">{rowData.success_rate}%</span>,
    },
    // answer_attempts
    {
      id: "answer_attempts",
      name: t("answer_attempts"),
      key: "answer_attempts",
      cell: ({ rowData }) => <span className="cell-font tw-whitespace-nowrap">{rowData.answer_attempts}</span>,
    },
    // show_mark
    {
      id: "show_mark",
      name: t("show_grade"),
      key: "show_mark",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: rowData.show_mark ? "#4ec1c6" : "#ff5656",
            backgroundColor: rowData.show_mark ? "#e3f5f6" : "#ffe4e4",
          }}
        >
          {t(rowData.show_mark ? t("Yes") : t("No"))}
        </span>
      ),
    },
    // include_view_per
    {
      id: "include_view_per",
      name: t("include_view_per"),
      key: "include_view_per",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: rowData.include_view_per ? "#4ec1c6" : "#ff5656",
            backgroundColor: rowData.include_view_per ? "#e3f5f6" : "#ffe4e4",
          }}
        >
          {t(rowData.include_view_per ? t("Yes") : t("No"))}
        </span>
      ),
    },
    // include_achivement_per
    {
      id: "include_achivement_per",
      name: t("include_achivement_per"),
      key: "include_achivement_per",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: rowData.include_achivement_per ? "#4ec1c6" : "#ff5656",
            backgroundColor: rowData.include_achivement_per ? "#e3f5f6" : "#ffe4e4",
          }}
        >
          {t(rowData.include_achivement_per ? t("Yes") : t("No"))}
        </span>
      ),
    },
    // monitored
    {
      id: "monitored",
      name: t("monitored"),
      key: "monitored",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: rowData.monitored ? "#4ec1c6" : "#ff5656",
            backgroundColor: rowData.monitored ? "#e3f5f6" : "#ffe4e4",
          }}
        >
          {t(rowData.monitored ? t("Yes") : t("No"))}
        </span>
      ),
    },
    // start_date
    {
      id: "start_date",
      name: t("start_date"),
      key: "start_date",
      sortable: true,
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData.start_date ? (
            <>
              <div>{moment(toLocalTimezone(rowData.start_date)).format("DD/MM/YYYY")}</div>
              <div>{moment(toLocalTimezone(rowData.start_date)).format("hh:mm A")}</div>
            </>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    // end_date
    {
      id: "end_date",
      name: t("end_date_quiz"),
      key: "end_date",
      sortable: true,
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData.end_date ? (
            <>
              <div>{moment(toLocalTimezone(rowData.end_date)).format("DD/MM/YYYY")}</div>
              <div>{moment(toLocalTimezone(rowData.end_date)).format("hh:mm A")}</div>
            </>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    // actions
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData, rowIndex }) => (
        <div className={`${classes["exhibition_list__actions"]} tw-flex tw-items-center tw-justify-end`}>
          {quizActionsList.map((action, index) => (
            <div
              className={
                action.disabled && action.disabled(rowData, rowIndex) ? "tw-pointer-events-none tw-opacity-50" : ""
              }
            >
              <IconsTooltip
                title={action.title(rowData)}
                content={
                  <button
                    key={action.id}
                    disabled={action.disabled && action.disabled(rowData, rowIndex)}
                    className={classes["exhibition_list__actions-btn"]}
                    onClick={() => {
                      action.action(rowData, rowIndex);
                    }}
                  >
                    {action.icon(rowData, rowIndex)}
                  </button>
                }
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      id: "status",
      name: t("Exhibition.status_exh"),
      key: "status",
      sortable: false,
      cell: ({ rowData, rowIndex }) => (
        <SwitchToggle
          key={rowData.id}
          status={rowData.active}
          id={rowData.id}
          onStatusChange={() => {
            disableOrEnableQuiz(rowData, rowIndex);
          }}
          disabled={role === "partner"}
        />
      ),
    },
  ];

  const filterFields = [
    // title
    {
      id: "title",
      component: "input",
      type: "text",
      placeholder: t("quiz_title"),
      label: t("quiz_title"),
      name: "title",
      value: filter.title ?? "",
      classes: "col-12 col-md-6",
      onReset: () => filterChangeHandler({ target: { value: "", name: "title" } }),
      onchange: filterChangeHandler,
    },
    // answers_corrected
    {
      id: "answers_corrected",
      component: "select",
      placeholder: t("quiz_grading"),
      label: t("quiz_grading"),
      initialValue: t("quiz_grading"),
      name: "answers_corrected",
      value: filter.answers_corrected ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        { value: 1, label: t("completed") },
        { value: 0, label: t("not_completed") },
        { value: -1, label: t("no_participants") },
      ],
    },
    // questions_count
    {
      id: "questions_count",
      component: "select",
      placeholder: t("qustion_number"),
      label: t("qustion_number"),
      initialValue: t("qustion_number"),
      name: "questions_count",
      value: filter.questions_count ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: [
        {
          id: "1",
          value: JSON.stringify({ max: 10, min: 0 }),
          label: "0 - 10",
        },
        {
          id: "2",
          value: JSON.stringify({ max: 20, min: 11 }),
          label: "11 - 20",
        },
        {
          id: "3",
          value: JSON.stringify({ max: 30, min: 21 }),
          label: "21 - 30",
        },
        {
          id: "4",
          value: JSON.stringify({ max: 40, min: 31 }),
          label: "31 - 40",
        },
        {
          id: "5",
          value: JSON.stringify({ max: 50, min: 41 }),
          label: "41 - 50",
        },
        {
          id: "6",
          value: JSON.stringify({ max: 100, min: 51 }),
          label: "51 - 100",
        },
        {
          id: "7",
          value: JSON.stringify({ min: 101, max: "" }),
          label: t("survey.morethan"),
        },
      ],
    },
    // success_rate
    {
      id: "success_rate",
      component: "select",
      placeholder: t("pass_percentage"),
      label: t("pass_percentage"),
      initialValue: t("pass_percentage"),
      name: "success_rate",
      value: filter.success_rate ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: [
        {
          id: "2",
          value: JSON.stringify({ max: 20, min: 10 }),
          label: "10 - 20",
        },
        {
          id: "3",
          value: JSON.stringify({ max: 30, min: 21 }),
          label: "21 - 30",
        },
        {
          id: "4",
          value: JSON.stringify({ max: 40, min: 31 }),
          label: "31 - 40",
        },
        {
          id: "5",
          value: JSON.stringify({ max: 50, min: 41 }),
          label: "41 - 50",
        },
        {
          id: "6",
          value: JSON.stringify({ max: 60, min: 51 }),
          label: "51 - 60",
        },
        {
          id: "7",
          value: JSON.stringify({ max: 70, min: 61 }),
          label: "61 - 70",
        },
        {
          id: "8",
          value: JSON.stringify({ max: 80, min: 71 }),
          label: "71 - 80",
        },
        {
          id: "9",
          value: JSON.stringify({ max: 90, min: 81 }),
          label: "81 - 90",
        },
      ],
    },
    // show_mark
    {
      id: "show_mark",
      component: "select",
      placeholder: t("show_mark"),
      label: t("show_mark"),
      initialValue: t("show_mark"),
      name: "show_mark",
      value: filter.show_mark ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        { value: true, label: t("yes") },
        { value: false, label: t("No") },
      ],
    },
    // monitored
    {
      id: "monitored",
      component: "select",
      placeholder: t("monitored"),
      label: t("monitored"),
      initialValue: t("monitored"),
      name: "monitored",
      value: filter.monitored ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        { value: true, label: t("yes") },
        { value: false, label: t("No") },
      ],
    },
    // include_view_per
    {
      id: "include_view_per",
      component: "select",
      placeholder: t("include_view_per"),
      label: t("include_view_per"),
      initialValue: t("include_view_per"),
      name: "include_view_per",
      value: filter.include_view_per ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        { value: true, label: t("yes") },
        { value: false, label: t("No") },
      ],
    },
    // include_achivement_per
    {
      id: "include_achivement_per",
      component: "select",
      placeholder: t("include_achivement_per"),
      label: t("include_achivement_per"),
      initialValue: t("include_achivement_per"),
      name: "include_achivement_per",
      value: filter.include_achivement_per ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        { value: true, label: t("yes") },
        { value: false, label: t("No") },
      ],
    },
    // start_date
    {
      id: "start_date",
      component: "dateRange",
      placeholder: t("general.start_date"),
      label: t("general.start_date"),
      name: "start_date",
      value: filter.start_date ?? "",
      classes: "col-12 col-md-3",
      onchange: filterChangeHandler,
    },
    // end_date
    {
      id: "end_date",
      component: "dateRange",
      placeholder: t("general.end_date"),
      label: t("general.end_date"),
      name: "end_date",
      value: filter.end_date ?? "",
      classes: "col-12 col-md-3",
      onchange: filterChangeHandler,
    },
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-2 tw-ms-auto",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setFilter({});
        setFilterPayload({});
        if (currentPage == 1) {
          refetchQuiz({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2",
      action: () => {
        setFilterPayload(getFilter());
        if (currentPage == 1) {
          refetchQuiz();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  const fetchQuiz = (params = getFilter()) => {
    params = {
      ...params,
      perPage: 10,
      quizable_type: quizable,
      quizable_id: quizableId,
    };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getAssessments(courseId, params);
        console.log(response);
        if (response.status == 200) {
          setQuizzes(response.data.data.quizzes);
          setMeta(response.data.data.meta);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  const sortActionHandler = async (columnKey) => {
    let key = columnKey;

    setOrder({
      order: key,
      dir: order?.order == key && order?.dir == "ASC" ? "DESC" : "ASC",
    });
  };

  const refetchQuiz = async (params = getFilter()) => {
    try {
      let customFilter = {
        ...params,
        ...order,
        page: currentPage,
      };
      await fetchQuiz(customFilter);
    } catch (e) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  useEffect(() => {
    refetchQuiz();
  }, [currentPage, order]);

  const breadcrumbList = [
    ...(role == "admin"
      ? [
          {
            id: t("admin.label.admin_label"),
            page: t("admin.label.admin_label"),
            pagePath: "/admin",
          },
        ]
      : [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
        ]),
    {
      id: t("sidebar.label.courses"),
      page: t("sidebar.label.courses"),
      pagePath: `/${role}/courses`,
    },
    {
      id: t("Courseoutline"),
      page: t("Courseoutline"),
      pagePath: `/${role}/course/${courseId}`,
    },
    {
      id: t("trainer.quiz.exams"),
      page: t("trainer.quiz.exams"),
      active: true,
    },
  ];

  return (
    <>
      {showDeleteModalByRow && (
        <SharedModal
          icon={DeleteIcon}
          title={t("trainer.quiz.modal.delete.label")}
          name={showDeleteModalByRow.title}
          confirmMsg={t("trainer.quiz.modal.delete.description")}
          confirmText={t("trainer.quiz.modal.delete.accept")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModalByRow(null);
          }}
          onConfirm={async () => {
            try {
              setIsUpdating(true);
              const res = await deleteExam(showDeleteModalByRow.id);
              if (res.status && res.status == 200 && res.data.status) {
                setShowDeleteModalByRow(null);
                setIsUpdating(false);
                refetchQuiz();
              } else {
                throw res;
              }
            } catch (error) {
              setShowDeleteModalByRow(null);
              setIsUpdating(false);
              console.log(error);
              toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.data?.msg}</span>);
            }
          }}
          // isLoading={iseDeleteLoading}
        />
      )}
      <input
        id="import-zip"
        key={`import-zip-${keyPrefix}`}
        type="file"
        accept=".zip"
        className="tw-hidden"
        onChange={({ target: { files } }) => {
          setKeyPrefix(rend());
          localImportExamXML(files[0]);
        }}
      />
      <input
        id="import-json"
        key={`import-json-${keyPrefix}`}
        type="file"
        accept=".json"
        className="tw-hidden"
        onChange={({ target: { files } }) => {
          setKeyPrefix(rend());
          localImportExam(files[0]);
        }}
      />
      <div className="tw-ms-auto"></div>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <div className="tw-space-y-4 tw-mb-8">
            <MainBox>
              <MainBoxHead title={t("trainer.quiz.exams")} actions={actionsList} />
              {isFilter && <FilterBox fields={filterFields} />}
              <MainTable columns={columns} data={quizzes} sortAction={sortActionHandler} />
              <Pagination
                count={meta.totalPages}
                page={currentPage}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                className="main-pagination"
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}
