import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  getChapter,
  getTraineeChapterAnalysis,
  getTraineeCourseAnalysis,
  getUserAnalysis,
} from "../../../../Services/api/Analysis/AnalysisProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import BarChart from "./barChart/BarChart";

const TraineeChapter = () => {
  require("../Analysis.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUsers] = useState(null);
  const [usersId, setUsersId] = useState(null);
  const [courseError, setCourseError] = useState(null);
  const [chapterError, setChapterError] = useState(null);
  const [lessonProgress, setLessonProgress] = useState([]);
  const [search, setSearch] = useState(false);
  const [allCourses, setAllCourses] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [chapter, setChapter] = useState([]);
  const [chapterId, setChapterId] = useState(null);
  const [chapterIdCheck, setChapterIdCheck] = useState(null);
  const [courseIdCheck, setCourseIdCheck] = useState(null);

  useEffect(() => {
    getUserAnalysis().then((res) => {
      if (res.status && res.status == 200 && res.data.status) {
        setUsers(res.data.data);
        setIsLoading(false);
      } else {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      }
    });
  }, []);

  useEffect(() => {
    setAllCourses([]);
    setCourseId(null);
    setCourseIdCheck(null);
    setChapter([]);
    setChapterIdCheck(null);
    setChapterId(null);
    setSearch(false);
    setIsLoading(true);
    if (usersId) {
      getTraineeCourseAnalysis(usersId).then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setAllCourses(res.data.response.courses);
          setCourseIdCheck(usersId);
          setIsLoading(false);
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
        }
      });
    } else {
      setIsLoading(false);
    }
  }, [usersId]);

  useEffect(() => {
    setIsLoading(true);
    setChapter([]);
    setChapterIdCheck(null);
    setChapterId(null);
    setSearch(false);
    if (courseId !== null) {
      getChapter(courseId)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setChapter(res.data.chapters);
            setChapterIdCheck(courseId);
            setIsLoading(false);
            setCourseError(null);
          }
        })
        .catch((err) => {
          
        });
    } else {
      setChapter([]);
      setChapterId(null);
      setIsLoading(false);
      setSearch(false);
    }
  }, [courseId]);

  useEffect(() => {
    setChapterError(null);
  }, [chapterId]);

  const apiHandler = (e) => {
    setIsLoading(true);
    if (courseId === null) {
      setCourseError(t("crud.errors.required"));
      setIsLoading(false);
    } else if (chapterId === null) {
      setChapterError(t("crud.errors.required"));
      setIsLoading(false);
    } else {
      setCourseError(null);
      getTraineeChapterAnalysis(usersId, chapterId).then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setIsLoading(false);
          setLessonProgress(res.data.response.lessons_progress);
          setSearch(true);
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
        }
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="admin_label">
            <NavLink to="/admin">{t("Home")}</NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("sidebar.label.chartTraineeAnalysis")}
          </div>
          <>
            <div className="counter_analysis">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  width: "100%",
                  gap: "50px",
                  alignItems: "flex-end",
                }}
              >
                <div className="select_analysis" style={{ width: "100%" }}>
                  <div className="select_label">{t("analysis.allUser")}</div>
                  <Select
                    name="users"
                    id="users"
                    options={user}
                    getOptionLabel={(option) =>
                      i18n.language === "ar"
                        ? option.name_ar == null
                          ? option.name_en
                          : option.name_ar
                        : option.name_en
                    }
                    getOptionValue={(option) => option.id}
                    isClearable={true}
                    onChange={(item) => {
                      if (item) {
                        setUsersId(item.id);
                      } else {
                        setUsersId(null);
                        setChapterId(null);
                      }
                    }}
                    placeholder={t("analysis.select")}
                  />
                  <p className={"form-input-error-space"} style={{ position: "absolute" }}>
                    {courseError ? courseError : null}
                  </p>
                </div>

                {courseIdCheck !== null ? (
                  <div className="select_analysis" style={{ width: "100%" }}>
                    <div className="select_label">{t("analysis.allCourse")}</div>
                    <Select
                      name="users"
                      id="users"
                      options={allCourses}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      onChange={(item) => {
                        if (item) {
                          setCourseId(item.id);
                        } else {
                          setCourseId(null);
                          setChapterId(null);
                        }
                      }}
                      placeholder={t("analysis.select")}
                    />
                    <p className={"form-input-error-space"} style={{ position: "absolute" }}>
                      {courseError ? courseError : null}
                    </p>
                  </div>
                ) : null}

                {chapterIdCheck && (
                  <div className="select_analysis" style={{ width: "100%" }}>
                    <div className="select_label">{t("analysis.allChapter")}</div>
                    <Select
                      name="chapter"
                      id="chapter"
                      options={chapter}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      onChange={(item) => {
                        if (item) {
                          setChapterId(item.id);
                        } else {
                          setChapterId(null);
                        }
                      }}
                      placeholder={t("analysis.select")}
                    />
                    <p className={"form-input-error-space"} style={{ position: "absolute" }}>
                      {chapterError ? chapterError : null}
                    </p>
                  </div>
                )}
                {chapterId && (
                  <div
                    className="select_analysis"
                    style={{
                      width: "100%",
                    }}
                  >
                    <button
                      className="btn"
                      style={{
                        backgroundColor: "#126e77",
                        color: "#fff",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                      }}
                      onClick={apiHandler}
                    >
                      {t("analysis.search")}
                    </button>
                  </div>
                )}
              </div>

              {isLoading ? (
                <div
                  style={{
                    height: "25vh",
                    marginTop: "20px",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <>
                  {search == false ? (
                    <h5
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "70px 0",
                      }}
                    >
                      {usersId == null
                        ? t("analysis.userError")
                        : courseId == null
                        ? t("analysis.courseError")
                        : chapterId == null
                        ? t("analysis.chapterError")
                        : null}
                    </h5>
                  ) : (
                    <div className="mt-5">
                      <BarChart lessonProgress={lessonProgress} />
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default TraineeChapter;
