import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { publishReqsListConstants } from "../../Constants/publishReq/publishReq.constant";
import i18n from "../../../i18n/i18n";

// GET publish REQS LIST
export const fetchPublishReqsList = ({ page = 1, perPage = 10, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: publishReqsListConstants.GET_PUBLISHREQS_LIST_REQUEST,
    });

    await connector
      .get(env.publish_req_list, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        // console.log(response);
        if (response?.status === 200) {
          dispatch({
            type: publishReqsListConstants.GET_PUBLISHREQS_LIST_SUCCESS,
            payload: {
              requests: response?.data?.requests,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: publishReqsListConstants.GET_PUBLISHREQS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: publishReqsListConstants.GET_PUBLISHREQS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};
export const AcceptReq = (id) => {
  return async (dispatch) => {
    dispatch({
      type: publishReqsListConstants.ACCEPT_PUBLISHREQ_REQUEST,
    });
    await connector
      .post(URLBuilder(env.accept_req, { id: id }))
      .then((response) => {
        console.log("response", response);
        if (response.status && response.status === 200) {
          dispatch({
            type: publishReqsListConstants.ACCEPT_PUBLISHREQ_SUCCESS,
            payload: { id },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: publishReqsListConstants.ACCEPT_PUBLISHREQ_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: publishReqsListConstants.ACCEPT_PUBLISHREQ_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};
export const RejectReq = (id, reject_reason) => {
  return async (dispatch) => {
    dispatch({
      type: publishReqsListConstants.REJECT_PUBLISHREQ_REQUEST,
    });
    await connector
      .post(URLBuilder(env.reject_req, { id: id }), {
        reject_reason: reject_reason,
      })
      .then((response) => {
        console.log("response", response);
        if (response.status && response.status === 200) {
          dispatch({
            type: publishReqsListConstants.REJECT_PUBLISHREQ_SUCCESS,
            payload: { id },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: publishReqsListConstants.REJECT_PUBLISHREQ_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: publishReqsListConstants.REJECT_PUBLISHREQ_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};
export const deleteAdvertisement = (id) => {
  return async (dispatch) => {
    dispatch({
      type: publishReqsListConstants.DELETE_PUBLISHREQ_REQUEST,
    });
    await connector
      .delete(URLBuilder(env.delete_ad, { id: id }))
      .then((response) => {
        console.log("response", response);
        if (response.status && response.status === 200) {
          dispatch({
            type: publishReqsListConstants.DELETE_PUBLISHREQ_SUCCESS,
            payload: { id },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {i18n.language === "ar"
                ? "تم حذف الاعلان بنجاح"
                : "The Advertise Deleted successfully"}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: publishReqsListConstants.DELETE_PUBLISHREQ_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: publishReqsListConstants.DELETE_PUBLISHREQ_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};
