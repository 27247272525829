import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {NavLink} from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {Formik} from "formik";
import Pdf from "./../../../../assets/image/rating/pdf.png";
import Xls from "./../../../../assets/image/rating/xls.png";
import {exportOrder, getOrder,} from "../../../../Services/api/Financial/FinancialProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import {toast} from "react-toastify";
import ReactPaginate from "react-paginate";
import IconTooltips from "../../../../Components/tooltips/IconsTooltip";

function List(props) {
  require("./Report.css");
  const { t } = useTranslation();
  const history = useHistory();

  const [order, setOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [meta, setMeta] = useState({});
  const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] =
    useState(false);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [filter, setFilter] = useState({});

  const [status, setStatus] = useState([
    { text: t("financial.label.approved"), value: 1 },
    { text: t("financial.label.canceled"), value: 2 },
    { text: t("financial.label.waiting"), value: 3 },
    { text: t("financial.label.refund"), value: 4 },
    { text: t("financial.label.sponsor"), value: 5 },
  ]);

  const [paymentMethod, setPaymentMethod] = useState([
    { text: t("financial.label.bankTrasfer"), value: "BankTrasfer" },
    { text: t("financial.label.stripe"), value: "Stripe" },
    { text: t("financial.label.freeCourse"), value: "FreeCourse" },
    { text: t("financial.label.wallet"), value: "Wallet" },
  ]);
  const [PaginationSelected, setPaginationSelected] = useState(null)

  const fetchOrder = (params = null) => {
    setIsUpdating(true)
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getOrder(clearEmptyProp(params));
        if (response.status == 200) {
          setOrder(response.data.orders.data);
          setMeta(response.data.orders.meta);
          setIsLoading(false);
          setIsUpdating(false)
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  useEffect(async () => {
    try {
      await fetchOrder(filter);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  }, []);

  useEffect(() => {
    setCurrentOrder(order && order);
  }, [order]);

  const handlePageClick = (event) => {
      let paginationElment = document.querySelectorAll(".pagination .active")
      let paginationActive = paginationElment.length
      if(paginationActive > 0 ){
        paginationElment[0].classList.remove("active")
      }
    // document.querySelectorAll(".pagination .active")[0].classList.remove("active")
    // console.log(event.selected, meta)
    setPaginationSelected(event.selected)
    const toBeUpdatedFilters = filter;
    toBeUpdatedFilters.page = event.selected + 1;
    setFilter(toBeUpdatedFilters);

    fetchOrder(toBeUpdatedFilters);
  };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
};

const formatTime = (t) => {
  let hours = "" + t.getHours(),
    minutes = "" + t.getMinutes(),
    seconds = "" + t.getSeconds();

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return [hours, minutes, seconds].join(":");
};

  return (
    <>
      <div className="container-fluid">
        <div className="admin_label">
          <NavLink
            to={`${
              localStorage.getItem("type") == "accountant"
                ? "/accountant"
                : "/admin"
            }`}
          >
            {t("admin.label.admin_label")}
          </NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {t("financial.label.report")}
        </div>
        {isUpdating ?<SkeletonCardOverlay skeletonWidth="100"/>:<div></div>}
        {isPerformingBackgroundOperation && (
          <SkeletonCardOverlay borderRadius={10} />
        )}
        {isLoading == false ? (
          <>
            {showFilters == true ? (
              <div
                className={`tajah_table_fade_change ${
                  showFilters && "filter_opacity"
                }`}
                style={{}}
              >
                <Formik
                  initialValues={{
                    resource_name: "",
                    trainee_name: "",
                    payment_method: "",
                    status: "",
                    start_date: "",
                    end_date: ""
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      setIsPerformingBackgroundOperation(true);

                      let dataToBeUploaded = {
                        ...values,
                      };
                      if(dataToBeUploaded.start_date && dataToBeUploaded.end_date){
                            dataToBeUploaded.start_date = formatDate(dataToBeUploaded.start_date) + " " + formatTime(dataToBeUploaded.start_date);
                            dataToBeUploaded.end_date = formatDate(dataToBeUploaded.end_date) + " " + formatTime(dataToBeUploaded.end_date);
                      }



                      setFilter(dataToBeUploaded);
                      await fetchOrder(dataToBeUploaded);
                      setIsPerformingBackgroundOperation(false);
                      // handlePageClick({selected: 0})

                      //   if(document.querySelectorAll(".pagination .active")[0]){
                      //       document.querySelectorAll(".pagination .active")[0].classList.remove("active")
                      //       document.querySelectorAll(".pagination .page-item")[1].classList.add('active')
                      //   }else{
                      //       document.querySelectorAll(".pagination .page-item")[1].classList.add('active')
                      //   }

                    } catch (e) {
                      toast.error(
                        <>
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                              display: "block",
                              paddingBottom: "10px",
                            }}
                          >
                            {e.response.data.msg}
                          </span>
                          {e.response.data.errors ? (
                            <ul>
                              {Object.keys(e.response.data.errors).map(
                                (key) => {
                                  return (
                                    <li>
                                      <span style={{ fontSize: 11 }}>
                                        {e.response.data.errors[key]}
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          ) : null}
                        </>
                      );
                    }
                  }}
                  validateOnChange={false}
                >
                  {({ values, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit} class={"table_bank_tran"}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          width: "90%",
                          borderBottomWidth: 1,
                          borderBottomColor: "rgba(0,0,0,0.3)",
                          borderBottomStyle: "solid",
                          height: 40,
                        }}
                      >
                        <h5>{t("Filter")}</h5>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "90%",
                          marginTop: 20,
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            marginLeft: document.body.dir == "rtl" ? 10 : 0,
                            marginRight: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        >
                          <label>{t("financial.label.resource_name")}</label>
                          <input
                            id="resource_name"
                            type="text"
                            name="resource_name"
                            className="admin_filter_input"
                            onChange={(e) => {
                              setFieldValue("resource_name", e.target.value);
                            }}
                            placeholder={t("financial.label.resource_name")}
                            value={values.resource_name}
                          />
                        </div>

                        <div
                          style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        >
                          <label>{t("financial.label.payment_method")}</label>
                          <Select
                            name="payment_method"
                            id="payment_method"
                            options={paymentMethod}
                            getOptionLabel={(option) => option.text}
                            getOptionValue={(option) => option.value}
                            isClearable={true}
                            onChange={(item) => {
                              if (item) {
                                setFieldValue("payment_method", item.value);
                              } else {
                                setFieldValue("payment_method", null);
                              }
                            }}
                            placeholder={t("financial.label.payment_method")}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "90%",
                          marginTop: 30,
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        >
                          <label>{t("financial.label.trainee_name")}</label>
                          <input
                            id="trainee_name"
                            type="text"
                            name="trainee_name"
                            className="admin_filter_input"
                            onChange={(e) => {
                              setFieldValue("trainee_name", e.target.value);
                            }}
                            placeholder={t("financial.label.trainee_name")}
                            value={values.trainee_name}
                          />
                        </div>

                        <div
                          style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        >
                          <label>{t("financial.label.status")}</label>
                          <Select
                            name="status"
                            id="status"
                            options={status}
                            getOptionLabel={(option) => option.text}
                            getOptionValue={(option) => option.value}
                            isClearable={true}
                            onChange={(item) => {
                              if (item) {
                                setFieldValue("status", item.value);
                              } else {
                                setFieldValue("status", null);
                              }
                            }}
                            placeholder={t("financial.label.status")}
                          />
                        </div>
                      </div>
                        {/* Add Time  */}
                        <div
                            style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: "90%",
                            marginTop: 30,
                            }}
                        >
                            <div  style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}>
                                <label htmlFor="start_day" >
                                    {t('zoom.start_date')}
                                </label>
                                <DatePicker
                                    selected={values.start_date}
                                    onChange={(date) =>{
                                        setFieldValue("start_date", date);
                                    }}
                                    className="admin_add_input"
                                    dateFormat={"yyyy-MM-dd"}
                                    name='start_date'
                                />
                            </div>
                            <div   style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}>
                                <label htmlFor="end_day">
                                    {t("end_date")}
                                </label>
                                <DatePicker
                                    selected={values.end_date}
                                    onChange={(date) =>{
                                        setFieldValue("end_date", date);
                                    }}
                                    className="admin_add_input"
                                    dateFormat={"yyyy-MM-dd"}
                                    name='end_date'
                                    // minDate={new Date()}
                                />
                            </div>
                        </div>
                        {/* End  */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          width: "90%",
                          marginTop: 20,
                        }}
                      >
                        <button
                          style={{
                            width: "100%",
                          }}
                          type="submit"
                          className="admin_add_button"
                        >
                          {t("crud.placeholders.submit")}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            ) : null}
            <div
              className={`table_bank_tran ${
                showFilters && "tabel_anmation_open"
              }`}
            >
              <div className="tajah_card_title_container">
                <h3 className="table_header">
                  <div>{t("financial.label.report")}</div>
                </h3>
                <div className={"tajah_card_title_container_actions"}>
                  <button
                    style={{ borderRadius: 10, padding: 10, borderWidth: 0 }}
                    className={
                      showFilters ? "btn btn-sm btn-danger" : "btn-bg-filter"
                    }
                    onClick={() => {
                      setShowFilters(!showFilters);
                    }}
                  >
                    {/* <i
                      className={
                        "fas fa-filter tajah_ticket_list_table_filter_icon"
                      }
                    /> */}
                     <IconTooltips
                              title={t('Filter')}
                              content={<i className={"fas fa-filter tajah_ticket_list_table_filter_icon"}/>}
                              />
                  </button>
                  <button
                    className={"btn btn-sm btn-primary"}
                    style={{
                      marginRight: document.body.dir == "rtl" ? 10 : 0,
                      marginLeft: document.body.dir == "ltr" ? 10 : 0,
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      borderWidth: 0,
                    }}
                    onClick={async () => {
                      try {
                        setIsPerformingBackgroundOperation(true);
                        await exportOrder(clearEmptyProp(filter), "xlsx");
                        setIsPerformingBackgroundOperation(false);
                      } catch (e) {
                        setIsPerformingBackgroundOperation(false);
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("failed_fetching")}
                          </span>
                        );
                      }
                    }}
                  >
                    <IconTooltips
                      title={t("modal.coupon.hover.titleDownloadExcel")}
                      content={<img src={Xls} style={{ width: 25 }} />}
                    />
                  </button>
                  <button
                    className={"btn btn-sm btn-primary"}
                    style={{
                      marginRight: document.body.dir == "rtl" ? 10 : 0,
                      marginLeft: document.body.dir == "ltr" ? 10 : 0,
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      borderWidth: 0,
                    }}
                    onClick={async () => {
                      try {
                        setIsPerformingBackgroundOperation(true);
                        await exportOrder(clearEmptyProp(filter), "pdf");
                        setIsPerformingBackgroundOperation(false);
                      } catch (e) {
                        setIsPerformingBackgroundOperation(false);
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("failed_fetching")}
                          </span>
                        );
                      }
                    }}
                  >
                    <IconTooltips
                      title={t("modal.coupon.hover.titleDownloadPdf")}
                      content={<img src={Pdf} style={{ width: 25 }} />}
                    />
                  </button>
                </div>
              </div>
              <div
                className="table-responsive"
                style={{
                  width: "90%",
                }}
              >
                <table className="table table-bordered" style={{whiteSpace:'nowrap',width:'100%'}}>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{t("financial.label.trainee_name")}</th>
                      <th scope="col">{t("financial.label.status")}</th>
                      <th scope="col">{t("financial.label.payment_method")}</th>
                      <th scope="col">{t("financial.label.resource_name")}</th>
                      <th scope="col">{t("financial.label.type")}</th>
                      <th scope="col">{t("financial.label.date")}</th>
                      <th scope="col">
                        {t("financial.label.item_real_price")}
                      </th>
                      <th scope="col">
                        {t("financial.label.coupon_discount")}
                      </th>
                      <th scope="col">{t("financial.label.offer_discount")}</th>
                      <th scope="col">{t("financial.label.tax")}</th>
                      <th scope="col">{t("financial.label.total_price")}</th>
                      <th scope="col">
                        {t("financial.label.paid_from_wallet")}
                      </th>
                      <th scope="col">{t("financial.label.to_be_paid")}</th>
                      <th scope="col">{t("financial.label.fromSponsor")}</th>
                      <th scope="col">{t("financial.label.IsGift")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentOrder &&
                      currentOrder.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <th scope="row">
                                {
                                    PaginationSelected?
                                        (40*PaginationSelected) + index + 1
                                    :
                                        index + 1
                                }
                            </th>
                            <td scope="col">{item.user}</td>
                            <td scope="col">{item.status}</td>
                            <td scope="col">{item.payment_method}</td>
                            <td scope="col">{item.item}</td>
                            <td scope="col">{item.type}</td>
                            <td scope="col">{item.date}</td>
                            <td scope="col">{item.item_real_price}</td>
                            <td scope="col">{item.coupon_discount}</td>
                            <td scope="col">{item.offer_discount}</td>
                            <td scope="col">{item.tax_value}</td>
                            <td scope="col">{item.total_price}</td>
                            <td scope="col">{item.paid_from_wallet}</td>
                            <td scope="col">{item.to_be_paid}</td>
                            <td scope="col">{item.sponsor == "" ? t("No") : t("Yes")}</td>
                            <td scope="col">{item.is_gift == 0 ? t("No") : t("Yes")}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <ReactPaginate
                  nextLabel={t("next")}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={meta.totalPages}
                  previousLabel={t("previous")}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default List;
