import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AddActivityToLessonModal from "../../../../Modals/AddActivityToLessonModal/AddActivityToLessonModal";
import { LessonContentProvider } from "../../../../Providers/LessonContentProvider";
import {
  BanAllActivityComment,
  PublishAllActivityComment,
  addActivityComment,
  fetchChapterCommentsList,
} from "../../../../Redux/Actions/activityComment/activityComment.action";
import { saveWatchingProgress } from "../../../../Redux/Actions/courses/coursesList.action";
import toLocalTimezone from "../../../../Services/Timezone";
import { getViewDiscussion } from "../../../../Services/api/DiscussionBoard/DiscussionProvider";
import { deleteLessonActivity } from "../../../../Services/api/courses/courseProvider";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar-icon.svg";
import DeleteIconModal from "../../../../assets/icons/delete.svg";
import { ReactComponent as DocumentIcon } from "../../../../assets/icons/document.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import { Lang } from "../../../../utils";
import AddNewItem from "../../../Shared/Components/AddNewItem/AddNewItem";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CommentForm from "../NewLesson/Content/Activities/List/CommentForm";
import Content from "./Content/Content";
import DiscBoardCommentBox from "./DiscBoardCommentBox";
import DiscFormModal from "./DiscFormModal/DiscFormModal";
import NoDiscView from "./NoDiscView";
import { discActions } from "./discAction.jsx";
import classes from "./discBoard.module.css";

const EduGoals = ({ role }) => {
  const editDiscModalRef = useRef();
  const { t, i18n } = useTranslation();
  const { id, courseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [addNewComment, setAddNewComment] = useState(false);
  const [ListView, setListView] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openEditDiscModal, setOpenEditDiscModal] = useState(false);
  const [isModalActivityOpen, setIsModalActivityOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCourseClosed, setIsCourseClosed] = useState(true);

  const { chapter, chapterCommentsListLoading, chapterCommentsList } =
    useSelector((state) => state.activityCommentsListReducer);

  const handleCloseModal = () => setIsModalActivityOpen(false);

  const handleOpenModal = () => {
    setIsModalActivityOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const deleteActivity = () => {
    const myPromise = new Promise((resolve, reject) => {
      deleteLessonActivity(chapter.id)
        .then(async (result) => {
          if (result.status) {
            toast.success(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("trainer.course.lesson.successful_deleting_activity")}
              </span>
            );
            resolve();
          }
          handleCloseDeleteModal();
          getCommentsListHandler();
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("trainer.course.lesson.failed_deleting_activity")}
            </span>
          );

          reject();
        });
    });

    return myPromise;
  };

  const addCommentToLesson = (payload) => {
    dispatch(addActivityComment(payload));
    getCommentsListHandler();
  };

  const handlePublishAll = () => {
    dispatch(PublishAllActivityComment(chapter.id, true));
  };
  const handleBanAll = () => {
    dispatch(BanAllActivityComment(chapter.id, true));
  };

  const getCommentsListHandler = () => {
    dispatch(
      fetchChapterCommentsList({
        id: id,
        // page: currentPage,
        // perPage: 10,
        // filter,
      })
    );
  };

  const saveWatchingProgressAPI = async () => {
    try {
      if (!chapter.include_view_per) return;
      if (chapter?.include_view_per && chapter?.is_watched) return;
      const payload = {
        course_id: courseId,
        chapter_id: id,
        watchable_id: chapter.id,
        watchable_type: "discussion",
      };
      dispatch(saveWatchingProgress(payload));
    } catch (err) {
      console.log("ERROR");
      console.log(err);
    }
  };

  useEffect(() => {
    getCommentsListHandler();
    setIsLoading(false);
    saveWatchingProgressAPI();
  }, []);

  useEffect(() => {
    if (chapter) {
      console.log("chapter", chapter);
      setIsCourseClosed(
        chapter?.morph_data?.course_status === "Closed" ||
          chapter?.morph_data?.course_status === "مغلقة"
      );
    }
  }, [chapter]);

  const openEditDiscModalHandler = () => {
    setOpenEditDiscModal(true);
    editDiscModalRef.current.showModal();
  };

  const closeEditDiscModalHandler = () => {
    setOpenEditDiscModal(false);
    editDiscModalRef.current.dismissModal();
  };

  const downloadActivityFile = (url, name) => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportHandler = (type) => {
    console.log("Export");
  };

  return (
    <LessonContentProvider>
      <>
        <div className={`${classes["activities-list"]}`}>
          {chapterCommentsListLoading && isLoading && <SkeletonCardOverlay />}
          <div className="container-fluid">
            <div className={classes["activities-list__container"]}>
              {chapter === undefined ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <MainBox>
                        <div className="tw-flex tw-items-center tw-mb-4">
                          <button
                            className={classes["activities-list__back-button"]}
                            onClick={() => history.goBack()}
                          >
                            <i
                              className={`fas fa-chevron-${
                                i18n.language === Lang.AR ? "right" : "left"
                              } tw-text-black tw-mt-2`}
                            ></i>
                            <span>{t("back")}</span>
                          </button>
                        </div>
                        {role !== "partner" && !isCourseClosed ? (
                          <AddNewItem
                            title={t("trainer.course.lesson.add_activity")}
                            action={{
                              onClick: () => handleOpenModal(),
                            }}
                          />
                        ) : null}
                      </MainBox>
                    </div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <MainBox>
                      <div className="tw-flex tw-items-center tw-mb-4">
                        <button
                          className={classes["activities-list__back-button"]}
                          onClick={() => history.goBack()}
                        >
                          <i
                            className={`fas fa-chevron-${
                              i18n.language === Lang.AR ? "right" : "left"
                            } tw-text-black tw-mt-2`}
                          ></i>
                          <span>{t("back")}</span>
                        </button>
                      </div>
                      <MainBoxHead
                        title={chapter?.name}
                        actions={discActions({
                          ...((role === "trainer" ||
                            role === "manager" ||
                            role === "admin") &&
                          !isCourseClosed
                            ? {
                                banAllAction: () => handleBanAll(),
                                publishAllAction: () => handlePublishAll(),
                                editAction: openEditDiscModalHandler,
                                deleteAction: handleOpenDeleteModal,
                              }
                            : {}),
                          ...(role !== "partner"
                            ? {
                                exportPdf: () => exportHandler("pdf"),
                                exportExcel: () => exportHandler("xlsx"),
                              }
                            : {}),
                        })}
                      />
                      <div className="tw-space-y-2">
                        <div
                          className="d-flex align-items-center"
                          style={{
                            gap: "30px",
                          }}
                        >
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t(
                              "trainer.course.lesson.activity.activity_details"
                            )}
                          </div>
                        </div>
                        <div className="tw-p-4 border">
                          <div className="tw-flex tw-items-center tw-space-s-2 tw-text-teal-500">
                            <CalendarIcon className="tw-h-4" />
                            <div>
                              {`${moment(toLocalTimezone(chapter?.created_at))
                                .local(true)
                                .format("DD/MM/YYYY")} ${t("the_hour")}
                    ${moment(toLocalTimezone(chapter?.created_at))
                      .local(true)
                      .format("hh:mm A")}`}
                            </div>
                          </div>
                          <div className="tw-text-gray-400 tw-text-sm">
                            {" "}
                            {chapter?.desc?.replace(/<\/?[^>]+(>|$)/g, "")}
                          </div>
                          <div className="tw-py-4">
                            {Object.keys(chapter.attachment ?? {}).length ? (
                              <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2">
                                <div className="tw-flex tw-items-center tw-space-s-2">
                                  <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                                    <DocumentIcon className="tw-h-4 tw-w-4" />
                                  </div>

                                  <div>{chapter.attachment.name}</div>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      downloadActivityFile(
                                        chapter.attachment.url,
                                        chapter.attachment.name
                                      );
                                    }}
                                  >
                                    <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div id="replies-container">
                        <div className="tw-text-teal-600 tw-font-bold tw-mb-2">
                          {t("trainer.course.lesson.activity.coments")}
                        </div>
                        <div
                          className="tw-overflow-y-auto inner-scrollbar  tw-pe-4 tw-space-y-4"
                          style={{ maxHeight: "80vh" }}
                        >
                          {chapterCommentsList?.map((item) =>
                            role === "trainer" ||
                            role === "manager" ||
                            role === "admin" ||
                            item.is_published ? (
                              <DiscBoardCommentBox
                                item={item}
                                isAdmin={role === "admin"}
                                isTrainer={role === "trainer"}
                                isManager={role === "manager"}
                                role={role}
                                discussionId={chapter.id}
                                isClosed={isCourseClosed}
                              />
                            ) : null
                          )}
                        </div>
                      </div>
                      {!isCourseClosed ? (
                        <AddNewItem
                          title={t(
                            "trainer.course.lesson.activity.add_comment"
                          )}
                          action={{
                            onClick: () => setAddNewComment(!addNewComment),
                          }}
                        />
                      ) : null}
                      {addNewComment && !isCourseClosed ? (
                        <CommentForm
                          handleClose={() => setAddNewComment(!addNewComment)}
                          isComment={true}
                          discussionId={chapter.id}
                          addCommentHandler={addCommentToLesson}
                        />
                      ) : null}
                    </MainBox>
                  </div>
                </div>
              )}
            </div>
          </div>
          <BasicModal ref={editDiscModalRef}>
            {openEditDiscModal && (
              <DiscFormModal
                chapter={chapter}
                title={t("trainer.course.lesson.activity.edit")}
                dismissModal={closeEditDiscModalHandler}
                getCommentsListHandler={getCommentsListHandler}
                activityId={chapter.id}
              />
            )}
          </BasicModal>
          {isModalActivityOpen && (
            <AddActivityToLessonModal
              chapterId={id}
              dismissModal={handleCloseModal}
              title={t(`trainer.course.lesson.add_activity`)}
              isChapter={true}
            />
          )}
          {isDeleteModalOpen && (
            <SharedModal
              icon={DeleteIconModal}
              title={t("disc_delete_msg")}
              confirmMsg={t("confirm_disc_delete_msg")}
              confirmText={t("general.confirm")}
              cancelText={t("general.cancel")}
              onCancel={handleCloseDeleteModal}
              onConfirm={() => deleteActivity()}
              isLoading={false}
            />
          )}
        </div>
      </>
    </LessonContentProvider>
  );
};

export default EduGoals;
