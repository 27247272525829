import React, { useEffect } from "react";
import ContentLoadingSpinner from "../ContentLoadingSpinner/ContentLoadingSpinner";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import Select from "react-select";
import classes from "./sharedModal.module.css";
const SharedModal = ({
  icon,
  iconCom,
  title,
  name,
  confirmMsg,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  isLoading,
  color,
  isCancel,
  checkBoxMsg,
  setCheckboxValue,
  isDropDownList,
  dropDownLabel,
  dropDownOptions,
  dropDownOnChange,
  dropDownNote,
}) => {
  const modalRef = React.useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className={classes["modal"]}>
      <div className={classes["modal__container"]} ref={modalRef}>
        <div className={classes["modal__icon"]}>
          {iconCom ? iconCom : <img src={icon} alt="" />}
        </div>
        <div className={classes["modal__content"]}>
          <div className={classes["modal__content__title"]}>{title}</div>
          <div
            className={classes["modal__content__name"]}
            style={{ color: color === "#26b3b9" ? "#26b3b9" : "#e62626" }}
          >
            {name}
          </div>
          <div className={classes["modal__content__confirm-msg"]}>
            {confirmMsg}
          </div>
          {isCancel && (
            <div style={{ marginTop: "20px" }}>
              <Checkbox
                name="Checkbox"
                label={checkBoxMsg}
                onChange={(event) => {
                  event.target.checked
                    ? setCheckboxValue(1)
                    : setCheckboxValue(0);
                }}
              />
            </div>
          )}
          {isDropDownList && (
            <div className={`${classes["create_event__form__field"]} col-12`}>
              <label htmlFor="dropDown">{dropDownLabel}</label>
              <Select
                name="dropDown"
                id="dropDown"
                options={dropDownOptions}
                // value={dropDownOptions?.find(
                //   (option) => option.id == values.user_id
                // )}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(e) => {
                  dropDownOnChange(e.id);
                }}
                placeholder={dropDownLabel}
              />
              <p className={classes.dropDownNote}>{dropDownNote}</p>
            </div>
          )}
        </div>
        <div className={classes["modal__buttons"]}>
          <button
            className={classes["modal__buttons__confirm"]}
            onClick={onConfirm}
            disabled={isLoading}
            style={{ background: color === "#26b3b9" ? "#26b3b9" : "#e62626" }}
          >
            {isLoading ? <ContentLoadingSpinner /> : confirmText}
          </button>
          <button
            className={classes["modal__buttons__cancel"]}
            onClick={onCancel}
          >
            {cancelText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SharedModal;
