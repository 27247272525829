import { Pagination } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../Components";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye.svg";
import FilterIcon from "../../../assets/icons/filter.svg";
import PdfIcon from "../../../assets/icons/pdf.svg";
import DeleteIcon from "../../../assets/icons/warning.svg";
import XlsIcon from "../../../assets/icons/xls.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./ActivitiesMark.module.css";

import moment from "moment";
import toLocalTimezone from "../../../Services/Timezone";
import { exportActivitiesMarkList } from "../../../Services/api/ActivitiesMarkList";
import { getLessonActivityAnswers } from "../../../Services/api/courses/courseProvider";
import {
  deleteExam,
  exportQuizParticipants,
  getQuizParticipants,
} from "../../../Services/api/exams/ExamsProvider";
import HomeIcon from "../../../assets/icons/home.svg";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import StarsRating from "../../Shared/Components/StarsRating/StarsRating";

export default function AcrivitiesMark({ role }) {
  const { id, activityId } = useParams();
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false); ///////////////////////////////////////////////////
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [participants, setParticipants] = useState([]);
  const [activity, setActivity] = useState({});
  const [rating, setRating] = useState([]);

  const [quiz, setQuiz] = useState({});
  const [filter, setFilter] = useState({});

  const [filterPayload, setFilterPayload] = useState({});

  const [order, setOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [showDeleteModalByRow, setShowDeleteModalByRow] = useState(null);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const [filterData, setFilterData] = useState({
    student_name: "",
    email: "",
    grade: "",
    result: "",
    enrolled_date: "",
  });

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      student_name: "",
      email: "",
      grade: "",
      result: "",
      enrolled_date: "",
    });
    setIsResetApplied(true);
  };

  function getFilterData() {
    const filter = { page: currentPage, perPage: 10 };
    filterData.student_name && (filter.student_name = filterData.student_name);
    filterData.email && (filter.email = filterData.email);
    filterData.grade && (filter.grade = filterData.grade);
    filterData.result && (filter.result = filterData.result);
    filterData.enrolled_date &&
      (filter.enrolled_date = filterData.enrolled_date);

    return filter;
  }

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      await exportActivitiesMarkList(filterPayload, type, activityId);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: () => {
        setIsFilter((val) => !val);
      },
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: () => {
        exportHandler("pdf");
      },
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: () => {
        exportHandler("xlsx");
      },
    },
  ];

  const getParticipants = async () => {
    const filter = getFilterData();
    try {
      setIsLoading(true);
      const res = await getLessonActivityAnswers(activityId, filter);
      console.log(res);
      if (res.status == 200) {
        setActivity(res.data.activity.length ? res.data.activity[0] : {});
        setParticipants(res.data.data);
        setRating(res.data.ratings);
        setMeta(res.data.meta);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    setFilterPayload(filter);
  };

  useEffect(() => {
    getParticipants();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const quizActionsList = [
    {
      id: "view-details",
      icon: (row) => <EyeIcon className="tw-w-5 tw-h-5" />,
      title: (row) => t("booth.details"),
      action: (row) => {
        // history.push(
        //   `/${
        //     isTrainer ? "trainer" : "admin"
        //   }/course/${courseId}/${quizable}/${quizableId}/assessment/${quizId}/participants/answers/${
        //     row.user.id
        //   }`
        // );
      },
    },
  ];

  const columns = [
    // id
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + ((meta.currentPage ?? 1) - 1) * 10}</>
      ),
    },
    {
      id: "image",
      name: t("Exhibition.photo"),
      key: "img",
      cell: ({ rowData }) =>
        rowData.user?.img ? (
          <div className=" tw-h-16 tw-w-16 tw-shrink-0 tw-mx-auto">
            <img
              src={rowData.user?.img}
              className="tw-rounded-full tw-h-full tw-aspect-square"
              alt=""
            />
          </div>
        ) : (
          <></>
        ),
    },
    {
      id: "name",
      name: t("student_name"),
      key: "name",
      sortable: false,
      cell: ({ rowData }) => (
        <span className="cell-font tw-w-36 text2lines">
          {rowData.user?.name}
        </span>
      ),
    },
    {
      id: "email",
      name: t("email"),
      key: "email",
      cell: ({ rowData }) => (
        <span className="cell-font">{rowData.user?.email}</span>
      ),
    },
    {
      id: "grade",
      name: t("student_grade"),
      key: "grade",
      cell: ({ rowData }) => (
        <span className="cell-font">
          {Math.round(parseFloat(rowData.score) * 100) / 100}(
          {Math.round(
            (parseFloat(rowData.score) / parseFloat(activity.activity_mark)) *
              10000
          ) / 100}
          % )
        </span>
      ),
    },
    {
      id: "result",
      name: t("student_result"),
      key: "result",
      cell: ({ rowData }) => (
        <span
          className={`cell-font tw-p-1 tw-rounded ${
            rowData.is_correct
              ? (parseFloat(rowData.score) /
                  parseFloat(activity.activity_mark)) *
                  100 >=
                parseFloat(activity.success_mark)
                ? "tw-text-emerald-600 tw-bg-emerald-600/10"
                : "tw-text-red-600 tw-bg-red-600/10"
              : "tw-text-gray-400 tw-bg-gray-400/10"
          }`}
        >
          {console.log(parseFloat(rowData.score), parseFloat(activity))}
          {rowData.is_correct
            ? (parseFloat(rowData.score) / parseFloat(activity.activity_mark)) *
                100 >=
              parseFloat(activity.success_mark)
              ? t("success")
              : t("fail")
            : t("undefined")}
        </span>
      ),
    },

    {
      id: "rate",
      name: t("booth.rate"),
      key: "rate",
      cell: ({ rowData }) => {
        const rate = rating?.find((item) => rowData.user.id == item.user_id);

        return (
          <>
            <StarsRating
              rating={rate?.stars || 0}
              cutStyle={classes["stars-font-size"]}
            />
          </>
        );
      },
    },
    {
      id: "note",
      name: t("note_rate"),
      key: "note",
      cell: ({ rowData }) => {
        const rate = rating?.find((item) => rowData?.user.id == item.user_id);

        return (
          <div style={{ width: 300 }}>
            <p className={classes["note_table"]}>{rate?.description || "-"}</p>
          </div>
        );
      },
    },
    {
      id: "enrolled_date",
      name: t("enrolled_date"),
      key: "enrolled_date",
      cell: ({ rowData }) => (
        <span className="cell-font">
          {rowData.created_at ? (
            <>
              <div>{moment(rowData.created_at).format("DD/MM/YYYY")}</div>
              <div>{moment(rowData.created_at).format("hh:mm A")}</div>
            </>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    // actions
    // {
    //   id: "actions",
    //   name: t("Exhibition.actions"),
    //   key: "actions",
    //   cell: ({ rowData, rowIndex }) => (
    //     <div
    //       className={`${classes["exhibition_list__actions"]} tw-flex tw-items-center tw-justify-center`}
    //     >
    //       {quizActionsList.map((action, index) => (
    //         <IconsTooltip
    //           title={action.title(rowData)}
    //           content={
    //             <button
    //               key={action.id}
    //               className={classes["exhibition_list__actions-btn"]}
    //               onClick={() => {
    //                 action.action(rowData, rowIndex);
    //               }}
    //             >
    //               {action.icon(rowData, rowIndex)}
    //             </button>
    //           }
    //         />
    //       ))}
    //     </div>
    //   ),
    // },
  ];

  const filterFields = [
    // search
    {
      id: "student_name",
      component: "input",
      type: "search",
      placeholder: t("student_name"),
      name: "student_name",
      value: filterData.student_name ?? "",
      classes: "col-12 col-md-4",
      onReset: resetHandler,
      onchange: filterChangeHandler,
    },
    // email
    {
      id: "email",
      component: "input",
      type: "search",
      placeholder: t("Email"),
      name: "email",
      value: filterData.email ?? "",
      classes: "col-12 col-md-4",
      onReset: resetHandler,
      onchange: filterChangeHandler,
    },
    // student grade
    {
      id: "grade",
      component: "select",
      placeholder: t("student_grade"),
      initialValue: t("student_grade"),
      name: "grade",
      value: filterData.grade ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: [
        {
          id: "1",
          value: JSON.stringify({ max: 10, min: 0 }),
          label: "0 - 10",
        },
        {
          id: "2",
          value: JSON.stringify({ max: 20, min: 11 }),
          label: "11 - 20",
        },
        {
          id: "3",
          value: JSON.stringify({ max: 30, min: 21 }),
          label: "21 - 30",
        },
        {
          id: "4",
          value: JSON.stringify({ max: 40, min: 31 }),
          label: "31 - 40",
        },
        {
          id: "5",
          value: JSON.stringify({ max: 50, min: 41 }),
          label: "41 - 50",
        },
        {
          id: "6",
          value: JSON.stringify({ max: 60, min: 51 }),
          label: "51 - 60",
        },
        {
          id: "7",
          value: JSON.stringify({ max: 70, min: 61 }),
          label: "61 - 70",
        },
        {
          id: "8",
          value: JSON.stringify({ max: 80, min: 71 }),
          label: "71 - 80",
        },
        {
          id: "9",
          value: JSON.stringify({ max: 90, min: 81 }),
          label: "81 - 90",
        },
        {
          id: "10",
          value: JSON.stringify({ max: 100, min: 91 }),
          label: "91 - 100",
        },
      ],
    },
    // student result
    {
      id: "result",
      component: "select",
      placeholder: t("student_result"),
      initialValue: t("student_result"),
      name: "result",
      value: filterData.result ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        {
          id: "success",
          value: "1",
          label: t("success"),
        },
        {
          id: "fail",
          value: "0",
          label: t("fail"),
        },
      ],
    },

    // enrolled_date
    {
      id: "enrolled_date",
      component: "dateRange",
      placeholder: t("enrolled_date"),
      label: t("enrolled_date"),
      name: "enrolled_date",
      value: filterData.enrolled_date ?? "",
      classes: "col-12 col-md-3",
      onchange: filterChangeHandler,
    },

    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-2 mr-auto",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        resetFilterHandler();
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          getParticipants();
        }
      },
    },
  ];

  // const fetchQuiz = (params = getFilter()) => {
  //   params = {
  //     ...params,
  //     perPage: 10,
  //   };
  //   setIsUpdating(true);
  //   const myPromise = new Promise(async (resolve, reject) => {
  //     try {
  //       let response = await getQuizParticipants(quizId, params);
  //       console.log(response);
  //       if (response.status == 200) {
  //         setParticipants(response.data.data.participants);
  //         setQuiz(response.data.data.quiz);
  //         setMeta(response.data.data.meta);
  //         setIsLoading(false);
  //         setIsUpdating(false);
  //       }
  //       resolve();
  //     } catch (e) {
  //       reject(e);
  //     }
  //   });

  //   return myPromise;
  // };

  const sortActionHandler = async (columnKey) => {
    let key = columnKey;

    setOrder({
      order: key,
      dir: order?.order == key && order?.dir == "ASC" ? "DESC" : "ASC",
    });
  };

  // const refetchQuiz = async (params = getFilter()) => {
  //   try {
  //     let customFilter = {
  //       ...params,
  //       ...order,
  //       page: currentPage,
  //     };
  //     await fetchQuiz(customFilter);
  //   } catch (e) {
  //     toast.error(
  //       <span style={{ fontSize: 13, fontWeight: "bold" }}>
  //         {t("failed_fetching")}
  //       </span>
  //     );
  //   }
  // };

  useEffect(() => {
    // refetchQuiz();
  }, [currentPage, order]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "course",
      page: t("Courseoutline"),
      pagePath: `/${role}/course/${location?.state?.courseID}`,
    },
    {
      id: "lesson",
      page: location?.state?.title,
      pagePath: `${role}/course/lesson/${id}`,
    },
    {
      id: "activity",
      page: activity?.title,
      pagePath: "#",
      active: true,
    },
  ];

  return (
    <>
      {/* {showDeleteModalByRow && (
        <SharedModal
          icon={DeleteIcon}
          title={t("modal.support.ticket_types.delete.title")}
          name={showDeleteModalByRow.title}
          deleteMsg={t("modal.support.ticket_types.delete.body")}
          confirmText={t("survey.delete_aprove")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModalByRow(null);
          }}
          onConfirm={async () => {
            try {
              setIsUpdating(true);
              const res = await deleteExam(showDeleteModalByRow.id);
              refetchQuiz();
              setShowDeleteModalByRow(null);
              setIsUpdating(false);
            } catch (error) {
              setIsUpdating(false);
            }
          }}
          // isLoading={iseDeleteLoading}
        />
      )} */}
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {isExportLoading ? (
          <SkeletonCardOverlay skeletonWidth="100" />
        ) : (
          <div></div>
        )}

        <div className="tw-space-y-4 tw-mb-8">
          <MainBox>
            {/* {isLoading && <SkeletonCardOverlay skeletonWidth="100" />} */}
            <MainBoxHead title={t("participants")} actions={actionsList} />
            {isFilter && <FilterBox fields={filterFields} />}
            <MainTable
              columns={columns}
              data={participants}
              sortAction={sortActionHandler}
              isLoading={isLoading}
            />
            <Pagination
              count={meta.totalPages}
              page={currentPage}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              className="main-pagination"
              onChange={(e, page) => {
                setCurrentPage(page);
              }}
            />
          </MainBox>
        </div>
      </div>
    </>
  );
}
