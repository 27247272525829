import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function addRequest(payload, role) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      if (role) {
        connector
          .post(env.publish_req_guest, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        connector
          .post(env.publish_req, payload, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportPublishReqList(data, export_type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.publish_req_list, {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Publish Requests" + "." + export_type
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getPublishRequestDetails(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.publish_req_details, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function sendCustomEmail(id, subject, body) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.send_custom_email, { id: id }), {
          subject: subject,
          body: body,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
