import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {NavLink} from "react-router-dom";
import DatePicker from "react-datepicker";
import {Formik} from "formik";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import {toast} from "react-toastify";
import {
    deleteCoupon,
    exportCoupons,
    getCoursesCoupon,
    listAllCoupon
} from "../../../../Services/api/coupon/couponProvider";
import {BasicModal} from "../../../Shared/Components/Modal/BasicModal";
import DeleteModal from "../../../../Modals/DeleteModal/DeleteModal";
import CoursesCouponModal from "../../../../Modals/couponsModal/CoursesCouponModal";
import IconTooltips from '../../../../Components/tooltips/IconsTooltip'
import Pdf from './../../../../assets/image/rating/pdf.png'
import Xls from './../../../../assets/image/rating/xls.png'
import ReactPaginate from "react-paginate";

function CouponList(props) {
  require("./CouponList.css");
  const {t} = useTranslation();
  const history = useHistory();
  const coursesCouponModalRef = useRef();
  const modalHandler = () => deleteModalRef.current.dismissModal();
  const deleteModalRef = useRef();
  const [roles, setRoles] = useState([]);
  const [meta, setMeta] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] = useState(false)
  const [loginTypes, setLoginTypes] = useState([
    {text: "Facebook", value: "facebook"},
    {text: "Twitter", value: "twitter"},
    {text: "LinkedIn", value: "linkedin"},
    {text: "Google", value: "google"},
    {text: "Normal", value: "normal"},
    {text: "SSO", value: "sso"},
  ]);
  const [courses, setCourses] = useState([]);
  const [showCourses, setShowCourses] = useState(false);
  const [statuses, setStatuses] = useState([
    {text: "Inactive", value: 0},
    {text: "Active", value: 1}
  ]);
  const [currentFilter, setCurrentFilter] = useState({})
  const [coupons, setCoupons] = useState([])
  const [isUpdating, setIsUpdating] = useState(false);

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const [couponId, setCouponId] = useState("");
  const [couponIndex, setCouponIndex] = useState("");

  const fetchAllCoupon = (params = null) => {
    setIsUpdating(true)
    return new Promise((resolve, reject) => {
      listAllCoupon(clearEmptyProp(params))
          .then((res) => {
            if (res.status && res.status == 200 && res.data.status) {
              setCoupons(res.data.coupons.data);
              setMeta(res.data.coupons.meta);
              setIsLoading(false);
              setIsUpdating(false)
              reAlignElementsHeights(showFilters)
              resolve();
            } else {
              reject("Error");
            }
          })
          .catch((err) => {
            toast.error(
                <span style={{fontSize: 13, fontWeight: "bold"}}>
                    {t("failed_fetching")}
                </span>
            );
          });
    });
  };

  useEffect(() => {
    fetchAllCoupon(currentFilter);
  }, []);


  const editHandler = (id) => {
    history.push(`/${localStorage.getItem("type") == "accountant" ? "accountant" : "admin"}/coupon/edit/${id}`);
  };

  const deleteHandler = (id,index) => {
    setCouponId(id);
    setCouponIndex(index)
    coursesCouponModalRef.current.showModal();
    setShowCourses(false)
  };

  const deleteDate = (id,index) => {
    deleteCoupon(id)
        .then(() => {
          const newCoupons = coupons.filter((item, i) => i !== index);
          setCoupons(newCoupons);
          coursesCouponModalRef.current.dismissModal();
        })
        .catch((err) => {
          coursesCouponModalRef.current.dismissModal();
          toast.error(
              <span style={{fontSize: 13, fontWeight: "bold"}}>
                {t("failed_deleting")}
              </span>
          );
        });
  };


  useEffect(() => {
    if (!isLoading) {
      document.getElementById('content-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
          parseInt(document.getElementById('content-header-bar').offsetHeight) + 40
      ).toString() + 'px';

      document.getElementById('content-body-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight)
      ).toString() + 'px'
    }
  }, [isLoading])

  useEffect(() => {
    if (!isLoading) {
      reAlignElementsHeights(showFilters)
    }
  }, [showFilters])


  const reAlignElementsHeights = (isFilterShown) => {
    if (!isFilterShown) {
      document.getElementById('content-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
          parseInt(document.getElementById('content-header-bar').offsetHeight) + 40
      ).toString() + 'px';

      document.getElementById('content-body-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight)
      ).toString() + 'px'
    } else {
      document.getElementById('content-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) +
          parseInt(document.getElementById('content-header-bar').offsetHeight) +
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) + 80
      ).toString() + 'px';

      document.getElementById('content-body-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
          parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) + 40
      ).toString() + 'px'
    }
  }


  const coursesCoupon = (id) => {
    getCoursesCoupon(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCourses(res.data.response);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  };


  const showAllCourses=(coupon)=>{
    coursesCoupon(coupon.id)
    coursesCouponModalRef.current.showModal();
    setShowCourses(true)
  }


  const handlePageClick = (event) => {
    const toBeUpdatedFilters = currentFilter
    toBeUpdatedFilters.page = event.selected + 1
    setCurrentFilter(toBeUpdatedFilters);

    fetchAllCoupon(toBeUpdatedFilters)
};

  return (
      <>
        <BasicModal ref={coursesCouponModalRef}>
        {!showCourses ? (
          <DeleteModal
          label={t("coupon.label.delete_aprove")}
          title={t("modal.coupon.delete.title")}
          deleteMsg={t("modal.coupon.delete.body")}
          refuse={modalHandler}
          accept={() => deleteDate(couponId,couponIndex)}
          deleteModalRef={coursesCouponModalRef}
          />
        ):(
          <CoursesCouponModal
          label={t("coupon.label.courses")}
          title={t("coupon.label.courses")}
          body={courses}
          coursesCouponModalRef={coursesCouponModalRef}
          />
        )}
       </BasicModal>
        <div className="container-fluid" id={"content-section"} style={{
          position: 'relative',
          padding: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}>
          <div className="admin_flex" id="content-header-bar">
            <div className="admin_label">
              <NavLink to={`${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}`}>
                {t("admin.label.admin_label")}
              </NavLink>
              <p style={{padding:"0 5px"}}>/</p>
              {t("coupon.label.coupon")}
            </div>
            <div className="admin_button_flex" >
              <NavLink
                  to={`${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/coupon/add`}
                  className="text-white admin_button_green"
                  style={{padding: "10px "}}
              >
                <i className="fas fa-plus"></i>
                <span>{t("coupon.label.add_coupon")}</span>
              </NavLink>
            </div>
          </div>
          {isUpdating ?<SkeletonCardOverlay skeletonWidth="100"/>:<div></div>}
          {isLoading == false ? (
              <div id="content-body-section" style={{
                position: 'absolute',
                width: '100%'
              }}>
                {
                  isPerformingBackgroundOperation ?
                      <SkeletonCardOverlay borderRadius={10} top={40}/> : null
                }
                <div className={"tajah_table_fade_change"} style={{
                  opacity: showFilters ? 1 : 0
                }}>
                  <Formik
                      initialValues={{
                        course_name: "",
                        domain_name: "",
                        coupon_name: "",
                        start_date: "",
                        end_date: "",
                        guest_email:""
                      }}

                      onSubmit={async (values, {setSubmitting}) => {
                        try {
                          setIsPerformingBackgroundOperation(true);
                          setCurrentFilter(values);
                          await fetchAllCoupon(values);
                          reAlignElementsHeights(showFilters);
                          setIsPerformingBackgroundOperation(false);
                        } catch (e) {
                          toast.error(
                              <span style={{fontSize: 13, fontWeight: "bold"}}>
                                            {t("failed_fetching")}
                                        </span>
                          );
                        }
                      }}
                      validateOnChange={false}
                  >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                      }) => (
                        <form onSubmit={handleSubmit} class={"table_bank_tran"}>
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            width: '90%',
                            borderBottomWidth: 1,
                            borderBottomColor: "rgba(0,0,0,0.3)",
                            borderBottomStyle: "solid",
                            height: 40
                          }}>
                            <h5>{t("Filter")}</h5>
                          </div>

                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: '90%',
                            marginTop: 30
                          }}>
                            <div style={{
                              flex: 1
                            }}>
                              <div className={"tajah_filter_item_label_actionable"}>
                                <div>
                                  <label>{t("crud.placeholders.select.start_date")}</label>
                                </div>
                                <div className={"tajah_filter_item_label_action"}>
                                  {
                                    values.start_date ? (
                                        <div className={"tajah_filter_item_label_action_clear"}
                                             onClick={() => {
                                               setFieldValue("start_date", "")
                                             }}>
                                          <i className="fas fa-times"
                                             style={{color: "white", fontSize: 10}}></i>
                                        </div>
                                    ) : null
                                  }
                                </div>
                              </div>
                              <DatePicker
                                  value={values.start_date}
                                  onChange={(date) =>
                                      setFieldValue("start_date", formatDate(new Date(date)))
                                  }
                                  placeholderText={t(
                                      "crud.placeholders.select.start_date"
                                  )}
                                  className="admin_filter_input"
                                  dateFormat={"yyyy-MM-dd"}
                              />
                            </div>

                            <div style={{
                              flex: 1,
                              marginRight: document.body.dir == 'rtl' ? 10 : 0,
                              marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                            }}>
                              <div className={"tajah_filter_item_label_actionable"}>
                                <div>
                                  <label>{t("crud.placeholders.select.end_date")}</label>
                                </div>
                                <div className={"tajah_filter_item_label_action"}>
                                  {
                                    values.end_date ? (
                                        <div className={"tajah_filter_item_label_action_clear"}
                                             onClick={() => {
                                               setFieldValue("end_date", "")
                                             }}>
                                          <i className="fas fa-times"
                                             style={{color: "white", fontSize: 10}}></i>
                                        </div>
                                    ) : null
                                  }
                                </div>
                              </div>
                              <DatePicker
                                  value={values.end_date}
                                  onChange={(date) =>
                                      setFieldValue("end_date", formatDate(new Date(date)))
                                  }
                                  placeholderText={t(
                                      "crud.placeholders.select.end_date"
                                  )}
                                  className="admin_filter_input"
                                  dateFormat={"yyyy-MM-dd"}
                              />
                            </div>

                            <div style={{
                              flex: 1,
                              marginRight: document.body.dir == 'rtl' ? 10 : 0,
                              marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                            }}>
                              <label>{t("crud.placeholders.email_search")}</label>
                              <input
                                  id="guest_email"
                                  type="text"
                                  name="guest_email"
                                  className="admin_filter_input"
                                  onChange={(e) => {
                                    setFieldValue("guest_email", e.target.value)
                                  }}
                                  placeholder={t("crud.placeholders.email_search")}
                                  value={values.guest_email}
                              />
                            </div>
                          </div>

                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: '90%',
                            marginTop: 30
                          }}>
                            <div style={{
                              flex: 1,
                              marginRight: document.body.dir == 'rtl' ? 10 : 0,
                              marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                            }}>
                              <label>{t("crud.placeholders.coupon_name_search")}</label>
                              <input
                                  id="coupon_name"
                                  type="text"
                                  name="coupon_name"
                                  className="admin_filter_input"
                                  onChange={(e) => {
                                    setFieldValue("coupon_name", e.target.value)
                                  }}
                                  placeholder={t("crud.placeholders.coupon_name_search")}
                                  value={values.coupon_name}
                              />
                            </div>

                            <div style={{
                              flex: 1,
                              marginRight: document.body.dir == 'rtl' ? 10 : 0,
                              marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                            }}>
                              <label>{t("crud.placeholders.domain_name_search")}</label>
                              <input
                                  id="domain_name"
                                  type="text"
                                  name="domain_name"
                                  className="admin_filter_input"
                                  onChange={(e) => {
                                    setFieldValue("domain_name", e.target.value)
                                  }}
                                  placeholder={t("crud.placeholders.domain_name_search")}
                                  value={values.domain_name}
                              />
                            </div>

                            <div style={{
                              flex: 1,
                              marginRight: document.body.dir == 'rtl' ? 10 : 0,
                              marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                            }}>
                              <label>{t("crud.placeholders.course_name_search")}</label>
                              <input
                                  id="course_name"
                                  type="text"
                                  name="course_name"
                                  className="admin_filter_input"
                                  onChange={(e) => {
                                    setFieldValue("course_name", e.target.value)
                                  }}
                                  placeholder={t("crud.placeholders.course_name_search")}
                                  value={values.course_name}
                              />
                            </div>
                          </div>

                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            width: '90%',
                            marginTop: 20
                          }}>
                            <button
                                style={{
                                  width: '100%'
                                }}
                                type="submit"
                                className="admin_add_button"
                            >
                              {t("crud.placeholders.submit")}
                            </button>
                          </div>
                        </form>
                    )}
                  </Formik>
                </div>

                <div className="table_bank_tran tajah_table_vertical_movement" style={{
                  top: showFilters ? parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) + 40 : 0
                }}>
                  <div className="tajah_card_title_container">
                    <div>{t("coupon.label.coupon")}</div>
                    <div className={"tajah_card_title_container_actions"}>
                      <button style={{borderRadius:10,padding:10,borderWidth:0}} className={showFilters ? "btn btn-sm btn-danger" : "btn-bg-filter"}
                              onClick={() => {
                                setShowFilters(!showFilters);
                              }}>
                              <IconTooltips
                              title={t('sidebar.label.coupon')}
                              content={<i className={"fas fa-filter tajah_ticket_list_table_filter_icon"}/>}
                              />

                      </button>
                      <button className={"btn btn-sm btn-primary color-button-excel"} style={{
                        marginRight: document.body.dir == 'rtl' ? 10 : 0,
                        marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        borderWidth:0
                      }} onClick={async () => {
                        try{
                          setIsPerformingBackgroundOperation(true);
                          await exportCoupons('xlsx',clearEmptyProp(currentFilter));
                          setIsPerformingBackgroundOperation(false);
                        } catch (e) {
                          setIsPerformingBackgroundOperation(false);
                          toast.error(
                              <span style={{fontSize: 13, fontWeight: "bold"}}>
                                                    {t("failed_fetching")}
                                                </span>
                          );
                        }
                      }}>
                      <IconTooltips
                      title={t('modal.coupon.hover.titleDownloadExcel')}
                      content={<img src={Xls} style={{width:25}}/>}
                      />
                      </button>
                      <button className={"btn btn-sm btn-primary color-button-pdf"} style={{
                        marginRight: document.body.dir == 'rtl' ? 10 : 0,
                        marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        borderWidth:0
                      }} onClick={async () => {
                        try{
                          setIsPerformingBackgroundOperation(true);
                          await exportCoupons('pdf',clearEmptyProp(currentFilter));
                          setIsPerformingBackgroundOperation(false);
                        } catch (e) {
                          setIsPerformingBackgroundOperation(false);
                          toast.error(
                              <span style={{fontSize: 13, fontWeight: "bold"}}>
                                                    {t("failed_fetching")}
                                                </span>
                          );
                        }
                      }}>
                      <IconTooltips
                      title={t('modal.coupon.hover.titleDownloadPdf')}
                      content={<img src={Pdf} style={{width:25}}/>}
                      />
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive" style={{
                    width: '90%'
                  }}>
                    <table className="table table-bordered">
                      <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t("coupon.label.coupon_name")}</th>
                        <th scope="col"> {t("coupon.label.coupon_code")}</th>
                        <th scope="col">{t("coupon.label.coupon_dis")}</th>
                        <th scope="col">{t("coupon.label.start_date")}</th>
                        <th scope="col">{t("coupon.label.end_date")}</th>
                        <th scope="col">{t("coupon.label.status")}</th>
                        <th scope="col">{t("coupon.label.use")}</th>
                        <th scope="col">{t("coupon.label.release")}</th>
                        <th scope="col">{t("coupon.label.courses")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {coupons.map((coupon,index) => {
                        return (
                            <tr key={index}>
                              <td scope="row">{index+1}</td>
                              <td>{coupon.name}</td>
                              <td>{coupon.code}</td>
                              <td>{coupon.mechanism == 1 ? coupon.value + "SR" : coupon.value + "%"}</td>
                              <td>{coupon.start_date}</td>
                              <td>{coupon.end_date}</td>
                              {coupon.status == 1 ? (
                                  <td><span style={{color:'#019501'}}>{t("coupon.label.active")}</span></td>
                              ) : (
                                  <td><span style={{color:'#D20101'}}>{t("coupon.label.in_active")}</span></td>
                              )}
                              <td>{coupon.is_used == true ? t("coupon.label.used") : t("coupon.label.not_used")}</td>
                              <td className="">
                                <div className="release_icon">
                                <IconTooltips
                                title={t('modal.coupon.buttons.edit')}
                                content={<i
                                  className="fal fa-edit"
                                  onClick={() => editHandler(coupon.id)}
                                  style={{marginLeft:5,marginRight:5,backgroundColor:'white',
                                  borderWidth:0,fontSize:20,color:'black',cursor:'pointer'}}
                              ></i>}
                                />
                                <IconTooltips
                                title={t('modal.coupon.buttons.delete')}
                                content={<i
                                  className="fal fa-trash-alt"
                                  onClick={() => deleteHandler(coupon.id,index)}
                                  style={{marginLeft:5,marginRight:5,backgroundColor:'white',
                                  borderWidth:0,fontSize:20,color:'red',cursor:'pointer'}}
                              ></i>}
                                />

                                </div>
                              </td>
                              <td>
                              <div className="release_icon">
                              <IconTooltips
                              title={t('modal.coupon.buttons.data')}
                              content={<i
                                className="fal fa-eye"
                                onClick={()=>showAllCourses(coupon)}
                                style={{marginLeft:5,marginRight:5,backgroundColor:'white',
                                borderWidth:0,fontSize:20,color:'black',cursor:'pointer'}}
                            ></i>}
                              />
                              </div>
                          </td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </table>
                    <ReactPaginate
                    nextLabel={t("next")}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={meta.totalPages}
                    previousLabel={t("previous")}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
                  </div>
                </div>
              </div>
          ) : (
              <div className="mt-5" style={{
                height: "65vh"
              }}>
                <SkeletonCard/>
              </div>
          )}
        </div>
      </>
  )
}

export default CouponList;
